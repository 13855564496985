import queryString from "query-string";

import {
    ADD_CHEMICAL,
    GET_CHEMICAL,
    DEL_CHEMICAL,
    UPDATE_CHEMICAL,
    EXPORT_CHEMICAL
} from "@/api/ih/chemical/chemical";

const state = {
    dataChemical: [],
};

const mutations = {
    setChemical: (states, payload) => {
        states.dataChemical = payload;
    },
};

const actions = {
    // IH MANAGEMENT CHEMICAL
    addChemical: async ({ }, args) => {
        return await ADD_CHEMICAL(args);
    },
    delChemical: async ({ }, id) => {
        return await DEL_CHEMICAL(id);
    },
    updateChemical: async ({ }, args) => {
        return await UPDATE_CHEMICAL(args);
    },
    getChemical: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_CHEMICAL(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setChemical", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setChemical", {
                        setChemical: [],
                    });
                }
            });
    },
    exportChemical: async ({ }, id) => {
        return await EXPORT_CHEMICAL(id).then((resp) => {
            return resp.data.data;
        }).catch((err) => {
            return err;
        });
    }
};

const getters = {
    chemicalData: (states) => states.dataChemical
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};