<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-5">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <!-- Breadcrumb -->
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("OH Management") }}
                </li>
                <li class="breadcrumb-item">{{ $t("Report") }}</li>
                <li class="breadcrumb-item">Leading & Lagging Indicator</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Year")
                  }}</label>
                  <!-- <input
                    type="month"
                    v-model="body.date_input"
                    class="form-control"
                    placeholder="Pilih Tanggal"
                    v-validate="'required'"
                    name="Tanggal"
                    @input="sorting()"
                  /> -->
                  <v-select
                    :options="selectYearOption()"
                    v-model="body.date_input"
                    label="year_name"
                    :reduce="(e) => e.id"
                    @input="sorting()" />
                </div>
              </div>
              <div class="col">
                <div class="form-group" v-if="filterCompany == 1">
                  <label for="inputPassword" class="form-label">{{
                    $t("Company")
                  }}</label>
                  <v-select
                    :options="selectCompanyOption()"
                    v-model="company_id"
                    label="company_name"
                    :reduce="(e) => e.id"
                    @input="changeCompany()" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Tabs  -->
      <div class="card mt-2">
        <div class="card-header">
          <nav class="navbar navbar-expand-lg navbar-light">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo03"
              aria-controls="navbarTogglerDemo03"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
              <ul class="nav nav-tabs nav-tabs-sm card-header-tabs">
                <li class="nav-item" @click="ohManagement()">
                  <a class="nav-link" :class="{ active: direct === 'OH' }">
                    <h2 class="card-header-title">OH Indicator</h2>
                  </a>
                </li>
                <li
                  class="nav-item"
                  style="margin-left: 25px"
                  @click="indicatorRate()">
                  <a class="nav-link" :class="{ active: direct === 'Rate' }">
                    <h2 class="card-header-title">
                      {{ $t("OH Lagging Indicator") }}
                    </h2>
                  </a>
                </li>
                <li
                  class="nav-item"
                  style="margin-left: 25px"
                  @click="activitys()">
                  <a
                    class="nav-link"
                    :class="{ active: direct === 'OH_Activity' }">
                    <h2 class="card-header-title">
                      {{ $t("OH Leading Indicator") }}
                    </h2>
                  </a>
                </li>
                <li
                  class="nav-item"
                  style="margin-left: 25px"
                  @click="clickDoc()">
                  <a
                    class="nav-link"
                    :class="{ active: direct === 'Documentation' }">
                    <h2 class="card-header-title">{{ $t("Documentation") }}</h2>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <b-dropdown variant="primary">
            <template #button-content>
              <span class="fe fe-download"></span> Export
            </template>
            <b-dropdown-item
              v-if="filterCompany == 1"
              @click="downloadBIBMitra()"
              >BIB + Mitra</b-dropdown-item
            >
            <b-dropdown-item @click="downloadLeading()"
              >Leading Lagging</b-dropdown-item
            >
            <b-dropdown-item
              v-if="filterCompany == 1"
              @click="downloadCompany()"
              >{{ $t("Company Input") }}</b-dropdown-item
            >
            <b-dropdown-item v-if="conditionYearly()" @click="downloadYearly()">{{
              $t("Yearly")
            }}</b-dropdown-item>
          </b-dropdown>
        </div>
        <!-- Components -->
        <div v-if="direct === 'OH'">
          <OccupationalHealth />
        </div>
        <div v-if="direct === 'Rate'">
          <IndicatorRate />
        </div>
        <div v-if="direct === 'OH_Activity'">
          <ActivityOh />
        </div>
        <!-- <div v-if="direct === 'Top_Disease'">
          <Disease />
        </div> -->
        <div v-if="direct === 'Documentation'">
          <Documentation />
        </div>
      </div>
    </div>
    <b-modal
      id="modal-loading"
      size="sm"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc>
      <b class="d-flex justify-content-center mb-3">Please wait</b>
      <div class="d-flex justify-content-center">
        <b-spinner variant="primary" label="Loading"></b-spinner>
      </div>
    </b-modal>
  </div>
</template>
<script>
import OccupationalHealth from "../../../components/report/Occupational.vue";
import IndicatorRate from "../../../components/report/IndicatorRate.vue";
import ActivityOh from "../../../components/report/Activity.vue";
import Disease from "../../../components/report/Disease.vue";
import Documentation from "../../../components/report/Documentation.vue";
import moment from "moment";
import axiosIns from "@/api/axios";

export default {
  name: "lagging_indicator",
  computed: {
    url() {
      return this.$store.getters["summary/excelUrl"]
        ? this.$store.getters["summary/excelUrl"]
        : [];
    },
    company() {
      return this.$store.getters["detailManagement/dataCompany"]
        ? this.$store.getters["detailManagement/dataCompany"]
        : [];
    }
  },
  components: {
    OccupationalHealth,
    IndicatorRate,
    Disease,
    ActivityOh,
    Documentation
  },
  data() {
    return {
      direct: "OH",
      listYear: [],
      filterCompany: "",
      // user_role: localStorage.getItem("user_roles"),
      company_id: localStorage.getItem("company"),
      roles_id: "",
      // companys_id: localStorage.getItem("company"),
      date_input: "",
      body: {
        date_input: moment().format("YYYY"),
        data: "Leading"
      }
    };
  },
  mounted() {
    // this.getLink();
    // this.linkYearly();
    this.getCompanny();
    let year = moment().format("YYYY");
    for (let i = parseInt(year) - 2; i < parseInt(year) + 3; i++) {
      this.listYear.push(i);
    }
    console.log('adczcz', this.company_id);
    console.log('adczcz2', this.roles_id);
    const company = localStorage.getItem("company");
    const roles = localStorage.getItem("roles_id");
    this.roles_id = roles
    this.filterCompany = parseInt(roles) == 1 ? 1 : 0;
  },
  methods: {
    direct: function (id) {
      this.activeId = id;
      let body = {
        company_id: this.company_id,
        date_input: this.body.date_input
      };
      this.$store.dispatch("leadingLagging/getBodyData", body);
    },
    selectYearOption() {
      const year = moment().format("YYYY");
      const temp = [];
      for (let i = 2021; i <= year; i++) {
        temp.push({
          id: i,
          year_name: i
        });
      }
      return temp;
    },
    selectCompanyOption() {
      const company = this.company;
      const term = [];
      term.push({
        id: 0,
        company_name: "BIB + Mitra"
      });

      company.map((i) => {
        term.push({
          id: i.id,
          company_name: i.company_name
        });
      });

      return term;
    },
    downloadBIBMitra() {
      let body = {
        data: "Total",
        date_input: this.body.date_input
      };
      if (this.filterCompany == 1) body["company_id"] = this.company_id;
      this.$bvModal.show("modal-loading");
      this.$store
        .dispatch("summary/downloadExcel", body)
        .then((resp) => {
          let link = this.$store.getters["summary/excelUrl"];
          if (link.hasOwnProperty("path")) {
            window.open(axiosIns.defaults.baseURL + "/" + link.path);
          } else {
            this.$swal("Error", link.data.error, "error");
            console.log(link.data.error);
          }
        })
        .finally(() => this.$bvModal.hide("modal-loading"));
    },
    downloadLeading() {
      let body = {
        date_input: this.body.date_input.substring(4, 0),
        data: "Leading"
      };
      if (this.filterCompany == 1) body["company_id"] = this.company_id;
      this.$bvModal.show("modal-loading");
      this.$store
        .dispatch("summary/downloadExcel", body)
        .then(() => {
          let link = this.$store.getters["summary/excelUrl"];
          if (link.hasOwnProperty("path")) {
            window.open(axiosIns.defaults.baseURL + "/" + link.path);
          } else {
            this.$swal("Error", link.data.error, "error");
            console.log(link.data.error);
          }
        })
        .finally(() => this.$bvModal.hide("modal-loading"));
    },
    downloadCompany() {
      let body = {
        data: "Total Company",
        date_input: this.body.date_input
      };
      if (this.filterCompany == 1) body["company_id"] = this.company_id;
      this.$bvModal.show("modal-loading");
      this.$store
        .dispatch("summary/downloadExcel", body)
        .then((resp) => {
          let link = this.$store.getters["summary/excelUrl"];
          if (link.hasOwnProperty("path")) {
            window.open(axiosIns.defaults.baseURL + "/" + link.path);
          } else {
            this.$swal("Error", link.data.error, "error");
            console.log(link.data.error);
          }
        })
        .finally(() => this.$bvModal.hide("modal-loading"));
    },
    downloadYearly() {
      let body = {
        data: "Yearly",
        date_input: this.body.date_input.substring(4, 0)
      };
      if (this.filterCompany == 1) body["company_id"] = this.company_id;
      this.$bvModal.show("modal-loading");
      this.$store
        .dispatch("summary/downloadExcel", body)
        .then(() => {
          let link = this.$store.getters["summary/excelUrl"];

          if (link.hasOwnProperty("path")) {
            window.open(`${axiosIns.defaults.baseURL}/${link.path}`);
          } else {
            this.$swal("Error", link.data.error, "error");
            console.log(link.data.error);
          }
        })
        .finally(() => this.$bvModal.hide("modal-loading"));
    },
    getCompanny() {
      this.$store.dispatch("detailManagement/getCompany");
    },
    sorting() {
      let body = {
        company_id: this.company_id,
        date_input: this.body.date_input
      };
      console.log(this.direct);
      if (this.direct == "OH") {
        this.$root.$emit("occupational", body);
      }
      if (this.direct == "Rate") {
        this.$root.$emit("indicator", body);
      }
      if (this.direct == "OH_Activity") {
        this.$root.$emit("activity", body);
      }
      if (this.direct == "Documentation") {
        this.$root.$emit("documentation", body);
      }
    },
    changeCompany() {
      let body = {
        company_id: this.company_id,
        date_input: this.body.date_input
      };
      if (this.direct == "OH") {
        this.$root.$emit("occupational", body);
      }
      if (this.direct == "Rate") {
        this.$root.$emit("indicator", body);
      }
      if (this.direct == "OH_Activity") {
        this.$root.$emit("activity", body);
      }
      if (this.direct == "Documentation") {
        this.$root.$emit("documentation", body);
      }
    },
    ohManagement() {
      let body = {
        company_id: this.company_id,
        date_input: this.body.date_input
      };
      this.direct = "OH";
      // this.$root.$emit("occupational", body);
      this.$store.dispatch("leadingLagging/getBodyData", body);
    },
    indicatorRate() {
      let body = {
        company_id: this.company_id,
        date_input: this.body.date_input
      };
      this.direct = "Rate";
      this.$store.dispatch("leadingLagging/getBodyData", body);
    },
    clickDoc() {
      let body = {
        company_id: this.company_id,
        date_input: this.body.date_input
      };
      this.direct = "Documentation";
      this.$store.dispatch("leadingLagging/getBodyData", body);

    },
    activitys() {
      let body = {
        company_id: this.company_id,
        date_input: this.body.date_input
      };
      this.direct = "OH_Activity";
      this.$store.dispatch("leadingLagging/getBodyData", body);
    },

    conditionYearly() {
      if (this.company_id == 1 && this.roles_id == 1) {
        return true
      }
    }
  }
};
</script>
