import { Workbox } from "workbox-window";

let wb;

if ("serviceWorker" in navigator) {
  wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

  // wb.addEventListener("controlling", () => {
  //   window.location.reload();
  // });

  // wb.register();
  navigator.serviceWorker.getRegistrations().then(function(regs) {
    for(let reg of regs) {
      reg.unregister();
    } 
  });
} else {
  wb = null;
}

export default wb;