import queryString from "query-string";

import {
    GET_COMPANY_DATA,
    ADD_COMPANY,
    DEL_COMPANY,
    UPDATE_COMPANY
} from "@/api/compny/company";

const state = {
    dataCompany: [],
};

const mutations = {
    setCompany: (states, payload) => {
        states.dataCompany = payload;
    },
};

const actions = {
    getCompany: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_COMPANY_DATA(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setCompany", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setCompany", {
                        setCompany: [],
                    });
                }
            });
    },

    addCompany: async ({ }, args) => {
        return await ADD_COMPANY(args);
    },

    delCompany: async ({ }, id) => {
        return await DEL_COMPANY(id);
    },

    updateCompany: async ({ }, args) => {
        return await UPDATE_COMPANY(args);
    },



};

const getters = {
    CompanyData: (states) => states.dataCompany
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};