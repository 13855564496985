<template>
  <!-- HEADER -->
  <div class="row align-items-center justify-content-between">
    <div class="cart">
      <!-- Link -->
      <div class="card-header">
        <div>
          <h4></h4>
        </div>
        <div style="margin-left: 7px">
          <b-button v-b-modal.modal-4 variant="primary" @click="isAdd = true">
            <span class="fe fe-plus"></span>
            {{ $t("Safety") }}
          </b-button>
        </div>
        <b-button
          v-b-toggle.collapse-1
          variant="primary"
          style="margin-left: 7px">
          <span class="fe fe-sliders"></span>
        </b-button>
      </div>
      <!-- <b-collapse id="collapse-1">
                <form @submit.prevent="sorting()">
                    <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
                        <div class="col">
                            <div class="form-group">
                                <label for="exampleInputEmail1" class="form-label">Company</label>
                                <v-select :options="[
                    { label: 'UGEMS', id: 'UGEMS' },
                    { label: 'Februari', id: 'Februari' },
                    { label: 'Maret', id: 'Maret' },
                        ]" />
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="exampleInputEmail1" class="form-label">Monthly</label>
                                <v-select :options="[
                    { label: 'Januari', id: 'Januari' },
                    { label: 'Februari', id: 'Februari' },
                    { label: 'Maret', id: 'Maret' },
                        ]" />
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3" style="margin-right: 15px">
                        <div class="col"></div>
                        <div class="col-auto">
                            <button type="submit" class="btn btn-danger lift">Filter</button>
                        </div>
                    </div>
                </form>
            </b-collapse> -->
      <vue-good-table
        :line-numbers="true"
        :columns="columns"
        :rows="safety.data"
        :sort-options="{
          enabled: true
        }"
        :total-rows="safety.totalItems"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          position: 'bottom',
          perPage: 10,
          perPageDropdown: [10, 50, 100],
          dropdownAllowAll: false,
          setCurrentPage: 1,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page',
          allLabel: 'All'
        }"
        style-class="vgt-table border-0 table"
        @on-page-change="onPageChange"
        @on-per-page-change="onPageChange">
        <!-- Loading -->
        <div slot="emptystate" class="text-center font-size-14">
          No data available
        </div>
        <!-- Custom Rows -->
        <template slot="table-column" slot-scope="props">
          <!-- Table Action -->
          <span v-if="props.column.label == 'Safety'">
            {{ $t("Safety") }}
          </span>
          <span v-if="props.column.label == 'ORDER'">
            {{ $t("ORDER") }}
          </span>
          <span v-if="props.column.label == 'DESCRIPTION'">
            {{ $t("Description") }}
          </span>
          <span v-if="props.column.label == 'TYPE'">
            {{ $t("Type") }}
          </span>
          <span v-if="props.column.label == 'Mandatory'">
            {{ $t("Mandatory") }}
          </span>
          <span v-if="props.column.label == 'ACTION'">
            {{ $t("Action") }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <!-- Table Action -->
          <span v-if="props.column.field == 'action'">
            <b-button
              class="no-wrap btn-pill"
              variant="warning"
              size="sm"
              @click="updateData(props.row)">
              <span class="fe fe-edit"></span>
            </b-button>
            <b-button
              class="no-wrap ml-2"
              variant="danger"
              style="margin-left: 5px"
              size="sm"
              @click="deleteData(props.row.id)">
              <span class="fe fe-trash-2"></span>
            </b-button>
          </span>
        </template>
      </vue-good-table>
    </div>
    <!-- Body -->
    <!-- Modal -->
    <b-modal id="modal-4" centered hide-footer :title="$t('Chapter Form')">
      <form @submit.prevent="saveAdd()" v-if="isAdd === true">
        <div class="form-group">
          <label>{{ $t("Safety Name") }}</label>
          <input
            type="text"
            v-model="bodyAdd.data_name"
            class="form-control"
            placeholder="Safety"
            v-validate="'required'"
            name="Safety" />
          <span class="text-sm text-danger" v-show="errors.has('Safety')">{{
            errors.first("Safety")
          }}</span>
        </div>
        <div class="form-group">
          <label>Order</label>
          <input
            type="number"
            v-model="bodyAdd.data_order"
            class="form-control"
            placeholder="Order"
            v-validate="'required'"
            name="Order" />
          <span class="text-sm text-danger" v-show="errors.has('Order')">{{
            errors.first("Order")
          }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t("Description") }}</label>
          <textarea
            type="text"
            v-model="bodyAdd.description"
            class="form-control"
            placeholder="Description" />
        </div>
        <div class="form-group">
          <label>{{ $t("Type") }}</label>
          <v-select
            v-model="bodyAdd.type_data"
            :options="['Integer', 'String', 'Text Area']"
            v-validate="'required'"
            name="Type"></v-select>
          <span class="text-sm text-danger" v-show="errors.has('Type')">{{
            errors.first("Type")
          }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t("Mandatory") }}</label>
          <v-select
            v-model="bodyAdd.is_mandatory"
            :options="mandatory"
            :label="label"
            :reduce="(e) => e.code"
            v-validate="'required'"
            name="Mandatory"></v-select>
          <span class="text-sm text-danger" v-show="errors.has('Mandatory')">{{
            errors.first("Mandatory")
          }}</span>
        </div>
        <button
          style="margin-left: auto; float: right"
          class="mt-4 btn btn-primary"
          type="submit">
          {{ $t("Save") }}
        </button>
      </form>

      <form @submit.prevent="saveUpdate()" v-else>
        <div class="form-group">
          <label>{{ $t("Safety Name") }}</label>
          <input
            type="text"
            v-model="bodyUpdate.data_name"
            class="form-control"
            placeholder="Safety"
            v-validate="'required'"
            name="Safety" />
          <span class="text-sm text-danger" v-show="errors.has('Safety')">{{
            errors.first("Safety")
          }}</span>
        </div>
        <div class="form-group">
          <label>Order</label>
          <input
            type="number"
            v-model="bodyUpdate.data_order"
            class="form-control"
            placeholder="Order"
            v-validate="'required'"
            name="Order" />
          <span class="text-sm text-danger" v-show="errors.has('Order')">{{
            errors.first("Order")
          }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t("Description") }}</label>
          <textarea
            type="text"
            v-model="bodyUpdate.description"
            class="form-control"
            placeholder="Description" />
        </div>
        <div class="form-group">
          <label>{{ $t("Type") }}</label>
          <v-select
            v-model="bodyUpdate.type_data"
            :options="['Integer', 'String', 'Text Area']"
            v-validate="'required'"
            name="Type"></v-select>
          <span class="text-sm text-danger" v-show="errors.has('Type')">{{
            errors.first("Type")
          }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t("Mandatory") }}</label>
          <v-select
            v-model="bodyUpdate.is_mandatory"
            :options="mandatory"
            :label="label"
            :reduce="(e) => e.code"
            v-validate="'required'"
            name="Mandatory"></v-select>
          <span class="text-sm text-danger" v-show="errors.has('Mandatory')">{{
            errors.first("Mandatory")
          }}</span>
        </div>
        <button
          style="margin-left: auto; float: right"
          class="mt-4 btn btn-primary"
          type="submit">
          {{ $t("Save") }}
        </button>
      </form>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "defaultCMS",
  computed: {
    safety() {
      return this.$store.getters["yearly/safetyData"]
        ? this.$store.getters["yearly/safetyData"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        limit: 1000,
        order: "",
        search: ""
      },
      bodyAdd: {
        data_name: "",
        data_order: "",
        description: "",
        type_data: "",
        is_mandatory: ""
      },
      bodyUpdate: {
        id: "",
        data_name: "",
        data_order: "",
        description: "",
        type_data: "",
        is_mandatory: ""
      },
      mandatory: [
        {
          label: "YES",
          code: true
        },
        {
          label: "NO",
          code: false
        }
      ],
      isAdd: true,
      columns: [
        {
          label: "Safety",
          field: "data_name",
          thClass: "table-header text-muted w-AUTO font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "ORDER",
          field: "data_order",
          thClass: "table-header text-muted w-AUTO font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "DESCRIPTION",
          field: "description",
          thClass: "table-header text-muted w-AUTO font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "TYPE",
          field: "type_data",
          thClass: "table-header text-muted w-AUTO font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "Mandatory",
          field: "is_mandatory",
          thClass: "table-header text-muted w-AUTO font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ]
    };
  },
  mounted() {
    this.getSafety();
  },
  methods: {
    //action
    updateData(props) {
      this.$bvModal.show("modal-4");
      this.isAdd = false;

      this.bodyUpdate.id = props.id;
      this.bodyUpdate.data_name = props.data_name;
      this.bodyUpdate.data_order = props.data_order;
      this.bodyUpdate.description = props.description;
      this.bodyUpdate.type_data = props.type_data;
      this.bodyUpdate.is_mandatory = props.is_mandatory;
    },
    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.$store
            .dispatch("yearly/addSafety", this.bodyAdd)
            .then((resp) => {
              this.$swal({
                title: this.$t("Saved"),
                text: this.$t("Your input has been saved"),
                icon: "success",
                confirmButtonText: this.$t("OK")
              }).then((resp) => {
                this.bodyAdd.data_name = "";
                this.bodyAdd.data_order = "";
                this.bodyAdd.description = "";
                this.bodyAdd.type_data = "";
                this.bodyAdd.is_mandatory = "";
                this.getSafety();
                this.$bvModal.hide("modal-4");
              });
            });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
        }
      });
    },

    saveUpdate() {
      this.$store
        .dispatch("yearly/updateSafety", this.bodyUpdate)
        .then((resp) => {
          this.$swal({
            title: this.$t("Saved"),
            text: this.$t("Your input has been saved"),
            icon: "success",
            confirmButtonText: this.$t("OK")
          }).then((resp) => {
            this.getSafety();
            this.$bvModal.hide("modal-4");
          });
        })
        .catch((resp) => {
          this.$swal("Error", this.$t("Error while edit data"), "error");
        });
    },

    deleteData(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("yearly/delSafety", id)
            .then((resp) => {
              this.getSafety();
              this.$swal(
                "Deleted!",
                this.$t("Your file has been deleted"),
                "success"
              );
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    },

    // api hit
    getSafety() {
      this.$store.dispatch("yearly/getSafety", this.body);
    },

    // filter
    filterPage() {
      this.getChapter();
    },

    sorting() {
      this.getChapter();
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;
      // if (
      //   parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
      //   1000
      // ) {
      this.getSafety();
      // }
    }
  }
};
</script>
