import axiosIns from '@/api/axios'

export const GET_FACILITY_DATA = (args) => {
    return axiosIns.get(`/medical/facility?${args}`)
}

export const ADD_FACILITY = (args) => {
    return axiosIns.post(`/medical/facility/create`, args)
}

export const DEL_FACILITY = (id) => {
    return axiosIns.put(`/medical/facility/delete?id=${id}`)
}

export const UPDATE_FACILITY  = (args) => {
    return axiosIns.put(`/medical/facility/update?id=${args.id}`, args)
}