<template>
  <!-- HEADER -->
  <div class="header">
    <!-- Body -->
    <div :class="`cart ${load === true ? '' : 'd-none'}`">
      <div class="card-body text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div :class="`${load === true ? 'd-none' : ''}`" v-if="direct === 'Rate'">
      <div class="card-header d-none">
        <!-- Title -->
        <h4 class="card-header-title"></h4>
        <!-- Button -->
        <b-button
          v-b-toggle.collapse-1
          variant="primary"
          style="margin-left: 7px">
          <span class="fe fe-sliders"></span>
        </b-button>
      </div>
      <div style="overflow: auto">
        <table class="table table-sm table-nowrap table-bordered oh-table tableFixHead">
          <thead>
            <tr>
              <td scope="col" rowspan="2">
                {{ $t("Occupational Health Indicators") }}
              </td>
              <th scope="col" rowspan="2">Treshold</th>
              <th colspan="12" style="text-align: center">{{ $t("Month") }}</th>
              <th scope="col" rowspan="2" class="text-center">
                {{ $t("Total") }}
              </th>
            </tr>
            <tr>
              <th v-for="(i, index) in mothly" :key="index" scope="col">
                {{ i }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(label, index) in indicator" :key="index">
              <th scope="row" style="text-align: left">
                {{ label.label }}
                <span
                  v-if="label.description != null"
                  :title="label.description"
                  class="fe fe-alert-circle"></span>
              </th>
              <td scope="row">{{ label.treshold }}</td>
              <td
                class="text-center"
                v-for="(i, index) in lastmonth"
                :key="index">
                {{
                  acumalaka(i)
                    ? convertNumber(revealValue(i, label.values)) === ""
                      ? dataNol(index)
                      : convertNumber(revealValue(i, label.values))
                    : ""
                }}
              </td>
              <td class="text-center" style="color: black">
                {{ label.total }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "indicatorRate",
  computed: {
    indicator() {
      return this.$store.getters["leadingLagging/indicatorRate"]
        ? this.$store.getters["leadingLagging/indicatorRate"]
        : [];
    },
    bodyDateCompany() {
      return this.$store.getters["leadingLagging/body_data"]
        ? this.$store.getters["leadingLagging/body_data"]
        : [];
    }
  },
  data() {
    return {
      bodyData: {
        date_input: moment().format("YYYY"),
        company_id: ""
      },
      mothly: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MEI",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC"
      ],
      thisMonth: moment().format("M"),
      thisYear: moment().format("YYYY"),
      direct: "Rate",
      value: [],
      totalData: [],
      load: true
    };
  },
  mounted() {
    this.$root.$on("indicator", (body) => {
      this.bodyData.company_id = body.company_id;
      this.bodyData.date_input = body.date_input;
      this.getIndicator();
    });
    const lastmonth = moment().endOf("year").format("M");
    this.lastmonth = Number(lastmonth);
    if (this.bodyDateCompany.length == undefined) {
      this.bodyData.company_id = this.bodyDateCompany.company_id;
      this.bodyData.date_input = this.bodyDateCompany.date_input;
    }
    this.getIndicator();
  },
  methods: {
     acumalaka(i){
      if(this.bodyData.date_input != this.thisYear){
        return true
      }else{
        if(i <= this.thisMonth){
          return true
        }else{
          return false
        }
      }
    },
    revealValue(monthly, values) {
      const index = values.findIndex((e) => e.month === monthly);
      if (index > -1) {
        return values[index]["value"];
      } else {
        return "";
      }
    },
    dataNol(index) {
      const dayNow = moment().format("MM");
      if (index < dayNow) {
        return 0;
      }
    },

    // check(val){
    //   for (let a = 0; a < val.length; a++) {
    //     this.value = val[a];
    //
    //   }
    //   if (this.value.label.includes("Man Power") && this.value.label.includes("Man Hours")) {
    //       return val
    //     } else {
    //       this.$swal("Error", this.$t('Error while add data'), "error");
    //     }
    // },

    // api hit
    getIndicator() {
      this.load = true;
      this.$store
        .dispatch("leadingLagging/getIndicator", this.bodyData)
        .then((resps) => {
          const resp = this.$store.getters["leadingLagging/errorIndicator"];
          this.load = false;
          const datas = resp?.data;
          if (datas?.hasOwnProperty("error")) {
            this.$swal(`Error ${resp.data.httpCode}`, resp.data.error, "error");
            this.load = false;
          } else {
            this.load = false;
          }
          this.load = false;
        });
    },

    findTotalData(label, treshold) {
      const data = this.indicator;
      let obj = data.find((o) => {
        return o.treshold == treshold && o.label == label;
      });
      let datas = obj.values.map((item) => {
        return item.value;
      });
      var dat = datas.reduce((total, value) => total + value);
      return Math.ceil(dat);
    },

    // filter
    filterPage() {
      this.getIndicator();
    },

    sorting() {
      this.getIndicator();
    },
    convertNumber(num) {
      if (num) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return "";
      }
    }
  }
};
</script>
