import queryString from "query-string";

import {
    GET_INDICATOR_DATA,
    ADD_INDICATOR,
    DEL_INDICATOR,
    UPDATE_INDICATOR
} from "@/api/oh/indicator";

const state = {
    dataIndicator: [],
};

const mutations = {
    setIndicator: (states, payload) => {
        states.dataIndicator = payload;
    },
};

const actions = {
    getIndicator: async ({
        commit
    }, args) => {
        if (args.data && args.data.length > 0) {
            return args.data;
        } else {
            const params = queryString.stringify(args);
            return await GET_INDICATOR_DATA(params)
                .then((resp) => {

                    if (resp.data.httpCode === 200) {
                        commit("setIndicator", resp.data.data);
                    }
                })
                .catch((err) => {
                    let resp = err.response;
                    console.log(err);
                    if (resp.data.httpCode === 404) {
                        commit("setIndicator", {
                            setIndicator: [],
                        });
                    }
                });
        }
    },

    addIndicator: async ({ }, args) => {
        return await ADD_INDICATOR(args);
    },

    delIndicator: async ({ }, id) => {
        return await DEL_INDICATOR(id);
    },

    updateIndicator: async ({ }, args) => {
        return await UPDATE_INDICATOR(args);
    },



};

const getters = {
    IndicatorData: (states) => states.dataIndicator
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};