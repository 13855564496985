<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-4">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item">Inventory</li>
                <li class="breadcrumb-item">Summary Stock</li>
                <li class="breadcrumb-item">History</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div class="row align-items-center justify-content-between">
        <div class="cart">
          <div class="card">
            <div class="card-header">
              <!-- Title -->
              <h5 class="card-header-title">{{ details.item_name }}</h5>
            </div>
            <!-- Link -->
            <vue-good-table
              :line-numbers="true"
              :columns="columns"
              :rows="details.row"
              :total-rows="details.totalRecords"
              :sort-options="{
                enabled: true
              }"
              :pagination-options="{
                enabled: true,
                mode: 'pages',
                position: 'bottom',
                perPage: 10,
                perPageDropdown: [10, 50, 100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: 'All'
              }"
              style-class="vgt-table border-0 table"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange">
              <!-- Loading -->
              <div slot="emptystate" class="text-center font-size-14">
                No data available
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <b-button
                    class="no-wrap btn-pill ml-2"
                    variant="info"
                    size="sm"
                    @click.prevent="seeStock()">
                    <span class="fe fe-file-text"></span>
                  </b-button>
                </span>
              </template>

              <!-- Custom Rows -->
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "drugs",
  computed: {
    details() {
      return this.$store.getters["reporting/mutationData"]
        ? this.$store.getters["reporting/mutationData"]
        : [];
    }
  },
  data() {
    return {
      lol: "we",
      body: {
        id: "",
        page: 1,
        perPage: 10,
        searchQuery: "",
        sort: ""
      },
      columns: [
        {
          label: "Time",
          field: "trans_time",
          thClass: `table-header no-wrap w-auto text-center text-muted font-weight-bold`,
          tdClass: "no-wrap color-secondary text-center align-middle"
        },
        {
          label: "Description",
          field: "trans_id",
          thClass:
            "table-header no-wrap w-auto text-center text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle"
        },
        {
          label: "Category",
          field: "trans_category",
          thClass:
            "table-header no-wrap text-center w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle"
        },
        {
          label: "EXP DATE",
          field: "expiry_date",
          thClass:
            "table-header no-wrap text-center w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle"
        },
        {
          label: "Stock In",
          field: "in_label",
          thClass:
            "table-header no-wrap w-auto text-center text-muted font-weight-bold",
          tdClass:
            "no-wrap color-secondary bg-success-soft text-center align-middle"
        },
        {
          label: "Stock Out",
          field: "out_label",
          thClass:
            "table-header no-wrap w-auto text-center text-muted font-weight-bold",
          tdClass:
            "no-wrap color-secondary text-center bg-primary-soft align-middle"
        },
        {
          label: "Remaining Stock",
          field: "stock_label",
          thClass:
            "table-header no-wrap w-auto text-center text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle"
        },
        {
          label: "Action",
          field: "action",
          thClass:
            "table-header no-wrap w-auto text-center text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle"
        }
      ],
      rows: [
        {
          time: "20-1-2023 13:45:23",
          desc: "Pembelian PO #123",
          category: "Pembelian",
          stock_in: "100",
          stock_out: "-",
          avaible_stock: "100"
        },
        {
          time: "1-2-2023 19:15:43",
          desc: "Resep Dokter  #123",
          category: "Pengeluaran",
          stock_in: "-",
          stock_out: "10",
          avaible_stock: "90"
        },
        {
          time: "7-2-2023 16:25:36",
          desc: "Koreksi Stock (Expired)",
          category: "Pengeluaran",
          stock_in: "-",
          stock_out: "7",
          avaible_stock: "83"
        }
      ]
    };
  },
  mounted() {
    this.getDetail();
  },

  methods: {
    tdFunc(rows) {
      const now = moment();
      const exp_date = moment(rows.exp_date, "DD-MM-YYYY");

      //     rows.exp_date,
      //     now.format("YYYY/MM/DD HH:mm:ss"),
      //     exp_date.format("YYYY/MM/DD HH:mm:ss")
      // );
      const diff = exp_date.diff(now, "days");

      if (diff < 0) {
        return "bg-danger-soft";
      } else if (diff > 8) {
        return "bg-info-soft";
      } else {
        return "bg-warning-soft";
      }
    },
    nows(props) {
      const time = moment(props, "DD-MM-YYYY, h:mm:ss");
      const formated = moment(time).format("D MMMM YYYY, H:mm");
      return formated;
    },
    formated(val) {
      return val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    getDetail() {
      this.body.id = this.$router.currentRoute.query.item_id;
      this.$store.dispatch("reporting/getMutation", this.body);
    },

    seeStock(id) {
      this.$router.push(
        `/clinic-management/inventory/drugs-&-medical/stock-detail?item_id=${this.body.id}`
      );
    },

    onPageChange(payload) {
      this.body.perPage = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getDetail();
    }
  }
};
</script>
