import axiosIns from '@/api/axios'

export const GET_CATEGORY_DATA = (args) => {
    return axiosIns.get(`/mcu/test/category?${args}`)
}

export const ADD_CATEGORY = (args) => {
    return axiosIns.post(`/mcu/test/category/create`, args)
}

export const DEL_CATEGORY = (id) => {
    return axiosIns.put(`/mcu/test/category/delete?id=${id}`)
}

export const UPDATE_CATEGORY  = (args) => {
    return axiosIns.put(`/mcu/test/category/update?id=${args.id}`, args)
}