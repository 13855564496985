import queryString from "query-string";

import {
    GET_ROLE_DATA,
    ADD_ROLE,
    DEL_ROLE,
    UPDATE_ROLE
} from "@/api/role/role";

const state = {
    dataRole: [],
};

const mutations = {
    setRole: (states, payload) => {
        states.dataRole = payload;
    },
   
};

const actions = {
    getRole: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_ROLE_DATA(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    commit("setRole", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                if (resp.data.httpCode === 404) {
                    commit("setRole", {
                        setRole: [],
                    });
                }
            });
    },

    addRole: async ({}, args) => {
        return await ADD_ROLE(args);
    },

    delRole: async ({}, id) => {
        return await DEL_ROLE(id);
    },

    updateRole: async ({}, args) => {
        return await UPDATE_ROLE(args);
    },



};

const getters = {
    RoleData: (states) => states.dataRole,
    
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};