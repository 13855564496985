var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center justify-content-between"},[_c('div',{staticClass:"card p-0"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v("Medical")]),_c('div',{staticStyle:{"margin-left":"15px"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-1",modifiers:{"collapse-1":true}}],staticClass:"ml-2",attrs:{"variant":"primary"}},[_c('span',{staticClass:"fe fe-sliders"})])],1),_c('div',{staticStyle:{"margin-right":"15px"}})]),_c('vue-good-table',{attrs:{"line-numbers":true,"columns":_vm.columns,"rows":_vm.dataMedical.data,"sort-options":{
        enabled: true,
      },"pagination-options":{
        enabled: true,
        mode: 'pages',
        position: 'bottom',
        perPage: 10,
        perPageDropdown: [10, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        allLabel: 'All',
      },"style-class":"vgt-table border-0 table"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('div',[_c('b-button',{staticClass:"no-wrap btn-pill",attrs:{"variant":"info","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.viewRecord(props.row)}}},[_c('span',{staticClass:"fe fe-eye"})])],1)]):_vm._e()]}}])},[_c('div',{staticClass:"text-center font-size-14",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" No data available ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }