import axiosIns from '@/api/axios'

// Competency
export const GET_COMPETENCY = (args) => {
    return axiosIns.get(`/oh/yearly/competency?${args}`)
}

export const ADD_COMPETENCY = (args) => {
    return axiosIns.post(`/oh/yearly/competency/create`, args)
}

export const DEL_COMPETENCY = (id) => {
    return axiosIns.put(`/oh/yearly/competency/delete?id=${id}`)
}

export const UPDATE_COMPETENCY  = (args) => {
    return axiosIns.put(`/oh/yearly/competency/update?id=${args.id}`, args)
}

// Health
export const GET_HEALTH = (args) => {
    return axiosIns.get(`/oh/yearly/health?${args}`)
}

export const ADD_HEALTH = (args) => {
    return axiosIns.post(`/oh/yearly/health/create`, args)
}

export const DEL_HEALTH = (id) => {
    return axiosIns.put(`/oh/yearly/health/delete?id=${id}`)
}

export const UPDATE_HEALTH  = (args) => {
    return axiosIns.put(`/oh/yearly/health/update?id=${args.id}`, args)
}

// Safety
export const GET_SAFETY = (args) => {
    return axiosIns.get(`/oh/yearly/safety?${args}`)
}

export const ADD_SAFETY = (args) => {
    return axiosIns.post(`/oh/yearly/safety/create`, args)
}

export const DEL_SAFETY = (id) => {
    return axiosIns.put(`/oh/yearly/safety/delete?id=${id}`)
}

export const UPDATE_SAFETY  = (args) => {
    return axiosIns.put(`/oh/yearly/safety/update?id=${args.id}`, args)
}


export const ADD_YEARLY = (args) => {
    return axiosIns.post(`oh/management/create`, args)
}
