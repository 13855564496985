<template>
  <div class="row align-items-center justify-content-between">
    <div class="cart">
      <div class="card-body">
        <div class="d-flex justify-content-end mb-3">
          <label class="align-self-center mb-0 mr-3">Search</label>
          <b-form-input
            class="w-auto"
            v-model="body.searchQuery"
            placeholder="Type Here..."
            @change="getDrugsList()"></b-form-input>
        </div>

        <vue-good-table
          mode="remote"
          :line-numbers="true"
          :columns="columns"
          :rows="dataDrugs.rows"
          :total-rows="dataDrugs.totalRecords"
          :sort-options="{
            enabled: true,
            initialSortBy: { field: 'item_label', type: 'asc' }
          }"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: body.perPage,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }"
          style-class="vgt-table table table-bordered"
          @on-sort-change="onSortChange"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange"
          :isLoading.sync="isLoading">
          <!-- Loading -->

          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'action'">
              <b-button
                class="no-wrap btn-pill ml-2"
                variant="success"
                size="sm"
                title="Detail Drug"
                @click.prevent="seeDetail(props.row.item_id)">
                <span class="fe fe-info"></span>
              </b-button>
              <b-button
                class="no-wrap ml-2"
                variant="info"
                style="margin-left: 5px"
                size="sm"
                title="Detail Stock"
                @click="seeStock(props.row.item_id)">
                <span class="fe fe-file-text"></span>
              </b-button>
              <b-button
                class="no-wrap btn-pill ml-2"
                variant="warning"
                size="sm"
                title="Mutation"
                @click.prevent="seeMutation(props.row.item_id)">
                <span class="fe fe-git-branch"></span>
              </b-button>
            </span>
            <div v-else-if="props.column.field === 'exp_date'">
              <!-- <div :class="tdFunc(props.row)">
							{{ props.row.exp_date }}
						</div> -->
              <span
                :class="`badge ${tdFunc(props.row)}`"
                style="font-size: 100% !important; padding: 0.33em 2em"
                >{{ nows(props.row.exp_date) }}</span
              >
            </div>
          </template>
        </vue-good-table>
      </div>
      <!-- Link -->
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "drugs",
  computed: {
    dataDrugs() {
      return this.$store.getters["reporting/listDrugsData"]
        ? this.$store.getters["reporting/listDrugsData"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        perPage: 10,
        searchQuery: "",
        sort: "",
        item_id: "",
        type: "DISPOSABLE-ITEM"
      },
      isLoading: false,
      columns: [
        // {
        // 	label: "Barcode",
        // 	field: "barcode",
        // 	thClass: `table-header no-wrap w-auto text-center text-muted font-weight-bold`,
        // 	tdClass: "no-wrap color-secondary text-center align-middle",
        // },
        {
          label: "DRUG Name",
          field: "item_label",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "UNIT",
          field: "uom_label",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Stock",
          field: "qty",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle"
        },
        {
          label: "EXP DATE",
          field: "expiry_date",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass:
            "table-header no-wrap text-muted text-center font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle",
          sortable: false
        }
      ],
      rows: [
        {
          barcode: 117213,
          drug_name: "Panadol",
          unit: "TAB",
          stock: "27",
          exp_date: "28-01-2023"
        },
        {
          barcode: 782912,
          drug_name: "Decolgen",
          unit: "TAB",
          stock: "21",
          exp_date: "06-02-2023"
        },
        {
          barcode: 981602,
          drug_name: "Bodrex",
          unit: "TAB",
          stock: "42",
          exp_date: "22-03-2023"
        }
      ]
    };
  },
  mounted() {
    this.getDrugsList();
  },

  methods: {
    tdFunc(rows) {
      const now = moment();
      const exp_date = moment(rows.exp_date, "DD-MM-YYYY");

      // 	rows.exp_date,
      // 	now.format("YYYY/MM/DD HH:mm:ss"),
      // 	exp_date.format("YYYY/MM/DD HH:mm:ss")
      // );
      const diff = exp_date.diff(now, "days");

      if (diff < 0) {
        return "bg-danger-soft";
      } else if (diff > 8) {
        return "bg-info-soft";
      } else {
        return "bg-warning-soft";
      }
    },
    nows(props) {
      const exp_date = moment(props, "DD-MM-YYYY");
      const formated = moment(exp_date).format("ddd, MMMM Do YYYY");
      return formated;
    },

    seeDetail(id) {
      this.$router.push(
        `/clinic-management/inventory/drugs-&-medical/drugs-detail?item_id=${id}`
      );
    },
    seeStock(id) {
      this.$router.push(
        `/clinic-management/inventory/drugs-&-medical/stock-detail?item_id=${id}`
      );
    },
    seeMutation(id) {
      this.$router.push(
        `/clinic-management/inventory/drugs-&-medical/mutation?item_id=${id}`
      );
    },

    onSortChange(params) {
      let sort1 = params[0].field;
      let sort2 = params[0].type;
      this.body.sort = `${sort1},${sort2}`;
      this.getDrugsList();
    },
    onPageChange(payload) {
      this.body.perPage = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getDrugsList();
      // }
    },

    async getDrugsList() {
      this.isLoading = true;
      this.$store.dispatch("reporting/getListDrugs", this.body).finally(() => {
        this.isLoading = false;
        let count = 0;
        for (let i = 0; i < this.dataDrugs.rows.length; i++) {
          if (this.dataDrugs.rows[i].base_qty < 3) {
            count += 1;
          }
        }

        if (count > 0) {
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            })
            .fire({
              icon: "warning",
              title: "There is an almost empty stock"
            });
        }
      });
    }
  }
};
</script>
