import axiosIns from '@/api/axios'

export const GET_OH_DATA = (args) => {
    return axiosIns.get(`/oh?${args}`)
}

export const ADD_OH = (args) => {
    return axiosIns.post(`/oh/create`, args)
}

export const DEL_OH = (id) => {
    return axiosIns.put(`/oh/delete?id=${id}`)
}

export const UPDATE_OH  = (args) => {
    return axiosIns.put(`/oh/update?id=${args.id}`, args)
}