import axiosIns from '@/api/axios'

export const GET_HEALTHCARE_DATA = (args) => {
    return axiosIns.get(`/healthcare/facility?${args}`)
}
export const GET_FACILITY_FEATURE = (args) => {
    return axiosIns.get(`/healthcare/facility/feature?${args}`)
}

export const ADD_HEALTHCARE = (args) => {
    return axiosIns.post(`/healthcare/facility/create`, args)
}

export const DEL_HEALTHCARE = (id) => {
    return axiosIns.put(`/healthcare/facility/delete?id=${id}`)
}

export const UPDATE_HEALTHCARE  = (args) => {
    return axiosIns.put(`/healthcare/facility/update?id=${args.healthcare_facility_id}`, args)
}