import axiosIns from '@/api/axios'

export const GET_ACTIVITY_DATA = (args) => {
    return axiosIns.get(`/oh/activity?${args}`)
}

export const ADD_ACTIVITY = (args) => {
    return axiosIns.post(`/oh/activity/create`, args)
}

export const DEL_ACTIVITY = (id) => {
    return axiosIns.put(`/oh/activity/delete?id=${id}`)
}

export const UPDATE_ACTIVITY  = (args) => {
    return axiosIns.put(`/oh/activity/update?id=${args.id}`, args)
}