import queryString from "query-string";

import {
    GET_BLOCK_DATA,
    ADD_BLOCK,
    DEL_BLOCK,
    UPDATE_BLOCK
} from "@/api/disease/block";

const state = {
    dataBlock: [],
};

const mutations = {
    setBlock: (states, payload) => {
        states.dataBlock = payload;
    },
};

const actions = {
    getBlock: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_BLOCK_DATA(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setBlock", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setBlock", {
                        setBlock: [],
                    });
                }
            });
    },

    addBlock: async ({ }, args) => {
        return await ADD_BLOCK(args);
    },

    delBlock: async ({ }, id) => {
        return await DEL_BLOCK(id);
    },

    updateBlock: async ({ }, args) => {
        return await UPDATE_BLOCK(args);
    },



};

const getters = {
    BlockData: (states) => states.dataBlock
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};