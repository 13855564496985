import axiosIns from '@/api/axios'

export const GET_CATEGORY_DATA = (args) => {
    return axiosIns.get(`/items/category?${args}`)
}

export const ADD_CATEGORY = (args) => {
    return axiosIns.post(`/items/category`, args)
}

export const DEL_CATEGORY = (id) => {
    return axiosIns.delete(`/items/category?id=${id}`)
}

export const UPDATE_CATEGORY  = (args) => {
    return axiosIns.put(`/items/category?id=${args.id}`, args)
}