import axiosIns from '@/api/axios'

export const GET_DATA_HEALTHCARE_FACILITY = (args) => {
	return axiosIns.get(`/healthcare/facility`);
};

export const GET_DATA_COMPANY = (args) => {
	return axiosIns.get(`/company`);
};

export const GET_DATA_STAFF_ROLES = (args) => {
	return axiosIns.get(`/medical/staff/position`);
};

export const GET_DATA_ROLES = (args) => {
	return axiosIns.get(`/roles`);
};

export const GET_DATA_USER = (args) => {
	return axiosIns.get(`/users/search?${args}`);
};

export const GET_ROLE_MANAGEMENT_DATA = (args) => {
    return axiosIns.get(`/roles/user?${args}`)
}

export const ADD_ROLE_MANAGEMENT = (args) => {
    return axiosIns.post(`/roles/user/create`, args)
}

export const UPDATE_ROLE_MANAGEMENT = (args) => {
    return axiosIns.put(`/roles/user/update?nik=${args.nik_old}`, args)
}

export const DELETE_ROLE_MANAGEMENT = (nik) => {
    return axiosIns.delete(`/roles/user/delete?${nik}`)
}