<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-6">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("OH Management") }}
                </li>
                <li class="breadcrumb-item">Partners Correction</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div class="card mt-3">
        <Daily is_admin="true" />
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Daily from "../../../components/input/Daily.vue";

export default {
  name: "Correction",
  components: {
    Daily
  },
  data() {
    return {
      direct: "Daily",
      listYear: [],
      company: "",
    };
  },
  methods: {
    renderYear() {},
  },
  mounted() {
    this.company = localStorage.getItem("company_name");
    let year = moment().format("YYYY");
    for (let i = parseInt(year) - 2; i < parseInt(year) + 3; i++) {
      this.listYear.push(i);
    }
  },
};
</script>