import axiosIns from '@/api/axios'

export const GET_MEASURING_UNIT_DATA = (args) => {
    return axiosIns.get(`/ih/measuring/unit?${args}`)
}

export const ADD_MEASURING_UNIT = (args) => {
    return axiosIns.post(`/ih/measuring/unit/create`, args)
}

export const DEL_MEASURING_UNIT = (id) => {
    return axiosIns.put(`/ih/measuring/unit/delete?id=${id}`)
}

export const UPDATE_MEASURING_UNIT  = (args) => {
    return axiosIns.put(`/ih/measuring/unit/update?id=${args.id}`, args)
}