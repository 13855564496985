<template>
  <!-- HEADER -->
  <div class="header">
    <div class="card">
      <div class="card-header">
        <!-- Title -->
        <h4 class="card-header-title">{{ $t("Riwayat Alergi") }}</h4>
        <!-- Button -->
        <b-button
          v-b-toggle.collapse-1
          variant="primary"
          style="margin-left: 7px"
        >
          <span class="fe fe-sliders"></span>
        </b-button>
      </div>
      <b-collapse id="collapse-1">
        <form @submit.prevent="sorting()">
          <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
            <div class="col">
              <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">{{
                  $t("NAME")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="body.hazard_name"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">Order</label>
                <v-select v-model="body.order" :options="['ASC', 'DESC']" />
              </div>
            </div>
          </div>

          <div class="row mb-3" style="margin-right: 15px">
            <div class="col"></div>
            <div class="col-auto">
              <!-- Button -->
              <button type="submit" class="btn btn-danger lift">
                {{ $t("Filter") }}
              </button>
            </div>
          </div>
          <!-- / .row -->
        </form>
      </b-collapse>
      <vue-good-table
        :line-numbers="true"
        :columns="columns"
        :rows="chemical.data"
        :sort-options="{
          enabled: true,
        }"
        :total-rows="chemical.totalItems"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          position: 'bottom',
          perPage: 10,
          perPageDropdown: [10, 50, 100],
          dropdownAllowAll: false,
          setCurrentPage: 1,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page',
          allLabel: 'All',
        }"
        style-class="vgt-table border-0 table"
        @on-page-change="onPageChange"
        @on-per-page-change="onPageChange"
      >
        <!-- Loading -->
        <div slot="emptystate" class="text-center font-size-14">
          No data available
        </div>
        <!-- Custom Rows -->
        <template slot="table-row" slot-scope="props">
          <!-- Table Action -->
          <span v-if="props.column.field == 'action'">
            <b-button
              class="no-wrap btn-eye"
              variant="info"
              size="sm"
              @click="$router.push(`/clinic-mcu/medical-record`)"
            >
              <span class="fe fe-eye"></span>
            </b-button>
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "chemicalHazard",
  computed: {
    chemical() {
      return this.$store.getters["chemical/ChemicalData"]
        ? this.$store.getters["chemical/ChemicalData"]
        : [];
    },
  },
  data() {
    return {
      body: {
        page: 1,
        limit: 10,
        order: "",
        // hazard_name: ''
      },
      hazard_name: "",
      hazard_names: "",
      id: "",
      isAdd: true,
      isLoading: false,
      columns: [
        {
          label: "DATE",
          field: "hazard_name",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "LAB NAME",
          field: "hazard_name",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "NOREG",
          field: "hazard_name",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "TYPE MCU",
          field: "hazard_name",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
      ],
    };
  },
  mounted() {
    this.getChemical();
  },
  methods: {
    // api hit
    getChemical() {
      this.$store.dispatch("chemical/getChemical", this.body);
    },

    // filter
    filterPage() {
      this.getChemical();
    },

    sorting() {
      this.getChemical();
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getChemical();
    },
  },
};
</script>
