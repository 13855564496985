import {
    ADD_DATA,
    GET_ALL_DATA,
    UPDATE_DATA,
    DELETE_DATA,
} from "@/api/notification/index";
import querystring from "query-string";

const state = {
    list: [],
    detail: {},
};

const mutations = {
    SET_LIST: (state, payload) => {
        state.list = payload;
    },
};

const actions = {
    GET_LIST: async ({ commit }, params) => {
        params = querystring.stringify(params);
        const resp = await GET_ALL_DATA(params);
        let data = [];
        console.log('resp.data.length', resp.data.data.data.rows.length)
        for (let index = 0; index < resp.data.data.data.rows.length; index++) {
            data.push({
                ...resp.data.data.data.rows[index],
                email: false,
                whatsapp: false,
            })
        }
        let resData = {
            data: data,
            totalItems: resp.data.data.totalItems,
            page: resp.data.data.page,
            limit: resp.data.data.limit,
            totalPages: resp.data.data.totalPages,
        }
        console.log(resData)
        // const data = resp.data.data;
        commit("SET_LIST", resData);
    },

    CREATE_DATA: async ({ commit }, args) => {
        return await ADD_DATA(args);
    },
    UPDATE_DATA: async ({ }, args) => {
        return await UPDATE_DATA(args);
    },

    DELETE_DATA: async ({ }, id) => {
        return await DELETE_DATA(id);
    },
};

const getters = {
    list: (state) => state.list,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
