<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-6">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("OH Management") }}
                </li>
                <li class="breadcrumb-item">Report</li>
                <li class="breadcrumb-item">Recap Daily Input</li>
              </ol>
            </nav>
          </div>
        </div>

        <div class="card mt-5">
          <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">Recap Daily Input</h4>
            <div>
              <b-form-input
                id="date_input"
                type="date"
                v-model="param.date_input"
                @change="getRecap()"></b-form-input>
            </div>
          </div>

          <div class="cart">
            <div class="card-body">
              <div class="d-flex justify-content-end mb-3">
                <div class="d-flex">
                  <label class="align-self-center mb-0 mr-3">Search</label>
                  <b-form-input
                    class="w-auto"
                    v-model="param.searchQuery"
                    placeholder="Type Here..."
                    @change="getRecap()"></b-form-input>
                </div>
              </div>
              <vue-good-table
                mode="remote"
                :line-numbers="false"
                :columns="columns"
                :rows="recap.rows"
                :total-rows="recap.totalRecords"
                :sort-options="{
                  enabled: true
                }"
                :pagination-options="{
                  enabled: true,
                  mode: 'pages',
                  position: 'bottom',
                  perPage: 10,
                  perPageDropdown: [5, 10, 20, 50, 100],
                  dropdownAllowAll: false,
                  setCurrentPage: 1,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'page',
                  allLabel: 'All'
                }"
                :group-options="{
                  enabled: true,
                  collapsable: true
                }"
                style-class="vgt-table table table-bordered"
                @on-sort-change="onSortChange"
                @on-page-change="onPageChange"
                @on-per-page-change="onPageChange"
                :isLoading.sync="isLoading">
                <!-- Loading -->
                <div slot="emptystate" class="text-center font-size-14">
                  No data available
                </div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'timelog'">
                    {{ timelog(props.row.timelog) }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "RecapDaily",
  computed: {
    recap() {
      return this.$store.getters["recapDaily/recapData"]
        ? this.$store.getters["recapDaily/recapData"]
        : [];
    }
  },
  data() {
    return {
      param: {
        searchQuery: "",
        sort: "",
        page: 1,
        perPage: 10,
        date_input: ""
      },
      isLoading: false,
      columns: [
        {
          label: "Name",
          field: "name",
          thClass: "table-header text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "Value",
          field: "value",
          thClass: "table-header text-muted font-weight-bold",
          tdClass: "color-secondary align-middle text-right"
        },
        {
          label: "User",
          field: "fullname",
          thClass: "table-header text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "Timelog",
          field: "timelog",
          thClass: "table-header text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        }
      ],
      vgt: {
        searchTerm: "",
        pageLength: 10
      }
    };
  },
  mounted() {
    this.param.date_input = moment().format("YYYY-MM-DD");
    this.getRecap();
  },
  methods: {
    getRecap() {
      this.$store.dispatch("recapDaily/getRecap", this.param);
    },
    // vgt-props
    onSortChange(params) {},
    onPageChange(params) {
      this.param.perPage = params.currentPerPage;
      this.param.page = params.currentPage;
      this.getRecap();
    },
    timelog(datetime) {
      if (datetime) {
        return moment(datetime).format("DD MMM YYYY HH:mm:ss");
      }
      return datetime;
    }
  }
};
</script>
