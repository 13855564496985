import { ADD_DRUG_TEST, GET_DATA_DRUG_TEST, GET_DRUG_TEST_BY_REGISTER } from "@/api/clinic/drugTest";
import queryString from "query-string";

const state = {
	dataAll: [],
	dataDrugByRegister: [],
};

const mutations = {
	setAllData: (states, payload) => {
		states.dataAll = payload;
	},
	setDrugByRegister: (states, payload) => {
		states.dataDrugByRegister = payload;
	},
};

const actions = {
	getAllData: async ({ commit }, args) => {
		const params = queryString.stringify(args);
		return await GET_DATA_DRUG_TEST(params)
			.then((resp) => {
				if (resp.data.httpCode === 200) {
					commit("setAllData", resp.data.data);
				}
			})
			.catch((err) => {
				let resp = err.response;
				if (resp.data.httpCode === 404) {
					commit("setAllData", {
						setAllData: [],
					});
				}
			});
	},
	//  byRegistId?register_id=args
	getDrugByRegister: async ({ commit }, args) => {

		const params = queryString.stringify(args);
		return await GET_DRUG_TEST_BY_REGISTER(params)
			.then((resp) => {

				if (resp.data.httpCode === 200) {
					commit("setDrugByRegister", resp.data.data);
				}
			})
			.catch((err) => {
				let resp = err.response;
				console.log(err);
				if (resp.data.httpCode === 404) {
					commit("setDrugByRegister", {
						setDrugByRegister: [],
					});
				}
			});
	},

	addDrugTest: async ({ }, args) => {
		return await ADD_DRUG_TEST(args);
	},
};

const getters = {
	allData: (states) => states.dataAll,
	detailDrugByRegister: (states) => states.dataDrugByRegister,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
