import queryString from "query-string";
import { DELETE_ROW_REV, GET_DETAIL_OPNAME, GET_SELECTED_EXP, GET_SELECTED_PRODUCT, GET_TABLE_ADD, GET_TABLE_OPNAME, SAVE_REVISION, SUBMIT_REV } from "@/api/clinic/opname";

const state = {
    dataProductSelected: [],
    dataExpSelected: [],
    dataTableRev: [],
    dataTableOpname: [],
    dataDetailOpname: []
};

const mutations = {
    setSelectedProduct: (states, payload) => {
        states.dataProductSelected = payload;
    },
    setSelectedExp: (states, payload) => {
        states.dataExpSelected = payload;
    },
    setTableRev: (states, payload) => {
        states.dataTableRev = payload;
    },

    // Main Page
    setTableOpname: (states, payload) => {
        states.dataTableOpname = payload;
    },

    // Detail
    setDetailOpname: (states, payload) => {
        states.dataDetailOpname = payload;
    },
}

const actions = {
    getSelected: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_SELECTED_PRODUCT(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setSelectedProduct", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setSelectedProduct", {
                        setSelectedProduct: [],
                    });
                }
            });
    },

    getSelectedExp: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_SELECTED_EXP(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setSelectedExp", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setSelectedExp", {
                        setSelectedExp: [],
                    });
                }
            });
    },

    addRevision: async ({ }, args) => {
        return await SAVE_REVISION(args);
    },

    getTableAddRevision: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_TABLE_ADD(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setTableRev", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setTableRev", {
                        setTableRev: [],
                    });
                }
            });
    },

    deleteRev: async ({ }, args) => {
        return await DELETE_ROW_REV(args);
    },

    SubmitRevs: async ({ }, args) => {
        return await SUBMIT_REV(args);
    },

    // Main Page
    getTableOpname: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_TABLE_OPNAME(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setTableOpname", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setTableOpname", {
                        setTableOpname: [],
                    });
                }
            });
    },

    getDetailOpname: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_DETAIL_OPNAME(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setDetailOpname", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setDetailOpname", {
                        setDetailOpname: [],
                    });
                }
            });
    },
}

const getters = {
    productSelectedData: (states) => states.dataProductSelected,
    selectedExpData: (states) => states.dataExpSelected,
    tableRevData: (states) => states.dataTableRev,
    tableOpnameData: (states) => states.dataTableOpname,
    detailOpnameData: (states) => states.dataDetailOpname
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};