import axiosIns from '@/api/axios'

export const GET_MEDICAL_WASTE_PARTNER = (args) => {
    return axiosIns.get(`/medical-waste/partner?${args}`)
}

export const ADD_MEDICAL_WASTE_PARTNER = (args) => {
    return axiosIns.post(`/medical-waste/partner`, args)
}

export const UPDATE_MEDICAL_WASTE_PARTNER = (args) => {
    return axiosIns.put(`/medical-waste/partner`, args)
}

export const DELETE_MEDICAL_WASTE_PARTNER = (args) => {
    return axiosIns.delete(`/medical-waste/partner?id=${args}`)
}
