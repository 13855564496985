import axiosIns from '@/api/axios'

export const GET_FACTOR_DATA = (args) => {
    return axiosIns.get(`/ih/factor?${args}`)
}

export const ADD_FACTOR = (args) => {
    return axiosIns.post(`/ih/factor/create`, args)
}

export const DEL_FACTOR = (id) => {
    return axiosIns.put(`/ih/factor/delete?id=${id}`)
}

export const UPDATE_FACTOR  = (args) => {
    return axiosIns.put(`/ih/factor/update?id=${args.id}`, args)
}