import queryString from "query-string";

import {
    GET_MEDICAL_WASTE_PARTNER,
    ADD_MEDICAL_WASTE_PARTNER,
    UPDATE_MEDICAL_WASTE_PARTNER,
    DELETE_MEDICAL_WASTE_PARTNER,
    // DEL_UNIT,
    // UPDATE_UNIT
} from "@/api/inventory/partner";

const state = {
    dataPartner: [],
};

const mutations = {
    setPartner: (states, payload) => {
        states.dataPartner = payload;
    },
};

const actions = {
    getPartner: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_MEDICAL_WASTE_PARTNER(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setPartner", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setPartner", {
                        setPartner: [],
                    });
                }
            });
    },

    addPartner: async ({ }, args) => {
        return await ADD_MEDICAL_WASTE_PARTNER(args);
    },

    delPartner: async ({ }, id) => {
        return await DELETE_MEDICAL_WASTE_PARTNER(id);
    },

    updatePartner: async ({ }, args) => {
        return await UPDATE_MEDICAL_WASTE_PARTNER(args);
    },



};

const getters = {
    PartnerData: (states) => states.dataPartner
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};