<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-4">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item">{{ this.breadcrumb }}</li>
                <li class="breadcrumb-item">History</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center" style="margin-top: -1.5rem">
              <div class="col">
                <!-- Nav -->
                <ul class="nav nav-tabs nav-overflow header-tabs">
                  <li class="nav-item" @click="direct = 'Record'">
                    <a
                      class="nav-link"
                      :class="{ active: direct === 'Record' }"
                    >
                      Medical Record
                    </a>
                  </li>
                  <li class="nav-item" @click="direct = 'mcuRec'">
                    <a
                      class="nav-link"
                      :class="{ active: direct === 'mcuRec' }"
                    >
                      MCU Record
                    </a>
                  </li>
                  <li class="nav-item" @click="direct = 'drugTest'">
                    <a
                      class="nav-link"
                      :class="{ active: direct === 'drugTest' }"
                    >
                      Drug Test
                    </a>
                  </li>
                  <!-- <li class="nav-item" @click="direct = 'allergy'">
                    <a
                      class="nav-link"
                      :class="{ active: direct === 'allergy' }"
                    >
                      Allergy
                    </a>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Table -->
      <!-- <div v-if="direct ==='Register' ">
        <Register />
      </div> -->
      <div v-if="direct === 'Record'">
        <Record />
      </div>
      <div v-if="direct === 'mcuRec'">
        <Mcu />
      </div>
      <div v-if="direct === 'drugTest'">
        <DrugTest />
      </div>
    </div>
  </div>
</template>
<script>
//   import Register from '../../../components/clinic_management/patient/register.vue'
import Record from "../../../../components/clinic_management/patient/history/record.vue";
import Mcu from "../../../../components/clinic_management/patient/history/mcu.vue";
import DrugTest from "../../../../components/clinic_management/patient/history/drugTest.vue"
export default {
  name: "history",
  components: {
    DrugTest,
    Record,
    Mcu,
  },
  data() {
    return {
      direct: "Record",
      breadcrumb: "",
    };
  },
  mounted() {
    this.breadcrumb = localStorage.getItem("breadcrumb");
  },
};
</script>
