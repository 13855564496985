import axiosIns from "@/api/axios";

export const GET_ROLE_MANAGEMENT_CLINIC_DATA = (args) => {
	return axiosIns.get(`/roles/clinic?${args}`);
};

export const GET_DATA_ROLE = (args) => {
	return axiosIns.get(`/medical/staff/position`);
};

export const GET_DATA_ROLES = (args) => {
	return axiosIns.get(`/roles`);
};

export const GET_DATA_USER = (args) => {
	return axiosIns.get(`/users`);
};

export const ADD_ROLE_MANAGEMENT_CLINIC = (args) => {
	return axiosIns.post(`/roles/clinic/create`, args);
};

export const UPDATE_ROLE_MANAGEMENT_CLINIC = (args) => {
	return axiosIns.put("/roles/clinic/update", args);
};

export const DELETE_ROLE_MANAGEMENT_CLINIC = (id) => {
	return axiosIns.put(`/roles/clinic/delete?id=${id}`);
};
