<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <div class="mt-6">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("IH Management") }}
                </li>
                <li class="breadcrumb-item">Measurement</li>
                <li class="breadcrumb-item">Detail</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div class="card mt-5">
        <b-tabs content-class="mt-3" fill>
          <b-tab title="Problem, Identification, & Causing" class="p-4" active>
            <div class="row g-3 mt-2">
              <div class="form-group col-4">
                <h3>{{ $t("Factor") }}</h3>
                <b>{{ measuring.factor_name }}</b>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Measurement Date") }}</h3>
                <p>{{ convertDate(measuring.measurement_date) }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Company") }}</h3>
                <p>{{ measuring.company_name }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Location") }}</h3>
                <p>{{ measuring.location }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Activity") }}</h3>
                <p>{{ measuring.measuring_activity_name }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Method") }}</h3>
                <p>{{ measuring.measuring_method_name }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Kit") }}</h3>
                <p>{{ measuring.kit_name }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Treshold") }}</h3>
                <p>{{ measuring.nab }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Unit") }}</h3>
                <p>{{ measuring.unit_name }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Result") }}</h3>
                <p>{{ measuring.result }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Causing") }}</h3>
                <p>{{ measuring.causing }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Status") }}</h3>
                <span v-if="measuring.status == 'OPEN'" class="badge bg-warning"
                  >OPEN</span
                >
                <span
                  v-if="measuring.status == 'IN-PROGRESS'"
                  class="badge bg-info"
                  >IN PROGRESS</span
                >
                <span
                  v-if="measuring.status == 'CLOSED'"
                  class="badge bg-success"
                  >CLOSED</span
                >
              </div>
            </div>
          </b-tab>
          <b-tab title="Corrective Actions" class="p-4">
            <div
              class="form-group mt-4"
              v-for="(i, index) in measuring.corrective_actions"
              :key="`CA-${index}`">
              <h2>{{ i.corrective_action_name }}</h2>
              <div class="row">
                <div class="col-lg-4">
                  <b>Action</b>
                  <p v-if="i.actions">{{ i.actions }}</p>
                  <p v-else>-</p>
                </div>
                <div class="col-lg-2">
                  <b>Due Date</b>
                  <p v-if="i.due_date">{{ convertDate(i.due_date) }}</p>
                  <p v-else>-</p>
                </div>
                <div class="col-lg-2">
                  <b>PIC</b>
                  <p v-if="i.pic_name">{{ i.pic_name }}</p>
                  <p v-else>-</p>
                </div>
                <div class="col-lg-2">
                  <b>Status</b>
                  <p>
                    <span v-if="i.status == 'OPEN'" class="badge bg-warning"
                      >OPEN</span
                    >
                  </p>
                  <p>
                    <span v-if="i.status == 'IN-PROGRESS'" class="badge bg-info"
                      >IN PROGRESS</span
                    >
                  </p>
                  <p>
                    <span v-if="i.status == 'CLOSED'" class="badge bg-success"
                      >CLOSED</span
                    >
                  </p>
                </div>
                <div
                  class="col-lg-2 text-right"
                  v-if="rolesId === 1 || i.pic_name === userName">
                  <b-button
                    v-if="i.status != 'CLOSED'"
                    variant="primary"
                    size="sm"
                    @click="modalAdd(i.id)"
                    ><span class="fe fe-plus"></span
                  ></b-button>
                  <b-button
                    v-if="i.status != 'CLOSED'"
                    variant="warning"
                    size="sm"
                    class="ml-2"
                    @click="modalAdd2(i.id)"
                    ><span class="fe fe-calendar"></span
                  ></b-button>
                  <b-button
                    v-b-toggle="`kon-${index}`"
                    variant="info"
                    class="ml-2"
                    size="sm"
                    ><span class="fe fe-info"></span
                  ></b-button>
                </div>
              </div>
              <b-collapse :id="`kon-${index}`" class="mt-2">
                <b-tabs content-class="mt-3">
                  <b-tab title="Progress" active>
                    <b-card v-for="(j, idx) in i.progress" :key="`pr-${idx}`">
                      <div class="d-flex justify-content-between">
                        <h3 class="mb-0 align-self-center">
                          {{ convertDate2(j.updated_at) }}
                        </h3>
                        <div class="float-end" v-if="i.status != 'CLOSED'">
                          <b-button
                            variant="success"
                            class="mr-2"
                            size="sm"
                            v-if="
                              rolesId === 1 ||
                              (rolesId == 2 && company == measuring.company)
                            "
                            @click="approval(j.id, 'APPROVED', 'progress')"
                            ><span class="fe fe-check"></span>
                          </b-button>
                          <b-button
                            variant="primary"
                            size="sm"
                            v-if="
                              rolesId === 1 ||
                              (rolesId == 2 && company == measuring.company)
                            "
                            @click="approval(j.id, 'REJECTED', 'progress')">
                            <span class="fe fe-x"></span>
                          </b-button>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-lg-6 col-sm-12">
                          <b>NOTE</b>
                          <p>{{ j.progress }}</p>
                          <b>PIC</b>
                          <p>{{ j.pic_name }}</p>
                          <b>Attachment</b>
                          <ul>
                            <li
                              v-for="(q, indx) in j.attachments"
                              :key="`atc-${indx}`">
                              <a :href="q.path" target="_blank">{{
                                q.filename
                              }}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                          <b>Approval</b>
                          <div
                            class="list-group list-group-flush list-group-activity my-n3">
                            <div
                              class="list-group-item"
                              v-for="(prod, idxx) in j.approval"
                              :key="idxx">
                              <div class="row">
                                <div class="col-auto">
                                  <!-- Avatar -->
                                  <div class="avatar avatar-sm">
                                    <div
                                      :class="`avatar-title fs-lg rounded-circle ${
                                        prod.status == 'APPROVED'
                                          ? 'bg-success-soft text-success'
                                          : 'bg-primary-soft text-primary'
                                      }`">
                                      <i
                                        :class="`fe ${
                                          prod.status == 'APPROVED'
                                            ? 'fe-check'
                                            : 'fe-x'
                                        }`"></i>
                                    </div>
                                  </div>
                                </div>
                                <div class="col ms-n2">
                                  <!-- Heading -->
                                  <h5 class="mb-1">
                                    <b>{{ prod.status }}</b>
                                  </h5>

                                  <!-- Text -->
                                  <p class="small text-gray-700 mb-0">
                                    Remark: <b>{{ prod.remark }}</b> <br />
                                    Approved by: <b>{{ prod.approver_name }}</b>
                                  </p>

                                  <!-- Time -->
                                  <small class="text-muted">
                                    {{ convertDate2(prod.created_at) }}
                                  </small>
                                </div>
                              </div>
                              <!-- / .row -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card>
                    <b-alert variant="info" :show="i.progress.length == 0"
                      >Data Not Found</b-alert
                    >
                  </b-tab>
                  <b-tab title="Reschedule">
                    <b-card v-for="(j, idx) in i.reschedule" :key="`rs-${idx}`">
                      <div class="d-flex justify-content-between">
                        <h3 class="mb-0 align-self-center">
                          {{ convertDate2(j.updated_at) }}
                        </h3>
                        <div class="float-end" v-if="i.status != 'CLOSED'">
                          <b-button
                            variant="success"
                            class="mr-2"
                            size="sm"
                            v-if="
                              rolesId === 1 ||
                              (rolesId == 2 && company == measuring.company)
                            "
                            @click="approval(j.id, 'APPROVED', 'reschedule')"
                            ><span class="fe fe-check"></span>
                          </b-button>
                          <b-button
                            variant="primary"
                            size="sm"
                            v-if="
                              rolesId === 1 ||
                              (rolesId == 2 && company == measuring.company)
                            "
                            @click="approval(j.id, 'REJECTED', 'reschedule')">
                            <span class="fe fe-x"></span>
                          </b-button>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-lg-6 col-sm-12">
                          <b>PIC</b>
                          <p>{{ j.pic_name }}</p>
                          <b>Remark</b>
                          <p>{{ j.remark }}</p>
                          <b>Requested New Due Date</b>
                          <p>{{ convertDate(j.due_date) }}</p>
                          <b>Attachment</b>
                          <ul>
                            <li
                              v-for="(q, indx) in j.attachments"
                              :key="`atc-${indx}`">
                              <a :href="q.path" target="_blank">{{
                                q.filename
                              }}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                          <b>Approval</b>
                          <div
                            class="list-group list-group-flush list-group-activity my-n3">
                            <div
                              class="list-group-item"
                              v-for="(prod, idxx) in j.approval"
                              :key="idxx">
                              <div class="row">
                                <div class="col-auto">
                                  <!-- Avatar -->
                                  <div class="avatar avatar-sm">
                                    <div
                                      :class="`avatar-title fs-lg rounded-circle ${
                                        prod.status == 'APPROVED'
                                          ? 'bg-success-soft text-success'
                                          : 'bg-primary-soft text-primary'
                                      }`">
                                      <i
                                        :class="`fe ${
                                          prod.status == 'APPROVED'
                                            ? 'fe-check'
                                            : 'fe-x'
                                        }`"></i>
                                    </div>
                                  </div>
                                </div>
                                <div class="col ms-n2">
                                  <!-- Heading -->
                                  <h5 class="mb-1">
                                    <b>{{ prod.status }}</b>
                                  </h5>

                                  <!-- Text -->
                                  <p class="small text-gray-700 mb-0">
                                    Remark: <b>{{ prod.remark }}</b> <br />
                                    Approved by: <b>{{ prod.approver_name }}</b>
                                  </p>

                                  <!-- Time -->
                                  <small class="text-muted">
                                    {{ convertDate2(prod.created_at) }}
                                  </small>
                                </div>
                              </div>
                              <!-- / .row -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card>
                    <b-alert variant="info" :show="i.reschedule.length == 0"
                      >Data Not Found</b-alert
                    >
                  </b-tab>
                </b-tabs>
              </b-collapse>
              <hr v-if="index + 1 < measuring.corrective_actions.length" />
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <b-modal id="modal-1" centered hide-footer :title="$t('Progress Form')">
        <form @submit.prevent="saveAdd()">
          <div class="form-group">
            <label>{{ $t("Progress") }}</label>
            <input
              v-validate="'required'"
              name="Progress"
              type="text"
              v-model="addProgress.progress"
              class="form-control"
              placeholder="Progress" />
            <span class="text-sm text-danger" v-show="errors.has('Progress')">{{
              errors.first("Progress")
            }}</span>
          </div>
          <div class="form-group">
            <label>Document</label>
            <input
              type="file"
              multiple
              class="form-control"
              ref="fileUpload"
              @change="fileUploader" />
          </div>
          <div class="form-group">
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </b-modal>
      <b-modal id="modal-2" centered hide-footer :title="$t('Reschedule Form')">
        <form @submit.prevent="saveAdd2()">
          <div class="form-group">
            <label>{{ $t("Remark") }}</label>
            <input
              v-validate="'required'"
              name="Remark"
              type="text"
              v-model="addReschedule.remark"
              class="form-control"
              placeholder="Remark" />
            <span class="text-sm text-danger" v-show="errors.has('Remark')">{{
              errors.first("Remark")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Due Date") }}</label>
            <input
              v-validate="'required'"
              type="date"
              class="form-control"
              v-model="addReschedule.due_date"
              name="Due Date" />
            <span class="text-sm text-danger" v-show="errors.has('Due Date')">{{
              errors.first("Due Date")
            }}</span>
          </div>
          <div class="form-group">
            <label>Document</label>
            <input
              type="file"
              multiple
              class="form-control"
              ref="fileUpload"
              @change="fileUploader2" />
          </div>
          <div class="form-group">
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { LOGOUT } from "@/api/auth";
export default {
  name: "defaultCMS",
  computed: {
    rolesId() {
      return localStorage.getItem("roles_id")
        ? Number(localStorage.getItem("roles_id"))
        : 0;
    },
    userName() {
      return localStorage.getItem("fullname");
    },
    company() {
      return localStorage.getItem("company");
    }
    // measuring() {
    //     return this.$store.getters["action/getMeasuringData"] ?
    //         this.$store.getters["action/getMeasuringData"] : [];
    // },
  },
  data() {
    return {
      isLoading: false,
      measuring: {},
      columns: [
        {
          label: "CHAPTER",
          field: "chapter_name",
          thClass: "table-header text-muted w-100 font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ],
      addProgress: {
        id: "",
        progress: "",
        attachment: []
      },
      addReschedule: {
        id: "",
        remark: "",
        due_date: "",
        attachment: []
      }
    };
  },
  mounted() {
    // this.getChapter();
    this.getDetail();
  },
  methods: {
    //action
    convertDate(d) {
      return d ? moment(d).format("D MMM YYYY") : d;
    },

    convertDate2(d) {
      return d ? moment(d).format("D MMM YYYY hh:mm:ss") : d;
    },
    getDetail() {
      const id = this.$router.currentRoute.query.id;
      if (id) {
        // this.id = id;
        this.$store
          .dispatch("action/getMeasuring", {
            id: id
          })
          .then(() => {
            this.measuring = this.$store.getters["action/measuringData"];
          });
      }
    },

    modalAdd(id) {
      this.addProgress.id = id;
      this.$bvModal.show("modal-1");
    },
    modalAdd2(id) {
      this.addReschedule.id = id;
      this.$bvModal.show("modal-2");
    },

    async fileUploader(event) {
      this.addProgress.attachment = event.target.files;
    },
    async fileUploader2(event) {
      this.addReschedule.attachment = event.target.files;
    },
    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        const dataForm = new FormData();
        dataForm.append("ih_measurement_corrective_id", this.addProgress.id);
        dataForm.append("progress", this.addProgress.progress);
        for (let docs of this.addProgress.attachment) {
          dataForm.append("attachments", docs); // note, no square-brackets
        }
        if (!res) return false;
        try {
          this.isLoading = true;
          this.$store.dispatch("action/newProgress", dataForm).then((resp) => {
            this.$swal({
              title: this.$t("Saved"),
              text: this.$t("Your input has been saved"),
              icon: "success",
              confirmButtonText: this.$t("OK")
            }).then((resp) => {
              this.isLoading = false;
              this.addProgress.attachment = "";
              (this.addProgress.id = ""),
                (this.addProgress.progress = ""),
                this.$bvModal.hide("modal-1");
              this.getDetail();
            });
          });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
        }
      });
    },
    saveAdd2() {
      this.$validator.validateAll().then(async (res) => {
        const dataForm = new FormData();
        dataForm.append("ih_measurement_corrective_id", this.addReschedule.id);
        dataForm.append("remark", this.addReschedule.remark);
        dataForm.append("due_date", this.addReschedule.due_date);
        for (let docs of this.addReschedule.attachment) {
          dataForm.append("attachments", docs); // note, no square-brackets
        }
        if (!res) return false;
        try {
          this.isLoading = true;
          this.$store
            .dispatch("action/newReschedule", dataForm)
            .then((resp) => {
              this.$swal({
                title: this.$t("Saved"),
                text: this.$t("Your input has been saved"),
                icon: "success",
                confirmButtonText: this.$t("OK")
              }).then((resp) => {
                this.isLoading = false;
                this.addReschedule.attachment = "";
                (this.addReschedule.id = ""),
                  (this.addReschedule.due_date = ""),
                  (this.addReschedule.remark = ""),
                  this.$bvModal.hide("modal-2");
                this.getDetail();
              });
            });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
        }
      });
    },

    approval(id, stat, type) {
      this.$swal({
        title: this.$t("Warning!"),
        text: this.$t(
          `Are you sure want to ${stat.toLowerCase()} this ${type}?`
        ),
        icon: "warning",
        input: "textarea",
        inputLabel: "Remark",
        inputPlaceholder: `Type your ${type} note...`,
        inputAttributes: {
          "aria-label": `Type your ${type} note...`
        },
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes!"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          if (type == "progress") {
            this.$store
              .dispatch("action/approveProgress", {
                ih_measurement_action_id: id,
                remark: result.value,
                status: stat
              })
              .then((resp) => {
                this.isLoading = false;
                this.$swal(
                  "Saved!",
                  this.$t("Your progress has been saved"),
                  "success"
                );
                this.getDetail();
              })
              .catch((resp) => {
                this.$swal(
                  "Error",
                  this.$t(`Error while ${stat.toLowerCase()} data`),
                  "error"
                );
              });
          } else if (type == "reschedule") {
            this.$store
              .dispatch("action/approveReschedule", {
                ih_measurement_reschedule_id: id,
                remark: result.value,
                status: stat
              })
              .then((resp) => {
                this.isLoading = false;
                this.$swal(
                  "Saved!",
                  this.$t("Your progress has been saved"),
                  "success"
                );
                this.getDetail();
              })
              .catch((resp) => {
                this.$swal(
                  "Error",
                  this.$t(`Error while ${stat.toLowerCase()} data`),
                  "error"
                );
              });
          }
        }
      });
    },

    // api hit
    getMeasuring() {
      this.$store.dispatch("action/getMeasuring", this.body);
    },

    // filter
    filterPage() {
      this.getChapter();
    },

    sorting() {
      // this.onPageChange((payload) => {
      //   // payload.currentPerPage=  10,
      //   // payload.currentPage= 1
      // })

      this.getChapter();
      this.body.page = 1;
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getChapter();
      // if (
      //   parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
      //   1000
      // ) {
      // }
    }
  }
};
</script>
