<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <!-- / .row -->

      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("Chemical Hazard") }}</h4>
          <!-- Button -->
          <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
            <span class="fe fe-file-plus"></span>
            {{ $t("Add") }}
          </b-button>
          <b-button
            v-b-toggle.collapse-1
            variant="primary"
            style="margin-left: 7px">
            <span class="fe fe-sliders"></span>
          </b-button>
        </div>
        <b-collapse id="collapse-1">
          <form @submit.prevent="sorting()">
            <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Hazard Name")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="body.hazard_name" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label"
                    >Order</label
                  >
                  <v-select v-model="body.order" :options="['ASC', 'DESC']" />
                  <!-- <v-select v-model="body.order" :options="['ASC', 'DESC']" @input="sorting" /> -->
                </div>
              </div>
            </div>

            <div class="row mb-3" style="margin-right: 15px">
              <div class="col"></div>
              <div class="col-auto">
                <!-- Button -->
                <button type="submit" class="btn btn-danger lift">
                  {{ $t("Filter") }}
                </button>
              </div>
            </div>
            <!-- / .row -->
          </form>
        </b-collapse>
        <vue-good-table
          :line-numbers="true"
          :columns="columns"
          :rows="chemical.data"
          :sort-options="{
            enabled: true
          }"
          :total-rows="chemical.totalItems"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }"
          style-class="vgt-table border-0 table"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange">
          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <!-- Custom Rows -->
          <template slot="table-column" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.label == 'HAZARD DATA NAME'">
              {{ $t("Hazard Data Name") }}
            </span>
            <span v-if="props.column.label == 'Image'">
              {{ $t("ICON") }}
            </span>
            <span v-if="props.column.label == 'ACTION'">
              {{ $t("Action") }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.field == 'action'">
              <b-button
                class="no-wrap btn-pill"
                variant="warning"
                size="sm"
                @click="updateData(props.row)">
                <span class="fe fe-edit"></span>
              </b-button>
              <b-button
                class="no-wrap ml-2"
                variant="danger"
                style="margin-left: 5px"
                size="sm"
                @click="deleteData(props.row.id)">
                <span class="fe fe-trash-2"></span>
              </b-button>
            </span>
            <span v-else-if="props.column.field == 'image_path'">
              <img
                style="max-width: 64px; max-height: 64px"
                :src="baseUrl + props.row.icon"
                alt="" />
            </span>
          </template>
        </vue-good-table>
      </div>

      <!-- Modal -->
      <b-modal id="modal-1" hide-footer :title="$t('Chemical Hazard Form')">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="form-group">
            <label>{{ $t("Hazard Name") }}</label>
            <input
              type="text"
              v-model="hazard_name"
              class="form-control"
              placeholder="Hazard Name"
              v-validate="'required'"
              name="Hazard Name" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Hazard Name')"
              >{{ errors.first("Hazard Name") }}</span
            >
          </div>
          <div class="form-group">
            <label>{{ $t("Image") }}</label>
            <input
              type="file"
              class="form-control"
              v-validate="'required'"
              accept="image/*"
              ref="fileUpload"
              @change="fileUploader" />
            <span class="text-sm text-danger" v-show="errors.has('file')">{{
              errors.first("file")
            }}</span>
          </div>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="submit"
            v-if="isLoading == false">
            {{ $t("Save") }}
          </button>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="button"
            disabled
            v-else>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
            Loading...
          </button>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="form-group">
            <label>{{ $t("Hazard Name") }}</label>
            <input
              type="text"
              v-model="hazard_names"
              class="form-control"
              placeholder="Hazard Name" />
          </div>
          <div class="form-group">
            <label>{{ $t("Image") }}</label>
            <input
              type="file"
              class="form-control"
              v-validate="'required'"
              accept="image/*"
              ref="fileUpload"
              @change="fileUploader2" />
            <span class="text-sm text-danger" v-show="errors.has('file')">{{
              errors.first("file")
            }}</span>
          </div>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="submit"
            v-if="isLoading == false">
            {{ $t("Save") }}
          </button>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="button"
            disabled
            v-else>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
            Loading...
          </button>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "chemicalHazard",
  computed: {
    chemical() {
      return this.$store.getters["chemical/ChemicalData"]
        ? this.$store.getters["chemical/ChemicalData"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        limit: 10,
        order: ""
        // hazard_name: ''
      },
      hazard_name: "",
      icon: "",
      hazard_names: "",
      icons: "",
      id: "",
      baseUrl: "",
      isAdd: true,
      isLoading: false,
      columns: [
        {
          label: "HAZARD DATA NAME",
          field: "hazard_name",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Image",
          field: "image_path",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ]
    };
  },
  mounted() {
    // if (this.chemical && this.chemical.length < 1) {
    this.getChemical();
    this.baseUrl = process.env.VUE_APP_API_URL;
    // }
  },
  methods: {
    //action
    updateData(props) {
      this.$bvModal.show("modal-1");
      this.isAdd = false;
      this.id = props.id;
      this.hazard_names = props.hazard_name;
      const files = "";
      let container = new DataTransfer();
      const image = props.icon;
      const image_url = `${this.baseUrl}${image}`;
      const response = fetch(image_url);
      // here image is url/location of image
      const blob = response.blob();
      const file = new File([blob], image, {
        type: blob.type
      });

      files = file;
      container.items.add(file);
      this.icons = container.files;
      this.$refs.fileUpload.files = container.files;
    },

    async fileUploader(event) {
      this.icon = event.target.files;
    },
    async fileUploader2(event) {
      this.icons = event.target.files;
    },

    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          const formData = new FormData();
          formData.append("hazard_name", this.hazard_name);
          formData.append("icon", this.icon[0]);
          this.isLoading = true;
          this.$store
            .dispatch("chemical/addChemical", formData)
            .then((resp) => {
              this.$swal({
                title: this.$t("Saved"),
                text: this.$t("Your input has been saved"),
                icon: "success",
                confirmButtonText: this.$t("OK")
              }).then((resp) => {
                this.isLoading = false;
                this.hazard_name = "";
                this.icon = "";
                this.getChemical();
                this.$bvModal.hide("modal-1");
              });
            });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
        }
      });
    },

    saveUpdate() {
      const formData = new FormData();
      formData.append("id", this.id);
      formData.append("hazard_name", this.hazard_names);
      formData.append("icon", this.icons[0]);
      this.isLoading = true;
      this.$store
        .dispatch("chemical/updateChemical", formData)
        .then((resp) => {
          this.$swal({
            title: this.$t("Saved"),
            text: this.$t("Your input has been saved"),
            icon: "success",
            confirmButtonText: this.$t("OK")
          }).then((resp) => {
            this.isLoading = false;
            this.getChemical();
            this.$bvModal.hide("modal-1");
          });
        })
        .catch((resp) => {
          this.$swal("Error", this.$t("Error while edit data"), "error");
        });
    },

    deleteData(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("chemical/delChemical", id)
            .then((resp) => {
              this.getChemical();
              this.$swal(
                "Deleted!",
                this.$t("Your file has been deleted"),
                "success"
              );
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    },

    // api hit
    getChemical() {
      this.$store.dispatch("chemical/getChemical", this.body);
    },

    // filter
    filterPage() {
      this.getChemical();
    },

    sorting() {
      this.getChemical();
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;

      // if (
      //   parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
      //   1000
      // ) {
      this.getChemical();
      // }
    }
  }
};
</script>
