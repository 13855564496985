import axiosIns from '@/api/axios'

export const GET_OH_DATA = (args) => {
    return axiosIns.get(`/oh/management/?${args}`)
}

export const GET_COMPANY = (args) => {
    return axiosIns.get(`/company?${args}`)
}

export const ADD_OH_DAILY = (args) => {
    return axiosIns.post(`oh/management/create`, args)
}

export const ANALYZE_DATA = (args) => {
    return axiosIns.put(`/oh/management/approve/analyze`, args)
}

export const IMPORT_FILE = (body) => {
    return axiosIns.post('/oh/management/import', body, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
};


export const CHECKED_DATA = () => {
    return axiosIns.get(`/oh/management/approve/get`)
}

export const CHECKED = () => {
    return axiosIns.put(`/oh/management/approve/checked`)
}

export const APPROVE_DATA = (args) => {
    return axiosIns.post(`/oh/management/approve`, args)
}

export const GET_STATUS = (args) => {
    return axiosIns.get(`/oh/management/approve/status?${args}`)
}

export const LAST_UPDATE = () => {
    return axiosIns.get('/oh/management/report/history/action')
}
export const DEL_CHEMICAL = () => {
    
}
export const UPDATE_CHEMICAL = () => {

}
