<template>
  <div class="row align-items-center justify-content-between">
    <div class="card p-0">
      <div class="card-header">
        <!-- Title -->
        <h4 class="card-header-title">Patient Info</h4>
      </div>
      <div v-if="load === false">
        <div class="list-group list-group-flush">
          <div class="list-group-item p-4">
            <div class="row">
              <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                  <div
                    class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                    <i class="fe fe-credit-card"></i>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="small mt-2">
                  <strong>NIK</strong>
                </p>
                <p class="small mt-2">
                  <strong>{{ this.history.nik }}</strong>
                </p>
              </div>
              <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                  <div
                    class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                    <i class="fe fe-user"></i>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="small mt-2">
                  <strong>Name</strong>
                </p>
                <p class="small mt-2">
                  <strong>{{ this.patient.patient_name }}</strong>
                </p>
              </div>
              <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                  <div
                    class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                    <i class="fe fe-home"></i>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="small mt-2">
                  <strong>Company</strong>
                </p>
                <p class="small mt-2">
                  <strong>{{ this.history.company }}</strong>
                </p>
              </div>
              <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                  <div
                    class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                    <i class="fe fe-layers"></i>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="small mt-2">
                  <strong>Departement</strong>
                </p>
                <p class="small mt-2">
                  <strong>{{ this.history.department }}</strong>
                </p>
              </div>
              <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                  <div
                    class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                    <i class="fe fe-award"></i>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="small mt-2">
                  <strong>Position</strong>
                </p>
                <p class="small mt-2">
                  <strong>{{ this.history.position }}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body text-center" v-else>
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div class="card p-0">
      <div class="card-header">
        <!-- Title -->
        <h4 class="card-header-title">Drug Test</h4>
        <!-- Button -->
        <div style="margin-right: 15px"></div>
      </div>
      <vue-good-table
        :line-numbers="true"
        :columns="columns"
        :rows="dataMedical.data"
        :sort-options="{
          enabled: true
        }"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          position: 'bottom',
          perPage: 10,
          perPageDropdown: [10, 50, 100],
          dropdownAllowAll: false,
          setCurrentPage: 1,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page',
          allLabel: 'All'
        }"
        style-class="vgt-table border-0 table">
        <!-- Loading -->
        <div slot="emptystate" class="text-center font-size-14">
          No data available
        </div>
        <!-- Custom Rows -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'register_id'">
            {{ props.row.register_id }}
          </span>
          <span v-if="props.column.field == 'register_date'">
            {{ date(props.row.register_date) }}
          </span>
          <span v-if="props.column.field == 'clinic_name'">
            {{(props.row.clinic_name) }}
          </span>
          <span v-if="props.column.field == 'doctor'">
            {{ (props.row.doctor) }}
          </span>
          <span v-if="props.column.field == 'status'">
            {{ (props.row.status) }}
          </span>
          <span v-if="props.column.field == 'positif_drugs'">
            {{ (props.row.positif_drugs) }}
          </span>
          <!-- Table Action -->
          <span v-else-if="props.column.field == 'action'">
            <div>
              <b-button
                class="no-wrap btn-pill"
                variant="info"
                size="sm"
                @click.prevent="viewRecord(props.row.register_id)">
                <span class="fe fe-eye"></span>
              </b-button>
            </div>
          </span>

          <!-- <span v-else-if="props.column.field == 'created_at'">
                        {{ fixTime(props.row.created_at) }}
                    </span> -->
        </template>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "patient",
  computed: {
    dataMedical() {
      return this.$store.getters["historyMcu/patientRecordData"]
        ? this.$store.getters["historyMcu/patientRecordData"]
        : [];
    }
  },
  data() {
    return {
      columns: [
        {
          label: "Noreg",
          field: "register_id",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Register Date",
          field: "register_date",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        // {
        //   label: "Test Date",
        //   field: "test_date",
        //   thClass: "table-header w-auto text-muted font-weight-bold",
        //   tdClass: "w-auto color-secondary align-middle"
        // },
        {
          label: "Clinic",
          field: "clinic_name",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Doctor",
          field: "doctor",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Status",
          field: "status",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "PENGGUNAAN NARKOBA",
          field: "positif_drugs",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ],
      patient: [],
      history: [],
      load: false
    };
  },
  mounted() {
    this.patient = JSON.parse(localStorage.getItem("dataMedical"));
    this.history = JSON.parse(localStorage.getItem("seehistory"));
    this.getUnit();
  },
  methods: {
    viewRecord(id) {
      localStorage.setItem("registerID", id);
      this.$router.push("/clinic-management/drug_test/detail");
    },

    getUnit() {
      // let body = {
      //   patient_name: "",
      // 	company_id: "",
      // 	register_date: moment().format("YYYY-MM-DD"),
      // 	clinic_id: localStorage.getItem("clinic_id"),
      // };
      // this.$store.dispatch("drugTest/getAllData", body);
      let body = {
        type_visit: "Test Narkoba",
        nik: this.patient.nik,
        employee_name: this.patient.patient_name
      };
      this.$store.dispatch("historyMcu/getRecordPatient", body);
    },

    date(date) {
      return moment(date).format("DD MMMM YYYY");
    }
  }
};
</script>
