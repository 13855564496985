<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-6">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item">{{ $t("Inventory") }}</li>
                <li class="breadcrumb-item">Entry Stock</li>
                <li class="breadcrumb-item">{{ $t("Input Stock") }}</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Table -->
      <div class="card mt-5">
        <!-- <form @submit.prevent="addPushData()"> -->
        <form @submit.prevent="addItem()">
          <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div class="form-group">
                <label for="Product" class="form-label">{{
                  $t("Product")
                }} <span class="text-danger">*</span></label>
                <v-select v-model="bodyAdd.item_id" :options="drug.data" :reduce="(e) => e.id" @input="productUnit()"
                  label="item_label"></v-select>
                <!-- <span class="text-sm text-danger" v-show="errors.has('Product')">{{ errors.first("Product") }}</span> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div class="form-group">
                <label for="Qty" class="form-label">{{
                  $t("Qty")
                }} <span class="text-danger">*</span></label>
                <input type="number" :disabled="checkVal()" v-model="bodyAdd.item_qty" class="form-control" name="Qty"
                  min="1" />
                <!-- <span class="text-sm text-danger" v-show="errors.has('Qty')">{{ errors.first("Qty") }}</span> -->
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div class="form-group">
                <label for="Unit" class="form-label">{{
                  $t("Unit")
                }} <span class="text-danger">*</span></label>
                <v-select :disabled="checkVal()" v-model="bodyAdd.item_uom_id" :options="unit.uom" label="item_uom_name"
                  :reduce="(e) => e.item_uom_id" />
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div class="form-group">
                <label for="Price/Unit" class="form-label">Price/Unit <span class="text-danger">*</span></label>
                <vue-numeric class="form-control" currency="Rp. " separator="," v-model="bodyAdd.item_price" />
                <!-- <div class="input-group mb-3">
                  <span class="input-group-text" id="inputGroup-sizing-default"
                    >Rp.</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    :disabled="checkVal()"
                    aria-label="Sizing example input"
                    v-model="bodyAdd.item_price"
                    aria-describedby="inputGroup-sizing-default"
                    @blur="val()"
                    @keypress="isNumberInput($event)" />
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div class="form-group">
                <label for="Expired Date" class="form-label">Expired Date <span class="text-danger">*</span></label>
                <input type="date" class="form-control" :disabled="checkVal()" v-model="bodyAdd.expiry_date"
                  :min="now()" />
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div class="form-group">
                <label for="SAP Item ID" class="form-label">SAP Item ID</label>
                <input type="text" :disabled="checkVal()" v-model="bodyAdd.sap_material_number" class="form-control" />
              </div>
            </div>
          </div>
          <button class="float-end btn btn-primary mr-4 mb-3" type="submit" v-if="isLoading === false">
            Add
          </button>
          <button class="float-end btn btn-primary mr-4 mb-3" type="button" disabled v-if="isLoading === true">
            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
        </form>
      </div>
      <div class="row">
        <div class="col-xl-9 col-lg-8 col-sm-12">
          <div class="card mt-3">
            <table class="table table-sm table-bordered po-table">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">SAP Item ID</th>
                  <th scope="col">Item Name</th>
                  <th scope="col">QTY</th>
                  <th scope="col">Unit</th>
                  <th scope="col">EXP Date</th>
                  <th scope="col">Price</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(prod, index) in productList.rows" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td :id="index + 'a'" contenteditable="true" @keydown="
                      updateSAP($event, index, prod.id, 'sap_material_number')
                    ">
                    {{ prod.sap_material_number }}
                  </td>
                  <td>{{ prod.item_label }}</td>
                  <td :id="index + 'b'" contenteditable="true" @keydown="updateQTY($event, index, prod.id, 'item_qty')">
                    {{ prod.item_qty }}
                  </td>
                  <td>{{ prod.item_uom_name }}</td>
                  <td>{{ convertDate(prod.expiry_date) }}</td>
                  <td class="text-right">
                    Rp. {{ convertNumber(prod.item_price) }}
                  </td>
                  <td>
                    <b-button class="no-wrap ml-2" variant="danger" style="margin-left: 5px" size="sm"
                      @click="delProd(prod)">
                      <span class="fe fe-trash"></span>
                    </b-button>
                  </td>
                </tr>
                <!-- v-if="productList.total_price > 0" -->
                <tr>
                  <td class="text-right" colspan="6">Total :</td>
                  <td class="text-right">
                    Rp. {{ convertNumber(productList.total_price) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-sm-12">
          <div class="card mt-3">
            <form>
              <div class="mt-4" style="margin-left: 15px; margin-right: 15px">
                <div class="col">
                  <div class="form-group">
                    <label class="form-label">Order Date <span class="text-danger">*</span></label>
                    <input type="date" class="form-control" v-model="submit.order_time" :max="now()" />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="form-label">SAP PO No (Optional)</label>
                    <input type="text" class="form-control" v-model="submit.sap_po_number" />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="Supplier Name" class="form-label">Supplier Name <span
                        class="text-danger">*</span></label>
                    <input type="text" class="form-control" v-model="submit.supplier_name" />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="form-label">Due Date <span class="text-danger">*</span></label>
                    <input type="date" class="form-control" v-model="submit.due_date" :min="now()" />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="Note" class="form-label">Note</label>
                    <textarea type="text" class="form-control" v-model="submit.note" />
                    </div>
                </div>
                <div>
                  <button
                    style="margin-left: auto; float: right"
                    v-if="isLoading == false"
                    class="mr-3 mb-3 btn btn-primary"
                    @click.prevent="submitOrder">
                    {{ $t("Submit") }}
                  </button>
                  <button class="float-end btn btn-primary mr-4 mb-3" type="button" disabled v-if="isLoading === true">
            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://unpkg.com/vue@2.5.9/dist/vue.min.js"></script>
<script src="https://unpkg.com/lodash@4.17.4/lodash.min.js"></script>
<script>
import moment from "moment";
import { LOGOUT } from "@/api/auth";
import po from "@/store/clinic/po";
import entryStock from "@/store/clinic/entryStock";
export default {
  name: "inventoryInputPO",
  computed: {
    drug() {
      return this.$store.getters["drug/DrugData"]
        ? this.$store.getters["drug/DrugData"]
        : [];
    },
    unit() {
      return this.$store.getters["entryStock/UnitProductData"]
        ? this.$store.getters["entryStock/UnitProductData"]
        : [];
    },
    detail() {
      return this.$store.getters["entryStock/dataEditPO"]
        ? this.$store.getters["entryStock/dataEditPO"]
        : [];
    }
    // unit() {
    //   return this.$store.getters["po/UnitProductData"]
    //     ? this.$store.getters["po/UnitProductData"]
    //     : [];
    // },
    // detail() {
    //   return this.$store.getters["po/dataEditPO"]
    //     ? this.$store.getters["po/dataEditPO"]
    //     : [];
    // },
    // productList(){
    //   return this.$store.getters["po/InputProductData"] ? this.$store.getters["po/InputProductData"] : [];
    // }
  },
  // watch: {
  //   productList(val){
  //     return this.$store.getters["po/InputProductData"] ? this.$store.getters["po/InputProductData"] : [];
  //   }
  // },
  data() {
    return {
      bodyProduct: {
        item_type: ""
      },
      bodyUnit: {
        page: 1,
        limit: 10,
        order: "",
        unit_name: ""
      },
      bodyAdd: {
        expiry_date: moment().format("YYYY-MM-DD"),
        stock_id: "",
        item_id: "",
        item_qty: 1,
        item_price: "",
        item_uom_id: "",
        sap_material_number: "",
        note: "-"
      },
      submit: {
        po_id: null,
        order_time: moment().format("YYYY-MM-DD"),
        due_date: "",
        sap_po_number: "",
        supplier_name: "",
        note: ""
      },
      po_id: null,
      productList: {},
      isLoading: false,
      columns: [
        {
          label: "NAMA PRODUK",
          field: "drugs_name",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "SATUAN",
          field: "unit_name",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "JUMLAH",
          field: "price",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "HARGA",
          field: "qty",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ]
    };
  },
  mounted() {
    this.getDetail();
    this.getDrug();
    // this.getInputProduct();
  },
  methods: {
    convertDate(d) {
      if (d) {
        return moment(d).format("D MMM YYYY");
      }
      return d;
    },
    getDetail() {
      const po_id = this.$router.currentRoute.query.id;

      if (po_id) {
        this.po_id = po_id;
        this.$store.dispatch("po/getEditPO", { id: po_id }).then(() => {
          let data = this.$store.getters["po/dataEditPO"];
          this.submit = {
            po_id: po_id,
            order_time: data.order_time,
            sap_po_number: data.sap_po_number,
            supplier_name: data.supplier_name,
            note: data.note
          };
          this.getInputProduct();
          this.bodyAdd.po_id = po_id;
        });
      } else {
        this.getInputProduct();
      }
    },
    getDrug() {
      this.$store.dispatch("drug/getDrug", this.bodyProduct);
    },
    getUnit() {
      this.$store.dispatch("unit/getUnit", this.bodyUnit);
    },
    getInputProduct() {
      this.$store
        .dispatch("entryStock/getInputProduct", { id: this.stock_id || "" })
        .then(() => {
          let data = this.$store.getters["entryStock/InputProductData"];
          this.productList = data;
        });
    },

    // submitOrder2() {
    //   this.$store.dispatch("entryStock/submitStock", this.submit).then(() => {
    //     this.$router.push("/clinic-management/inventory/entry-stock");
    //   });
    // },

    submitOrder() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Submitted Stock cannot be changed later!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes"
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (this.submit.order_time && this.submit.supplier_name && this.submit.due_date) {
              this.isLoading = true
              this.$store
              .dispatch("entryStock/submitStockPo", this.submit)
              .then((poResponse) => {
                const poId = poResponse.data.data.id;
                const orderTime = poResponse.data.data.order_time;
                const doPayload = {
                  po_id: poId,
                  order_time: orderTime
                };

                setTimeout(() => {
                  this.$store
                    .dispatch("entryStock/submitStockDo", doPayload)
                    .then(() => {
                      this.isLoading = false
                      this.$router.push(
                        "/clinic-management/inventory/entry-stock"
                      );
                      this.$swal.fire(
                        "Submitted!",
                        "Your order has been submitted.",
                        "success"
                      );
                    })
                    .catch((doError) => {
                      this.isLoading = false
                      console.error("Error submitting DO:", doError);
                      this.$swal.fire(
                        "Error!",
                        "There was an error submitting the Delivery Order.",
                        "error"
                      );
                    });
                }, 500);
              })
              .catch((poError) => {
                this.isLoading = false
                console.error("Error submitting PO:", poError);
                this.$swal.fire(
                  "Error!",
                  "There was an error submitting the Purchase Order.",
                  "error"
                );
              });
            } else {
              this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                }
              })
              .fire({
                icon: "error",
                title: 'Fill the Mandatory Field'
              });
            }
            
          }
        });
    },

    now() {
      return moment().format("YYYY-MM-DD");
    },

    addItem() {
      if (this.bodyAdd.item_id && this.bodyAdd.item_price && this.bodyAdd.item_qty && this.bodyAdd.item_uom_id) {
        this.isLoading = true;
        this.$store
          .dispatch("entryStock/addUnitProduct", this.bodyAdd)
          .then(() => {
            this.isLoading = false;
            let data = this.$store.getters["entryStock/InputProductData"];
            this.productList = data;
            this.bodyAdd = {
              ...this.bodyAdd,
              expiry_date: moment().format("YYYY-MM-DD"),
              item_qty: 1,
              item_uom_id: "",
              item_price: "",
              sap_material_number: "",
              note: "-",
              item_id: ""
            };
          });
      
      } else {
        this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                }
              })
              .fire({
                icon: "error",
                title: 'Fill the Mandatory Field'
              });
    }
  },

    productUnit() {
      this.$store
        .dispatch("entryStock/getUnitProduct", this.bodyAdd.item_id)
        .then(() => {
          let data = this.$store.getters["entryStock/UnitProductData"];
          this.bodyAdd.sap_material_number = data.sap_material_number;
        });
    },

    delProd(prod) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("entryStock/deleteUnitProduct", prod.id)
              .then(() => {
                // this.$swal.fire(
                //   "Deleted!",
                //   "Your file has been deleted.",
                //   "success"
                // );
                let data = this.$store.getters["entryStock/InputProductData"];
                this.productList = data;
                this.$swal.fire(
                  "Deleted!",
                  "Your file has been deleted.",
                  "success"
                );
              });
          }
        });
    },

    checkVal() {
      if (this.bodyAdd.item_id.length > 0) {
        return false;
      } else {
        return true;
      }
    },

    updateSAP(evt, index, id, vars) {
      switch (evt.keyCode) {
        case 13:
          evt.preventDefault();
          const value = document.getElementById(index + "a").innerText;
          const body = {
            id,
            [vars]: value
          };
          this.$store.dispatch("entryStock/updateInner", body);
          break;
        default:
          if (evt.keyCode >= 65 && evt.keyCode <= 90) {
            evt.preventDefault();
          }
          break;
      }
    },

    updateQTY(evt, index, id, vars) {
      switch (evt.keyCode) {
        case 13:
          evt.preventDefault();
          const value = document.getElementById(index + "b").innerText;
          const body = {
            id,
            [vars]: value
          };
          this.$store.dispatch("entryStock/updateInner", body).then(() => {
            const Toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Successfully Change"
            });
          });
          break;
        default:
          if (evt.keyCode >= 65 && evt.keyCode <= 90) {
            evt.preventDefault();
          }
          break;
      }
    },
    val() {
      this.bodyAdd.item_price = this.bodyAdd.item_price
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    isNumberInput(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    convertNumber(num) {
      return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }
    // addPushData() {
    //   var dataPush = {
    //     id: this.id,
    //     drugs_name: this.bodyProduct.drugs_name,
    //     unit_name: this.bodyUnit.unit_name,
    //     price: this.price,
    //     qty: this.qty,
    //   };
    //   this.pushData.push(dataPush);
    // },
    // removePushData(index) {
    //   this.pushData.splice(index, 1);
    // },
  }
};
</script>
<style>
.po-table td:first-child,
.po-table td:nth-last-child(3),
.po-table td:nth-last-child(2),
.po-table td:last-child {
  text-align: center;
}

.po-table > :not(caption) > * > * {
  padding: 0.5rem !important;
}
</style>