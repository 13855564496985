import queryString from "query-string";

import {
    GET_CHAPTER_DATA,
    ADD_CHAPTER,
    DEL_CHAPTER,
    UPDATE_CHAPTER
} from "@/api/disease/chapter";

const state = {
    dataChapter: [],
};

const mutations = {
    setChapter: (states, payload) => {
        states.dataChapter = payload;
    },
};

const actions = {
    getChapter: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_CHAPTER_DATA(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    commit("setChapter", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                if (resp.data.httpCode === 404) {
                    commit("setChapter", {
                        setChapter: [],
                    });
                }
            });
    },

    addChapter: async ({}, args) => {
        return await ADD_CHAPTER(args);
    },

    delChapter: async ({}, id) => {
        return await DEL_CHAPTER(id);
    },

    updateChapter: async ({}, args) => {
        return await UPDATE_CHAPTER(args);
    },



};

const getters = {
    ChapterData: (states) => states.dataChapter
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};