import queryString from 'query-string'
import {
    GET_TYPE_DATA,
    ADD_TYPE,
    DEL_TYPE,
    UPDATE_TYPE
} from "@/api/hazard/type";


const state = {
    dataType: [],
};

const mutations = {
    setType: (states, payload) => {
        states.dataType = payload;
    },
};

const actions = {
    getType: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_TYPE_DATA(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    commit("setType", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setType", {
                        setType: [],
                    });
                }
            });
    },

    addType: async ({ }, args) => {
        return await ADD_TYPE(args);
    },

    delType: async ({ }, id) => {
        return await DEL_TYPE(id);
    },

    updateType: async ({ }, args) => {
        return await UPDATE_TYPE(args);
    },



};

const getters = {
    TypeData: (states) => states.dataType
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};