<template>
  <div class="main-content">
    <!-- HEADER -->
    <div class="header d-none">
      <div class="container-fluid">
        <div class="card mt-5">
          <div class="card-body">
            <div class="row justify-content-between align-items-center">
              <div class="col-12 col-md-9 col-xl-7">
                <!-- Heading -->
                <h2 class="mb-2">{{ $t("Dashboard") }}</h2>

                <!-- Text -->
                <p class="text-muted mb-md-0">
                  {{ $t("Welcome to GEMS Uhealth") }}
                </p>
              </div>
              <div class="col-12 col-md-auto d-none">
                <button
                  class="btn btn-primary lift"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal">
                  <span class="fe fe-folder-plus"></span>
                  Report
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Body -->
      </div>
    </div>
    <!-- / .header -->

    <div class="container-fluid mt-4">
      <div class="card">
        <!-- Image -->
        <img
          src="../assets/img/covers/profile-cover-1.jpg"
          class="card-img-top" />

        <!-- Body -->
        <div class="card-body text-center">
          <!-- Image -->
          <a class="avatar avatar-xl card-avatar card-avatar-top">
            <img
              src="../assets/img/avatars/profiles/users_1.png"
              class="avatar-img rounded-circle border border-4 border-card" />
          </a>

          <!-- Heading -->
          <h2 class="card-title">
            <p>{{ profile.fullname }}</p>
          </h2>

          <!-- Text -->
          <p class="small text-muted mb-3">
            {{ profile.company }}
          </p>

          <!-- Badges -->
          <p class="card-text">
            <span class="badge bg-secondary-soft mr-2">
              Department of {{ profile.department }}
            </span>
            <span class="badge bg-secondary-soft">
              Position as {{ profile.position }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- / .main-content -->
</template>
<script>
// import Chart from 'chart.js'
export default {
  name: "Profile",
  computed: {
    profile() {
      return this.$store.getters["summary/profileData"]
        ? this.$store.getters["summary/profileData"]
        : [];
    }
  },
  data() {
    return {};
  },
  mounted() {
    this.getProfile();
  },

  methods: {
    getProfile() {
      this.$store.dispatch("summary/getProfile").then(() => {
        let data = this.$store.getters["summary/profileData"];
        localStorage.setItem("company", data.company_id);
      });
    }
  }
};
</script>
