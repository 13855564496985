import queryString from "query-string";

import {
    GET_UNIT_DATA,
    ADD_UNIT,
    DEL_UNIT,
    UPDATE_UNIT
} from "@/api/inventory/unit";

const state = {
    dataUnit: [],
};

const mutations = {
    setUnit: (states, payload) => {
        states.dataUnit = payload;
    },
};

const actions = {
    getUnit: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_UNIT_DATA(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setUnit", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setUnit", {
                        setUnit: [],
                    });
                }
            });
    },

    addUnit: async ({ }, args) => {
        return await ADD_UNIT(args);
    },

    delUnit: async ({ }, id) => {
        return await DEL_UNIT(id);
    },

    updateUnit: async ({ }, args) => {
        return await UPDATE_UNIT(args);
    },



};

const getters = {
    UnitData: (states) => states.dataUnit
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};