<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-4">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item ">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("Non Partnership Clinic") }}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div class="card p-0">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("No Partnership Clinic") }}</h4>
          <!-- Button -->
          <div style="margin-left: 15px">
            <b-button v-b-toggle.collapse-1 variant="primary" class="ml-2">
              <span class="fe fe-sliders"></span>
            </b-button>
          </div>
          <div style="margin-right: 15px"></div>
        </div>
        <b-collapse id="collapse-1">
          <form @submit.prevent="getAllData()">
            <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
              <!-- <div class="col" v-if="filterCompany == 1">
            <div class="form-group">
              <label for="exampleInputEmail1" class="form-label">Company</label>
              <input type="text" class="form-control" v-model="body.company" />
            </div>
          </div> -->

              <!-- Select -->
              <div class="col-4" v-if="filterCompany == 1">
                <div class="form-group">
                  <label>{{ $t("Company") }}</label>
                  <v-select :options="selectCompanyOption()" v-model="body.company_id" label="company_name"
                    :reduce="(e) => e.id" />
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                $t("Employee Name")
              }}</label>
                  <input type="text" class="form-control" v-model="body.employee_name" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Date from</label>
                  <input type="date" class="form-control" placeholder="Pilih Tanggal" v-model="body.start_date"
                    name="Pilih Tanggal" />
                  <!-- <v-select v-model="body.order" :options="['ASC', 'DESC']" @input="sorting" /> -->
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Date to</label>
                  <input type="date" class="form-control" placeholder="Pilih Tanggal" v-model="body.end_date"
                    name="Pilih Tanggal" />
                  <!-- <v-select v-model="body.order" :options="['ASC', 'DESC']" @input="sorting" /> -->
                </div>
              </div>
            </div>

            <div class="row mb-3" style="margin-right: 15px">
              <div class="col"></div>
              <div class="col-auto">
                <!-- Button -->
                <button type="submit" class="btn btn-danger lift">
                  {{ $t("Filter") }}
                </button>
              </div>
            </div>
            <!-- / .row -->
          </form>
        </b-collapse>
        <vue-good-table :line-numbers="true" :columns="columns" :rows="dataGet.data" ref="my-table" :sort-options="{
            enabled: true
          }" :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: currentPage,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }" style-class="vgt-table border-0 table" @on-page-change="onPageChange" @on-per-page-change="onPageChange">
          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <!-- Custom Rows -->
          <template slot="table-column" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.label == 'NAMA KARYAWAN'">
              {{ $t("Employe Name") }}
            </span>
            <span v-if="props.column.label == 'PERUSAHAAN'">
              {{ $t("Company") }}
            </span>
            <span v-if="props.column.label == 'DIVISI'">
              {{ $t("Division") }}
            </span>
            <span v-if="props.column.label == 'STATUS'"> Status </span>
            <span v-if="props.column.label == 'JENIS KUNJUNGAN'">
              {{ $t("Jenis Kunjungan") }}
            </span>
            <span v-if="props.column.label == 'WAKTU REGIS'">
              {{ $t("Registration Date") }}
            </span>
            <span v-if="props.column.label == 'ACTION'">
              {{ $t("Action") }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.field == 'action'">
              <div v-if="validationFeatureTable(clinic_features)">
                <b-button class="no-wrap btn-pill" variant="info" size="sm" @click.prevent="detailPatient(props.row)"
                  v-if="props.row.status == 'DONE'">
                  <span class="fe fe-activity"></span>
                </b-button>
                <b-button class="no-wrap btn-pill" variant="info" size="sm" @click.prevent="diagnoseData(props.row)"
                  v-if="props.row.status == 'REGISTERED'">
                  <span class="fe fe-activity"></span>
                </b-button>
                <b-button class="no-wrap btn-pill ml-2" variant="secondary" size="sm"
                  @click.prevent="seeHistory(props.row)" v-if="props.row.status == 'REGISTERED'">
                  <span class="fe fe-trending-up"></span>
                </b-button>
                <b-button class="no-wrap ml-2" variant="danger" style="margin-left: 5px" size="sm"
                  @click="deleteData(props.row.register_id)" v-if="props.row.status == 'REGISTERED'">
                  <span class="fe fe-trash"></span>
                </b-button>
              </div>
              <!-- <div v-if="clinic_features[0].clinic_feature_id === '2'">

            </div> -->
            </span>

            <span v-else-if="props.column.field == 'register_date'">
              {{ fixTime(props.row.register_date) }}
            </span>
          </template>
        </vue-good-table>
      </div>
      <b-modal id="modal-1" size="lg" hide-footer title="Form Doctor Diagnosis">
        <form @submit.prevent="saveAdd()">
          <div class="form-group">
            <label>{{ $t("Register ID") }}</label>
            <input type="text" v-model="bodyDetail.id" class="form-control" placeholder="Clinic/RS Name"
              name="Register ID" v-validate="'required'" disabled />
            <span class="text-sm text-danger"
              v-show="errors.has('Register ID')">{{ errors.first("Register ID") }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Employee Name") }}</label>
            <input type="text" v-model="bodyDetail.patient_name" class="form-control" placeholder="Clinic/RS Name"
              name="Employee Name" v-validate="'required'" disabled />
            <span class="text-sm text-danger"
              v-show="errors.has('Employee Name')">{{ errors.first("Employee Name") }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Clinic/ RS Name") }}</label>
            <input type="text" v-model="bodyDetail.clinic_name" class="form-control" placeholder="Clinic/RS Name"
              name="Clinic" v-validate="'required'" />
            <span class="text-sm text-danger" v-show="errors.has('Clinic')">{{
              errors.first("Clinic")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Doctor Name") }}</label>
            <input type="text" v-model="bodyDetail.doctor_name" class="form-control" placeholder="Doctor Name"
              name="Doctor Name" v-validate="'required'" />
            <span class="text-sm text-danger"
              v-show="errors.has('Doctor Name')">{{ errors.first("Doctor Name") }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("File") }}</label>
            <input type="file" multiple class="form-control" v-validate="'required'" ref="fileUpload"
              @change="fileUploader2" name="file" />
            <span class="text-sm text-danger" v-show="errors.has('file')">{{
              errors.first("file")
            }}</span>
          </div>
          <button style="margin-left: auto; float: right" class="btn btn-primary" type="submit"
            v-if="isLoading == false">
            {{ $t("Save") }}
          </button>
          <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled v-else>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
  import moment from "moment";
  export default {
    name: "patient",
    computed: {
      // materialtype() {
      //   return this.$store.getters["type/TypeData"]
      //     ? this.$store.getters["type/TypeData"]
      //     : [];
      // },
      qrData() {
        return this.$store.getters["patient/qrData"] ?
          this.$store.getters["patient/qrData"] : [];
      },
      patientDataQr() {
        return this.$store.getters["patient/patientQrData"] ?
          this.$store.getters["patient/patientQrData"] : [];
      },
      dataGet() {
        return this.$store.getters["patient/allData"] ?
          this.$store.getters["patient/allData"] : [];
      },
      employees() {
        return this.$store.getters["patient/employeeData"] ?
          this.$store.getters["patient/employeeData"] : [];
      },
      drug() {
        return this.$store.getters["drug/DrugData"] ?
          this.$store.getters["drug/DrugData"] : [];
      },
      unit() {
        return this.$store.getters["unit/UnitData"] ?
          this.$store.getters["unit/UnitData"] : [];
      },
      desease() {
        return this.$store.getters["disease/deseaseData"] ?
          this.$store.getters["disease/deseaseData"] : [];
      },
      doctors() {
        return this.$store.getters["patient/doctorData"] ?
          this.$store.getters["patient/doctorData"] : [];
      },
      treatment() {
        return this.$store.getters["treatment/TreatmentData"] ?
          this.$store.getters["treatment/TreatmentData"] : [];
      },
      category() {
        return this.$store.getters["patient/categoryData"] ?
          this.$store.getters["patient/categoryData"] : [];
      },
      filtered() {
        const data = this.$store.getters["disease/deseaseData"] || [];
        return data.data;
      },
      paginated() {
        const data = this.$store.getters["disease/deseaseData"] || [];
        return data.data;
      },
      hasNextPage() {
        const data = this.$store.getters["disease/deseaseData"] || [];

        return true;
      },

      categoryTest() {
        return this.$store.getters["categoryMcuTest/CategoryData"] ?
          this.$store.getters["categoryMcuTest/CategoryData"] : [];
      },
      groupTest() {
        return this.$store.getters["groupMcu/GroupData"] ?
          this.$store.getters["groupMcu/GroupData"] : [];
      },
      itemTest() {
        return this.$store.getters["itemMcu/ItemData"] ?
          this.$store.getters["itemMcu/ItemData"] : [];
      },
      companyFilter() {
        return this.$store.getters["detailManagement/dataCompany"] ?
          this.$store.getters["detailManagement/dataCompany"] : [];
      }
    },
    data() {
      return {
        // sockets: {},
        // lol: {},
        currentPage: 1,
        filterCompany: "",
        search: {
          no_ktp: "",
          fullname: "",
          nik: "",
          date_of_birth: ""
        },
        file: null,
        body: {
          company_id: "",
          purpose_visit: 2,
          employee_name: "",
          start_date: moment().format("YYYY-MM-DD"),
          end_date: moment().format("YYYY-MM-DD"),
        },
        bodyDetail: {
          id: "",
          patient_name: "",
          clinic_name: "",
          doctor_name: "",
          files: []
        },
        isLoading: false,
        diagnose: {
          id: "",
          init_complaint: "",
          weight: "",
          height: "",
          blood_pressure: "",
          allergy: "",
          visit_type: "",
          doctor: "",
          main_complaint: "",
          vital_sign: "",
          disease_id: "",
          spell: "",
          pak: "",
          disease: [{
            disease_id: ""
          }],
          drugs: [{
            drugs_id: "",
            qty: "",
            drugs_unit_id: ""
          }],
          doctor_action: "",
          action_care: "",
          follow_up: "",
          next_visit: "",
          doctor_note: "",
          sick_leave: "",
          sick_leave_day: ""
        },
        mcuData: {
          register_id: "",
          mcu_record: []
        },
        importPayload: [{
            name: "Riwayat",
            file: null,
            sample: "/mcu_riwayat.xlsx"
          },
          {
            name: "Non Laboratorium",
            file: null,
            sample: "/mcu_non_lab.xlsx"
          },
          {
            name: "Laboratorium",
            file: null,
            sample: "/mcu_lab.xlsx"
          },
          {
            name: "Fisik",
            file: null,
            sample: "/mcu_fisik.xlsx"
          }
        ],
        dataPatient: {
          blood_type: "",
          clinic_id: "",
          code: "",
          company: "",
          company_id: "",
          date_of_birth: "",
          department: "",
          fullname: "",
          gender: "",
          id: "",
          nik: "",
          position: "",
          status: "",
          init_complaint: "",
          weight: "",
          height: "",
          blood_pressure: "",
          allergy: "",
          clinic_feature_id: ""
        },
        dataPatient2: {
          id: "",
          clinic_id: "",
          clinic_feature_id: "",
          company: "",
          employee_age: "",
          length_of_work: "",
          mcu_year: "",
          married_status: "",
          mcu_category_id: "",
          fullname: "",
          date_of_birth: "",
          department: "",
          gender: "",
          nik: "",
          position: "",
          no_lab: "",
          mcu_location: ""
        },
        type_visit: "",
        register_id: 0,
        clinic_ids: "",
        clinic_features: [],
        feature_id: "",
        datasRegis: {},
        load: false,
        kunjungan: null,
        id: "",
        statuss: "",
        isManualInput: true,
        isQRCode: true,
        isUpdate: true,
        qrButton: false,
        manualButton: false,
        recs: "",
        columns: [{
            label: "NAMA KARYAWAN",
            field: "patient_name",
            thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "PERUSAHAAN",
            field: "company",
            thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "DIVISI",
            field: "department",
            thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "STATUS",
            field: "status",
            thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "JENIS KUNJUNGAN",
            field: "type_visit",
            thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "WAKTU REGIS",
            field: "register_date",
            thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "ACTION",
            field: "action",
            sortable: false,
            thClass: "table-header no-wrap text-muted text-center font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          }
        ],
        desease_search: false,
        desease_timeout: null,
        desease_paramater: {
          page: 1,
          limit: 10,
          disease_name: ""
        },
        desease_observer: null,
        columnsSearch: [{
            label: "NAMA KARYAWAN",
            field: "fullname",
            thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "NIK",
            field: "nik",
            thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "NO KTP",
            field: "no_ktp",
            thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "DATE OF BIRTH",
            field: "date_of_birth",
            thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "ACTION",
            field: "action",
            thClass: "table-header no-wrap text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          }
        ],
        user_role: []
      };
    },
    mounted() {
      const companyId = localStorage.getItem("company");
      this.body.company_id = companyId;
      let user_role = localStorage.getItem("user_roles");
      this.role_user = JSON.parse(user_role);
      this.clinic_ids = localStorage.getItem("clinic_id");
      // this.body.clinic_id = this.clinic_ids;
      const roles = localStorage.getItem("roles_id");
      this.filterCompany = parseInt(roles) == 1 ? 1 : 0;
      this.getAllData();
      this.getDrug();
      this.getUnit();
      this.getCompany();
      this.desease_observer = new IntersectionObserver(this.getDesease);
      this.body.clinic_id = this.clinic_ids;
      this.dataPatient.clinic_id = this.clinic_ids;
      this.clinic_features = JSON.parse(localStorage.getItem("clinic_feature"));
      this.getDoctor();
      this.getTreatment();
      this.getCategory();
      this.getCategorys();
    },

    methods: {
      setFile(e, i) {
        const files = e.target.files;
        if (files) {
          const file = e.target.files[0];
          this.importPayload[i]["file"] = file;
        }
      },
      async fileUploader2(event) {
        this.bodyDetail.files = event.target.files;
      },
      async importData() {
        try {
          const importPayload = this.importPayload;
          const invalidFile = importPayload.findIndex((e) => !e.file);

          if (invalidFile > -1) {
            this.$swal({
              icon: "error",
              title: "Error",
              text: "All input must be filled"
            });
            return;
          }

          let i = 0;
          while (i < importPayload.length) {
            const file = importPayload[i]["file"];
            const formData = new FormData();
            formData.append("register_id", this.register_id);
            formData.append("files", file);

            this.$store.dispatch("patient/importRegisterPatient", formData);
            i++;
          }
          this.getAllData();
          return;
        } catch (err) {
          console.log("Error while importing mcu record", err);
        }
      },
      addDrugs() {
        const blank_drugs = {
          drugs_id: "",
          qty: "",
          drugs_unit_id: ""
        };
        this.diagnose.drugs.push(blank_drugs);
      },
      addDisease() {
        const blank_disease = {
          disease_id: ""
        };
        this.diagnose.disease.push(blank_disease);
      },
      removeDrugs(index) {
        this.diagnose.drugs.splice(index, 1);
      },
      removeDisease(index) {
        this.diagnose.disease.splice(index, 1);
      },

      validationFeatureTable(value) {
        const clinicFeature =
          this.role_user.length > 0 ?
          this.role_user[0].healthcare_facility_feature : [];
        const invent = clinicFeature.findIndex(
          (el) =>
          el.healthcare_facility_feature_id === "1" ||
          el.healthcare_facility_feature_id === "3"
        );
        // const index = value.findIndex(
        //   (e) => e.clinic_feature_id === "1" || e.clinic_feature_id === "3"
        // );
        if (invent > -1) {
          return true;
        } else {
          return false;
        }
      },

      newRecord() {
        const blank_record = {
          category_name: "",
          group_name: "",
          check_title: "",
          check_value: ""
        };
        this.mcuData.mcu_record.push(blank_record);
      },
      removeRecord(i) {
        this.mcuData.mcu_record.splice(i, 1);
      },

      fixTime(data) {
        return moment(data).format("YYYY-MM-DD");
      },

      selectDropdown(unit) {},

      getDataEmploye(e) {
        let id = this.dataPatient.fullname;
        let datas = e.filter((x) => x.id === id);
        this.dataPatient.company = datas[0].company;
        if (datas[0].gender === "M") {
          this.dataPatient.gender = "Male";
        } else if (datas[0].gender === "F") {
          this.dataPatient.gender = "Female";
        } else {
          this.dataPatient.gender = "Unknown";
        }
        this.dataPatient.date_of_birth = datas[0].date_of_birth;
        this.dataPatient.nik = datas[0].nik;
        this.dataPatient.blood_type = datas[0].blood_type;
        this.dataPatient.position = datas[0].position;
        this.dataPatient.department = datas[0].department;
        this.datasRegis = datas[0];
      },

      dataSelected(props) {
        this.manualButton = true;
        this.qrButton = false;
        this.$bvModal.hide("modal-5");
        this.$bvModal.show("modal-2");

        this.dataPatient.fullname = props.fullname;
        this.dataPatient.company = props.company;
        this.dataPatient.department = props.department;
        this.dataPatient.date_of_birth = props.date_of_birth;
        this.dataPatient.nik = props.nik;
        this.dataPatient.position = props.position;
        this.dataPatient.gender = props.gender;
        this.dataPatient.blood_type = props.blood_type;

        this.dataPatient2.company = props.company;
        this.dataPatient2.fullname = props.fullname;
        this.dataPatient2.id = props.id;
        this.dataPatient2.clinic_id = this.body.clinic_id;
        this.dataPatient2.date_of_birth = props.date_of_birth;
        this.dataPatient2.nik = props.nik;
        this.dataPatient2.position = props.position;
        this.dataPatient2.gender = props.gender;
        this.dataPatient2.department = props.department;
      },

      registerPatient() {
        if (this.recs === "Clinic") {
          this.$store
            .dispatch("patient/registerPatient", this.dataPatient)
            .then(() => {
              this.$swal({
                icon: "success",
                title: "Success",
                text: "Data has been Register"
              });
              this.qrButton = false;
              this.$bvModal.hide("modal-2");
              this.getAllData();
              this.dataPatient.weight = "";
              this.dataPatient.height = "";
              this.dataPatient.allergy = "";
              this.dataPatient.blood_pressure = "";
              this.dataPatient.init_complaint = "";
            });
        } else {}
      },
      registerPatient2() {
        if (this.recs === "Clinic") {
          delete this.dataPatient.code;
          delete this.dataPatient.status;
          delete this.dataPatient.company_id;
          delete this.dataPatient.id;
          this.$store
            .dispatch("patient/registerPatient2", this.dataPatient)
            .then(() => {
              this.$swal({
                icon: "success",
                title: "Success",
                text: "Data has been Register"
              });
              this.manualButton = false;
              this.$bvModal.hide("modal-2");
              this.getAllData();
              this.dataPatient.weight = "";
              this.dataPatient.height = "";
              this.dataPatient.allergy = "";
              this.dataPatient.blood_pressure = "";
              this.dataPatient.init_complaint = "";
            });
        } else {
          this.$store
            .dispatch("patient/registerPatient2", this.dataPatient2)
            .then(() => {
              this.$swal({
                icon: "success",
                title: "Success",
                text: "Data has been Register"
              });
              this.qrButton = false;
              this.$bvModal.hide("modal-2");
              this.getAllData();
            });
        }
      },

      diagnoseData(props) {
        this.$bvModal.show("modal-1");
        this.bodyDetail.id = props.register_id;
        this.bodyDetail.patient_name = props.patient_name;
        this.bodyDetail.clinic_name = props.clinic_name;
        this.bodyDetail.doctor_name = props.doctor;
      },

      diagnosePatient() {
        let sick_leave_days = Number(this.diagnose.sick_leave_day);
        this.diagnose.sick_leave_day = sick_leave_days;
        this.$validator.validateAll().then(async (res) => {
          if (!res) return false;
          try {
            this.$store
              .dispatch("patient/diagnosePatient", this.diagnose)
              .then(() => {
                this.getAllData();
                this.$bvModal.hide("modal-1");
                this.$swal({
                  icon: "success",
                  title: "Success",
                  text: "Patient has been Register"
                });
                this.diagnose = {
                  id: "",
                  init_complaint: "",
                  weight: "",
                  height: "",
                  blood_pressure: "",
                  allergy: "",
                  visit_type: "",
                  doctor: "",
                  main_complaint: "",
                  vital_sign: "",
                  disease_id: "",
                  spell: "",
                  pak: "",
                  drugs: [{
                    drugs_id: "",
                    qty: "",
                    drugs_unit_id: ""
                  }],
                  doctor_action: "",
                  action_care: "",
                  follow_up: "",
                  next_visit: "",
                  doctor_note: ""
                };
              });
          } catch (resp) {
            this.$swal("Error", this.$t("Error while add data"), "error");
          }
        });
      },

      deleteData(id) {
        this.$swal({
          title: this.$t("Are you sure?"),
          text: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00D97E",
          cancelButtonColor: "#E63757",
          confirmButtonText: this.$t("Yes, delete it!"),
          cancelButtonText: this.$t("Cancel")
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("patient/deletePatient", id)
              .then((resp) => {
                this.getAllData();
                this.$swal(
                  "Deleted!",
                  "This Record has been deleted!",
                  "success"
                );
              })
              .catch((resp) => {
                this.$swal("Error", this.$t("Error while delete data"), "error");
              });
          }
        });
      },

      saveRecord() {
        this.$validator.validateAll().then(async (res) => {
          if (!res) return false;
          try {
            this.$store
              .dispatch("patient/diagnosePatient", this.mcuData)
              .then(() => {
                this.getAllData();
                this.$bvModal.hide("modal-1");
                this.mcuData = {
                  register_id: "",
                  mcu_record: []
                };
                this.$swal({
                  icon: "success",
                  title: "Success",
                  text: "Patient has been Register"
                });
              });
          } catch (resp) {
            this.$swal("Error", this.$t("Error while add data"), "error");
          }
        });
      },

      seeHistory(dat) {
        localStorage.setItem("dataMedical", JSON.stringify(dat));
        localStorage.setItem("seeHistory", JSON.stringify(dat));
        localStorage.setItem("breadcrumb", "No Partnership Clinic");
        this.$router.push("/clinic-mcu/history");
      },

      detailPatient(dat) {
        localStorage.setItem("registerID", dat.register_id);
        localStorage.setItem("breadcrumb", "No Partnership Clinic");
        localStorage.setItem("dataMedical", JSON.stringify(dat));
        this.$router.push("/clinic-mcu/history/record-detail");
      },

      selectCompanyOption() {
        const company = this.companyFilter;
        const term = [];
        term.push({
          id: null,
          company_name: "BIB + Mitra"
        });

        company.map((i) => {
          term.push({
            id: i.id,
            company_name: i.company_name
          });
        });
        return term;
      },

      // api hit
      async getDesease(e) {
        await this.$store.dispatch("disease/getDesease", this.desease_paramater);
      },
      async getDoctor() {
        await this.$store.dispatch("patient/getDoctor");
      },

      saveAdd() {
        this.$validator.validateAll().then(async (res) => {
          const formData = new FormData();
          formData.append("id", this.bodyDetail.id);
          formData.append("patient_name", this.bodyDetail.patient_name);
          formData.append("clinic_name", this.bodyDetail.clinic_name);
          formData.append("doctor", this.bodyDetail.doctor_name);
          for (let file of this.bodyDetail.files) {
            formData.append("files", file); // note, no square-brackets
          }
          if (!res) return false;
          try {
            this.isLoading = true;
            this.$store
              .dispatch("patient/addNoPartnership", formData)
              .then((resp) => {
                this.$swal("Saved", "Your input has been saved", "success").then(
                  (resp) => {
                    this.isLoading = false;
                    this.bodyDetail.id = "";
                    this.bodyDetail.patient_name = "";
                    this.bodyDetail.clinic_name = "";
                    this.bodyDetail.doctor_name = "";
                    this.bodyDetail.files = [];
                    this.getAllData();
                    this.$bvModal.hide("modal-1");
                  }
                );
              });
          } catch (resp) {
            this.$swal("Error", "Error while add data", "error");
          }
        });
      },

      async getDetailDisease(e) {
        let id = e;
        await this.$store.dispatch("disease/getDetailDisease", id);
      },

      getCategorys() {
        let body = {
          limit: 1000,
          page: 1
        };
        this.$store.dispatch("categoryMcuTest/getCategory", body);
      },
      getGroup() {
        let body = {
          limit: 1000,
          page: 1
        };
        this.$store.dispatch("groupMcu/getGroup", body);
      },
      getItem() {
        let body = {
          limit: 1000,
          page: 1
        };
        this.$store.dispatch("itemMcu/getItem", body);
      },
      getCompany() {
        this.$store.dispatch("detailManagement/getCompany");
      },

      async onOpen() {
        if (this.hasNextPage) {
          await this.$nextTick();
          this.desease_observer.observe(this.$refs.load);
        }
      },
      onClose() {
        this.desease_observer.disconnect();
      },

      getAllData() {

        this.currentPage = 1;
        // this.$refs['my-table'].reset();
        this.$store.dispatch("patient/getAllData", this.body);
      },
      getTreatment() {
        let body = {
          limit: 100,
          page: 1
        };
        this.$store.dispatch("treatment/getTreatment", body);
      },

      getEmployee() {
        this.load = true;
        this.$store.dispatch("patient/getDataEmoloyee", this.search).then(() => {
          this.load = false;
        });
      },
      getDrug() {
        let body = {
          limit: 1000,
          page: 1
        };
        this.$store.dispatch("drug/getDrug", body);
      },
      getUnit() {
        let body = {
          limit: 1000,
          page: 1
        };
        this.$store.dispatch("unit/getUnit", body);
      },
      getCategory() {
        let body = {
          limit: 100,
          page: 1
        };
        this.$store.dispatch("categoryMcuTest/getCategory", body);
      },

      getCategory() {
        // let body = {
        //   limit: 100,
        //   page: 1,
        // };
        this.$store.dispatch("patient/getCategoryMcu");
      },

      // filter
      filterPage() {
        this.getName();
      },

      sorting() {
        this.getName();
      },

      // Pagination
      onPageChange(payload) {
        //   "total",
        //   parseInt(payload.currentPage) * parseInt(payload.currentPerPage)
        // );
        if (
          parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
          1000
        ) {
          this.body.limit = payload.currentPerPage;
          this.body.page = payload.currentPage;
          this.getName();
        }
      },

      // disease

      prevDisease() {
        let page = this.desease_paramater.page;
        page -= 1;
        page = page <= 1 ? 1 : page;

        this.desease_paramater.page = page;
        this.getDesease();
      },

      nextDisease() {
        const disase_data = this.paginated;
        const total_page = disase_data.totalPages;
        let page = this.desease_paramater.page;
        page += 1;
        page = page <= 1 ? 1 : page;
        if (page >= total_page) {
          page = totalPages;
        }

        this.desease_paramater.page = page;
        this.getDesease();
      },

      async searchDisease(search, loading) {
        if (search) {
          loading(true);

          clearTimeout(this.desease_timeout);
          this.desease_timeout = setTimeout(async () => {
            this.desease_paramater.disease_name = search;

            await this.getDesease();

            loading(false);
          }, 500);
        }
      }
    }
  };
</script>

<style scoped>
  .pagination-select {
    display: flex;
    margin: 0.25rem 0.25rem 0;
  }

  .pagination-select button {
    flex-grow: 1;
  }

  .pagination-select button:hover {
    cursor: pointer;
  }
</style>