import axiosIns from '@/api/axios'

export const GET_MEASURING_ACTIVITY_DATA = (args) => {
    return axiosIns.get(`/ih/measuring/activity?${args}`)
}

export const ADD_MEASURING_ACTIVITY = (args) => {
    return axiosIns.post(`/ih/measuring/activity/create`, args)
}

export const DEL_MEASURING_ACTIVITY = (id) => {
    return axiosIns.put(`/ih/measuring/activity/delete?id=${id}`)
}

export const UPDATE_MEASURING_ACTIVITY  = (args) => {
    return axiosIns.put(`/ih/measuring/activity/update?id=${args.id}`, args)
}