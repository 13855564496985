import queryString from "query-string";

import {
    GET_GROUP_DATA,
    ADD_GROUP,
    DEL_GROUP,
    UPDATE_GROUP
} from "@/api/mcu/test/group";

const state = {
    dataGroup: [],
};

const mutations = {
    setGroup: (states, payload) => {
        states.dataGroup = payload;
    },
};

const actions = {
    getGroup: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_GROUP_DATA(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    commit("setGroup", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setGroup", {
                        setGroup: [],
                    });
                }
            });
    },

    addGroup: async ({}, args) => {
        return await ADD_GROUP(args);
    },

    delGroup: async ({}, id) => {
        return await DEL_GROUP(id);
    },

    updateGroup: async ({}, args) => {
        return await UPDATE_GROUP(args);
    },



};

const getters = {
    GroupData: (states) => states.dataGroup
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};