import axiosIns from '@/api/axios'

export const GET_MEASURING_METHOD_DATA = (args) => {
    return axiosIns.get(`/ih/measuring/method?${args}`)
}

export const ADD_MEASURING_METHOD = (args) => {
    return axiosIns.post(`/ih/measuring/method/create`, args)
}

export const DEL_MEASURING_METHOD = (id) => {
    return axiosIns.put(`/ih/measuring/method/delete?id=${id}`)
}

export const UPDATE_MEASURING_METHOD  = (args) => {
    return axiosIns.put(`/ih/measuring/method/update?id=${args.id}`, args)
}