import queryString from 'query-string'

import { 
    GET_ROLE_MANAGEMENT_DATA, 
    ADD_ROLE_MANAGEMENT,
	UPDATE_ROLE_MANAGEMENT,
	DELETE_ROLE_MANAGEMENT,
    GET_DATA_HEALTHCARE_FACILITY,
    GET_DATA_COMPANY,
	GET_DATA_STAFF_ROLES,
    GET_DATA_ROLES,
    GET_DATA_USER } from '@/api/role_management/role_management';

const state = {
    dataRoleManagement: [],
    datahealthcareFacility: [],
	dataCompany: [],
	dataStaffRoles: [],
    dataRoles: [],
	dataUser: [],
};

const mutations = {
    setRoleManagement: (states, payload) => {
        states.dataRoleManagement = payload;
    },
    setHealthCareFacility: (states, payload) => {
		states.datahealthcareFacility = payload;
	},
	setCompany: (states, payload) => {
		states.dataCompany = payload;
	},
	setStaffRoles: (states, payload) => {
		states.dataStaffRoles = payload;
	},
    setRoles: (states, payload) => {
		states.dataRoles = payload;
	},
	setUser: (states, payload) => {
		states.dataUser = payload;
	},
};

const actions = {
    getDataRoleManagement: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        try {
            const resp = await GET_ROLE_MANAGEMENT_DATA(params)
            const datas = resp.data.data
            commit("setRoleManagement", datas);
        } catch (err) {
            let resp = err.response;
            console.log(err)
            if (resp.data.httpCode === 404) {
                commit("setRoleManagement", {
                    setRoleManagement: [],
                });
            }
        }
    },

    addRoleManagement: async ({ }, args) => {
        return await ADD_ROLE_MANAGEMENT(args);
    },

	updateRoleManagement: async ({ }, args) => {
        return await UPDATE_ROLE_MANAGEMENT(args);
    },

	deleteRoleManagement: async ({ }, nik) => {
		const params = queryString.stringify(nik);
        return await DELETE_ROLE_MANAGEMENT(params);
    },

    getHealthCare: async ({ commit }) => {
		try {
			const resp = await GET_DATA_HEALTHCARE_FACILITY();
			const datas = resp.data.data.data;
			commit("setHealthCareFacility", datas);
		} catch (err) {
			let resp = err.response;
			console.log(err);
			if (resp.data.httpCode === 404) {
				commit("setHealthCareFacility", {
					setHealthCareFacility: [],
				});
			}
		}
	},

	getCompany: async ({ commit }) => {
		try {
			const resp = await GET_DATA_COMPANY();
			const datas = resp.data.data.data;
			commit("setCompany", datas);
		} catch (err) {
			let resp = err.response;
			console.log(err);
			if (resp.data.httpCode === 404) {
				commit("setCompany", {
					setCompany: [],
				});
			}
		}
	},

	getStaffRoles: async ({ commit }) => {
		try {
			const resp = await GET_DATA_STAFF_ROLES();
			const datas = resp.data.data.data;
			commit("setStaffRoles", datas);
		} catch (err) {
			let resp = err.response;
			console.log(err);
			if (resp.data.httpCode === 404) {
				commit("setStaffRoles", {
					setStaffRoles: [],
				});
			}
		}
	},

    getRoles: async ({ commit }) => {
		try {
			const resp = await GET_DATA_ROLES();
			const datas = resp.data.data.data;
			commit("setRoles", datas);
		} catch (err) {
			let resp = err.response;
			console.log(err);
			if (resp.data.httpCode === 404) {
				commit("setRoles", {
					setRoles: [],
				});
			}
		}
	},

	getUser: async ({ commit }, args) => {
		const params = queryString.stringify(args);
		try {
			const resp = await GET_DATA_USER(params);
			const datas = resp.data.data;
			commit("setUser", datas);
		} catch (err) {
			let resp = err.response;
			console.log(err);
			if (resp.data.httpCode === 404) {
				commit("setUser", {
					setUser: [],
				});
			}
		}
	},
};

const getters = {
    roleManagement: (states) => states.dataRoleManagement,
    datahealthcareFacility: (states) => states.datahealthcareFacility,
	dataCompany: (states) => states.dataCompany,
	dataStaffRoles: (states) => states.dataStaffRoles,
    dataRoles: (states) => states.dataRoles,
	dataUser: (states) => states.dataUser,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};