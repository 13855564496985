<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-6">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item ">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item">{{ $t("Medical Waste") }}</li>
                <li class="breadcrumb-item fw-bolder">Bin</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Table -->
      <div class="row">
        <div class="col">
          <div class="card mt-2">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title">{{ $t("List Bin") }}</h4>
              <!-- Button -->
              <!-- <router-link tag="b-button" variant="primary" to="/clinic-management/input-do">
                                <span class="fe fe-file-plus"></span>
                                Create New DO
                            </router-link> -->
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-end mb-3">
                <label class="align-self-center mb-0 mr-3">Search</label>
                <b-form-input
                  v-model="body.searchQuery"
                  class="w-auto"
                  placeholder="Type Here..."
                  @change="getDataBin()"></b-form-input>
              </div>
              <vue-good-table
                mode="remote"
                @on-selected-rows-change="selectionChanged"
                :columns="listcolumns"
                :rows="dataBin.rows"
                :total-rows="dataBin.totalRecords"
                :sort-options="{
                  enabled: true
                }"
                :select-options="{ enabled: true }"
                :pagination-options="{
                  enabled: true,
                  mode: 'pages',
                  position: 'bottom',
                  perPage: 10,
                  perPageDropdown: [10, 50, 100],
                  dropdownAllowAll: false,
                  setCurrentPage: 1,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'page',
                  allLabel: 'All'
                }"
                style-class="vgt-table table table-bordered"
                @on-page-change="onPageChange"
                @on-per-page-change="onPageChange"
                @on-sort-change="onSortChange"
                :isLoading.sync="isLoading">
                <!-- Loading -->
                <div slot="selected-row-actions">
                  <b-button
                    class="no-wrap btn-pill"
                    title="Detail"
                    variant="primary"
                    size="sm"
                    v-b-modal.confirm
                    @click="detail(props.row)">
                    Submit
                  </b-button>
                </div>
                <div slot="emptystate" class="text-center font-size-14">
                  No data available
                </div>
                <template slot="table-row" slot-scope="props">
                  <!-- Table Action -->
                  <span v-if="props.column.field == 'action'">
                    <b-button
                      class="no-wrap btn-pill"
                      title="Detail"
                      variant="info"
                      size="sm"
                      v-b-modal.modal-lg
                      @click="detail(props.row)">
                      <span class="fe fe-clipboard"></span>
                    </b-button>
                    <b-button
                      v-if="props.row.po_status === 'SAVED'"
                      class="no-wrap ml-2"
                      variant="warning"
                      title="Edit"
                      style="margin-left: 5px"
                      size="sm"
                      @click.prevent="
                        $router.push(
                          `/clinic-management/input-po?id=${props.row.id}`
                        )
                      ">
                      <span class="fe fe-edit"></span>
                    </b-button>
                    <b-button
                      v-if="props.row.po_status === 'SAVED'"
                      class="no-wrap ml-2"
                      variant="danger"
                      title="Cancel"
                      style="margin-left: 5px"
                      size="sm"
                      @click="deleteData(props.row.id)">
                      <span class="fe fe-archive"></span>
                    </b-button>
                  </span>
                  <span
                    v-else-if="props.column.field == 'do_status'"
                    v-html="badges(props.row.do_status)">
                  </span>
                  <!-- <span v-else-if="props.column.field == 'price'">
                                        Rp. {{ convertNumber(props.row.price) }}
                                    </span> -->
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
      <b-modal id="confirm" title="Disposal Medical Waste" hide-footer>
        <div class="form-group">
          <label for="inputPassword">{{ $t("Disposal ID") }}</label>
          <input
            type="text"
            class="form-control"
            placeholder="Disposal ID"
            v-model="dispWaste.sap_mw_number" />
        </div>
        <div class="form-group">
          <label for="inputPassword">{{ $t("Disposal Partner") }}</label>
          <v-select
            v-model="dispWaste.disposal_partner"
            :reduce="(option) => option.id"
            :options="partner.rows"
            label="partner_name" />
        </div>
        <div class="form-group">
          <label for="inputPassword">{{ $t("Disposal Date") }}</label>
          <input
            type="date"
            class="form-control"
            placeholder="Pilih Tanggal"
            v-validate="'required'"
            name="Tanggal"
            v-model="dispWaste.disposal_date" />
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1" class="form-label">Note</label>
          <textarea type="text" class="form-control" v-model="dispWaste.note" />
        </div>
        <button
          style="margin-left: auto; float: right"
          class="mt-4 btn btn-primary"
          type="submit"
          @click="submitForm">
          {{ $t("Submit") }}
        </button>
      </b-modal>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "inventoryInputDO",
  computed: {
    dataBin() {
      return this.$store.getters["medicalWaste/binData"]
        ? this.$store.getters["medicalWaste/binData"]
        : [];
    },
    partner() {
      return this.$store.getters["partner/PartnerData"]
        ? this.$store.getters["partner/PartnerData"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        perPage: 10,
        searchQuery: "",
        sort: ""
      },
      dataDetail: {},
      datas: [
        {
          id: 1,
          sap_code: "#5491",
          suply: "PT. Kalbe",
          qty: "100",
          price: "150000",
          status: "Order"
        },
        {
          id: 2,
          sap_code: "#2313",
          suply: "PT. Kimia Farma",
          qty: "150",
          price: "250000",
          status: "Order"
        }
      ],
      listcolumns: [
        {
          label: "ID",
          field: "id",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "SAP MATERIAL NUMBER",
          field: "sap_material_number",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "ITEM NAME",
          field: "item_label",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "QTY",
          field: "item_uom_label",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Status",
          field: "mw_category",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle text-center"
        },
        {
          label: "EXP DATE",
          field: "expiry_date",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle text-center"
        }
        // {
        //     label: "ACTION",
        //     field: "action",
        //     thClass: "table-header no-wrap text-muted font-weight-bold",
        //     tdClass: "no-wrap color-secondary align-middle text-center",
        //     sortable: false
        // },
      ],
      isLoading: false,
      dispWaste: {
        sap_mw_number: "",
        disposal_partner: "",
        disposal_date: "",
        note: "",
        items: []
      }
    };
  },
  mounted() {
    this.getDataBin();
    this.getPartner();
  },
  methods: {
    async getDataBin() {
      this.isLoading = true;
      this.$store.dispatch("medicalWaste/getBinData", this.body).finally(() => {
        this.isLoading = false;
      });
    },

    getPartner() {
      this.$store.dispatch("partner/getPartner", {
        page: 1,
        perPage: 1000,
        sort: "",
        searchQuery: ""
      });
    },

    convertNumber(num) {
      return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    convertDate(d) {
      return moment(d).format("D MMM YYYY");
    },

    onSortChange(params) {
      let sort1 = params[0].field;
      let sort2 = params[0].type;
      this.body.sort = `${sort1},${sort2}`;
      this.getDataBin();
    },

    onPageChange(payload) {
      this.body.perPage = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getDataBin();
    },

    deleteData(id) {
      let body = {
        id: id
      };
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("Do/deleteDO", body).then(() => {
              this.getDataBin();
              this.$swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              );
            });
          }
        });
    },

    detail(props) {
      this.dataDetail = props;
    },

    badges(status) {
      switch (status) {
        case "SAVED":
          return `<span class="badge bg-info-soft">SAVED</span>`;
        case "DELIVERED":
          return `<span class="badge bg-success-soft">DELIVERED</span>`;
        case "CANCELED":
          return `<span class="badge bg-danger-soft">CANCELED</span>`;
        default:
          break;
      }
    },

    convertCurrency(dat) {
      return dat.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    selectionChanged(e) {
      this.dispWaste.items = e.selectedRows.map((item) => {
        return item.id;
      });
    },

    submitForm() {
      this.$store
        .dispatch("medicalWaste/submitMedical", this.dispWaste)
        .then(() => {
          this.$bvModal.hide("confirm");
          this.$swal("Success", "Disposal Data has been Created ", "success");
          this.getDataBin();
        });
    }

    // searchByQuery(e) {
    // }
  }
};
</script>
<style>
.po-table td:first-child,
.po-table td:nth-last-child(3),
.po-table td:nth-last-child(2),
.po-table td:last-child {
  text-align: center;
}

.po-table > :not(caption) > * > * {
  padding: 0.5rem !important;
}
</style>
