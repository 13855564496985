import axiosIns from '@/api/axios'

// Add
export const GET_SELECTED_PRODUCT = (args) => {
    return axiosIns.get(`/inventory/stock-opname/cart/item?${args}`)
}

export const GET_SELECTED_EXP = (args) => {
    return axiosIns.get(`/inventory/stock-opname/cart/exp?${args}`)
}

export const SAVE_REVISION = (args) => {
    return axiosIns.post('/inventory/stock-opname/cart', args)
}

export const GET_TABLE_ADD = (args) => {
    return axiosIns.get(`/inventory/stock-opname/clinic-items?${args}`)
}

export const DELETE_ROW_REV = (args) => {
    return axiosIns.delete(`/inventory/stock-opname/cart?id=${args}`)
}

export const SUBMIT_REV = (args) => {
    return axiosIns.post('/inventory/stock-opname/submit', args)
}


// Main Page
export const GET_TABLE_OPNAME = (args) => {
    return axiosIns.get(`/inventory/stock-opname?${args}`)
}

// Detail
export const GET_DETAIL_OPNAME = (args) => {
    return axiosIns.get(`/inventory/stock-opname/detail?${args}`)
}

