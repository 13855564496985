import axiosIns from '@/api/axios'

export const GET_ITEM_DATA = (args) => {
    return axiosIns.get(`/mcu/test/item?${args}`)
}

export const ADD_ITEM = (args) => {
    return axiosIns.post(`/mcu/test/item/create`, args)
}

export const DEL_ITEM = (id) => {
    return axiosIns.put(`/mcu/test/item/delete?id=${id}`)
}

export const UPDATE_ITEM  = (args) => {
    return axiosIns.put(`/mcu/test/item/update?id=${args.id}`, args)
}