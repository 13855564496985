import axiosIns from '@/api/axios'

export const GET_QUESTION_DATA = (args) => {
    return axiosIns.get(`/mcu/question-type?${args}`)
}

export const ADD_QUESTION_TYPE = (args) => {
    return axiosIns.post(`/mcu/question-type/create`, args)
}

export const DEL_QUESTION_TYPE = (id) => {
    return axiosIns.put(`/mcu/question-type/delete?id=${id}`)
}

export const UPDATE_QUESTION_TYPE = (args) => {
    return axiosIns.put(`/mcu/question-type/update?id=${args.id}`, args)
}