import queryString from "query-string";

import {
    GET_FACTOR_DATA,
    ADD_FACTOR,
    DEL_FACTOR,
    UPDATE_FACTOR
} from "@/api/ih/factor";

const state = {
    dataFactor: [],
};

const mutations = {
    setFactor: (states, payload) => {
        states.dataFactor = payload;
    },
};

const actions = {
    getFactor: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_FACTOR_DATA(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setFactor", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setFactor", {
                        setFactor: [],
                    });
                }
            });
    },

    addFactor: async ({ }, args) => {
        return await ADD_FACTOR(args);
    },

    delFactor: async ({ }, id) => {
        return await DEL_FACTOR(id);
    },

    updateFactor: async ({ }, args) => {
        return await UPDATE_FACTOR(args);
    },



};

const getters = {
    FactorData: (states) => states.dataFactor
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};