import queryString from "query-string";
import {
    GET_UNIT_PRODUCT,
    ADD_UNIT_PRODUCT,
    GET_INPUT_PRODUCT,
    DEL_INPUT_PRODUCT,
    SUBMIT_DO,
    GET_LIST_DO,
    DELETE_LIST_DO,
    GET_DETAIL_DO,
    GET_EDIT_DO,
    UPDATE_INNER_DO,
    GET_PO_REF,
    GET_REF_ID,
    SAVE_DO,
    COPY_PRODUCT
} from "@/api/clinic/do";

const state = {
    dataUnitProduct: [],
    dataInputProduct: [],
    dataListDO: [],
    dataDetailDO: [],
    dataEditDO: {},
    dataReference: [],
    datRefs: []
};

const mutations = {
    setUnitProduct: (states, payload) => {
        states.dataUnitProduct = payload;
    },
    setInputProduct: (states, payload) => {
        states.dataInputProduct = payload;
    },
    setDataDO: (states, payload) => {
        states.dataListDO = payload;
    },
    setDetailDO: (states, payload) => {
        states.dataDetailDO = payload;
    },
    setEditDO: (states, payload) => {
        states.dataEditDO = payload;
    },
    setReference: (states, payload) => {
        states.dataReference = payload;
    },
    setDataRefs: (states, payload) => {
        states.datRefs = payload;
    },
}

const actions = {
    getUnitProduct: async ({
        commit
    }, args) => {
        // const params = queryString.stringify(args);
        return await GET_UNIT_PRODUCT(args)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setUnitProduct", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setUnitProduct", {
                        setUnitProduct: [],
                    });
                }
            });
    },

    addUnitProduct: async ({
        commit
    }, args) => {
        return await ADD_UNIT_PRODUCT(args).then((resp) => {

            if (resp.data.httpCode === 200) {
                commit("setInputProduct", resp.data.data);
            }
        })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setInputProduct", {
                        setInputProduct: [],
                    });
                }
            });
    },

    copyProduct: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await COPY_PRODUCT(params).then((resp) => {

            if (resp.data.httpCode === 200) {
                commit("setInputProduct", resp.data.data);
            }
        })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setInputProduct", {
                        setInputProduct: [],
                    });
                }
            });
    },

    getInputProduct: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_INPUT_PRODUCT(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setInputProduct", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setInputProduct", {
                        setInputProduct: [],
                    });
                }
            });
    },
    deleteUnitProduct: async ({
        commit
    }, args) => {
        return await DEL_INPUT_PRODUCT(args).then((resp) => {

            if (resp.data.httpCode === 200) {
                commit("setInputProduct", resp.data.data);
            }
        })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setInputProduct", {
                        setInputProduct: [],
                    });
                }
            });
    },

    submitDO: async ({ }, args) => {
        return await SUBMIT_DO(args);
    },
    saveDO: async ({ }, args) => {
        return await SAVE_DO(args);
    },

    getListDO: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_LIST_DO(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {

                    commit("setDataDO", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setDataDO", {
                        setDataDO: [],
                    });
                }
            });
    },

    deleteDO: async ({ }, args) => {
        const params = queryString.stringify(args);
        return await DELETE_LIST_DO(params);
    },

    getDetailDO: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_EDIT_DO(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setDetailDO", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setDetailDO", {
                        setDetailDO: [],
                    });
                }
            });
    },

    getEditDO: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_EDIT_DO(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setEditDO", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setEditDO", {
                        setEditDO: [],
                    });
                }
            });
    },
    updateInner: async ({ }, args) => {
        // const params = queryString.stringify(args);
        return await UPDATE_INNER_DO(args);
    },

    getListOrdered: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_PO_REF(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {

                    commit("setReference", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setReference", {
                        setReference: [],
                    });
                }
            });
    },
    getDataRef: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_REF_ID(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {

                    commit("setDataRefs", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setDataRefs", {
                        setDataRefs: [],
                    });
                }
            });
    },

}

const getters = {
    UnitProductData: (states) => states.dataUnitProduct,
    InputProductData: (states) => states.dataInputProduct,
    DeliveryOrderData: (states) => states.dataListDO,
    dataDetailDO: (states) => states.dataDetailDO,
    dataEditDO: (states) => states.dataEditDO,
    dataReferencePO: (states) => states.dataReference,
    dataRefs: (states) => states.datRefs
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};