<template>
  <!-- HEADER -->
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-6">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("OH Management") }}
                </li>
                <li class="breadcrumb-item">{{ $t("Report") }}</li>
                <li class="breadcrumb-item">
                  {{ $t("Health Attendance Data") }}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("Health Attendance Data") }}</h4>
          <!-- form  -->
          <b-button variant="primary" @click="download()">
            <span class="fe fe-download"></span>
            Export
          </b-button>
        </div>
        <div class="form-group col-lg-4 p-3 ml-4" v-if="filterCompany == 1">
          <label for="">Company</label>
          <v-select v-model="companys" :loading="isLoading" :options="selectCompanyOption()" label="company_name"
            :reduce="(e) => e.id" @input="changeCompany($event)" />
        </div>
        <div style="overflow: auto">
          <!-- <table class="table table-sm table-nowrap">
            <thead>
              <tr>
                <th scope="col">Periode</th>
                <th v-for="(i, index) in summarize" :key="index" scope="col">{{ month(i.number_month) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(label, index) in labelsName" :key="index">
                <th scope="row">{{ label }}</th>
                <div>
                  <td>{{ label.total_kunjungan_fas_klinik }}</td>
                  <td>{{ label.jumlah_tenaga_kerja_sakit }}</td>
                  <td>{{ label.jumlah_absensi_sakit }}</td>
                  <td>{{ label.jumlah_spell }}</td>
                </div>
              </tr>
            </tbody>
          </table> -->
          <b-skeleton-table v-if="isLoading == true" :rows="5" :columns="4" :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
          <table v-if="isLoading == false" class="table table-sm table-nowrap">
            <thead>
              <tr>
                <th scope="col">{{ $t("Period") }}</th>
                <th v-for="(i, index) in labelsName" :key="index" scope="col">
                  {{ i }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(label, index) in summarize" :key="index">
                <th scope="row">{{ month(label.number_month) }}</th>
                <td>{{ convertNumber(label.total_visited_total_kunjungan) }}</td>
                <td>{{ convertNumber(label.sick_employee_jumlah_tenaga_kerja_sakit) }}</td>
                <td>{{ convertNumber(label.absence_jumlah_absensi_sakit) }}</td>
                <td>{{ convertNumber(label.spell) }}</td>
              </tr>
              <tr v-for="(label, index) in totalSummarize" :key="index">
                <th>Total</th>
                <td>{{ convertNumber(label.total_visited_total_kunjungan) }}</td>
                <td>{{ convertNumber(label.sick_employee_jumlah_tenaga_kerja_sakit) }}</td>
                <td>{{ convertNumber(label.absence_jumlah_absensi_sakit) }}</td>
                <td>{{ convertNumber(label.spell) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import moment from "moment";
  import axiosIns from "@/api/axios";
  export default {
    name: "ohManagement",
    computed: {
      url() {
        return this.$store.getters["summary/excelUrl"] ?
          this.$store.getters["summary/excelUrl"] : [];
      },
      summarize() {
        return this.$store.getters["summary/summarize"] ?
          this.$store.getters["summary/summarize"] : [];
      },
      totalSummarize() {
        return this.$store.getters["summary/totalSummarize"] ?
          this.$store.getters["summary/totalSummarize"] : [];
      },
      company() {
        return this.$store.getters["detailManagement/dataCompany"] ?
          this.$store.getters["detailManagement/dataCompany"] : [];
      },
    },
    data() {
      return {
        companys: "",
        isLoading: false,
        filterCompany: "",
        body: {
          date_input: moment().year(Number).format("YYYY"),
          data: "Data Absensi",
          company_id: ""
        },
        bodys: {
          flag: "Monthly",
          data: "Data",
          date_input: moment().year(Number).format("YYYY"),
          company_id: ""
        },
        bodySum: {
          flag: "Yearly",
          data: "Data",
          date_input: moment().year(Number).format("YYYY"),
          company_id: ""
        },
        isAdd: true,
        labelsName: [
          "TOTAL KUNJUNGAN KLINIK",
          "TOTAL JUMLAH HARI SAKIT ATAU ABSEN",
          "TOTAL TENAGA KERJA SAKIT",
          "TOTAL SPELL"
        ],
        mothly: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MEI",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC"
        ]
      };
    },
    mounted() {
      const company = localStorage.getItem("company");
      const roles = localStorage.getItem("roles_id");
      this.filterCompany = parseInt(roles) == 1 ? 1 : 0;
      this.bodys.company_id = company
      this.bodySum.company_id = company
      this.body.company_id = company
      this.companys = company
      // this.getLink();
      this.getCompanny();
      this.getSummary();
      this.getTotalSummary();
    },
    methods: {
      // api hit
      getLink() {
        this.$store.dispatch("summary/downloadExcel", this.body);
      },
      getSummary() {
        this.isLoading = true
        this.$store.dispatch("summary/getSummary", this.bodys).then((res) => {
          this.isLoading = false
        });
      },
      getTotalSummary() {
        this.$store.dispatch("summary/getTotalSummary", this.bodySum);
      },
      getCompanny() {
        this.$store.dispatch("detailManagement/getCompany");
      },

      //method
      month(r) {
        return moment(r).format("MMMM");

        //
      },
      download() {
        this.$store.dispatch("summary/downloadExcel", this.body).then(() => {
          let link = this.$store.getters["summary/excelUrl"];
          if (link.hasOwnProperty("path")) {
            window.open(axiosIns.defaults.baseURL + "/" + link.path);
          } else {
            this.$swal("Error", link.data.error, "error");
            console.log(link.data.error);
          }
        });
      },
      convertNumber(num) {
        if (num) {
          return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        } else {
          return "";
        }
      },

      selectCompanyOption() {
        // return this.company
        const company = this.company;
        const term = [];
        term.push({
          id: 0,
          company_name: "BIB + Mitra"
        });

        company.map((i) => {
          term.push({
            id: i.id,
            company_name: i.company_name
          });
        });
        return term;
      },
      changeCompany(evt) {
        this.bodys.company_id = evt
        this.bodySum.company_id = evt,
          this.body.company_id = evt

        this.getSummary()
        this.getTotalSummary()
      }
    }
  };
</script>