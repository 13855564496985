import axiosIns from '@/api/axios'

export const GET_TREATMENT_DATA = (args) => {
    return axiosIns.get(`/medical/treatment?${args}`)
}

export const ADD_TREATMENT = (args) => {
    return axiosIns.post(`/medical/treatment/create`, args)
}

export const DEL_TREATMENT = (id) => {
    return axiosIns.put(`/medical/treatment/delete?id=${id}`)
}

export const UPDATE_TREATMENT  = (args) => {
    return axiosIns.put(`/medical/treatment/update?id=${args.id}`, args)
}