import axiosIns from '@/api/axios'

export const GET_DISPOSABLE_DATA = (args) => {
    return axiosIns.get(`/items?${args}`)
}

export const ADD_DISPOSABLE = (args) => {
    return axiosIns.post(`/items`, args)
}

export const DEL_DISPOSABLE = (id) => {
    return axiosIns.delete(`/items?id=${id}`)
}

export const UPDATE_DISPOSABLE  = (args) => {
    return axiosIns.put(`/items?id=${args.id}`, args)
}