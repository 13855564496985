import queryString from "query-string";

import {
    GET_TRAINING_SUBGROUP_DATA,
    ADD_TRAINING_SUBGROUP,
    DEL_TRAINING_SUBGROUP,
    UPDATE_TRAINING_SUBGROUP
} from "@/api/medical/staff/training/subGroup";

const state = {
    dataTrainingSubGroup: [],
};

const mutations = {
    setTrainingSubGroup: (states, payload) => {
        states.dataTrainingSubGroup = payload;
    },
};

const actions = {
    getTrainingSubGroup: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_TRAINING_SUBGROUP_DATA(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    commit("setTrainingSubGroup", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setTrainingSubGroup", {
                        setTrainingSubGroup: [],
                    });
                }
            });
    },

    addTrainingSubGroup: async ({}, args) => {
        return await ADD_TRAINING_SUBGROUP(args);
    },

    delTrainingSubGroup: async ({}, id) => {
        return await DEL_TRAINING_SUBGROUP(id);
    },

    updateTrainingSubGroup: async ({}, args) => {
        return await UPDATE_TRAINING_SUBGROUP(args);
    },



};

const getters = {
    TrainingSubGroupData: (states) => states.dataTrainingSubGroup
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};