<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-4">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item ">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item">Inventory</li>
                <li class="breadcrumb-item fw-bolder">Summary Stock</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <div class="row align-items-center" style="margin-top: -2rem">
            <div class="col">
              <!-- Nav -->
              <ul class="nav nav-tabs nav-overflow header-tabs">
                <li class="nav-item" @click="direct = 'Drugs'">
                  <a class="nav-link" :class="{ active: direct === 'Drugs' }">
                    Medicine
                  </a>
                </li>
                <li class="nav-item" @click="direct = 'Disposable'">
                  <a
                    class="nav-link"
                    :class="{ active: direct === 'Disposable' }"
                  >
                    Disposable Item
                  </a>
                </li>
                <li class="nav-item" @click="direct = 'Medical Tool'">
                  <a
                    class="nav-link"
                    :class="{ active: direct === 'Medical Tool' }"
                  >
                    Medical Tool
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <!-- form  -->
        </div>

        <div class="card-body p-0" v-if="direct === 'Drugs'">
          <Drugs />
        </div>
        <div class="card-body p-0" v-if="direct === 'Medical Tool'">
          <MedicalTool />
        </div>
        <div class="card-body p-0" v-if="direct === 'Disposable'">
          <Disposable />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Drugs from "@/components/inventory/drugs&medical/drugs.vue";
import Disposable from "@/components/inventory/drugs&medical/disposable.vue";
import MedicalTool from "@/components/inventory/drugs&medical/medical_tool.vue"
export default {
  name: "reportStock",
  components: {
    Drugs,
    Disposable,
    MedicalTool
  },
  computed: {},
  data() {
    return {
      direct: "Drugs",
    };
  },
  mounted() {},
};
</script>