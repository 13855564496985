import queryString from "query-string";

import {
    GET_HEALTHCARE_DATA,
    ADD_HEALTHCARE,
    DEL_HEALTHCARE,
    UPDATE_HEALTHCARE,
    GET_FACILITY_FEATURE
} from "@/api/healthcare/healthcare";

const state = {
    dataHealthcare: [],
    dataFeature: []
};

const mutations = {
    setHealthcare: (states, payload) => {
        states.dataHealthcare = payload;
    },
    setFeature: (states, payload) => {
        states.dataFeature = payload;
    },
};

const actions = {
    getHealthcare: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_HEALTHCARE_DATA(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    commit("setHealthcare", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                if (resp.data.httpCode === 404) {
                    commit("setHealthcare", {
                        setHealthcare: [],
                    });
                }
            });
    },

    getFeature: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_FACILITY_FEATURE(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    commit("setFeature", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                if (resp.data.httpCode === 404) {
                    commit("setFeature", {
                        setFeature: [],
                    });
                }
            });
    },

    addHealthcare: async ({}, args) => {
        return await ADD_HEALTHCARE(args);
    },

    delHealthcare: async ({}, id) => {
        return await DEL_HEALTHCARE(id);
    },

    updateHealthcare: async ({}, args) => {
        return await UPDATE_HEALTHCARE(args);
    },



};

const getters = {
    HealthcareData: (states) => states.dataHealthcare,
    FeatureData: (states) => states.dataFeature
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};