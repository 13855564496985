import queryString from "query-string";

import {
    GET_RESULT_DATA,
    ADD_RESULT,
    DEL_RESULT,
    UPDATE_RESULT
} from "@/api/mcu/category/result";

const state = {
    dataResult: [],
};

const mutations = {
    setResult: (states, payload) => {
        states.dataResult = payload;
    },
};

const actions = {
    getResult: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_RESULT_DATA(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    commit("setResult", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setResult", {
                        setResult: [],
                    });
                }
            });
    },

    addResult: async ({}, args) => {
        return await ADD_RESULT(args);
    },

    delResult: async ({}, id) => {
        return await DEL_RESULT(id);
    },

    updateResult: async ({}, args) => {
        return await UPDATE_RESULT(args);
    },



};

const getters = {
    ResultData: (states) => states.dataResult
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};