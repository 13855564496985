<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-4">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item ">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item">Inventory</li>
                <li class="breadcrumb-item">Summary Stock</li>
                <li class="breadcrumb-item fw-bolder">Stock Detail</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div class="row align-items-center justify-content-between">
        <div class="cart">
          <div class="card">
            <div class="card-header">
              <!-- Title -->
              <h5 class="card-header-title">{{ detailsStock.item_name }}</h5>
            </div>
            <!-- Link -->
            {{ details }}
            <vue-good-table
              :line-numbers="true"
              :columns="columns"
              :rows="detailsStock.row"
              :sort-options="{
                enabled: true
              }"
              :pagination-options="{
                enabled: true,
                mode: 'pages',
                position: 'bottom',
                perPage: 10,
                perPageDropdown: [10, 50, 100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: 'All'
              }"
              style-class="vgt-table table-bordered"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange">
              <!-- Loading -->
              <div slot="emptystate" class="text-center font-size-14">
                No data available
              </div>
              <!-- Custom Rows -->
              <template slot="table-row" slot-scope="props">
                <!-- Table Action -->
                <span v-if="props.column.field == 'action'">
                  <b-button
                    class="no-wrap btn-pill ml-2"
                    variant="danger"
                    size="sm"
                    @click.prevent="recall(props.row)"
                    v-if="props.row.is_expired === true">
                    <span class="fe fe-repeat"></span>
                  </b-button>
                  <b-button
                    class="no-wrap btn-pill ml-2"
                    variant="warning"
                    size="sm"
                    @click.prevent="seeMutation()">
                    <span class="fe fe-git-branch"></span>
                  </b-button>
                </span>
                <div v-else-if="props.column.field === 'expiry_date'">
                  <!-- <span :class="`badge ${tdFunc(props.row)}`"
                                        style="font-size: 100% !important; padding: 0.33em 2em">{{ nows(props.row.expiry_date) }}</span> -->
                  <span v-html="func(props.row)"></span>
                  <!-- {{  }} -->
                </div>
                <div v-else-if="props.column.field === 'trans_date'">
                  <!-- <span :class="`badge ${tdFunc(props.row)}`"
                                        style="font-size: 100% !important; padding: 0.33em 2em">{{ nows(props.row.expiry_date) }}</span> -->
                  {{ nows(props.row.trans_date) }}
                </div>
                <div v-else-if="props.column.field === 'item_price'">
                  <!-- <span :class="`badge ${tdFunc(props.row)}`"
                                        style="font-size: 100% !important; padding: 0.33em 2em">{{ nows(props.row.expiry_date) }}</span> -->
                  Rp.{{ formated(props.row.item_price) }}
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "drugs",
  computed: {
    detailsStock() {
      return this.$store.getters["reporting/detailStockData"]
        ? this.$store.getters["reporting/detailStockData"]
        : [];
    }
  },
  data() {
    return {
      lol: "we",
      body: {
        id: "",
        page: 1,
        perPage: 10,
        searchQuery: "",
        sort: ""
      },
      columns: [
        {
          label: "Buy Date",
          field: "trans_date",
          thClass: `table-header no-wrap w-auto text-center text-muted font-weight-bold`,
          tdClass: "no-wrap color-secondary text-center align-middle"
        },
        {
          label: "Price",
          field: "item_price",
          thClass:
            "table-header no-wrap w-auto text-center text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle"
        },
        {
          label: "EXP DATE",
          field: "expiry_date",
          thClass:
            "table-header no-wrap text-center w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle"
        },
        {
          label: "Stock",
          field: "qty",
          thClass:
            "table-header no-wrap w-auto text-center text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass:
            "table-header no-wrap text-muted text-center font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle"
        }
      ],
      rows: [
        {
          buy_date: "20-12-2022",
          price: "10000",
          exp_date: "25-01-2023",
          qty: "20"
        },
        {
          buy_date: "20-12-2022",
          price: "10000",
          exp_date: "17-02-2023",
          qty: "100"
        }
      ]
    };
  },
  mounted() {
    this.getDetail();
  },

  methods: {
    tdFunc(rows) {
      const now = moment();
      const exp_date = moment(rows.exp_date, "DD-MM-YYYY");

      //   rows.exp_date,
      //   now.format("YYYY/MM/DD HH:mm:ss"),
      //   exp_date.format("YYYY/MM/DD HH:mm:ss")
      // );
      const diff = exp_date.diff(now, "days");

      if (diff < 0) {
        return "bg-danger-soft";
      } else if (diff > 8) {
        return "bg-info-soft";
      } else {
        return "bg-warning-soft";
      }
    },

    func(d) {
      if (d.is_expired) {
        return `<span class="badge bg-danger-soft">${this.nows(
          d.expiry_date
        )}</span>`;
      } else {
        if (d.expired_diff <= 7) {
          return `<span class="badge bg-warning-soft">${this.nows(
            d.expiry_date
          )}</span>`;
        } else {
          return `<span class="badge bg-success-soft">${this.nows(
            d.expiry_date
          )}</span>`;
        }
      }
    },

    recall(e) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t(
          "Send this expired drugs into medical waste? this action cannot be undone!"
        ),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("reporting/medicalWaste", {
              item_id: this.body.id,
              item_qty: e.base_qty,
              expiry_date: e.expiry_date,
              mw_category: "EXPIRED",
              note: ""
            })
            .then(() => {
              this.getDetail();
            });
        }
      });
    },

    nows(d) {
      if (d) {
        return moment(d).format("D MMM YYYY");
      }
      return d;
    },
    formated(val) {
      return val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    getDetail() {
      this.body.id = this.$router.currentRoute.query.item_id;
      this.$store.dispatch("reporting/detailStock", this.body);
    },

    seeMutation(id) {
      this.$router.push(
        `/clinic-management/inventory/drugs-&-medical/mutation?item_id=${this.body.id}`
      );
    }
  }
};
</script>
