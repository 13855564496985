<template>
	<!-- HEADER -->
	<div class="header">
		<div class="container-fluid">
			<!-- Body -->
			<div class="mt-4">
				<!-- Title -->
				<div class="card">
					<div class="card-body">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item fw-bolder">
									{{ $t("Clinic Management") }}
								</li>
								<li class="breadcrumb-item">{{ this.breadcrumb }}</li>
								<li class="breadcrumb-item">Detail</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
			<div class="card p-0">
				<div class="card-header">
					<!-- Title -->
					<h4 class="card-header-title">Patient Info</h4>
				</div>
				<div v-if="load === false">
					<div class="list-group list-group-flush">
						<div class="list-group-item p-4">
							<div class="row">
								<div class="col-auto">
									<!-- Avatar -->
									<div class="avatar avatar-sm">
										<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
											<i class="fe fe-credit-card"></i>
										</div>
									</div>
								</div>
								<div class="col">
									<p class="small mt-2">
										<strong>NIK</strong>
									</p>
									<p class="small mt-2">
										<strong>{{ this.history.nik }}</strong>
									</p>
								</div>
								<div class="col-auto">
									<!-- Avatar -->
									<div class="avatar avatar-sm">
										<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
											<i class="fe fe-user"></i>
										</div>
									</div>
								</div>
								<div class="col">
									<p class="small mt-2">
										<strong>Name</strong>
									</p>
									<p class="small mt-2">
										<strong>{{ this.history.patient_name }}</strong>
									</p>
								</div>
								<div class="col-auto">
									<!-- Avatar -->
									<div class="avatar avatar-sm">
										<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
											<i class="fe fe-home"></i>
										</div>
									</div>
								</div>
								<div class="col">
									<p class="small mt-2">
										<strong>Company</strong>
									</p>
									<p class="small mt-2">
										<strong>{{ this.history.company }}</strong>
									</p>
								</div>
								<div class="col-auto">
									<!-- Avatar -->
									<div class="avatar avatar-sm">
										<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
											<i class="fe fe-layers"></i>
										</div>
									</div>
								</div>
								<div class="col">
									<p class="small mt-2">
										<strong>Departement</strong>
									</p>
									<p class="small mt-2">
										<strong>{{ this.history.department }}</strong>
									</p>
								</div>
								<div class="col-auto">
									<!-- Avatar -->
									<div class="avatar avatar-sm">
										<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
											<i class="fe fe-award"></i>
										</div>
									</div>
								</div>
								<div class="col">
									<p class="small mt-2">
										<strong>Position</strong>
									</p>
									<p class="small mt-2">
										<strong>{{ this.history.position }}</strong>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body text-center" v-else>
					<div class="spinner-border" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
				</div>
			</div>
			<div class="card p-0">
				<div class="card-header">
					<!-- Title -->
					<h4 class="card-header-title">Result Drug Test</h4>
					<!-- Button -->
					<div style="margin-right: 15px"></div>
				</div>
				<vue-good-table
					:line-numbers="true"
					:columns="columns"
					:rows="detailDrugTest.resultDrugTest"
					:sort-options="{
					enabled: true,
					}"
					:pagination-options="{
					enabled: true,
					mode: 'pages',
					position: 'bottom',
					perPage: 10,
					perPageDropdown: [10, 50, 100],
					dropdownAllowAll: false,
					setCurrentPage: 1,
					nextLabel: 'next',
					prevLabel: 'prev',
					rowsPerPageLabel: 'Rows per page',
					ofLabel: 'of',
					pageLabel: 'page',
					allLabel: 'All',
					}"
					style-class="vgt-table border-0 table"
				>
					<!-- Loading -->
					<div slot="emptystate" class="text-center font-size-14">
					No data available
					</div>
					<!-- Custom Rows -->
					<!-- <template slot="table-row" slot-scope="props">
					</template> -->
				</vue-good-table>
			</div>
		</div>
	</div>
</template>
<script>
import axiosIns from '@/api/axios'

export default {
	name: "patient",
	components: {},
	computed: {
		detailDrugTest() {
			return this.$store.getters["drugTest/detailDrugByRegister"]
				? this.$store.getters["drugTest/detailDrugByRegister"]
				: [];
		},
	},
	data() {
		return {
			register_id: "",
			history: [],
			breadcrumb: "",
			load: false,
			payload: {
				id: "",
				files: [],
			},
			url: "",
			columns: [
				{
					label: "Amphetamine",
					field: "amphetamine",
					thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
					tdClass: "no-wrap color-secondary align-middle",
				},
				{
					label: "Benzoat",
					field: "benzoat",
					thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
					tdClass: "no-wrap color-secondary align-middle",
				},
				{
					label: "Cocaine",
					field: "cocaine",
					thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
					tdClass: "no-wrap color-secondary align-middle",
				},
				{
					label: "Ganja",
					field: "ganja",
					thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
					tdClass: "no-wrap color-secondary align-middle",
				},
				{
					label: "Metamphitamine",
					field: "metamphitamine",
					thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
					tdClass: "no-wrap color-secondary align-middle",
				},
				{
					label: "Morphine",
					field: "morphine",
					thClass: "table-header w-auto text-muted font-weight-bold",
					tdClass: "w-auto color-secondary align-middle",
				},
			],
		};
	},
	mounted() {
		this.register_id = localStorage.getItem("registerID");
		this.breadcrumb = localStorage.getItem("breadcrumb");
		this.history = JSON.parse(localStorage.getItem("dataMedical"));
		this.getDetail();
		this.url = axiosIns.defaults.baseURL + "/";
	},

	methods: {
		getDetail() {
			let body = {
				register_id: this.register_id,
			};
			this.load = true;
			this.$store.dispatch("drugTest/getDrugByRegister", body).then(() => {
				this.load = false;
			});
		},
		documentView(props) {
			this.$bvModal.show("modal-1");
			this.payloads.files = props.files;
		},
	},
};
</script>
