import queryString from "query-string";

import {
	GET_ROLE_MANAGEMENT_CLINIC_DATA,
	GET_DATA_ROLE,
	GET_DATA_USER,
	ADD_ROLE_MANAGEMENT_CLINIC,
	GET_DATA_ROLES,
	UPDATE_ROLE_MANAGEMENT_CLINIC,
	DELETE_ROLE_MANAGEMENT_CLINIC,
} from "@/api/clinic/role_management_clinic";

const state = {
	dataRoleClinicManagement: [],
	dataRole: [],
	dataRoles: [],
	dataUser: [],
};

const mutations = {
	setRoleClinicManagement: (states, payload) => {
		states.dataRoleClinicManagement = payload;
	},

	setRole: (states, payload) => {
		states.dataRole = payload;
	},
	setRoles: (states, payload) => {
		states.dataRoles = payload;
	},

	setUser: (states, payload) => {
		states.dataUser = payload;
	},
};

const actions = {
	getDataRoleClinicManagement: async ({ commit }, args) => {
		const params = queryString.stringify(args);
		try {
			const resp = await GET_ROLE_MANAGEMENT_CLINIC_DATA(params);
			const datas = resp.data.data.data;
			commit("setRoleClinicManagement", datas);
		} catch (err) {
			let resp = err.response;
			console.log(err);
			if (resp.data.httpCode === 404) {
				commit("setRoleClinicManagement", {
					setRoleClinicManagement: [],
				});
			}
		}
	},
	getRole: async ({ commit }) => {
		try {
			const resp = await GET_DATA_ROLE();
			const datas = resp.data.data.data;
			commit("setRole", datas);
		} catch (err) {
			let resp = err.response;
			console.log(err);
			if (resp.data.httpCode === 404) {
				commit("setRole", {
					setRole: [],
				});
			}
		}
	},

	getRoles: async ({ commit }) => {
		try {
			const resp = await GET_DATA_ROLES();
			const datas = resp.data.data.data;
			commit("setRoles", datas);
		} catch (err) {
			let resp = err.response;
			console.log(err);
			if (resp.data.httpCode === 404) {
				commit("setRoles", {
					setRoles: [],
				});
			}
		}
	},

	getUser: async ({ commit }) => {
		try {
			const resp = await GET_DATA_USER();
			const datas = resp.data.data.data;
			commit("setUser", datas);
		} catch (err) {
			let resp = err.response;
			console.log(err);
			if (resp.data.httpCode === 404) {
				commit("setUser", {
					setUser: [],
				});
			}
		}
	},

	addRoleClinicManagement: async ({}, args) => {
		return await ADD_ROLE_MANAGEMENT_CLINIC(args);
	},

	updateRoleClinicManagement: async ({}, args) => {
		return await UPDATE_ROLE_MANAGEMENT_CLINIC(args);
	},

	deleteRoleClinicManagement: async ({}, args) => {
		return await DELETE_ROLE_MANAGEMENT_CLINIC(args);
	},
};

const getters = {
	roleClinicManagement: (states) => states.dataRoleClinicManagement,
	dataRole: (states) => states.dataRole,
	dataRoles: (states) => states.dataRoles,
	dataUser: (states) => states.dataUser,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
