<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-5">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <!-- Breadcrumb -->
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("OH Management") }}
                </li>
                <li class="breadcrumb-item">{{ $t("Approval") }}</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div class="mt-5" v-if="companys == 1">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="sorting()">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputEmail1" class="form-label">{{
                      $t("Company")
                    }}</label>
                    <v-select
                      :options="company"
                      v-model="bodyDataDaily.company_id"
                      label="company_name"
                      :reduce="(e) => e.id" />
                  </div>
                </div>
                <!-- <div class="col">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1" class="form-label">Year</label>
                                        <input type="month" v-model="bodyDataDaily.date_input" class="form-control"
                                            placeholder="Pilih Tanggal" v-validate="'required'" name="Tanggal" />
                                        <v-select :options="listYear" v-model="body.date_input" />
                                    </div>
                                </div> -->
              </div>

              <div class="row">
                <div class="col"></div>
                <div class="col-auto">
                  <!-- Button -->
                  <button type="submit" class="btn btn-danger lift">
                    {{ $t("Filter") }}
                  </button>
                </div>
              </div>
              <!-- / .row -->
            </form>
          </div>
        </div>
      </div>

      <!-- Tabs  -->
      <div class="card">
        <div class="card-header">
          <p></p>
          <b-button class="ml-3" variant="primary" v-b-modal.modal-center>
            {{ $t("Approve") }}
          </b-button>
        </div>
        <div class="card-body" style="padding: 0px !important">
          <b-tabs fill>
            <b-tab title="Data" @click="type1">
              <div class="tableFixHead" style="overflow: auto">
                <table class="table table-sm table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col" rowspan="2">
                        {{ $t("Occupational Health Indicators") }}
                      </th>
                      <th colspan="31" style="text-align: center">
                        {{ $t("Month") }}
                      </th>
                    </tr>
                    <tr>
                      <th
                        v-for="(i, index) in mothly"
                        :key="index"
                        scope="col"
                        class="text-center">
                        {{ i }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in ohDaily" :key="index">
                      <th scope="row">
                        {{ row.oh_label }}
                        <span
                          v-if="row.description != null"
                          :title="row.description"
                          class="fe fe-alert-circle"></span>
                      </th>
                      <td
                        v-for="(item, index_subcol) in lastmonth"
                        :key="index_subcol"
                        :class="`text-center ${colors(
                          reveals(item, row.values)
                        )}`">
                        <span>{{
                          convertNumber(revealValue(item, row.values))
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-tab>

            <b-tab title="Activity" @click="type2">
              <div class="tableFixHead" style="overflow: auto">
                <table class="table table-sm table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col" rowspan="2">
                        {{ $t("Occupational Health Indicators") }}
                      </th>
                      <th colspan="31" style="text-align: center">
                        {{ $t("Month") }}
                      </th>
                    </tr>
                    <tr>
                      <th
                        v-for="(i, index) in mothly"
                        :key="index"
                        scope="col"
                        class="text-center">
                        {{ i }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in ohDaily" :key="index">
                      <th scope="row">
                        {{ row.oh_label }}
                        <span
                          v-if="row.description != null"
                          :title="row.description"
                          class="fe fe-alert-circle"></span>
                      </th>
                      <td
                        v-for="(item, index_subcol) in lastmonth"
                        :key="index_subcol"
                        :class="`text-center ${colors(
                          reveals(item, row.values)
                        )}`">
                        {{ convertNumber(revealValue(item, row.values)) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-tab>
          </b-tabs>
        </div>
        <div class="card-footer">
          <b-row>
            <b-col>
              <small class="d-flex">
                <span class="text-danger" style="font-size: 24px">●</span>
                <p class="ml-2" style="margin-top: 9px">{{ $t("Analyzed") }}</p>
              </small>
            </b-col>
            <b-col>
              <small class="d-flex">
                <span class="text-secondary" style="font-size: 24px">●</span>
                <p class="ml-2" style="margin-top: 9px">{{ $t("Checked") }}</p>
              </small>
            </b-col>
            <b-col>
              <small class="d-flex">
                <span class="text-info" style="font-size: 24px">●</span>
                <p class="ml-2" style="margin-top: 9px">{{ $t("Approved") }}</p>
              </small>
            </b-col>
            <b-col>
              <small class="d-flex">
                <span class="text-success" style="font-size: 24px">●</span>
                <p class="ml-2" style="margin-top: 9px">
                  {{ $t("Final Approved") }}
                </p>
              </small>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <b-modal id="modal-center" centered hide-footer title="Approval">
      <form @submit.prevent="approve()">
        <div class="form-group">
          <label>{{ $t("Period") }}</label>
          <input
            type="month"
            v-model="date_input"
            class="form-control"
            name="Pilih Tanggal"
            @change="checkStatus()" />
        </div>
        <div>
          <div v-if="isLoading == false">
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              disabled
              type="submit"
              v-if="(status >= 3 && companys != 1) || status > 3">
              {{ $t("Submit") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-else>
              {{ $t("Submit") }}
            </button>
          </div>
          <div v-else>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
// import {
//     set
// } from 'vue';
// import axiosIns from "@/api/axios";

export default {
  name: "approval",
  computed: {
    company() {
      return this.$store.getters["detailManagement/dataCompany"]
        ? this.$store.getters["detailManagement/dataCompany"]
        : [];
    },
    ohDaily() {
      return this.$store.getters["detailManagement/OHDailyData"]
        ? this.$store.getters["detailManagement/OHDailyData"]
        : [];
    },
    dataAnalyze() {
      return this.$store.getters["detailManagement/analyzeData"]
        ? this.$store.getters["detailManagement/analyzeData"]
        : [];
    }
  },
  data() {
    return {
      listYear: [],
      date_input: "",
      companys: localStorage.getItem("company"),
      bodyDataDaily: {
        oh_type: "Data",
        type_input: "Monthly",
        company_id: localStorage.getItem("company"),
        date_input: moment().format("YYYY")
        // date_input: moment().format("YYYY-MM"),
      },
      // bodyAnalyze: {
      //     oh_type: "Data",
      //     type_input: "Monthly",
      //     company_id: ""
      // },
      mothly: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MEI",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC"
      ],
      status: "",
      isLoading: false
      // companys: ""
    };
  },
  mounted() {
    const lastmonth = moment().endOf("year").format("M");
    this.lastmonth = Number(lastmonth);
    this.customGetOH();
    this.getCompanny();
    // this.companys = localStorage.getItem('company')
    // }
  },
  methods: {
    revealValue(monthly, values) {
      const index = values.findIndex((e) => e.month === monthly);
      if (index > -1) {
        return values[index]["value"];
      } else {
        return "";
      }
    },
    reveals(monthly, values) {
      const index = values.findIndex((e) => e.month === monthly);
      if (index > -1) {
        return values[index]["status"];
      } else {
        return "";
      }
    },
    colors(code) {
      switch (code) {
        case 1:
          return "bg-danger-soft";
        case 2:
          return "bg-secondary-soft";
        case 3:
          return "bg-info-soft ";
        case 4:
          return "bg-success-soft";
        default:
          return "";
      }
    },
    customGetOH() {
      this.$store.dispatch("detailManagement/getDataDaily", this.bodyDataDaily);
    },
    getAnalyze() {
      this.$store.dispatch("detailManagement/getAnalyze", this.bodyDataDaily);
    },
    getCompanny() {
      this.$store.dispatch("detailManagement/getCompany");
    },
    type1() {
      this.bodyDataDaily.oh_type = "Data";
      this.customGetOH();
    },
    type2() {
      this.bodyDataDaily.oh_type = "Activity";
      this.customGetOH();
    },
    convertNumber(num) {
      if (num) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return "";
      }
    },
    approve() {
      // let formValues = {}
      let datas = {
        date_input: this.date_input,
        company_id: this.bodyDataDaily.company_id,
        status: this.status
      };
      this.isLoading = true;
      if (this.bodyDataDaily.company_id.length > 0) {
        this.$store
          .dispatch("detailManagement/approveData", datas)
          .then(() => {
            this.isLoading = false;
            this.customGetOH();
            this.$bvModal.hide("modal-center");
            this.date_input = "";
            this.$swal({
              icon: "success",
              title: "Success",
              text: "Data has been Approve"
            });
          })
          .catch((resp) => {
            this.$swal("Error", this.$t("Error while add data"), "error");
            this.isLoading = false;
          });
      } else {
        this.$bvModal.hide("modal-center");
        this.date_input = "";
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Company field can't be empty!"
        });
      }
    },

    sorting() {
      this.customGetOH();
      // this.getAnalyze();
    },
    checkStatus() {
      let dataSend = {
        date_input: this.date_input,
        company_id: this.bodyDataDaily.company_id
      };
      this.$store.dispatch("detailManagement/getStatus", dataSend).then(() => {
        let status = this.$store.getters["detailManagement/checkStatus"];
        this.status = status.status;
      });
    }
  }
};
</script>
<style>
/* th:nth-of-type(3),
td:nth-of-type(3) {
  text-align: right;
} */

/* Fixed Headers */
</style>
