import queryString from 'query-string'
import {
    GET_OH_DATA,
    ADD_OH_DAILY,
    DEL_CHEMICAL,
    UPDATE_CHEMICAL,
    GET_COMPANY,
    ANALYZE_DATA,
    CHECKED_DATA,
    CHECKED,
    APPROVE_DATA,
    GET_STATUS,
    LAST_UPDATE,
    IMPORT_FILE
} from "@/api/oh_management/detail_management";


const state = {
    dataOHDaily: [],
    dataOHDailyInput: [],
    dataCompany: [],

    // Yearly
    dataCompetency: [],

    // Checked
    dataChecked: [],
    dataAnalyze: [],
    dataStatus: [],
    dataLastUpdate: [],

    //set data
    companySelect: "",
    loaderData: true

};

const mutations = {
    setOHDaily: (states, payload) => {
        states.dataOHDaily = payload;
    },
    setOHDailyInput: (states, payload) => {
        states.dataOHDailyInput = payload;
    },
    setCompany: (states, payload) => {
        states.dataCompany = payload;
    },
    // Yearly
    setCompetency: (states, payload) => {
        states.dataCompetency = payload;
    },

    // Checked
    setChecked: (states, payload) => {
        states.dataChecked = payload;
    },
    setDataAnalyze: (states, payload) => {
        states.dataAnalyze = payload
    },
    setStatus: (states, payload) => {
        states.dataStatus = payload
    },

    setLastUpdate: (states, payload) => {
        states.dataLastUpdate = payload;
    },

    //Data set
    setCompanySelect: (states, payload) => {
        states.companySelect = payload;
    },
    setLoader: (states, payload) => {
        states.loaderData = payload;
    }
};

const actions = {
    getDataDaily: async ({ commit }, args) => {
        const params = queryString.stringify(args);
        try {
            const resp = await GET_OH_DATA(params)
            const datas = resp.data.data.data
            commit("setOHDaily", datas);
        } catch (err) {
            let resp = err.response;
            console.log(err);
            if (resp.data.httpCode === 404) {
                commit("setOHDaily", {
                    setOHDaily: [],
                });
            }
        }
    },

    getDataDailyInput: async ({ commit }, args) => {
        const params = queryString.stringify(args);
        try {
            const resp = await GET_OH_DATA(params)
            const datas = resp.data.data.data
            commit("setOHDailyInput", datas);
        } catch (err) {
            let resp = err.response;
            console.log(err);
            if (resp.data.httpCode === 404) {
                commit("setOHDailyInput", {
                    setOHDailyInput: [],
                });
            }
        }
    },

    getCompany: async ({ commit }, args) => {
        const params = queryString.stringify(args);
        try {
            const resp = await GET_COMPANY(params)

            const datas = resp.data.data.data
            commit("setCompany", datas);
        } catch (err) {
            let resp = err.response;
            console.log(err);
            if (resp.data.httpCode === 404) {
                commit("setCompany", {
                    setCompany: [],
                });
            }
        }
    },

    addOhDaily: async ({ }, args) => {
        return await ADD_OH_DAILY(args);
    },

    delChemical: async ({ }, id) => {
        return await DEL_CHEMICAL(id);
    },

    updateChemical: async ({ }, args) => {
        return await UPDATE_CHEMICAL(args);
    },

    analyzeData: async ({ }, args) => {
        return await ANALYZE_DATA(args);
    },

    // Yearly
    getCompetency: async ({ commit }, args) => {
        const params = queryString.stringify(args);
        try {
            const resp = await GET_OH_DATA(params)
            const datas = resp.data.data
            commit("setCompetency", datas);
        } catch (err) {
            let resp = err.response;
            console.log(err);
            if (resp.data.httpCode === 404) {
                commit("setCompetency", {
                    setCompetency: [],
                });
            }
        }
    },

    // Checked
    getChecked: async ({ commit }) => {
        // const params = queryString.stringify(args);
        try {
            const resp = await CHECKED_DATA()
            const datas = resp.data.data
            commit("setChecked", datas);
        } catch (err) {
            let resp = err.response;
            console.log(err);
            if (resp.data.httpCode === 404) {
                commit("setChecked", {
                    setChecked: [],
                });
            }
        }
    },
    checkData: async ({ }, args) => {
        return await CHECKED(args);
    },
    checkDataBIB: async ({ }, args) => {
        return await CHECKED_DATA_BIB(args);
    },

    getAnalyze: async ({ commit }, args) => {
        const params = queryString.stringify(args);
        try {
            const resp = await GET_ANALYZE(params)
            const datas = resp.data.data.data
            commit("setDataAnalyze", datas);
        } catch (err) {
            let resp = err.response;
            console.log(err);
            if (resp.data.httpCode === 404) {
                commit("setDataAnalyze", {
                    setDataAnalyze: [],
                });
            }
        }
    },
    getStatus: async ({ commit }, args) => {
        const params = queryString.stringify(args);
        try {
            const resp = await GET_STATUS(params)
            const datas = resp.data.data

            commit("setStatus", datas);
        } catch (err) {
            let resp = err.response;
            console.log(err);
            if (resp.httpCode === 404) {
                commit("setStatus", {
                    setStatus: [],
                });
            }
        }
    },

    approveData: async ({ }, args) => {
        return await APPROVE_DATA(args);
    },

    importFile: async ({ }, args) => {
        return await IMPORT_FILE(args);
    },

    getLastUpdate: async ({ commit }) => {
        try {
            const resp = await LAST_UPDATE()

            const datas = resp.data.data
            commit("setLastUpdate", datas);
        } catch (err) {
            let resp = err.response;
            console.log(err);
            if (resp.data.httpCode === 404) {
                commit("setLastUpdate", {
                    setLastUpdate: [],
                });
            }
        }
    },
};

const getters = {
    OHDailyData: (states) => states.dataOHDaily,
    OHDailyDataInput: (states) => states.dataOHDailyInput,
    dataCompany: (states) => states.dataCompany,

    // Yearly
    competencyData: (states) => states.dataCompetency,

    // Yearly
    chekedData: (states) => states.dataChecked,
    checkStatus: (states) => states.dataStatus,

    lastUpdate: (states) => states.dataLastUpdate,

    companySelectData: (states) => states.companySelect,
    loadingPageData: (states) => states.loaderData


};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};