import queryString from 'query-string'
import {
    GET_NAME_DATA,
    ADD_NAME,
    DEL_NAME,
    UPDATE_NAME
} from "@/api/hazard/name";


const state = {
    dataName: [],
};

const mutations = {
    setName: (states, payload) => {
        states.dataName = payload;
    },
};

const actions = {
    getName: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_NAME_DATA(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    commit("setName", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setName", {
                        setName: [],
                    });
                }
            });
    },

    addName: async ({ }, args) => {
        return await ADD_NAME(args);
    },

    delName: async ({ }, id) => {
        return await DEL_NAME(id);
    },

    updateName: async ({ }, args) => {
        return await UPDATE_NAME(args);
    },



};

const getters = {
    NameData: (states) => states.dataName
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};