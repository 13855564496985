<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <!-- / .row -->

      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">
            {{ $t("Healthcare Data Facility") }}
          </h4>
          <!-- Button -->
          <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
            <span class="fe fe-file-plus"></span>
            {{ $t("Healthcare Fasility") }}
          </b-button>
          <b-button
            v-b-toggle.collapse-1
            variant="primary"
            style="margin-left: 7px">
            <span class="fe fe-sliders"></span>
          </b-button>
        </div>
        <b-collapse id="collapse-1">
          <form @submit.prevent="sorting()">
            <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Medical Fasility")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="body.chemical_material_type_name" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Healthcare Feature")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="body.healthcare_facility_feature" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Healthcare Name")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="body.healthcare_facility_name" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label"
                    >Order</label
                  >
                  <v-select
                    v-model="body.order"
                    :options="['ASC', 'DESC']"
                    @input="sorting" />
                </div>
              </div>
            </div>

            <div class="row mb-3" style="margin-right: 15px">
              <div class="col"></div>
              <div class="col-auto">
                <!-- Button -->
                <button type="submit" class="btn btn-danger lift">
                  {{ $t("Filter") }}
                </button>
              </div>
            </div>
            <!-- / .row -->
          </form>
        </b-collapse>
        <vue-good-table
          :line-numbers="true"
          :columns="columns"
          :rows="healthcareFasility.data"
          :sort-options="{
            enabled: true
          }"
          :total-rows="healthcareFasility.totalItems"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }"
          style-class="vgt-table border-0 table"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange">
          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <!-- Custom Rows -->
          <template slot="table-row" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.field == 'action'">
              <b-button
                class="no-wrap btn-pill"
                variant="warning"
                size="sm"
                @click="updateData(props.row)">
                <span class="fe fe-edit"></span>
              </b-button>
              <b-button
                class="no-wrap ml-2"
                variant="danger"
                style="margin-left: 5px"
                size="sm"
                @click="deleteData(props.row.id)">
                <span class="fe fe-trash-2"></span>
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </div>

      <!-- Modal -->
      <b-modal id="modal-1" hide-footer title="Name Form">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="form-group">
            <label>{{ $t("Medical Fasility") }}</label>
            <v-select
              v-model="medical_facility_id"
              :options="medicalFasility.data"
              label="facility_name"
              :reduce="(e) => e.id"
              v-validate="'required'"
              name="Facility Name" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Facility Name')"
              >{{ errors.first("Facility Name") }}</span
            >
          </div>
          <div class="form-group">
            <label>{{ $t("Healthcare Fasility Fetature") }}</label>
            <div class="form-group">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  v-model="healthcare_facility_feature_id"
                  type="checkbox"
                  id="inlineCheckbox1"
                  true-value="1"
                  false-value="no" />
                <label class="form-check-label" for="inlineCheckbox1">{{
                  $t("Registration Employee & MCU Record")
                }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  v-model="healthcare_facility_feature_id"
                  type="checkbox"
                  id="inlineCheckbox2"
                  true-value="2"
                  false-value="no" />
                <label class="form-check-label" for="inlineCheckbox2">{{
                  $t("Inventory")
                }}</label>
              </div>
            </div>
          </div>
          <!-- <b-form-group label="Healthcare Fasility Fetature" v-slot="{ ariaDescribedby }">
                        <b-form-checkbox-group
                            v-model="healthcare_facility_feature_id"
                            :options="options"
                            :aria-describedby="ariaDescribedby"
                            plain
                        ></b-form-checkbox-group>
                    </b-form-group> -->
          <div class="form-group">
            <label>{{ $t("Healthcare Fasility Name") }}</label>
            <input
              type="text"
              v-model="healthcare_facility_name"
              class="form-control"
              placeholder="Healthcare Fasility Name" />
          </div>
          <button
            style="margin-left: auto; float: right"
            class="btn btn-primary"
            type="submit"
            v-if="isLoading == false">
            {{ $t("Save") }}
          </button>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="button"
            disabled
            v-else>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
            Loading...
          </button>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="form-group">
            <label>{{ $t("Medical Fasility") }}</label>
            <v-select
              v-model="medical_facility_ids"
              :options="medicalFasility.data"
              label="facility_name"
              :reduce="(e) => e.id" />
          </div>
          <div class="form-group">
            <label>{{ $t("Healthcare Fasility Fetature") }}</label>
            <div class="form-group">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  v-model="healthcare_facility_feature_ids"
                  type="checkbox"
                  id="inlineCheckbox1"
                  true-value="1"
                  v-bind:value="healthcare_facility_feature_ids" />
                <label class="form-check-label" for="inlineCheckbox1">{{
                  $t("Registration Employee & MCU Record")
                }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  v-model="healthcare_facility_feature_ids"
                  type="checkbox"
                  id="inlineCheckbox2"
                  true-value="2"
                  v-bind:value="healthcare_facility_feature_ids" />
                <label class="form-check-label" for="inlineCheckbox2">{{
                  $t("Inventory")
                }}</label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>{{ $t("Healthcare Fasility Name") }}</label>
            <input
              type="text"
              v-model="healthcare_facility_names"
              class="form-control"
              placeholder="Material Name" />
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "defaultCMS",
  computed: {
    healthcareFasility() {
      return this.$store.getters["healthcareFasility/HealthcareFasilityData"]
        ? this.$store.getters["healthcareFasility/HealthcareFasilityData"]
        : [];
    },
    medicalFasility() {
      return this.$store.getters["facility/FacilityData"]
        ? this.$store.getters["facility/FacilityData"]
        : [];
    }
  },
  data() {
    return {
      options: [
        { text: "Registration Employee & MCU Record", value: 1 },
        { text: "Inventory", value: 2 }
      ],
      body: {
        page: 1,
        limit: 10,
        order: ""
      },
      medical_facility_id: "",
      medical_facility_ids: "",
      healthcare_facility_feature_id: "",
      healthcare_facility_feature_ids: "",
      healthcare_facility_name: "",
      healthcare_facility_names: "",
      id: "",
      isAdd: true,
      isLoading: false,
      facility_name: "",
      columns: [
        {
          label: "MEDICAL FASILITY",
          field: "medical_facility_id",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "HEALTHCARE FEATURE",
          field: "healthcare_facility_feature_id",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "HEALTHCARE FASILITY NAME",
          field: "healthcare_facility_name",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ]
    };
  },
  mounted() {
    if (this.healthcareFasility && this.healthcareFasility.length < 1) {
      this.getHealthcareFasility();
      this.getFacility();
    }
  },
  methods: {
    //action
    updateData(props) {
      this.$bvModal.show("modal-1");
      this.isAdd = false;
      this.id = props.id;
      this.medical_facility_ids = props.medical_facility_id;
      this.healthcare_facility_feature_ids =
        props.healthcare_facility_feature_id;
      this.healthcare_facility_names = props.healthcare_facility_name;
    },

    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.isLoading = true;
          this.$store
            .dispatch("healthcareFasility/addHealthcareFasility", {
              medical_facility_id: this.medical_facility_id,
              healthcare_facility_feature_id:
                this.healthcare_facility_feature_id,
              healthcare_facility_name: this.healthcare_facility_name
            })
            .then((resp) => {
              this.$swal("Saved", "Your input has been saved", "success").then(
                (resp) => {
                  this.isLoading = false;
                  this.medical_facility_id = "";
                  this.healthcare_facility_feature_id = "";
                  this.healthcare_facility_name = "";
                  this.getHealthcareFasility();
                  this.$bvModal.hide("modal-1");
                }
              );
            });
        } catch (resp) {
          this.$swal("Error", "Error while add data", "error");
        }
      });
    },
    saveUpdate() {
      let body = {
        id: this.id,
        medical_facility_id: this.medical_facility_ids,
        healthcare_facility_feature_id: this.healthcare_facility_feature_ids,
        healthcare_facility_name: this.healthcare_facility_names
      };
      this.isLoading = true;
      this.$store
        .dispatch("healthcareFasility/updateHealthcareFasility", body)
        .then((resp) => {
          this.$swal("Saved", "Your input has been saved", "success").then(
            (resp) => {
              this.isLoading = false;
              this.getHealthcareFasility();
              this.$bvModal.hide("modal-1");
            }
          );
        })
        .catch((resp) => {
          this.$swal("Error", "Error while add data", "error");
        });
    },

    deleteData(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("healthcareFasility/delHealthcareFasility", id)
            .then((resp) => {
              this.getHealthcareFasility();
              this.$swal("Deleted!", "Your file has been deleted.", "success");
            })
            .catch((resp) => {
              this.$swal("Error", "Error while delete data", "error");
            });
        }
      });
    },

    // api hit
    async getHealthcareFasility() {
      await this.$store.dispatch(
        "healthcareFasility/getHealthcareFasility",
        this.body
      );
    },

    async getFacility() {
      await this.$store.dispatch("facility/getFacility", this.body);
    },

    // filter
    filterPage() {
      this.getHealthcareFasility();
    },

    sorting() {
      this.getHealthcareFasility();
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;

      if (
        parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
        1000
      ) {
        this.getHealthcareFasility();
      }
    }
  }
};
</script>
