<template>
  <!-- HEADER -->
  <div class="header">
    <!-- Body -->
    <div :class="`cart ${ load === true ? '' : 'd-none'}`">
      <div class="card-body text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div :class="`cart ${ load === true ? 'd-none' : ''}`">
      <b-collapse id="collapse-1">
        <form @submit.prevent="sorting()">
          <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
            <div class="col">
              <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">{{ $t('Company') }}</label>
                <v-select :options="[
                                  { label: 'UGEMS', id: 'UGEMS' },
                                  { label: 'Februari', id: 'Februari' },
                                  { label: 'Maret', id: 'Maret' },
                              ]" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">{{ $t('Monthly') }}</label>
                <v-select :options="[
                                  { label: 'Januari', id: 'Januari' },
                                  { label: 'Februari', id: 'Februari' },
                                  { label: 'Maret', id: 'Maret' },
                              ]" />
              </div>
            </div>
          </div>
          <div class="row mb-3" style="margin-right: 15px">
            <div class="col"></div>
            <div class="col-auto">
              <!-- Button -->
              <button type="submit" class="btn btn-danger lift">Filter</button>
            </div>
          </div>
          <!-- / .row -->
        </form>
      </b-collapse>
      <vue-good-table :line-numbers="true" :columns="columns" multiple :rows="ohDaily.rows" :sort-options="{
          enabled: true,
        }" :total-rows="ohDaily.totalRecords" :pagination-options="{
          enabled: true,
          mode: 'pages',
          position: 'bottom',
          perPage: body.perPage,
          perPageDropdown: [10, 50, 100],
          dropdownAllowAll: false,
          setCurrentPage: 1,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page',
          allLabel: 'All',
        }" style-class="vgt-table border-0 table" @on-page-change="onPageChange" @on-per-page-change="onPageChange" @on-sort-change="onSortChange">
        <!-- Loading -->
        <div slot="emptystate" class="text-center font-size-14">
          No data available
        </div>
        <template slot="table-row" slot-scope="props">
          <!-- Image -->
          <span v-if="props.column.field == 'image_path'">
            <img v-for="(i, index) in props.row.image_path" :key="index" style="max-height: 100px" :src="url + i" alt=""
              v-b-modal.modal-2 @click="fotoPreview(props.row)" />
            <!-- {{ i }} -->
          </span>
        </template>
      </vue-good-table>
    </div>

    <!-- Modal -->
    <!-- <b-modal id="modal-1" hide-footer :title="$t('Form Add Documentation')">
      <form @submit.prevent="saveAdd()" v-if="isAdd === true">
        <div class="form-group">
          <label>{{ $t('Name') }}</label>
          <input type="text" v-model="document_name" class="form-control" placeholder="Nama" v-validate="'required'"
            name="Nama" />
          <span class="text-sm text-danger"
            v-show="errors.has('Nama')">{{errors.first('Nama')}}</span>
        </div>
        <div class="form-group">
          <label>{{ $t('Documentation Type') }}</label>
          <v-select v-model="document_type" :options="[
                              { label: 'Cek', id: '1' },
                              { label: 'Rawat Inap', id: '2' },
                              { label: 'Operasi', id: '3' },
                          ]" v-validate="'required'" name="Jenis Documentasi" />
          <span class="text-sm text-danger" v-show="errors.has('Jenis Documentasi')">{{errors.first('Jenis Documentasi')}}</span>
        </div>
        <div class="form-group">
          <label>{{ $t('Image') }}</label>
          <input type="file" multiple  class="form-control" v-validate="'required'" ref="fileUpload" @change="fileUploader"/>
          <span class="text-sm text-danger" v-show="errors.has('file')">{{errors.first('file')}}</span>
        </div>
        <button style="margin-left: auto; float: right;" class="mt-4 btn btn-primary" type="submit">
          {{ $t('Save') }}
        </button>
      </form>
    </b-modal> -->
    <b-modal id="modal-2" size="xl" hide-footer :title="$t('Foto Documentation')">
      <!-- <div class="carousel-inner row">
        <div
          v-for="(i, index) in payloads.files"
          :key="index"
          :class="index === 0 ? 'carousel-item active' : 'carousel-item'"
        >
          <img class="d-block w-100" :src="url + i" :alt="i.name" />
        </div>
      </div> -->
      <b-carousel id="carousel1" style="text-shadow: 1px 1px 2px #333" controls indicators background="white"
        :interval="4000" img-width="1024" img-height="300" class="mycarousel">
        <!-- <b-carousel
        id="carousel1"
        style="text-shadow: 1px 1px 2px #333"
        controls
        indicators
        background="white"
        :interval="4000"
        img-width="1024"
        img-height="300"
        v-model="slide"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
        class="mycarousel"
      > -->
        <b-carousel-slide class="item row" v-for="(i, index) in payloads.files" :key="index">
          <img slot="img" class="d-block img-fluid w-100" :src="url + i" alt="factory picture" />
        </b-carousel-slide>
      </b-carousel>
    </b-modal>
  </div>
</template>
<script>
  import axiosIns from "@/api/axios";
  import moment from 'moment'
  export default {
    name: "documentation",
    computed: {
      ohDaily() {
        return this.$store.getters["documentation/actualData"] ?
          this.$store.getters["documentation/actualData"] :
          [];
      },
      bodyDateCompany() {
      return this.$store.getters["leadingLagging/body_data"]
        ? this.$store.getters["leadingLagging/body_data"]
        : [];
    }
    },
    data() {
      return {
        body: {
          page: 1,
          perPage: 10,
          sort: "",
          type_input: "monthly",
          oh_type: "document",
          year: moment().format("YYYY"),
          company_id: ""
        },
        document_name: "",
        document_type: "",
        files: null,
        activity_name: "",
        id: "",
        isAdd: true,
        payloads: {
          id: "",
          document_name: "",
          document_type: "",
          date_input: "",
          files: [],
        },
        url: "",
        load: true,
        columns: [{
            label: "Documentation Name",
            field: "value",
            thClass: "table-header no-wrap w-AUTO text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "Group Activity",
            field: "oh_label",
            thClass: "table-header no-wrap w-AUTO text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "Year",
            field: "year",
            thClass: "table-header text-center no-wrap w-AUTO text-muted font-weight-bold",
            tdClass: "no-wrap text-center color-secondary align-middle"
          },
          {
            label: "DOCUMENTATION",
            field: "image_path",
            sortable: false,
            thClass: "table-header text-center no-wrap w-AUTO text-muted font-weight-bold",
            tdClass: "no-wrap text-center color-secondary align-middle"
          }
        ],
      };
    },
    mounted() {
      this.$root.$on("documentation", (body) => {
        this.body.company_id = body.company_id;
        this.body.year = body.date_input;
        console.log('asdasd', body);
        this.getOh();
      });
      // const lastmonth = moment().endOf("year").format("M");
    // this.lastmonth = Number(lastmonth);
    if (this.bodyDateCompany.length == undefined) {
      this.body.company_id = this.bodyDateCompany.company_id;
      this.body.year = this.bodyDateCompany.date_input;
    }
      this.getOh();
      // console.log('this.body', this.body)

      this.url = axiosIns.defaults.baseURL + "/";
    },
    methods: {
      async fileUploader() {
        // this.files = this.$refs.fileUpload.files;
        // await this.saveAdd();
      },


      fotoPreview(props) {
        this.$bvModal.show("modal-2");
        this.isAddDoc = false;
        this.payloads.id = props.id;
        this.payloads.files = props.image_path;
        this.$bvModal.hide("modal-1");
      },

      // api hit
      getOh() {
        this.load = true
        this.$store.dispatch("documentation/getActual", this.body).then(() => {
          this.load = false
        });
      },

      // filter
      filterPage() {
        this.getOh();
        this.getActivity();
      },

      sorting() {
        this.getOh();
        this.getActivity();
      },
      // Pagination
      onPageChange(payload) {
        this.body.perPage = payload.currentPerPage;
        this.body.page = payload.currentPage;
          this.getOh();
      },
      onSortChange(params) {
        let sort1 = params[0].field;
        let sort2 = params[0].type;
        this.body.sort = `${sort1},${sort2}`;
        this.getOh();
      },
    },
  };
</script>