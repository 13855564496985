import queryString from "query-string";

import {
    GET_TOOLS_DATA,
    ADD_TOOLS,
    DEL_TOOLS,
    UPDATE_TOOLS
} from "@/api/inventory/tools";

const state = {
    dataTools: [],
};

const mutations = {
    setTools: (states, payload) => {
        states.dataTools = payload;
    },
};

const actions = {
    getTool: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_TOOLS_DATA(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setTools", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setTools", {
                        setTools: [],
                    });
                }
            });
    },

    addTool: async ({ }, args) => {
        return await ADD_TOOLS(args);
    },

    delTool: async ({ }, id) => {
        return await DEL_TOOLS(id);
    },

    updateTool: async ({ }, args) => {
        return await UPDATE_TOOLS(args);
    },



};

const getters = {
    ToolData: (states) => states.dataTools
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};