<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-6">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("OH Management") }}
                </li>
                <li class="breadcrumb-item">Input</li>
                <li class="breadcrumb-item">{{ company }}</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Table -->
      <div class="card mt-3">
        <div class="card-header">
          <!-- Title -->
          <ul class="nav nav-pills">
            <li class="nav-item" @click="direct = 'Daily'">
              <a class="nav-link" :class="{ active: direct === 'Daily' }">
                <h2 class="card-header-title">{{ $t("Daily") }}</h2>
              </a>
            </li>
            <li
              class="nav-item"
              style="margin-left: 0.5rem"
              @click="direct = 'Monthly'"
            >
              <a class="nav-link" :class="{ active: direct === 'Monthly' }">
                <h2 class="card-header-title">{{ $t("Monthly") }}</h2>
              </a>
            </li>
            <li
              class="nav-item"
              style="margin-left: 0.5rem"
              @click="direct = 'Yearly'"
            >
              <a class="nav-link" :class="{ active: direct === 'Yearly' }">
                <h2 class="card-header-title">{{ $t("Yearly") }}</h2>
              </a>
            </li>
            <li
              class="nav-item"
              style="margin-left: 0.5rem"
              @click="direct = 'Documentation'"
            >
              <a
                class="nav-link"
                :class="{ active: direct === 'Documentation' }"
              >
                <h2 class="card-header-title">{{ $t("Documentation") }}</h2>
              </a>
            </li>
          </ul>
        </div>
        <div v-if="direct === 'Daily'">
          <DayDaily is_admin="false" />
        </div>
        <div v-if="direct === 'Monthly'">
          <Monthly />
        </div>
        <div v-if="direct === 'Yearly'">
          <Yearly />
        </div>
        <div v-if="direct === 'Documentation'">
          <Documentation />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import DayDaily from "../../../components/input/Daily.vue";
// import DayDaily from "./tabs/daily.vue";
import Monthly from "../../../components/input/Monthly.vue";
import Yearly from "../../../components/input/Yearly.vue";
import Documentation from "../../../components/input/Documentation.vue";

export default {
  name: "Input",
  components: {
    DayDaily,
    Monthly,
    Yearly,
    Documentation,
  },
  data() {
    return {
      direct: "Daily",
      listYear: [],
      company: "",
    };
  },
  methods: {
    renderYear() {},
  },
  mounted() {
    this.company = localStorage.getItem("company_name");
    let year = moment().format("YYYY");
    for (let i = parseInt(year) - 2; i < parseInt(year) + 3; i++) {
      this.listYear.push(i);
    }
  },
};
</script>

<style>
[contenteditable="true"]:focus {
  background-color: rgb(255, 249, 170);
}

.orange {
  background-color: rgb(51, 255, 0);
}
.tableFixHead {
  overflow-y: scroll;
  height: 75vh;
}
.tableFixHead thead {
  position: sticky;
  top: 0px;
  z-index: 2;
}
.tableFixHead thead td {
  position: sticky;
  top: 0px;
  left: 0;
  z-index: 4;
  background-color: #f9fbfd;
  color: #95aac9;
}
.tableFixHead tbody th {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 0;
}
table.oh-table {
  border-collapse: collapse;
  width: 100%;
}
.oh-table th,
.oh-table td {
  padding: 8px 16px;
}
.oh-table th {
  background: #eee;
}
table.oh-table {
  /* margin: 1em 0; */
  border-collapse: collapse;
  border: 0.1em solid #d6d6d6;
}
.oh-table th,
.oh-table td {
  padding: 0.25em 0.5em 0.25em 1em;
  text-align: center;
  text-indent: -0.5em;
}

.oh-table th {
  vertical-align: bottom;
  background-color: #666;
  color: #fff;
}

.oh-table tr:nth-child(even) th[scope="row"] {
  background-color: #f2f2f2;
}

.oh-table tr:nth-child(odd) th[scope="row"] {
  background-color: #fff;
}

.oh-table tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.oh-table tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

/* th:nth-of-type(3),
td:nth-of-type(3) {
  text-align: right;
}

/* Fixed Headers */

.oh-table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

.oh-table th[scope="row"] {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}
.oh-table th[scope="colss"] {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 91;
}

.oh-table th[scope="row"] {
  vertical-align: top;
  color: inherit;
  background-color: inherit;
  background: linear-gradient(
    90deg,
    transparent 0%,
    transparent calc(100% - 0.05em),
    #d6d6d6 calc(100% - 0.05em),
    #d6d6d6 100%
  );
}

table.oh-table:nth-of-type(2) th:not([scope="row"]):first-child {
  left: 0;
  z-index: 3;
  background: linear-gradient(
    90deg,
    #666 0%,
    #666 calc(100% - 0.05em),
    #ccc calc(100% - 0.05em),
    #ccc 100%
  );
}
</style>
