<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <!-- / .row -->

      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("Item") }}</h4>
          <!-- Button -->
          <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
            <span class="fe fe-file-plus"></span>
            {{ $t("Add") }}
          </b-button>
          <b-button
            v-b-toggle.collapse-1
            variant="primary"
            style="margin-left: 7px"
          >
            <span class="fe fe-sliders"></span>
          </b-button>
          <!-- <b-button v-b-toggle.collapse-1 variant="primary" style="margin-left : 7px">
            <span class="fe fe-sliders"></span>
          </b-button> -->
        </div>
        <b-collapse id="collapse-1">
          <form @submit.prevent="sorting()">
            <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label"
                    >Group</label
                  >
                  <v-select
                    v-model="body.group_id"
                    :options="group.data"
                    label="group_name"
                    :reduce="(e) => e.id"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Item")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="body.item_name"
                  />
                </div>
              </div>
            </div>

            <div class="row" style="margin-left: 15px; margin-right: 15px">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Item Unit")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="body.item_unit"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label"
                    >Order</label
                  >
                  <v-select v-model="body.sort" :options="['ASC', 'DESC']" />
                </div>
              </div>
            </div>

            <div class="row mb-3" style="margin-right: 15px">
              <div class="col"></div>
              <div class="col-auto">
                <!-- Button -->
                <button type="submit" class="btn btn-primary lift">
                  {{ $t("Filter") }}
                </button>
              </div>
            </div>
            <!-- / .row -->
          </form>
        </b-collapse>
        <vue-good-table
          :line-numbers="true"
          :columns="columns"
          :rows="item.data"
          :sort-options="{
            enabled: true,
          }"
          :total-rows="item.totalItems"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All',
          }"
          style-class="vgt-table border-0 table"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange"
        >
          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <!-- Custom Rows -->
          <template slot="table-row" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.field == 'is_string'">
              {{ is(props.row, 'is_string') }}
            </span>
            <span v-else-if="props.column.field == 'is_equal_min'">
              {{ is(props.row, 'is_equal_min') }}
            </span>
            <span v-else-if="props.column.field == 'is_equal_max'">
              {{ is(props.row, 'is_equal_max') }}
            </span>
            <span v-else-if="props.column.field == 'string_value'">
              {{ props.row.string_value ? props.row.string_value : '-' }}
            </span>
            <span v-else-if="props.column.field == 'references'">
              {{ props.row.references ? props.row.references : '-' }}
            </span>
            <span v-else-if="props.column.field == 'action'">
              <b-button
                class="no-wrap btn-pill"
                variant="warning"
                size="sm"
                @click="updateData(props.row)"
              >
                <span class="fe fe-edit"></span>
              </b-button>
              <b-button
                class="no-wrap ml-2"
                variant="danger"
                style="margin-left: 5px"
                size="sm"
                @click="deleteData(props.row.id)"
              >
                <span class="fe fe-trash-2"></span>
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </div>

      <!-- Modal -->
      <b-modal id="modal-1" hide-footer :title="$t('Item Form')">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="form-group">
            <label>Group<span class="text-danger">*</span></label>
            <v-select
              v-model="bodyAdd.group_id"
              :options="group.data"
              label="group_name"
              :reduce="(e) => e.id"
              v-validate="'required'"
              name="Group Name"
            />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Group Name')"
              >{{ errors.first("Group Name") }}</span
            >
          </div>
          <div class="form-item">
            <label>{{ $t("Item Name") }}<span class="text-danger">*</span></label>
            <input
              type="text"
              v-model="bodyAdd.item_name"
              class="form-control"
              placeholder="Item Name"
              v-validate="'required'"
              name="Item Name"
            />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Item Name')"
              >{{ errors.first("Item Name") }}</span
            >
          </div>
          <div class="form-item mt-3">
            <label>{{ $t("Description") }}</label>
            <input
              type="text"
              v-model="bodyAdd.description"
              class="form-control"
              placeholder="Description"
            />
          </div>
          <div class="form-item mt-3">
            <label>Satuan</label>
            <input
              type="text"
              v-model="bodyAdd.item_unit"
              class="form-control"
              placeholder="Satuan"
            />
          </div>
          <div class="form-item mt-3">
            <label>Is String</label>
            <v-select
            :options="[{value: true, label: 'YES'}, {value: false, label: 'NO'}]" 
            label="label"
            :reduce="(e) => e.value"
            v-model="bodyAdd.is_string"
            :clearable="false"  />
          </div>
          <div v-if="bodyAdd.is_string == false">
            <div class="form-item mt-3">
              <label>Nilai Min</label>
              <input
                type="text"
                v-model="bodyAdd.item_minimum"
                class="form-control"
                placeholder="Nilai Min"
              />
            </div>
            <div class="form-item mt-3">
              <label>Is Equal Min</label>
              <v-select
              :options="[{value: true, label: 'YES'}, {value: false, label: 'NO'}]" 
              label="label"
              :reduce="(e) => e.value"
              v-model="bodyAdd.is_equal_min"
              :clearable="false"  />
            </div>
            <div class="form-item mt-3">
              <label>Nilai Max</label>
              <input
                type="text"
                v-model="bodyAdd.item_maximum"
                class="form-control"
                placeholder="Nilai Max"
              />
            </div>
            <div class="form-item mt-3">
              <label>Is Equal Max</label>
              <v-select
              label="label"
              :reduce="(e) => e.value"
              :options="[{value: true, label: 'YES'}, {value: false, label: 'NO'}]" 
              v-model="bodyAdd.is_equal_max"
              :clearable="false"  />
            </div>
          </div>
          <div class="form-item mt-3" v-if="bodyAdd.is_string == true">
            <label>String Value</label>
            <input type="text" class="form-control" v-model="bodyAdd.string_value">
          </div>
          <div>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false"
            >
              {{ $t("Save") }}
            </button>
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled v-else>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="form-group">
            <label>Group<span class="text-danger">*</span></label>
            <v-select
              v-model="bodyUpdate.group_id"
              :options="group.data"
              label="group_name"
              :reduce="(e) => e.id"
              v-validate="'required'"
              name="Group Name"
            />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Group Name')"
              >{{ errors.first("Group Name") }}</span
            >
          </div>
          <div class="form-item">
            <label>{{ $t("Item Name") }}<span class="text-danger">*</span></label>
            <input
              type="text"
              v-model="bodyUpdate.item_name"
              class="form-control"
              placeholder="Item Name"
              v-validate="'required'"
              name="Item Name"
            />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Item Name')"
              >{{ errors.first("Item Name") }}</span
            >
          </div>
          <div class="form-item mt-3">
            <label>{{ $t("Description") }}</label>
            <input
              type="text"
              v-model="bodyUpdate.description"
              class="form-control"
              placeholder="Description"
            />
          </div>
          <div class="form-item mt-3">
            <label>Satuan</label>
            <input
              type="text"
              v-model="bodyUpdate.item_unit"
              class="form-control"
              placeholder="Satuan"
            />
          </div>
          <div class="form-item mt-3">
            <label>Is String</label>
            <v-select
            :options="[{value: true, label: 'YES'}, {value: false, label: 'NO'}]" 
            label="label"
            :reduce="(e) => e.value"
            v-model="bodyUpdate.is_string"
            :clearable="false"  />
          </div>
          <div v-if="bodyUpdate.is_string == false">
            <div class="form-item mt-3">
              <label>Nilai Min</label>
              <input
                type="text"
                v-model="bodyUpdate.item_minimum"
                class="form-control"
                placeholder="Nilai Min"
              />
            </div>
            <div class="form-item mt-3">
              <label>Is Equal Min</label>
              <v-select
              :options="[{value: true, label: 'YES'}, {value: false, label: 'NO'}]" 
              label="label"
              :reduce="(e) => e.value"
              v-model="bodyUpdate.is_equal_min"
              :clearable="false"  />
            </div>
            <div class="form-item mt-3">
              <label>Nilai Max</label>
              <input
                type="text"
                v-model="bodyUpdate.item_maximum"
                class="form-control"
                placeholder="Nilai Max"
              />
            </div>
            <div class="form-item mt-3">
              <label>Is Equal Max</label>
              <v-select
              label="label"
              :reduce="(e) => e.value"
              :options="[{value: true, label: 'YES'}, {value: false, label: 'NO'}]" 
              v-model="bodyUpdate.is_equal_max"
              :clearable="false"  />
            </div>
          </div>
          <div class="form-item mt-3" v-if="bodyUpdate.is_string == true">
            <label>String Value</label>
            <input type="text" class="form-control" v-model="bodyUpdate.string_value">
          </div>
          <div>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false"
            >
              {{ $t("Save") }}
            </button>
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled v-else>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "defaultCMS",
  computed: {
    item() {
      return this.$store.getters["itemMcu/ItemData"]
        ? this.$store.getters["itemMcu/ItemData"]
        : [];
    },
    group() {
      return this.$store.getters["groupMcu/GroupData"]
        ? this.$store.getters["groupMcu/GroupData"]
        : [];
    },
  },
  data() {
    return {
      body: {
        page: 1,
        limit: 10,
        sort: "",
        // group_id: '',
        item_name: null,
        item_unit: null,
      },
      bodys: {
        page: 1,
        limit: 1000,
        order: "",
      },
      bodyAdd: {
        group_id: "",
        item_name: "-",
        item_unit: "-",
        item_minimum: "-",
        is_string: false,
        is_equal_min: false,
        item_maximum: "-",
        is_equal_max: false,
        description: "-",
        string_value: "-"
      },
      bodyUpdate: {
        id: "",
        group_id: "",
        item_name: "-",
        item_unit: "-",
        item_minimum: "-",
        is_string: false,
        is_equal_min: false,
        item_maximum: "-",
        is_equal_max: false,
        description: "-",
        string_value: "-"
      },
      id: "",
      isAdd: true,
      isLoading: false,
      columns: [
        {
          label: "GROUP",
          field: "group_name",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "ITEM",
          field: "item_name",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "ITEM UNIT",
          field: "item_unit",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "DESC",
          field: "description",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "Is String",
          field: "is_string",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "String Value",
          field: "string_value",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "Is Equal Min",
          field: "is_equal_min",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "MIN",
          field: "item_minimum",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "Is Equal Max",
          field: "is_equal_max",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "MAX",
          field: "item_maximum",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "References Result",
          field: "references",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
      ],
    };
  },
  mounted() {
    // if (this.item && this.item.length < 1) {
      this.getItem();
      this.getGroup();
    // }
  },
  methods: {
    //action
    updateData(props) {
      this.$bvModal.show("modal-1");
      this.isAdd = false;
      this.bodyUpdate.id = props.id;
      this.bodyUpdate.item_name = props.item_name;
      this.bodyUpdate.group_id = props.group_id;
      this.bodyUpdate.item_unit = props.item_unit;
      this.bodyUpdate.is_string = props.is_string;
      this.bodyUpdate.item_minimum = props.item_minimum;
      this.bodyUpdate.is_equal_min = props.is_equal_min;
      this.bodyUpdate.item_maximum = props.item_maximum;
      this.bodyUpdate.is_equal_max = props.is_equal_max;
      this.bodyUpdate.string_value = props.string_value;
      this.bodyUpdate.description = props.description;
    },
    customStore() {
      this.$store.dispatch("itemMcu/getItem", this.item);
    },
    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.isLoading = true
          this.$store.dispatch("itemMcu/addItem", this.bodyAdd).then((resp) => {
            this.$swal({
              title: this.$t("Saved"),
              text: this.$t("Your input has been saved"),
              icon: "success",
              confirmButtonText: this.$t("OK"),
            }).then((resp) => {
              this.isLoading = false
              this.bodyAdd.item_name = "";
              this.bodyAdd.group_id = "-";
              this.bodyAdd.item_unit = "-";
              this.bodyAdd.string_value = "-"
              this.bodyAdd.item_minimum = "-";
              this.bodyAdd.item_maximum = "-";
              this.bodyAdd.is_string = false;
              this.bodyAdd.is_equal_min = false;
              this.bodyAdd.is_equal_max = false;
              this.getItem();
              this.$bvModal.hide("modal-1");
            });
          });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
        }
      });
    },

    saveUpdate() {
      this.isLoading = true
      this.$store
        .dispatch("itemMcu/updateItem", this.bodyUpdate)
        .then((resp) => {
          this.$swal({
            title: this.$t("Saved"),
            text: this.$t("Your input has been saved"),
            icon: "success",
            confirmButtonText: this.$t("OK"),
          }).then((resp) => {
            this.isLoading = false
            this.getItem();
            this.$bvModal.hide("modal-1");
          });
        })
        .catch((resp) => {
          this.$swal("Error", this.$t("Error while edit data"), "error");
        });
    },

    deleteData(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel"),
      }).then((item) => {
        if (item.isConfirmed) {
          this.$store
            .dispatch("itemMcu/delItem", id)
            .then((resp) => {
              this.getItem();
              this.$swal(
                "Deleted!",
                this.$t("Your file has been deleted"),
                "success"
              );
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    },

    is(prop, condi) {
      if (condi == 'is_string') {
        if (prop.is_string == false) {
          return 'NO'          
        } else {
          return 'YES'          
        } 
      } else if (condi == 'is_equal_min') {
        if (prop.is_equal_min == false) {
          return 'NO'          
        } else {
          return 'YES'          
        } 
      } else if (condi == 'is_equal_max') {
        if (prop.is_equal_max == false) {
          return 'NO'          
        } else {
          return 'YES'          
        } 
      }
    },

    // api hit
    getItem() {
      this.$store.dispatch("itemMcu/getItem", this.body);
      // this.customStore();
    },
    getGroup() {
      this.$store.dispatch("groupMcu/getGroup", this.bodys);
      // this.customStore();
    },

    // filter
    filterPage() {
      this.getItem();
    },

    sorting() {
      this.getItem();
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;

      // if (
      //   parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
      //   1000
      // ) {
        this.getItem();
      // }
    },
  },
};
</script>