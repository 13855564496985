<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-6">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item">{{ $t("Inventory") }}</li>
                <li class="breadcrumb-item">Stock Opname</li>
                <li class="breadcrumb-item">Opname Detail</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Table -->
      <div class="row">
        <div class="col">
          <div class="card mt-2">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title">{{ $t("Detail") }}</h4>
              <b-button
                class="no-wrap btn-pill"
                title="Download"
                variant="primary"
                size="sm"
                @click="exporting()">
                <span class="fe fe-download-cloud"></span>
                Download
              </b-button>
              <!-- Button -->
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-md-4">
                  <!-- Card -->
                  <div class="card">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col">
                          <!-- Title -->
                          <h6 class="text-uppercase text-muted mb-2">
                            Internal ID
                          </h6>

                          <!-- Heading -->
                          <span class="h2 mb-0">
                            {{ dataOpname.id }}
                          </span>
                        </div>
                        <div class="col-auto">
                          <!-- Icon -->
                          <!-- <a class="btn btn-sm btn-primary" href="pricing.html">Upgrade</a> -->
                        </div>
                      </div>
                      <!-- / .row -->
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <!-- Card -->
                  <div class="card">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col">
                          <!-- Title -->
                          <h6 class="text-uppercase text-muted mb-2">
                            Adjustment Time
                          </h6>

                          <!-- Heading -->
                          <span class="h2 mb-0">
                            {{ convertDate(dataOpname.opname_date) }}
                          </span>
                        </div>
                        <div class="col-auto">
                          <!-- Chart -->
                          <div class="chart chart-sparkline">
                            <canvas
                              class="chart-canvas"
                              id="sparklineChart"
                              width="75"
                              height="35"
                              style="
                                display: block;
                                box-sizing: border-box;
                                height: 35px;
                                width: 75px;
                              "></canvas>
                          </div>
                        </div>
                      </div>
                      <!-- / .row -->
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <!-- Card -->
                  <div class="card">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col">
                          <!-- Title -->
                          <h6 class="text-uppercase text-muted mb-2">Status</h6>

                          <!-- Heading -->
                          <span class="h2 mb-0">
                            {{ dataOpname.so_status }}
                          </span>
                        </div>
                        <div class="col-auto">
                          <!-- Chart -->
                          <div class="chart chart-sparkline">
                            <canvas
                              class="chart-canvas"
                              id="sparklineChart"
                              width="75"
                              height="35"
                              style="
                                display: block;
                                box-sizing: border-box;
                                height: 35px;
                                width: 75px;
                              "></canvas>
                          </div>
                        </div>
                      </div>
                      <!-- / .row -->
                    </div>
                  </div>
                </div>
              </div>
              <vue-good-table
                :line-numbers="true"
                :columns="listcolumns"
                :rows="dataOpname.item"
                :sort-options="{
                  enabled: true
                }"
                :pagination-options="{
                  enabled: true,
                  mode: 'pages',
                  position: 'bottom',
                  perPage: 10,
                  perPageDropdown: [10, 50, 100],
                  dropdownAllowAll: false,
                  setCurrentPage: 1,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'page',
                  allLabel: 'All'
                }"
                style-class="vgt-table table table-bordered">
                <!-- Loading -->
                <div slot="emptystate" class="text-center font-size-14">
                  No data available
                </div>
                <template slot="table-row" slot-scope="props">
                  <!-- Table Action -->
                  <span v-if="props.column.field == 'action'">
                    <b-button
                      class="no-wrap btn-pill"
                      title="Edit"
                      variant="warning"
                      size="sm"
                      @click="getDrug(props.row)">
                      <span class="fe fe-edit"></span>
                    </b-button>
                  </span>
                  <!-- <span v-else-if="props.column.field == 'price'">
                                        Rp. {{ convertNumber(props.row.price) }}
                                    </span> -->
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
  </div>
</template>
<script>
import moment from "moment";
import axiosIns from "@/api/axios";
import queryString from "query-string";
export default {
  name: "Opname Detail",
  computed: {
    dataOpname() {
      return this.$store.getters["opname/detailOpnameData"]
        ? this.$store.getters["opname/detailOpnameData"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        perPage: 10,
        searchQuery: "",
        sort: ""
      },
      dataDetail: {},
      datas: [
        {
          id: 1,
          date: "28 Feb 2023 ",
          diff: "Rp. 200.000"
        },
        {
          id: 2,
          date: "24 Feb 2023",
          diff: "Rp. 553.342"
        }
      ],
      listcolumns: [
        {
          label: "ID",
          field: "sap_material_number",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Product Name",
          field: "item_label",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Expired Date",
          field: "expiry_date",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Stock System",
          field: "stock_system",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Actual Stock",
          field: "stock_actual",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Difference",
          field: "stock_diff",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Remark",
          field: "note",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ],
      isLoading: false
    };
  },
  mounted() {
    this.getTable();
  },
  methods: {
    getTable() {
      const id = this.$router.currentRoute.query.id;
      this.$store.dispatch("opname/getDetailOpname", {
        id: id
      });
    },

    convertDate(d) {
      return moment(d).format("D MMM YYYY");
    },

    onSortChange(params) {
      let sort1 = params[0].field;
      let sort2 = params[0].type;
      this.body.sort = `${sort1},${sort2}`;
      this.getDataDO();
    },

    convertCurrency(dat) {
      return dat.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    exporting() {
      const id = this.$router.currentRoute.query.id;
      // hms/api/inventory/stock-opname/report?id=OP/013/2023-03/001
      axiosIns
        .get(
          `${axiosIns.defaults.baseURL}/inventory/stock-opname/report?id=${id}`,
          {
            responseType: "blob"
          }
        )
        .then((res) => {
          const href = URL.createObjectURL(res.data);
          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute(
            "download",
            `Uhealth_Report Stock Opname (${id}).xlsx`
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    }

    // searchByQuery(e) {
    //   console.log(e);
    // }
  }
};
</script>
