<template>
	<!-- HEADER -->
	<div class="header">
		<div class="container-fluid">
			<!-- Body -->
			<div class="mt-4">
				<!-- Title -->
				<div class="card">
					<div class="card-body">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item fw-bolder">
									{{ $t("Clinic Management") }}
								</li>
								<li class="breadcrumb-item">{{ this.breadcrumb }}</li>
								<li class="breadcrumb-item">History</li>
								<li class="breadcrumb-item">Medical Record</li>
								<li class="breadcrumb-item">Detail</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
			<div class="card p-0">
				<div class="card-header">
					<!-- Title -->
					<h4 class="card-header-title">Patient Info</h4>
				</div>
				<div v-if="load === false">
					<div class="list-group list-group-flush">
						<div class="list-group-item p-4">
							<div class="row">
								<div class="col-auto">
									<!-- Avatar -->
									<div class="avatar avatar-sm">
										<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
											<i class="fe fe-credit-card"></i>
										</div>
									</div>
								</div>
								<div class="col">
									<p class="small mt-2">
										<strong>NIK</strong>
									</p>
									<p class="small mt-2">
										<strong>{{ this.history.nik }}</strong>
									</p>
								</div>
								<div class="col-auto">
									<!-- Avatar -->
									<div class="avatar avatar-sm">
										<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
											<i class="fe fe-user"></i>
										</div>
									</div>
								</div>
								<div class="col">
									<p class="small mt-2">
										<strong>Name</strong>
									</p>
									<p class="small mt-2">
										<strong>{{ this.history.patient_name }}</strong>
									</p>
								</div>
								<div class="col-auto">
									<!-- Avatar -->
									<div class="avatar avatar-sm">
										<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
											<i class="fe fe-home"></i>
										</div>
									</div>
								</div>
								<div class="col">
									<p class="small mt-2">
										<strong>Company</strong>
									</p>
									<p class="small mt-2">
										<strong>{{ this.history.company }}</strong>
									</p>
								</div>
								<div class="col-auto">
									<!-- Avatar -->
									<div class="avatar avatar-sm">
										<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
											<i class="fe fe-layers"></i>
										</div>
									</div>
								</div>
								<div class="col">
									<p class="small mt-2">
										<strong>Departement</strong>
									</p>
									<p class="small mt-2">
										<strong>{{ this.history.department }}</strong>
									</p>
								</div>
								<div class="col-auto">
									<!-- Avatar -->
									<div class="avatar avatar-sm">
										<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
											<i class="fe fe-award"></i>
										</div>
									</div>
								</div>
								<div class="col">
									<p class="small mt-2">
										<strong>Position</strong>
									</p>
									<p class="small mt-2">
										<strong>{{ this.history.position }}</strong>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body text-center" v-else>
					<div class="spinner-border" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-xl-5">
					<!-- Content -->
					<div class="card">
						<div class="card-header">
							<!-- Title -->
							<h4 class="card-header-title">Doctor Diagnose</h4>
						</div>
						<div v-if="load === false">
							<div class="mb-3 p-4">
								<div class="row mb-4">
									<div class="col-auto">
										<!-- Avatar -->
										<div class="avatar avatar-sm">
											<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
												<i class="fe fe-file"></i>
											</div>
										</div>
									</div>
									<div class="col">
										<p class="small mt-2">
											<strong>Complaint</strong>
										</p>
										<p class="small mt-2">
											<strong>{{ dataPatient.init_complaint }}</strong>
										</p>
									</div>
									<div class="col-auto">
										<!-- Avatar -->
										<div class="avatar avatar-sm">
											<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
												<i class="fe fe-activity"></i>
											</div>
										</div>
									</div>
									<div class="col">
										<p class="small mt-2">
											<strong>BMI</strong>
										</p>
										<p class="small mt-2">
											<strong>{{ dataPatient.bmi }}</strong>
										</p>
									</div>
								</div>
								<div class="row mb-4">
									<div class="col-auto">
										<!-- Avatar -->
										<div class="avatar avatar-sm">
											<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
												<i class="fe fe-arrow-up"></i>
											</div>
										</div>
									</div>
									<div class="col">
										<p class="small mt-2">
											<strong>Height</strong>
										</p>
										<p class="small mt-2">
											<strong>{{ dataPatient.height }} CM</strong>
										</p>
									</div>
									<div class="col-auto">
										<!-- Avatar -->
										<div class="avatar avatar-sm">
											<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
												<i class="fe fe-user"></i>
											</div>
										</div>
									</div>
									<div class="col">
										<p class="small mt-2">
											<strong>Weight</strong>
										</p>
										<p class="small mt-2">
											<strong>{{ dataPatient.weight }} KG</strong>
										</p>
									</div>
								</div>
								<div class="row mb-4">
									<div class="col-auto">
										<!-- Avatar -->
										<div class="avatar avatar-sm">
											<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
												<i class="fe fe-droplet"></i>
											</div>
										</div>
									</div>
									<div class="col">
										<p class="small mt-2">
											<strong>Blood Pressure</strong>
										</p>
										<p class="small mt-2">
											<strong>{{ dataPatient.blood_pressure }} mmHg</strong>
										</p>
									</div>
									<div class="col-auto">
										<!-- Avatar -->
										<div class="avatar avatar-sm">
											<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
												<i class="fe fe-slash"></i>
											</div>
										</div>
									</div>
									<div class="col">
										<p class="small mt-2">
											<strong>Allergy</strong>
										</p>
										<p class="small mt-2">
											<strong>{{ dataPatient.allergy }}</strong>
										</p>
									</div>
								</div>

								<div class="row mb-4">
									<div class="col-auto">
										<!-- Avatar -->
										<div class="avatar avatar-sm">
											<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
												<i class="fe fe-user"></i>
											</div>
										</div>
									</div>
									<div class="col">
										<p class="small mt-2">
											<strong>Doctor</strong>
										</p>
										<p class="small mt-2">
											<strong>{{ dataPatient.doctor }}</strong>
										</p>
									</div>
									<div class="col-auto">
										<!-- Avatar -->
										<div class="avatar avatar-sm">
											<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
												<i class="fe fe-file-text"></i>
											</div>
										</div>
									</div>
									<div class="col">
										<p class="small mt-2">
											<strong>Doctor Action</strong>
										</p>
										<p class="small mt-2">
											<strong>{{ dataPatient.doctor_action }}</strong>
										</p>
									</div>
								</div>

								<div class="row mb-4">
									<div class="col-auto">
										<!-- Avatar -->
										<div class="avatar avatar-sm">
											<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
												<i class="fe fe-heart"></i>
											</div>
										</div>
									</div>
									<div class="col">
										<p class="small mt-2">
											<strong>Vital Sign</strong>
										</p>
										<p class="small mt-2">
											<strong>{{ dataPatient.vital_sign }}</strong>
										</p>
									</div>
									<div class="col-auto">
										<!-- Avatar -->
										<div class="avatar avatar-sm">
											<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
												<i class="fe fe-refresh-cw"></i>
											</div>
										</div>
									</div>
									<div class="col">
										<p class="small mt-2">
											<strong>SPELL</strong>
										</p>
										<p v-if="dataPatient.spell === true" class="small mt-2">
											<!-- <strong>{{ dataPatient.sick_leave }}</strong> -->
											<strong>Yes</strong>
										</p>
										<p v-if="dataPatient.spell === false" class="small mt-2">
											<!-- <strong>{{ dataPatient.sick_leave }}</strong> -->
											<strong>No</strong>
										</p>
										<!-- <p class="small mt-2">
											<strong>{{ dataPatient.spell }}</strong>
										</p> -->
									</div>
								</div>
							
								<div class="row mb-4">
									<div class="col-auto">
										<!-- Avatar -->
										<div class="avatar avatar-sm">
											<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
												<i class="fe fe-log-out"></i>
											</div>
										</div>
									</div>
									<div class="col">
										<p class="small mt-2">
											<strong>Sick Leave</strong>
										</p>
										<p v-if="dataPatient.sick_leave === false" class="small mt-2">
											<!-- <strong>{{ dataPatient.sick_leave }}</strong> -->
											<strong>No</strong>
										</p>
										<p v-if="dataPatient.sick_leave === true" class="small mt-2">
											<!-- <strong>{{ dataPatient.sick_leave }}</strong> -->
											<strong>Yes</strong>
										</p>
									</div>
									<div class="col-auto">
										<!-- Avatar -->
										<div class="avatar avatar-sm">
											<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
												<i class="fe fe-calendar"></i>
											</div>
										</div>
									</div>
									<div class="col">
										<p class="small mt-2">
											<strong>Sick Leave Day</strong>
										</p>
										<p class="small mt-2">
											<strong>{{ dataPatient.sick_leave_day }}</strong>
										</p>
									</div>
								</div>
								<div class="row mb-4">
									<div class="col-auto">
										<!-- Avatar -->
										<div class="avatar avatar-sm">
											<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
												<i class="fe fe-clipboard"></i>
											</div>
										</div>
									</div>
									<div class="col">
										<p class="small mt-2">
											<strong>PAK</strong>
										</p>
										<p v-if="dataPatient.pak === true" class="small mt-2">
											<!-- <strong>{{ dataPatient.sick_leave }}</strong> -->
											<strong>Yes</strong>
										</p>
										<p v-if="dataPatient.pak === false" class="small mt-2">
											<!-- <strong>{{ dataPatient.sick_leave }}</strong> -->
											<strong>No</strong>
										</p>
										<!-- <p class="small mt-2">
											<strong>{{ dataPatient.pak }}</strong>
										</p> -->
									</div>
									<div class="col-auto">
										<!-- Avatar -->
										<div class="avatar avatar-sm">
											<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
												<i class="fe fe-user-plus"></i>
											</div>
										</div>
									</div>
									<div class="col">
										<p class="small mt-2">
											<strong>Follow Up</strong>
										</p>
										<p class="small mt-2">
											<strong>{{ dataPatient.follow_up }}</strong>
										</p>
									</div>
								</div>
								<div class="row mb-4">
									<div class="col-auto">
										<!-- Avatar -->
										<div class="avatar avatar-sm">
											<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
												<i class="fe fe-arrow-right-circle"></i>
											</div>
										</div>
									</div>
									<div class="col">
										<p class="small mt-2">
											<strong>Next Visit</strong>
										</p>
										<p class="small mt-2">
											<strong>{{ dataPatient.next_visit }}</strong>
										</p>
									</div>
								</div>
							</div>
						</div>
						<div class="card-body text-center" v-else>
							<div class="spinner-border" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 col-xl-7">
					<!-- Content -->
					<div class="card">
						<div class="card-header">
							<!-- Title -->
							<h4 class="card-header-title">Disease</h4>
						</div>
						<div v-if="load === false">
							<div class="list-group list-group-flush">
								<div class="list-group-item p-4" v-for="(item, index) in dataPatient.disease" :key="index">
									<div class="row">
										<div class="col-auto">
											<!-- Avatar -->
											<div class="avatar avatar-sm">
												<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
													<i class="fe fe-thermometer"></i>
												</div>
											</div>
										</div>
										<div class="col">
											<p class="small mt-2">
												<strong>{{ item.disease_name }}</strong>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card-body text-center" v-else>
							<div class="spinner-border" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>

					<div class="card">
						<div class="card-header">
							<!-- Title -->
							<h4 class="card-header-title">Disposable</h4>
						</div>
						<div v-if="load === false">
							<div class="table-responsive">
								<table class="table table-sm">
									<thead>
										<tr>
											<th class="text-center">Item</th>
											<th class="text-center">Quantity</th>
											<th class="text-center">Unit</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in dataPatient.disposable" :key="index">
											<td class="text-center">{{ item.item_name }}</td>
											<td class="text-center">{{ item.qty }}</td>
											<td class="text-center">{{ item.unit_name }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="card-body text-center" v-else>
							<div class="spinner-border" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>

					<div class="card">
						<div class="card-header">
							<!-- Title -->
							<h4 class="card-header-title">Receipt</h4>
						</div>
						<div v-if="load === false">
							<div class="table-responsive">
								<table class="table table-sm">
									<thead>
										<tr>
											<th class="text-center">Drug</th>
											<th class="text-center">Quantity</th>
											<th class="text-center">Unit</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in dataPatient.drugs" :key="index">
											<td class="text-center">{{ item.drugs_name }}</td>
											<td class="text-center">{{ item.qty }}</td>
											<td class="text-center">{{ item.unit_name }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="card-body text-center" v-else>
							<div class="spinner-border" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
					<div class="card" v-if="dataPatient.follow_up == 3">
						<div class="card-header">
							<!-- Title -->
							<h4 class="card-header-title">Document Referral</h4>
						</div>
						<div v-if="load === false">
							<div class="list-group list-group-flush">
								<div class="list-group-item p-4" v-for="(item, index) in dataPatient.disease" :key="index">
									<div class="row">
										<div class="col-auto" v-b-modal.modal-1 @click="documentView">
											<!-- Avatar -->
											<div class="avatar avatar-sm">
												<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
													<i class="fe fe-file"></i>
												</div>
											</div>
										</div>
										<div class="col">
											<p class="small mt-2">
												<strong>Nama Document Referral</strong>
												<!-- <strong>{{ item.disease_name }}</strong> -->
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card-body text-center" v-else>
							<div class="spinner-border" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal id="modal-1" size="xl" hide-footer :title="$t('Document Referral')">
			<div class="row" v-for="(item, index) in payload.files" :key="index">
				<img slot="img" class="d-block img-fluid w-50" :src="url + item" alt="Document Referal" />
			</div>
		</b-modal>
	</div>
</template>
<script>
import axiosIns from '@/api/axios';
export default {
	name: "patient",
	components: {},
	computed: {
		dataPatient() {
			return this.$store.getters["historyMcu/detailPatientRecord"]
				? this.$store.getters["historyMcu/detailPatientRecord"]
				: [];
		},
	},
	data() {
		return {
			register_id: "",
			history: [],
			breadcrumb: "",
			load: false,
			payload: {
				id: "",
				files: [],
			},
			url: "",
		};
	},
	mounted() {
		this.register_id = localStorage.getItem("registerID");
		this.breadcrumb = localStorage.getItem("breadcrumb");
		this.history = JSON.parse(localStorage.getItem("dataMedical"));
		this.getDetail();
		this.url = axiosIns.defaults.baseURL + "/";
	},

	methods: {
		getDetail() {
			let body = {
				register_id: this.register_id,
			};
			this.load = true;
			this.$store.dispatch("historyMcu/getDetailRecordPatient", body).then(() => {
				this.load = false;
			});
		},
		documentView(props) {
			this.$bvModal.show("modal-1");
			this.payloads.files = props.files;
		},
	},
};
</script>
