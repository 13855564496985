import queryString from "query-string";

import {
    GET_DISEASE_DATA,
    ADD_DESEASE,
    DEL_DESEASE,
    UPDATE_DISEASE,
    DISEASE_DETAIL,
    DISIASE_DIAGNOSE
} from "@/api/disease/disease";

const state = {
    dataDesease: [],
    dataDeseaseDiagnose: [],
    disease: {},
};

const mutations = {
    setDesease: (states, payload) => {
        states.dataDesease = payload;
    },
    setDeseaseDiagnose: (states, payload) => {
        states.dataDeseaseDiagnose = payload;
    },
    setDetailDisease: (states, payload) => {
        states.disease = payload;
        payload = { ...payload, ...{ id: Number(payload['id']) } }
        states.dataDesease.data.push(payload)

    },
};

const actions = {
    getDesease: async ({
        commit
    }, args) => {
        try {
            const params = queryString.stringify(args);
            const resp = await GET_DISEASE_DATA(params)
            if (resp.data.httpCode === 200) {
                commit("setDesease", resp.data.data);
            }
        } catch (err) {
            console.log('Error while get desase')
            let resp = err.response;
            console.log(err);
            if (resp.data.httpCode === 404) {
                commit("setDesease", {
                    setDesease: [],
                });
            }
        }
    },

    getDiseaseDiagnose: async ({
        commit
    }, args) => {
        try {
            const params = queryString.stringify(args);
            const resp = await DISIASE_DIAGNOSE(params)
            if (resp.data.httpCode === 200) {
                commit("setDeseaseDiagnose", resp.data.data);
            }
        } catch (err) {
            console.log('Error while get desase')
            let resp = err.response;
            console.log(err);
            if (resp.data.httpCode === 404) {
                commit("setDeseaseDiagnose", {
                    setDeseaseDiagnose: [],
                });
            }
        }
    },

    getDetailDisease: async ({
        commit
    }, args) => {
        try {
            // const params = queryString.stringify(args);
            const resp = await DISEASE_DETAIL(args)
            if (resp.data.httpCode === 200) {

                commit("setDetailDisease", resp.data.data);
            }
        } catch (err) {
            console.log('Error while get desase')
            let resp = err.response;
            console.log(err);
            if (resp.data.httpCode === 404) {
                console.log('disease not found')
            }
        }
    },

    addDesease: async ({ }, args) => {
        return await ADD_DESEASE(args);
    },

    delDesease: async ({ }, id) => {
        return await DEL_DESEASE(id);
    },

    updateDisease: async ({ }, args) => {
        return await UPDATE_DISEASE(args);
    },



};

const getters = {
    deseaseData: (states) => states.dataDesease,
    deseaseDataDiagnose: (states) => states.dataDeseaseDiagnose
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};