import queryString from "query-string";

import {
    GET_ACTIVITY_DATA,
    ADD_ACTIVITY,
    DEL_ACTIVITY,
    UPDATE_ACTIVITY
} from "@/api/oh/activity";

const state = {
    dataActivity: [],
};

const mutations = {
    setActivity: (states, payload) => {
        states.dataActivity = payload;
    },
};

const actions = {
    getActivity: async ({
        commit
    }, args) => {
        if (args.data && args.data.length > 0) {
            return args.data;
        } else {
            const params = queryString.stringify(args);
            return await GET_ACTIVITY_DATA(params)
                .then((resp) => {

                    if (resp.data.httpCode === 200) {
                        commit("setActivity", resp.data.data);
                    }
                })
                .catch((err) => {
                    let resp = err.response;
                    console.log(err);
                    if (resp.data.httpCode === 404) {
                        commit("setActivity", {
                            setActivity: [],
                        });
                    }
                });
        }
    },

    addActivity: async ({ }, args) => {
        return await ADD_ACTIVITY(args);
    },

    delActivity: async ({ }, id) => {
        return await DEL_ACTIVITY(id);
    },

    updateActivity: async ({ }, args) => {
        return await UPDATE_ACTIVITY(args);
    },



};

const getters = {
    ActivityData: (states) => states.dataActivity
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};