<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-6">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item ">
                  {{ $t("IH Management") }}
                </li>
                <li class="breadcrumb-item fw-bolder">Measurement</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h2 class="card-header-title">{{ $t("Measurement") }}</h2>
          <!-- Button -->
          <b-button
            v-if="
              (campaignData.id == body.campaign && rolesId === 1) ||
              rolesId === 2
            "
            v-b-modal.modal-1
            variant="primary"
            style="margin-right: 7px"
            @click="openAdd()">
            <span class="fe fe-file-plus"></span>
            {{ $t("Add") }}
          </b-button>
          <b-button
            variant="outline-primary"
            style="margin-right: 7px"
            @click="download()">
            <span class="fe fe-download"></span>
            {{ $t("Export") }}
          </b-button>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="form-group col">
              <label>{{ $t("Company") }}</label>
              <v-select
                :options="selectCompanyOption()"
                label="company_name"
                :reduce="(e) => e.id"
                v-model="body.company_id"
                @input="getMeasuring()" />
            </div>
            <div class="form-group col">
              <label>{{ $t("Campaign") }}</label>
              <v-select
                :options="campaign.rows"
                v-model="body.campaign"
                :reduce="(e) => e.id"
                label="label"
                @input="getMeasuring()" />
            </div>
            <div class="form-group col">
              <label>{{ $t("Search") }}</label>
              <b-form-input
                v-model="body.searchQuery"
                placeholder="Type Here..."
                @change="getMeasuring()"></b-form-input>
            </div>
          </div>

          <!-- <div class="d-flex justify-content-end mb-3">
                        <label class="align-self-center mb-0 mr-3">Search</label>
                        <b-form-input v-model="body.searchQuery" class="w-auto" placeholder="Type Here..."
                            @change="getPosition()"></b-form-input>
                    </div> -->
          <vue-good-table
            mode="remote"
            :line-numbers="true"
            :columns="columns"
            :rows="measuring.rows"
            :total-rows="measuring.totalRecords"
            :sort-options="{
              enabled: true
            }"
            :pagination-options="{
              enabled: true,
              perPage: body.perPage
            }"
            style-class="vgt-table table table-bordered"
            @on-sort-change="onSortChange"
            @on-page-change="onPageChange"
            @on-per-page-change="onPageChange"
            :isLoading.sync="isLoading">
            <!-- Loading -->
            <div slot="emptystate" class="text-center font-size-14">
              No data available
            </div>
            <!-- Custom Rows -->
            <template slot="table-row" slot-scope="props">
              <!-- Table Action -->
              <span v-if="props.column.field == 'action'">
                <b-button
                  class="no-wrap btn-pill mr-2"
                  variant="info"
                  size="sm"
                  @click.prevent="
                    $router.push(`/ih/measuring/detail?id=${props.row.id}`)
                  ">
                  <span class="fe fe-eye"></span>
                </b-button>
                <b-button
                  class="no-wrap btn-pill mr-2"
                  variant="warning"
                  size="sm"
                  @click="UpdateData(props.row)">
                  <span class="fe fe-edit"></span>
                </b-button>
                <b-button
                  class="no-wrap btn-pill"
                  variant="danger"
                  size="sm"
                  @click="deleteData(props.row.id)">
                  <span class="fe fe-trash"></span>
                </b-button>
              </span>
              <span v-else-if="props.column.field == 'status'">
                <span v-if="props.row.status == 'OPEN'" class="badge bg-warning"
                  >OPEN</span
                >
                <span
                  v-if="props.row.status == 'IN-PROGRESS'"
                  class="badge bg-info"
                  >IN PROGRESS</span
                >
                <span
                  v-if="props.row.status == 'CLOSED'"
                  class="badge bg-success"
                  >CLOSED</span
                >
              </span>
            </template>
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-end">
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="measuring.totalRecords"
                    :per-page="body.perPage"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="m-3"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    ">
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </div>
      <!-- Modal -->
      <b-modal
        id="modal-1"
        size="xl"
        centered
        hide-footer
        :title="$t('Measuring IH Form')">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <b-tabs v-model="tabIndex" content-class="mt-3" fill>
            <b-tab title="Problem, Identification, & Causing" active>
              <div class="row g-3 mt-2">
                <div class="form-group col-12">
                  <label
                    >{{ $t("Factor") }}<span class="text-danger">*</span></label
                  >
                  <v-select
                    v-validate="'required'"
                    name="Factor"
                    :options="factor.data"
                    label="factor_name"
                    :reduce="(e) => e.id"
                    v-model="bodyAdd.factor" />
                  <small class="text-muted"
                    >Didn't find what you were looking for,
                    <a href="#" @click.prevent="$router.push(`/ih/factor`)"
                      >add new</a
                    ></small
                  >
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Factor')"
                    >{{ errors.first("Factor") }}</span
                  >
                </div>
                <div class="form-group col-6">
                  <label
                    >{{ $t("Measurement Date")
                    }}<span class="text-danger">*</span></label
                  >
                  <input
                    v-validate="'required'"
                    name="Date"
                    :max="now()"
                    type="date"
                    class="form-control"
                    placeholder="Date"
                    v-model="bodyAdd.measurement_date" />
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Date')"
                    >{{ errors.first("Date") }}</span
                  >
                </div>
                <div class="form-group col-6">
                  <label>{{ $t("Campaign") }}</label>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    :value="campaignData.campaign_name" />
                </div>
                <!-- <div class="form-group col-6">
                                    <label>{{ $t("Campaign")}}<span class="text-danger">*</span></label>
                                    <v-select :options="campaign.rows" v-model="bodyAdd.campaign" :reduce="(e) => e.id"
                                        label="campaign_name" />
                                    <span class="text-sm text-danger"
                                        v-show="errors.has('Campaign')">{{ errors.first("Campaign") }}</span>
                                </div> -->
                <div class="form-group col-6">
                  <label>{{ $t("Location") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Location"
                    v-model="bodyAdd.location" />
                </div>
                <div class="form-group col-6">
                  <label
                    >{{ $t("Activity")
                    }}<span class="text-danger">*</span></label
                  >
                  <v-select
                    v-validate="'required'"
                    name="Activity"
                    :options="measuringActivity.data"
                    label="measuring_activity_name"
                    :reduce="(e) => e.id"
                    v-model="bodyAdd.activity" />
                  <small class="text-muted"
                    >Didn't find what you were looking for,
                    <a
                      href="#"
                      @click.prevent="$router.push(`/ih/measuring/activity`)"
                      >add new</a
                    ></small
                  >
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Activity')"
                    >{{ errors.first("Activity") }}</span
                  >
                </div>
                <div class="form-group col-6">
                  <label
                    >{{ $t("Method") }}<span class="text-danger">*</span></label
                  >
                  <v-select
                    v-validate="'required'"
                    name="Method"
                    :options="measuringMethod.data"
                    label="measuring_method_name"
                    :reduce="(e) => e.id"
                    v-model="bodyAdd.method" />
                  <small class="text-muted"
                    >Didn't find what you were looking for,
                    <a
                      href="#"
                      @click.prevent="$router.push(`/ih/measuring/method`)"
                      >add new</a
                    ></small
                  >
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Method')"
                    >{{ errors.first("Method") }}</span
                  >
                </div>
                <div class="form-group col-6">
                  <label
                    >{{ $t("Kit") }}<span class="text-danger">*</span></label
                  >
                  <v-select
                    v-validate="'required'"
                    name="Kit"
                    :options="kit.rows"
                    label="kit_name"
                    v-model="kitSelected"
                    @input="selectedKit()" />
                  <small class="text-muted"
                    >Didn't find what you were looking for,
                    <a
                      href="#"
                      @click.prevent="$router.push(`/ih/measuring/kit`)"
                      >add new</a
                    ></small
                  >
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Kit')"
                    >{{ errors.first("Kit") }}</span
                  >
                </div>
                <div class="form-group col-6">
                  <label
                    >{{ $t("Treshold Condition")
                    }}<span class="text-danger">*</span></label
                  >
                  <v-select
                    :options="['EQUAL', 'MORE', 'LESS', 'BETWEEN', 'NONE']"
                    v-validate="'required'"
                    name="Treshold Condition"
                    type="text"
                    v-model="bodyAdd.nab_condition" />
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Treshold Condition')"
                    >{{ errors.first("Treshold Condition") }}</span
                  >
                </div>
                <div
                  :class="`form-group ${
                    bodyAdd.nab_condition == 'BETWEEN' ? 'col-3' : 'col-6'
                  }`">
                  <label
                    >{{ $t("Treshold")
                    }}<span class="text-danger">*</span></label
                  >
                  <input
                    v-validate="'required'"
                    name="Treshold"
                    type="text"
                    class="form-control"
                    placeholder="Treshold"
                    v-model="bodyAdd.nab1" />
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Treshold')"
                    >{{ errors.first("Treshold") }}</span
                  >
                </div>
                <div
                  v-if="bodyAdd.nab_condition == 'BETWEEN'"
                  class="form-group col-3">
                  <label
                    >{{ $t("Treshold 2")
                    }}<span class="text-danger">*</span></label
                  >
                  <input
                    v-validate="'required'"
                    name="Treshold 2"
                    type="text"
                    class="form-control"
                    placeholder="Treshold"
                    v-model="bodyAdd.nab2" />
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Treshold')"
                    >{{ errors.first("Treshold") }}</span
                  >
                </div>
                <div class="form-group col-6">
                  <label>{{ $t("Unit") }}</label>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    v-model="unitKit" />
                </div>
                <div class="form-group col-6">
                  <label
                    >{{ $t("Result") }}<span class="text-danger">*</span></label
                  >
                  <input
                    v-validate="'required'"
                    name="Result"
                    type="text"
                    class="form-control"
                    placeholder="Result"
                    v-model="bodyAdd.result" />
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Result')"
                    >{{ errors.first("Result") }}</span
                  >
                </div>
                <div class="form-group col-6">
                  <label>{{ $t("Causing") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Causing"
                    v-model="bodyAdd.causing" />
                </div>
                <div class="form-group">
                  <button
                    type="button"
                    style="margin-left: auto; float: right"
                    class="mt-4 btn btn-primary ml-2"
                    @click="tabIndex++">
                    {{ $t("Next") }}
                  </button>
                </div>
              </div>
            </b-tab>
            <b-tab title="Corrective Actions">
              <div class="text-right">
                <b-dropdown variant="primary">
                  <template #button-content>
                    <span class="fe fe-plus"></span> Add
                  </template>
                  <b-dropdown-item @click="getAction('Elimination')"
                    >Elimination</b-dropdown-item
                  >
                  <b-dropdown-item @click="getAction('Substitutions')"
                    >Substitutions</b-dropdown-item
                  >
                  <b-dropdown-item @click="getAction('Engineering Control')"
                    >Engineering Control
                  </b-dropdown-item>
                  <b-dropdown-item @click="getAction('Administrative Control')"
                    >Administrative Control
                  </b-dropdown-item>
                  <b-dropdown-item @click="getAction('PPE / APD')"
                    >PPE / APD</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <div
                class="form-group"
                v-for="(i, index) in corrective"
                :key="`CA-${index}`">
                <h2>{{ i.corrective_action_name }}</h2>
                <button
                  type="button"
                  class="btn btn-primary mt-4 float-end"
                  @click="removeItem(index)">
                  <span class="fe fe-trash"></span>
                </button>
                <div class="row">
                  <div class="col-lg-12 mb-3">
                    <label>Action</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Action"
                      v-model="corrective[index]['actions']" />
                  </div>
                  <div class="col-lg-3">
                    <label
                      >Due Date<span
                        class="text-danger"
                        v-if="corrective[index]['actions']"
                        >*</span
                      ></label
                    >

                    <input
                      v-validate="corrective[index]['action'] ? 'required' : ''"
                      :name="`DueDate-${index}`"
                      type="date"
                      class="form-control"
                      placeholder="Due Date"
                      :min="now()"
                      :data-vv-as="`date-${corrective[index]['label']}`"
                      v-model="corrective[index]['due_date']" />

                    <span
                      class="text-sm text-danger"
                      v-show="errors.has(`DueDate-${index}`)"
                      >{{ "Due Date is Required" }}</span
                    >
                  </div>
                  <div class="col-lg-6">
                    <label
                      >PIC<span
                        class="text-danger"
                        v-if="corrective[index]['action']"
                        >*</span
                      ></label
                    >
                    <v-select
                      v-validate="
                        corrective[index]['actions'] ? 'required' : ''
                      "
                      v-model="corrective[index]['pic']"
                      :options="users"
                      label="name_label"
                      :reduce="(e) => e.keycloak_user_id"
                      :name="`PIC-${index}`" />

                    <!-- <input v-validate="corrective[index]['action'] ? 'required' : ''" type="text"
                                            class="form-control" placeholder="PIC" v-model="corrective[index]['pic']"
                                            :name="`PIC-${index}`" /> -->

                    <span
                      class="text-sm text-danger"
                      v-show="errors.has(`PIC-${index}`)"
                      >{{ "PIC is Required" }}</span
                    >
                  </div>
                  <div class="col-lg-3">
                    <label
                      >Status<span
                        class="text-danger"
                        v-if="corrective[index]['actions']"
                        >*</span
                      ></label
                    >
                    <v-select
                      v-validate="
                        corrective[index]['actions'] ? 'required' : ''
                      "
                      :name="`Status-${index}`"
                      :options="['OPEN', 'IN PROGRESS', 'CLOSED']"
                      v-model="corrective[index]['status']" />

                    <span
                      class="text-sm text-danger"
                      v-show="errors.has(`Status-${index}`)"
                      >{{ "Status is Required" }}</span
                    >
                  </div>
                </div>
                <hr v-if="index + 1 < corrective.length" />
                <!-- <span class="text-sm text-danger"
                                    v-show="errors.has('Date')">{{ errors.first("Date") }}</span> -->
              </div>
              <div class="form-group">
                <button
                  style="margin-left: auto; float: right"
                  class="mt-4 btn btn-primary ml-2"
                  type="submit"
                  v-if="isLoading == false">
                  {{ $t("Save") }}
                </button>
                <button
                  style="margin-left: auto; float: right"
                  class="mt-4 btn btn-primary"
                  type="button"
                  disabled
                  v-else>
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>
                  Loading...
                </button>
                <button
                  style="margin-left: auto; float: right"
                  class="mt-4 btn btn-primary ml-2"
                  @click="tabIndex--">
                  {{ $t("Prev") }}
                </button>
              </div>
            </b-tab>
          </b-tabs>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <b-tabs v-model="tabIndex" content-class="mt-3" fill>
            <b-tab title="Problem, Identification, & Causing" active>
              <div class="row g-3 mt-2">
                <div class="form-group col-12">
                  <label
                    >{{ $t("Factor") }}<span class="text-danger">*</span></label
                  >
                  <v-select
                    v-validate="'required'"
                    name="Factor"
                    :options="factor.data"
                    label="factor_name"
                    :reduce="(e) => e.id"
                    v-model="bodyUpdate.factor" />
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Factor')"
                    >{{ errors.first("Factor") }}</span
                  >
                </div>
                <div class="form-group col-6">
                  <label
                    >{{ $t("Measurement Date")
                    }}<span class="text-danger">*</span></label
                  >
                  <input
                    v-validate="'required'"
                    name="Date"
                    :max="now()"
                    type="date"
                    class="form-control"
                    placeholder="Date"
                    v-model="bodyUpdate.measurement_date" />
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Date')"
                    >{{ errors.first("Date") }}</span
                  >
                </div>
                <div class="form-group col-6">
                  <label>{{ $t("Campaign") }}</label>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    :value="campaignData.campaign_name" />
                </div>
                <!-- <div class="form-group col-6">
                                    <label>{{ $t("Campaign")}}<span class="text-danger">*</span></label>
                                    <v-select :options="campaign.rows" v-model="bodyUpdate.campaign" :reduce="(e) => e.id"
                                        label="campaign_name" />
                                    <span class="text-sm text-danger"
                                        v-show="errors.has('Campaign')">{{ errors.first("Campaign") }}</span>
                                </div> -->
                <div class="form-group col-6">
                  <label>{{ $t("Location") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Location"
                    v-model="bodyUpdate.location" />
                </div>
                <div class="form-group col-6">
                  <label
                    >{{ $t("Activity")
                    }}<span class="text-danger">*</span></label
                  >
                  <v-select
                    v-validate="'required'"
                    name="Activity"
                    :options="measuringActivity.data"
                    label="measuring_activity_name"
                    :reduce="(e) => e.id"
                    v-model="bodyUpdate.activity" />
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Activity')"
                    >{{ errors.first("Activity") }}</span
                  >
                </div>
                <div class="form-group col-6">
                  <label
                    >{{ $t("Method") }}<span class="text-danger">*</span></label
                  >
                  <v-select
                    v-validate="'required'"
                    name="Method"
                    :options="measuringMethod.data"
                    label="measuring_method_name"
                    :reduce="(e) => e.id"
                    v-model="bodyUpdate.method" />
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Method')"
                    >{{ errors.first("Method") }}</span
                  >
                </div>
                <div class="form-group col-6">
                  <label
                    >{{ $t("Kit") }}<span class="text-danger">*</span></label
                  >
                  <v-select
                    v-validate="'required'"
                    name="Kit"
                    :options="kit.rows"
                    :reduce="(e) => e.id"
                    label="kit_name"
                    v-model="bodyUpdate.kit"
                    @input="selectedKit2()" />
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Kit')"
                    >{{ errors.first("Kit") }}</span
                  >
                </div>
                <div class="form-group col-6">
                  <label
                    >{{ $t("Treshold Condition")
                    }}<span class="text-danger">*</span></label
                  >
                  <v-select
                    :options="['EQUAL', 'MORE', 'LESS', 'BETWEEN', 'NONE']"
                    v-validate="'required'"
                    name="Treshold Condition"
                    type="text"
                    v-model="bodyUpdate.nab_condition" />
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Treshold Condition')"
                    >{{ errors.first("Treshold Condition") }}</span
                  >
                </div>
                <div
                  :class="`form-group ${
                    bodyUpdate.nab_condition == 'BETWEEN' ? 'col-3' : 'col-6'
                  }`">
                  <label
                    >{{ $t("Treshold")
                    }}<span class="text-danger">*</span></label
                  >
                  <input
                    v-validate="'required'"
                    name="Treshold"
                    type="text"
                    class="form-control"
                    placeholder="Treshold"
                    v-model="bodyUpdate.nab1" />
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Treshold')"
                    >{{ errors.first("Treshold") }}</span
                  >
                </div>
                <div
                  v-if="bodyUpdate.nab_condition == 'BETWEEN'"
                  class="form-group col-3">
                  <label
                    >{{ $t("Treshold 2")
                    }}<span class="text-danger">*</span></label
                  >
                  <input
                    v-validate="'required'"
                    name="Treshold 2"
                    type="text"
                    class="form-control"
                    placeholder="Treshold"
                    v-model="bodyUpdate.nab2" />
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Treshold')"
                    >{{ errors.first("Treshold") }}</span
                  >
                </div>
                <div class="form-group col-6">
                  <label>{{ $t("Unit") }}</label>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    :value="kitName" />
                </div>
                <div class="form-group col-6">
                  <label
                    >{{ $t("Result") }}<span class="text-danger">*</span></label
                  >
                  <input
                    v-validate="'required'"
                    name="Result"
                    type="text"
                    class="form-control"
                    placeholder="Result"
                    v-model="bodyUpdate.result" />
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Result')"
                    >{{ errors.first("Result") }}</span
                  >
                </div>
                <div class="form-group col-6">
                  <label>{{ $t("Causing") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Causing"
                    v-model="bodyUpdate.causing" />
                </div>
                <div class="form-group">
                  <button
                    style="margin-left: auto; float: right"
                    class="mt-4 btn btn-primary ml-2"
                    @click="tabIndex++">
                    {{ $t("Next") }}
                  </button>
                </div>
              </div>
            </b-tab>
            <b-tab title="Corrective Actions">
              <div class="text-right">
                <b-dropdown variant="primary">
                  <template #button-content>
                    <span class="fe fe-plus"></span> Add
                  </template>
                  <b-dropdown-item @click="getAction('Elimination')"
                    >Elimination</b-dropdown-item
                  >
                  <b-dropdown-item @click="getAction('Substitutions')"
                    >Substitutions</b-dropdown-item
                  >
                  <b-dropdown-item @click="getAction('Engineering Control')"
                    >Engineering Control
                  </b-dropdown-item>
                  <b-dropdown-item @click="getAction('Administrative Control')"
                    >Administrative Control
                  </b-dropdown-item>
                  <b-dropdown-item @click="getAction('PPE / APD')"
                    >PPE / APD</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <div
                class="form-group"
                v-for="(i, index) in corrective"
                :key="`CA-${index}`">
                <h2>{{ i.corrective_action_name }}</h2>
                <button
                  type="button"
                  class="btn btn-primary mt-4 float-end"
                  @click="removeItem(index)">
                  <span class="fe fe-trash"></span>
                </button>
                <div class="row">
                  <div class="col-lg-12 mb-3">
                    <label>Action</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Action"
                      v-model="i.actions" />
                  </div>
                  <div class="col-lg-3">
                    <label
                      >Due Date<span class="text-danger" v-if="i.actions"
                        >*</span
                      ></label
                    >

                    <input
                      v-validate="i.action ? 'required' : ''"
                      :name="`DueDate-${index}`"
                      type="date"
                      class="form-control"
                      placeholder="Due Date"
                      :min="now()"
                      :data-vv-as="`date-${i.label}`"
                      v-model="i.due_date" />

                    <span
                      class="text-sm text-danger"
                      v-show="errors.has(`DueDate-${index}`)"
                      >{{ "Due Date is Required" }}</span
                    >
                  </div>
                  <div class="col-lg-6">
                    <label
                      >PIC<span class="text-danger" v-if="i.action"
                        >*</span
                      ></label
                    >
                    <v-select
                      v-validate="i.actions ? 'required' : ''"
                      v-model="i.pic"
                      :options="users"
                      label="name_label"
                      :reduce="(e) => e.keycloak_user_id"
                      :name="`PIC-${index}`" />

                    <!-- <input v-validate="i.['action'] ? 'required' : ''" type="text"
                                            class="form-control" placeholder="PIC" v-model="i.['pic']"
                                            :name="`PIC-${index}`" /> -->

                    <span
                      class="text-sm text-danger"
                      v-show="errors.has(`PIC-${index}`)"
                      >{{ "PIC is Required" }}</span
                    >
                  </div>
                  <div class="col-lg-3">
                    <label
                      >Status<span class="text-danger" v-if="i.actions"
                        >*</span
                      ></label
                    >
                    <v-select
                      v-validate="i.actions ? 'required' : ''"
                      :name="`Status-${index}`"
                      :options="['OPEN', 'IN PROGRESS', 'CLOSED']"
                      v-model="i.status" />

                    <span
                      class="text-sm text-danger"
                      v-show="errors.has(`Status-${index}`)"
                      >{{ "Status is Required" }}</span
                    >
                  </div>
                </div>
                <hr v-if="index + 1 < bodyUpdate.corrective.length" />
                <!-- <span class="text-sm text-danger"
                                    v-show="errors.has('Date')">{{ errors.first("Date") }}</span> -->
              </div>
              <div class="form-group">
                <button
                  style="margin-left: auto; float: right"
                  class="mt-4 btn btn-primary ml-2"
                  type="submit"
                  v-if="isLoading == false">
                  {{ $t("Save") }}
                </button>
                <button
                  style="margin-left: auto; float: right"
                  class="mt-4 btn btn-primary"
                  type="button"
                  disabled
                  v-else>
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>
                  Loading...
                </button>
                <button
                  style="margin-left: auto; float: right"
                  class="mt-4 btn btn-primary ml-2"
                  @click="tabIndex--">
                  {{ $t("Prev") }}
                </button>
              </div>
            </b-tab>
          </b-tabs>
        </form>
      </b-modal>
      <b-modal
        id="modal-view"
        size="xl"
        centered
        hide-footer
        :title="$t('Measuring IH Detail')">
        <b-tabs fill>
          <b-tab title="Problem, Identification, & Causing" active>
            <div class="row g-3 mt-2">
              <div class="form-group col-4">
                <h3>{{ $t("Factor") }}</h3>
                <b>{{ detailMeasuring.factor_name }}</b>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Measurement Date") }}</h3>
                <p>{{ convertDate(detailMeasuring.measurement_date) }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Company") }}</h3>
                <p>{{ detailMeasuring.company_name }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Location") }}</h3>
                <p>{{ detailMeasuring.location }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Activity") }}</h3>
                <p>{{ detailMeasuring.measuring_activity_name }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Method") }}</h3>
                <p>{{ detailMeasuring.measuring_method_name }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Kit") }}</h3>
                <p>{{ detailMeasuring.kit_name }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Treshold") }}</h3>
                <p>{{ detailMeasuring.nab }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Unit") }}</h3>
                <p>{{ detailMeasuring.unit_name }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Result") }}</h3>
                <p>{{ detailMeasuring.result }}</p>
              </div>
              <div class="form-group col-4">
                <h3>{{ $t("Causing") }}</h3>
                <p>{{ detailMeasuring.causing }}</p>
              </div>
            </div>
          </b-tab>
          <b-tab title="Corrective Actions">
            <div
              class="form-group mt-4"
              v-for="(i, index) in detailMeasuring.corrective_actions"
              :key="`CA-${index}`">
              <h2>{{ i.corrective_action_name }}</h2>
              <div class="row">
                <div class="col-lg-3">
                  <b>Action</b>
                  <p v-if="i.actions">{{ i.actions }}</p>
                  <p v-else>-</p>
                </div>
                <div class="col-lg-3">
                  <b>Due Date</b>
                  <p v-if="i.due_date">{{ convertDate(i.due_date) }}</p>
                  <p v-else>-</p>
                </div>
                <div class="col-lg-3">
                  <b>PIC</b>
                  <p v-if="i.pic_name">{{ i.pic_name }}</p>
                  <p v-else>-</p>
                </div>
                <div class="col-lg-3">
                  <b>Status</b>
                  <p v-if="i.status">{{ i.status }}</p>
                  <p v-else>-</p>
                </div>
              </div>
              <hr
                v-if="index + 1 < detailMeasuring.corrective_actions.length" />
              <!-- <hr v-if="index + 1 < detailMeasuring.corrective_action.length" /> -->
            </div>
          </b-tab>
        </b-tabs>
      </b-modal>
    </div>
  </div>
</template>

<script>
import moment, { now } from "moment";
import axiosIns from "@/api/axios";
import queryString from "query-string";
export default {
  name: "IHMeasurement",
  computed: {
    company() {
      return this.$store.getters["detailManagement/dataCompany"]
        ? this.$store.getters["detailManagement/dataCompany"]
        : [];
    },
    measuringActivity() {
      return this.$store.getters["measuringActivity/MeasuringActivityData"]
        ? this.$store.getters["measuringActivity/MeasuringActivityData"]
        : [];
    },
    measuringMethod() {
      return this.$store.getters["measuringMethod/MeasuringMethodData"]
        ? this.$store.getters["measuringMethod/MeasuringMethodData"]
        : [];
    },
    kit() {
      return this.$store.getters["kit/kitData"]
        ? this.$store.getters["kit/kitData"]
        : [];
    },
    users() {
      return this.$store.getters["roleClinicManagement/dataUser"]
        ? this.$store.getters["roleClinicManagement/dataUser"]
        : [];
    },
    factor() {
      return this.$store.getters["factor/FactorData"]
        ? this.$store.getters["factor/FactorData"]
        : [];
    },
    measuring() {
      return this.$store.getters["action/measuringData"]
        ? this.$store.getters["action/measuringData"]
        : [];
    },
    campaign() {
      return this.$store.getters["campaign/campaignData"]
        ? this.$store.getters["campaign/campaignData"]
        : [];
    },
    rolesId() {
      return localStorage.getItem("roles_id")
        ? Number(localStorage.getItem("roles_id"))
        : 0;
    }
  },
  data() {
    return {
      body: {
        page: 1,
        perPage: 10,
        searchQuery: "",
        sort: "",
        campaign: "",
        company_id: ""
      },
      bodyAdd: {
        measurement_date: "",
        campaign: "",
        location: "",
        activity: "",
        method: "",
        kit: "",
        nab1: "",
        nab2: "",
        nab_condition: "",
        result: "",
        causing: "",
        factor: "",
        corrective: []
      },
      bodyUpdate: {
        id: "",
        measurement_date: "",
        campaign: "",
        location: "",
        activity: "",
        method: "",
        kit: "",
        nab1: "",
        nab2: "",
        nab_condition: "",
        result: "",
        causing: "",
        factor: "",
        corrective: []
      },
      campaignData: {},
      kitSelected: {},
      unitKit: "",
      isAdd: true,
      isLoading: false,
      columns: [
        {
          label: "DATE",
          field: "measurement_date",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "company",
          field: "company_name",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "LOCATION",
          field: "location",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "ACTIVITY",
          field: "measuring_activity_name",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "TRESHOLD",
          field: "nab",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "UNIT",
          field: "unit_name",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "RESULT",
          field: "result",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "STATUS",
          field: "status",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "color-secondary text-center align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass:
            "table-header no-wrap text-center text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle",
          sortable: false
        }
      ],
      corrective: [],
      detailMeasuring: {},
      kitName: "",
      tabIndex: 1
    };
  },
  mounted() {
    this.getAll();
  },
  methods: {
    now() {
      return moment().format("YYYY-MM-DD");
    },
    convertDate(d) {
      return d ? moment(d).format("D MMM YYYY") : d;
    },
    selectYearOption() {
      const year = moment().format("YYYY");
      const temp = [];
      for (let i = year - 3; i <= year; i++) {
        temp.push({
          id: i,
          year_name: i
        });
      }
      return temp;
    },
    selectCompanyOption() {
      const currentCompany = localStorage.getItem("company");
      const company = this.company;
      const term = [];
      if (this.rolesId === 1) {
        term.push({
          id: 0,
          company_name: "BIB + Mitra"
        });

        company.map((i) => {
          term.push({
            id: i.id,
            company_name: i.company_name
          });
        });
      } else {
        company.map((i) => {
          if (i.id === currentCompany) {
            term.push({
              id: i.id,
              company_name: i.company_name
            });
          }
        });
      }
      return term;
    },

    openAdd() {
      this.isAdd = true;
      this.corrective = [];
    },

    views(e) {
      this.detailMeasuring = e;
      this.$bvModal.show("modal-view");
    },

    download() {
      let body = {
        campaign: this.body.campaign,
        company_id: this.body.company_id
      };
      let params = queryString.stringify(body);
      axiosIns
        .get(
          `${axiosIns.defaults.baseURL}/ih/management/measurement/pica-report?${params}`,
          {
            responseType: "blob"
          }
        )
        .then((res) => {
          const href = URL.createObjectURL(res.data);
          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute(
            "download",
            `PICA HASIL PENGUKURAN LINGKUNGAN & KESEHATAN KERJA ${this.campaignData.campaign_name}.xlsx`
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },

    removeItem(index) {
      this.corrective.splice(index, 1);
    },

    UpdateData(e) {
      this.bodyUpdate.id = e.id;
      this.bodyUpdate.activity = e.activity;
      this.bodyUpdate.causing = e.causing;
      this.bodyUpdate.year_period = e.year_period;
      this.bodyUpdate.campaign = e.campaign;
      this.corrective = e.corrective_actions;
      this.bodyUpdate.kit = e.kit;
      this.bodyUpdate.location = e.location;
      this.bodyUpdate.measurement_date = e.measurement_date;
      this.bodyUpdate.method = e.method;
      this.bodyUpdate.nab_condition = e.nab_condition;
      this.bodyUpdate.nab1 = e.nab1;
      this.bodyUpdate.nab2 = e.nab2;
      this.bodyUpdate.result = e.result;
      this.bodyUpdate.factor = e.factor;
      this.unitKit = e.unit_name;
      const index = this.$store.getters["kit/kitData"].rows.findIndex(
        (e) => e.id === this.bodyUpdate.kit
      );
      if (index >= 0) {
        this.kitName = this.$store.getters["kit/kitData"].rows[index].unit_name;
      }
      this.$bvModal.show("modal-1");
      this.isAdd = false;
    },

    selectedKit() {
      this.bodyAdd.kit = this.kitSelected.id;
      this.unitKit = this.kitSelected.unit_name;
    },
    selectedKit2(e) {
      const index = this.$store.getters["kit/kitData"].rows.findIndex(
        (e) => e.id === this.bodyUpdate.kit
      );
      if (index >= 0) {
        this.kitName = this.$store.getters["kit/kitData"].rows[index].unit_name;
      }
    },

    getAll() {
      this.isLoading = true;
      this.getCompanny();
      this.getActivity();
      this.getMethod();
      this.getKit();
      this.getUsers();
      this.getFactor();
      this.getCampaign();
    },

    getMeasuring() {
      // this.isLoading = true
      this.$store.dispatch("action/getMeasuring", this.body).then(() => {
        this.isLoading = false;
      });
    },
    getCompanny() {
      this.$store.dispatch("detailManagement/getCompany");
    },
    getActivity() {
      this.$store.dispatch("measuringActivity/getMeasuringActivity");
    },
    getMethod() {
      this.$store.dispatch("measuringMethod/getMeasuringMethod");
    },
    getUsers() {
      this.$store.dispatch("roleClinicManagement/getUser");
    },
    getCampaign() {
      let data = {
        page: 1,
        perPage: 1000,
        sort: "",
        searchQuery: ""
      };
      this.$store.dispatch("campaign/getCampaign", data).then(() => {
        let d = this.$store.getters["campaign/campaignData"];
        const index = d.rows.findIndex((e) => e.is_active === true);
        if (index >= 0) {
          this.campaignData = d.rows[index];
          this.body.campaign = d.rows[index].id;
          this.bodyAdd.campaign = d.rows[index].id;
          this.getMeasuring();
        }
      });
    },
    getFactor() {
      this.$store.dispatch("factor/getFactor");
    },
    getKit() {
      let data = {
        page: 1,
        perPage: 1000,
        sort: "",
        searchQuery: ""
      };
      this.$store.dispatch("kit/getKit", data);
    },
    getAction(label) {
      let body = {
        page: 1,
        perPage: 1000,
        sort: "",
        searchQuery: ""
      };
      this.$store.dispatch("action/getAction", body).then(() => {
        const data = this.$store.getters["action/ActionData"];
        const index = data.data.findIndex(
          (e) => e.corrective_action_name === label
        );
        if (index >= 0) {
          let rec = {
            corrective_action_name: data.data[index].corrective_action_name,
            corrective_action: data.data[index].id,
            actions: "",
            due_date: "",
            pic: "",
            status: "OPEN"
          };
          this.corrective.push(rec);
        }
      });
    },

    campaignFunc() {
      this.body.campaign = this.campaignData.id;
      this.bodyAdd.campaign = this.campaignData.id;
      this.getMeasuring();
    },

    onSortChange(params) {
      let sort1 = params[0].field;
      let sort2 = params[0].type;
      this.body.sort = `${sort1},${sort2}`;
      this.getMeasuring();
    },
    onPageChange(payload) {
      this.body.perPage = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getMeasuring();
    },

    // Action
    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.bodyAdd.corrective = this.corrective;
          this.$store
            .dispatch("action/addMeasuring", this.bodyAdd)
            .then((resp) => {
              this.$swal({
                title: this.$t("Saved"),
                text: this.$t("Your input has been saved"),
                icon: "success",
                confirmButtonText: this.$t("OK")
              }).then((resp) => {
                this.isLoading = false;
                this.getMeasuring();
                this.$bvModal.hide("modal-1");
              });
            });
        } catch (resp) {}
      });
    },

    saveUpdate() {
      this.bodyUpdate.corrective = this.corrective;
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.isLoading = true;
          this.$store
            .dispatch("action/updateMeasurement", this.bodyUpdate)
            .then((resp) => {
              this.$swal({
                title: this.$t("Saved"),
                text: this.$t("Your input has been saved"),
                icon: "success",
                confirmButtonText: this.$t("OK")
              }).then((resp) => {
                this.isLoading = false;
                this.getMeasuring();
                this.$bvModal.hide("modal-1");
              });
            });
        } catch (resp) {}
      });
    },

    deleteData(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("action/delMeasuring", id)
            .then((resp) => {
              this.getMeasuring();
              this.$swal(
                "Deleted!",
                this.$t("Record has been deleted"),
                "success"
              );
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    }
  }
};
</script>
