import queryString from "query-string";
import { GET_BIN_DATA, GET_DISPOSABLE, SUBMIT_MEDICAL_WASTE } from "@/api/clinic/medical_waste";

const state = {
    dataBin: [],
    dataDisposable: [],
};

const mutations = {
    setBin: (states, payload) => {
        states.dataBin = payload;
    },
    setDisposable: (states, payload) => {
        states.dataDisposable = payload;
    },
}

const actions = {
    getBinData: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_BIN_DATA(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setBin", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setBin", {
                        setBin: [],
                    });
                }
            });
    },

    submitMedical: async ({ }, args) => {
        return await SUBMIT_MEDICAL_WASTE(args);
    },


    getDisposableData: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_DISPOSABLE(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setDisposable", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setDisposable", {
                        setDisposable: [],
                    });
                }
            });
    },
}

const getters = {
    binData: (states) => states.dataBin,
    disposalData: (states) => states.dataDisposable,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};