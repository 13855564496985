<template>
  <!-- HEADER -->
  <div class="header">
    <!-- Body -->
    <div :class="`cart ${load === true ? '' : 'd-none'}`">
      <div class="card-body text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div :class="`${load === true ? 'd-none' : ''}`">
      <div class="card-header d-none">
        <!-- Title -->
        <h4 class="card-header-title"></h4>
        <!-- Button -->
        <b-button
          v-b-toggle.collapse-1
          variant="primary"
          style="margin-left: 7px">
          <span class="fe fe-sliders"></span>
        </b-button>
      </div>
      <b-collapse id="collapse-1">
        <form @submit.prevent="sorting()">
          <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
            <div class="col">
              <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">{{
                  $t("Name")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="body.activity_name" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">Order</label>
                <v-select v-model="body.order" :options="['ASC', 'DESC']" />
              </div>
            </div>
          </div>

          <div class="row mb-3" style="margin-right: 15px">
            <div class="col"></div>
            <div class="col-auto">
              <!-- Button -->
              <button type="submit" class="btn btn-danger lift">
                {{ $t("Filter") }}
              </button>
            </div>
          </div>
          <!-- / .row -->
        </form>
      </b-collapse>
      <div style="overflow: auto">
        <table class="table table-sm table-nowrap table-bordered oh-table tableFixHead">
          <thead>
            <tr>
              <td scope="col" rowspan="2" >
                {{ $t("Occupational Health Indicators") }}
              </td>
              <th colspan="12" style="text-align: center">{{ $t("Month") }}</th>
              <th scope="col" rowspan="2" class="text-center">
                {{ $t("Total") }}
              </th>
            </tr>
            <tr>
              <th v-for="(i, index) in mothly" :key="index" scope="col">
                {{ i }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(label, index) in ohDaily" :key="index">
              <th scope="row" style="text-align: left">
                {{ label.oh_label }}
                <span
                  v-if="label.description != null"
                  :title="label.description"
                  class="fe fe-alert-circle"></span>
              </th>
              <td
                class="text-center"
                v-for="(i, index) in lastmonth"
                :key="index">
                {{
                  acumalaka(i)
                    ? convertNumber(revealValue(i, label.values)) === ""
                      ? dataNol(index)
                      : convertNumber(revealValue(i, label.values))
                    : ""
                }}
              </td>
              <!-- <th class="text-center" v-if="bodyDataDaily.oh_type === 'Activity'">
                {{ convertNumber(findTotalActivity(label.oh_id, label.oh_type)) === "" ? 0 : convertNumber(findTotalActivity(label.oh_id, label.oh_type)) }}
              </th> -->
              <td class="text-center" style="color: black">
                {{ convertNumber(label.total) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "Activity",
  computed: {
    activity() {
      return this.$store.getters["activity/ActivityData"]
        ? this.$store.getters["activity/ActivityData"]
        : [];
    },
    ohDaily() {
      return this.$store.getters["detailManagement/OHDailyData"]
        ? this.$store.getters["detailManagement/OHDailyData"]
        : [];
    },
    bodyData() {
      return this.$store.getters["leadingLagging/body_data"]
        ? this.$store.getters["leadingLagging/body_data"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        limit: 100,
        order: ""
      },
      bodyDataDaily: {
        oh_type: "Activity",
        type_input: "Monthly",
        company_id: "",
        date_input: moment().format("YYYY")
      },
      date_input: "",
      record: [],
      recordAct: [],
      mothly: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MEI",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC"
      ],
      thisMonth: moment().format("M"),
      thisYear: moment().format("YYYY"),
      lastmonth: 12,
      totalData: [],
      load: true
    };
  },
  mounted() {
    this.$root.$on("activity", (body) => {
      this.bodyDataDaily.company_id = body.company_id;
      this.bodyDataDaily.date_input = body.date_input;
      this.customGetOH();
    });
    const lastmonth = moment().endOf("year").format("M");
    this.lastmonth = Number(lastmonth);
    // // this.getOh tivity();
    if (this.bodyData.length == undefined) {
      this.bodyDataDaily.company_id = this.bodyData.company_id;
      this.bodyDataDaily.date_input = this.bodyData.date_input;
    }
    this.customGetOH();
  },
  methods: {
     acumalaka(i){
      if(this.bodyDataDaily.date_input != this.thisYear){
        return true
      }else{
        if(i <= this.thisMonth){
          return true
        }else{
          return false
        }
      }
    },
    revealValue(monthly, values) {
      const index = values.findIndex((e) => e.month === monthly);
      if (index > -1) {
        return values[index]["value"];
      } else {
        return "";
      }
    },
    dataNol(index) {
      const dayNow = moment().format("MM");
      if (index < dayNow) {
        return 0;
      }
    },
    // api hit
    // getOh() {
    //   this.$store.dispatch("ohData/getOh", this.body).then(() => {
    //     const datas = this.$store.getters["ohData/OhData"];
    //     const list = datas.data;
    //     let rec = list.map((item) => {
    //       return {
    //         oh_type: "Data",
    //         oh_id: Number(item.id),
    //         oh_label: item.data_name,
    //         value: item.type_data == "Integer" && !item.is_mandatory ? 0 : "",
    //       };
    //     });
    //     this.record = rec;

    //     console.log(rec);
    //   });
    // },

    customGetOH() {
      this.load = true;
      this.$store
        .dispatch("detailManagement/getDataDaily", this.bodyDataDaily)
        .then(() => {
          this.load = false;
        });
    },

    getActivity() {
      this.$store.dispatch("activity/getActivity", this.body).then(() => {
        const datas = this.$store.getters["activity/ActivityData"];
        const list = datas.data;
        let rec = list.map((item) => {
          return {
            oh_type: "Activity",
            oh_id: Number(item.id),
            oh_label: item.activity_name,
            value: item.type_data == "Integer" && !item.is_mandatory ? 0 : ""
          };
        });
        this.recordAct = rec;
      });

      // this.customGetActivity();
    },

    // findTotalActivity(id, type) {
    // 		const data = this.ohDaily;
    // 		let obj = data.find((o) => {
    // 			return o.oh_type == type && o.oh_id == id;
    // 		});
    // 		let datas = obj.values.map((item) => {
    // 			return item.value
    // 		})

    // 		var dat = datas.reduce((total, value) => total + value, 0)

    // 		return dat;
    // 	},

    // filter
    filterPage() {
      this.getActivity();
    },

    sorting() {
      this.getActivity();
    },
    convertNumber(num) {
      if (num) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    }
  }
};
</script>
