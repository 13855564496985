import queryString from 'query-string'
import { GET_DATA } from "@/api/oh_management/recap_daily";


const state = {
    recap: [],
};

const mutations = {
    setRecap: (states, payload) => {
        states.recap = payload;
    }
};

const actions = {
    getRecap: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        try {
            const resp = await GET_DATA(params)
            const datas = resp.data.data
            commit("setRecap", datas);
        } catch (err) {
            let resp = err.response;
            commit("setRecap", resp);
            console.log("this err", err);
        }
    },
};

const getters = {
    recapData: (states) => states.recap,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};