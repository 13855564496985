import axiosIns from '@/api/axios'

export const GET_UNIT_PRODUCT = (args) => {
    return axiosIns.get(`/inventory/po/cart/item?id=${args}`)
}
export const ADD_UNIT_PRODUCT = (args) => {
    return axiosIns.post(`/inventory/po/cart`, args)
}
export const GET_INPUT_PRODUCT = (args) => {
    return axiosIns.get(`/inventory/po/cart?${args}`)
}
export const DEL_INPUT_PRODUCT = (args) => {
    return axiosIns.delete(`/inventory/po/cart?id=${args}`)
}

export const UPDATE_INNER_PO = (args) => {
    return axiosIns.put(`/inventory/po/cart`, args)
}


export const SUBMIT_PO = (args) => {
    return axiosIns.post(`/inventory/po`, args)
}
export const GET_LIST_PO = (args) => {
    return axiosIns.get(`/inventory/po?${args}`)
}
export const DELETE_LIST_PO = (args) => {
    return axiosIns.delete(`/inventory/po?${args}`)
}
export const GET_DETAIL_PO = (args) => {
    return axiosIns.get(`/inventory/po/detail?${args}`)
}
export const GET_EDIT_PO = (args) => {
    return axiosIns.get(`/inventory/po/edit?${args}`)
}

export const GET_DO_LIST = (args) => {
    return axiosIns.get(`/inventory/do/lite?id=${args}`)
}

export const SUBMIT_GR = (args) => {
    return axiosIns.post(`/inventory/do/gr`, args)
}


export const GET_DETAIL_DO = (args) => {
    return axiosIns.get(`/inventory/po/detail?id=${args}`)
}