<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <!-- / .row -->

      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("Indicator") }}</h4>
          <!-- Button -->
          <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
            <span class="fe fe-file-plus"></span>
            {{ $t("Add") }}
          </b-button>
          <b-button
            v-b-toggle.collapse-1
            variant="primary"
            style="margin-left: 7px">
            <span class="fe fe-sliders"></span>
          </b-button>
        </div>
        <b-collapse id="collapse-1">
          <form @submit.prevent="sorting()">
            <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Indicator Name")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="body.indicator_name" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label"
                    >Order</label
                  >
                  <v-select v-model="body.order" :options="['ASC', 'DESC']" />
                  <!-- <v-select v-model="body.order" :options="['ASC', 'DESC']" @input="sorting" /> -->
                </div>
              </div>
            </div>

            <div class="row mb-3" style="margin-right: 15px">
              <div class="col"></div>
              <div class="col-auto">
                <!-- Button -->
                <button type="submit" class="btn btn-danger lift">
                  {{ $t("Filter") }}
                </button>
              </div>
            </div>
            <!-- / .row -->
          </form>
        </b-collapse>
        <vue-good-table
          :line-numbers="true"
          :columns="columns"
          :rows="indicator.data"
          :sort-options="{
            enabled: true
          }"
          :total-rows="indicator.totalItems"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }"
          style-class="vgt-table border-0 table"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange">
          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <!-- Custom Rows -->
          <template slot="table-row" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.field == 'action'">
              <b-button
                class="no-wrap btn-pill"
                variant="warning"
                size="sm"
                @click="updateData(props.row)">
                <span class="fe fe-edit"></span>
              </b-button>
              <b-button
                class="no-wrap ml-2"
                variant="danger"
                style="margin-left: 5px"
                size="sm"
                @click="deleteData(props.row.id)">
                <span class="fe fe-trash-2"></span>
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </div>

      <!-- Modal -->
      <b-modal id="modal-1" size="lg" hide-footer title="Indicator Form">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="row g-3">
            <div class="form-group col">
              <label>{{ $t("Indicator Name") }}</label>
              <input
                type="text"
                v-model="bodyAdd.indicator_name"
                class="form-control"
                placeholder="Indicator Name"
                v-validate="'required'"
                name="Indicator Name" />
              <span
                class="text-sm text-danger"
                v-show="errors.has('Indicator Name')"
                >{{ errors.first("Indicator Name") }}</span
              >
            </div>
            <div class="form-group col">
              <label>{{ $t("Treshold") }}</label>
              <input
                type="text"
                v-model="bodyAdd.indicator_treshold"
                class="form-control"
                placeholder="Treshold"
                v-validate="'required'"
                name="Indicator Treshold" />
              <span
                class="text-sm text-danger"
                v-show="errors.has('Indicator Treshold')"
                >{{ errors.first("Indicator Treshold") }}</span
              >
            </div>
            <div class="form-group col">
              <label>Order</label>
              <input
                type="number"
                v-model="bodyAdd.oh_order"
                class="form-control"
                placeholder="Order"
                v-validate="'required'"
                name="Order" />
              <span class="text-sm text-danger" v-show="errors.has('Order')">{{
                errors.first("Order")
              }}</span>
            </div>
            <div class="form-group col">
              <label>{{ $t("Percentage") }}</label>
              <v-select
                v-model="bodyAdd.is_percentage"
                :options="percentage"
                :label="label"
                :reduce="(e) => e.code"
                v-validate="'required'"
                name="Percentage"></v-select>
              <span
                class="text-sm text-danger"
                v-show="errors.has('Percentage')"
                >{{ errors.first("Percentage") }}</span
              >
            </div>
          </div>
          <div class="row g-3">
            <div class="col">
              <label for="">{{ $t("Formula / Rumus") }}</label>
              <button
                type="button"
                style="margin-left: auto; float: right"
                class="btn btn-primary"
                @click="addVariable">
                <span class="fe fe-plus"></span> {{ $t("Add Variables") }}
              </button>
            </div>
          </div>
          <div class="row g-3" v-for="(item, index) in variables" :key="index">
            <div class="form-group col-6">
              <label>{{ $t("Data Type") }}</label>
              <v-select
                v-model="item.type"
                :reduce="(option) => option.key"
                v-if="index % 2 == 0"
                :options="[
                  { label: 'OH DATA', key: 'data' },
                  { label: 'OH ACTIVITY', key: 'activity' },
                  { label: 'NUMBER', key: 'const' }
                ]"
                :name="`variables[${index}][type]`"
                v-validate="'required'" />
              <v-select
                v-model="item.type"
                :reduce="(option) => option.key"
                v-else
                :options="[{ label: 'OPERATOR', key: 'opr' }]"
                :name="`variables[${index}][type]`"
                v-validate="'required'" />
              <span class="text-sm text-danger" v-show="errors.has('Type')">{{
                errors.first("Type")
              }}</span>
            </div>
            <div class="form-group col-5">
              <label>{{ $t("Value") }}</label>
              <input
                type="text"
                class="form-control"
                placeholder="Value"
                v-model="item.value"
                v-validate="'required'"
                name="Value"
                v-if="item.type === 'const'" />
              <v-select
                v-if="item.type === 'data'"
                v-model="item.value"
                :options="ohData.data"
                label="data_name"
                :reduce="(e) => e.id"
                v-validate="'required'"
                name="Data Name" />
              <v-select
                v-if="item.type === 'activity'"
                v-model="item.value"
                :options="activity.data"
                label="activity_name"
                :reduce="(e) => e.id"
                v-validate="'required'"
                name="Activity Name" />
              <v-select
                v-model="item.value"
                :reduce="(option) => option.id"
                :options="[
                  { label: '+ (Tambah)', id: '+' },
                  { label: '- (Kurang)', id: '-' },
                  { label: '* (Kali)', id: '*' },
                  { label: '/ (Bagi)', id: '/' }
                ]"
                v-if="item.type === 'opr'"
                v-validate="'required'"
                :name="`variables[${index}][value]`" />
              <span class="text-sm text-danger" v-show="errors.has('Value')">{{
                errors.first("Value")
              }}</span>
            </div>
            <!-- <div class="form-group col-2" v-if="item.index"> -->
            <div class="form-group col-1" v-if="index >= 0">
              <button
                type="button"
                style="margin-left: auto"
                class="btn btn-primary mt-4"
                @click="removeDrugs(index)">
                <span class="fe fe-trash"></span>
              </button>
            </div>
          </div>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="submit"
            v-if="isLoading == false">
            {{ $t("Save") }}
          </button>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="button"
            disabled
            v-else>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
            Loading...
          </button>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="row g-3">
            <div class="form-group col">
              <label>{{ $t("Indicator Name") }}</label>
              <input
                type="text"
                v-model="bodyUpdate.indicator_name"
                class="form-control"
                placeholder="Indicator Name"
                v-validate="'required'"
                name="Indicator Name" />
              <span
                class="text-sm text-danger"
                v-show="errors.has('Indicator Name')"
                >{{ errors.first("Indicator Name") }}</span
              >
            </div>
            <div class="form-group col">
              <label>{{ $t("Treshold") }}</label>
              <input
                type="text"
                v-model="bodyUpdate.indicator_treshold"
                class="form-control"
                placeholder="Treshold"
                v-validate="'required'"
                name="Indicator Treshold" />
              <span
                class="text-sm text-danger"
                v-show="errors.has('Indicator Treshold')"
                >{{ errors.first("Indicator Treshold") }}</span
              >
            </div>
            <div class="form-group col">
              <label>Order</label>
              <input
                type="number"
                v-model="bodyUpdate.oh_order"
                class="form-control"
                placeholder="Order"
                v-validate="'required'"
                name="Order" />
              <span class="text-sm text-danger" v-show="errors.has('Order')">{{
                errors.first("Order")
              }}</span>
            </div>
            <div class="form-group col">
              <label>{{ $t("Percentage") }}</label>
              <v-select
                v-model="bodyUpdate.is_percentage"
                :options="percentage"
                :label="label"
                :reduce="(e) => e.code"
                v-validate="'required'"
                name="Percentage"></v-select>
              <span
                class="text-sm text-danger"
                v-show="errors.has('Percentage')"
                >{{ errors.first("Percentage") }}</span
              >
            </div>
          </div>
          <div class="row g-3">
            <div class="col">
              <label for="">{{ $t("Formula / Rumus") }}</label>
              <button
                type="button"
                style="margin-left: auto; float: right"
                class="btn btn-primary"
                @click="addVariable">
                <span class="fe fe-plus"></span> {{ $t("Add Variables") }}
              </button>
            </div>
          </div>
          <div class="row g-3" v-for="(item, index) in variables" :key="index">
            <div class="form-group col-6">
              <label>{{ $t("Data Type") }}</label>
              <v-select
                v-model="item.type"
                :reduce="(option) => option.key"
                v-if="index == 0"
                :options="[
                  { label: 'OH DATA', key: 'data' },
                  { label: 'OH ACTIVITY', key: 'activity' },
                  { label: 'NUMBER', key: 'const' }
                ]"
                :name="`variables[${index}][type]`"
                v-validate="'required'" />
              <v-select
                v-model="item.type"
                v-else
                :reduce="(option) => option.key"
                :options="[
                  { label: 'OH DATA', key: 'data' },
                  { label: 'OH ACTIVITY', key: 'activity' },
                  { label: 'NUMBER', key: 'const' },
                  { label: 'OPERATOR', key: 'opr' }
                ]"
                :name="`variables[${index}][type]`"
                v-validate="'required'" />
              <span class="text-sm text-danger" v-show="errors.has('Type')">{{
                errors.first("Type")
              }}</span>
            </div>
            <div class="form-group col-5">
              <label>{{ $t("Value") }}</label>
              <input
                type="number"
                class="form-control"
                placeholder="Value"
                v-model="item.value"
                v-validate="'required'"
                name="Value"
                v-if="item.type === 'const'" />
              <v-select
                v-if="item.type === 'data'"
                v-model="item.value"
                :options="ohData.data"
                label="data_name"
                :reduce="(e) => e.id"
                v-validate="'required'"
                name="Data Name" />
              <v-select
                v-if="item.type === 'activity'"
                v-model="item.value"
                :options="activity.data"
                label="activity_name"
                :reduce="(e) => e.id"
                v-validate="'required'"
                name="Activity Name" />
              <v-select
                v-model="item.value"
                :reduce="(option) => option.id"
                :options="[
                  { label: '+ (Tambah)', id: '+' },
                  { label: '- (Kurang)', id: '-' },
                  { label: '* (Kali)', id: '*' },
                  { label: '/ (Bagi)', id: '/' }
                ]"
                v-if="item.type === 'opr'"
                v-validate="'required'"
                :name="`variables[${index}][value]`" />
              <span class="text-sm text-danger" v-show="errors.has('Value')">{{
                errors.first("Value")
              }}</span>
            </div>
            <!-- <div class="form-group col-2" v-if="item.index"> -->
            <div class="form-group col-1" v-if="index >= 0">
              <button
                type="button"
                style="margin-left: auto"
                class="btn btn-primary mt-4"
                @click="removeDrugs(index)">
                <span class="fe fe-trash"></span>
              </button>
            </div>
          </div>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="submit"
            v-if="isLoading == false">
            {{ $t("Save") }}
          </button>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="button"
            disabled
            v-else>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
            Loading...
          </button>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "defaultCMS",
  computed: {
    indicator() {
      return this.$store.getters["indicator/IndicatorData"]
        ? this.$store.getters["indicator/IndicatorData"]
        : [];
    },
    ohData() {
      return this.$store.getters["ohData/OhData"]
        ? this.$store.getters["ohData/OhData"]
        : [];
    },
    activity() {
      return this.$store.getters["activity/ActivityData"]
        ? this.$store.getters["activity/ActivityData"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        limit: 10,
        order: "",
        indicator_name: ""
      },
      bodyAdd: {
        indicator_name: "",
        is_percentage: "",
        oh_order: "",
        indicator_treshold: "",
        indicator_formula: ""
      },
      bodyUpdate: {
        id: "",
        indicator_name: "",
        percentage: "",
        indicator_treshold: "",
        indicator_formula: "",
        is_percentage: "",
        oh_order: ""
      },
      variables: [
        {
          type: "",
          value: ""
        }
      ],
      percentage: [
        {
          label: "YES",
          code: true
        },
        {
          label: "NO",
          code: false
        }
      ],
      dataType: [],
      type: "",
      data_value: ["OH DATA", "OH ACTIVITY", "NUMBER", "OPERATOR"],
      isAdd: true,
      isLoading: false,
      columns: [
        {
          label: "INDICATOR",
          field: "indicator_name",
          thClass: "table-header text-muted w-AUTO font-weight-bold",
          tdClass: "color-secondary align-middle"
        },

        {
          label: "TRESHOLD",
          field: "indicator_treshold",
          thClass: "table-header text-muted w-AUTO font-weight-bold",
          tdClass: "color-secondary align-middle"
        },

        {
          label: "FORMULA",
          field: "indicator_formula_readable",
          thClass: "table-header text-muted w-AUTO font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ]
    };
  },
  mounted() {
    // if (this.indicator && this.indicator.length < 1) {
    this.getIndicator();
    // }
    // if (this.activity && this.activity.length < 1) {
    this.getActivity();
    // }
    // if (this.ohData && this.ohData.length < 1) {
    this.getOh();
    // }

    // if (this.variables.length == 1) {
    //     this.dataType = [{
    //             label: 'OH DATA',
    //             id: 'data'
    //         },
    //         {
    //             label: 'OH ACTIVITY',
    //             id: 'activity'
    //         },
    //         {
    //             label: 'NUMBER',
    //             id: 'const'
    //         },
    //     ]
    // } else {
    //     this.dataType = [{
    //             label: 'OH DATA',
    //             id: 'data'
    //         },
    //         {
    //             label: 'OH ACTIVITY',
    //             id: 'activity'
    //         },
    //         {
    //             label: 'NUMBER',
    //             id: 'const'
    //         },
    //         {
    //             label: 'OPERATOR',
    //             id: 'opr'
    //         }]
    // }
  },
  methods: {
    addVariable() {
      const blank_drugs = {
        type: "",
        value: ""
      };
      this.variables.push(blank_drugs);
    },
    removeDrugs(index) {
      this.variables.splice(index, 1);
    },
    //action
    updateData(props) {
      this.$bvModal.show("modal-1");
      this.isAdd = false;
      this.bodyUpdate.id = props.id;
      this.bodyUpdate.indicator_name = props.indicator_name;
      this.bodyUpdate.indicator_treshold = props.indicator_treshold;
      this.bodyUpdate.is_percentage = props.is_percentage;
      this.bodyUpdate.oh_order = props.oh_order;
      this.variables = JSON.parse(props.indicator_formula);
    },

    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        const data = {
          indicator_name: this.bodyAdd.indicator_name,
          indicator_treshold: this.bodyAdd.indicator_treshold,
          is_percentage: this.bodyAdd.is_percentage,
          oh_order: this.bodyAdd.oh_order,
          indicator_formula: this.variables
        };
        try {
          this.isLoading = true;
          this.$store.dispatch("indicator/addIndicator", data).then((resp) => {
            this.$swal("Saved", "Your input has been saved", "success").then(
              (resp) => {
                (this.isLoading = false((this.bodyAdd.indicator_name = ""))),
                  (this.bodyAdd.indicator_treshold = ""),
                  (this.variables = []);
                this.bodyAdd.is_percentage = "";
                // (this.variables.value = "");
                // (this)
                this.getIndicator();
                this.$bvModal.hide("modal-1");
              }
            );
          });
        } catch (resp) {
          this.$swal("Error", "Error while add data", "error");
        }
      });
    },
    customStore() {
      this.$store.dispatch("indicator/getIndicator", this.indicator);
    },
    customStoreOHData() {
      this.$store.dispatch("ohData/getOh", this.ohData);
    },
    customStoreOhActivity() {
      this.$store.dispatch("activity/getActivity", this.activity);
    },
    saveUpdate() {
      let body = {
        id: this.bodyUpdate.id,
        indicator_name: this.bodyUpdate.indicator_name,
        indicator_treshold: this.bodyUpdate.indicator_treshold,
        is_percentage: this.bodyUpdate.is_percentage,
        oh_order: this.bodyUpdate.oh_order,
        indicator_formula: this.variables
      };
      this.isLoading = true;
      this.$store
        .dispatch("indicator/updateIndicator", body)
        .then((resp) => {
          this.$swal("Saved", "Your input has been saved", "success").then(
            (resp) => {
              this.isLoading = false;
              this.getIndicator();
              this.$bvModal.hide("modal-1");
            }
          );
        })
        .catch((resp) => {
          this.$swal("Error", "Error while add data", "error");
        });
    },

    deleteData(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("indicator/delIndicator", id)
            .then((resp) => {
              this.getIndicator();
              this.$swal("Deleted!", "Your file has been deleted.", "success");
            })
            .catch((resp) => {
              this.$swal("Error", "Error while delete data", "error");
            });
        }
      });
    },

    // api hit
    getIndicator() {
      this.$store.dispatch("indicator/getIndicator", this.body);
      // this.customStore();
    },
    async getOh() {
      let body = {
        page: 1
      };
      this.$store.dispatch("ohData/getOh", body);
      // this.customStoreOHData();
    },
    async getActivity() {
      let body = {
        page: 1
      };
      this.$store.dispatch("activity/getActivity", body);
      // this.customStoreOhActivity();
    },

    // filter
    filterPage() {
      this.getIndicator();
    },

    sorting() {
      this.getIndicator();
    },

    test(index, opr) {
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;
      // if (
      //   parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
      //   1000
      // ) {
      this.getIndicator();
      // }
    }
  }
};
</script>
