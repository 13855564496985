<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-4">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item ">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item fw-bolder">{{ $t("Registration") }}</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <!-- / .row -->

      <!-- Table -->
      <div v-if="direct === 'Register'">
        <Register />
      </div>
      <!-- <div v-if="direct ==='Record'">
        <Record />
      </div> -->
    </div>
  </div>
</template>
<script>
import Register from "../../../components/clinic_management/patient/register.vue";
import Record from "../../../components/clinic_management/patient/record.vue";
export default {
  name: "patient",
  components: {
    Register,
    Record,
  },
  data() {
    return {
      direct: "Register",
    };
  },
  mounted() {},
};
</script>
