<template>
  <div class="row align-items-center justify-content-between">
    <div class="card p-0">
      <div class="card-header">
        <!-- Title -->
        <h4 class="card-header-title">Medical</h4>
        <!-- Button -->
        <div style="margin-left: 15px">
          <b-button v-b-toggle.collapse-1 variant="primary" class="ml-2">
            <span class="fe fe-sliders"></span>
          </b-button>
        </div>
        <div style="margin-right: 15px"></div>
      </div>
      <vue-good-table
        :line-numbers="true"
        :columns="columns"
        :rows="dataMedical.data"
        :sort-options="{
          enabled: true,
        }"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          position: 'bottom',
          perPage: 10,
          perPageDropdown: [10, 50, 100],
          dropdownAllowAll: false,
          setCurrentPage: 1,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page',
          allLabel: 'All',
        }"
        style-class="vgt-table border-0 table"
      >
        <!-- Loading -->
        <div slot="emptystate" class="text-center font-size-14">
          No data available
        </div>
        <!-- Custom Rows -->
        <template slot="table-row" slot-scope="props">
          <!-- Table Action -->
          <span v-if="props.column.field == 'action'">
            <div>
              <b-button
                class="no-wrap btn-pill"
                variant="info"
                size="sm"
                @click.prevent="viewRecord(props.row)"
              >
                <span class="fe fe-eye"></span>
              </b-button>
            </div>
          </span>

          <!-- <span v-else-if="props.column.field == 'created_at'">
                        {{ fixTime(props.row.created_at) }}
                    </span> -->
        </template>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "patient",
  computed: {
    dataMedical() {
      return this.$store.getters["patient/medicalRecord"]
        ? this.$store.getters["patient/medicalRecord"]
        : [];
    },
  },
  data() {
    return {
      rows: [
        {
          id: 1,
          patient_name: "Pramudya Bagaskara",
          company: "CODE ID",
          department: "Front-End",
          position: "Developer",
        },
        {
          id: 2,
          patient_name: "Asmaul Sufarhansyah",
          company: "PT Equipment & Reability",
          department: "Back-End",
          position: "Developer",
        },
      ],
      columns: [
        {
          label: "NAMA KARYAWAN",
          field: "patient_name",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "w-auto color-secondary align-middle",
        },
        {
          label: "PERUSAHAAN",
          field: "company",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "w-auto color-secondary align-middle",
        },
        {
          label: "POSITION",
          field: "position",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "w-auto color-secondary align-middle",
        },
        {
          label: "DEPARTMENT",
          field: "department",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "w-auto color-secondary align-middle",
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
      ],
    };
  },
  mounted() {
    this.getUnit();
  },
  methods: {
    viewRecord(data) {
      localStorage.setItem("dataRecord", JSON.stringify(data));
      this.$router.push("/clinic-mcu/medical-record");
      // this.$store.commit("patient/setRecordPatient", data);
    },

    getUnit() {
      let body = {
        limit: 100,
        page: 1,
      };
      this.$store.dispatch("patient/getRecord", body);
    },
  },
};
</script>
