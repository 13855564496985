import axiosIns from '@/api/axios'

export const GET_TRAINING_SUBGROUP_DATA = (args) => {
    return axiosIns.get(`/medical/staff/training/subgroup?${args}`)
}

export const ADD_TRAINING_SUBGROUP = (args) => {
    return axiosIns.post(`/medical/staff/training/subgroup/create`, args)
}

export const DEL_TRAINING_SUBGROUP = (id) => {
    return axiosIns.put(`/medical/staff/training/subgroup/delete?id=${id}`)
}

export const UPDATE_TRAINING_SUBGROUP  = (args) => {
    return axiosIns.put(`/medical/staff/training/subgroup/update?id=${args.id}`, args)
}