var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item fw-bolder"},[_vm._v(" "+_vm._s(_vm.$t("Clinic Management"))+" ")]),_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Inventory")]),_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Summary Stock")]),_c('li',{staticClass:"breadcrumb-item"},[_vm._v("History")])])])])])]),_c('div',{staticClass:"row align-items-center justify-content-between"},[_c('div',{staticClass:"cart"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h5',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.details.item_name))])]),_c('vue-good-table',{attrs:{"line-numbers":true,"columns":_vm.columns,"rows":_vm.details.row,"total-rows":_vm.details.totalRecords,"sort-options":{
              enabled: true
            },"pagination-options":{
              enabled: true,
              mode: 'pages',
              position: 'bottom',
              perPage: 10,
              perPageDropdown: [10, 50, 100],
              dropdownAllowAll: false,
              setCurrentPage: 1,
              nextLabel: 'next',
              prevLabel: 'prev',
              rowsPerPageLabel: 'Rows per page',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All'
            },"style-class":"vgt-table border-0 table"},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('b-button',{staticClass:"no-wrap btn-pill ml-2",attrs:{"variant":"info","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.seeStock()}}},[_c('span',{staticClass:"fe fe-file-text"})])],1):_vm._e()]}}])},[_c('div',{staticClass:"text-center font-size-14",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" No data available ")])])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }