import axiosIns from '@/api/axios'

export const GET_NAME_DATA = (args) => {
    return axiosIns.get(`chemical/material/name?${args}`)
}

export const ADD_NAME = (args) => {
    return axiosIns.post(`chemical/material/name/create`, args)
}

export const DEL_NAME = (id) => {
    return axiosIns.put(`chemical/material/name/delete?id=${id}`)
}

export const UPDATE_NAME = (args) => {
    return axiosIns.put(`chemical/material/name/update?id=${args.chemical_material_name_id}`, args)
}