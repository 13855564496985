import queryString from "query-string";

import {
    GET_DRUG_DATA,
    ADD_DRUG,
    DEL_DRUG,
    UPDATE_DRUG,
    GET_DRUG_CLINIC,
    EDIT_DRUG_CLINIC
} from "@/api/inventory/drug";

const state = {
    dataDrug: [],
    dataDrugClinic: [],
};

const mutations = {
    setDrug: (states, payload) => {
        states.dataDrug = payload;
    },
    setDrugClinic: (states, payload) => {
        states.dataDrugClinic = payload;
    },
};

const actions = {
    getDrug: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_DRUG_DATA(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setDrug", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setDrug", {
                        setDrug: [],
                    });
                }
            });
    },

    addDrug: async ({ }, args) => {
        return await ADD_DRUG(args);
    },

    delDrug: async ({ }, id) => {
        return await DEL_DRUG(id);
    },

    updateDrug: async ({ }, args) => {
        return await UPDATE_DRUG(args);
    },

    getDrugClinic: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_DRUG_CLINIC(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    if (resp.data.data === null) {
                        commit("setDrugClinic", {
                            setDrugClinic: [],
                        });
                    } else {
                        commit("setDrugClinic", resp.data.data);
                    }
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setDrugClinic", {
                        setDrugClinic: [],
                    });
                }
            });
    },
    editDrugClinic: async ({ }, args) => {
        return await EDIT_DRUG_CLINIC(args);
    },



};

const getters = {
    DrugData: (states) => states.dataDrug,
    DrugDataClinic: (states) => states.dataDrugClinic
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};