var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center justify-content-between"},[_c('div',{staticClass:"cart"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-end mb-3"},[_c('label',{staticClass:"align-self-center mb-0 mr-3"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"w-auto",attrs:{"placeholder":"Type Here..."},on:{"change":function($event){return _vm.getDrugsList()}},model:{value:(_vm.body.searchQuery),callback:function ($$v) {_vm.$set(_vm.body, "searchQuery", $$v)},expression:"body.searchQuery"}})],1),_c('vue-good-table',{attrs:{"mode":"remote","line-numbers":true,"columns":_vm.columns,"rows":_vm.dataDrugs.rows,"total-rows":_vm.dataDrugs.totalRecords,"sort-options":{
            enabled: true,
            initialSortBy: { field: 'item_label', type: 'asc' }
          },"pagination-options":{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: _vm.body.perPage,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          },"style-class":"vgt-table table table-bordered","isLoading":_vm.isLoading},on:{"on-sort-change":_vm.onSortChange,"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('b-button',{staticClass:"no-wrap btn-pill ml-2",attrs:{"variant":"success","size":"sm","title":"Detail Drug"},on:{"click":function($event){$event.preventDefault();return _vm.seeDetail(props.row.item_id)}}},[_c('span',{staticClass:"fe fe-info"})]),_c('b-button',{staticClass:"no-wrap ml-2",staticStyle:{"margin-left":"5px"},attrs:{"variant":"info","size":"sm","title":"Detail Stock"},on:{"click":function($event){return _vm.seeStock(props.row.item_id)}}},[_c('span',{staticClass:"fe fe-file-text"})]),_c('b-button',{staticClass:"no-wrap btn-pill ml-2",attrs:{"variant":"warning","size":"sm","title":"Mutation"},on:{"click":function($event){$event.preventDefault();return _vm.seeMutation(props.row.item_id)}}},[_c('span',{staticClass:"fe fe-git-branch"})])],1):(props.column.field === 'exp_date')?_c('div',[_c('span',{class:`badge ${_vm.tdFunc(props.row)}`,staticStyle:{"font-size":"100% !important","padding":"0.33em 2em"}},[_vm._v(_vm._s(_vm.nows(props.row.exp_date)))])]):_vm._e()]}}])},[_c('div',{staticClass:"text-center font-size-14",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" No data available ")])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }