import axiosIns from '@/api/axios'

export const GET_DRUG_DATA = (args) => {
    return axiosIns.get(`/items?${args}`)
}

export const ADD_DRUG = (args) => {
    return axiosIns.post(`/items`, args)
}

export const DEL_DRUG = (id) => {
    return axiosIns.delete(`/items?id=${id}`)
}

export const UPDATE_DRUG  = (args) => {
    return axiosIns.put(`/items?id=${args.id}`, args)
}

export const GET_DRUG_CLINIC = (args) => {
    return axiosIns.get(`/clinic/items?${args}`)
}

export const EDIT_DRUG_CLINIC = (args) => {
    return axiosIns.put(`/clinic/items`, args)
}