var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"}),_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-1",modifiers:{"collapse-1":true}}],staticStyle:{"margin-left":"7px"},attrs:{"variant":"primary"}},[_c('span',{staticClass:"fe fe-sliders"})])],1),_c('b-collapse',{attrs:{"id":"collapse-1"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sorting()}}},[_c('div',{staticClass:"row mt-4",staticStyle:{"margin-left":"15px","margin-right":"15px"}},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"exampleInputEmail1"}},[_vm._v(_vm._s(_vm.$t("Name")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.hazard_name),expression:"body.hazard_name"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.body.hazard_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "hazard_name", $event.target.value)}}})])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Order")]),_c('v-select',{attrs:{"options":['ASC', 'DESC']},on:{"input":_vm.sorting},model:{value:(_vm.body.order),callback:function ($$v) {_vm.$set(_vm.body, "order", $$v)},expression:"body.order"}})],1)])]),_c('div',{staticClass:"row mb-3",staticStyle:{"margin-right":"15px"}},[_c('div',{staticClass:"col"}),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-danger lift",attrs:{"type":"submit"}},[_vm._v("Filter")])])])])]),_c('vue-good-table',{attrs:{"line-numbers":true,"columns":_vm.columns_disease,"rows":_vm.chemical.data,"sort-options":{
        enabled: true
      },"total-rows":_vm.chemical.totalItems,"pagination-options":{
        enabled: true,
        mode: 'pages',
        position: 'bottom',
        perPage: 10,
        perPageDropdown: [10, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        allLabel: 'All'
      },"style-class":"vgt-table border-0 table"},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPageChange},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label == 'Disease')?_c('span',[_c('center',[_vm._v("10 Penyakit Banyak Bulan Ini")])],1):_vm._e(),(props.column.label == 'JAN')?_c('span',[_c('center',[_vm._v("JAN")])],1):_vm._e(),(props.column.label == 'FEB')?_c('span',[_c('center',[_vm._v("FEB")])],1):_vm._e(),(props.column.label == 'MAR')?_c('span',[_c('center',[_vm._v("MAR")])],1):_vm._e(),(props.column.label == 'APR')?_c('span',[_c('center',[_vm._v("APR")])],1):_vm._e(),(props.column.label == 'MAY')?_c('span',[_c('center',[_vm._v("MAY")])],1):_vm._e(),(props.column.label == 'JUN')?_c('span',[_c('center',[_vm._v("JUN")])],1):_vm._e(),(props.column.label == 'JUL')?_c('span',[_c('center',[_vm._v("JUL")])],1):_vm._e(),(props.column.label == 'AUG')?_c('span',[_c('center',[_vm._v("AUG")])],1):_vm._e(),(props.column.label == 'SEP')?_c('span',[_c('center',[_vm._v("SEP")])],1):_vm._e(),(props.column.label == 'OCT')?_c('span',[_c('center',[_vm._v("OCT")])],1):_vm._e(),(props.column.label == 'NOV')?_c('span',[_c('center',[_vm._v("NOV")])],1):_vm._e(),(props.column.label == 'DES')?_c('span',[_c('center',[_vm._v("DES")])],1):_vm._e(),(props.column.label == 'Jumlah')?_c('span',[_vm._v(" Jumlah ")]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field == 'Jumlah')?_c('span',[_c('p',[_vm._v("5000")])]):_vm._e()]}}])},[_c('div',{staticClass:"text-center font-size-14",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" No data available ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }