import queryString from "query-string";
import {
    GET_UNIT_PRODUCT,
    ADD_UNIT_PRODUCT,
    GET_INPUT_PRODUCT,
    DEL_INPUT_PRODUCT,
    SUBMIT_PO,
    GET_LIST_PO,
    DELETE_LIST_PO,
    GET_DETAIL_PO,
    GET_EDIT_PO,
    UPDATE_INNER_PO,
    GET_DO_LIST,
    SUBMIT_GR,
    GET_DETAIL_DO,
} from "@/api/clinic/po";

const state = {
    dataUnitProduct: [],
    dataInputProduct: [],
    dataListPO: [],
    dataDetailPO: [],
    dataDetailDO: [],
    dataEditPO: {},
    dataDoList: []
};

const mutations = {
    setUnitProduct: (states, payload) => {
        states.dataUnitProduct = payload;
    },
    setInputProduct: (states, payload) => {
        states.dataInputProduct = payload;
    },
    setDataPO: (states, payload) => {
        states.dataListPO = payload;
    },
    setDetailPO: (states, payload) => {
        states.dataDetailPO = payload;
    },
    setEditPO: (states, payload) => {
        states.dataEditPO = payload;
    },
    setDoList: (states, payload) => {
        states.dataDoList = payload;
    },

    setDetailDO: (states, payload) => {
        states.dataDetailDO = payload;
    },
}

const actions = {
    getUnitProduct: async ({
        commit
    }, args) => {
        // const params = queryString.stringify(args);
        return await GET_UNIT_PRODUCT(args)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setUnitProduct", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setUnitProduct", {
                        setUnitProduct: [],
                    });
                }
            });
    },

    addUnitProduct: async ({
        commit
    }, args) => {
        return await ADD_UNIT_PRODUCT(args).then((resp) => {

            if (resp.data.httpCode === 200) {

                commit("setInputProduct", resp.data.data);
            }
        })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setInputProduct", {
                        setInputProduct: [],
                    });
                }
            });
    },

    getInputProduct: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_INPUT_PRODUCT(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setInputProduct", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setInputProduct", {
                        setInputProduct: [],
                    });
                }
            });
    },
    deleteUnitProduct: async ({
        commit
    }, args) => {
        return await DEL_INPUT_PRODUCT(args).then((resp) => {

            if (resp.data.httpCode === 200) {

                commit("setInputProduct", resp.data.data);
            }
        })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setInputProduct", {
                        setInputProduct: [],
                    });
                }
            });
    },

    submitPO: async ({ }, args) => {
        return await SUBMIT_PO(args);
    },

    getListPO: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_LIST_PO(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {

                    commit("setDataPO", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setDataPO", {
                        setDataPO: [],
                    });
                }
            });
    },

    deletePO: async ({ }, args) => {
        const params = queryString.stringify(args);
        return await DELETE_LIST_PO(params);
    },

    getDetailPO: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_DETAIL_PO(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setDetailPO", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setDetailPO", {
                        setDetailPO: [],
                    });
                }
            });
    },

    getEditPO: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_EDIT_PO(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setEditPO", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setEditPO", {
                        setEditPO: [],
                    });
                }
            });
    },
    updateInner: async ({ }, args) => {
        // const params = queryString.stringify(args);
        return await UPDATE_INNER_PO(args);
    },

    // GR

    getDoList: async ({
        commit
    }, args) => {
        // const params = queryString.stringify(args);
        return await GET_DO_LIST(args)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setDoList", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setDoList", {
                        setDoList: [],
                    });
                }
            });
    },

    submitGR: async ({ }, args) => {
        // const params = queryString.stringify(args);
        return await SUBMIT_GR(args);
    },

    // do
    detailDO: async ({
        commit
    }, args) => {
        // const params = queryString.stringify(args);
        return await GET_DETAIL_DO(args)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setDetailDO", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setDetailDO", {
                        setDetailDO: [],
                    });
                }
            });
    },

}

const getters = {
    UnitProductData: (states) => states.dataUnitProduct,
    InputProductData: (states) => states.dataInputProduct,
    PurchaseOrderData: (states) => states.dataListPO,
    dataDetailPO: (states) => states.dataDetailPO,
    dataDetailDO: (states) => states.dataDetailDO,
    dataEditPO: (states) => states.dataEditPO,
    dataDo: (states) => states.dataDoList
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};