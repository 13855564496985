import queryString from "query-string";

import {
    GET_ACTION_DATA,
    ADD_ACTION,
    DEL_ACTION,
    UPDATE_ACTION,
    ADD_MEASURING,
    GET_MEASURING,
    DEL_MEASURING,
    UPDATE_MEASUREMENT,
    ADD_NEW_PROGRESS,
    APPROVE_PROGRESS,
    ADD_NEW_RESCHEDULE,
    APPROVE_RESCHEDULE
} from "@/api/ih/action";

const state = {
    dataAction: [],
    dataMeasuring: [],
};

const mutations = {
    setAction: (states, payload) => {
        states.dataAction = payload;
    },
    setMeasuring: (states, payload) => {
        states.dataMeasuring = payload;
    },
};

const actions = {
    getAction: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_ACTION_DATA(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setAction", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setAction", {
                        setAction: [],
                    });
                }
            });
    },

    addAction: async ({ }, args) => {
        return await ADD_ACTION(args);
    },

    delAction: async ({ }, id) => {
        return await DEL_ACTION(id);
    },

    updateAction: async ({ }, args) => {
        return await UPDATE_ACTION(args);
    },


    // IH MANAGEMENT MEASURING
    addMeasuring: async ({ }, args) => {
        return await ADD_MEASURING(args);
    },
    delMeasuring: async ({ }, id) => {
        return await DEL_MEASURING(id);
    },
    updateMeasurement: async ({ }, args) => {
        return await UPDATE_MEASUREMENT(args);
    },
    getMeasuring: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_MEASURING(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setMeasuring", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setMeasuring", {
                        setMeasuring: [],
                    });
                }
            });
    },

    newProgress: async ({ }, args) => {
        return await ADD_NEW_PROGRESS(args);
    },

    newReschedule: async ({ }, args) => {
        return await ADD_NEW_RESCHEDULE(args);
    },

    approveProgress: async ({ }, args) => {
        return await APPROVE_PROGRESS(args);
    },

    approveReschedule: async ({ }, args) => {
        return await APPROVE_RESCHEDULE(args);
    },



};

const getters = {
    ActionData: (states) => states.dataAction,
    measuringData: (states) => states.dataMeasuring
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};