import queryString from 'query-string'
import {
    EXCEL_DOWNLOAD,
    PROFILE,
    SUMMARY_DATA
} from "@/api/oh_management/summary";


const state = {
    excel: [],
    summary: [],
    totalSummary: [],
    profiles: {}

};

const mutations = {
    setExcel: (states, payload) => {
        states.excel = payload;

    },
    setSummary: (states, payload) => {
        states.summary = payload;
    },
    setTotalSummary: (states, payload) => {
        states.totalSummary = payload;
    },
    setProfile: (states, payload) => {
        states.profiles = payload;
    },
};

const actions = {

    downloadExcel: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        try {
            const resp = await EXCEL_DOWNLOAD(params)
            const datas = resp.data.data

            commit("setExcel", datas);
        } catch (err) {
            let resp = err.response;
            commit("setExcel", resp);
            console.log("this err", err);
            // if (resp.data.httpCode === 404) {
            //     commit("setExcel", {
            //         setExcel: [],
            //     });
            // }
        }
    },

    getSummary: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        try {
            const resp = await SUMMARY_DATA(params)
            const datas = resp.data.data.data
            commit("setSummary", datas);
        } catch (err) {
            let resp = err.response;
            console.log(err);
            if (resp.data.httpCode === 404) {
                commit("setSummary", {
                    setSummary: [],
                });
            }
        }
    },
    getTotalSummary: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        try {
            const resp = await SUMMARY_DATA(params)
            const datas = resp.data.data.data
            commit("setTotalSummary", datas);
        } catch (err) {
            let resp = err.response;
            console.log(err);
            if (resp.data.httpCode === 404) {
                commit("setTotalSummary", {
                    setSummary: [],
                });
            }
        }
    },

    getProfile: async ({
        commit
    }) => {
        try {
            const resp = await PROFILE()
            const datas = resp.data.data
            commit("setProfile", datas);
        } catch (err) {
            let resp = err.response;
            console.log(err);
            if (resp.data.httpCode === 404) {
                commit("setProfile", {
                    setProfile: {},
                });
            }
        }
    },

};

const getters = {
    excelUrl: (states) => states.excel,
    summarize: (states) => states.summary,
    totalSummarize: (states) => states.totalSummary,
    profileData: (states) => states.profiles

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};