import queryString from "query-string";

import {
    GET_DOCUMENT,
    ADD_DOCUMENT,
    DEL_DOCUMENT,
    UPDATE_DOCUMENT
} from "@/api/oh/document";

const state = {
    dataDocument: [],
};

const mutations = {
    setDocument: (states, payload) => {
        states.dataDocument = payload;
    },
};

const actions = {
    getDocument: async ({
        commit
    }, args) => {
        if (args.data && args.data.length > 0) {
            return args.data;
        } else {
            const params = queryString.stringify(args);
            return await GET_DOCUMENT(params)
                .then((resp) => {

                    if (resp.data.httpCode === 200) {
                        commit("setDocument", resp.data.data);
                    }
                })
                .catch((err) => {
                    let resp = err.response;
                    console.log(err);
                    if (resp.data.httpCode === 404) {
                        commit("setDocument", {
                            setDocument: [],
                        });
                    }
                });
        }
    },

    addDocument: async ({ }, args) => {
        return await ADD_DOCUMENT(args);
    },

    delDocument: async ({ }, id) => {
        return await DEL_DOCUMENT(id);
    },

    updateDocument: async ({ }, args) => {
        return await UPDATE_DOCUMENT(args);
    },



};

const getters = {
    DocumentData: (states) => states.dataDocument
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};