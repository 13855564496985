<template>
  <!-- <b-overlay show="true" rounded="sm"> -->
  <div id="app" class="d-flex flex-column min-vh-100 theme6" ref="baseBody">
    <div id="loader" :class="`position-absolute justify-content-center align-items-center ${
        loadData === false ? 'd-none' : 'd-flex'
      } `">
      <div class="">
        <img src="../src/assets/img/horizontal warna.png" style="width: 200px" />
        <!-- <img src="../src/assets/img/DismalIdioticBrownbutterfly-size_restricted.gif" class="m-auto mt-3 d-block"
          style="width: 100px"> -->
        <img src="../src/assets/img/giphy.gif" class="m-auto mt-3 d-block" style="width: 100px" />
      </div>
    </div>
    <div class="card-body text-center" v-if="printName() == 'Unknown'">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else>
      <nav class="navbar navbar-vertical fixed-start navbar-expand-md navbar-vibrant navbar-dark" id="sidebar">
        <div class="test"></div>
        <div class="container-fluid">
          <!-- <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#sidebarCollapse"
          aria-controls="sidebarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        > -->
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse"
            aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation" v-b-toggle.sidebars>
            <span class="navbar-toggler-icon"></span>
          </button>
          <!-- Brand -->
          <router-link tag="a" class="navbar-brand" to="/">
            <img src="./assets/img/U_health_warna.png" class="navbar-brand-img mx-auto" alt="..." />
          </router-link>

          <div class="navbar-user d-md-none">
            <!-- Dropdown -->
            <div class="dropdown">
              <!-- Toggle -->
              <a href="#" id="sidebarIcon" class="dropdown-toggle" role="button" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <!-- <div class="avatar avatar-sm avatar-online">
                <span class="avatar-title rounded-circle">U</span>
              </div> -->
                <b-nav-item-dropdown right style="list-style-type: none">
                  <!-- Using 'button-content' slot -->
                  <template #button-content>
                    <div class="avatar avatar-sm avatar-online">
                      <span class="avatar-title rounded-circle avatar-online">U</span>
                    </div>
                  </template>
                  <b-dropdown-item href="#">
                    {{ fullname }}
                    <br />
                    {{ companyName !== 'null' ? companyName : '' }}
                  </b-dropdown-item>
                  <hr />
                  <router-link tag="b-dropdown-item" to="/profile">Profile</router-link>
                  <b-dropdown-item href="#" @click.prevent="logout">
                    Logout
                  </b-dropdown-item>
                </b-nav-item-dropdown>
              </a>

              <!-- Menu -->
              <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarIcon">
                <b-link class="dropdown-item" @click="logout">Logout</b-link>
              </div>
            </div>
          </div>

          <!-- Collapse -->
          <div class="collapse navbar-collapse" id="sidebarCollapse">
            <!-- Divider -->
            <!-- <hr class="navbar-divider my-3"> -->

            <!-- Heading -->
            <!-- <h6 class="navbar-heading text-colors text-dark mt-3">
            Health Management System
          </h6> -->

            <!-- Navigation -->
            <ul class="navbar-nav accordion mt-5" style="z-index: 99999999" role="tablist">
              <li class="nav-item" v-for="(item, index) in availableMenu" :key="`${item.name}${index}`" :id="`sidebar-${index+1}`">
                <a :id="item.id" :name="item.named" :class="`nav-link text-colors mx-3 ${
                    item.child && item.child.length > 0 && index > 0
                      ? 'collapsed'
                      : ''
                  } ${
                    item.click
                      ? 'isActives text-primary fw-bolder'
                      : 'hovers fw-lighter'
                  }`" v-b-toggle="item.link" data-navtype="parent" v-if="item.child && item.child.length > 0">
                  <i :class="`fe fe-${item.icon}`"></i>
                  {{ $t(item.i18n) || item.name }}
                </a>
                <router-link :id="item.id" :name="item.named" tag="a" @click.native="load1" :class="`nav-link text-colors mx-3 ${
                    item.child && item.child.length > 0 && index > 0
                      ? 'collapsed'
                      : ''
                  } ${
                    item.click
                      ? 'isActives text-primary fw-bolder'
                      : 'hovers fw-lighter'
                  }`" :to="
                    item.child && item.child.length > 0
                      ? `#${item.link}`
                      : item.link
                  " v-else>
                  <i :class="`fe fe-${item.icon}`"></i>
                  {{ $t(item.i18n) || item.name }}
                </router-link>
                <!-- ${(item.child && (item.child.length > 0) && index == 0) || item.click ? 'show' : ''} -->
                <b-collapse :id="item.child && item.child.length > 0 ? item.link : ''"
                  v-if="item.child && item.child.length > 0"
                  :visible="item.child && item.child.length > 0 && index == 0" accordion="my-accordion" role="tabpanel">
                  <ul class="nav nav-sm flex-column accordion" role="tablist">
                    <li class="nav-item" v-for="(a, i) in item.child" :key="i" :id="`sub-sidebar-${i+1}`">
                      <a :id="a.id" :name="a.named" :class="`nav-link text-colors mx-3 ${
                          a.child && a.child.length > 0 && index > 0
                            ? 'collapsed'
                            : ''
                        } ${
                          a.click
                            ? 'isActives text-primary fw-bolder'
                            : 'hovers fw-lighter'
                        }`" v-b-toggle="a.link" data-navtype="parent" v-if="a.child && a.child.length > 0">
                        {{ $t(a.i18n) || a.name }}
                      </a>
                      <router-link :id="a.id" :name="a.named" tag="a" @click.native="load1" :class="`nav-link mx-3 ${
                          a.click ? 'fw-bolder' : 'hovers text-white fw-lighter'
                        }`" :to="a.link" v-else>
                        {{ $t(a.i18n) || a.name }}
                      </router-link>
                      <b-collapse :id="a.child && a.child.length > 0 ? a.link : ''" v-if="a.child && a.child.length > 0"
                        :visible="a.child && a.child.length > 0 && index == 0" accordion="my-accordion-2"
                        role="tabpanel">
                        <ul class="nav nav-sm flex-column">
                          <li class="nav-item" v-for="(lv3, idx) in a.child" :key="idx" :id="`list-item-${idx+1}`">
                            <router-link :id="lv3.id" :name="lv3.named" tag="a" @click.native="load1" :class="`nav-link mx-3 ${
                                lv3.click
                                  ? 'fw-bolder'
                                  : 'hovers text-white fw-lighter'
                              }`" :to="lv3.link">
                              {{ $t(lv3.i18n) || lv3.name }}
                            </router-link>
                          </li>
                        </ul>
                      </b-collapse>
                    </li>
                  </ul>
                </b-collapse>
              </li>
            </ul>

            <!-- Divider -->
            <hr class="navbar-divider my-3" />
          </div>
          <!-- / .navbar-collapse -->
        </div>
        <b-collapse id="sidebars">
          <div class="mt-3 mb-5" style="margin-left: 10px">
            <ul class="navbar-nav accordion mt-5" style="z-index: 99999999" role="tablist">
              <li class="nav-item" v-for="(item, index) in availableMenu" :key="`${item.name}${index}`">
                <a :class="`nav-link text-colors mx-3 ${
                    item.child && item.child.length > 0 && index > 0
                      ? 'collapsed'
                      : ''
                  } ${
                    item.click
                      ? 'isActives text-primary fw-bolder'
                      : 'hovers fw-lighter'
                  }`" v-b-toggle="item.link" data-navtype="parent" v-if="item.child && item.child.length > 0">
                  <i :class="`fe fe-${item.icon}`"></i>
                  {{ $t(item.i18n) || item.name }}
                </a>
                <router-link tag="a" :class="`nav-link text-colors mx-3 ${
                    item.child && item.child.length > 0 && index > 0
                      ? 'collapsed'
                      : ''
                  } ${
                    item.click
                      ? 'isActives text-primary fw-bolder'
                      : 'hovers fw-lighter'
                  }`" :to="
                    item.child && item.child.length > 0
                      ? `#${item.link}`
                      : item.link
                  " v-else>
                  <i :class="`fe fe-${item.icon}`"></i>
                  {{ $t(item.i18n) || item.name }}
                </router-link>
                <!-- ${(item.child && (item.child.length > 0) && index == 0) || item.click ? 'show' : ''} -->
                <b-collapse :id="item.child && item.child.length > 0 ? item.link : ''"
                  v-if="item.child && item.child.length > 0"
                  :visible="item.child && item.child.length > 0 && index == 0" accordion="my-accordion" role="tabpanel">
                  <ul class="nav nav-sm flex-column accordion" role="tablist">
                    <li class="nav-item" v-for="(a, i) in item.child" :key="i">
                      <a :class="`nav-link text-colors mx-3 ${
                          a.child && a.child.length > 0 && index > 0
                            ? 'collapsed'
                            : ''
                        } ${
                          a.click
                            ? 'isActives text-primary fw-bolder'
                            : 'hovers fw-lighter'
                        }`" v-b-toggle="a.link" data-navtype="parent" v-if="a.child && a.child.length > 0">
                        {{ $t(a.i18n) || a.name }}
                      </a>
                      <router-link tag="a" :class="`nav-link mx-3 ${
                          a.click ? 'fw-bolder' : 'hovers text-white fw-lighter'
                        }`" :to="a.link" v-else>
                        {{ $t(a.i18n) || a.name }}
                      </router-link>
                      <b-collapse :id="a.child && a.child.length > 0 ? a.link : ''" v-if="a.child && a.child.length > 0"
                        :visible="a.child && a.child.length > 0 && index == 0" accordion="my-accordion-2"
                        role="tabpanel">
                        <ul class="nav nav-sm flex-column">
                          <li class="nav-item" v-for="(lv3, idx) in a.child" :key="idx">
                            <router-link tag="a" :class="`nav-link mx-3 ${
                                lv3.click
                                  ? 'fw-bolder'
                                  : 'hovers text-white fw-lighter'
                              }`" :to="lv3.link">
                              {{ $t(lv3.i18n) || lv3.name }}
                            </router-link>
                          </li>
                        </ul>
                      </b-collapse>
                    </li>
                  </ul>
                </b-collapse>
              </li>
            </ul>
          </div>
        </b-collapse>
      </nav>
      <div class="main-content">
        <nav class="navbar navbar-expand-md navbar-light d-none d-md-flex fixed-top" id="topbar">
          <div class="container-fluid justify-content-end">
            <a class="navbar-brand" href="/">
              <img src="./assets/img/horizontal warna.png" class="navbar-brand-img mx-auto" alt="..." />
            </a>

            <div class="navbar-user">
              <label class="form-check-label" for="flexSwitchCheckDefault" style="margin-left: -10px">EN</label>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" @input="changesLang()" />
                <label class="form-check-label" for="flexSwitchCheckDefault">ID</label>
              </div>
              <!-- <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="changeLang" @change="changeLang = !changeLang">
              <label class="form-check-label" for="cardToggle"></label>
            </div> -->
              <!-- {{ changeLang }} -->

              <div class="dropdown me-4 d-none d-md-flex">
                <!-- Toggle -->
                <!-- <a href="#" class="navbar-user-link" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <span class="icon active">
                  <i class="fe fe-bell"></i>
                </span>
              </a> -->

                <!-- Menu -->
                <div class="dropdown-menu dropdown-menu-end dropdown-menu-card">
                  <div class="card-header">
                    <!-- Title -->
                    <h5 class="card-header-title">Notifications</h5>

                    <!-- Link -->
                    <a href="#!" class="small"> View all </a>
                  </div>
                  <!-- / .card-header -->
                  <div class="card-body">
                    <!-- List group -->
                    <div class="list-group list-group-flush list-group-activity my-n3">
                      <a class="list-group-item text-reset" href="#!">
                        <div class="row">
                          <div class="col-auto">
                            <!-- Avatar -->
                            <div class="avatar avatar-sm">
                              <img src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..."
                                class="avatar-img rounded-circle" />
                            </div>
                          </div>
                          <div class="col ms-n2">
                            <!-- Content -->
                            <div class="small">
                              <strong>Dianna Smiley</strong> shared your post
                              with Ab Hadley, Adolfo Hess, and 3 others.
                            </div>

                            <!-- Time -->
                            <small class="text-muted"> 2m ago </small>
                          </div>
                        </div>
                        <!-- / .row -->
                      </a>
                      <a class="list-group-item text-reset" href="#!">
                        <div class="row">
                          <div class="col-auto">
                            <!-- Avatar -->
                            <div class="avatar avatar-sm">
                              <img src="./assets/img/avatars/profiles/avatar-2.jpg" alt="..."
                                class="avatar-img rounded-circle" />
                            </div>
                          </div>
                          <div class="col ms-n2">
                            <!-- Content -->
                            <div class="small">
                              <strong>Ab Hadley</strong> reacted to your post
                              with a 😍
                            </div>

                            <!-- Time -->
                            <small class="text-muted"> 2m ago </small>
                          </div>
                        </div>
                        <!-- / .row -->
                      </a>
                      <a class="list-group-item text-reset" href="#!">
                        <div class="row">
                          <div class="col-auto">
                            <!-- Avatar -->
                            <div class="avatar avatar-sm">
                              <img src="./assets/img/avatars/profiles/avatar-3.jpg" alt="..."
                                class="avatar-img rounded-circle" />
                            </div>
                          </div>
                          <div class="col ms-n2">
                            <!-- Content -->
                            <div class="small">
                              <strong>Adolfo Hess</strong> commented
                              <blockquote class="mb-0">
                                “I don’t think this really makes sense to do
                                without approval from Johnathan since he’s the
                                one...”
                              </blockquote>
                            </div>

                            <!-- Time -->
                            <small class="text-muted"> 2m ago </small>
                          </div>
                        </div>
                        <!-- / .row -->
                      </a>
                      <a class="list-group-item text-reset" href="#!">
                        <div class="row">
                          <div class="col-auto">
                            <!-- Avatar -->
                            <div class="avatar avatar-sm">
                              <img src="./assets/img/avatars/profiles/avatar-4.jpg" alt="..."
                                class="avatar-img rounded-circle" />
                            </div>
                          </div>
                          <div class="col ms-n2">
                            <!-- Content -->
                            <div class="small">
                              <strong>Daniela Dewitt</strong> subscribed to you.
                            </div>

                            <!-- Time -->
                            <small class="text-muted"> 2m ago </small>
                          </div>
                        </div>
                        <!-- / .row -->
                      </a>
                      <a class="list-group-item text-reset" href="#!">
                        <div class="row">
                          <div class="col-auto">
                            <!-- Avatar -->
                            <div class="avatar avatar-sm">
                              <img src="./assets/img/avatars/profiles/avatar-5.jpg" alt="..."
                                class="avatar-img rounded-circle" />
                            </div>
                          </div>
                          <div class="col ms-n2">
                            <!-- Content -->
                            <div class="small">
                              <strong>Miyah Myles</strong> shared your post with
                              Ryu Duke, Glen Rouse, and 3 others.
                            </div>

                            <!-- Time -->
                            <small class="text-muted"> 2m ago </small>
                          </div>
                        </div>
                        <!-- / .row -->
                      </a>
                      <a class="list-group-item text-reset" href="#!">
                        <div class="row">
                          <div class="col-auto">
                            <!-- Avatar -->
                            <div class="avatar avatar-sm">
                              <img src="./assets/img/avatars/profiles/avatar-6.jpg" alt="..."
                                class="avatar-img rounded-circle" />
                            </div>
                          </div>
                          <div class="col ms-n2">
                            <!-- Content -->
                            <div class="small">
                              <strong>Ryu Duke</strong> reacted to your post
                              with a 😍
                            </div>

                            <!-- Time -->
                            <small class="text-muted"> 2m ago </small>
                          </div>
                        </div>
                        <!-- / .row -->
                      </a>
                      <a class="list-group-item text-reset" href="#!">
                        <div class="row">
                          <div class="col-auto">
                            <!-- Avatar -->
                            <div class="avatar avatar-sm">
                              <img src="./assets/img/avatars/profiles/avatar-7.jpg" alt="..."
                                class="avatar-img rounded-circle" />
                            </div>
                          </div>
                          <div class="col ms-n2">
                            <!-- Content -->
                            <div class="small">
                              <strong>Glen Rouse</strong> commented
                              <blockquote class="mb-0">
                                “I don’t think this really makes sense to do
                                without approval from Johnathan since he’s the
                                one...”
                              </blockquote>
                            </div>

                            <!-- Time -->
                            <small class="text-muted"> 2m ago </small>
                          </div>
                        </div>
                        <!-- / .row -->
                      </a>
                      <a class="list-group-item text-reset" href="#!">
                        <div class="row">
                          <div class="col-auto">
                            <!-- Avatar -->
                            <div class="avatar avatar-sm">
                              <img src="./assets/img/avatars/profiles/avatar-8.jpg" alt="..."
                                class="avatar-img rounded-circle" />
                            </div>
                          </div>
                          <div class="col ms-n2">
                            <!-- Content -->
                            <div class="small">
                              <strong>Grace Gross</strong> subscribed to you.
                            </div>

                            <!-- Time -->
                            <small class="text-muted"> 2m ago </small>
                          </div>
                        </div>
                        <!-- / .row -->
                      </a>
                    </div>
                  </div>
                </div>
                <!-- / .dropdown-menu -->
              </div>
              <b-nav-item-dropdown right style="list-style-type: none">
                <!-- Using 'button-content' slot -->
                <template #button-content>
                  <div>
                    <i class="fe fe-user"></i>
                    {{ printName() }}
                    <i class="fe fe-chevron-down"></i>
                  </div>
                </template>
                <b-dropdown-item href="#">
                  {{ fullname }}
                  <br />
                  {{ companyName !== 'null' ? companyName : '' }}
                </b-dropdown-item>
                <hr />
                <router-link tag="b-dropdown-item" to="/profile">Profile</router-link>
                <b-dropdown-item href="#" @click.prevent="logout">
                  Logout
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </div>
          </div>
        </nav>
        <router-view style="padding-top: 70px" id="content-view" :class="`${loadData === false ? '' : 'd-none'}`" />
        <!-- <div class="container"> -->
        <!-- <footer class="py-1 my-4 bg-dark" id="myfooter">
        <p class="text-center text-light my-2 small">&copy; PT Golden Energy Mines Tbk</p>
      </footer> -->
        <!-- </div> -->
      </div>
    </div>
    <footer class="bg-dark text-center text-lg-start">
      <!-- Copyright -->
      <div class="text-center p-3 text-light" style="background-color: rgba(0, 0, 0, 0.2)">
        Provided by Digitech - GEMS. ©️ {{ currentYear }} PT. Golden Energy Mines, Tbk.
        <!-- <a class="text-dark" href="https://mdbootstrap.com/">MDBootstrap.com</a> -->
      </div>
      <!-- Copyright -->
    </footer>
  </div>
  <!-- </b-overlay> -->
</template>

<script>
  import Keycloak from "keycloak-js";
  // import lang from "@/lang.json";
  import lang from "@/lang.json";
  import {
    LOGOUT
  } from "../src/api/auth";
  import role from "./store/role/role";

  export default {
    name: "app",
    computed: {
      getCurrentLocaleData() {
        const locale = this.$i18n.locale;
        if (locale === "en")
          return {
            flag: "us",
            lang: "English",
          };
        else if (locale === "id")
          return {
            flag: "id",
            lang: "Indonesia",
          };
      },
      loadingPage() {
        return this.$store.getters["detailManagement/loadingPageData"] ?
          this.$store.getters["detailManagement/loadingPageData"] : [];
      },
    },
    data() {
      return {
        kcOptions: {
          realm: process.env.VUE_APP_KEYCLOAK_REALMS,
          "auth-server-url": process.env.VUE_APP_KEYCLOAK_URL,
          url: process.env.VUE_APP_KEYCLOAK_URL,
          "ssl-required": "external",
          clientId: process.env.VUE_APP_KEYCLOAK_CLIENT,
          resource: process.env.VUE_APP_KEYCLOAK_CLIENT,
          "verify-token-audience": true,
          "use-resource-role-mappings": true,
          "confidential-port": 0,
          "policy-enforcer": {},
        },
        keycloak: null,
        view: false,
        indexTemp: null,
        changeLang: false,
        none: [{
          icon: "home",
          // name: lang.lang.en.sidebar.home,
          name: "Dashboard",
          i18n: "Dashboard",
          link: "/",
        }, ],
        menus: [{
            id: "dashboard",
            named: "dashboard",
            icon: "home",
            // name: lang.lang.en.sidebar.home,
            name: "Dashboard",
            i18n: "Dashboard",
            link: "/",
          },
          {
            id: "master-data",
            named: "masterdata",
            icon: "database",
            name: "Master Data",
            i18n: "Master Data",
            link: "masterData",
            show: true,
            child: [{
                id: "role",
                named: "role",
                name: "Role",
                link: "/role",
              },
              {
                id: "disease-submenu",
                named: "disease-submenu",
                name: "Disease",
                i18n: "Disease",
                link: "diseaseSubMenu",
                child: [{
                    id: "disease-chapter",
                    named: "disease-chapter",
                    name: "Chapter",
                    i18n: "Chapter",
                    link: "/disease/chapter",
                  },
                  {
                    id: "disease-block",
                    named: "disease-block",
                    name: "Block",
                    i18n: "Block",
                    link: "/disease/block",
                  },
                  {
                    id: "disease-category",
                    named: "disease-category",
                    name: "Category",
                    i18n: "Category",
                    link: "/disease/category",
                  },
                  {
                    id: "disease",
                    named: "disease",
                    name: "Disease",
                    i18n: "Disease",
                    link: "/disease",
                  },
                ],
              },
              // Inventory
              {
                id: "inventory-submenu",
                named: "inventory-submenu",
                name: "Inventory",
                i18n: "Inventory",
                link: "inventorySubMenu",
                child: [{
                    id: "medicine-category",
                    named: "medicine-category",
                    name: "Medicine Category",
                    i18n: "Medicine Category",
                    link: "/inventory/category",
                  },
                  {
                    id: "inventory-medicine",
                    named: "inventory-medicine",
                    name: "Medicine",
                    i18n: "Medicine",
                    link: "/inventory/medicine",
                  },
                  {
                    id: "disposable-item-category",
                    named: "disposable-item-category",
                    name: "Disposable Item Category",
                    i18n: "Disposable Item Category",
                    link: "/inventory/disposable-category",
                  },
                  {
                    id: "disposable-item",
                    named: "disposable-item",
                    name: "Disposable Item",
                    i18n: "Disposable Item",
                    link: "/inventory/disposable-item",
                  },
                  {
                    id: "medical-tool-category",
                    named: "medical-tool-category",
                    name: "Medical Tool Category",
                    i18n: "Medical Tool Category",
                    link: "/inventory/medical-equipment-category",
                  },
                  {
                    id: "medical-tool",
                    named: "medical-tool",
                    name: "Medical Tool",
                    i18n: "Medical Tool",
                    link: "/inventory/medical-tool-item",
                  },
                  {
                    id: "inventory-uom",
                    named: "inventory-uom",
                    name: "UOM",
                    link: "/inventory/unit",
                  },
                  {
                    id: "medical-waste-partner",
                    named: "medical-waste-partner",
                    name: "Medical Waste Partner",
                    link: "/inventory/medical-waste/partner",
                  },
                ],
              },
              {
                id: "chemical-submenu",
                named: "chemical-submenu",
                name: "Chemical",
                i18n: "Chemical",
                link: "chemicalSubMenu",
                child: [{
                    id: "material-type",
                    named: "material-type",
                    name: "Material Type",
                    i18n: "Material Type",
                    link: "/chemical/material-type",
                  },
                  {
                    id: "chemical-hazard",
                    named: "chemical-hazard",
                    name: "Chemical Hazard",
                    i18n: "Chemical Hazard",
                    link: "/chemical/hazard",
                  },
                  {
                    id: "material-name",
                    named: "material-name",
                    name: "Material Name",
                    i18n: "Material Name",
                    link: "/chemical/material-name",
                  },
                ],
              },
              {
                id: "medical-submenu",
                named: "medical-submenu",
                name: "Medical",
                i18n: "Medical",
                link: "medicalSubMenu",
                child: [{
                    id: "medical-facility",
                    named: "medical-facility",
                    name: "Facility",
                    i18n: "Facility",
                    link: "/medical/facility",
                  },
                  {
                    id: "medical-treatment",
                    named: "medical-treatment",
                    name: "Treatment",
                    i18n: "Treatment",
                    link: "/medical/treatment",
                  },
                  {
                    id: "staff-position",
                    named: "staff-position",
                    name: "Staff - Position",
                    i18n: "Staff - Position",
                    link: "/medical/staff/position",
                  },
                  {
                    id: "staff-training-group",
                    named: "staff-training-group",
                    name: "Staff - Training - Group",
                    i18n: "Staff - Training - Group",
                    link: "/medical/staff/training/group",
                  },
                  {
                    id: "staff-training-subgroup",
                    named: "staff-training-subgroup",
                    name: "Staff - Training - SubGroup",
                    i18n: "Staff - Training - SubGroup",
                    link: "/medical/staff/training/subGroup",
                  },
                ],
              },
              {
                id: "mcu-submenu",
                named: "mcusubmenu",
                name: "MCU",
                i18n: "MCU",
                link: "mcuSubMenu",
                child: [{
                    id: "mcu-list-category",
                    named: "mculistcategory",
                    name: "Category - Category",
                    i18n: "Category - Category",
                    link: "/category/category",
                  },
                  {
                    id: "mcu-category-result",
                    named: "mcu-category-result",
                    name: "Category - Result",
                    i18n: "Category - Result",
                    link: "/category/result",
                  },
                  {
                    id: "mcu-test-category",
                    named: "mcu-test-category",
                    name: "Test - Category",
                    link: "/test/category",
                  },
                  {
                    id: "mcu-test-group",
                    named: "mcu-test-group",
                    name: "Test - Group",
                    i18n: "Test - Group",
                    link: "/test/group",
                  },
                  {
                    id: "mcu-test-item",
                    named: "mcu-test-item",
                    name: "Test - Item",
                    i18n: "Test - Item",
                    link: "/test/item",
                  },
                  {
                    id: "mcu-question-type",
                    named: "mcu-question-type",
                    name: "Question Type",
                    i18n: "Question Type",
                    link: "/question/questionType",
                  },
                  {
                    id: "mcu-question",
                    named: "mcu-question",
                    name: "Question ",
                    i18n: "Question",
                    link: "/question/question",
                  },
                ],
              },
              {
                id: "ih-submenu",
                named: "ih-submenu",
                name: "IH",
                i18n: "IH",
                link: "ihSubMenu",
                child: [{
                    id: "ih-action",
                    named: "ih-action",
                    name: "Action",
                    i18n: "Action",
                    link: "/ih/action",
                  },
                  {
                    id: "ih-factor",
                    named: "ih-factor",
                    name: "Factor",
                    i18n: "Factor",
                    link: "/ih/factor",
                  },
                  {
                    id: "ih-measuring-activity",
                    named: "ih-measuring-activity",
                    name: "Measuring - Activity",
                    i18n: "Measuring - Activity",
                    link: "/ih/measuring/activity",
                  },
                  {
                    id: "ih-measuring-method",
                    named: "ih-measuring-method",
                    name: "Measuring - Method",
                    i18n: "Measuring - Method",
                    link: "/ih/measuring/method",
                  },
                  // {
                  //   id: "ih-measuring-unit",
                  //   named   id: "ih-measuring-unit",
                  //   name: "Measuring - Unit",
                  //   i18n: "Measuring - Unit",
                  //   link: "/ih/measuring/unit",
                  // },
                  {
                    id: "ih-measuring-kit",
                    named: "ih-measuring-kit",
                    name: "Measuring - Kit",
                    i18n: "Measuring - Kit",
                    link: "/ih/measuring/kit",
                  },
                  {
                    id: "ih-measuring-campaign",
                    named: "ih-measuring-campaign",
                    name: "Measuring - Campaign",
                    i18n: "Measuring - Campaign",
                    link: "/ih/measuring/campaign",
                  },
                ],
              },
              {
                id: "oh-submenu",
                named: "oh-submenu",
                name: "OH",
                i18n: "OH",
                link: "ohSubMenu",
                child: [{
                    id: "oh-leading",
                    named: "oh-leading",
                    name: "Leading",
                    i18n: "Leading",
                    link: "/oh/activity",
                  },
                  {
                    id: "oh-lagging",
                    named: "oh-lagging",
                    name: "Lagging Indicator",
                    i18n: "Lagging Indicator",
                    link: "/oh/indicator",
                  },
                  {
                    id: "oh-indicator",
                    named: "oh-indicator",
                    name: "Indicator",
                    i18n: "Indicator",
                    link: "/oh/data",
                  },
                  {
                    id: "oh-group-activity",
                    named: "oh-group-activity",
                    name: "Group Activity",
                    i18n: "Group Activity",
                    link: "/oh/group-activity",
                  },
                  {
                    id: "oh-yearly",
                    named: "oh-yearly",
                    name: "Yearly",
                    i18n: "Yearly",
                    link: "/oh/yearly",
                  },
                ],
              },
              {
                id: "company-mapping",
                named: "company-mapping",
                name: "Company Mapping",
                link: "/company-mapping",
              },
            ],
          },
          {
            id: "healthcare-facility",
            named: "healthcare-facility",
            icon: "shield",
            name: "Healthcare Facility",
            i18n: "Healthcare Facility",
            link: "/healthcare-facility",
            click: false,
          },
          {
            id: "role-management",
            named: "role-management",
            icon: "user",
            name: "User Management",
            i18n: "User Management",
            link: "/role-management",
            child:[{
              id: "oh-input",
                named: "oh-input",
                name: "Role Management",
                i18n: "Role Management",
                link: "/role-management",
              },
              // {
              //   id: "overhandle",
              //   named: "overhandle",
              //   name: "Overhandle",
              //   i18n: "Overhandle",
              //   link: "/overhandle",
              // }
            ]
          },
          {
            id: "oh-management",
            named: "oh-management",
            icon: "book",
            name: "OH Management",
            i18n: "OH Management",
            link: "ohManagement",
            child: [{
                id: "oh-input",
                named: "oh-input",
                name: "Input",
                i18n: "Input",
                link: "/oh-management/input",
              },
              {
                id: "oh-correction",
                named: "oh-correction",
                name: "Correction",
                i18n: "Correction",
                link: "/oh-management/correction",
              },
              // {
              //   id: "oh-approval",
              //   named   id: "oh-approval",
              //   name: "Approval",
              //   link: "/oh-management/approval",
              // },
              {
                id: "oh-report-submenu",
                named: "oh-report-submenu",
                name: "Report",
                i18n: "Report",
                link: "reportSubMenu",
                child: [{
                    id: "oh-leading-lagging",
                    named: "oh-leading-lagging",
                    name: "Leading & Lagging Indicator",
                    link: "/oh-management/monthly/leading-lagging-indicator",
                  },
                  // {
                  //   id: "oh-tenaga-kerja",
                  //   named   id: "oh-tenaga-kerja",
                  //   name: "Tenaga Kerja Sakit",
                  //   link: "/oh-management/monthly/tenaga-kerja-sakit",
                  // },
                  {
                    id: "oh-health-attendance",
                    named: "oh-health-attendance",
                    name: "Health Attendance Data",
                    i18n: "Health Attendance Data",
                    link: "/oh-management/monthly/summary"
                    ,
                  },
                  {
                    id: "oh-recap-daily",
                    named: "oh-recap-daily",
                    name: "Recap Daily Input",
                    link: "/oh-management/recap-daily",
                  },
                ],
              },
            ],
          },
          {
            id: "clinic-management",
            named: "clinic-management",
            icon: "plus-square",
            name: "Clinic Management",
            i18n: "Clinic Management",
            link: "masterKlinik",
            child: [{
                id: "inventory-masterdata",
                named: "inventory-masterdata",
                name: "Master Data",
                i18n: "Master Data",
                link: "inventoryMaster",
                child: [{
                    id: "inventory-medicine",
                    named: "inventory-medicine",
                    name: "Medicine",
                    i18n: "Medicine",
                    link: "/inventory/medicine",
                  },
                  {
                    id: "inventory-disposable-item",
                    named: "inventory-disposable-item",
                    name: "Disposable Item",
                    i18n: "Disposable Item",
                    link: "/inventory/disposable-item",
                  },
                  {
                    id: "inventory-medical-tool",
                    named: "inventory-medical-tool",
                    name: "Medical Tool",
                    i18n: "Medical Tool",
                    link: "/inventory/medical-tool-item",
                  },
                ],
              },
              // {
              //   id: "inventory-role-management-clinic",
              //named   id: "inventory-role-management-clinic",
              //   name: "Role Management Clinic",
              //   i18n: "Role Management Clinic",
              //   link: "/clinic-management/role-management-clinic",
              // },
              {
                id: "registration",
                named: "registration",
                name: "Registration",
                i18n: "Registration",
                link: "/clinic-patient",
              },
              {
                id: "drug-test",
                named: "drug-test",
                name: "Drug Test",
                i18n: "Drug Test",
                link: "/clinic-management/drug_test",
              },
              {
                id: "non-partnership-clinic",
                named: "non-partnership-clinic",
                name: "Non Partnership Clinic",
                i18n: "Non Partnership Clinic",
                link: "/no-partnership-clinic",
              },
              {
                id: "medical-mcu-record",
                named: "medical-mcu-record",
                name: "Medical & MCU Record",
                i18n: "Medical & MCU Record",
                link: "/medical-record-mcu",
              },
              {
                id: "medical-waste",
                named: "medical-waste",
                name: "Medical Waste",
                i18n: "Medical Waste",
                link: "MedicalWaste",
                child: [{
                    id: "medical-waste-bin",
                    named: "medical-waste-bin",
                    name: "Bin",
                    i18n: "Bin",
                    link: "/clinic-management/inventory/bin",
                  },
                  {
                    id: "medical-waste-disposal",
                    named: "medical-waste-disposal",
                    name: "Disposal",
                    i18n: "Disposal",
                    link: "/clinic-management/inventory/disposal",
                  },
                ],
              },
              {
                id: "inventory-submenu",
                named: "inventory-submenu",
                name: "Inventory",
                i18n: "Inventory",
                link: "inventorySubMenu",
                child: [{
                    id: "summary-stock",
                    named: "summary-stock",
                    name: "Summary Stock",
                    i18n: "Summary Stock",
                    link: "/clinic-management/inventory/drugs-&-medical",
                  },
                  // {
                  //   id: "purchase-order",
                  //   named: "purchase-order",
                  //   name: "Purchase Order",
                  //   i18n: "Purchase Order",
                  //   link: "/clinic-management/inventory/list-po",
                  // },
                  {
                    id: "entry-stock",
                    named: "entry-stock",
                    name: "Entry Stock",
                    i18n: "Entry Stock",
                    link: "/clinic-management/inventory/entry-stock",
                  },
                  // {
                  //   id: "delivery-order",
                  //   named: "delivery-order",
                  //   name: "Delivery Order",
                  //   i18n: "Delivery Order",
                  //   link: "/clinic-management/inventory/list-do",
                  // },
                  {
                    id: "stock-opname",
                    named: "stock-opname",
                    name: "Stock Opname",
                    i18n: "Stock Opname",
                    link: "/clinic-management/inventory/stock-opname",
                  },
                ],
              },
            ],
          },
          {
            id: "ih-management",
            named: "ih-management",
            icon: "clipboard",
            name: "IH Management",
            i18n: "IH Management",
            link: "IHManagement",
            child: [{
                id: "ih-measurement",
                named: "ih-measurement",
                name: "Measurement",
                i18n: "Measurement",
                link: "/ih-measurement",
              },
              {
                id: "ih-chemical",
                named: "ih-chemical",
                name: "Chemical",
                i18n: "Chemical",
                link: "/ih-chemical",
              },
            ],
          },
          {
            id:"notification-settings",
            named:"notification-settings",
            icon:"settings",
            name:"Notification Settings",
            i18n:"Notification Settings",
            link:"/notification-settings"
          }
        ],
        navVars: {},
        choice: [1, 2, 3, 4, 5, 6],
        pick: 1,
        expand: true,
        fullname: "",
        companyName: "",
        feature: [],
        featureName: "",
        loadData: true,
        availableMenu: [],
        currentYear: new Date().getFullYear()
      };
    },

    created() {
      if (this.$workbox) {
        this.$workbox.addEventListener("waiting", () => {
          this.showUpgradeUI = true;
        });
      }
      this.keycloak = new Keycloak(this.kcOptions);
      this.authenticate();
    },
    mounted() {
      setTimeout(() => {
        this.loadData = false;
      }, 2000);
      // let set = setInterval(() => {
      //       if (this.loadingPage === false) {
      //         this.loadData = false
      //         clearInterval(set);
      //       }
      //   }, 2000);

      // this.$store.commit("role/setLoader", true)

    },
    methods: {
      load1() {
        this.loadData = true;
        setTimeout(() => {
          this.loadData = false;
        }, 2000);
      },
      changesLang() {
        this.changeLang = !this.changeLang;
        if (this.changeLang == false) {
          this.$i18n.locale = "en";
        } else {
          this.$i18n.locale = "id";
        }
      },
      authenticate: function () {
        this.AccessToken = "Authenticating....";

        this.keycloak
          .init({
            onLoad: "login-required",
            checkLoginIframe: true,
            promiseType: "native",
          })
          .then((auth) => {
            if (!auth) {
              this.AccessToken = "";
            } else {
              this.AccessToken = this.keycloak.token;
            }
            // set updated token
            this.$http.defaults.headers[
              "Authorization"
            ] = `Bearer ${this.keycloak.token}`;

            localStorage.setItem("ugems-accessToken", this.keycloak.token);
            localStorage.setItem(
              "ugems-refreshToken",
              this.keycloak.refreshToken
            );
            localStorage.setItem(
              "ugems-tokenParsed",
              JSON.stringify(this.keycloak.tokenParsed)
            );
            this.getProfile();
          })
          .catch((err) => {
            console.log("Authenticated Failed");
            console.log("err", err);
            this.$swal.fire("Error!", `${err}`, "error");
          });
      },
      switchTemplate() {
        this.$refs.baseBody.classList.remove(
          "theme1",
          "theme2",
          "theme3",
          "theme4",
          "theme5",
          "theme6"
        );
        this.$refs.baseBody.classList.add(`theme${this.pick}`);
      },
      navClick(link) {
        const idx = this.menus.findIndex((e) => e.link === link);
        if (this.menus[idx].child) {
          let selected = this.menus[idx];

          if (this.$refs[selected.link][0].classList.contains("collapsed")) {
            this.$refs[selected.link][0].classList.remove("collapsed");
          }
        }
      },

      filterMenu() {
        let company = localStorage.getItem("company");
        let user_role = localStorage.getItem("user_roles");
        const role_user = JSON.parse(user_role);
        const isSuperAdmin = role_user.findIndex(
          (e) => parseInt(e.roles_id) == 1
        );
        const isCompanyAdmin = role_user.findIndex(
          (e) => parseInt(e.roles_id) == 2
        );
        const isExternal = role_user.findIndex(
          (e) => parseInt(e.roles_id) == 3
        );
        const isCompanyDoctor = role_user.findIndex(
          (e) => parseInt(e.roles_id) == 4
        );
        const isCompanyParamedic = role_user.findIndex(
          (e) => parseInt(e.roles_id) == 5
        );
        const isIHPic = role_user.findIndex(
          (e) => parseInt(e.roles_id) == 6
        );
        const isParamedicPractitioner = role_user.findIndex(
          (e) => parseInt(e.roles_id) == 7
        );

        const clinicFeature = role_user.length > 0 ? role_user[0].healthcare_facility_feature : [];
        const invent = clinicFeature.findIndex(el => el.healthcare_facility_feature_id === '2');

        if (role_user.length > 0) {
          if (isSuperAdmin >= 0) {
            this.availableMenu = this.menus
          } else if (isCompanyAdmin >= 0) {
            let filters = this.menus.filter((item) => {
              return(
                item.link !== "masterData" &&
                item.link !== "/healthcare-facility"
              )
            })

            const findIdx = filters.findIndex(el => el.link === 'masterKlinik');
            if (company != 1) {
              const subMenus = filters[findIdx].child.filter(el => el.link !== 'inventoryMaster');
              filters[findIdx].child = subMenus;
              if (invent < 0) {
              const subMenus = filters[findIdx].child.filter(el => el.link !== 'inventoryMaster' && el.link !== 'MedicalWaste' && el.link !== 'inventorySubMenu');
              filters[findIdx].child = subMenus;
            }

            }
            
            const findIdxCorrection = filters.findIndex(el => el.link === 'ohManagement');
            const subOh = filters[findIdxCorrection].child.filter(el => el.link !== '/oh-management/correction');
            filters[findIdxCorrection].child = subOh;

            this.availableMenu = filters;
          } else if(isExternal >= 0) {
            let filters = this.menus.filter((item) => {
              return(
                item.link !== "masterData" &&
                item.link !== "/healthcare-facility" &&
                item.link !== "IHManagement" &&
                item.link !== "/role-management" &&
                item.link !== "ohManagement" &&
                item.link !== "/notification-settings"
              )
            })

            const findIdx = filters.findIndex(el => el.link === 'masterKlinik');
            if (invent < 0) {
              let subMenus = null;
              if (role_user[0].healthcare_facility_type_clinic === 'Clinic External') {
                subMenus = filters[findIdx].child.filter(el => el.link !== 'inventoryMaster' && el.link !== 'MedicalWaste' && el.link !== 'inventorySubMenu' && el.link !== '/no-partnership-clinic');
              } else {
                subMenus = filters[findIdx].child.filter(el => el.link !== 'inventoryMaster' && el.link !== 'MedicalWaste' && el.link !== 'inventorySubMenu' && el.link !== '/no-partnership-clinic' && el.link !== '/clinic-management/drug_test');
              }
              filters[findIdx].child = subMenus;
            }

            // const findIdxCorrection = filters.findIndex(el => el.link === 'ohManagement');
            // const subOh = filters[findIdxCorrection].child.filter(el => el.link !== '/oh-management/correction');
            // filters[findIdxCorrection].child = subOh;
            
            this.availableMenu = filters;
          } else if(isCompanyDoctor >= 0 || isCompanyParamedic >= 0 || isParamedicPractitioner >= 0) {
            let filters = this.menus.filter((item) => {
              return(
                item.link !== "masterData" &&
                item.link !== "/healthcare-facility" &&
                item.link !== "IHManagement" &&
                item.link !== "/role-management" &&
                item.link !== "/notification-settings"
              )
            })
            
            const findIdx = filters.findIndex(el => el.link === 'masterKlinik');
            if (invent < 0) {
              const subMenus = filters[findIdx].child.filter(el => el.link !== 'inventoryMaster' && el.link !== 'MedicalWaste' && el.link !== 'inventorySubMenu');
              filters[findIdx].child = subMenus;
            }

            const findIdxCorrection = filters.findIndex(el => el.link === 'ohManagement');
            const subOh = filters[findIdxCorrection].child.filter(el => el.link !== '/oh-management/correction');
            filters[findIdxCorrection].child = subOh;
            
            this.availableMenu = filters;
          } else  if (isIHPic >= 0) {
            let filters = this.menus.filter((item) => {
              return(
                item.link !== "masterData" &&
                item.link !== "/healthcare-facility" &&
                item.link !== "masterKlinik" &&
                item.link !== "/role-management" &&
                item.link !== "ohManagement" &&
                item.link !== "/notification-settings"
              )
            })

            this.availableMenu = filters;
          }
        } else {
          this.availableMenu = this.none
        }
      },
      switchTemplate() {
        this.$refs.baseBody.classList.remove(
          "theme1",
          "theme2",
          "theme3",
          "theme4",
          "theme5",
          "theme6"
        );
        this.$refs.baseBody.classList.add(`theme${this.pick}`);
      },
      navClick(link) {
        const idx = this.menus.findIndex((e) => e.link === link);
        if (this.menus[idx].child) {
          let selected = this.menus[idx];

          if (this.$refs[selected.link][0].classList.contains("collapsed")) {
            this.$refs[selected.link][0].classList.remove("collapsed");
          }
        }
      },
      async accept() {
        this.showUpgradeUI = false;
        await this.$workbox.messageSW({
          type: "SKIP_WAITING",
        });
      },
      logout() {
        this.$swal({
          title: "Confirm Logout",
          text: "Are you sure want to logout?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00D97E",
          cancelButtonColor: "#E63757",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push("/");
            let params = {
              client_id: process.env.VUE_APP_KEYCLOAK_CLIENT,
              refresh_token: localStorage.getItem("ugems-refreshToken"),
            };
            LOGOUT(params)
              .then((resp) => {
                localStorage.removeItem("ugems-refreshToken");
                localStorage.removeItem("ugems-accessToken");
                localStorage.removeItem("ugems-accessToken");
                localStorage.removeItem("company");
                window.location.reload();
              })
              .catch((resp) => {
                this.$swal(
                  "Error",
                  "Something Went Wrong, Please Try Again",
                  "error"
                );
              });
          }
        });
      },
      printName() {
        const stringUser = localStorage.getItem("ugems-tokenParsed");
        if (stringUser) {
          const user = JSON.parse(stringUser);
          const given_name = user.given_name;
          return given_name;
        }
        return "Unknown";
      },
      getProfile() {
        this.$store
          .dispatch("summary/getProfile")
          .then(() => {
            localStorage.removeItem('roles_id')
            localStorage.removeItem('company')
            localStorage.removeItem('fullname')
            localStorage.removeItem('company_name')
            localStorage.removeItem('clinic_roles')
            localStorage.removeItem('user_roles')
            localStorage.removeItem('clinic_id')
            localStorage.removeItem('clinic_feature')
            setTimeout(() => {
              let data = this.$store.getters["summary/profileData"];
              const user_role = data.user_roles;
              let data_user_role = user_role.map((e) => {
                return e.roles_id;
              });
              localStorage.setItem("roles_id", data_user_role);
              this.fullname = data.fullname;
              this.companyName = data.company
              localStorage.setItem("company", data.company_id);
              localStorage.setItem("fullname", data.fullname);
              localStorage.setItem("company_name", data.company);
              localStorage.setItem(
                "clinic_roles",
                JSON.stringify(data.clinic_roles)
              );
              localStorage.setItem("user_roles", JSON.stringify(data.user_roles));
              this.filterMenu();
  
              // const clinic_id = data.clinic_roles;
              // let data_clinic = clinic_id.map((e) => {
              //   return e.clinic_id;
              // });
              // const data_feature_clinic = clinic_id.map((e) => {
              //   return e.clinic_feature;
              // });
              // localStorage.setItem("clinic_id", data_clinic);
              // const clinic_feature = data.clinic_roles;
              // let data_featuress = [];
              // clinic_feature.map((e) => {
              //   let dat = e.clinic_feature;
              //   data_featuress = dat.map((item) => {
              //     return item;
              //   });
              // });
              // this.feature = data_featuress;
              // const finds = this.feature.findIndex(
              //   (e) => e.clinic_feature_id === "1"
              // );

              // if (finds > -1) {
              //   this.featureName = "Clinic";
              // } else if (finds < 0 && this.feature.length > 0) {
              //   this.featureName = "MCU";
              // } else if (finds < 0 && this.feature.length < 0) {
              //   this.featureName = "";
              // }
              // localStorage.setItem(
              //   "clinic_feature",
              //   JSON.stringify(data_featuress)
              // );
            }, 150);
          })
          .then(() => {
            this.fullname = localStorage.getItem("fullname");
            this.companyName = localStorage.getItem("company_name");
          });
      },
    },
  };
</script>

<style lang="scss">
  @import "~@/assets/scss/vendors/bootstrap-vue/index";

  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
  /* MY CUSTOM CSS */
  @import url("./assets/css/mycustom.css");

  .navbar-brand-img {
    max-width: 100% !important;
    max-height: 100% !important;
  }

  #loader {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    background-color: white;
    // background-color: #dfdfde;
    // display: flex !important;
    // justify-content: center !important;
    // align-items: center !important;
  }

  #loader>div {
    position: static !important;
  }

  .vgt-loading__content {
    background-color: #fad7dd !important;
    color: #e63757 !important;
  }
</style>