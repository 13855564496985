import axiosIns from '@/api/axios'

export const GET_CHAPTER_DATA = (args) => {
    return axiosIns.get(`/disease/chapter?${args}`)
}

export const ADD_CHAPTER = (args) => {
    return axiosIns.post(`/disease/chapter/create`, args)
}

export const DEL_CHAPTER = (id) => {
    return axiosIns.put(`/disease/chapter/delete?id=${id}`)
}

export const UPDATE_CHAPTER  = (args) => {
    return axiosIns.put(`/disease/chapter/update?id=${args.id}`, args)
}