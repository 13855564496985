<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <!-- / .row -->

      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("Activity") }}</h4>
          <!-- Button -->
          <b-button v-b-modal.modals variant="primary" @click="isAdd = true">
            <span class="fe fe-file-plus"></span>
            {{ $t("Add") }}
          </b-button>
          <b-button
            v-b-toggle.collapse-1
            variant="primary"
            style="margin-left: 7px"
          >
            <span class="fe fe-sliders"></span>
          </b-button>
        </div>
        <b-collapse id="collapse-1">
          <form @submit.prevent="sorting()">
            <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Activity Name")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="body.activity_name"
                  />
                </div>
              </div>
              <!-- <div class="col">
                                <div class="form-group">
                                    <label for="exampleInputEmail1" class="form-label">Chapter Name</label>
                                    <input type="text" class="form-control" v-model="body.user_agent" />
                                </div>
                            </div> -->
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label"
                    >Order</label
                  >
                  <v-select v-model="body.order" :options="['ASC', 'DESC']" />
                  <!-- <v-select v-model="body.order" :options="['ASC', 'DESC']" @input="sorting" /> -->
                </div>
              </div>
            </div>

            <div class="row mb-3" style="margin-right: 15px">
              <div class="col"></div>
              <div class="col-auto">
                <!-- Button -->
                <button type="submit" class="btn btn-danger lift">
                  {{ $t("Filter") }}
                </button>
              </div>
            </div>
            <!-- / .row -->
          </form>
        </b-collapse>
        <vue-good-table
          :line-numbers="true"
          :columns="columns"
          :rows="activity.data"
          :sort-options="{
            enabled: true,
          }"
          :total-rows="activity.totalItems"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All',
          }"
          style-class="vgt-table border-0 table"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange"
        >
          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <!-- Custom Rows -->
          <template slot="table-row" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.field == 'action'">
              <b-button
                class="no-wrap btn-pill"
                variant="warning"
                size="sm"
                @click="updateData(props.row)"
              >
                <span class="fe fe-edit"></span>
              </b-button>
              <b-button
                class="no-wrap ml-2"
                variant="danger"
                style="margin-left: 5px"
                size="sm"
                @click="deleteData(props.row.id)"
              >
                <span class="fe fe-trash-2"></span>
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </div>

      <!-- Modal -->
      <b-modal id="modals" hide-footer title="Activity Form">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="form-group">
            <label>{{ $t("Activity Name") }}</label>
            <input
              type="text"
              v-model="bodyAdd.activity_name"
              class="form-control"
              placeholder="Activity Name"
              v-validate="'required'"
              name="Activity Name"
            />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Activity Name')"
              >{{ errors.first("Activity Name") }}</span
            >
          </div>
          <div class="form-group">
            <label>{{ $t("Data Flag") }}</label>
            <v-select
              v-model="bodyAdd.activity_flag"
              :options="['Daily', 'Monthly', 'Yearly']"
              v-validate="'required'"
              name="Data Flag"
            ></v-select>
            <span
              class="text-sm text-danger"
              v-show="errors.has('Data Flag')"
              >{{ errors.first("Data Flag") }}</span
            >
          </div>
          <div class="form-group">
            <label>Order</label>
            <input
              type="number"
              v-model="bodyAdd.activity_order"
              class="form-control"
              placeholder="Order"
              v-validate="'required'"
              name="Order"
            />
            <span class="text-sm text-danger" v-show="errors.has('Order')">{{
              errors.first("Order")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Description") }}</label>
            <textarea
              type="text"
              v-model="bodyAdd.description"
              class="form-control"
              placeholder="Description"
            />
          </div>
          <div class="form-group">
            <label>{{ $t("Type") }}</label>
            <v-select
              v-model="bodyAdd.type_data"
              :options="['Integer', 'String', 'Text Area']"
              v-validate="'required'"
              name="Type"
            ></v-select>
            <span class="text-sm text-danger" v-show="errors.has('Type')">{{
              errors.first("Type")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Mandatory") }}</label>
            <v-select
              v-model="bodyAdd.is_mandatory"
              :options="mandatory"
              :label="label"
              :reduce="(e) => e.code"
              v-validate="'required'"
              name="Mandatory"
            ></v-select>
            <span
              class="text-sm text-danger"
              v-show="errors.has('Mandatory')"
              >{{ errors.first("Mandatory") }}</span
            >
          </div>
          <div class="form-group">
            <b-form-radio-group
              v-model="bodyAdd.manual_input"
              :options="options"
              class="mb-3"
              value-field="value"
              text-field="name"
              disabled-field="notEnabled"
            >
            </b-form-radio-group>
          </div>
          <div v-if="bodyAdd.manual_input === 1">
            <div class="row g-3">
              <div class="col">
                <label>{{ $t("Formula / Rumus") }}</label>
                <button
                  type="button"
                  style="margin-left: auto; float: right"
                  class="btn btn-primary"
                  @click="addVariable"
                >
                  <span class="fe fe-plus"></span> {{ $t("Add Variable") }}
                </button>
              </div>
            </div>
            <div
              class="row g-3"
              v-for="(item, index) in bodyAdd.activity_formula"
              :key="index"
            >
              <div class="form-group col-6">
                <label>{{ $t('Data Type') }}</label>
                <v-select
                  v-model="item.type"
                  :reduce="(option) => option.key"
                  v-if="index % 2 == 0"
                  :options="[
                    { label: 'OH ACTIVITY', key: 'activity' },
                    { label: 'NUMBER', key: 'const' },
                  ]"
                  :name="`variables[${index}][type]`"
                  v-validate="'required'"
                />
                <v-select
                  v-model="item.type"
                  :reduce="(option) => option.key"
                  v-else
                  :options="[{ label: 'OPERATOR', key: 'opr' }]"
                  :name="`variables[${index}][type]`"
                  v-validate="'required'"
                />
                <span class="text-sm text-danger" v-show="errors.has('Type')">{{
                  errors.first("Type")
                }}</span>
              </div>
              <div class="form-group col-5">
                <label>{{ $t('Value') }}</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Value"
                  v-model="item.value"
                  v-validate="'required'"
                  name="Value"
                  v-if="item.type === 'const'"
                />
                <v-select
                  v-if="item.type === 'data'"
                  v-model="item.value"
                  :options="ohData.data"
                  label="data_name"
                  :reduce="(e) => e.id"
                  v-validate="'required'"
                  name="Data Name"
                />
                <v-select
                  v-if="item.type === 'activity'"
                  v-model="item.value"
                  :options="activity.data"
                  label="activity_name"
                  :reduce="(e) => e.id"
                  v-validate="'required'"
                  name="Activity Name"
                />
                <v-select
                  v-model="item.value"
                  :reduce="(option) => option.id"
                  :options="[
                    { label: '+ (Tambah)', id: '+' },
                    { label: '- (Kurang)', id: '-' },
                    { label: '* (Kali)', id: '*' },
                    { label: '/ (Bagi)', id: '/' },
                  ]"
                  v-if="item.type === 'opr'"
                  v-validate="'required'"
                  :name="`variables[${index}][value]`"
                />
                <span class="text-sm text-danger" v-show="errors.has('Value')">{{
                  errors.first("Value")
                }}</span>
              </div>
              <!-- <div class="form-group col-2" v-if="item.index"> -->
              <div class="form-group col-1" v-if="index >= 0">
                <button
                  type="button"
                  style="margin-left: auto"
                  class="btn btn-primary mt-4"
                  @click="removeDrugs(index)"
                >
                  <span class="fe fe-trash"></span>
                </button>
              </div>
            </div>
          </div>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="submit"
            v-if="isLoading == false"
          >
            {{ $t("Save") }}
          </button>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="button"
            disabled
            v-else
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </button>
        </form>

        <form @submit.prevent="saveUpdate()" v-if="isAdd === false">
          <div class="form-group">
            <label for="Activity Name">Activity Name</label>
            <input
              type="text"
              v-model="bodyUpdate.activity_name"
              class="form-control"
              placeholder="Activity Name"
              v-validate="'required'"
              name="Activity Name"
            />
          </div>
          <span
            class="text-sm text-danger"
            v-show="errors.has('Activity Name')"
            >{{ errors.first("Activity Name") }}</span
          >
          <div class="form-group">
            <!-- <label>{{ $t('Data Flag') }}</label> -->
            <label>Data Flag</label>
            <v-select
              v-model="bodyUpdate.activity_flag"
              :options="['Daily', 'Monthly', 'Yearly']"
              v-validate="'required'"
              name="Data Flag"
            ></v-select>
            <span
              class="text-sm text-danger"
              v-show="errors.has('Data Flag')"
              >{{ errors.first("Data Flag") }}</span
            >
          </div>
          <div class="form-group">
            <label>Order</label>
            <input
              type="number"
              v-model="bodyUpdate.activity_order"
              class="form-control"
              placeholder="Order"
              v-validate="'required'"
              name="Order"
            />
            <span class="text-sm text-danger" v-show="errors.has('Order')">{{
              errors.first("Order")
            }}</span>
          </div>
          <div class="form-group">
            <!-- <label>{{ $('Description') }}</label> -->
            <label>Description</label>
            <textarea
              type="text"
              v-model="bodyUpdate.description"
              class="form-control"
              placeholder="Description"
            />
          </div>
          <div class="form-group">
            <!-- <label>{{ $t('Type') }}</label> -->
            <label>Type</label>
            <v-select
              v-model="bodyUpdate.type_data"
              :options="['Integer', 'String', 'Text Area']"
              v-validate="'required'"
              name="Type"
            ></v-select>
            <span class="text-sm text-danger" v-show="errors.has('Type')">{{
              errors.first("Type")
            }}</span>
          </div>
          <div class="form-group">
            <label>Mandatory</label>
            <v-select
              v-model="bodyUpdate.is_mandatory"
              :options="mandatory"
              :label="label"
              :reduce="(e) => e.code"
              v-validate="'required'"
              name="Mandatory"
            ></v-select>
            <span
              class="text-sm text-danger"
              v-show="errors.has('Mandatory')"
              >{{ errors.first("Mandatory") }}</span
            >
          </div>
          <div class="form-group">
            <b-form-radio-group
              v-model="bodyUpdate.manual_input"
              :options="options"
              class="mb-3"
              value-field="value"
              text-field="name"
              disabled-field="notEnabled"
            >
            </b-form-radio-group>
          </div>
          <div v-if="bodyUpdate.manual_input === 1">
            <div class="row g-3">
              <div class="col">
                <label for="">{{ $t("Formula / Rumus") }}</label>
                <button
                  type="button"
                  style="margin-left: auto; float: right"
                  class="btn btn-primary"
                  @click="updateVariable"
                >
                  <span class="fe fe-plus"></span> {{ $t("Add Variable") }}
                </button>
              </div>
            </div>
            <div
              class="row g-3"
              v-for="(item, index) in bodyUpdate.activity_formula"
              :key="index"
            >
                <div class="form-group col-6">
                <label>{{ $t('Data Type') }}</label>
                <v-select
                  v-model="item.type"
                  :reduce="(option) => option.key"
                  v-if="index % 2 == 0"
                  :options="[
                    { label: 'OH ACTIVITY', key: 'activity' },
                    { label: 'NUMBER', key: 'const' },
                  ]"
                  :name="`variables[${index}][type]`"
                  v-validate="'required'"
                />
                <v-select
                  v-model="item.type"
                  :reduce="(option) => option.key"
                  v-else
                  :options="[{ label: 'OPERATOR', key: 'opr' }]"
                  :name="`variables[${index}][type]`"
                  v-validate="'required'"
                />
                <span class="text-sm text-danger" v-show="errors.has('Type')">{{
                  errors.first("Type")
                }}</span>
              </div>
              <div class="form-group col-5">
                <label>{{ $t('Value') }}</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Value"
                  v-model="item.value"
                  v-validate="'required'"
                  name="Value"
                  v-if="item.type === 'const'"
                />
                <v-select
                  v-if="item.type === 'activity'"
                  v-model="item.value"
                  :options="activity.data"
                  label="activity_name"
                  :reduce="(e) => e.id"
                  v-validate="'required'"
                  name="Activity Name"
                />
                <v-select
                  v-model="item.value"
                  :reduce="(option) => option.id"
                  :options="[
                    { label: '+ (Tambah)', id: '+' },
                    { label: '- (Kurang)', id: '-' },
                    { label: '* (Kali)', id: '*' },
                    { label: '/ (Bagi)', id: '/' },
                  ]"
                  v-if="item.type === 'opr'"
                  v-validate="'required'"
                  :name="`variables[${index}][value]`"
                />
                <span class="text-sm text-danger" v-show="errors.has('Value')">{{
                  errors.first("Value")
                }}</span>
              </div>
              <div class="form-group col-1" v-if="index >= 0">
                <button
                  type="button"
                  style="margin-left: auto"
                  class="btn btn-primary mt-4"
                  @click="removeUpdate(index)"
                >
                  <span class="fe fe-trash"></span>
                </button>
              </div>
            </div>
          </div>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="submit"
            v-if="isLoading == false"
          >
            Save
          </button>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="button"
            disabled
            v-else
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </button>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "activity",
  computed: {
    activity() {
      return this.$store.getters["activity/ActivityData"]
        ? this.$store.getters["activity/ActivityData"]
        : [];
    },
  },
  data() {
    return {
      body: {
        page: "",
        limit: "",
        activity_name: "",
        order: "",
      },
      bodyAdd: {
        activity_name: "",
        activity_flag: "",
        activity_order: "",
        description: "",
        type_data: "",
        is_mandatory: "",
        manual_input: "",
        activity_formula: [
          {
            type: "",
            value: "",
          },
        ],
      },
      options: [
        { name: "Manual Entry", value: 0 },
        { name: "Automatic Entry By Formula", value: 1 },
      ],
      bodyUpdate: {
        id: "",
        activity_name: "",
        activity_flag: "",
        activity_order: "",
        description: "",
        type_data: "",
        is_mandatory: "",
        manual_input: "",
        activity_formula: [
          {
            type: "",
            value: "",
          },
        ],
      },
      // activity_name: '',
      // activity_names: '',
      // id: '',
      isAdd: true,
      isLoading: false,
      selectData: "",
      mandatory: [
        {
          label: "YES",
          code: true,
        },
        {
          label: "NO",
          code: false,
        },
      ],
      columns: [
        {
          label: "ACTIVITY",
          field: "activity_name",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },

        {
          label: "INPUT PERIOD",
          field: "activity_flag",
          thClass: "table-header no-wrap text-muted  font-weight-bold",
          tdClass: "no-wrap text-center color-secondary align-middle",
        },
        {
          label: "ORDER",
          field: "activity_order",
          thClass: "table-header no-wrap text-muted  font-weight-bold",
          tdClass: "no-wrap text-center color-secondary align-middle",
        },
        {
          label: "DESC",
          field: "description",
          thClass: "table-header no-wrap text-muted  font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "TYPE",
          field: "type_data",
          thClass: "table-header no-wrap text-muted  font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "MANDATORY",
          field: "is_mandatory",
          thClass: "table-header no-wrap text-muted  font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
      ],
    };
  },
  mounted() {
    // if (this.activity && this.activity.length < 1) {
    this.getActivity();
    // }
  },
  methods: {
    addVariable() {
      const blank_drugs = {
        type: "",
        value: "",
      };
      this.bodyAdd.activity_formula.push(blank_drugs);
    },
    removeDrugs(index) {
      this.bodyAdd.activity_formula.splice(index, 1);
    },
    updateVariable() {
      const blank_drugs = {
        type: "",
        value: "",
      };
      this.bodyUpdate.activity_formula.push(blank_drugs);
    },
    removeUpdate(index) {
      this.bodyUpdate.activity_formula.splice(index, 1);
    },
    //action
    updateData(props) {
      this.$bvModal.show("modals");
      this.isAdd = false;
      this.bodyUpdate.id = props.id;
      this.bodyUpdate.activity_name = props.activity_name;
      this.bodyUpdate.activity_flag = props.activity_flag;
      this.bodyUpdate.activity_order = props.activity_order;
      this.bodyUpdate.description = props.description;
      this.bodyUpdate.type_data = props.type_data;
      this.bodyUpdate.is_mandatory = props.is_mandatory;
      this.bodyUpdate.manual_input = props.manual_input;
      if (props.manual_input == 0) {
        this.bodyUpdate.activity_formula = [
          {
            type: "",
            value: "",
          },
        ]
      } else {
        this.bodyUpdate.activity_formula = JSON.parse(props.activity_formula);
      }
    },

    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.isLoading = true;
          this.$store
            .dispatch("activity/addActivity", this.bodyAdd)
            .then((resp) => {
              this.$swal("Saved", "Your input has been saved", "success").then(
                (resp) => {
                  this.isLoading = false;
                  this.bodyAdd.activity_name = "";
                  this.bodyAdd.activity_flag = "";
                  this.bodyAdd.activity_order = "";
                  this.bodyAdd.description = "";
                  this.bodyAdd.type_data = "";
                  this.bodyAdd.is_mandatory = "";
                  this.bodyAdd.manual_input = "";
                  this.bodyAdd.activity_formula = [];
                  this.getActivity();
                  this.$bvModal.hide("modals");
                }
              );
            });
        } catch (resp) {
          this.$swal("Error", "Error while add data", "error");
        }
      });
    },
    customStore() {
      this.$store.dispatch("activity/getActivity", this.activity);
    },
    saveUpdate() {
      this.isLoading = true;
      this.$store
        .dispatch("activity/updateActivity", this.bodyUpdate)
        .then((resp) => {
          this.$swal("Saved", "Your input has been saved", "success").then(
            (resp) => {
              this.isLoading = false;
              this.getActivity();
              this.$bvModal.hide("modals");
            }
          );
        })
        .catch((resp) => {
          this.$swal("Error", "Error while add data", "error");
        });
    },

    deleteData(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("activity/delActivity", id)
            .then((resp) => {
              this.getActivity();
              this.$swal("Deleted!", "Your file has been deleted.", "success");
            })
            .catch((resp) => {
              this.$swal("Error", "Error while delete data", "error");
            });
        }
      });
    },

    // api hit
    getActivity() {
      this.$store.dispatch("activity/getActivity", this.body);
    },

    // filter
    filterPage() {
      this.getActivity();
    },

    sorting() {
      this.getActivity();
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;
      // this.getActivity();
    },
  },
};
</script>
