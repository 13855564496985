<template>
  <!-- HEADER -->
  <div class="header">
    <!-- Body -->
    <!-- / .row -->

    <!-- Filter -->
    <!-- end of filter -->
    <!-- Table Top Disease  -->
    <div class="">
      <div class="card-header">
        <!-- Title -->
        <h4 class="card-header-title"></h4>
        <!-- Button -->
        <b-button
          v-b-toggle.collapse-1
          variant="primary"
          style="margin-left: 7px">
          <span class="fe fe-sliders"></span>
        </b-button>
      </div>
      <b-collapse id="collapse-1">
        <form @submit.prevent="sorting()">
          <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
            <div class="col">
              <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">{{
                  $t("Name")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="body.hazard_name" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">Order</label>
                <v-select
                  v-model="body.order"
                  :options="['ASC', 'DESC']"
                  @input="sorting" />
              </div>
            </div>
          </div>

          <div class="row mb-3" style="margin-right: 15px">
            <div class="col"></div>
            <div class="col-auto">
              <!-- Button -->
              <button type="submit" class="btn btn-danger lift">Filter</button>
            </div>
          </div>
          <!-- / .row -->
        </form>
      </b-collapse>
      <vue-good-table
        :line-numbers="true"
        :columns="columns_disease"
        :rows="chemical.data"
        :sort-options="{
          enabled: true
        }"
        :total-rows="chemical.totalItems"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          position: 'bottom',
          perPage: 10,
          perPageDropdown: [10, 50, 100],
          dropdownAllowAll: false,
          setCurrentPage: 1,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page',
          allLabel: 'All'
        }"
        style-class="vgt-table border-0 table"
        @on-page-change="onPageChange"
        @on-per-page-change="onPageChange">
        <!-- Loading -->
        <div slot="emptystate" class="text-center font-size-14">
          No data available
        </div>
        <!-- Custom Label  -->
        <template slot="table-column" slot-scope="props" style="text-center">
          <span v-if="props.column.label == 'Disease'">
            <center>10 Penyakit Banyak Bulan Ini</center>
          </span>
          <span v-if="props.column.label == 'JAN'">
            <center>JAN</center>
          </span>
          <span v-if="props.column.label == 'FEB'">
            <center>FEB</center>
          </span>
          <span v-if="props.column.label == 'MAR'">
            <center>MAR</center>
          </span>
          <span v-if="props.column.label == 'APR'">
            <center>APR</center>
          </span>
          <span v-if="props.column.label == 'MAY'">
            <center>MAY</center>
          </span>
          <span v-if="props.column.label == 'JUN'">
            <center>JUN</center>
          </span>
          <span v-if="props.column.label == 'JUL'">
            <center>JUL</center>
          </span>
          <span v-if="props.column.label == 'AUG'">
            <center>AUG</center>
          </span>
          <span v-if="props.column.label == 'SEP'">
            <center>SEP</center>
          </span>
          <span v-if="props.column.label == 'OCT'">
            <center>OCT</center>
          </span>
          <span v-if="props.column.label == 'NOV'">
            <center>NOV</center>
          </span>
          <span v-if="props.column.label == 'DES'">
            <center>DES</center>
          </span>
          <span v-if="props.column.label == 'Jumlah'"> Jumlah </span>
        </template>
        <!-- Custom Rows -->
        <template slot="table-row" slot-scope="props">
          <!-- Table Action -->
          <span v-if="props.column.field == 'Jumlah'">
            <p>5000</p>
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "Disease",
  computed: {
    chemical() {
      return this.$store.getters["chemical/ChemicalData"]
        ? this.$store.getters["chemical/ChemicalData"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        limit: 100,
        order: "",
        hazard_name: ""
      },
      hazard_name: "",
      hazard_names: "",
      id: "",
      columns_disease: [
        {
          label: "Disease",
          field: "hazard_name",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "JAN",
          field: "hazard_name",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "FEB",
          field: "hazard_name",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "MAR",
          field: "hazard_name",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "APR",
          field: "hazard_name",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "MAY",
          field: "hazard_name",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "JUN",
          field: "hazard_name",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "JUL",
          field: "hazard_name",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "AUG",
          field: "hazard_name",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "SEP",
          field: "hazard_name",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "NOV",
          field: "hazard_name",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "DES",
          field: "hazard_name",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Jumlah",
          field: "Jumlah",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ]
    };
  },
  mounted() {
    if (this.chemical && this.chemical.length < 1) {
      this.getChemical();
    }
  },
  methods: {
    // api hit
    getChemical() {
      this.$store.dispatch("chemical/getChemical", this.body);
    },

    // filter
    filterPage() {
      this.getChemical();
    },

    sorting() {
      this.getChemical();
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;

      if (
        parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
        1000
      ) {
        this.getChemical();
      }
    }
  }
};
</script>
