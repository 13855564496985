<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-4">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item fw-bolder">{{ $t("Drug Test") }}</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <!-- / .row -->

      <!-- Table -->
      <div class="row align-items-center justify-content-between">
        <div :class="`card ${load === true ? '' : 'd-none'}`">
          <div class="card-body text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <div :class="`card p-0 ${load === true ? 'd-none' : ''}`">
          <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
              {{ title() }} {{ $t("Drug Test") }}
            </h4>
            <!-- Button -->
            <div style="margin-left: 15px">
              <b-button v-b-modal.importFile :disabled="validationFeature(clinic_features)" variant="primary"
                class="ml-2" v-if="title() === 'MCU'">
                <span class="fe fe-file-plus"></span>
                Import File
              </b-button>
              <b-button v-b-modal.modal-3 variant="primary" @click="qrModal()" class="ml-2"
                :disabled="validationFeature(clinic_features)">
                <span class="fe fe-file-plus"></span>
                QR Code
              </b-button>
              <b-button v-b-modal.modal-5 @click="manualInputs()" variant="primary"
                :disabled="validationFeature(clinic_features)" class="ml-2">
                <span class="fe fe-file-plus"></span>
                {{ $t("Manual Input") }}
              </b-button>
              <!-- <b-button
                v-b-modal.modal-outside
                @click="manualInputs()"
                variant="primary"
                :disabled="validationFeature(clinic_features)"
                class="ml-2">
                <span class="fe fe-file-plus"></span>
                {{ $t("Unregistered Employee Input") }}
              </b-button> -->
              <b-dropdown class="ml-2" variant="primary">
                <template #button-content>
                  <span class="fe fe-download"></span> Export
                </template>
                <b-dropdown-item @click="downloadCompany()">By Company</b-dropdown-item>
                <b-dropdown-item @click="downloadClinic()">By Clinic</b-dropdown-item>
              </b-dropdown>
              <!-- <b-button @click="exporting()" variant="primary" class="ml-2">
                <span class="fe fe-download"></span>
                {{ $t("Export") }}
              </b-button> -->
              <!-- <b-button v-b-modal.modal-export variant="primary" class="ml-2">
                <span class="fe fe-download"></span>
                {{ $t("Export") }}
              </b-button> -->
            </div>
            <div style="margin-right: 15px"></div>
          </div>
          <!-- <b-collapse id="collapse-1"> -->
          <form @submit.prevent="filterPage()">
            <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
              <div class="col" v-if="filterCompany == 1">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">Company</label>

                  <v-select v-model="body.company_id" :options="selectCompanyOption()" label="company_name"
                    :reduce="(e) => e.id" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Employee Name")
                  }}</label>
                  <input type="text" class="form-control" v-model="body.employee_name" />
                </div>
              </div>
            </div>
            <div class="row mt-2" style="margin-left: 15px; margin-right: 15px">
              <div class="col">
                <div class="form-group">
                  <label>Registration Date From</label>
                  <input type="date" class="form-control" placeholder="Created At" v-model="body.start_date"
                    name="Pilih Tanggal" />
                  <!-- <v-select v-model="body.order" :options="['ASC', 'DESC']" @input="sorting" /> -->
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Registration Date To</label>
                  <input type="date" class="form-control" placeholder="Created At" v-model="body.end_date"
                    name="Pilih Tanggal" />
                  <!-- <v-select v-model="body.order" :options="['ASC', 'DESC']" @input="sorting" /> -->
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Test Date From</label>
                  <input type="date" class="form-control" placeholder="Pilih Tanggal Tes"
                    v-model="body.start_screening_date" name="Pilih Tanggal" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Test Date To</label>
                  <input type="date" class="form-control" placeholder="Pilih Tanggal Tes"
                    v-model="body.end_screening_date" name="Pilih Tanggal" />
                </div>
                <!-- <v-select v-model="body.order" :options="['ASC', 'DESC']" @input="sorting" /> -->
              </div>
            </div>

            <div class="row mb-3" style="margin-right: 15px">
              <div class="col"></div>
              <div class="col-auto">
                <!-- Button -->
                <button type="submit" class="btn btn-danger lift">
                  {{ $t("Filter") }}
                </button>
              </div>
            </div>
            <!-- / .row -->
          </form>
          <!-- </b-collapse> -->
          <vue-good-table :line-numbers="true" :columns="columns" :rows="dataGet.data" :total-rows="dataGet.totalItems"
            :sort-options="{
              enabled: true
            }" :pagination-options="{
              enabled: true,
              mode: 'pages',
              position: 'bottom',
              perPage: body.limit,
              perPageDropdown: [10, 50, 100],
              dropdownAllowAll: false,
              setCurrentPage: body.page,
              nextLabel: 'next',
              prevLabel: 'prev',
              rowsPerPageLabel: 'Rows per page',
              ofLabel: 'of',
              pageLabel: 'Page',
              allLabel: 'All'
            }" style-class="vgt-table border-0 table" @on-page-change="onPageChange" @on-per-page-change="onPageChange"
            @on-sort-change="onSortChange">
            <!-- Loading -->
            <div slot="emptystate" class="text-center font-size-14">
              No data available
            </div>
            <!-- Custom Rows -->
            <template slot="table-column" slot-scope="props">
              <!-- Table Action -->
              <span v-if="props.column.label == 'NAMA KARYAWAN'">
                {{ $t("Employee Name") }}
              </span>
              <span v-if="props.column.label == 'PERUSAHAAN'">
                {{ $t("Company") }}
              </span>
              <span v-if="props.column.label == 'DEPARTMENT'">
                {{ $t("Department") }}
              </span>
              <span v-if="props.column.label == 'STATUS'">
                {{ $t("Status") }}
              </span>
              <span v-if="props.column.label == 'CLINIC'">
                {{ $t("Clinic Location") }}
              </span>
              <span v-if="props.column.label == 'PENGGUNAAN NARKOBA'">
                {{ $t("RESULT TEST") }}
              </span>
              <span v-if="props.column.label == 'JENIS KUNJUNGAN'">
                {{ $t("Type visit") }}
              </span>
              <span v-if="props.column.label == 'WAKTU REGIS'">
                {{ $t("Registration Date") }}
              </span>
              <span v-if="props.column.label == 'WAKTU TEST'">
                {{ $t("Test Date") }}
              </span>
              <span v-if="props.column.label == 'ACTION'">
                {{ $t("Action") }}
              </span>
            </template>
            <template slot="table-row" slot-scope="props">
              <!-- Table Action -->
              <span v-if="props.column.field == 'action'">
                <div v-if="validationFeatureTable(clinic_features)">
                  <b-button class="no-wrap btn-pill" variant="success" size="sm"
                    @click.prevent="detailPatient(props.row)" v-if="props.row.status == 'DONE' && title() === 'Clinic'">
                    <span class="fe fe-activity"></span>
                  </b-button>
                  <b-button class="no-wrap btn-pill" variant="success" size="sm"
                    @click.prevent="detailPatientMCU(props.row)" v-if="props.row.status == 'DONE' && title() === 'MCU'">
                    <span class="fe fe-activity"></span>
                  </b-button>
                  <b-button class="no-wrap btn-pill" variant="info" size="sm" @click.prevent="diagnoseData(props.row)"
                    v-if="props.row.status == 'REGISTERED'">
                    <span class="fe fe-activity"></span>
                  </b-button>
                  <b-button class="no-wrap btn-pill ml-2" variant="secondary" size="sm"
                    @click.prevent="seeHistory(props.row)" v-if="
                      props.row.status == 'REGISTERED' && title() === 'Clinic'
                    ">
                    <span class="fe fe-trending-up"></span>
                  </b-button>
                  <b-button class="no-wrap ml-2" variant="danger" style="margin-left: 5px" size="sm"
                    @click="deleteData(props.row.register_id)" v-if="props.row.status == 'REGISTERED'">
                    <span class="fe fe-trash"></span>
                  </b-button>
                </div>
                <!-- <div v-if="clinic_features[0].clinic_feature_id === '2'">

            </div> -->
              </span>

              <span v-else-if="props.column.field == 'register_date'">
                {{ fixTime(props.row.register_date) }}
              </span>
              <span v-else-if="props.column.field == 'positif_drugs'">
                <!-- {{ props.row.positif_drugs }} -->
                <span :class="`badge bg-${
                    props.row.positif_drugs == 'Positive' ? 'danger' : 'success'
                  }-soft`">{{ props.row.positif_drugs }}</span>
              </span>
              <span v-else-if="props.column.field == 'screening_date'">
                {{ fixTime(props.row.screening_date) }}
              </span>
            </template>
          </vue-good-table>
        </div>

        <!-- Modal -->
        <b-modal id="modal-1" size="xl" hide-footer title="Drug Test Results Form" @hide="onHide">
          <b-tabs content-class="">
            <b-tab title="Manual Input" active v-if="type_visit === 'MCU'">
              <form>
                <button class="mt-2 btn btn-primary" @click.prevent="newRecord">
                  New Record
                </button>
                <div class="card mt-3" v-for="(item, index) in mcuData.mcu_record" :key="index">
                  <div class="card-header">
                    <!-- Title -->
                    <h4 class="card-header-title">Record #{{ index + 1 }}</h4>

                    <!-- Button -->
                    <button class="btn btn-primary" @click.prevent="removeRecord(index)">
                      <span class="fe fe-x"></span>
                    </button>
                  </div>
                  <div class="card-body">
                    <div class="row g-3">
                      <div class="form-group col">
                        <label>Category</label>
                        <span style="color: red">*</span>
                        <v-select v-model="item.category_name" :reduce="(option) => option.category_name"
                          :options="categoryTest.data" label="category_name" v-validate="'required'" name="Category" />
                        <span class="text-sm text-danger"
                          v-show="errors.has('Category')">{{ errors.first("Category") }}</span>
                      </div>
                      <div class="form-group col">
                        <label>Group</label>
                        <span style="color: red">*</span>
                        <v-select v-model="item.group_name" :reduce="(option) => option.group_name"
                          :options="groupTest.data" label="group_name" v-validate="'required'" name="Group" />
                        <span class="text-sm text-danger"
                          v-show="errors.has('Group')">{{ errors.first("Group") }}</span>
                      </div>
                    </div>
                    <div class="row g-3">
                      <div class="form-group col">
                        <label>Title</label>
                        <span style="color: red">*</span>
                        <v-select v-model="item.check_title" :reduce="(option) => option.item_name"
                          :options="itemTest.data" label="item_name" v-validate="'required'" name="Title" />
                        <span class="text-sm text-danger"
                          v-show="errors.has('Title')">{{ errors.first("Title") }}</span>
                      </div>
                      <div class="form-group col">
                        <label>Value</label>
                        <span style="color: red">*</span>
                        <input type="text" class="form-control" placeholder="Value" v-model="item.check_value"
                          name="Value" v-validate="'required'" />
                        <span class="text-sm text-danger"
                          v-show="errors.has('Value')">{{ errors.first("Value") }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <button style="margin-left: auto; float: right" class="btn btn-primary mt-4" type="submit"
                  @click.prevent="saveRecord()">
                  Save
                </button>
              </form>
            </b-tab>
            <div v-if="type_visit === 'Test Narkoba'">
              <form>
                <div class="row g-3 mt-2">
                  <div class="form-group col">
                    <label>{{ $t("Doctor Name") }}</label>
                    <span style="color: red">*</span>
                    <input type="text" class="form-control" placeholder="Doctor Name" v-model="dataResults.doctor_name"
                      name="Doctor" v-validate="'required'" />
                    <span class="text-sm text-danger" v-show="errors.has('Doctor')">{{ errors.first("Doctor") }}</span>
                  </div>
                </div>
                <div class="row g-3 mt-2">
                  <div class="form-group col">
                    <label>{{ $t("Amphetamine (AMP)") }}</label>
                    <span style="color: red">*</span>
                    <b-form-radio-group v-model="dataResults.amphetamine" :options="optionsResult" class="mb-3"
                      value-field="value" text-field="name" name="Amphetamine" v-validate="'required'">
                      <br>
                      <span class="text-sm text-danger"
                        v-show="errors.has('Amphetamine')">{{ errors.first("Amphetamine") }}</span>
                    </b-form-radio-group>
                  </div>
                  <div class="form-group col">
                    <label>{{ $t("Metamphitamine (MET)") }}</label>
                    <span style="color: red">*</span>
                    <b-form-radio-group v-model="dataResults.metamphitamine" :options="optionsResult" class="mb-3"
                      value-field="value" text-field="name" name="Metamphitamine" v-validate="'required'">
                      <br>

                      <span class="text-sm text-danger"
                        v-show="errors.has('Metamphitamine')">{{ errors.first("Metamphitamine") }}</span>
                    </b-form-radio-group>
                  </div>
                  <div class="form-group col">
                    <label>{{ $t("Cocaine (COC)") }}</label>
                    <span style="color: red">*</span>
                    <b-form-radio-group v-model="dataResults.cocaine" :options="optionsResult" class="mb-3"
                      value-field="value" text-field="name" name="Cocaine" v-validate="'required'">
                      <br>

                      <span class="text-sm text-danger"
                        v-show="errors.has('Cocaine')">{{ errors.first("Cocaine") }}</span>
                    </b-form-radio-group>
                  </div>
                  <div class="form-group col">
                    <label>{{ $t("Ganja (THC)") }}</label>
                    <span style="color: red">*</span>
                    <b-form-radio-group v-model="dataResults.ganja" :options="optionsResult" class="mb-3"
                      value-field="value" text-field="name" name="Ganja" v-validate="'required'">
                      <br>

                      <span class="text-sm text-danger" v-show="errors.has('Ganja')">{{ errors.first("Ganja") }}</span>
                    </b-form-radio-group>
                  </div>
                  <div class="form-group col">
                    <label>{{ $t("Benzoat") }}</label>
                    <span style="color: red">*</span>
                    <b-form-radio-group v-model="dataResults.benzoat" :options="optionsResult" class="mb-3"
                      value-field="value" text-field="name" name="Benzoat" v-validate="'required'">
                      <br>

                      <span class="text-sm text-danger"
                        v-show="errors.has('Benzoat')">{{ errors.first("Benzoat") }}</span>
                    </b-form-radio-group>
                  </div>
                  <div class="form-group col">
                    <label>{{ $t("Morphine (MOP)") }}</label>
                    <span style="color: red">*</span>
                    <b-form-radio-group v-model="dataResults.morphine" :options="optionsResult" class="mb-3"
                      value-field="value" text-field="name" name="Morphine" v-validate="'required'">
                      <br>

                      <span class="text-sm text-danger"
                        v-show="errors.has('Morphine')">{{ errors.first("Morphine") }}</span>
                    </b-form-radio-group>
                  </div>
                </div>
                <div class="row g-3 mt-2">
                  <div class="form-group col">
                    <label>Note</label>
                    <textarea type="text" class="form-control" placeholder="Note" v-model="dataResults.note" />
                    </div>
                </div>
                <div class="row g-3 mt-2">
                  <div class="form-group">
                    <b-form-checkbox
                      v-model="dataResults.need_referral"
                      class="mb-3"
                      value="true"
                      >Need Referral</b-form-checkbox
                    >
                  </div>
                </div>
                <div>
                  <button
                    v-if="isLoading === false"
                    style="margin-left: auto; float: right"
                    class="btn btn-primary mt-4 ml-2"
                    type="submit"
                    :disabled="statuss == 'DONE' ? true : false"
                    @click.prevent="storeResult()">
                    Save
                  </button>
                  <button
                    v-if="isLoading == true"
                    style="margin-left: auto; float: right"
                    class="mt-4 btn btn-primary"
                    type="button"
                    disabled>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                    Loading...
                  </button>
                </div>
              </form>
            </div>
          </b-tabs>
        </b-modal>

        <b-modal id="addDisease" hide-footer title="Add Disease">
          <form @submit.prevent="saveAddDisease()">
            <div class="form-group">
              <label>{{ $t("Chapter") }}</label>
              <v-select
                v-model="addDisease.chapter_id"
                :reduce="(option) => option.id"
                :disabled="statuss == 'DONE' ? true : false"
                :options="chapter.data"
                @input="chapterSelectToBlock"
                :filterable="false"
                label="chapter_name"
                v-validate="'required'"
                name="Type Of Disease">
              </v-select>
              <span
                class="text-sm text-danger"
                v-show="errors.has('Chapter Name')"
                >{{ errors.first("Chapter Name") }}</span
              >
            </div>
            <div class="form-group" v-if="addDisease.chapter_id > 0">
              <label>{{ $t("Block") }}</label>
              <v-select
                v-model="addDisease.block_id"
                :options="dataBlock"
                @input="blockSelectToCatgory"
                label="block_name"
                :reduce="(e) => e.id"
                v-validate="'required'"
                name="Block Name" />
              <span
                class="text-sm text-danger"
                v-show="errors.has('Block Name')"
                >{{ errors.first("Block Name") }}</span
              >
            </div>
            <div class="form-group" v-if="addDisease.block_id > 0">
              <label>{{ $t("Category") }}</label>
              <v-select
                v-model="addDisease.category_id"
                :options="dataCategories"
                label="category_name"
                @input="categorySelectToDisease"
                :reduce="(e) => e.id"
                v-validate="'required'"
                name="Category Name" />
              <span
                class="text-sm text-danger"
                v-show="errors.has('Category Name')"
                >{{ errors.first("Category Name") }}</span
              >
            </div>
            <div class="form-group" v-if="addDisease.category_id > 0">
              <label>{{ $t("Disease") }}</label>
              <span style="color: red">*</span>
              <!-- <label v-if="index < 1">{{ $t("Disease") }}</label> -->
              <!-- <span v-if="index < 1" style="color: red">*</span> -->
              <v-select
                v-model="addDisease.disease_id"
                :reduce="(option) => option.id"
                :disabled="statuss == 'DONE' ? true : false"
                :options="dataDisease"
                :filterable="false"
                label="disease_name"
                v-validate="'required'"
                name="Type Of Disease">
                <!-- <li slot="list-footer" class="pagination-select">
                <button @click.prevent="prevDisease">Prev</button>
                <button @click.prevent="nextDisease">Next</button>
              </li> -->
              </v-select>
              <span
                class="text-sm text-danger"
                v-show="errors.has('Type Of Disease')"
                >{{ errors.first("Type Of Disease") }}</span
              >
            </div>
            <button
              v-if="load == false"
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit">
              {{ $t("Save") }}
            </button>
          </form>
        </b-modal>

        <b-modal
          id="modal-2"
          size="lg"
          hide-footer
          title="Patient Registration Form"
          v-if="isManualInput === true">
          <form v-if="recs === 'Clinic'">
            <label for="">
              <h3>{{ $t("Patient Info") }}</h3>
            </label>
            <div class="row g-3">
              <div class="form-group col">
                <label>Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Name"
                  v-model="dataPatient.fullname"
                  disabled />
              </div>
              <div class="form-group col">
                <label>{{ $t("Company") }}</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Company"
                  v-model="dataPatient.company"
                  disabled />
              </div>
            </div>
            <div class="row g-3">
              <div class="form-group col">
                <label>Gender</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Gender"
                  v-model="dataPatient.gender"
                  disabled />
              </div>
              <div class="form-group col">
                <label>Date of Birth</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Date of Birth"
                  v-model="dataPatient.date_of_birth"
                  disabled />
              </div>
            </div>
            <div class="row g-3">
              <div class="form-group col">
                <label>NIK</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="NIK"
                  v-model="dataPatient.nik"
                  disabled />
              </div>
              <div class="form-group col">
                <label>Blood Type</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Blood Type"
                  v-model="dataPatient.blood_type"
                  disabled />
              </div>
            </div>
            <div class="row g-3">
              <div class="form-group col">
                <label>Position</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Position"
                  v-model="dataPatient.position"
                  disabled />
              </div>
              <div class="form-group col">
                <label>Department</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Department"
                  v-model="dataPatient.department"
                  disabled />
              </div>
            </div>
            <div class="row g-3">
              <div class="form-group col">
                <label>Type Of Drug Test</label>
                    <span style="color: red">*</span>
                <b-form-checkbox-group
                  v-model="dataPatient.type_drugs_test"
                  :options="options"
                  class="mb-3"
                  value-field="value"
                  v-validate="'required'"
                  text-field="name">
                </b-form-checkbox-group>
              </div>
              <div class="form-group col">
                <label>Date Of Drug Test</label>
                <input
                  type="date"
                  class="form-control col"
                  placeholder=""
                  v-model="dataPatient.screening_date" />

                <!-- </div> -->
              </div>
            </div>

            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              @click.prevent="registerPatient()"
              v-if="qrButton == true">
              Register
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              @click.prevent="registerPatient2()"
              v-if="manualButton == true">
              Register
            </button>
          </form>
          <form v-if="recs === 'MCU'">
            <!-- <div class="row g-3">
        </div> -->
            <div class="row g-3">
              <div class="form-group col">
                <label>Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Name"
                  v-model="dataPatient2.fullname"
                  disabled />
              </div>
              <div class="form-group col">
                <label>{{ $t("Company") }}</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Company"
                  v-model="dataPatient2.company"
                  disabled />
              </div>
            </div>
            <div class="row g-3">
              <div class="form-group col">
                <label>Department</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Department"
                  v-model="dataPatient2.department"
                  disabled />
              </div>
              <div class="form-group col">
                <label>{{ $t("Position") }}</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Position"
                  v-model="dataPatient2.position"
                  disabled />
              </div>
            </div>
            <div class="row g-3">
              <div class="form-group col">
                <label>Age</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="dataPatient2.employee_age" />
              </div>
              <div class="form-group col">
                <label>Work Periode</label>
                <b-input-group append="Year" style="padding: 0 !important">
                  <b-form-input
                    type="text"
                    v-model="dataPatient2.length_of_work"></b-form-input>
                </b-input-group>
              </div>
            </div>
            <div class="row g-3">
              <div class="form-group col">
                <label>MCU Year</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="dataPatient2.mcu_year" />
              </div>
              <div class="form-group col">
                <label>Married Status</label>
                <v-select
                  v-model="dataPatient2.married_status"
                  :reduce="(option) => option.id"
                  :options="[
                    { label: 'BELUM KAWIN', id: 0 },
                    { label: 'KAWIN', id: 1 }
                  ]" />
              </div>
            </div>
            <div class="row g-3">
              <div class="form-group col">
                <label>No Lab</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="dataPatient2.no_lab" />
              </div>
              <div class="form-group col">
                <label>MCU Category</label>
                <v-select
                  v-model="dataPatient2.mcu_category_id"
                  :reduce="(option) => option.id"
                  :options="categoryMCU.data"
                  label="category_name"
                  v-validate="'required'"
                  name="Dokter" />
              </div>
            </div>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              @click.prevent="registerPatient()"
              v-if="qrButton == true">
              Register
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              @click.prevent="registerPatient2()"
              v-if="manualButton == true">
              Register
            </button>
          </form>
        </b-modal>

        <b-modal
          id="modal-outside"
          size="lg"
          hide-footer
          title="Patient Registration Form"
          v-if="isManualInput === true">
          <form v-if="recs === 'Clinic'">
            <label for="">
              <h3>{{ $t("Patient Info") }}</h3>
            </label>
            <div class="row g-3">
              <div class="form-group col">
                <label>Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Name"
                  v-model="dataPatient.fullname" />
              </div>
              <div class="form-group col">
                <label>{{ $t("Company") }}</label>
                <v-select
                  v-model="dataPatient.company"
                  :options="selectCompanyOption()"
                  label="company_name"
                  :reduce="(e) => e.company_name" />
                <!-- <input
                  type="text"
                  class="form-control"
                  placeholder="Company"
                  v-model="dataPatient.company"
                   /> -->
              </div>
            </div>
            <div class="row g-3">
              <div class="form-group col">
                <label>Gender</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Gender"
                  v-model="dataPatient.gender" />
              </div>
              <div class="form-group col">
                <label>Date of Birth</label>
                <input
                  type="date"
                  class="form-control col"
                  placeholder=""
                  v-model="dataPatient.date_of_birth" />
                <!-- <input
                  type="text"
                  class="form-control"
                  placeholder="Date of Birth"
                  v-model="dataPatient.date_of_birth"
                   /> -->
              </div>
            </div>
            <div class="row g-3">
              <div class="form-group col">
                <label>NIK</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="NIK"
                  v-model="dataPatient.nik" />
              </div>
              <div class="form-group col">
                <label>Blood Type</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Blood Type"
                  v-model="dataPatient.blood_type"
                  disabled />
              </div>
            </div>
            <div class="row g-3">
              <div class="form-group col">
                <label>Position</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Position"
                  v-model="dataPatient.position" />
              </div>
              <div class="form-group col">
                <label>Department</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Department"
                  v-model="dataPatient.department" />
              </div>
            </div>
            <div class="row g-3">
              <div class="form-group col">
                <label>Type Of Drug Test</label>
                <b-form-checkbox-group
                  v-model="dataPatient.type_drugs_test"
                  :options="options"
                  class="mb-3"
                  value-field="value"
                  v-validate="'required'"
                  text-field="name">
                </b-form-checkbox-group>
              </div>
              <div class="form-group col">
                <label>Date Of Drug Test</label>
                <input
                  type="date"
                  class="form-control col"
                  placeholder=""
                  v-model="dataPatient.screening_date" />

                <!-- </div> -->
              </div>
            </div>

            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              @click.prevent="registerPatient()"
              v-if="qrButton == true">
              Register
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              @click.prevent="registerPatient2()">
              Register
            </button>
          </form>
          <form v-if="recs === 'MCU'">
            <!-- <div class="row g-3">
        </div> -->
            <div class="row g-3">
              <div class="form-group col">
                <label>Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Name"
                  v-model="dataPatient2.fullname"
                  disabled />
              </div>
              <div class="form-group col">
                <label>{{ $t("Company") }}</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Company"
                  v-model="dataPatient2.company"
                  disabled />
              </div>
            </div>
            <div class="row g-3">
              <div class="form-group col">
                <label>Department</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Department"
                  v-model="dataPatient2.department"
                  disabled />
              </div>
              <div class="form-group col">
                <label>{{ $t("Position") }}</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Position"
                  v-model="dataPatient2.position"
                  disabled />
              </div>
            </div>
            <div class="row g-3">
              <div class="form-group col">
                <label>Age</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="dataPatient2.employee_age" />
              </div>
              <div class="form-group col">
                <label>Work Periode</label>
                <b-input-group append="Year" style="padding: 0 !important">
                  <b-form-input
                    type="text"
                    v-model="dataPatient2.length_of_work"></b-form-input>
                </b-input-group>
              </div>
            </div>
            <div class="row g-3">
              <div class="form-group col">
                <label>MCU Year</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="dataPatient2.mcu_year" />
              </div>
              <div class="form-group col">
                <label>Married Status</label>
                <v-select
                  v-model="dataPatient2.married_status"
                  :reduce="(option) => option.id"
                  :options="[
                    { label: 'BELUM KAWIN', id: 0 },
                    { label: 'KAWIN', id: 1 }
                  ]" />
              </div>
            </div>
            <div class="row g-3">
              <div class="form-group col">
                <label>No Lab</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="dataPatient2.no_lab" />
              </div>
              <div class="form-group col">
                <label>MCU Category</label>
                <v-select
                  v-model="dataPatient2.mcu_category_id"
                  :reduce="(option) => option.id"
                  :options="categoryMCU.data"
                  label="category_name"
                  v-validate="'required'"
                  name="Dokter" />
              </div>
            </div>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              @click.prevent="registerPatient()"
              v-if="qrButton == true">
              Register
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              @click.prevent="registerPatient2()"
              v-if="manualButton == true">
              Register
            </button>
          </form>
        </b-modal>

        <b-modal
          id="modal-5"
          size="xl"
          hide-footer
          @hide="onHide"
          title="Drug Test Registration Form">
          <!-- <form> -->
          <div
            class="overlay-layer bg-opacity-5 text-center"
            v-if="load == true">
            <div
              class="spinner-border text-primary"
              style="width: 6rem; height: 6rem"
              role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div v-else>
            <div class="row g-3">
              <div class="form-group col">
                <label>Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Name"
                  v-model="search.fullname" />
              </div>

              <div class="form-group col">
                <label>NIK</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="NIK"
                  v-model="search.nik" />
              </div>
            </div>
            <!-- <div class="row g-3">
              <div class="form-group col">
                <label>Employee Name Outside ISafe</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee Name"
                  v-model="search.employee_name" />
              </div>
             </div> -->
            <div class="row g-3">
              <div class="form-group col">
                <label>No. KTP</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="KTP"
                  v-model="search.no_ktp" />
              </div>
              <div class="form-group col">
                <label>Date of Birth</label>
                <input
                  type="date"
                  class="form-control"
                  placeholder="Date of Birth"
                  v-model="search.date_of_birth" />
              </div>
              <!-- <button
                style="margin-left: auto; float: right"
                class="mt-2 mb-2 btn btn-primary"
                @click.prevent="getEmployee()">
                Submit
              </button> -->
              <button
                style="margin-left: auto; float: right"
                class="mt-2 mb-3 btn btn-primary"
                @click.prevent="getEmployee()">
                Search
              </button>
              <!-- {{ employees }} -->
            </div>

            <vue-good-table
              v-if="searchEmployee == true"
              :line-numbers="true"
              :columns="columnsSearch"
              :rows="employeesList"
              :sort-options="{
                enabled: true
              }"
              :pagination-options="{
                enabled: true,
                mode: 'pages',
                position: 'bottom',
                perPage: 10,
                perPageDropdown: [10, 50, 100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: 'All'
              }"
              style-class="vgt-table mt-4 border-0 table"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange">
              <!-- Loading -->
              <div slot="emptystate" class="text-center font-size-14">
                No data available
              </div>
              <template slot="table-row" slot-scope="props">
                <!-- Table Action -->
                <span v-if="props.column.field == 'action'">
                  <b-button
                    class="no-wrap btn-pill"
                    variant="primary"
                    size="sm"
                    @click="dataSelected(props.row)"
                    >Select
                  </b-button>
                </span>

                <span v-else-if="props.column.field == 'created_at'">
                  {{ fixTime(props.row.created_at) }}
                </span>
              </template>
            </vue-good-table>
          </div>
          <!-- </form> -->
        </b-modal>

        <b-modal
          id="modal-3"
          hide-footer
          title="QRCode"
          v-if="isQRCode === true">
          <div v-if="isQRCode === true">
            <div class="d-block text-center">
              <h3>
                {{ $t("Please Scan Barcode or Input Registration Code") }}
              </h3>
            </div>
            <div class="d-block text-center">
              <h1>
                <b style="letter-spacing: 0.25rem; font-size: 32px">{{
                  qrData.code
                }}</b>
              </h1>
              <div>
                <qr-code
                  :text="`${qrData.code}-${body.clinic_id}`"
                  :size="500"
                  class="d-flex justify-content-center"></qr-code>
              </div>
            </div>
          </div>
        </b-modal>
        <b-modal id="importFile" size="xl" hide-footer title="Form Import MCU">
          <form @submit.prevent="importData">
            <div class="row">
              <div
                class="col-12 mb-3"
                v-for="(item, i) in importPayload"
                :key="i">
                <label
                  >Upload File
                  <!-- <span
                    ><a :href="item.sample" download target="_blank">
                      <i class="fe fe-download"></i> Sample
                    </a></span
                  > -->
                </label>
                <input
                  type="file"
                  class="form-control"
                  @change="(e) => setFile(e, i)" />
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-right">
                <button
                  style="margin-left: auto; float: right"
                  class="btn btn-primary mt-4"
                  type="submit">
                  Save
                </button>
              </div>
            </div>
          </form>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import axiosIns from "@/api/axios";
import moment from "moment";
export default {
  name: "drugTest",
  computed: {
    qrData() {
      return this.$store.getters["patient/qrData"]
        ? this.$store.getters["patient/qrData"]
        : [];
    },
    patientDataQr() {
      return this.$store.getters["patient/patientQrData"]
        ? this.$store.getters["patient/patientQrData"]
        : [];
    },
    dataGet() {
      return this.$store.getters["drugTest/allData"]
        ? this.$store.getters["drugTest/allData"]
        : [];
    },
    employees() {
      return this.$store.getters["roleManagement/dataUser"]
        ? this.$store.getters["roleManagement/dataUser"]
        : [];
    },
    drug() {
      return this.$store.getters["drug/DrugData"]
        ? this.$store.getters["drug/DrugData"]
        : [];
    },
    unit() {
      return this.$store.getters["po/UnitProductData"]
        ? this.$store.getters["po/UnitProductData"]
        : [];
    },
    unitReceipt() {
      return this.$store.getters["po/UnitProductData"]
        ? this.$store.getters["po/UnitProductData"]
        : [];
    },
    desease() {
      return this.$store.getters["disease/deseaseData"]
        ? this.$store.getters["disease/deseaseData"]
        : [];
    },
    doctors() {
      return this.$store.getters["patient/doctorData"]
        ? this.$store.getters["patient/doctorData"]
        : [];
    },
    treatment() {
      return this.$store.getters["treatment/TreatmentData"]
        ? this.$store.getters["treatment/TreatmentData"]
        : [];
    },
    category() {
      return this.$store.getters["patient/categoryData"]
        ? this.$store.getters["patient/categoryData"]
        : [];
    },
    filtered() {
      const data = this.$store.getters["disease/deseaseData"] || [];
      return data.data;
    },
    paginated() {
      const data = this.$store.getters["disease/deseaseData"] || [];
      return data.data;
    },
    hasNextPage() {
      const data = this.$store.getters["disease/deseaseData"] || [];
      return true;
    },

    category() {
      return this.$store.getters["category/CategoryData"]
        ? this.$store.getters["category/CategoryData"]
        : [];
    },
    block() {
      return this.$store.getters["block/BlockData"]
        ? this.$store.getters["block/BlockData"]
        : [];
    },
    chapter() {
      return this.$store.getters["chapter/ChapterData"]
        ? this.$store.getters["chapter/ChapterData"]
        : [];
    },

    categoryTest() {
      return this.$store.getters["categoryMcuTest/CategoryData"]
        ? this.$store.getters["categoryMcuTest/CategoryData"]
        : [];
    },
    groupTest() {
      return this.$store.getters["groupMcu/GroupData"]
        ? this.$store.getters["groupMcu/GroupData"]
        : [];
    },
    itemTest() {
      return this.$store.getters["itemMcu/ItemData"]
        ? this.$store.getters["itemMcu/ItemData"]
        : [];
    },
    categoryMCU() {
      return this.$store.getters["categoryMcu/CategoryData"]
        ? this.$store.getters["categoryMcu/CategoryData"]
        : [];
    },
    companyFilter() {
      return this.$store.getters["detailManagement/dataCompany"]
        ? this.$store.getters["detailManagement/dataCompany"]
        : [];
    }
  },

  data() {
    return {
      filterCompany: "",
      screening_date: moment().format("YYYY-MM-DD"),
      company: "",
      searchEmployee: false,
      company_id: "",
      company_name: "",
      company_id: "",
      currentstep: 0,
      isLoading: false,
      search: {
        no_ktp: "",
        employee_name: "",
        fullname: "",
        nik: "",
        date_of_birth: ""
      },
      file: null,
      body: {
        // patient_name: "",
        page: 1,
        limit: 10,
        sort: "",
        clinic_id:localStorage.getItem("user_roles"),
        company_id: localStorage.getItem("company"),
        employee_name: "",
        clinic_id: "",
        start_screening_date: moment().format("YYYY-MM-DD"),
        end_screening_date: moment().format("YYYY-MM-DD"),
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        type_visit: "Test Narkoba"
      },
      addDisease: {
        chapter_id: "",
        block_id: "",
        category_id: "",
        disease_id: "",
        disease_name: ""
      },
      addDiseaseValue: [
        {
          chapter: "",
          block: "",
          category: "",
          disease_id: "",
          disease_name: ""
        }
      ],
      diagnose: {
        id: "",
        bmi: "",
        init_complaint: "",
        weight: "",
        height: "",
        blood_pressure: "",
        allergy: "",
        visit_type: "",
        doctor: "",
        main_complaint: "",
        vital_sign: "",
        disease_id: "",
        spell: "",
        pak: "",
        file_doctor_letter: [],
        file_receipt_letter: [],
        file_sick_letter: [],
        disease: [
          {
            chapter: "",
            block: "",
            category: "",
            disease_id: "",
            disease_name: ""
          }
        ],
        drugs: [
          {
            drugs_id: "",
            qty: 1,
            drugs_unit_id: "",
            expired_date: "",
            how_use: "",
            is_external: false,
            drugExpireDate: [],
            dataAddDrugs: []
          }
        ],
        disposable: [
          {
            item_id: "",
            qty: 1,
            item_unit_id: "",
            expired_date: "",
            disposableExpireDate: []
          }
        ],
        doctor_action: "",
        action_care: "",
        follow_up: "",
        star_date: moment().format("YYYY-MM-DD"),
        next_visit: "",
        doctor_note: "",
        sick_leave: "",
        sick_leave_day: "",
        non_medisin: ""
      },
      mcuData: {
        register_id: "",
        mcu_record: []
      },
      importPayload: [
        {
          // name: "Riwayat",
          file: null,
          sample: "/mcu_riwayat.xlsx"
        }
      ],
      dataResults: {
        id: "",
        doctor_name: "",
        amphetamine: "",
        metamphitamine: "",
        cocaine: "",
        ganja: "",
        benzoat: "",
        morphine: "",
        note: "",
        need_referral: false
      },
      dataPatient: {
        blood_type: "",
        clinic_id: "",
        code: "",
        company: "",
        company_id: "",
        // date_of_birth: "",
        date_of_birth: moment().format("YYYY-MM-DD"),
        department: "",
        fullname: "",
        gender: "",
        id: "",
        nik: "",
        position: "",
        status: "",
        init_complaint: "",
        weight: "",
        height: "",
        blood_pressure: "",
        allergy: "",
        clinic_feature_id: "",
        type_drugs_test: [],
        screening_date: moment().format("YYYY-MM-DD"),
        clinic_name: "",
        doctor_name: "",
        drugs_test: true
      },
      dataPatient2: {
        id: "",
        clinic_id: "",
        clinic_feature_id: "",
        company: "",
        employee_age: "",
        length_of_work: "",
        mcu_year: "",
        married_status: "",
        mcu_category_id: "",
        fullname: "",
        date_of_birth: "",
        department: "",
        gender: "",
        nik: "",
        position: "",
        no_lab: "",
        mcu_location: "",
        screening_date: ""
      },
      options: [
        { name: "Urine", value: "Urine" },
        { name: "Blood", value: "Blood" },
        { name: "Saliva", value: "Saliva" },
        { name: "Hair", value: "Hair" }
      ],
      optionsReceipt: [{ item: 0, name: "Non Medisin" }],
      optionsResult: [
        { name: "Positif", value: "Positif" },
        { name: "Negatif", value: "Negatif" }
      ],
      disposableItemOption: [],
      drugOption: [],
      unitOption: [],
      type_visit: "",
      register_id: 0,
      purpose_visit: 0,
      clinic_ids: "",
      clinic_features: [],
      feature_id: "",
      datasRegis: {},
      load: true,
      kunjungan: null,
      id: "",
      statuss: "",
      isManualInput: true,
      isQRCode: true,
      isUpdate: true,
      qrButton: false,
      manualButton: false,
      recs: "",
      columns: [
        {
          label: "NAMA KARYAWAN",
          field: "patient_name",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "PERUSAHAAN",
          field: "company",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "DEPARTMENT",
          field: "department",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "STATUS",
          field: "status",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "CLINIC",
          field: "clinic_name",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "PENGGUNAAN NARKOBA",
          field: "positif_drugs",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "JENIS KUNJUNGAN",
          field: "type_visit",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "WAKTU REGIS",
          field: "register_date",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "WAKTU TEST",
          field: "screening_date",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass:
            "table-header no-wrap text-muted text-center font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ],
      columnsDrugs: [
        {
          label: "Medicine Name",
          field: "item_label",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "UNIT",
          field: "uom_label",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Stock",
          field: "qty",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle"
        },
        {
          label: "EXP DATE",
          field: "expiry_date",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle"
        }
      ],
      desease_search: false,
      desease_timeout: null,
      desease_timeout_chapter: null,
      desease_paramater: {
        page: 1,
        limit: 10,
        disease_name: ""
      },
      desease_chapter_paramater: {
        page: 1,
        limit: "",
        disease_name: ""
      },
      desease_observer: null,
      columnsSearch: [
        {
          label: "NAMA KARYAWAN",
          field: "fullname",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "NIK",
          field: "nik",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "NO KTP",
          field: "no_ktp",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Company",
          field: "company",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "DATE OF BIRTH",
          field: "date_of_birth",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Flag",
          field: "flag",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ],
      dataBlock: [],
      dataCategories: [],
      dataDisease: [],
      role_user: []
    };
  },

  mounted() {
    let dataClinic = JSON.parse(localStorage.getItem("user_roles"))
    this.body.clinic_id = dataClinic.healthcare_facility_id
    this.role_id = parseInt(localStorage.getItem("roles_id"));
    this.company_id = localStorage.getItem("company");
    this.company_name = localStorage.getItem("company_name");
    let user_role = localStorage.getItem("user_roles");
    this.role_user = JSON.parse(user_role);
    // this.clinic_ids = localStorage.getItem("seeHistory");
    this.body.clinic_id = this.clinic_ids;
    const roles = localStorage.getItem("roles_id");
    this.filterCompany = parseInt(roles) == 1 ? 1 : 0;
    // if (localStorage.getItem("roles_id") !== "1") {
    //   this.company_ids = localStorage.getItem("company");
    //   this.body.company_id = this.company_ids;
    // }
    this.getAllData();
    this.getCompany();
    this.getDrug();
    this.getDisposable();
    this.desease_observer = new IntersectionObserver(this.getDesease);
    this.body.clinic_id = this.clinic_ids;
    this.dataPatient.clinic_id = this.clinic_ids;
    this.clinic_features = JSON.parse(localStorage.getItem("clinic_feature"));
    this.getDoctor();
    this.getTreatment();
    this.getCategory();
    // this.getDesease();
    this.getCategoryDisease();
    this.getBlock();
    this.getChapter();
    this.getCategoryMCU();
  },

  methods: {
    selectCompanyOption() {
      const company = this.companyFilter;
      const term = [];
      term.push({
        id: null,
        company_name: "BIB + Mitra"
      });

      company.map((i) => {
        term.push({
          id: i.id,
          company_name: i.company_name
        });
      });
      return term;
    },

    downloadCompany(){
      let body = {
        company_id: this.body.company_id,
        employee_name: this.body.employee_name,
        start_date: this.body.start_date,
        end_date: this.body.end_date,
        start_screening_date: this.body.start_screening_date,
        end_screening_date: this.body.end_screening_date
      };
      let params = queryString.stringify(body);

      axiosIns
        .get(
          `${axiosIns.defaults.baseURL}/clinic/patient/drugs/test/export-excel?${params}`,
          {
            responseType: "blob"
          }
        )
        .then((res) => {
          const href = URL.createObjectURL(res.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", `Uhealth Drug Test Report(By Company).xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
    downloadClinic(){
      let dataClinic = JSON.parse(localStorage.getItem("user_roles"))
      this.body.clinic_id = dataClinic[0].healthcare_facility_id

      let body = {
        clinic_id: this.body.clinic_id,
        employee_name: this.body.employee_name,
        start_date: this.body.start_date,
        end_date: this.body.end_date,
        start_screening_date: this.body.start_screening_date,
        end_screening_date: this.body.end_screening_date
      };

      let params = queryString.stringify(body);
      axiosIns
        .get(
          `${axiosIns.defaults.baseURL}/clinic/patient/drugs/test/export-excel?${params}`,
          {
            responseType: "blob"
          }
        )
        .then((res) => {
          const href = URL.createObjectURL(res.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", `Uhealth Drug Test Report(By Clinic).xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },

    // exporting(type) {
    //   if(type == 'company') {
    //     let body = {
    //     company_id: this.body.company_id,
    //     employee_name: this.body.employee_name,
    //     start_date: this.body.start_date,
    //     end_date: this.body.end_date,
    //     start_screening_date: this.body.start_screening_date,
    //     end_screening_date: this.body.end_screening_date
    //   };
    //   let params = queryString.stringify(body);

    //   axiosIns
    //     .get(
    //       `${axiosIns.defaults.baseURL}/clinic/patient/drugs/test/export-excel?${params}`,
    //       {
    //         responseType: "blob"
    //       }
    //     )
    //     .then((res) => {
    //       const href = URL.createObjectURL(res.data);
    //       const link = document.createElement("a");
    //       link.href = href;
    //       link.setAttribute("download", `Uhealth Drug Test Report.xlsx`);
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link);
    //       URL.revokeObjectURL(href);
    //     });
    //   } else if (type == 'clinic') {
    //     let body = {
    //     clinic_id: this.body.clinic_id,
    //     employee_name: this.body.employee_name,
    //     start_date: this.body.start_date,
    //     end_date: this.body.end_date,
    //     start_screening_date: this.body.start_screening_date,
    //     end_screening_date: this.body.end_screening_date
    //   };
    //   let params = queryString.stringify(body);

    //   axiosIns
    //     .get(
    //       `${axiosIns.defaults.baseURL}/clinic/patient/drugs/test/export-excel?${params}`,
    //       {
    //         responseType: "blob"
    //       }
    //     )
    //     .then((res) => {
    //       const href = URL.createObjectURL(res.data);
    //       const link = document.createElement("a");
    //       link.href = href;
    //       link.setAttribute("download", `Uhealth Drug Test Report.xlsx`);
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link);
    //       URL.revokeObjectURL(href);
    //     });
    //   }
      
      
    // },

    getCompany() {
      this.$store.dispatch("detailManagement/getCompany");
    },

    reset() {
      this.dataPatient.purpose_visit = 0;
      this.dataPatient.clinic_name = "";
      this.dataPatient.doctor_name = "";
    },

    getData() {
      axios
        .get("/test/server", { maxContentLength: 100000 })
        .then(function (response) {})
        .catch(function (err) {
          this.$swal(
                `Error`,
                err,
                "error"
              );
          console.error(err);
        });
    },

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
      this.search = {
        no_ktp: "",
        fullname: "",
        nik: "",
        date_of_birth: ""
      };
      this.searchEmployee = false;
    },

    nextStep() {
      this.currentstep = this.currentstep + 1;
    },

    lastStep() {
      this.currentstep = this.currentstep - 1;
    },

    setFile(e, i) {
      const files = e.target.files;
      if (files) {
        const file = e.target.files[0];
        this.importPayload[i]["file"] = file;
      }
    },

    async importData() {
      try {
        const importPayload = this.importPayload;
        const invalidFile = importPayload.findIndex((e) => !e.file);
        if (invalidFile > -1) {
          this.$swal({
            icon: "error",
            title: "Error",
            text: "All input must be filled"
          });
          return;
        }

        let i = 0;
        while (i < importPayload.length) {
          const file = importPayload[i]["file"];
          const formData = new FormData();
          formData.append("clinic_id", this.clinic_ids);
          formData.append("files", file);

          this.$store.dispatch("patient/importRegisterPatient", formData);
          i++;
        }
        this.getAllData();
        return;
      } catch (err) {
        console.log("Error while importing mcu record", err);
      }
    },

    addDrugs() {
      const blank_drugs = {
        drugs_id: "",
        qty: "",
        drugs_unit_id: "",
        expired_date: "",
        how_use: "",
        is_external: false,
        dataAddDrugs: []
      };
      this.diagnose.drugs.push(blank_drugs);
    },

    addDrugsDisposable() {
      const blank_drugs = {
        drugs_id: "",
        qty: "",
        drugs_unit_id: ""
      };
      this.diagnose.disposable.push(blank_drugs);
    },

    // addDisease() {
    //   const blank_disease = {
    //     disease_id: "",
    //   };
    //   this.diagnose.disease.push(blank_disease);
    // },

    chapterSelectToBlock() {
      this.load = false;
      let blocks = this.block.data;
      let chapter_id = this.addDisease.chapter_id;
      var fill = blocks.filter((item) => {
        return item.chapter_id == chapter_id;
      });
      this.dataBlock = fill;
    },

    blockSelectToCatgory() {
      this.load = false;
      let catgories = this.category.data;
      let block_id = this.addDisease.block_id;
      let chapter_id = this.addDisease.chapter_id;
      var fill = catgories.filter((item) => {
        return item.block_id == block_id && item.chapter_id == chapter_id;
      });
      this.dataCategories = fill;
    },

    categorySelectToDisease() {
      this.load = false;
      let diseasies = this.desease.data;
      let block_id = this.addDisease.block_id;
      let chapter_id = this.addDisease.chapter_id;
      let category_id = this.addDisease.category_id;
      var fill = diseasies.filter((item) => {
        return (
          item.block_id == block_id &&
          item.chapter_id == chapter_id &&
          item.category_id == category_id
        );
      });
      this.dataDisease = fill;
    },

    async fileUploaderDoctor(event) {
      this.diagnose.file_doctor_letter = event.target.files;
    },

    async fileUploaderReceipt(event) {
      this.diagnose.file_receipt_letter = event.target.files;
    },

    async fileUploaderLicense(event) {
      this.diagnose.file_sick_letter = event.target.files;
    },

    saveAddDisease() {
      if (this.addDisease.disease_id == 0) {
        this.$validator.validateAll().then(async (res) => {
          if (!res) return false;
          this.load = false;
        });
      } else {
        this.load = false;
        let chapter = this.chapter.data;
        let blocks = this.block.data;
        let categories = this.category.data;
        let diseasies = this.desease.data;

        let block_id = this.addDisease.block_id;
        let chapter_id = this.addDisease.chapter_id;
        let category_id = this.addDisease.category_id;
        let disease_id = this.addDisease.disease_id;

        var fillChapter = chapter.filter((item) => {
          return item.id == chapter_id;
        });
        var fillBlocks = blocks.filter((item) => {
          return item.id == block_id;
        });
        var fillCategories = categories.filter((item) => {
          return item.id == category_id;
        });

        var fillDisease = diseasies.filter((item) => {
          return item.id == disease_id;
        });

        const data = {
          disease_id: this.addDisease.disease_id,
          chapter: fillChapter[0].chapter_name,
          block: fillBlocks[0].block_name,
          category: fillCategories[0].category_name,
          disease_name: fillDisease[0].disease_name
        };
        this.diagnose.disease.push(data);
        this.$bvModal.hide("addDisease");
        this.load = false;
      }
    },

    reset() {
      this.currentstep = 0;
    },

    resetAddDisease() {
      this.addDisease = {
        chapter: "",
        block: "",
        category: "",
        disease_id: "",
        disease_name: ""
      };
    },

    removeDrugs(index) {
      this.diagnose.drugs.splice(index, 1);
    },

    removeDisease(index) {
      this.diagnose.disease.splice(index, 1);
      // this.addDiseaseValue.splice(index, 1);
    },

    removeDrugsDisposable(index) {
      this.diagnose.disposable.splice(index, 1);
    },

    newRecord() {
      const blank_record = {
        category_name: "",
        group_name: "",
        check_title: "",
        check_value: ""
      };
      this.mcuData.mcu_record.push(blank_record);
    },

    removeRecord(i) {
      this.mcuData.mcu_record.splice(i, 1);
    },

    fixTime(data) {
      return moment(data).format("D MMMM YYYY");
    },

    selectDropdown(unit) {},

    title() {
      const clinicFeature =
        this.role_user.length > 0
          ? this.role_user[0].healthcare_facility_feature
          : [];
      const invent = clinicFeature.findIndex(
        (el) => el.healthcare_facility_feature_id === "1"
      );
      // const indexx = this.clinic_features.findIndex((e) => e.clinic_feature_id === "1");
      if (invent > -1) {
        return "Clinic";
      } else {
        return "MCU";
      }
    },

    manualInputs() {
      const clinicFeature =
        this.role_user.length > 0
          ? this.role_user[0].healthcare_facility_feature
          : [];
      const invent = clinicFeature.findIndex(
        (el) => el.healthcare_facility_feature_id === "1"
      );
      // const indexx = this.clinic_features.findIndex((e) => e.clinic_feature_id === "1");
      if (invent > -1) {
        this.recs = "Clinic";
        this.dataPatient2.clinic_feature_id = 1;
        this.dataPatient.clinic_feature_id = 1;
      } else {
        this.recs = "MCU";
        this.dataPatient2.clinic_feature_id = 3;
        this.dataPatient.clinic_feature_id = 3;
      }
    },

    getDataEmploye(e) {
      let id = this.dataPatient.fullname;
      let datas = e.filter((x) => x.id === id);
      this.dataPatient.company = datas[0].company;
      if (datas[0].gender === "M") {
        this.dataPatient.gender = "Male";
      } else if (datas[0].gender === "F") {
        this.dataPatient.gender = "Female";
      } else {
        this.dataPatient.gender = "Unknown";
      }
      this.dataPatient.date_of_birth = datas[0].date_of_birth;
      this.dataPatient.nik = datas[0].nik;
      this.dataPatient.blood_type = datas[0].blood_type;
      this.dataPatient.position = datas[0].position;
      this.dataPatient.department = datas[0].department;
      this.datasRegis = datas[0];
    },

    qrModal() {
      this.qrButton = true;
      this.manualButton = false;
      this.isQRCode = true;
      const clinicFeature =
        this.role_user.length > 0
          ? this.role_user[0].healthcare_facility_feature
          : [];
      const invent = clinicFeature.findIndex(
        (el) => el.healthcare_facility_feature_id === "1"
      );
      // const finds = this.clinic_features.findIndex((e) => e.clinic_feature_id === "1");
      if (invent > -1) {
        this.feature_id = 1;
      } else {
        this.feature_id = 3;
      }
      let bodys = {
        clinic_feature_id: this.feature_id,
        clinic_id: this.body.clinic_id
      };

      // const indexx = this.clinic_features.findIndex((e) => e.clinic_feature_id === "1");
      if (invent > -1) {
        this.recs = "Clinic";
        this.dataPatient2.clinic_feature_id = 1;
        this.dataPatient.clinic_feature_id = 1;
      } else {
        this.recs = "MCU";
        this.dataPatient2.clinic_feature_id = 3;
        this.dataPatient.clinic_feature_id = 3;
      }
      this.$store.dispatch("patient/getQrCode", bodys).then(() => {
        let codeInterval = this.$store.getters["patient/qrData"];
        let bodys = {
          code: codeInterval.code,
          clinic_id: this.body.clinic_id
        };
        let set = setInterval(() => {
          this.$store.dispatch("patient/getPatientQr", bodys).then(() => {
            let dataInterval = this.$store.getters["patient/patientQrData"];

            if (dataInterval.status > 0) {
              clearInterval(set);
              this.$bvModal.hide("modal-3");
              this.$bvModal.show("modal-2");
              this.$store.dispatch("patient/getPatientQr", bodys).then(() => {
                let dataPatient = this.$store.getters["patient/patientQrData"];
                this.dataPatient.blood_type = dataPatient.blood_type;
                this.dataPatient.clinic_id = dataPatient.clinic_id;
                this.dataPatient.code = dataPatient.code;
                this.dataPatient.company = dataPatient.company;
                this.dataPatient.company_id = dataPatient.company_id;
                this.dataPatient.date_of_birth = dataPatient.date_of_birth;
                this.dataPatient.department = dataPatient.department;
                this.dataPatient.fullname = dataPatient.fullname;
                this.dataPatient.gender = dataPatient.gender;
                (this.dataPatient.id = dataPatient.id),
                  (this.dataPatient.nik = dataPatient.nik),
                  (this.dataPatient.position = dataPatient.position),
                  (this.dataPatient.status = dataPatient.status);

                this.dataPatient2.company = dataPatient.company;
                this.dataPatient2.fullname = dataPatient.fullname;
                this.dataPatient2.id = dataPatient.id;
                this.dataPatient2.clinic_id = dataPatient.clinic_id;
                this.dataPatient2.department = dataPatient.department;
                this.dataPatient2.position = dataPatient.position;
              });
            }
          });
        }, 2000);
      });
    },

    dataSelected(props) {
      this.manualButton = true;
      this.qrButton = false;
      this.$bvModal.hide("modal-5");
      this.$bvModal.show("modal-2");

      this.search.employee_name = props.fullname;
      this.dataPatient.fullname = props.fullname;
      this.dataPatient.company = props.company;
      this.dataPatient.department = props.department;
      this.dataPatient.date_of_birth = props.date_of_birth;
      this.dataPatient.nik = props.nik;
      this.dataPatient.position = props.position;
      this.dataPatient.gender = props.gender;
      this.dataPatient.blood_type = props.blood_type;

      this.dataPatient2.company = props.company;
      this.dataPatient2.fullname = props.fullname;
      this.dataPatient2.id = props.id;
      this.dataPatient2.clinic_id = this.body.clinic_id;
      this.dataPatient2.date_of_birth = props.date_of_birth;
      this.dataPatient2.nik = props.nik;
      this.dataPatient2.position = props.position;
      this.dataPatient2.gender = props.gender;
      this.dataPatient2.department = props.department;
      this.dataPatient2.mcu_year = moment().format("YYYY");
    },

    registerPatient() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) {
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            })
            .fire({
              icon: "error",
              title: "Please fill out the mandatory fields"
            });
        } else {
          if (this.recs === "Clinic") {
            this.$store
              .dispatch("patient/registerPatient", this.dataPatient)
              .then(() => {
                this.$swal({
                  icon: "success",
                  title: "Success",
                  text: "Patient has been registered"
                });
                //this.body.page = 1;
                this.qrButton = false;
                this.$bvModal.hide("modal-2");
                this.getAllData();
                this.dataPatient.weight = "";
                this.dataPatient.height = "";
                this.dataPatient.allergy = "";
                this.dataPatient.blood_pressure = "";
                this.dataPatient.init_complaint = "";
                this.dataPatient.type_drugs_test = [];
                this.dataPatient.clinic_name = "";
                this.dataPatient.doctor_name = "";
              });
          } else {
            this.$store
              .dispatch("patient/registerPatient", this.dataPatient2)
              .then(() => {
                this.$swal({
                  icon: "success",
                  title: "Success",
                  text: "Patient has been registered"
                });
                //this.body.page = 1;
                this.qrButton = false;
                this.$bvModal.hide("modal-2");
                this.getAllData();
                (this.dataPatient2.employee_age = ""),
                (this.dataPatient2.length_of_work = ""),
                (this.dataPatient2.married_status = ""),
                (this.dataPatient2.mcu_year = ""),
                (this.dataPatient2.no_lab = ""),
                (this.dataPatient2.mcu_location = "");
              });
          }
        }
      });
    },

    // registerPatient() {
    //    this.$validator.validateAll().then(async(res) => {
    //     if(!res){
    //        this.$swal
    //         .mixin({
    //           toast: true,
    //           position: "top-end",
    //           showConfirmButton: false,
    //           timer: 3000,
    //           timerProgressBar: true,
    //           didOpen: (toast) => {
    //             toast.addEventListener("mouseenter", this.$swal.stopTimer);
    //             toast.addEventListener("mouseleave", this.$swal.resumeTimer);
    //           }
    //         })
    //         .fire({
    //           icon: "error",
    //           title: "Please fill out the mandatory fields"
    //         });
    //     }else{
    //   if (this.recs === "Clinic") {
    //     this.$store
    //       .dispatch("patient/registerPatient", this.dataPatient)
    //       .then(() => {
    //         this.$swal({
    //           icon: "success",
    //           title: "Success",
    //           text: "Patient has been registered"
    //         });
    //         //this.body.page = 1;
    //         this.qrButton = false;
    //         this.$bvModal.hide("modal-2");
    //         this.getAllData();
    //         this.dataPatient.weight = "";
    //         this.dataPatient.height = "";
    //         this.dataPatient.allergy = "";
    //         this.dataPatient.blood_pressure = "";
    //         this.dataPatient.init_complaint = "";
    //         this.dataPatient.type_drugs_test = [];
    //         this.dataPatient.clinic_name = "";
    //         this.dataPatient.doctor_name = "";
    //       });
    //   } else {
    //     this.$store
    //       .dispatch("patient/registerPatient", this.dataPatient2)
    //       .then(() => {
    //         this.$swal({
    //           icon: "success",
    //           title: "Success",
    //           text: "Patient has been registered"
    //         });
    //         //this.body.page = 1;
    //         this.qrButton = false;
    //         this.$bvModal.hide("modal-2");
    //         this.getAllData();
    //         (this.dataPatient2.employee_age = ""),
    //           (this.dataPatient2.length_of_work = ""),
    //           (this.dataPatient2.married_status = ""),
    //           (this.dataPatient2.mcu_year = ""),
    //           (this.dataPatient2.no_lab = ""),
    //           (this.dataPatient2.mcu_location = "");
    //         // this.dataPatient.weight = "";
    //         // this.dataPatient.height = "";
    //         // this.dataPatient.allergy = "";
    //         // this.dataPatient.blood_pressure = "";
    //         // this.dataPatient.init_complaint = "";
    //       });
    //   }
    // },
    // registerPatient2() {
    //   this.$validator.validateAll().then(async (res) => {
    // if (!res) {
    //   this.$swal
    //     .mixin({
    //       toast: true,
    //       position: "top-end",
    //       showConfirmButton: false,
    //       timer: 3000,
    //       timerProgressBar: true,
    //       didOpen: (toast) => {
    //         toast.addEventListener("mouseenter", this.$swal.stopTimer);
    //         toast.addEventListener("mouseleave", this.$swal.resumeTimer);
    //       }
    //     })
    //     .fire({
    //       icon: "error",
    //       title: "Please fill out the mandatory fields"
    //     });
    // } else {
    //   if (this.recs === "Clinic") {
    //     delete this.dataPatient.code;
    //     delete this.dataPatient.status;
    //     delete this.dataPatient.company_id;
    //     delete this.dataPatient.id;
    //     this.$store
    //       .dispatch("patient/registerPatient2", this.dataPatient)
    //       .then(() => {
    //         this.$swal({
    //           icon: "success",
    //           title: "Success",
    //           text: "Patient has been registered"
    //         });
    //         //this.body.page = 1;
    //         this.manualButton = false;
    //         this.$bvModal.hide("modal-2");
    //         this.$bvModal.hide("modal-outside");
    //         this.getAllData();
    //         this.dataPatient.blood_type = "";
    //         this.dataPatient.date_of_birth = "";
    //         this.dataPatient.department = "";
    //         this.dataPatient.fullname = "";
    //         this.dataPatient.position = "";
    //         this.dataPatient.screening_date = "";
    //         this.dataPatient.nik = "";
    //         this.dataPatient.weight = "";
    //         this.dataPatient.height = "";
    //         this.dataPatient.allergy = "";
    //         this.dataPatient.blood_pressure = "";
    //         this.dataPatient.init_complaint = "";
    //         this.dataPatient.purpose_visit = "";
    //         this.dataPatient.clinic_name = "";
    //         this.dataPatient.doctor_name = "";
    //         this.dataPatient.type_drugs_test = [];
    //       });
    //   } else {
    //     this.$store
    //       .dispatch("patient/registerPatient2", this.dataPatient2)
    //       .then(() => {
    //         this.$swal({
    //           icon: "success",
    //           title: "Success",
    //           text: "Patient has been registered"
    //         });
    //         //this.body.page = 1;
    //         this.qrButton = false;
    //         this.$bvModal.hide("modal-2");
    //         this.$bvModal.hide("modal-outside");
    //         this.getAllData();
    //       });
    //   }
    // },

    registerPatient2() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) {
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            })
            .fire({
              icon: "error",
              title: "Please fill out the mandatory fields"
            });
        } else {
          if (this.recs === "Clinic") {
            delete this.dataPatient.code;
            delete this.dataPatient.status;
            delete this.dataPatient.company_id;
            delete this.dataPatient.id;
            this.$store
              .dispatch("patient/registerPatient2", this.dataPatient)
              .then(() => {
                this.$swal({
                  icon: "success",
                  title: "Success",
                  text: "Patient has been registered"
                });
                // //this.body.page = 1;
                this.manualButton = false;
                this.$bvModal.hide("modal-2");
                this.$bvModal.hide("modal-outside");
                this.getAllData();
                this.dataPatient.blood_type = "";
                this.dataPatient.date_of_birth = "";
                this.dataPatient.department = "";
                this.dataPatient.fullname = "";
                this.dataPatient.position = "";
                // this.dataPatient.screening_date = "";
                this.dataPatient.nik = "";
                this.dataPatient.weight = "";
                this.dataPatient.height = "";
                this.dataPatient.allergy = "";
                this.dataPatient.blood_pressure = "";
                this.dataPatient.init_complaint = "";
                this.dataPatient.purpose_visit = "";
                this.dataPatient.clinic_name = "";
                this.dataPatient.doctor_name = "";
                this.dataPatient.type_drugs_test = [];
              });
          } else {
            this.$store
              .dispatch("patient/registerPatient2", this.dataPatient2)
              .then(() => {
                this.$swal({
                  icon: "success",
                  title: "Success",
                  text: "Patient has been registered"
                });
                //this.body.page = 1;
                this.qrButton = false;
                this.$bvModal.hide("modal-2");
                this.$bvModal.hide("modal-outside");
                this.getAllData();
              });
          }
        }
      });
    },

    diagnoseData(props) {
      this.reset();
      this.register_id = props.register_id;

      this.type_visit = props.type_visit;
      this.purpose_visit = props.purpose_visit;
      this.$bvModal.show("modal-1");

      this.$store.dispatch("drugTest/getDrugByRegister", {
        register_id: this.register_id
      });
      // .then(async () => {
      //     await this.getDesease();
      //     let dataDiag = this.$store.getters["patient/diagnoseData"];
      //
      //     for (let i = 0; i < dataDiag.disease.length; i++) {
      //     await this.getDetailDisease(dataDiag.disease[i].disease_id);
      //     }

      //
      //     this.dataResults.id = props.register_id;

      //     this.statuss = props.status;
      //     this.diagnose.id = props.register_id;
      //     this.diagnose.init_complaint = dataDiag.init_complaint;
      //     this.diagnose.weight = dataDiag.weight;
      //     this.diagnose.bmi = dataDiag.bmi;
      //     this.diagnose.height = dataDiag.height;
      //     this.diagnose.blood_pressure = dataDiag.blood_pressure;
      //     this.diagnose.allergy = dataDiag.allergy;
      //     this.diagnose.doctor = dataDiag.doctor;
      //     this.diagnose.visit_type = dataDiag.visit_type;
      //     this.diagnose.main_complaint = dataDiag.main_complaint;
      //     this.diagnose.vital_sign = dataDiag.vital_sign;
      //     this.diagnose.spell = dataDiag.spell;
      //     this.diagnose.disease = dataDiag.disease;
      //     // this.diagnose.disease_id = dataDiag.disease_id;
      //     this.diagnose.pak = dataDiag.pak;
      //     this.diagnose.doctor_action = dataDiag.doctor_action;
      //     this.diagnose.action_care = dataDiag.action_care;
      //     this.diagnose.follow_up = 1;
      //     this.diagnose.next_visit = dataDiag.next_visit;
      //     this.diagnose.drugs = dataDiag.drugs;
      //     this.reset()
      // });
    },

    storeResult() {
      this.dataResults.id = this.register_id;
      this.$validator.validateAll().then(async (res) => {
        if (!res) {
          this.$swalPPP
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            })
            .fire({
              icon: "error",
              title: "Please fill out the mandatory fields"
            });
        } else {
          try {
            this.isLoading = true;
            await this.$store.dispatch("drugTest/addDrugTest", this.dataResults);
            this.isLoading = false; // Set isLoading to false on success
            // //this.body.page = 1;
            this.getAllData();
            this.$bvModal.hide("modal-1");
            this.$swal({
              icon: "success",
              title: "Success",
              text: "Medical data has been updated"
            });
            this.dataResults = {
              id: "",
              clinic_name: "",
              amphetamine: "",
              metamphitamine: "",
              cocaine: "",
              ganja: "",
              benzoat: "",
              morphine: "",
              need_referral: ""
            };
            this.reset();
          } catch (err) {
            this.isLoading = false; // Set isLoading to false on error
            console.log("err", err);
            this.$swal("Error", this.$t("Error while add data"), "error");
            
          }
        }
      }).finally(() => {
        this.isLoading = false; // Ensure isLoading is set to false in case of any outcome
      });
    },

    diagnosePatient() {
      let sick_leave_days = Number(this.diagnose.sick_leave_day);
      this.diagnose.sick_leave_day = sick_leave_days;
      this.diagnose.disease = JSON.stringify(this.diagnose.disease);
      this.diagnose.drugs = JSON.stringify(this.diagnose.drugs);
      this.diagnose.disposable = JSON.stringify(this.diagnose.disposable);
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.$store
            .dispatch("patient/diagnosePatient", this.diagnose)
            .then(() => {
              //this.body.page = 1;
              this.getAllData();
              this.$bvModal.hide("modal-1");
              this.$swal({
                icon: "success",
                title: "Success",
                text: "Medical data has been updated"
              });
              this.diagnose = {
                id: "",
                init_complaint: "",
                weight: "",
                height: "",
                blood_pressure: "",
                allergy: "",
                visit_type: "",
                doctor: "",
                main_complaint: "",
                vital_sign: "",
                disease_id: "",
                spell: "",
                pak: "",
                drugs: [
                  {
                    drugs_id: "",
                    qty: "",
                    drugs_unit_id: ""
                  }
                ],
                doctor_action: "",
                action_care: "",
                follow_up: "",
                next_visit: "",
                doctor_note: ""
              };
              this.reset();
            });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
        }
      });
    },

    diagnosePatientVisit2() {
      let sick_leave_days = Number(this.diagnose.sick_leave_day);
      // this.diagnose.sick_leave_day = sick_leave_days;
      this.$validator.validateAll().then(async (res) => {
        const formData = new FormData();
        formData.append("id", this.diagnose.id);
        formData.append("doctor", this.diagnose.doctor);
        formData.append("doctor_note", this.diagnose.doctor_note);
        formData.append("disease", JSON.stringify(this.diagnose.disease));
        // formData.append("drugs", JSON.stringify(this.diagnose.drugs));
        // formData.append("disposable", JSON.stringify(this.diagnose.disposable));
        formData.append("vital_sign", this.diagnose.vital_sign);
        formData.append("pak", this.diagnose.pak);
        formData.append("spell", this.diagnose.spell);
        formData.append("sick_leave", this.diagnose.sick_leave);
        formData.append("star_date", this.diagnose.star_date);
        formData.append("sick_leave_day", sick_leave_days);
        for (let file_doctor_letter of this.diagnose.file_doctor_letter) {
          formData.append("file_doctor_letter", file_doctor_letter); // note, no square-brackets
        }
        for (let file_receipt_letter of this.diagnose.file_receipt_letter) {
          formData.append("file_receipt_letter", file_receipt_letter); // note, no square-brackets
        }
        for (let file_sick_letter of this.diagnose.file_sick_letter) {
          formData.append("file_sick_letter", file_sick_letter); // note, no square-brackets
        }
        if (!res) return false;
        try {
          this.$store
            .dispatch("patient/addNoPartnership", formData)
            .then(() => {
              //this.body.page = 1;
              this.getAllData();
              this.$bvModal.hide("modal-1");
              this.$swal({
                icon: "success",
                title: "Success",
                text: "Medical data has been updated"
              });
              this.reset();
            });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
        }
      });
    },

    deleteData(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("patient/deletePatient", id)
            .then((resp) => {
              //this.body.page = 1;
              this.getAllData();
              this.$swal(
                "Deleted!",
                "This Record has been deleted!",
                "success"
              );
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    },

    saveRecord() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.$store
            .dispatch("patient/diagnosePatient", this.mcuData)
            .then(() => {
              this.getAllData();
              this.$bvModal.hide("modal-1");
              this.mcuData = {
                register_id: "",
                mcu_record: []
              };
              this.$swal({
                icon: "success",
                title: "Success",
                text: "Patient has been Register"
              });
            });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
        }
      });
    },

    validationFeature(value) {
      const clinicFeature =
        this.role_user.length > 0
          ? this.role_user[0].healthcare_facility_feature
          : [];
      const invent = clinicFeature.findIndex(
        (el) =>
          el.healthcare_facility_feature_id === "1" ||
          el.healthcare_facility_feature_id === "3"
      );
      // const index = value.findIndex((e) => e.clinic_feature_id === "1" || e.clinic_feature_id === "3");
      if (invent > -1) {
        return false;
      } else {
        return true;
      }
    },
    validationFeatureTable(value) {
      const clinicFeature =
        this.role_user.length > 0
          ? this.role_user[0].healthcare_facility_feature
          : [];
      const invent = clinicFeature.findIndex(
        (el) =>
          el.healthcare_facility_feature_id === "1" ||
          el.healthcare_facility_feature_id === "3"
      );
      // const index = value.findIndex((e) => e.clinic_feature_id === "1" || e.clinic_feature_id === "3");
      if (invent > -1) {
        return true;
      } else {
        return false;
      }
    },

    seeHistory(dat) {
      localStorage.setItem("dataMedical", JSON.stringify(dat));
      localStorage.setItem("seehistory", JSON.stringify(dat));
      localStorage.setItem("breadcrumb", "Drug Test");
      this.$router.push("/clinic-mcu/history");
    },

    detailPatient(dat) {
      localStorage.setItem("registerID", dat.register_id);
      localStorage.setItem("dataMedical", JSON.stringify(dat));
      localStorage.setItem("breadcrumb", "Drug Test");
      this.$router.push("/clinic-management/drug_test/detail");
    },
    detailPatientMCU(dat) {
      let newData = {
        nik: dat.nik,
        employee_name: dat.patient_name,
        company: dat.company,
        department: dat.department,
        position: dat.position
      };
      localStorage.setItem("registerID", dat.register_id);
      localStorage.setItem("MCUID", dat.register_id);
      localStorage.setItem("seehistory", JSON.stringify(newData));
      localStorage.setItem("breadcrumb", "Drug Test");
      // this.$router.push("/clinic-mcu/history/record-mcu");
    },

    // api hit
    getCategoryMCU() {
      this.$store.dispatch("categoryMcu/getCategory");
    },
    async getCategoryDisease() {
      let body = {
        limit: 1000,
        page: 1
      };
      this.$store.dispatch("category/getCategory", body);
    },
    async getBlock() {
      let body = {
        limit: 1000,
        page: 1
      };
      await this.$store.dispatch("block/getBlock", body);
    },
    async getChapter() {
      let body = {
        limit: 1000,
        page: 1
      };
      await this.$store.dispatch("chapter/getChapter", body);
    },
    async getDesease(e) {
      // let body = {
      //   limit: 500,
      //   page: 1,
      // };
      await this.$store.dispatch("disease/getDesease");
    },
    async getDoctor() {
      await this.$store.dispatch("patient/getDoctor");
    },

    async getDetailDisease(e) {
      let id = e;
      await this.$store.dispatch("disease/getDetailDisease", id);
    },

    getCategorys() {
      let body = {
        limit: 1000,
        page: 1
      };
      this.$store.dispatch("categoryMcuTest/getCategory", body);
    },
    getGroup() {
      let body = {
        limit: 1000,
        page: 1
      };
      this.$store.dispatch("groupMcu/getGroup", body);
    },
    getItem() {
      let body = {
        limit: 1000,
        page: 1
      };
      this.$store.dispatch("itemMcu/getItem", body);
    },

    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.desease_observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.desease_observer.disconnect();
    },

    getAllData() {
      this.load = true;
      this.$store.dispatch("drugTest/getAllData", this.body).then(() => {
        this.load = false;
      });
    },
    getTreatment() {
      this.load = true;
      let body = {
        limit: 100,
        page: 1
      };
      this.$store.dispatch("treatment/getTreatment", body).then(() => {
        this.load = false;
      });
    },

    getEmployee() {
      if (this.search.date_of_birth || this.search.fullname || this.search.nik || this.search.no_ktp) { 
        this.load = true;
      this.$store.dispatch("roleManagement/getUser", this.search).then(() => {
        let roleLogin = localStorage.getItem("roles_id");
        let resFilter = [];
        const companyUser = localStorage.getItem("company");

        if (roleLogin === "1" || roleLogin === "3") {
          resFilter = this.employees;
        } else {
          resFilter = this.employees.filter((el) => el.company_id === companyUser);
        }

        // Prioritize data based on "flag" when there are multiple entries with the same "nik"
        const uniqueNikMap = new Map();
        resFilter.forEach((employee) => {
          const nik = employee.nik;
          if (
            !uniqueNikMap.has(nik) ||
            this.compareFlags(employee.flag, uniqueNikMap.get(nik).flag) > 0
          ) {
            uniqueNikMap.set(nik, employee);
          }
        });

        // Convert the Map values back to an array
        this.employeesList = Array.from(uniqueNikMap.values());
        this.load = false;
        this.searchEmployee = true;
      });
      }else {
        this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            })
            .fire({
              icon: "warning",
              title: "Fill field above atleast 1 of them"
            });
            this.searchEmployee = false
      }
    },
    getDrug() {
      this.loadDate = true;
      let body = "MEDICINE";
      this.$store.dispatch("patient/getInventoryClinic", body).then(() => {
        this.loadDate = false;
        let datas = this.$store.getters["patient/dataInventoryClinic"];
        var option = [];
        datas.map((item) => {
          option.push({
            id: item.id,
          });
        });
        this.disposableItemOption = datas;
      });
    },
    getUnit() {
      let body = {
        page: 1,
        limit: 10,
        order: "",
        unit_name: ""
      };
      this.$store.dispatch("unit/getUnit", body);
    },
    productUnit(id, index) {
      this.$store.dispatch("po/getUnitProduct", id).then(() => {
        let data = this.$store.getters["po/UnitProductData"];
        return data;
      });
      let body = "DISPOSABLE-ITEM";
      this.$store.dispatch("patient/getInventoryClinic", body).then(() => {
        this.loadDate = false;
        let datas = this.$store.getters["patient/dataInventoryClinic"];
        var rec2 = datas.filter((i) => {
          return i.id == id;
        });
        var dat = rec2[0].expiry_date.map((i) => {
          if (i === null) {
            return "";
          } else {
            return i;
          }
        });
        this.diagnose.disposable[index].disposableExpireDate = dat;
      });
    },
    receiptUnit(id, index) {
      this.$store.dispatch("po/getUnitProduct", id).then(() => {
        let data = this.$store.getters["po/UnitProductData"];
        return data;
      });
      let body = "MEDICINE";
      this.$store.dispatch("patient/getInventoryClinic", body).then(() => {
        this.loadDate = false;
        let datas = this.$store.getters["patient/dataInventoryClinic"];
        var rec2 = datas.filter((i) => {
          return i.id == id;
        });
        var dat = rec2[0].expiry_date.map((i) => {
          if (i === null) {
            return "";
          } else {
            return i;
          }
        });
        this.diagnose.drugs[index].drugExpireDate = dat;
      });

      let bodyReport = {
        page: 1,
        perPage: 10,
        searchQuery: "",
        sort: "",
        item_id: id,
        type: "MEDICINE"
      };

      this.$store.dispatch("reporting/getListDrugs", bodyReport).then(() => {
        let datas = this.$store.getters["reporting/listDrugsData"];
        let rec = datas.rows.filter((i) => {
          return (i.item_id = id);
        });
        this.diagnose.drugs[index].dataAddDrugs = rec;
      });
    },
    compareFlags(flag1, flag2) {
      const flagPriority = { Keycloak: 1, iCore: 2, Uworkforce: 3 };

      if (flagPriority[flag1] < flagPriority[flag2]) {
        return -1;
      } else if (flagPriority[flag1] > flagPriority[flag2]) {
        return 1;
      } else {
        return 0; // Flags are equal in priority
      }
    },
    checkVal(id) {
      if (id !== "") {
        return false;
      } else {
        return true;
      }
    },
    receiptQTY(qty, id) {
      let bodyReport = {
        page: 1,
        perPage: 10,
        searchQuery: "",
        sort: "",
        item_id: id,
        type: "MEDICINE"
      };
      this.$store.dispatch("reporting/getListDrugs", bodyReport).then(() => {
        let datas = this.$store.getters["reporting/listDrugsData"];
        let Quantity = datas.rows.map((i) => {
          return i.base_qty;
        });
        if (Number(qty) > Number(Quantity)) {
          this.$swal("Error", this.$t("Quantity more than stock"), "error");
        }
      });
    },
    getCategory() {
      let body = {
        limit: 100,
        page: 1
      };
      this.$store.dispatch("categoryMcuTest/getCategory", body);
    },

    getCategory() {
      this.$store.dispatch("patient/getCategoryMcu");
    },

    // filter
    filterPage() {
      this.body.limit = 10,
      this.body.page = 1
      this.getAllData();
    },

    sorting() {
      this.getAllData();
    },

    onSortChange(params) {
        let sort1 = params[0].field;
        let sort2 = params[0].type;
        this.body.sort = `${sort1},${sort2}`;
        this.getAllData();
      },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getAllData();
    },

    // disease

    prevDisease() {
      let page = this.desease_paramater.page;
      page -= 1;
      page = page <= 1 ? 1 : page;

      this.desease_paramater.page = page;
      this.getDesease();
    },

    nextDisease() {
      const disase_data = this.paginated;
      const total_page = disase_data.totalPages;
      let page = this.desease_paramater.page;
      page += 1;
      page = page <= 1 ? 1 : page;
      if (page >= total_page) {
        page = totalPages;
      }

      this.desease_paramater.page = page;
      this.getDesease();
    },

    async searchDisease(search, loading) {
      if (search) {
        loading(true);

        clearTimeout(this.desease_timeout);
        this.desease_timeout = setTimeout(async () => {
          this.desease_paramater.disease_name = search;

          await this.getDesease();

          loading(false);
        }, 500);
      }
    }
  }
};
</script>
<style scoped>
.positive-result-row {
  background-color: red; /* Set the background color to red for positive results */
  color: white; /* Set the text color to white for better visibility */
}
.pagination-select {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}
.pagination-select button {
  flex-grow: 1;
}
.pagination-select button:hover {
  cursor: pointer;
}
</style>