<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-4">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item  fw-bolder">
                  {{ $t("Medical & MCU Record") }}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Filter -->
      <!-- end of filter -->

      <div class="card card-fill">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">Filter</h4>

          <a href="#" class="small" @click.prevent="resetFilter">Reset Form</a>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              <div class="form-group">
                <label class="form-label">{{ $t("NIK") }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="body.nik"
                  v-on:keyup.enter="filterPage()" />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label class="form-label"> Name </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="body.employee_name"
                  v-on:keyup.enter="filterPage()" />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label>{{ $t("Company") }}</label>
                <v-select
                  :options="selectCompanyOption()"
                  v-model="body.company_id"
                  label="company_name"
                  :clearable="false"
                  :reduce="(e) => e.id"
                  @input="filterPage()" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="form-label"> Department </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="body.department"
                  v-on:keyup.enter="filterPage()" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="form-label"> Position </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="body.position"
                  v-on:keyup.enter="filterPage()" />
              </div>
            </div>
          </div>
        </div>
        <!-- / .card-body -->
      </div>

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("Medical & MCU Records") }}</h4>
          <!-- Button -->
          <!-- <b-button variant="primary" @click="download()" class="mr-3">
            <span class="fe fe-download"></span>
            Export
          </b-button> -->
          <!-- <b-button
            v-b-toggle.collapse-1
            variant="primary"
            style="margin-left: 7px">
            <span class="fe fe-sliders"></span>
          </b-button> -->
          <!-- <span :class="`fe fe-chevron-${toggle()}`"></span> -->
        </div>

        <b-tabs class="" justified fill>
          <b-tab title="Medical" style="padding: 0px" @click="medical()">
            <vue-good-table
              mode="remote"
              :line-numbers="true"
              :columns="columns"
              :rows="mrc.data"
              :sort-options="{
                enabled: true
              }"
              :total-rows="mrc.totalItems"
              :pagination-options="{
                enabled: true,
                perPage: body.limit
              }"
              style-class="vgt-table table table-bordered"
              :isLoading.sync="isLoading"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-per-page-change="onPageChange">
              <!-- Loading -->
              <div slot="emptystate" class="text-center font-size-14">
                No data available
              </div>

              <!-- Custom Rows -->
              <template slot="table-row" slot-scope="props">
                <!-- Table Action -->
                <span v-if="props.column.field == 'action'">
                  <b-button
                    class="no-wrap btn-eye"
                    variant="info"
                    size="sm"
                    @click.prevent="seeHistory(props.row)">
                    <span class="fe fe-eye"></span>
                  </b-button>
                </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-end">
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="mrc.totalItems"
                      :per-page="body.limit"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="m-3"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      ">
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-tab>
          <b-tab title="MCU Record" style="padding: 0px" @click="mcurecord()">
            <vue-good-table
              mode="remote"
              :line-numbers="true"
              :columns="columns"
              :rows="mrc.data"
              :sort-options="{
                enabled: true
              }"
              :total-rows="mrc.totalItems"
              :pagination-options="{
                enabled: true,
                perPage: body.limit
              }"
              style-class="vgt-table table table-bordered"
              :isLoading.sync="isLoading"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-per-page-change="onPageChange">
              <!-- Loading -->
              <div slot="emptystate" class="text-center font-size-14">
                No data available
              </div>

              <!-- Custom Rows -->
              <template slot="table-row" slot-scope="props">
                <!-- Table Action -->
                <span v-if="props.column.field == 'action'">
                  <b-button
                    class="no-wrap btn-eye"
                    variant="info"
                    size="sm"
                    @click.prevent="seeHistory(props.row)">
                    <span class="fe fe-eye"></span>
                  </b-button>
                </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-end">
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="mrc.totalItems"
                      :per-page="body.limit"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="m-3"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      ">
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { filter } from "vue-cli-plugin-bootstrap-vue/sassAbstractsImports";

export default {
  name: "medicalRecordMcu",
  computed: {
    mrc() {
      return this.$store.getters["medicalRecordMcu/MRCData"]
        ? this.$store.getters["medicalRecordMcu/MRCData"]
        : [];
    },
    company() {
      return this.$store.getters["detailManagement/dataCompany"]
        ? this.$store.getters["detailManagement/dataCompany"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        limit: 10,
        sort: "",
        type_visit: "Rawat Jalan",
        nik: "",
        employee_name: "",
        department: "",
        position: "",
        company_id: ""
      },
      hazard_name: "",
      hazard_names: "",
      id: "",
      isAdd: true,
      isLoading: false,
      isToggle: false,
      columns: [
        {
          label: "NIK",
          field: "nik",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "NAME",
          field: "employee_name",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "COMPANY",
          field: "company",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "DEPARTEMENT",
          field: "department",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "POSITION",
          field: "position",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          sortable: false,
          thClass:
            "table-header text-center no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle"
        }
      ]
    };
  },
  mounted() {
    this.body.company_id = localStorage.getItem("company");
    if (this.body.company_id == "") {
      this.body.company_id = "0";
    }
    this.getMRC();
    this.getCompany();
  },
  methods: {
    resetFilter() {
      this.body = {
        nik: "",
        // company_id :"",
        department: "",
        position: "",
        employee_name: "",
        start_date: "",
        end_date: ""
      };
    },
    // api hit
    getMRC() {
      this.isLoading = true;
      this.$store
        .dispatch("medicalRecordMcu/getMRCData", this.body)
        .then(() => {
          this.isLoading = false;
        });
    },
    getCompany() {
      this.$store.dispatch("detailManagement/getCompany");
    },

    // download() {
    //   let body = {
    //     // date_input: this.date_input,
    //     company_id: localStorage.getItem("company")
    //   };
    // },

    selectCompanyOption() {
      const company = this.company;
      const term = [];
      term.push({
        id: "0",
        company_name: "BIB + Mitra"
      });

      company.map((i) => {
        term.push({
          id: i.id,
          company_name: i.company_name
        });
      });

      return term;
    },

    // filter
    filterPage() {
      this.getMRC();
    },

    sorting() {
      this.getMRC();
    },

    medical() {
      this.body.type_visit = "Rawat Jalan";
      this.getMRC();
    },

    mcurecord() {
      this.body.type_visit = "MCU";
      this.getMRC();
    },

    seeHistory(e) {
      let newData = {
        nik: e.nik,
        patient_name: e.employee_name,
        type_visit: "Rawat Jalan",
        status: 3
      };
      localStorage.setItem("seehistory", JSON.stringify(e));
      localStorage.setItem("breadcrumb", "Medical & MCU Record");
      localStorage.setItem("dataMedical", JSON.stringify(newData));
      this.$router.push("/clinic-mcu/history");
    },

    toggle() {
      if (this.isToggle == true) {
        return "up";
      } else {
        return "down";
      }
      // let toggle = controlsEnabled = !controlsEnabled
    },
    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getMRC();
    },
    onSortChange(params) {
      let sort1 = params[0].field;
      let sort2 = params[0].type;
      this.body.sort = `${sort1},${sort2}`;
      this.getMRC();
    }
  }
};
</script>
