import queryString from "query-string";

import {
    GET_OVERHANDLE,
    POST,
    SEND_OVERHANDLE
} from "@/api/overhandle/overhandle";

const state = {
    dataOverhandle: [],
};

const mutations = {
    setOverhandle: (states, payload) => {
        states.dataOverhandle = payload;
    },
};

const actions = {
    getOverhandle: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_OVERHANDLE(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    console.log(resp.data);
                    commit("setOverhandle", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setOverhandle", {
                        setOverhandle: [],
                    });
                }
            });
    },

    addOverhandle: async ({}, args) => {
        return await SEND_OVERHANDLE(args);
    },

    delCategory: async ({}, id) => {
        return await DEL_CATEGORY(id);
    },

    updateCategory: async ({}, args) => {
        return await UPDATE_CATEGORY(args);
    },



};

const getters = {
    overhandleData: (states) => states.dataOverhandle
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};