import axiosIns from '@/api/axios'

export const GET_DISEASE_DATA = (args) => {
    return axiosIns.get(`/disease?${args}`)
}

export const DISIASE_DIAGNOSE = (args) => {
    return axiosIns.get(`/disease/search?${args}`)
}


export const DISEASE_DETAIL = (id) => {
    return axiosIns.get(`/disease/byId?id=${id}`)
}

export const ADD_DESEASE = (args) => {
    return axiosIns.post(`/disease/create`, args)
}

export const DEL_DESEASE = (id) => {
    return axiosIns.put(`/disease/delete?id=${id}`)
}

export const UPDATE_DISEASE = (args) => {
    return axiosIns.put(`/disease/update?id=${args.id}`, args)
}