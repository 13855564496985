var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{class:`cart ${ _vm.load === true ? '' : 'd-none'}`},[_vm._m(0)]),_c('div',{class:`cart ${ _vm.load === true ? 'd-none' : ''}`},[_c('b-collapse',{attrs:{"id":"collapse-1"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sorting()}}},[_c('div',{staticClass:"row mt-4",staticStyle:{"margin-left":"15px","margin-right":"15px"}},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"exampleInputEmail1"}},[_vm._v(_vm._s(_vm.$t('Company')))]),_c('v-select',{attrs:{"options":[
                                { label: 'UGEMS', id: 'UGEMS' },
                                { label: 'Februari', id: 'Februari' },
                                { label: 'Maret', id: 'Maret' },
                            ]}})],1)]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"exampleInputEmail1"}},[_vm._v(_vm._s(_vm.$t('Monthly')))]),_c('v-select',{attrs:{"options":[
                                { label: 'Januari', id: 'Januari' },
                                { label: 'Februari', id: 'Februari' },
                                { label: 'Maret', id: 'Maret' },
                            ]}})],1)])]),_c('div',{staticClass:"row mb-3",staticStyle:{"margin-right":"15px"}},[_c('div',{staticClass:"col"}),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-danger lift",attrs:{"type":"submit"}},[_vm._v("Filter")])])])])]),_c('vue-good-table',{attrs:{"line-numbers":true,"columns":_vm.columns,"multiple":"","rows":_vm.ohDaily.rows,"sort-options":{
        enabled: true,
      },"total-rows":_vm.ohDaily.totalRecords,"pagination-options":{
        enabled: true,
        mode: 'pages',
        position: 'bottom',
        perPage: _vm.body.perPage,
        perPageDropdown: [10, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        allLabel: 'All',
      },"style-class":"vgt-table border-0 table"},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'image_path')?_c('span',_vm._l((props.row.image_path),function(i,index){return _c('img',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-2",modifiers:{"modal-2":true}}],key:index,staticStyle:{"max-height":"100px"},attrs:{"src":_vm.url + i,"alt":""},on:{"click":function($event){return _vm.fotoPreview(props.row)}}})}),0):_vm._e()]}}])},[_c('div',{staticClass:"text-center font-size-14",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" No data available ")])])],1),_c('b-modal',{attrs:{"id":"modal-2","size":"xl","hide-footer":"","title":_vm.$t('Foto Documentation')}},[_c('b-carousel',{staticClass:"mycarousel",staticStyle:{"text-shadow":"1px 1px 2px #333"},attrs:{"id":"carousel1","controls":"","indicators":"","background":"white","interval":4000,"img-width":"1024","img-height":"300"}},_vm._l((_vm.payloads.files),function(i,index){return _c('b-carousel-slide',{key:index,staticClass:"item row"},[_c('img',{staticClass:"d-block img-fluid w-100",attrs:{"slot":"img","src":_vm.url + i,"alt":"factory picture"},slot:"img"})])}),1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body text-center"},[_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])])])
}]

export { render, staticRenderFns }