import axiosIns from '@/api/axios'

export const GET_UNIT_PRODUCT = (args) => {
    return axiosIns.get(`/inventory/po/cart/item?id=${args}`)
}
export const ADD_UNIT_PRODUCT = (args) => {
    return axiosIns.post(`/inventory/entry-stock/add`, args)
}
export const GET_INPUT_PRODUCT = (args) => {
    return axiosIns.get(`/inventory/entry-stock?${args}`)
}
export const DEL_INPUT_PRODUCT = (args) => {
    return axiosIns.delete(`/inventory/entry-stock/delete?id=${args}`)
}

export const UPDATE_INNER_PO = (args) => {
    return axiosIns.put(`/inventory/entry-stock/update`, args)
}
export const COPY_PRODUCT = (args) => {
    return axiosIns.put(`/inventory/entry-stock/split?${args}`)
}

export const SUBMIT_STOCK_PO = (args) => {
    return axiosIns.post(`/inventory/entry-stock/submit-po`, args)
}
export const SUBMIT_STOCK_DO = (args) => {
    return axiosIns.post(`/inventory/entry-stock/submit-do`, args)
}
export const GET_LIST_PO = (args) => {
    return axiosIns.get(`/inventory/po?${args}`)
}
export const DELETE_LIST_PO = (args) => {
    return axiosIns.delete(`/inventory/po?${args}`)
}
export const GET_DETAIL_PO = (args) => {
    return axiosIns.get(`/inventory/po/detail?${args}`)
}
export const GET_EDIT_PO = (args) => {
    return axiosIns.get(`/inventory/po/edit?${args}`)
}

export const GET_DO_LIST = (args) => {
    return axiosIns.get(`/inventory/do/lite?id=${args}`)
}

export const SUBMIT_GR = (args) => {
    return axiosIns.post(`/inventory/do/gr`, args)
}


export const GET_DETAIL_DO = (args) => {
    return axiosIns.get(`/inventory/po/detail?id=${args}`)
}