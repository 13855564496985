<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <!-- / .row -->

      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">Role</h4>
          <!-- Button -->
          <!-- <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
                        <span class="fe fe-file-plus"></span>
                        Role
                    </b-button> -->
          <b-button
            v-b-toggle.collapse-1
            variant="primary"
            style="margin-left: 7px">
            <span class="fe fe-sliders"></span>
          </b-button>
        </div>
        <b-collapse id="collapse-1">
          <form @submit.prevent="sorting()">
            <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Role Name")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="body.search" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label"
                    >Order</label
                  >
                  <v-select v-model="body.order" :options="['ASC', 'DESC']" />
                </div>
              </div>
            </div>
            <div class="row mb-3" style="margin-right: 15px">
              <div class="col"></div>
              <div class="col-auto">
                <!-- Button -->
                <button type="submit" class="btn btn-primary lift">
                  {{ $t("Filter") }}
                </button>
              </div>
            </div>
            <!-- / .row -->
          </form>
        </b-collapse>
        <vue-good-table
          :line-numbers="true"
          :columns="columns"
          :rows="role.data"
          :sort-options="{
            enabled: true
          }"
          :total-rows="role.totalItems"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }"
          style-class="vgt-table border-0 table"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange">
          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <!-- Custom Rows -->
          <template slot="table-column" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.label == 'ROLE'">
              {{ $t("Role") }}
            </span>
            <span v-if="props.column.label == 'Description'">
              Description
            </span>
            <span v-if="props.column.label == 'ACTION'">
              {{ $t("Action") }}
            </span>
          </template>
          <!-- Custom Rows -->
          <template slot="table-row" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.field == 'action'">
              <b-button
                class="no-wrap btn-pill"
                variant="warning"
                size="sm"
                @click="updateData(props.row)">
                <span class="fe fe-edit"></span>
              </b-button>
              <!-- <b-button class="no-wrap ml-2" variant="danger" style="margin-left: 5px" size="sm"
                                @click="deleteData(props.row.id)">
                                <span class="fe fe-trash-2"></span>
                            </b-button> -->
            </span>
          </template>
        </vue-good-table>
      </div>

      <!-- Modal -->
      <b-modal id="modal-1" centered hide-footer :title="$t('Role Form')">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="form-group">
            <label>{{ $t("Role Name") }}</label>
            <input
              v-validate="'required'"
              name="Role Name"
              type="text"
              v-model="bodyAdd.roles_name"
              class="form-control"
              placeholder="Role Name" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Role Name')"
              >{{ errors.first("Role Name") }}</span
            >
          </div>
          <div class="form-group">
            <label>Description</label>
            <textarea
              type="text"
              v-model="bodyAdd.description"
              class="form-control"
              placeholder="Description" />
          </div>
          <div class="form-group">
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="form-group">
            <label>{{ $t("Role Name") }}</label>
            <input
              v-validate="'required'"
              name="Role Name"
              type="text"
              v-model="bodyUpdate.roles_name"
              class="form-control"
              placeholder="Role Name" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Role Name')"
              >{{ errors.first("Role Name") }}</span
            >
          </div>
          <div class="form-group">
            <label>Description</label>
            <textarea
              type="text"
              v-model="bodyUpdate.description"
              class="form-control"
              placeholder="Description" />
          </div>
          <div class="form-group">
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "defaultCMS",
  computed: {
    role() {
      return this.$store.getters["role/RoleData"]
        ? this.$store.getters["role/RoleData"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        limit: 10
        // order: "",
        // search: "",
      },
      bodyAdd: {
        roles_name: "",
        description: ""
      },
      bodyUpdate: {
        id: "",
        roles_name: "",
        description: ""
      },
      isAdd: true,
      isLoading: false,
      columns: [
        {
          label: "ROLE",
          field: "roles_name",
          thClass: "table-header text-muted w-50 font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "Description",
          field: "description",
          thClass: "table-header text-muted w-50 font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ]
    };
  },
  mounted() {
    // if (this.role && this.role.length < 1) {
    this.getRole();
    // }
  },
  methods: {
    //action
    updateData(props) {
      this.$bvModal.show("modal-1");
      this.isAdd = false;
      this.bodyUpdate.id = props.id;
      this.bodyUpdate.roles_name = props.roles_name;
      this.bodyUpdate.description = props.description;
    },
    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.isLoading = true;
          this.$store.dispatch("role/addRole", this.bodyAdd).then((resp) => {
            this.$swal({
              title: this.$t("Saved"),
              text: this.$t("Your input has been saved"),
              icon: "success",
              confirmButtonText: this.$t("OK")
            }).then((resp) => {
              this.isLoading = false;
              this.role_name = "";
              this.getRole();
              this.$bvModal.hide("modal-1");
            });
          });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
        }
      });
    },

    saveUpdate() {
      this.isLoading = true;
      this.$store
        .dispatch("role/updateRole", this.bodyUpdate)
        .then((resp) => {
          this.$swal({
            title: this.$t("Saved"),
            text: this.$t("Your input has been saved"),
            icon: "success",
            confirmButtonText: this.$t("OK")
          }).then((resp) => {
            this.isLoading = false;
            this.getRole();
            this.$bvModal.hide("modal-1");
          });
        })
        .catch((resp) => {
          this.$swal("Error", this.$t("Error while add data"), "error");
        });
    },
    deleteData(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          this.$store
            .dispatch("role/delRole", id)
            .then((resp) => {
              this.isLoading = false;
              this.getRole();
              this.$swal(
                "Deleted!",
                this.$t("Your file has been deleted"),
                "success"
              );
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    },

    // api hit
    getRole() {
      this.$store.dispatch("role/getRole", this.body);
    },

    // filter
    filterPage() {
      this.getRole();
    },

    sorting() {
      // this.onPageChange((payload) => {
      //   // payload.currentPerPage=  10,
      //   // payload.currentPage= 1
      // })

      this.getRole();
      this.body.page = 1;
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getRole();
      // if (
      //   parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
      //   1000
      // ) {
      // }
    }
  }
};
</script>
