import axiosIns from '@/api/axios'

export const GET_QUESTION_DATA = (args) => {
    return axiosIns.get(`/mcu/question?${args}`)
}

export const ADD_QUESTION = (args) => {
    return axiosIns.post(`/mcu/question/create`, args)
}

export const DEL_QUESTION = (id) => {
    return axiosIns.put(`/mcu/question/delete?id=${id}`)
}

export const UPDATE_QUESTION = (args) => {
    return axiosIns.put(`/mcu/question/update?id=${args.id}`, args)
}