import axiosIns from '@/api/axios'

export const GET_GROUP_DATA = (args) => {
    return axiosIns.get(`/mcu/test/group?${args}`)
}

export const ADD_GROUP = (args) => {
    return axiosIns.post(`/mcu/test/group/create`, args)
}

export const DEL_GROUP = (id) => {
    return axiosIns.put(`/mcu/test/group/delete?id=${id}`)
}

export const UPDATE_GROUP  = (args) => {
    return axiosIns.put(`/mcu/test/group/update?id=${args.id}`, args)
}