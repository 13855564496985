export default {
    // English
    en: {
        // MENU 
        'Dashboard': 'Dashboard',
        'Master Data': 'Master Data',
        'Disease': 'Disease',
        'Chapter': 'Chapter',
        'Block': 'Block',
        'Category': 'Category',
        'Disease': 'Disease',
        'Inventory': 'Inventory',
        'Drug Category': 'Drug Category',
        'Drug': 'Drug',
        'Inventory': 'Inventory',
        'Chemical': 'Chemical',
        'Material Type': 'Material Type',
        'Chemical Hazard': 'Chemical Hazard',
        'Material Name': 'Material Name',
        'Medical': 'Medical',
        'Medicine': 'Medicine',
        'Facility': 'Facility',
        'Treatment': 'Treatment',
        'Staff - Position': 'Staff - Position',
        'Staff - Training - Group': 'Staff - Training - Group',
        'Staff - Training - SubGroup': 'Staff - Training - SubGroup',
        'MCU': 'MCU',
        'Category - Category': 'Category - Category',
        'Category - Result': 'Category - Result',
        'Test - Group': 'Test - Group',
        'Test - Item': 'Test - Item',
        'Question Type': 'Question - Type',
        'Question': 'Question',
        'IH': 'IH',
        'Action': 'Action',
        'Factor': 'Factor',
        'Measuring - Activity': 'Measuring - Activity',
        'Measuring - Method': 'Measuring - Method',
        'Measuring - Unit': 'Measuring - Unit',
        'OH': 'OH',
        'Activity': 'Activity',
        'Indicator': 'Indicator',
        'Data': 'Data',
        'Document': 'Document',
        'Yearly': 'Yearly',
        'Healthcare Facility': 'Healthcare Facility',
        'OH Management': 'OH Management',
        'Input': 'Input',
        'Correction': 'Correction',
        'Report': 'Report',
        'Health Attendance Data': 'Health Attendance Data',
        'Clinic Management': 'Clinic Management',
        'Patient': 'Patient',
        'Input PO': 'Input PO',
        'List PO': 'List PO',
        'Report Stock': 'Report Stock',
        'Export': 'Export',
        'CREATED BY': 'CREATED BY',
        'Measurement': 'Measurement',
        'Doctor Name': 'Doctor Name',
        'TYPE VISIT': 'TYPE VISIT',
        'Disposal': 'Disposal',
        'IH Management': 'IH Management',
        'Stock Opname': 'Stock Opname',
        'Summary Stock': 'Summary Stock',
        'Entry Stock': 'Entry Stock',
        'Bin': 'Bin',
        'Drug Test': 'Drug Test',
        'Registration': 'Registration',
        'Medical Waste': 'Medical Waste',
        'Lab': 'Lab',
        'Medical & MCU Record': 'Medical & MCU Record',
        'Medical & MCU Records': 'Medical & MCU Records',
        'Medical Tool': 'Medical Tool',
        'Disposable Item': 'Disposable Item',
        'Role Management': 'Role Management',
        'Medicine Category': 'Medicine Category',
        'Leading': 'Leading',
        'Medical Tool Category': 'Medical Tool Category',
        'Group Activity': 'Group Activity',
        'Lagging Indicator': 'Lagging Indicator',
        'Disposable Item Category': 'Disposable Item Category',
        'Measuring - Kit': 'Measuring - Kit',
        'Measuring - Campaign': 'Measuring - Campaign',
        'Add Item': 'Add Item',
        'Upload Sick Letter': 'Upload Sick Letter',
        'Upload Receipt Letter': 'Upload Receipt Letter',
        'Upload Doctor Letter': 'Upload Doctor Letter',
        'Document Referral': 'Document Referral',
        'NIK': 'NIK',
        'History': 'History',
        'MCU Record': 'MCU Record',
        'Test Date': 'Test Date',
        'Registration Date': 'Registration Date',
        'Type visit': 'Type visit',
        'Status': 'Status',
        'Department': 'Department',
        'RESULT TEST': 'RESULT TEST',


        // CONTENT 
        'Welcome to GEMS Uhealth': 'Welcome to GEMS Uhealth',
        'Add': 'Add',
        'Manual Input': 'Manual Input',
        'Save': 'Save',
        'Chemical Material Data Name': 'Chemical Material Data Name',
        'Chemical Material Data': 'Chemical Material Data',
        'Action': 'Action',
        'Chapter Name': 'Chapter Name',
        'Chapter Form': 'Chapter Form',
        'Filter': 'Filter',
        'Block Name': 'Block Name',
        'Block Form': 'Block Form',
        'Category Name': 'Category Name',
        'Category Form': 'Category Form',
        'Are you sure?': 'Are you sure?',
        'Cancel': 'Cancel',
        'Yes, delete it!': 'Yes, delete it!',
        "You won't be able to revert this!": "You won't be able to revert this!",
        'Your file has been deleted': 'Your file has been deleted',
        'Error while delete data': 'Error while delete data',
        'Your input has been saved': 'Your input has been saved',
        'Error while add data': 'Error while add data',
        'Saved': "Saved",
        'OK': 'OK',
        'Disease Name': 'Disease Name',
        'Generic Name': 'Generic Name',
        'Brand': 'Brand',
        'Manufacture': 'Manufacture',
        'Dosage': 'Dosage',
        'Generic': 'Generic',
        'Drug Form': 'Drug Form',
        'Unit Name': 'Unit Name',
        'Unit Form': 'Unit Form',
        'Material Type Data Name': 'Material Type Data Name',
        'Type Name': 'Type Name',
        'Material Type': 'Material Type',
        'Error while edit data': 'Error while edit data',
        'Name Form': 'Name Form',
        'Hazard Name': 'Hazard Name',
        'Hazard Data Name': 'Hazard Data Name',
        'Chemical Hazard Form': 'Chemical Hazard Form',
        'Facility Name': 'Facility Name',
        'Facility Form': 'Facility Form',
        'Treatment Name': 'Treatment Name',
        'Treatment Form': 'Treatment Form',
        'Position': 'Position',
        'Position Name': 'Position Name',
        'Position Form': 'Position Form',
        'Group Name': 'Group Name',
        'Sub Group Name': 'Sub Group Name',
        'Result': 'Result',
        'Result Name': 'Result Name',
        'Result Form': 'Result Form',
        'Result Point': 'Result Point',
        'Point': 'Point',
        'Item': 'Item',
        'Item Unit': 'Item Unit',
        'Item Name': 'Item Name',
        'Item Form': 'Item Form',
        'Description': 'Description',
        'Action Name': 'Action Name',
        'IH Action': 'IH Action',
        'Index': 'Index',
        'Factor Name': 'Factor Name',
        'IH Factor': 'IH Factor',
        'Activity Name': 'Activity Name',
        'IH Measuring Activity': 'IH Measuring Activity',
        'Activity Form': 'Activity Form',
        'Action Form': 'Action Form',
        'Factor Form': 'Factor Form',
        'Method': 'Method',
        'Method Name': 'Method Name',
        'IH Measuring Method': 'IH Measuring Method',
        'Method Form': 'Method Form',
        'IH Measuring Unit': 'IH Measuring Unit',
        'Data Flag': 'Data Flag',
        'Type': 'Type',
        'Mandatory': 'Mandatory',
        'Input period': 'Input period',
        'OH Name': 'OH Name',
        'Document Name': 'Document Name',
        'Name': 'Name',
        'Document Form': 'Document Form',
        'Indicator Name': 'Indicator Name',
        'Treshold': 'Treshold',
        'Percentage': 'Percentage',
        'Data Type': 'Data Type',
        'Value': 'Value',
        'Indicator Form': 'Indicator Form',
        'Medical Facility': 'Medical Facility',
        'Facility Feature': 'Facility Feature',
        'Healthcare Name': 'Healthcare Name',
        'Healthcare Form': 'Healthcare Form',
        'Medical Facility Name': 'Medical Facility Name',
        'Healthcare Facility Name': 'Healthcare Facility Name',
        'Competency': 'Competency',
        'Competency Name': 'Competency Name',
        'Health': 'Health',
        'Health Name': 'Health Name',
        'Safety': 'Safety',
        'Safety Name': 'Safety Name',
        'Company': 'Company',
        'Date': 'Date',
        'Documentation Type': 'Documentation Type',
        'Image': 'Image',
        'Add Data': 'Add Data',
        'Form Documentation': 'Form Documentation',
        'Year': 'Year',
        'Occupational Health Indicators': 'Occupational Health Indicators',
        'Month': 'Month',
        'Form Input OH Monthly': 'Form Input OH Monthly',
        'Occupational Health': 'Occupational Health',
        'Occupational Health Activity': 'Occupational Health Activity',
        'Period 1 January Until 31 December': 'Period 1 January Until 31 December',
        'Permit Holder': 'Permit Holder',
        'Mining Services Company': 'Mining Services Company',
        'Form Input OH Yearly': 'Form Input OH Yearly',
        'Safety Field': 'Safety Field',
        'Health Field': 'Health Field',
        'Competency Field': 'Competency Field',
        'Form Input OH Daily': 'Form Input OH Daily',
        'Monthly': 'Monthly',
        'Daily': 'Daily',
        'Documentation': 'Documentation',
        'Period': 'Period',
        'Indicator Rate': 'Indicator Rate',
        'OH Activity': 'OH Activity',
        'Form Add Documentation': 'Form Add Documentation',
        'Company Input': 'Company Input',
        'Product': 'Product',
        'Unit': 'Unit',
        'Purchase price': 'Purchase price',
        'Qty': 'Qty',
        'Notes': 'Notes',
        'Product Name': 'Product Name',
        'Code': 'Code',
        'Supplier': 'Supplier',
        'Information': 'Information',
        'Item Income': 'Item Income',
        'Code List': 'Code List',
        'Open': 'Open',
        'Out': 'Out',
        'Employee Name': 'Employee Name',
        'Visit Type': 'Visit Type',
        'Doctor': 'Doctor',
        'Diagnotic Result': 'Diagnotic Result',
        'Main Complaint': 'Main Complaint',
        'Type Of Disease': 'Type Of Disease',
        'Add Drugs': 'Add Drugs',
        'Drug Name': 'Drug Name',
        'Quantity': 'Quantity',
        'Remove': 'Remove',
        'Doctor Action': 'Doctor Action',
        'Action Care': 'Action Care',
        'Follow Up Visit': 'Follow Up Visit',
        'Date Of Next Visit': 'Date Of Next Visit',
        'Attachment': 'Attachment',
        'Division': 'Division',
        'Please Scan Barcode or Input Registration Code': 'Please Scan Barcode or Input Registration Code',
        'Registration Time': 'Registration Time',
        'Formula': 'Formula',
        'Type MCU': 'Type MCU',
        'Non Partnership Clinic': 'Non Partnership Clinic',
        
    },
    // Indonesia
    id: {
        // MENU 
        'Dashboard': 'Beranda',
        'Master Data': 'Data Master',
        'Disease': 'Penyakit',
        'Chapter': 'Bagian',
        'Block': 'Blok',
        'Category': 'Kategori',
        'Disease': 'Penyakit',
        'Inventory': 'Gudang',
        'Drug Category': 'Kategori Obat',
        'Drug': 'Obat',
        'Inventory': 'Gudang',
        'Chemical': 'Bahan Kimia',
        'Material Type': 'Jenis Bahan',
        'Chemical Hazard': 'Bahaya Kimia',
        'Material Name': 'Nama Bahan',
        'Medical': 'Medis',
        'Medicine': 'Obat',
        'Facility': 'Fasilitas',
        'Treatment': 'Pengobatan',
        'Staff - Position': 'Staf - Posisi',
        'Staff - Training - Group': 'Staf - Pelatihan - Grup',
        'Staff - Training - SubGroup': 'Staf - Pelatihan - SubGrup',
        'MCU': 'MCU',
        'Category - Category': 'Kategori - Katergori',
        'Category - Result': 'Kategori - Hasil',
        'Test - Group': 'Tes - Grup',
        'Test - Item': 'Test - Barang',
        'IH': 'IH',
        'Action': 'Aksi',
        'Factor': 'Faktor',
        'Measuring - Activity': 'Ukur - Aktifitas',
        'Measuring - Method': 'Ukur - Metode',
        'Measuring - Unit': 'Ukur - Satuan',
        'OH': 'OH',
        'Activity': 'Aktivitas',
        'Indicator': 'Indikator',
        'Data': 'Data',
        'Document': 'Dokumen',
        'Yearly': 'Tahunan',
        'Healthcare Facility': 'Fasilitas Kesehatan',
        'OH Management': 'Managemen OH',
        'Input': 'Masukkan',
        'Correction': 'Koreksi',
        'Report': 'Laporan',
        'Health Attendance Data': 'Data Absensi Kesehatan',
        'Clinic Management': 'Managemen Klinik',
        'Patient': 'Pasien',
        'Input PO': 'Masukkan PO',
        'List PO': 'PO List',
        'Report Stock': 'Laporan Stok',
        'Export': 'Ekspor',
        'CREATED BY': 'Dibuat Oleh',
        'Measurement': 'Pengukuran',
        'Doctor Name': 'Nama Dokter',
        'TYPE VISIT': 'TIPE KUNJUNGAN',
        'Disposal': 'Pembuangan',
        'IH Management': 'IH Manajemen',
        'Stock Opname': 'Stok Opname',
        'Summary Stock': 'Ringkasan Stok',
        'Entry Stock': 'Entry Stock',
        'Bin': 'Tempat Sampah',
        'Drug Test': 'Tes Narkoba',
        'Registration': 'Registrasi',
        'Medical Waste': 'Limbah Medis',
        'Lab': 'Lab',
        'Type MCU': 'Tipe MCU',
        // CONTENT 
        'Welcome to GEMS Uhealth': 'Selamat Datang di GEMS Uhealth',
        'Add': 'Tambah',
        'Manual Input': 'Input Manual',
        'Save': 'Simpan',
        'Chemical Material Data Name': 'Nama Data Bahan Kimia',
        'Chemical Material Data': 'Data Material Bahan kimia',
        'Action': 'Aksi',
        'Chapter Name': 'Nama Bagian',
        'Chapter Form': 'Form Bagian',
        'Filter': 'Cari',
        'Block Name': 'Nama Blok',
        'Block Form': 'Form Blok',
        'Category Name': 'Nama Kategori',
        'Category Form': 'Form Kategori',
        'Are you sure?': 'Apa Kamu Yakin?',
        'Cancel': 'Batal',
        'Yes, delete it!': 'Ya, hapus!',
        "You won't be able to revert this!": 'Data Tidak Dapat Dikembalikan Lagi!',
        'Your file has been deleted': 'File Anda telah dihapus',
        'Error while delete data': 'Kesalahan saat menghapus data',
        'Your input has been saved': 'Masukkan Anda Telah di Simpan',
        'Error while add data': 'Terjadi kesalahan saat menambahkan data',
        'Saved': 'Berhasil',
        'OK': 'OKE',
        'Disease Name': 'Nama Penyakit',
        'Generic Name': 'Nama Umum',
        'Brand': 'Merek',
        'Manufacture': 'Pembuatan',
        'Dosage': 'Dosis',
        'Generic': 'Umum',
        'Drug Form': 'Form Obat',
        'Unit Name': 'Nama Unit',
        'Unit Form': 'Form Unit',
        'Material Type Data Name': 'Nama Data Jenis Bahan',
        'Type Name': 'Nama Tipe',
        'Material Type': 'Jenis Bahan',
        'Error while edit data': 'Terjadi kesalahan saat mengedit data',
        'Name Form': 'Nama Form',
        'Hazard Name': 'Nama Bahaya',
        'Hazard Data Name': 'Nama Data Bahaya',
        'Chemical Hazard Form': 'Bentuk Bahaya Kimia',
        'Facility Name': 'Nama Fasilitas',
        'Facility Form': 'Form Fasilitas',
        'Treatment Name': 'Nama Perawatan',
        'Treatment Form': 'Form Pengobatan',
        'Position': 'Posisi',
        'Position Name': 'Nama Posisi',
        'Position Form': 'Posisi Form',
        'Group Name': 'Nama Group',
        'Sub Group Name': 'Nama Sub Group',
        'Result': 'Hasil',
        'Result Name': 'Nama Hasil',
        'Result Form': 'Form Hasil',
        'Result Point': 'Result Point',
        'Point': 'Poin',
        'Item': 'Barang',
        'Item Unit': 'Satuan Barang',
        'Item Name': 'Nama Barang',
        'Item Form': 'Form Barang',
        'Description': 'Deskripsi',
        'Action Name': 'Nama Aksi',
        'IH Action': 'Aksi IH',
        'Index': 'Indeks',
        'Factor Name': 'Nama Faktor',
        'IH Factor': 'Faktor IH',
        'Activity Name': 'Nama Aktifitas',
        'IH Measuring Activity': 'Kegiatan Mengukur IH',
        'Activity Form': 'Aktifitas Form',
        "Action Form": 'Aksi Form',
        'Factor Form': 'Faktor Form',
        'Method': 'Metode',
        'Method Name': 'Nama Metode',
        'IH Measuring Method': 'Metode Pengukuran IH',
        'Method Form': 'Form Metode',
        'IH Measuring Unit': 'Unit Pengukuran IH',
        'Data Flag': 'Tanda Data',
        'Type': 'Tipe',
        'Mandatory': 'Wajib',
        'Input Period': 'Periode Masukkan',
        'OH Name': 'Nama OH',
        'Document Name': 'Nama Dokumentasi',
        'Name': 'Nama',
        'Document Form': 'Form Dokumentasi',
        'Indicator Name': 'Nama Indikator',
        'Treshold': 'Ambang',
        'Percentage': 'Persentase',
        'Data Type': 'Tipe Data',
        'Value': 'Nilai',
        'Indicator Form': 'Indikator Form',
        'Medical Facility': 'Fasilitas Kesehatan',
        'Facility Feature': 'Fitur Fasilitas',
        'Healthcare Name': 'Nama Kesehatan',
        'Healthcare Form': 'Fasilitas Form',
        'Medical Facility Name': 'Nama Fasilitas Medis',
        'Healthcare Facility Name': 'Nama Fasilitas Kesehatan',
        'Competency': 'Kompetensi',
        'Competency Name': 'Nama Kompetensi',
        'Health': 'Kesehatan',
        'Health Name': 'Nama Kesehatan',
        'Safety': 'Keamanan',
        'Safety Name': 'Nama Keamanan',
        'Company': 'Perusahaan',
        'Date': 'Tanggal',
        'Documentation Type': 'Tipe Dokumentasi',
        'Image': 'Gambar',
        'Add Data': 'Tambah Data',
        'Form Documentation': 'Form Dokumentasi',
        'Year': 'Tahun',
        'Occupational Health Indicators': 'Occupational Health Indicators',
        'Month': 'Bulan',
        'Form Input OH Monthly': 'Form OH Bulan',
        'Occupational Health': 'Kesehatan Kerja',
        'Occupational Health Activity': 'Kegiatan Kesehatan Kerja',
        'Period 1 January Until 31 December': 'Periode 1 Januari Sampai dengan 31 Desember',
        'Permit Holder': 'Pemegang Izin',
        'Mining Services Company': 'Perusahaan Jasa Pertambangan',
        'Form Input OH Yearly': 'Form Tahun OH',
        'Safety Field': 'Bidang Keselamatan',
        'Health Field': 'Bidang Kesehatan',
        'Competency Field': 'Bidang Kompetensi',
        'Form Input OH Daily': 'Form OH Harian',
        'Monthly': 'Bulanan',
        'Daily': 'Harian',
        'Documentation': 'Dokumentasi',
        'Period': 'Periode',
        'Indicator Rate': 'Tingkat Indikator',
        'OH Activity': 'Aktifitas OH',
        'Form Add Documentation': 'Form Tamhah Dokumentasi',
        'Company Input': 'Masukan Perusahaan',
        'Product': 'Produk',
        'Unit': 'Satuan',
        'Purchase price': 'Harga Beli',
        'Qty': 'Jumlah',
        'Notes': 'Catatan',
        'Product Name': 'Nama Produk',
        'Code': 'Kode',
        'Supplier': 'Pemasok',
        'Information': 'Keterangan',
        'Item Income': 'Barang Masuk',
        'Code List': 'Daftar Kode',
        'Open': 'Masuk',
        'Out': 'Keluar',
        'Employee Name': 'Nama Karyawan',
        'Visit Type': 'Tipe Kunjungan',
        'Doctor': 'Dokter',
        'Diagnotic Result': 'Hasil Diagnosis',
        'Main Complaint': 'Keluhan Utama',
        'Type Of Disease': 'Jenis Penyakit',
        'Add Drugs': 'Tambah Obat',
        'Drug Name': 'Nama Obat',
        'Quantity': 'Jumlah',
        'Remove': 'Hapus',
        'Doctor Action': 'Tindakan Dokter',
        'Action Care': 'Tindakan Perawatan',
        'Follow Up Visit': 'Kunjungan Tindak Lanjut',
        'Date Of Next Visit': 'Tanggal Kunjungan Berikutnya',
        'Attachment': 'Lampiran',
        'Division': 'Divisi',
        'Please Scan Barcode or Input Registration Code': 'Silahkan Scan Barcode atau Masukan Kode Registrasi',
        'Registration Time': 'Waktu Registrasi',
        'Formula': 'Formula',
        'Non Partnership Clinic': 'Klinik Non Partner',
        'Medical & MCU Record': 'Medis & Rekam MCU',
        'Medical & MCU Records': 'Medis & Rekam MCU',
        'Medical Tool': 'Alat Medis',
        'Disposable Item': 'Barang Sekali Pakai',
        'Role Management': 'Manajemen Role',
        'Medicine Category': 'Kategori Obat',
        'Leading': 'Leading',
        'Medical Tool Category': 'Kategori Alat Medis',
        'Group Activity': 'Group Activity',
        'Lagging Indicator': 'Lagging Indicator',
        'Measuring - Kit': 'Measuring - Kit',
        'Measuring - Campaign': 'Meaasuring - Campaign',
        'Disposable Item Category': 'Kategori Alat Sekali Pakai',
        'Add Item': 'Tambah Barang',
        'Upload Sick Letter': 'Upload Surat Sakit',
        'Upload Receipt Letter': 'Upload Surat Sakit',
        'Upload Doctor Letter': 'Upload Surat Sakit',
        'NIK': 'NIK',
        'MCU Record': 'MCU Record',
        'History': 'Riwayat',
        'Test Date': 'Tanggal Test',
        'Registration Date': 'Tanggal Daftar',
        'Type visit': 'Tipe Kunjungan',
        'Status': 'Status',
        'Department': 'Departemen',
        'RESULT TEST': 'Hasil Tes',
    }
}