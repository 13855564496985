import axiosIns from '@/api/axios'


// DRUGS
export const GET_DRUGS = (args) => {
    return axiosIns.get(`/inventory/stock?${args}`)
}

export const GET_DRUGS_DETAIL = (args) => {
    return axiosIns.get(`/items/detail?${args}`)
}

export const GET_STOCK_DETAIL = (args) => {
    return axiosIns.get(`/inventory/stock/detail?${args}`)
}

export const GET_MUTATION = (args) => {
    return axiosIns.get(`/inventory/stock/mutation?${args}`)
}

export const REVERT_DRUG = (args) => {
    return axiosIns.post(`/medical-waste/pool`, args)
}

export const EXPORT_EXCEL = (args) => {
    return axiosIns.get(`/inventory/stock/report?${args}`)
}

// MEDICAL TOOL

export const GET_MEDICAL = (args) => {
    return axiosIns.get(`/inventory/device-calibration?${args}`)
}

export const UPDATE_MEDICAL_TOOL = (args) => {
    return axiosIns.post(`/inventory/device-calibration`, args)
}

