import axiosIns from '@/api/axios'

export const GET_RESULT_DATA = (args) => {
    return axiosIns.get(`/mcu/category/result?${args}`)
}

export const ADD_RESULT = (args) => {
    return axiosIns.post(`/mcu/category/result/create`, args)
}

export const DEL_RESULT = (id) => {
    return axiosIns.put(`/mcu/category/result/delete?id=${id}`)
}

export const UPDATE_RESULT  = (args) => {
    return axiosIns.put(`/mcu/category/result/update?id=${args.id}`, args)
}