<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-6">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item">{{ $t("Inventory") }}</li>
                <li class="breadcrumb-item">Delivery Order</li>
                <li class="breadcrumb-item">{{ $t("Input DO") }}</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Table -->
      <b-overlay :show="show" rounded="sm">
        <div class="card mt-5">
          <!-- <form @submit.prevent="addPushData()"> -->

          <form @submit.prevent="addItem()" @keyup.enter="addItem()">
            <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Product")
                  }}</label>
                  <v-select
                    v-model="bodyAdd.item_id"
                    :options="drug.data"
                    :reduce="(e) => e.id"
                    @input="productUnit()"
                    label="item_label"></v-select>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Qty")
                  }}</label>
                  <input
                    type="number"
                    :disabled="checkVal()"
                    v-model="bodyAdd.item_qty"
                    class="form-control"
                    min="1" />
                </div>
              </div>
              <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Unit")
                  }}</label>
                  <v-select
                    :disabled="checkVal()"
                    v-model="bodyAdd.item_uom_id"
                    :options="unit.uom"
                    label="item_uom_name"
                    :reduce="(e) => e.item_uom_id" />
                </div>
              </div>
            </div>
            <div class="row mt-1" style="margin-left: 15px; margin-right: 15px">
              <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label"
                    >SAP Item ID</label
                  >
                  <input
                    type="text"
                    :disabled="checkVal()"
                    v-model="bodyAdd.sap_material_number"
                    class="form-control" />
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label"
                    >Price/Unit</label
                  >
                  <div class="input-group mb-3">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                      >Rp.</span
                    >
                    <input
                      type="text"
                      class="form-control"
                      :disabled="checkVal()"
                      aria-label="Sizing example input"
                      v-model="bodyAdd.item_price"
                      aria-describedby="inputGroup-sizing-default"
                      @blur="val()"
                      @keypress="isNumberInput($event)" />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label"
                    >Expired Date</label
                  >
                  <input
                    type="date"
                    class="form-control"
                    :disabled="checkVal()"
                    v-model="bodyAdd.expiry_date"
                    :min="now()" />
                </div>
              </div>
            </div>
            <button
              class="float-end btn btn-primary mr-4 mb-3"
              :disabled="bodyAdd.item_uom_id.length > 0 ? false : true"
              type="submit"
              v-if="isLoading === false">
              Update
            </button>
            <button
              class="float-end btn btn-primary mr-4 mb-3"
              type="button"
              disabled
              v-if="isLoading === true">
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </form>
        </div>
      </b-overlay>

      <div class="row">
        <div class="col-xl-9 col-lg-8 col-sm-12">
          <div class="card mt-3">
            <table class="table table-sm table-bordered po-table">
              <thead>
                <tr>
                  <th scope="col" style="text-align: center">No</th>
                  <th scope="col" style="text-align: center">SAP Item ID</th>
                  <th scope="col" style="text-align: center">Item Name</th>
                  <th scope="col" style="text-align: center">QTY</th>
                  <th scope="col" style="text-align: center">Unit</th>
                  <th scope="col" style="text-align: center">Price</th>
                  <th scope="col" style="text-align: center">EXP Date</th>
                  <th scope="col" style="text-align: center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(prod, index) in productList.cart" :key="index">
                  <td class="text-center">{{ index + 1 }}</td>
                  <td>{{ prod.sap_material_number }}</td>
                  <td>{{ prod.item_label }}</td>
                  <td class="text-right">{{ prod.item_qty }}</td>
                  <td class="text-center">{{ prod.item_uom_name }}</td>
                  <!-- v-if="prod.item_price" dibawah td ini -->
                  <td class="text-right">
                    {{ convertCurrency(prod.item_price) }}
                  </td>
                  <td>{{ convertDate(prod.expiry_date) }}</td>
                  <td>
                    <b-button
                      class="no-wrap ml-2"
                      variant="info"
                      style="margin-left: 5px"
                      size="sm"
                      title="Split"
                      @click="copy(prod.id)">
                      <span class="fe fe-copy"></span>
                    </b-button>
                    <b-button
                      class="no-wrap ml-2"
                      variant="warning"
                      style="margin-left: 5px"
                      size="sm"
                      @click="updates(prod)">
                      <span class="fe fe-edit"></span>
                    </b-button>
                    <b-button
                      class="no-wrap ml-2"
                      variant="danger"
                      style="margin-left: 5px"
                      size="sm"
                      @click="delProd(prod)">
                      <span class="fe fe-x"></span>
                    </b-button>
                  </td>
                </tr>
                <tr>
                  <td class="text-right" colspan="5">Total</td>
                  <td v-if="productList.total_price" class="text-right">
                    Rp. {{ convertCurrency(productList.total_price) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-sm-12">
          <div class="card mt-3">
            <form>
              <div class="mt-4" style="margin-left: 15px; margin-right: 15px">
                <div class="col">
                  <div class="form-group">
                    <label class="form-label">PO Reference</label>
                    <v-select
                      v-model="submit.po_id"
                      :options="refs"
                      :reduce="(option) => option.id"
                      label="id"
                      @input="insertRef()" />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="form-label">Delivery Date</label>
                    <input
                      type="date"
                      :disabled="checkRef()"
                      class="form-control"
                      v-model="submit.delivery_date"
                      :max="now()" />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="form-label">SAP DO No(Optional)</label>
                    <input
                      type="text"
                      :disabled="checkRef()"
                      class="form-control"
                      v-model="submit.sap_do_number" />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputEmail1" class="form-label"
                      >Supplier Name</label
                    >
                    <input
                      type="text"
                      :disabled="checkRef()"
                      class="form-control"
                      v-model="submit.supplier_name" />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputEmail1" class="form-label"
                      >Note</label
                    >
                    <textarea
                      type="text"
                      :disabled="checkRef()"
                      class="form-control"
                      v-model="submit.note" />
                  </div>
                </div>
                <div>
                  <button
                    style="margin-left: auto; float: right"
                    :disabled="checkRef()"
                    class="mr-3 mb-3 btn btn-primary"
                    @click.prevent="submitOrder">
                    {{ $t("Submit") }}
                  </button>
                  <!-- <button
                    style="margin-left: auto; float: right"
                    :disabled="checkRef()"
                    class="mr-3 mb-3 btn btn-primary"
                    @click.prevent="saveOrder"
                  >
                    {{ $t("Save") }}
                  </button> -->
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://unpkg.com/vue@2.5.9/dist/vue.min.js"></script>
<script src="https://unpkg.com/lodash@4.17.4/lodash.min.js"></script>
<script>
import moment from "moment";
export default {
  name: "inventoryInputPO",
  computed: {
    drug() {
      return this.$store.getters["drug/DrugData"]
        ? this.$store.getters["drug/DrugData"]
        : [];
    },
    unit() {
      return this.$store.getters["po/UnitProductData"]
        ? this.$store.getters["po/UnitProductData"]
        : [];
    },
    detail() {
      return this.$store.getters["po/dataDetailPO"]
        ? this.$store.getters["po/dataDetailPO"]
        : [];
    },
    refs() {
      return this.$store.getters["Do/dataReferencePO"]
        ? this.$store.getters["Do/dataReferencePO"]
        : [];
    }
    // productList(){
    //   return this.$store.getters["po/InputProductData"] ? this.$store.getters["po/InputProductData"] : [];
    // }
  },
  // watch: {
  //   productList(val){
  //     return this.$store.getters["po/InputProductData"] ? this.$store.getters["po/InputProductData"] : [];
  //   }
  // },
  data() {
    return {
      bodyProduct: {
        item_type: ""
      },
      bodyUnit: {
        page: 1,
        limit: 10,
        order: "",
        unit_name: ""
      },
      bodyAdd: {
        id: "",
        do_id: "",
        item_id: "",
        item_qty: 1,
        item_uom_id: "",
        sap_material_number: "",
        note: "-",
        item_price: "",
        expiry_date: ""
      },
      submit: {
        po_id: "",
        delivery_date: moment().format("YYYY-MM-DD"),
        sap_do_number: "",
        supplier_name: "",
        note: ""
      },
      do_id: null,
      productList: {},
      isLoading: false,
      show: false,
      columns: [
        {
          label: "NAMA PRODUK",
          field: "drugs_name",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "SATUAN",
          field: "unit_name",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "JUMLAH",
          field: "item_price",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "HARGA",
          field: "qty",
          thClass: "table-header no-wrap w-50 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ]
    };
  },
  mounted() {
    this.getDetail();
    this.getDrug();
    // this.getInputProduct();
    this.getReference();
  },
  methods: {
    getDetail() {
      const do_id = this.$router.currentRoute.query.po_id;
      if (do_id) {
        this.show = true;
        // this.do_id = do_id;
        this.$store.dispatch("Do/getDataRef", { po_id: do_id }).then(() => {
          let data = this.$store.getters["Do/dataRefs"];
          this.submit.po_id = do_id;
          this.submit.note = data.note;
          this.submit.supplier_name = data.supplier_name;
          this.show = false;
          this.getInputProduct();
        });
      }
    },
    getDrug() {
      this.$store.dispatch("drug/getDrug", this.bodyProduct);
    },
    getUnit() {
      this.$store.dispatch("unit/getUnit", this.bodyUnit);
    },
    getInputProduct() {
      this.$store
        .dispatch("Do/getInputProduct", { id: this.do_id || "" })
        .then(() => {
          let data = this.$store.getters["Do/InputProductData"];
          this.productList = data;
        });
    },
    getReference() {
      this.$store.dispatch("Do/getListOrdered");
    },
    insertRef() {
      let body = {
        po_id: this.submit.po_id
      };
      this.$store.dispatch("Do/getDataRef", body).finally(() => {
        let data = this.$store.getters["Do/dataRefs"];
        this.submit.note = data.note;
        this.submit.supplier_name = data.supplier_name;
        this.getInputProduct();
      });
    },

    submitOrder() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Submitted DO cannot be changed later!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes"
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("Do/submitDO", this.submit)
              .then(() => {
                this.$router.push("/clinic-management/inventory/stock-opname");
              })
              .catch(() => {
                let err = resp.response.data;
                console.log(err);
                this.$swal.fire("Error!", `${err.error}`, "error");
              });
          }
        });
    },

    now() {
      return moment().format("YYYY-MM-DD");
    },

    addItem() {
      this.isLoading = true;
      this.bodyAdd.item_price = Number(
        this.bodyAdd.item_price.replace(/,/g, "")
      );
      this.$store.dispatch("Do/addUnitProduct", this.bodyAdd).then(() => {
        this.isLoading = false;
        let data = this.$store.getters["Do/InputProductData"];
        this.productList = data;
        this.bodyAdd = {
          ...this.bodyAdd,
          item_qty: 1,
          item_uom_id: "",
          sap_material_number: "",
          note: "-",
          item_id: "",
          item_price: "",
          expiry_date: ""
        };
      });
    },

    productUnit() {
      this.$store
        .dispatch("po/getUnitProduct", this.bodyAdd.item_id)
        .then(() => {
          // let data = this.$store.getters["po/UnitProductData"];
          // this.bodyAdd.sap_material_number = data.sap_material_number;
        });
    },

    delProd(prod) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("Do/deleteUnitProduct", prod.id).then(() => {
              this.$swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              );
              let data = this.$store.getters["Do/InputProductData"];
              this.productList = data;
              this.$swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              );
            });
          }
        });
    },

    updates(data) {
      this.bodyAdd.id = data.id;
      this.bodyAdd.item_id = data.item_id;
      this.bodyAdd.item_qty = data.item_qty;
      this.bodyAdd.item_uom_id = data.item_uom_id;
      this.bodyAdd.sap_material_number = data.sap_material_number;
      this.bodyAdd.item_price = data.item_price
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.bodyAdd.expiry_date = data.expiry_date;

      this.productUnit();
    },

    copy(e) {
      this.$store
        .dispatch("Do/copyProduct", {
          id: e
        })
        .then(() => {
          let data = this.$store.getters["Do/InputProductData"];
          this.productList = data;
        });
    },

    checkVal() {
      if (this.bodyAdd.item_id.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    checkRef() {
      if (this.submit.po_id.length > 0) {
        return false;
      } else {
        return true;
      }
    },

    val() {
      this.bodyAdd.item_price = this.bodyAdd.item_price
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    convertCurrency(dat) {
      return dat.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    convertDate(d) {
      if (d) {
        return moment(d).format("D MMM YYYY");
      }
      return d;
    },

    isNumberInput(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }

    // addPushData() {
    //   var dataPush = {
    //     id: this.id,
    //     drugs_name: this.bodyProduct.drugs_name,
    //     unit_name: this.bodyUnit.unit_name,
    //     item_price: this.item_price,
    //     qty: this.qty,
    //   };
    //   this.pushData.push(dataPush);
    // },
    // removePushData(index) {
    //   this.pushData.splice(index, 1);
    // },
  }
};
</script>
<style>
.po-table td:first-child,
.po-table td:nth-last-child(3),
.po-table td:nth-last-child(2),
.po-table td:last-child {
  text-align: center;
}

.po-table > :not(caption) > * > * {
  padding: 0.5rem !important;
}
</style>
