var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.$t("Riwayat Alergi")))]),_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-1",modifiers:{"collapse-1":true}}],staticStyle:{"margin-left":"7px"},attrs:{"variant":"primary"}},[_c('span',{staticClass:"fe fe-sliders"})])],1),_c('b-collapse',{attrs:{"id":"collapse-1"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sorting()}}},[_c('div',{staticClass:"row mt-4",staticStyle:{"margin-left":"15px","margin-right":"15px"}},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"exampleInputEmail1"}},[_vm._v(_vm._s(_vm.$t("NAME")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.hazard_name),expression:"body.hazard_name"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.body.hazard_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "hazard_name", $event.target.value)}}})])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Order")]),_c('v-select',{attrs:{"options":['ASC', 'DESC']},model:{value:(_vm.body.order),callback:function ($$v) {_vm.$set(_vm.body, "order", $$v)},expression:"body.order"}})],1)])]),_c('div',{staticClass:"row mb-3",staticStyle:{"margin-right":"15px"}},[_c('div',{staticClass:"col"}),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-danger lift",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("Filter"))+" ")])])])])]),_c('vue-good-table',{attrs:{"line-numbers":true,"columns":_vm.columns,"rows":_vm.chemical.data,"sort-options":{
        enabled: true,
      },"total-rows":_vm.chemical.totalItems,"pagination-options":{
        enabled: true,
        mode: 'pages',
        position: 'bottom',
        perPage: 10,
        perPageDropdown: [10, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        allLabel: 'All',
      },"style-class":"vgt-table border-0 table"},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('b-button',{staticClass:"no-wrap btn-eye",attrs:{"variant":"info","size":"sm"},on:{"click":function($event){return _vm.$router.push(`/clinic-mcu/medical-record`)}}},[_c('span',{staticClass:"fe fe-eye"})])],1):_vm._e()]}}])},[_c('div',{staticClass:"text-center font-size-14",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" No data available ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }