var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center justify-content-between"},[_c('div',{staticClass:"card p-0"},[_vm._m(0),(_vm.load === false)?_c('div',[_c('div',{staticClass:"list-group list-group-flush"},[_c('div',{staticClass:"list-group-item p-4"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col"},[_vm._m(2),_c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v(_vm._s(this.history.nik))])])]),_vm._m(3),_c('div',{staticClass:"col"},[_vm._m(4),_c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v(_vm._s(this.patient.patient_name))])])]),_vm._m(5),_c('div',{staticClass:"col"},[_vm._m(6),_c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v(_vm._s(this.history.company))])])]),_vm._m(7),_c('div',{staticClass:"col"},[_vm._m(8),_c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v(_vm._s(this.history.department))])])]),_vm._m(9),_c('div',{staticClass:"col"},[_vm._m(10),_c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v(_vm._s(this.history.position))])])])])])])]):_c('div',{staticClass:"card-body text-center"},[_vm._m(11)])]),_c('div',{staticClass:"card p-0"},[_vm._m(12),_c('vue-good-table',{attrs:{"line-numbers":true,"columns":_vm.columns,"rows":_vm.dataMedical.data,"sort-options":{
        enabled: true,
      },"pagination-options":{
        enabled: true,
        mode: 'pages',
        position: 'bottom',
        perPage: 10,
        perPageDropdown: [10, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        allLabel: 'All',
      },"style-class":"vgt-table border-0 table"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'register_date')?_c('span',[_c('div',[_vm._v(" "+_vm._s(_vm.date(props.row.register_date))+" ")])]):(props.column.field == 'action')?_c('span',[_c('div',[_c('b-button',{staticClass:"no-wrap btn-pill",attrs:{"variant":"info","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.viewRecord(props.row)}}},[_c('span',{staticClass:"fe fe-edit"})])],1)]):_vm._e()]}}])},[_c('div',{staticClass:"text-center font-size-14",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" No data available ")])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v("Patient Info")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"avatar avatar-sm"},[_c('div',{staticClass:"avatar-title fs-lg bg-primary-soft rounded-circle text-primary"},[_c('i',{staticClass:"fe fe-credit-card"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v("NIK")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"avatar avatar-sm"},[_c('div',{staticClass:"avatar-title fs-lg bg-primary-soft rounded-circle text-primary"},[_c('i',{staticClass:"fe fe-user"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v("Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"avatar avatar-sm"},[_c('div',{staticClass:"avatar-title fs-lg bg-primary-soft rounded-circle text-primary"},[_c('i',{staticClass:"fe fe-home"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v("Company")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"avatar avatar-sm"},[_c('div',{staticClass:"avatar-title fs-lg bg-primary-soft rounded-circle text-primary"},[_c('i',{staticClass:"fe fe-layers"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v("Departement")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"avatar avatar-sm"},[_c('div',{staticClass:"avatar-title fs-lg bg-primary-soft rounded-circle text-primary"},[_c('i',{staticClass:"fe fe-award"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v("Position")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v("Medical Record")]),_c('div',{staticStyle:{"margin-right":"15px"}})])
}]

export { render, staticRenderFns }