import queryString from 'query-string'
import {
    GET_INDICATOR_RATE,
} from "@/api/oh_management/leading_lagging";


const state = {
    indicatorRate: [],
    errIndicator: [],
    body_data: [],
};

const mutations = {
    setIndicator: (states, payload) => {
        states.indicatorRate = payload;
    },
    setErrIndicator: (states, payload) => {
        states.errIndicator = payload;
    },
    setBodyData: (states, payload) => {
        states.body_data = payload;
    }
};

const actions = {
    getIndicator: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        try {
            const resp = await GET_INDICATOR_RATE(params)
            const datas = resp.data.data.data
            commit("setIndicator", datas);
        } catch (err) {
            let resp = err.response;
            console.log(err);
            commit("setErrIndicator", resp)
            if (resp.data.httpCode === 404) {
                commit("setIndicator", {
                    setIndicator: [],
                });
            }
        }
    },
    async getBodyData({ commit }, args) {
        commit("setBodyData", args)
    }
};

const getters = {
    indicatorRate: (states) => states.indicatorRate,
    errorIndicator: (states) => states.errIndicator,
    body_data: (states) => states.body_data
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};