import axiosIns from '@/api/axios'

export const GET_KIT = (args) => {
    return axiosIns.get(`/ih/measuring/kit?${args}`)
}

export const ADD_KIT = (args) => {
    return axiosIns.post(`/ih/measuring/kit`, args)
}

export const UPDATE_KIT = (args) => {
    return axiosIns.put(`/ih/measuring/kit`, args)
}

export const DELETE_KIT = (args) => {
    return axiosIns.delete(`/ih/measuring/kit?id=${args}`)
}
