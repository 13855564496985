var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card mt-5"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row justify-content-between align-items-center"},[_c('div',{staticClass:"col-12 col-md-9 col-xl-7"},[_c('h2',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t("Dashboard")))]),_c('p',{staticClass:"text-muted mb-md-0"},[_vm._v(" "+_vm._s(_vm.$t( `Welcome ${_vm.fullname} ${ _vm.company_name !== "null" ? `from ${_vm.company_name}` : _vm.userRole && _vm.userRole.length > 0 ? `from ${_vm.userRole[0].healthcare_facility_name}` : "" }` ))+" ")])]),_vm._m(0)])])]),(
          (_vm.company_name === null && _vm.company_name === 'null') ||
          (_vm.userRole && _vm.userRole.length < 1)
        )?_c('div',{staticClass:"card mt-5"},[_c('div',{staticClass:"card-body"},[_vm._v(" For additional access, contact to BIB Admin ")])]):_vm._e()])]),_c('div',{staticClass:"container-fluid"},[_vm._m(1),(
        _vm.userRole &&
        _vm.userRole.length > 0 &&
        _vm.userRole[0].healthcare_facility_type_clinic === 'Clinic Internal'
      )?_c('div',{staticClass:"header"},[_c('div',{staticClass:"card mt-5"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row justify-content-between align-items-center"},[_vm._m(2),(_vm.role_id === 1)?_c('div',{staticClass:"col-12 col-md-3"},[_c('v-select',{attrs:{"options":_vm.selectCompanyOption(),"label":"company_name","reduce":(e) => e.id},on:{"input":function($event){return _vm.getDataVacc()}},model:{value:(_vm.company_id),callback:function ($$v) {_vm.company_id=$$v},expression:"company_id"}})],1):_vm._e()])])])]):_vm._e(),(
        _vm.userRole &&
        _vm.userRole.length > 0 &&
        _vm.userRole[0].healthcare_facility_type_clinic === 'Clinic Internal'
      )?_c('div',{staticClass:"row"},_vm._l((_vm.vacciness),function(item,index){return _c('div',{key:index,staticClass:"col-xl-3 col-md-6 col-sm-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(item.label))])]),_c('div',{staticClass:"card-body"},[_c('div',{attrs:{"id":"chart"}},[_c('apexchart',{attrs:{"type":"radialBar","height":_vm.dimensionChart(),"options":_vm.chartOption(index),"series":_vm.totals(item.value)}}),_c('h5',{staticClass:"text-center"},[_c('span',{staticClass:"fe fe-users mr-3"}),_vm._v(" "+_vm._s(_vm.convertNumber(item.total))+" of "+_vm._s(_vm.convertNumber(item.man_power))+" Employees ")])],1)])])])}),0):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-auto d-none"},[_c('button',{staticClass:"btn btn-primary lift",attrs:{"data-bs-toggle":"modal","data-bs-target":"#exampleModal"}},[_c('span',{staticClass:"fe fe-folder-plus"}),_vm._v(" Report ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row d-none"},[_c('div',{staticClass:"col-12 col-lg-6 col-xl"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row align-items-center gx-0"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"text-uppercase text-muted mb-2"},[_vm._v("Value")]),_c('span',{staticClass:"h2 mb-0"},[_vm._v(" $24,500 ")]),_c('span',{staticClass:"badge bg-success-soft mt-n1"},[_vm._v(" +3.5% ")])]),_c('div',{staticClass:"col-auto"},[_c('span',{staticClass:"h2 fe fe-dollar-sign text-muted mb-0"})])])])])]),_c('div',{staticClass:"col-12 col-lg-6 col-xl"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row align-items-center gx-0"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"text-uppercase text-muted mb-2"},[_vm._v("Total hours")]),_c('span',{staticClass:"h2 mb-0"},[_vm._v(" 763.5 ")])]),_c('div',{staticClass:"col-auto"},[_c('span',{staticClass:"h2 fe fe-briefcase text-muted mb-0"})])])])])]),_c('div',{staticClass:"col-12 col-lg-6 col-xl"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row align-items-center gx-0"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"text-uppercase text-muted mb-2"},[_vm._v("Exit %")]),_c('span',{staticClass:"h2 mb-0"},[_vm._v(" 35.5% ")])]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"chart chart-sparkline"},[_c('canvas',{staticClass:"chart-canvas",attrs:{"id":"sparklineChart"}})])])])])])]),_c('div',{staticClass:"col-12 col-lg-6 col-xl"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row align-items-center gx-0"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"text-uppercase text-muted mb-2"},[_vm._v("Avg. Time")]),_c('span',{staticClass:"h2 mb-0"},[_vm._v(" 2:37 ")])]),_c('div',{staticClass:"col-auto"},[_c('span',{staticClass:"h2 fe fe-clock text-muted mb-0"})])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-9 col-xl-7"},[_c('h2',{staticClass:"mb-2"},[_vm._v("Vaccination Summary")])])
}]

export { render, staticRenderFns }