import queryString from "query-string";

import {
    GET_KIT,
    ADD_KIT,
    UPDATE_KIT,
    DELETE_KIT,
    // DEL_UNIT,
    // UPDATE_UNIT
} from "@/api/ih/measuring/kit";

const state = {
    datakit: [],
};

const mutations = {
    setkit: (states, payload) => {
        states.datakit = payload;
    },
};

const actions = {
    getKit: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_KIT(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setkit", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setkit", {
                        setkit: [],
                    });
                }
            });
    },

    addKit: async ({ }, args) => {
        return await ADD_KIT(args);
    },

    delKit: async ({ }, id) => {
        return await DELETE_KIT(id);
    },

    updateKit: async ({ }, args) => {
        return await UPDATE_KIT(args);
    },



};

const getters = {
    kitData: (states) => states.datakit
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};