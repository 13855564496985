import queryString from "query-string";
import { GET_MEDICAL_RECORD, GET_MEDICAL_RECORD_DETAIL, GET_MCU_RECORD } from "@/api/clinic/history";

const state = {
    dataPatientRecord: [],
    dataDetailPatient: [],
    dataMCUView: []
};

const mutations = {
    setPatientRecord: (states, payload) => {
        states.dataPatientRecord = payload;
    },
    setDetailPatient: (states, payload) => {
        states.dataDetailPatient = payload;
    },
    setMCUView: (states, payload) => {
        states.dataMCUView = payload;
    },
}

const actions = {
    getRecordPatient: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_MEDICAL_RECORD(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setPatientRecord", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setPatientRecord", {
                        setPatientRecord: [],
                    });
                }
            });
    },

    getDetailRecordPatient: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_MEDICAL_RECORD_DETAIL(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setDetailPatient", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setDetailPatient", {
                        setDetailPatient: [],
                    });
                }
            });
    },

    getMCUView: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_MCU_RECORD(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setMCUView", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setMCUView", {
                        setMCUView: [],
                    });
                }
            });
    },
}

const getters = {
    patientRecordData: (states) => states.dataPatientRecord,
    detailPatientRecord: (states) => states.dataDetailPatient,
    mcuRecordData: (states) => states.dataMCUView
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};