<template>
  <!-- HEADER -->
  <div class="row align-items-center justify-content-between">
    <div :class="`cart ${load === true ? '' : 'd-none'}`">
      <div class="card-body text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div :class="`cart ${load === true ? 'd-none' : ''}`">
      <!-- Link -->
      <!-- <div class="card-header">
        <div style="margin-left: 7px">
          
        </div>
      </div> -->
      <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
        <div class="col-2">
          <div class="form-group">
            <label for="inputPassword">{{ $t("Year") }}</label>
            <v-select
              @input="sorting()"
              :options="listYear"
              v-model="bodyDataDaily.date_input" />
          </div>
        </div>
        <div class="col-4" v-if="filterCompany == 1">
          <div class="form-group">
            <label>{{ $t("Company") }}</label>
            <v-select
              :options="selectCompanyOption()"
              v-model="bodyDataDaily.company_id"
              label="company_name"
              :reduce="(e) => e.id"
              @input="changeCompany()" />
          </div>
        </div>
        <div class="col">
          <div class="form-group mt-3">
            <!-- <label for="exampleInputEmail1" class="form-label">Order</label> -->
            <!-- <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true" class="float-end">
              <span class="fe fe-edit-2"></span>
              {{ $t("Edit Data") }}
            </b-button> -->
            <!-- <v-select v-model="body.order" :options="['ASC', 'DESC']" @input="sorting" /> -->
          </div>
        </div>
      </div>
      <!-- <div class="row pt-3 pl-4">
        <div class="row col-auto">
          <label for="inputPassword" class="col-auto col-form-label">{{
              $t("Year")
            }}</label>
          <div class="col-auto">
            <v-select @input="sorting()" :options="listYear" v-model="bodyDataDaily.date_input" />
          </div>
        </div>
        <div class="row col-8" v-if="filterCompany == 1">
          <label for="inputPassword" class="col col-form-label text-right">{{
              $t("Company")
            }}</label>
          <div class="col-6">
            <v-select :options="company" v-model="bodyDataDaily.company_id" label="company_name" :reduce="(e) => e.id"
              @input="changeCompany()" />
          </div>
        </div>
      </div> -->
      <!-- <div class="form-group mt-4"> -->
      <!-- <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true" class="float-end"
        style="margin-top: -40px; margin-right: 10px">
        <span class="fe fe-edit-2"></span>
        {{ $t("Edit Data") }}
      </b-button> -->
      <!-- </div> -->

      <hr />
      <b-tabs fill>
        <b-tab title="OH Indicator" @click="type1">
          <div class="card-body text-center" v-if="load == true">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div class="tableFixHead" style="overflow: auto" v-else>
            <table class="table table-sm table-nowrap table-bordered">
              <thead>
                <tr>
                  <th scope="col" rowspan="2">
                    {{ $t("Occupational Health Indicators") }}
                  </th>
                  <th colspan="12" style="text-align: center">
                    {{ $t("Month") }}
                  </th>
                  <th rowspan="2" style="text-align: center">
                    {{ $t("Total") }}
                  </th>
                </tr>
                <tr>
                  <th
                    v-for="(i, index) in mothly"
                    :key="index"
                    class="text-center"
                    scope="col">
                    {{ i }}
                  </th>
                </tr>
                <tr></tr>
              </thead>
              <tbody>
                <tr v-for="(row, index_row) in ohDaily" :key="row.id">
                  <th scope="row">
                    {{ row.oh_label }}
                    <span
                      v-if="row.description != null"
                      :title="row.description"
                      class="fe fe-alert-circle"></span>
                  </th>
                  <td
                    class="text-center my-input"
                    v-for="(item, index_subcol) in lastmonth"
                    :key="index_subcol"
                    :id="index_row + '-' + index_subcol"
                    contenteditable="false"
                    @blur="
                      Update($event, index_row, index_subcol, item, row.values)
                    "
                    @keyup.enter="
                      Update($event, index_row, index_subcol, item, row.values)
                    ">
                    {{ convertNumber(revealValue(item, row.values)) }}
                  </td>
                  <!-- <th style="text-align: center; color:black" v-if="bodyDataDaily.oh_type == 'Data'">
                    {{ convertNumber(findTotalMonthData(row.oh_id, row.oh_type)) }}
                  </th> -->
                  <th style="text-align: center; color: black">
                    {{ convertNumber(row.total) }}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>

        <b-tab :title="$t('OH Leading Indicator')" @click="type2">
          <div class="card-body text-center" v-if="load == true">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div class="tableFixHead" style="overflow: auto" v-else>
            <table class="table table-sm table-nowrap table-bordered">
              <thead>
                <tr>
                  <th scope="col" rowspan="2">
                    {{ $t("Occupational Health Indicators") }}
                  </th>
                  <th colspan="12" style="text-align: center">
                    {{ $t("Month") }}
                  </th>
                  <th rowspan="2" style="text-align: center">
                    {{ $t("Total") }}
                  </th>
                </tr>
                <tr>
                  <th
                    v-for="(i, index) in mothly"
                    :key="index"
                    scope="col"
                    class="text-center">
                    {{ i }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index_row) in ohDaily" :key="row.id">
                  <th scope="row">
                    {{ row.oh_label }}
                    <span
                      v-if="row.description != null"
                      :title="row.description"
                      class="fe fe-alert-circle"></span>
                  </th>
                  <td
                    class="text-center my-input"
                    v-for="(item, index_subcol) in lastmonth"
                    :key="index_subcol"
                    :id="index_row"
                    contenteditable="false"
                    @blur="
                      UpdateActivity(
                        $event,
                        index_row,
                        index_subcol,
                        item,
                        row.values
                      )
                    "
                    @keyup.enter="
                      UpdateActivity(
                        $event,
                        index_row,
                        index_subcol,
                        item,
                        row.values
                      )
                    ">
                    {{ convertNumber(revealValue(item, row.values)) }}
                  </td>
                  <!-- <th style="text-align: center; color:black" v-if="bodyDataDaily.oh_type == 'Activity'">
                    {{ convertNumber(findTotalMonthActivity(row.oh_id, row.oh_type)) }}
                  </th> -->
                  <th style="text-align: center; color: black">
                    {{ convertNumber(row.total) }}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>

        <!-- <b-tab title="Graphic">
          <apexchart
            type="bar"
            height="350"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </b-tab> -->
      </b-tabs>
    </div>

    <!-- Body -->

    <!-- Modal -->
    <b-modal
      id="modal-1"
      size="xl"
      hide-footer
      :title="$t('Form Input OH Monthly')">
      <div class="text-center" v-if="loadings == true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <form @submit.prevent="saveAdd()" v-else>
        <div class="row">
          <div class="form-group col-6">
            <label>{{ $t("Date") }}</label>
            <input
              type="month"
              @input="getOhDaily()"
              v-model="addBodyDataDaily.date_input"
              class="form-control"
              placeholder="Pilih Tanggal"
              v-validate="'required'"
              name="Pilih Tanggal" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Pilih Tanggal')"
              >{{ errors.first("Pilih Tanggal") }}</span
            >
          </div>
        </div>
        <div
          class="form-group col-6"
          v-if="
            record[0]?.status > 0 && addBodyDataDaily.date_input.length > 0
          ">
          <label for=""></label>
          <p>This month is already Approved</p>
        </div>
        <div v-else>
          <div v-if="addBodyDataDaily.date_input.length > 0">
            <div class="row" v-if="record.length > 0">
              <label for="">
                <h3>{{ $t("Occupational Health") }}</h3>
              </label>
              <template>
                <!-- <div class="row"> -->
                <div
                  class="form-group col-6"
                  v-for="(item, index) in record"
                  :key="index">
                  <div v-if="item.data_flag == 'Monthly'">
                    <label>{{ item.oh_label }}</label>
                    <span v-if="item.is_mandatory == true" style="color: red"
                      >*</span
                    >
                    <span
                      :title="item.description"
                      class="fe fe-alert-circle"></span>
                    <div>
                      <input
                        v-if="item.is_mandatory == true"
                        :type="item.type_data == 'Integer' ? 'number' : 'text'"
                        v-model="record[index]['value']"
                        class="form-control"
                        :placeholder="item.data_name"
                        v-validate="'required'"
                        :name="item.data_name"
                        min="0" />
                      <input
                        v-else
                        :type="item.type_data == 'Integer' ? 'number' : 'text'"
                        v-model="record[index]['value']"
                        class="form-control"
                        :placeholder="item.data_name"
                        min="0" />
                    </div>
                    <span
                      class="text-sm text-danger"
                      v-show="errors.has(item.data_name)"
                      >{{ "This Field is Required" }}</span
                    >
                  </div>
                </div>
                <!-- </div> -->
              </template>
            </div>
          </div>
        </div>
        <!-- <div class="row mt-4">
          <label for="">
            <h3>{{ $t('Occupational Health Activity') }}</h3>
          </label>
          <template v-if="recordAct.length === activity.data.length">
            <div class="form-group col-6" v-for="(item, index) in activity.data" :key="index">
              <label>{{ item.activity_name }}</label>
              <span v-if="item.is_mandatory == true" style="color: red">*</span>
              <span :title="item.description" class="fe fe-alert-circle"></span>
              <div>
                <input v-if="item.is_mandatory == true" :type="item.type_data == 'String' ? 'text' : 'number'"
                  v-model="recordAct[index]['value']" class="form-control" :placeholder="item.activity_name"
                  v-validate="'required'" :name="item.activity_name" min="0" />
                <input v-else :type="item.type_data == 'String' ? 'text' : 'number'" v-model="recordAct[index]['value']"
                  class="form-control" :placeholder="item.activity_name" min="0" />
              </div>
              <span class="text-sm text-danger"
                v-show="errors.has(item.activity_name)">{{ "This Field is Required" }}</span>
            </div>
          </template>
        </div> -->
        <div>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="submit"
            v-if="isLoading == false">
            {{ $t("Save") }}
          </button>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="button"
            disabled
            v-else>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
            Loading...
          </button>
          <!-- <button
          style="margin-left: auto; float: right"
          class="mt-4 btn btn-primary"
          type="submit"
          disabled
        >
          {{ $t('Save') }}
        </button> -->
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
// import { swal } from 'vue/types/umd';
export default {
  name: "ohManagement",
  computed: {
    ohData() {
      return this.$store.getters["ohData/OhData"]
        ? this.$store.getters["ohData/OhData"]
        : [];
    },
    activity() {
      return this.$store.getters["activity/ActivityData"]
        ? this.$store.getters["activity/ActivityData"]
        : [];
    },
    ohDaily() {
      return this.$store.getters["detailManagement/OHDailyData"]
        ? this.$store.getters["detailManagement/OHDailyData"]
        : [];
    },
    company() {
      return this.$store.getters["detailManagement/dataCompany"]
        ? this.$store.getters["detailManagement/dataCompany"]
        : [];
    },
    companySelect() {
      return this.$store.getters["detailManagement/companySelectData"]
        ? this.$store.getters["detailManagement/companySelectData"]
        : "1";
    }
  },
  data() {
    return {
      body: {
        page: 1,
        limit: 100,
        order: ""
      },
      bodyDataDaily: {
        oh_type: "Data",
        type_input: "Monthly",
        company_id: localStorage.getItem("company"),
        date_input: moment().format("YYYY")
      },
      totalbodyDataDaily: {
        oh_type: "Data",
        type_input: "Monthly",
        company_id: localStorage.getItem("company"),
        date_input: moment().format("YYYY")
      },
      totalbodyActivityDaily: {
        oh_type: "Activity",
        type_input: "Monthly",
        company_id: localStorage.getItem("company"),
        date_input: moment().format("YYYY")
      },
      addBodyDataDaily: {
        type_input: "Monthly",
        date_input: ""
      },
      recs1: [],
      recs2: [],
      dataAnalyze: [],
      filterCompany: "",
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: true,
            tools: {
              download: false
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MEI",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC"
          ]
        },
        yaxis: {
          title: {
            text: "$ (thousands)"
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands";
            }
          }
        }
      },
      series: [
        {
          name: "Data",
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 0, 0, 0]
        }
      ],
      company_id: "",
      date_input: "",
      date_approve: "",
      record: [],
      recordAct: [],
      isAdd: true,
      mothly: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MEI",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC"
      ],
      lastmonth: 12,
      listYear: [],
      totalMonthData: [],
      totalMonthActivity: [],
      isLoading: false,
      loadings: false,
      load: false
    };
  },
  // created() {

  // },
  mounted() {
    this.load = true;
    const lastmonth = moment().endOf("year").format("M");
    this.lastmonth = Number(lastmonth);
    const company = localStorage.getItem("company");
    const roles = localStorage.getItem("roles_id");
    this.filterCompany = parseInt(roles) == 1 ? 1 : 0;
    // if (this.filterCompany === 1) {
    //   this.bodyDataDaily.company_id = this.companySelect
    // }
    this.getOh();
    this.getActivity();
    this.customGetOH();
    // this.gettotalMonthOhData();
    // this.gettotalMonthActivity();
    let year = moment().format("YYYY");
    for (let i = parseInt(year) - 2; i < parseInt(year) + 3; i++) {
      this.listYear.push(i);
    }

    // this.companySelect
  },
  methods: {
    selectCompanyOption() {
      const company = this.company;
      const term = [];
      term.push({
        id: 0,
        company_name: "BIB + Mitra"
      });

      company.map((i) => {
        term.push({
          id: i.id,
          company_name: i.company_name
        });
      });

      return term;
    },
    revealValue(monthly, values) {
      const index = values.findIndex((e) => e.month === monthly);
      if (index > -1) {
        return values[index]["value"];
      } else {
        return "";
      }
    },
    isCompany() {
      const company = localStorage.getItem("company");

      return company;
    },
    isContentDate(monthly, values) {
      const index = values.findIndex((e) => e.month === monthly);
      if (index > -1) {
        return values[index]["date"];
      } else {
        return "";
      }
    },
    isContentValue(monthly, values) {
      const index = values.findIndex((e) => e.month === monthly);
      if (index > -1) {
        return values[index]["value"];
      } else {
        return "";
      }
    },
    changeCompany() {
      const company = this.bodyDataDaily.company_id;
      this.totalbodyDataDaily.company_id = company;
      this.totalbodyActivityDaily.company_id = company;
      const body = {
        oh_type: this.bodyDataDaily.oh_type,
        type_input: "Monthly",
        company_id: company,
        date_input: moment().format("YYYY")
      };
      // this.gettotalMonthOhData();
      // this.gettotalMonthActivity();
      this.load = true;
      this.$store.dispatch("detailManagement/getDataDaily", body).then(() => {
        this.load = false;
      });
      this.$store.commit("detailManagement/setCompanySelect", company);
    },
    //action
    Update(evt, id0, id1, monthly, values) {
      // evt.preventDefault();

      // var x = evt.keyCode;
      // if
      // if (evt.keyCode == 13) {
      //   var x = evt.charCode || evt.keyCode;
      //   (isNaN(String.fromCharCode(evt.which)) && x != 46) ||
      //     x === 32 ||
      //     x === 13 ||
      //     (x === 46 && evt.currentTarget.innerText.includes("."));
      // evt.preventDefault();

      this.$set(
        this.ohDaily[id0],
        id1,
        document.getElementById(id0 + "-" + id1).innerText
      );
      const index = values.findIndex((e) => e.month === monthly);
      const editCel = document.getElementById(id0 + "-" + id1).innerText;
      const dataValue = values[index]["value"];
      if (Number(dataValue) === Number(editCel)) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          })
          .fire({
            icon: "error",
            title: "your input should not be the same as the previous data"
          });
      } else if (Number(editCel) || Number(editCel) == 0) {
        let body = {
          id: values[index]["id"],
          value: editCel,
          is_deleted: false
        };
        this.$store
          .dispatch("inputOH/updateOhDaily", body)
          .then((resp) => {
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                }
              })
              .fire({
                icon: "success",
                title: "Signed in successfully"
              })
              .then((resp) => {
                if (num) {
                  return num
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return "";
                }
              });
          })
          .catch((resp) => {
            this.$swal("Error", "Error while add data", "error");
            this.isOnLoading = false;
          });
      } else {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          })
          .fire({
            icon: "error",
            title: "You input in Number"
          });
        this.isOnLoading = false;
      }
    },

    UpdateActivity(evt, id0, id1, monthly, values) {
      // evt.preventDefault();

      // var x = evt.keyCode;
      // if
      // if (evt.keyCode == 13) {
      //   var x = evt.charCode || evt.keyCode;
      //   (isNaN(String.fromCharCode(evt.which)) && x != 46) ||
      //     x === 32 ||
      //     x === 13 ||
      //     (x === 46 && evt.currentTarget.innerText.includes("."));
      // evt.preventDefault();

      this.$set(this.ohDaily[id0], id1, document.getElementById(id1).innerText);
      const index = values.findIndex((e) => e.month === monthly);
      const editCel = document.getElementById(id1).innerText;
      const dataValue = values[index]["value"];
      if (Number(dataValue) === Number(editCel)) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          })
          .fire({
            icon: "error",
            title: "your input should not be the same as the previous data"
          });
      } else if (Number(editCel) || Number(editCel) == 0) {
        let body = {
          id: values[index]["id"],
          value: editCel,
          is_deleted: false
        };

        this.$store
          .dispatch("inputOH/updateOhDaily", body)
          .then((resp) => {
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                }
              })
              .fire({
                icon: "success",
                title: "Signed in successfully"
              })
              .then((resp) => {
                if (num) {
                  return num
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return "";
                }
              });
          })
          .catch((resp) => {
            this.$swal("Error", "Error while add data", "error");
            this.isOnLoading = false;
          });
      } else {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          })
          .fire({
            icon: "error",
            title: "You input in Number"
          });
        this.isOnLoading = false;
      }
    },

    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          let concat = this.record.concat(this.recordAct);
          for (let i = 0; i < concat.length; i++) {
            delete concat[i].description;
            delete concat[i].type_data;
            delete concat[i].is_mandatory;
            delete concat[i].type_input;
            delete concat[i].status;
            delete concat[i].data_flag;
          }
          let payload = {
            record: concat,
            type_input: "Monthly",
            date_input: this.addBodyDataDaily.date_input
            // company_id: this.company_id,
          };

          // this.dataAnalyze =
          this.loadings = true;

          this.$store
            .dispatch("detailManagement/addOhDaily", payload)
            .then((resp) => {
              this.$swal("Saved", "Your input has been saved", "success").then(
                (resp) => {
                  this.loadings = false;
                  this.customGetOH();
                  this.$bvModal.hide("modal-1");
                  this.addBodyDataDaily.date_input = "";
                  (this.record = []), (this.recordAct = []);
                }
              );
            })
            .catch((resp) => {
              this.$swal(
                `Error ${resp.response.data.httpCode}`,
                resp.response.data.error,
                "error"
              );
              this.loadings = false;
            });
        } catch (resp) {
          this.$swal("Error", "Error while add data", "error");
          this.loadings = false;
        }
      });
    },

    approve() {
      // let formValues = {}
      this.$swal({
        title: "Select Analyze Date",
        icon: "info",
        html: `<input type='month' id="dates"/>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Analyze",
        cancelButtonText: "Cancel"
      }).then((result) => {
        if (result.isConfirmed) {
          let dataSend = {
            date_input: document.getElementById("dates").value
          };
          this.$store
            .dispatch("detailManagement/analyzeData", dataSend)
            .then(() => {
              this.$swal({
                icon: "success",
                title: "Success",
                text: "Data has been Analyze"
              });
            });
        }
      });
    },

    checked() {
      this.$store.dispatch("detailManagement/getChecked").then(() => {
        const datas = this.$store.getters["detailManagement/chekedData"];
        let dateData = datas.data.map((item) => {
          return {
            date_input: item.date_input
          };
        });
        this.$swal({
          title: "Check Data?",
          icon: "question",
          // text: ` ${dates}`,
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Check",
          cancelButtonText: "Cancel"
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("detailManagement/checkData", dateData)
              .then(() => {
                this.$swal({
                  icon: "success",
                  title: "Success",
                  text: "Data has been Check"
                });
              });
          }
        });
      });
    },

    // api hit
    getOh() {
      this.$store.dispatch("ohData/getOh", this.body).then(() => {
        const datas = this.$store.getters["ohData/OhData"];
        const list = datas.data;
        let rec = list.map((item) => {
          return {
            oh_type: "Data",
            oh_id: Number(item.id),
            oh_label: item.data_name,
            value: item.type_data == "Integer" && !item.is_mandatory ? 0 : "",
            data_flag: item.data_flag
          };
        });
        // let recs = list.map((item) => {
        //   return {
        //     id: Number(item.id),
        //   };
        // });
        this.record = rec;

        // this.recs1 = recs;
      });
    },

    getOhDaily() {
      this.loadings = true;
      this.$store
        .dispatch("detailManagement/getDataDailyInput", this.addBodyDataDaily)
        .then(() => {
          this.loadings = false;
          const datas =
            this.$store.getters["detailManagement/OHDailyDataInput"];
          const list = datas;
          let rec = list.map((item) => {
            // const index = item.values.findIndex((e) => e.date === this.date_input);
            return {
              is_mandatory: item.is_mandatory,
              description: item.description,
              type_data: item.type_data,
              data_flag: item.data_flag,
              oh_type: item.oh_type,
              oh_id: Number(item.oh_id),
              oh_label: item.oh_label,
              total: item.total,
              status: item.status,
              value: item.values[0].value
            };
          });

          if (rec[0].status > 0) {
            this.$swal(
              `Error`,
              "You can't add this data because this month is already Approved",
              "error"
            ).then(() => {
              this.$bvModal.hide("modal-1");
              this.addBodyDataDaily.date_input = "";
            });
          } else {
            var rec2 = rec.filter((i) => {
              return i.oh_type == "Data", i.data_flag == "Monthly";
            });

            var rec3 = rec.filter((i) => {
              return i.oh_type == "Activity";
            });

            this.record = rec2;
            this.recordAct = rec3;
          }
        });
    },

    // gettotalMonthOhData() {
    // 	this.$store.dispatch("detailManagement/getDataDailyInput", this.totalbodyDataDaily).then(() => {
    // 		const datas = this.$store.getters["detailManagement/OHDailyDataInput"];
    // 		const list = datas;
    // 		let rec = list.map((item) => {
    // 			return {
    // 				oh_type: "Data",
    // 				oh_id: Number(item.oh_id),
    // 				values: item.values,
    // 			};
    // 		});
    // 		this.totalMonthData = rec;
    // 	});
    // },
    // findTotalMonthData(id, type) {
    // 	const data = this.totalMonthData;
    // 	let obj = data.find((o) => {
    // 		return o.oh_type == type && o.oh_id == id;
    // 	});
    //   var maping = obj?.values;
    //   let datas = maping?.map((item) => {
    //     return item.value
    //   })
    //   var dat = datas?.reduce((total, value) => total + value, 0)
    // 	return Number(dat);
    // },

    // gettotalMonthActivity() {
    // 	this.$store.dispatch("detailManagement/getDataDailyInput", this.totalbodyActivityDaily).then(() => {
    // 		const datas = this.$store.getters["detailManagement/OHDailyDataInput"];
    // 		const list = datas;
    // 		let rec = list.map((item) => {
    // 			return {
    // 				oh_type: "Activity",
    // 				oh_id: Number(item.oh_id),
    // 				value: item.values,
    // 			};
    // 		});
    // 		this.totalMonthActivity = rec;
    // 	});
    // },
    // findTotalMonthActivity(id, type) {
    // 	const data = this.totalMonthActivity;
    // 	let obj = data.find((o) => {
    // 		return o.oh_type == type && o.oh_id == id;
    // 	});

    //   let datas = obj.value.map((item) => {
    //     return item.value
    //   })

    //   var dat = datas.reduce((total, value) => total + value, 0)

    // 	return dat;
    // },

    customGetOH() {
      this.load = true;
      this.$store
        .dispatch("detailManagement/getDataDaily", this.bodyDataDaily)
        .then(() => {
          this.load = false;
        });
    },
    getCompanny() {
      this.$store.dispatch("detailManagement/getCompany");
    },

    getActivity() {
      this.$store.dispatch("activity/getActivity", this.body).then(() => {
        const datas = this.$store.getters["activity/ActivityData"];
        const list = datas.data;
        let rec = list.map((item) => {
          return {
            oh_type: "Activity",
            oh_id: Number(item.id),
            oh_label: item.activity_name,
            value: item.type_data == "Integer" && !item.is_mandatory ? 0 : ""
          };
        });
        // let recs = list.map((item) => {
        //   return {
        //     id: Number(item.id),
        //   };
        // });
        this.recordAct = rec;
        // this.recs2 = recs;
      });

      // this.customGetActivity();
    },

    // filter
    filterPage() {
      this.getOh();
      this.getActivity();
    },

    sorting() {
      this.totalbodyDataDaily.date_input = this.bodyDataDaily.date_input;
      this.totalbodyActivityDaily.date_input = this.bodyDataDaily.date_input;
      this.getOh();
      this.getActivity();
      this.customGetOH();
      // this.gettotalMonthOhData();
      // this.gettotalMonthActivity();
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;

      if (
        parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
        1000
      ) {
        this.getOh();
      }
    },

    type1() {
      this.bodyDataDaily.oh_type = "Data";
      this.customGetOH();
    },
    type2() {
      this.bodyDataDaily.oh_type = "Activity";
      this.customGetOH();
    },
    convertNumber(num) {
      if (num) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      }
    }
  }
};
</script>
