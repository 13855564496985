import axiosIns from '@/api/axios'

export const GET_ROLE_DATA = (args) => {
    return axiosIns.get(`/roles?${args}`)
}

export const ADD_ROLE = (args) => {
    return axiosIns.post(`/roles/create`, args)
}

export const DEL_ROLE = (id) => {
    return axiosIns.put(`/roles/delete?id=${id}`)
}

export const UPDATE_ROLE  = (args) => {
    return axiosIns.put(`/roles/update?id=${args.id}`, args)
}