import axiosIns from '@/api/axios'

export const GET_TRAINING_GROUP_DATA = (args) => {
    return axiosIns.get(`/medical/staff/training/group?${args}`)
}

export const ADD_TRAINING_GROUP = (args) => {
    return axiosIns.post(`/medical/staff/training/group/create`, args)
}

export const DEL_TRAINING_GROUP = (id) => {
    return axiosIns.put(`/medical/staff/training/group/delete?id=${id}`)
}

export const UPDATE_TRAINING_GROUP  = (args) => {
    return axiosIns.put(`/medical/staff/training/group/update?id=${args.id}`, args)
}