<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-6">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item ">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item">{{ $t("Medical Waste") }}</li>
                <li class="breadcrumb-item fw-bolder">Disposal</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Table -->
      <div class="row">
        <div class="col">
          <div class="card mt-2">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title">{{ $t("List Disposal Item") }}</h4>
              <!-- Button -->
              <b-button variant="primary" size="sm" @click="$router.push(`/clinic-management/inventory/bin`)">New
              </b-button>
              <b-button class="ml-2" variant="warning" size="sm" @click="exporting">Export</b-button>
            </div>
            <div class="card-body">
              <div class="row mb-5">
                <div class="col-lg-6">
                  <label class="mb-0 mr-3">Search : </label>
                  <b-form-input v-model="body.searchQuery" class="" placeholder="Type Here..." @change="getDisposal()">
                  </b-form-input>
                </div>
                <div class="col-lg-6">
                  <label class="mb-0 mr-3">Partner : </label>
                  <v-select v-model="body.disposal_partner_id" :reduce="(option) => option.id" :options="partner.rows" label="partner_name" @input="getDisposal"></v-select>
                </div>
              </div>

              <vue-good-table mode="remote" :line-numbers="true" :columns="listcolumns" :rows="dataDisposal.rows"
                :total-rows="dataDisposal.totalRecords" :sort-options="{
                  enabled: true
                }" :pagination-options="{
                  enabled: true,
                  mode: 'pages',
                  position: 'bottom',
                  perPage: body.perPage,
                  perPageDropdown: [10, 50, 100],
                  dropdownAllowAll: false,
                  setCurrentPage: 1,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'page',
                  allLabel: 'All'
                }" style-class="vgt-table table table-bordered" @on-page-change="onPageChange"
                @on-per-page-change="onPageChange" @on-sort-change="onSortChange" :isLoading.sync="isLoading">
                <!-- Loading -->
                <div slot="emptystate" class="text-center font-size-14">
                  No data available
                </div>
                <template slot="table-row" slot-scope="props">
                  <!-- Table Action -->
                  <span v-if="props.column.field == 'action'">
                    <b-button class="no-wrap btn-pill" title="Detail" variant="info" size="sm" v-b-modal.modal-lg
                      @click="detail(props.row)">
                      <span class="fe fe-clipboard"></span>
                    </b-button>
                    <!-- <b-button class="no-wrap btn-pill" title="Print" variant="warning" size="sm" v-b-modal.modal-lg
                      @click="print('printAble')">
                      <span class="fe fe-printer"></span>
                    </b-button> -->
                  </span>
                  <!-- <span v-else-if="props.column.field == 'price'">
                                        Rp. {{ convertNumber(props.row.price) }}
                                    </span> -->
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
      <b-modal id="modal-lg" size="lg" title="Detail Item" hide-footer>
        <b-button class="float-end no-wrap btn-pill" title="Print" variant="warning" size="sm" @click="print">
          <span class="fe fe-printer"></span>
        </b-button>
        <div id="printAble">
          <img src="../../../assets/img/U_health_warna.png" style="width: 200px" />
          <h3 class="display-6 text-center mt-5">
            Acknowledgement Of Expiry Medicine & Disposable.
          </h3>
          <table cellpadding="2" id="rawTable" class="mt-2">
            <tr>
              <td>To</td>
              <td>:</td>
              <td>{{ dataDetail.disposal_partner_name }}</td>
            </tr>
            <tr>
              <td>From</td>
              <td>:</td>
              <td>{{ dataDetail.from }}</td>
            </tr>
            <tr>
              <td>Date</td>
              <td>:</td>
              <td>{{ convertDate(dataDetail.disposal_time) }}</td>
            </tr>
          </table>
          <p class="mt-2">
            Following monthly inventory control on all stock available on our
            (First Aid Post / Clinic) on site, we found that there are some
            items already expired :
          </p>
          <p class="mt-2">The Items Are:</p>
          <table class="table table-bordered po-table table-striped table-sm mt-3">
            <thead>
              <tr>
                <th scope="col text-center">NO</th>
                <th scope="col">SAP Item ID</th>
                <th scope="col">Item Name</th>
                <th scope="col">QTY</th>
                <th scope="col">EXP Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in dataDetail.items" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.sap_material_number }}</td>
                <td>{{ item.item_name }}</td>
                <td>{{ item.item_label_qty }}</td>
                <td>{{ convertDate(item.expiry_date) }}</td>
              </tr>
            </tbody>
          </table>
          <p class="mt-4">
            Once the items are expired, we will send to
            {{ dataDetail.disposal_partner }} that have agreement with nearest
            Hospital that have incinerator facility.
          </p>
          <p class="mt-2">
            All expired medicine / consumable which cannot be returned to the
            company the medicine were purchased from MUST be disposed with and
            only with the medical waste. Normally medical waste is incinerated
            at the high temperatures.
          </p>
          <p class="mt-2">
            This is to avoid that the items will be misused in the future.
          </p>
          <div class="row mt-5">
            <div class="col">Prepared by,</div>
            <div class="col">
              Acknowledge and approved to destroy The expiry items by,
            </div>
          </div>
          <div class="row" style="margin-top: 100px">
            <div class="col">
              {{ writeName }} <br />
              Paramedic On Duty
            </div>
            <div class="col">
              Yulianto <br />
              OHIH Specialist PT.BIB
            </div>
          </div>
        </div>
        <div id="views">
          <table cellpadding="2" id="rawTable" class="mt-2">
            <tr>
              <td>System Disposal Number</td>
              <td>:</td>
              <td>{{ dataDetail.sap_mw_number }}</td>
            </tr>
            <tr>
              <td>Disposal Date</td>
              <td>:</td>
              <td>{{ convertDate(dataDetail.disposal_time) }}</td>
            </tr>
            <tr>
              <td>Disposal Partner</td>
              <td>:</td>
              <td>{{ dataDetail.disposal_partner_name }}</td>
            </tr>
            <tr>
              <td>Note</td>
              <td>:</td>
              <td>{{ dataDetail.note }}</td>
            </tr>
          </table>
          <table class="table table-bordered table-striped table-sm mt-3">
            <thead>
              <tr>
                <th scope="col">NO</th>
                <th scope="col">SAP Item ID</th>
                <th scope="col">Item Name</th>
                <th scope="col">QTY</th>
                <th scope="col">EXP Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in dataDetail.items" :key="index">
                <th>{{ index + 1 }}</th>
                <td>{{ item.sap_material_number }}</td>
                <td>{{ item.item_name }}</td>
                <td>{{ item.item_label_qty }}</td>
                <td>{{ convertDate(item.expiry_date) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
  import moment from "moment";
  import axiosIns from "@/api/axios";
  export default {
    name: "inventoryInputDO",
    computed: {
      dataDisposal() {
        return this.$store.getters["medicalWaste/disposalData"] ?
          this.$store.getters["medicalWaste/disposalData"] : [];
      },
      partner() {
        return this.$store.getters["partner/PartnerData"] ?
          this.$store.getters["partner/PartnerData"] : [];
      }
    },
    data() {
      return {
        body: {
          page: 1,
          perPage: 10,
          searchQuery: "",
          disposal_partner_id: "",
          sort: ""
        },
        dataDetail: {},
        listcolumns: [{
            label: "Internal Disposal Number",
            field: "id",
            thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "System Disposal Number",
            field: "sap_mw_number",
            thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "Disposal Partner",
            field: "disposal_partner_name",
            thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "Status",
            field: "mw_status",
            thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle text-center"
          },
          {
            label: "note",
            field: "note",
            thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "Disposal Date",
            field: "disposal_date",
            thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle text-center"
          },
          {
            label: "ACTION",
            field: "action",
            thClass: "table-header no-wrap text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle text-center",
            sortable: false
          }
        ],
        isLoading: false,
        dispWaste: {
          sap_mw_number: "",
          disposal_partner: "",
          disposal_date: "",
          note: "",
          items: []
        },
        writeName: "",
        company: ""
      };
    },
    mounted() {
      this.getDisposal();
      this.getPartner();

    },
    methods: {
      async getDisposal() {
        this.isLoading = true;
        this.$store
          .dispatch("medicalWaste/getDisposableData", this.body)
          .finally(() => {
            this.isLoading = false;
          });
      },

      getPartner() {
        this.$store.dispatch("partner/getPartner", {
          page: 1,
          perPage: 1000,
          sort: "",
          searchQuery: ""
        });
      },

      convertNumber(num) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      },

      convertDate(d) {
        return moment(d).format("D MMM YYYY");
      },

      onSortChange(params) {
        let sort1 = params[0].field;
        let sort2 = params[0].type;
        this.body.sort = `${sort1},${sort2}`;
        this.getDisposal();
      },

      deleteData(id) {
        let body = {
          id: id
        };
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$store.dispatch("Do/deleteDO", body).then(() => {
                this.getDisposal();
                this.$swal.fire(
                  "Deleted!",
                  "Your file has been deleted.",
                  "success"
                );
              });
            }
          });
      },

      detail(props) {
        this.dataDetail = props;
        this.writeName = localStorage.getItem("fullname");
        this.company = localStorage.getItem("company_name");
      },

      badges(status) {
        switch (status) {
          case "SAVED":
            return `<span class="badge bg-info-soft">SAVED</span>`;
          case "DELIVERED":
            return `<span class="badge bg-success-soft">DELIVERED</span>`;
          case "CANCELED":
            return `<span class="badge bg-danger-soft">CANCELED</span>`;
          default:
            break;
        }
      },

      convertCurrency(dat) {
        return dat.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      },

      selectionChanged(e) {
        this.dispWaste.items = e.selectedRows.map((item) => {
          return item.id;
        });
      },

      submitForm() {
        this.$store.dispatch("medicalWaste/submitMedical", this.dispWaste);
      },

      async print() {
        // window.print();
        await this.$htmlToPaper("printAble");
      },

      exporting() {
        axiosIns
          .get(`${axiosIns.defaults.baseURL}/medical-waste/excel`, {
            responseType: "blob"
          })
          .then((res) => {
            const href = URL.createObjectURL(res.data);
            // create "a" HTML element with href to file & click
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", `Uhealth_Disposal Report.xlsx`); //or any other extension
            document.body.appendChild(link);
            link.click();
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          });
      },

      onPageChange(payload) {
        this.body.perPage = payload.currentPerPage;
        this.body.page = payload.currentPage;
        this.getDisposal();
      }

      // searchByQuery(e) {
      // }
    }
  };
</script>
<style>
  .po-table td:first-child,
  .po-table td:nth-last-child(3),
  .po-table td:nth-last-child(2),
  .po-table td:last-child {
    text-align: center;
  }

  .po-table> :not(caption)>*>* {
    padding: 0.5rem !important;
  }

  @media print {
    body * {
      visibility: hidden;
    }

    /* #printAble,
    #printAble * {
      
      visibility: visible;
    } */

    #views {
      display: none;
    }

    #printAble {
      display: block;
    }

    /* #printAble {
      top: 0;
      left: 0;
    } */
  }

  #views {
    display: block;
  }

  #printAble {
    display: none;
  }
</style>