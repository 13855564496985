import queryString from "query-string";

import {
    GET_MEASURING_ACTIVITY_DATA,
    ADD_MEASURING_ACTIVITY,
    DEL_MEASURING_ACTIVITY,
    UPDATE_MEASURING_ACTIVITY
} from "@/api/ih/measuring/activity";

const state = {
    dataMeasuringActivity: [],
};

const mutations = {
    setMeasuringActivity: (states, payload) => {
        states.dataMeasuringActivity = payload;
    },
};

const actions = {
    getMeasuringActivity: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_MEASURING_ACTIVITY_DATA(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setMeasuringActivity", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setMeasuringActivity", {
                        setMeasuringActivity: [],
                    });
                }
            });
    },

    addMeasuringActivity: async ({ }, args) => {
        return await ADD_MEASURING_ACTIVITY(args);
    },

    delMeasuringActivity: async ({ }, id) => {
        return await DEL_MEASURING_ACTIVITY(id);
    },

    updateMeasuringActivity: async ({ }, args) => {
        return await UPDATE_MEASURING_ACTIVITY(args);
    },



};

const getters = {
    MeasuringActivityData: (states) => states.dataMeasuringActivity
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};