<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <!-- / .row -->

      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("Healthcare Facility") }}</h4>
          <!-- Button -->
          <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
            <span class="fe fe-file-plus"></span>
            {{ $t("Add") }}
          </b-button>
        </div>
        <vue-good-table
          :line-numbers="true"
          :columns="columns"
          :rows="healthcare.data"
          :sort-options="{
            enabled: true
          }"
          :total-rows="healthcare.totalItems"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }"
          style-class="vgt-table border-0 table"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange">
          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <!-- Custom Rows -->
          <template slot="table-row" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.field == 'action'">
              <b-button
                class="no-wrap btn-pill"
                variant="warning"
                size="sm"
                @click="updateData(props.row)">
                <span class="fe fe-edit"></span>
              </b-button>
              <b-button
                class="no-wrap ml-2"
                variant="danger"
                style="margin-left: 5px"
                size="sm"
                @click="deleteData(props.row.healthcare_facility_id)">
                <span class="fe fe-trash-2"></span>
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </div>

      <!-- Modal -->
      <b-modal id="modal-1" hide-footer title="Healthcare Form">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="form-group">
            <label>{{ $t("Medical Facility") }}</label>
            <v-select
              v-model="bodyAdd.medical_facility_id"
              :options="facility.data"
              label="facility_name"
              :reduce="(e) => e.id"
              v-validate="'required'"
              name="Medical Facility"
              @input="medicalChanged(bodyAdd.medical_facility_id)" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Medical Facility')"
              >{{ errors.first("Medical Facility") }}</span
            >
          </div>
          <div class="form-group" v-if="hideCompany()">
            <label>{{ $t("Company") }}</label>
            <v-select
              :options="company"
              v-model="bodyAdd.company_id"
              label="company_name"
              :reduce="(e) => e.id"
              v-validate="'required'"
              name="Company" />
            <span class="text-sm text-danger" v-show="errors.has('Company')">{{
              errors.first("Company")
            }}</span>
          </div>

          <div class="form-group">
            <label>{{ $t("Facility Feature") }}</label>
            <div class="d-flex">
              <div v-for="(data, index) in feature.data" :key="index">
                <b-form-checkbox
                  :id="`checkbox-${data.id}`"
                  name="healthcare_facility_feature_id"
                  style="margin-right: 2rem"
                  :value="data.id"
                  :disabled="disabledData(data.id)"
                  v-model="bodyAdd.healthcare_facility_feature_id">
                  {{ data.healthcare_facility_feature_name }}
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>{{ $t("Healthcare Name") }}</label>
            <input
              type="text"
              v-model="bodyAdd.healthcare_facility_name"
              class="form-control"
              placeholder="Healthcare Name"
              v-validate="'required'"
              name="Facility Name" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Facility Name')"
              >{{ errors.first("Facility Name") }}</span
            >
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="form-group">
            <label>{{ $t("Medical Facility") }}</label>
            <v-select
              v-model="bodyUpdate.medical_facility_id"
              :options="facility.data"
              label="facility_name"
              :reduce="(e) => e.id"
              @input="medicalChanged(bodyUpdate.medical_facility_id)" />
          </div>
          <div class="form-group" v-if="hideCompany()">
            <label>{{ $t("Company") }}</label>
            <v-select
              :options="company"
              v-model="bodyUpdate.company_id"
              label="company_name"
              :reduce="(e) => e.id"
              v-validate="'required'"
              name="Company" />
            <span class="text-sm text-danger" v-show="errors.has('Company')">{{
              errors.first("Company")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Facility Feature") }}</label>
            <div class="d-flex">
              <div v-for="(data, index) in feature.data" :key="index">
                <b-form-checkbox
                  :id="`checkbox2-${data.id}`"
                  v-model="bodyUpdate.healthcare_facility_feature_id"
                  style="margin-right: 2rem"
                  :value="data.id"
                  :disabled="disabledData(data.id)"
                  unchecked-value="">
                  {{ data.healthcare_facility_feature_name }}
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>{{ $t("Healthcare Name") }}</label>
            <input
              type="text"
              v-model="bodyUpdate.healthcare_facility_name"
              class="form-control"
              placeholder="Healthcare Name" />
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { push } from "vue-cli-plugin-bootstrap-vue/sassAbstractsImports";
import item from "@/store/mcu/test/item";
export default {
  name: "defaultCMS",
  computed: {
    healthcare() {
      return this.$store.getters["healthcare/HealthcareData"]
        ? this.$store.getters["healthcare/HealthcareData"]
        : [];
    },
    facility() {
      return this.$store.getters["facility/FacilityData"]
        ? this.$store.getters["facility/FacilityData"]
        : [];
    },
    feature() {
      return this.$store.getters["healthcare/FeatureData"]
        ? this.$store.getters["healthcare/FeatureData"]
        : [];
    },
    company() {
      return this.$store.getters["detailManagement/dataCompany"]
        ? this.$store.getters["detailManagement/dataCompany"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        limit: 10,
        order: ""
      },
      bodyAdd: {
        medical_facility_id: "",
        healthcare_facility_feature_id: [],
        healthcare_facility_name: "",
        company_id: ""
      },
      bodyUpdate: {
        medical_facility_id: "",
        healthcare_facility_id: "",
        healthcare_facility_feature_id: [],
        healthcare_facility_name: "",
        company_id: ""
      },
      check: [],
      id: "",
      isAdd: true,
      isLoading: false,
      columns: [
        {
          label: "Medical Facility Name",
          field: "medical_facility_name",
          thClass: "table-header text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "Healthcare Facility Name",
          field: "healthcare_facility_name",
          thClass: "table-header text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "Facility Feature",
          field: "healthcare_facility_feature_name",
          thClass: "table-header text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ]
    };
  },
  mounted() {
    if (this.healthcare && this.healthcare.length < 1) {
      this.getHealthcare();
      this.getFacility();
      this.getFacilityFeature();
      this.getCompanny();
    }
  },
  methods: {
    disabledData(id) {
      let medical = null;
      if (this.isAdd) {
        medical = this.bodyAdd.medical_facility_id;
      } else {
        medical = this.bodyUpdate.medical_facility_id;
      }

      if (medical == 4) {
        if (id == 3) {
          return true;
        } else if (id == 2) {
          return false;
        } else {
          return false;
        }
      } else if (medical == 5) {
        if (id == 2) {
          return true;
        } else if (id == 3) {
          return true;
        }
      } else if (medical == 6) {
        if (id == 1) {
          return true;
        } else if (id == 2) {
          return true;
        }
      }
    },
    medicalChanged(id) {
      if (id == 4) {
        if (this.isAdd) {
          this.bodyAdd.healthcare_facility_feature_id = ["1"];
        } else {
          this.bodyUpdate.healthcare_facility_feature_id = ["1"];
        }
      } else if (id == 5) {
        if (this.isAdd) {
          this.bodyAdd.healthcare_facility_feature_id = ["1"];
        } else {
          this.bodyUpdate.healthcare_facility_feature_id = ["1"];
        }
      } else if (id == 6) {
        if (this.isAdd) {
          this.bodyAdd.healthcare_facility_feature_id = ["3"];
        } else {
          this.bodyUpdate.healthcare_facility_feature_id = ["3"];
        }
      }
    },
    hideCompany() {
      let medical = null;
      if (this.isAdd) {
        medical = this.bodyAdd.medical_facility_id;
      } else {
        medical = this.bodyUpdate.medical_facility_id;
      }

      if (medical == 4) {
        return true;
      } else if (medical == 5) {
        if (this.isAdd) {
          this.bodyAdd.company_id = "";
        } else {
          this.bodyUpdate.company_id = "";
        }
        return false;
      } else if (medical == 6) {
        if (this.isAdd) {
          this.bodyAdd.company_id = "";
        } else {
          this.bodyUpdate.company_id = "";
        }
        return false;
      }
    },
    // checkedFasility(id) {
    //   const medical = this.bodyAdd.medical_facility_id;
    //   const datas = this.bodyAdd.healthcare_facility_feature_id;
    //   const permissions_array = datas
    //   let dat = "1"
    //   let dat1 = "3"
    //   if (medical == 4) {
    //     // if (permissions_array.length > 1) {
    //     //   permissions_array[0] = '1'
    //     // }
    //     if (permissions_array.length == 0) {
    //       permissions_array.push(dat)
    //     }
    //     return 1
    //   }else if (medical == 5) {
    //     // if (permissions_array.length > 1) {
    //     //   permissions_array[0] = '1'
    //     //   permissions_array.splice(permissions_array, 2)
    //     // } else
    //     // if (permissions_array.length == 0) {
    //     //   permissions_array.push(dat)
    //     // }
    //     return 1
    //   } else if (medical == 6) {
    //     // if (permissions_array.length > 1) {
    //     //   permissions_array[0] = '3'
    //     //   permissions_array.splice(permissions_array, 2)
    //     // } else
    //     if (permissions_array.length == 0) {
    //       permissions_array.push(dat1)
    //     }

    //     return 3
    //   }
    // },
    // dataChecked(event){
    //   const data = this.bodyAdd.healthcare_facility_feature_id;
    //   let permissions_array = data
    //   if (event) {
    //     if (permissions_array.length == 0) {
    //       permissions_array.push(event)
    //     } else if (permissions_array.length > 0) {
    //       permissions_array.push(event)
    //     } else {
    //       permissions_array = [event]
    //     }
    //   } else {
    //     permissions_array.push(event)
    //   }
    // },
    //action
    updateData(props) {
      this.$bvModal.show("modal-1");
      this.isAdd = false;
      this.bodyUpdate.healthcare_facility_id = props.healthcare_facility_id;
      this.bodyUpdate.healthcare_facility_feature_id =
        props.healthcare_facility_feature_id.split(",");
      this.bodyUpdate.healthcare_facility_name = props.healthcare_facility_name;
      this.bodyUpdate.medical_facility_id = props.medical_facility_id;
      this.bodyUpdate.company_id = props.company_id;
    },
    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.bodyAdd.healthcare_facility_feature_id =
            this.bodyAdd.healthcare_facility_feature_id.map((el) => {
              return parseInt(el);
            });
          // this.bodyAdd.healthcare_facility_feature_id = this.bodyAdd.healthcare_facility_feature_id.join(',')
          this.isLoading = true;
          this.$store
            .dispatch("healthcare/addHealthcare", this.bodyAdd)
            .then((resp) => {
              this.$swal("Saved", "Your input has been saved", "success").then(
                (resp) => {
                  this.isLoading = false;
                  this.bodyAdd.medical_facility_id = "";
                  this.bodyAdd.healthcare_facility_feature_id = [];
                  this.bodyAdd.healthcare_facility_name = "";
                  this.getHealthcare();
                  this.$bvModal.hide("modal-1");
                }
              );
            });
        } catch (resp) {
          this.$swal("Error", "Error while add data", "error");
        }
      });
    },

    saveUpdate() {
      // this.bodyUpdate.healthcare_facility_feature_id = this.bodyUpdate.healthcare_facility_feature_id.join(',')
      this.isLoading = true;
      this.bodyUpdate.healthcare_facility_feature_id =
        this.bodyUpdate.healthcare_facility_feature_id.map((el) => {
          return parseInt(el);
        });
      this.$store
        .dispatch("healthcare/updateHealthcare", this.bodyUpdate)
        .then((resp) => {
          this.$swal({
            title: this.$t("Saved"),
            text: this.$t("Your input has been saved"),
            icon: "success",
            confirmButtonText: this.$t("OK")
          }).then((resp) => {
            this.isLoading = false;
            this.getHealthcare();
            this.$bvModal.hide("modal-1");
          });
        })
        .catch((resp) => {
          this.$swal("Error", this.$t("Error while edit data"), "error");
        });
    },

    deleteData(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("healthcare/delHealthcare", id)
            .then((resp) => {
              this.getHealthcare();
              this.$swal(
                "Deleted!",
                this.$t("Your file has been deleted"),
                "success"
              );
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    },

    // api hit
    async getHealthcare() {
      await this.$store.dispatch("healthcare/getHealthcare", this.body);
    },
    async getFacility() {
      this.$store.dispatch("facility/getFacility", this.body);
    },
    async getFacilityFeature() {
      this.$store.dispatch("healthcare/getFeature", this.body);
    },
    getCompanny() {
      this.$store.dispatch("detailManagement/getCompany");
    },

    // filter
    filterPage() {
      this.getHealthcare();
    },

    sorting() {
      this.getHealthcare();
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getHealthcare();
      // if (
      //   parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
      //   1000
      // ) {
      //   this.getHealthcare();
      // }
    },
    // extra
    separator() {
      return this.healthcare_facility_feature_id.split(",");
    }
  }
};
</script>
