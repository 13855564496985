<template>
  <div class="row align-items-center justify-content-between">
    <div class="cart">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-3">
          <b-button variant="primary" v-b-modal.modal-1>Export</b-button>
          <div class="d-flex">
            <label class="align-self-center mb-0 mr-3">Search</label>
            <b-form-input
              class="w-auto"
              v-model="body.searchQuery"
              placeholder="Type Here..."
              @change="getDrugsList()"></b-form-input>
          </div>
        </div>
        <vue-good-table
          mode="remote"
          :line-numbers="true"
          :columns="columns"
          :rows="dataDrugs.rows"
          :total-rows="dataDrugs.totalRecords"
          :sort-options="{
            enabled: true,
            initialSortBy: {field:'item_label', type:'asc'}
          }"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: body.perPage,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }"
          style-class="vgt-table table table-bordered"
          @on-sort-change="onSortChange"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange"
          :isLoading.sync="isLoading">
          <!-- Loading -->
          <!-- @on-page-change="onPageChange"
                @on-per-page-change="onPageChange" -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'action'">
              <b-button
                class="no-wrap btn-pill ml-2"
                variant="success"
                size="sm"
                title="Detail Drug"
                @click.prevent="seeDetail(props.row.item_id)">
                <span class="fe fe-info"></span>
              </b-button>
              <b-button
                class="no-wrap ml-2"
                variant="info"
                style="margin-left: 5px"
                size="sm"
                title="Detail Stock"
                @click="seeStock(props.row.item_id)">
                <span class="fe fe-file-text"></span>
              </b-button>
              <b-button
                class="no-wrap btn-pill ml-2"
                variant="warning"
                size="sm"
                title="Mutation"
                @click.prevent="seeMutation(props.row.item_id)">
                <span class="fe fe-git-branch"></span>
              </b-button>
            </span>
            <div v-else-if="props.column.field === 'exp_date'">
              <!-- <div :class="tdFunc(props.row)">
							{{ props.row.exp_date }}
						</div> -->
              <span
                :class="`badge ${tdFunc(props.row)}`"
                style="font-size: 100% !important; padding: 0.33em 2em"
                >{{ nows(props.row.exp_date) }}</span
              >
            </div>
          </template>
        </vue-good-table>
      </div>
      <!-- Link -->
    </div>
    <b-modal id="modal-1" title="Export Data" hide-footer>
      <div class="form-group">
        <label for="exampleInputEmail1" class="form-label">Type</label>
        <v-select :options="['Monthly', 'Yearly']" v-model="bodyExport.type" />
      </div>
      <div
        class="form-group"
        v-if="bodyExport.type.length > 0 && bodyExport.type === 'Monthly'">
        <label for="exampleInputEmail1" class="form-label">{{
          $t("Month")
        }}</label>
        <input
          type="month"
          class="form-control"
          v-model="bodyExport.date"
          :max="now" />
      </div>
      <div
        class="form-group"
        v-if="bodyExport.type.length > 0 && bodyExport.type === 'Yearly'">
        <label for="exampleInputEmail1" class="form-label">{{
          $t("Year")
        }}</label>
        <datepicker
          input-class="form-control"
          format="yyyy"
          minimum-view="year"
          v-model="bodyExport.date">
        </datepicker>
        <!-- <input type="number" class="form-control" min="2020" :max="now" v-model="bodyExport.date" /> -->
      </div>
      <b-button class="float-end" variant="primary" @click="download"
        ><span class="fe fe-download"></span> Download</b-button
      >
      <b-button class="float-end mr-2" variant="primary" @click="reset"
        ><span class="fe fe-refresh-ccw"></span> Reset Form</b-button
      >
    </b-modal>
  </div>
</template>
<script>
import axiosIns from "@/api/axios";
import queryString from "query-string";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import store from "@/store";
export default {
  name: "drugs",
  computed: {
    dataDrugs() {
      return this.$store.getters["reporting/listDrugsData"]
        ? this.$store.getters["reporting/listDrugsData"]
        : [];
    }
  },
  components: {
    Datepicker
  },
  data() {
    return {
      body: {
        page: 1,
        perPage: 10,
        searchQuery: "",
        sort: "",
        item_id: "",
        type: "MEDICINE"
      },
      bodyExport: {
        type: "",
        item_type: "MEDICINE",
        date: ""
      },
      isLoading: false,
      now: moment().format("YYYY-MM"),
      columns: [
        // {
        // 	label: "Barcode",
        // 	field: "barcode",
        // 	thClass: `table-header no-wrap w-auto text-center text-muted font-weight-bold`,
        // 	tdClass: "no-wrap color-secondary text-center align-middle",
        // },
        {
          label: "Medicine Name",
          field: "item_label",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "UNIT",
          field: "uom_label",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Stock",
          field: "qty",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle"
        },
        {
          label: "EXP DATE",
          field: "expiry_date",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass:
            "table-header no-wrap text-muted text-center font-weight-bold",
          tdClass: "no-wrap color-secondary text-center align-middle",
          sortable: false
        }
      ]
    };
  },
  mounted() {
    this.getDrugsList();
  },

  methods: {
    nows(props) {
      const exp_date = moment(props, "DD-MM-YYYY");
      const formated = moment(exp_date).format("ddd, MMMM Do YYYY");
      return formated;
    },

    seeDetail(id) {
      this.$router.push(
        `/clinic-management/inventory/drugs-&-medical/drugs-detail?item_id=${id}`
      );
    },
    seeStock(id) {
      this.$router.push(
        `/clinic-management/inventory/drugs-&-medical/stock-detail?item_id=${id}`
      );
    },
    seeMutation(id) {
      this.$router.push(
        `/clinic-management/inventory/drugs-&-medical/mutation?item_id=${id}`
      );
    },

    onPageChange(payload) {
      this.body.perPage = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getDrugsList();
      // }
    },

    onSortChange(params) {
      let sort1 = params[0].field;
      let sort2 = params[0].type;
      this.body.sort = `${sort1},${sort2}`;
      this.getDrugsList();
    },

    async getDrugsList() {
      this.isLoading = true;
      this.$store.dispatch("reporting/getListDrugs", this.body).finally(() => {
        this.isLoading = false;
        let count = 0;
        for (let i = 0; i < this.dataDrugs.rows.length; i++) {
          if (this.dataDrugs.rows[i].base_qty < 3) {
            count += 1;
          }
        }

        if (count > 0) {
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            })
            .fire({
              icon: "warning",
              title: "There is an almost empty stock"
            });
        }
      });
    },

    reset() {
      this.bodyExport = {
        type: "",
        item_type: "MEDICINE",
        date: ""
      };
    },

    download() {
      if (this.bodyExport.type === "Yearly") {
        let year = moment(this.bodyExport.date).format("YYYY");
        this.bodyExport.date = `${year}-01-01`;
      }
      if (this.bodyExport.type === "Monthly") {
        this.bodyExport.date = `${this.bodyExport.date}-01`;
      }
      let params = queryString.stringify(this.bodyExport);
      axiosIns
        .get(`${axiosIns.defaults.baseURL}/inventory/stock/report?${params}`, {
          responseType: "blob"
        })
        .then((res) => {
          const href = URL.createObjectURL(res.data);
          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute(
            "download",
            `${this.bodyExport.type} Report Drug Stock (${this.bodyExport.date}).xlsx`
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          this.bodyExport = {
            type: "",
            item_type: "MEDICINE",
            date: ""
          };
        });
    }
  }
};
</script>
