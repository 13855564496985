<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-6">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item">
                  {{ $t("Medical & MCU Record") }}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <!-- Table -->
      <div class="mt-4">
        <!-- Content  -->
        <b-card no-body>
          <b-tabs pills card>
            <b-tab
              title="Medical Record"
              active
              style="padding: 0px !important"
            >
              <MedicalRecord />
            </b-tab>
            <b-tab title="MCU" style="padding: 0px !important">
              <MCU />
            </b-tab>
            <b-tab title="Riwayat Alergi" style="padding: 0px !important">
              <Riwayat />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import MedicalRecord from "@/components/clinic_management/medical_record_mcu/medical_record.vue";
import MCU from "@/components/clinic_management/medical_record_mcu/mcu.vue";
import Riwayat from "@/components/clinic_management/medical_record_mcu/riwayat.vue";

export default {
  name: "clinic_mcu",
  components: {
    Riwayat,
    MedicalRecord,
    MCU,
  },
  data() {
    return {
      direct: "MedicalRecord",
    };
  },
};
</script>
