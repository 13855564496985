import queryString from 'query-string'
import {
    ADD_DOC_ADD,
    DOC_UPDATE,
    DOC_DELETE,
    ADD_PLAN,
    GET_PLAN,
    UPDATE_PLAN,
    DELETE_PLAN,
    GET_ACTUAL
} from "@/api/oh_management/documentation";


const state = {
    dataActual: [],
    dataPlan: [],
    dataPlanList: []
};

const mutations = {
    setActual: (states, payload) => {
        states.dataActual = payload;
    },
    setPlan: (states, payload) => {
        states.dataPlan = payload;
    },
    setPlanList: (states, payload) => {
        states.dataPlanList = payload;
    },
};

const actions = {
    getActual: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_ACTUAL(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setActual", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setActual", {
                        setActual: [],
                    });
                }
            });
    },

    addOhDoc: async ({ }, args) => {
        return await ADD_DOC_ADD(args);
    },

    delDoc: async ({ }, id) => {
        return await DOC_DELETE(id);
    },

    updateDoc: async ({ }, args) => {
        return await DOC_UPDATE(args);
    },

    // Plan
    getPlans: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_PLAN(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setPlan", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setPlan", {
                        setPlan: [],
                    });
                }
            });
    },

    getPlanList: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_PLAN(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setPlanList", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setPlanList", {
                        setPlanList: [],
                    });
                }
            });
    },

    addPlan: async ({ }, args) => {
        return await ADD_PLAN(args);
    },
    updatePlan: async ({ }, args) => {
        return await UPDATE_PLAN(args);
    },
    deletePlan: async ({ }, id) => {
        return await DELETE_PLAN(id);
    },

};

const getters = {
    actualData: (states) => states.dataActual,
    planData: (states) => states.dataPlan,
    planListData: (states) => states.dataPlanList
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};