<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-6">
        <!-- Title -->
        <ul class="nav nav-tabs nav-tabs-sm card-header-tabs">
          <li class="nav nav-item">
            <h2 class="header-title">
              {{ $t("OH Management") }}
            </h2>
          </li>
          <li class="nav nav-item" style="margin-left: 7px">
            <h2 class="header-title">></h2>
          </li>
          <li class="nav nav-item" style="margin-left: 7px">
            <h2 class="header-title">Tenaga Kerja Sakit</h2>
          </li>
        </ul>
      </div>
      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">Tenaga Kerja Sakit</h4>
          <!-- form  -->
        </div>
        <div class="card-header">
          <div></div>
          <!-- Button -->
          <div>
            <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
              <span class="fe fe-download"></span>
              {{ $t("Add") }}
            </b-button>
          </div>
          <!-- <div style="margin-left: 15px;">
            <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
                <span class="fe fe-plus"></span>
                Add
            </b-button>
          </div> -->
          <b-button
            v-b-toggle.collapse-1
            variant="primary"
            style="margin-left: 7px">
            <span class="fe fe-sliders"></span>
          </b-button>
        </div>
        <b-collapse id="collapse-1">
          <form @submit.prevent="sorting()">
            <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label"
                    >Start Date</label
                  >
                  <input
                    type="date"
                    class="form-control"
                    v-model="body.hazard_name" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label"
                    >End Date</label
                  >
                  <input
                    type="date"
                    class="form-control"
                    v-model="body.hazard_name" />
                </div>
              </div>
            </div>

            <div class="row mb-3" style="margin-right: 15px">
              <div class="col"></div>
              <div class="col-auto">
                <!-- Button -->
                <button type="submit" class="btn btn-danger lift">
                  Filter
                </button>
              </div>
            </div>
            <!-- / .row -->
          </form>
        </b-collapse>
        <vue-good-table
          :line-numbers="true"
          :columns="columns"
          :rows="rows"
          :sort-options="{
            enabled: true
          }"
          :total-rows="chemical.totalItems"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }"
          style-class="vgt-table border-0 table"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange">
          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <!-- Custom Columns  -->
          <template slot="table-column" slot-scope="props" style="text-center">
            <span v-if="props.column.label == 'NIK'">
              <center>NIK</center>
            </span>
            <span v-if="props.column.label == 'NAMA'">
              <center>NAMA INISIAL</center>
            </span>
            <span v-if="props.column.label == 'POSISI'">
              <center>POSISI</center>
            </span>
            <span v-if="props.column.label == 'DEPARTEMEN'">
              <center>DEPARTEMEN</center>
            </span>
            <span v-if="props.column.label == 'JUMLAH HARI'">
              <center>
                JUMLAH <br />
                HARI ABSEN
              </center>
            </span>
            <span v-if="props.column.label == 'SPELL'">
              <center>SPELL</center>
            </span>
            <span v-if="props.column.label == 'DALAM'">
              <center>
                KLINIK <br />
                DALAM
              </center>
            </span>
            <span v-if="props.column.label == 'LUAR'">
              <center>
                KLINIK <br />
                LUAR
              </center>
            </span>
            <span v-if="props.column.label == 'DIAGNOSA'">
              <center>DIAGNOSA</center>
            </span>
            <span v-if="props.column.label == 'ACTION'"> ACTION </span>
          </template>
          <!-- Custom Rows -->
          <template slot="table-row" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.field == 'action'">
              <b-button
                class="no-wrap btn-pill"
                variant="warning"
                size="sm"
                @click="updateData(props.row)">
                <span class="fe fe-edit"></span>
              </b-button>
              <b-button
                class="no-wrap ml-2"
                variant="danger"
                style="margin-left: 5px"
                size="sm"
                @click="deleteData(props.row.id)">
                <span class="fe fe-trash-2"></span>
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "chemicalHazard",
  computed: {
    chemical() {
      return this.$store.getters["chemical/ChemicalData"]
        ? this.$store.getters["chemical/ChemicalData"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        limit: 10,
        order: "",
        hazard_name: ""
      },
      hazard_name: "",
      hazard_names: "",
      id: "",
      isAdd: true,
      columns: [
        {
          label: "NIK",
          field: "nik",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "NAMA",
          field: "name",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "POSISI",
          field: "posisi",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "DEPARTEMEN",
          field: "departemen",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "JUMLAH HARI",
          field: "jumlah",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "SPELL",
          field: "spell",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "DALAM",
          field: "dalam",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "LUAR",
          field: "luar",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "DIAGNOSA",
          field: "diagnosa",
          thClass: "table-header no-wrap w-100 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ],
      rows: [
        {
          nik: "C-011401",
          name: "AP",
          posisi: "Driver Sarana & Bis",
          departemen: "HRGA",
          jumlah: "1",
          spell: 1,
          diagnosa: "Myalgia"
        },
        {
          nik: "C-012811",
          name: "NH",
          posisi: "Crew Elektrik",
          departemen: "Electric",
          jumlah: "1",
          spell: 1,
          diagnosa: "Rhinitis Akut"
        }
      ]
    };
  },
  mounted() {
    if (this.chemical && this.chemical.length < 1) {
      this.getChemical();
    }
  },
  methods: {
    // api hit
    getChemical() {
      this.$store.dispatch("chemical/getChemical", this.body);
    },

    // filter
    filterPage() {
      this.getChemical();
    },

    sorting() {
      this.getChemical();
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;

      if (
        parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
        1000
      ) {
        this.getChemical();
      }
    }
  }
};
</script>
