import queryString from "query-string";

import {
    GET_COMPETENCY,
    ADD_COMPETENCY,
    DEL_COMPETENCY,
    UPDATE_COMPETENCY,

    GET_HEALTH,
    ADD_HEALTH,
    DEL_HEALTH,
    UPDATE_HEALTH,

    GET_SAFETY,
    ADD_SAFETY,
    DEL_SAFETY,
    UPDATE_SAFETY,

    ADD_YEARLY
} from "@/api/oh/yearly";

const state = {
    dataCompetency: [],
    dataHealth: [],
    dataSafety: []
};

const mutations = {
    setCompetency: (states, payload) => {
        states.dataCompetency = payload;
    },
    setHealth: (states, payload) => {
        states.dataHealth = payload;
    },
    setSafety: (states, payload) => {
        states.dataSafety = payload;
    },
};

const actions = {
    getCompetency: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_COMPETENCY(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    commit("setCompetency", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                if (resp.data.httpCode === 404) {
                    commit("setCompetency", {
                        setCompetency: [],
                    });
                }
            });
    },

    addCompetency: async ({}, args) => {
        return await ADD_COMPETENCY(args);
    },

    delCompetency: async ({}, id) => {
        return await DEL_COMPETENCY(id);
    },

    updateCompetency: async ({}, args) => {
        return await UPDATE_COMPETENCY(args);
    },


    //Health
    getHealth: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_HEALTH(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    commit("setHealth", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                if (resp.data.httpCode === 404) {
                    commit("setHealth", {
                        setHealth: [],
                    });
                }
            });
    },

    addHealth: async ({}, args) => {
        return await ADD_HEALTH(args);
    },

    delHealth: async ({}, id) => {
        return await DEL_HEALTH(id);
    },

    updateHealth: async ({}, args) => {
        return await UPDATE_HEALTH(args);
    },


    //Safety
    getSafety: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_SAFETY(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    commit("setSafety", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                if (resp.data.httpCode === 404) {
                    commit("setSafety", {
                        setSafety: [],
                    });
                }
            });
    },

    addSafety: async ({}, args) => {
        return await ADD_SAFETY(args);
    },

    delSafety: async ({}, id) => {
        return await DEL_SAFETY(id);
    },

    updateSafety: async ({}, args) => {
        return await UPDATE_SAFETY(args);
    },

    // Yearly Input
    addYearly: async ({}, args) => {
        return await ADD_YEARLY(args);
    },
};

const getters = {
    competencyData: (states) => states.dataCompetency,
    healthData: (states) => states.dataHealth,
    safetyData: (states) => states.dataSafety
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};