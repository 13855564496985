<template>
  <div class="main-content">
    <!-- HEADER -->
    <div class="header">
      <div class="container-fluid">
        <div class="card mt-5">
          <div class="card-body">
            <div class="row justify-content-between align-items-center">
              <div class="col-12 col-md-9 col-xl-7">
                <!-- Heading -->
                <h2 class="mb-2">{{ $t("Dashboard") }}</h2>

                <!-- Text -->
                <p class="text-muted mb-md-0">
                  {{
                    $t(
                      `Welcome ${fullname} ${
                        company_name !== "null"
                          ? `from ${company_name}`
                          : userRole && userRole.length > 0
                          ? `from ${userRole[0].healthcare_facility_name}`
                          : ""
                      }`
                    )
                  }}
                </p>
              </div>
              <div class="col-12 col-md-auto d-none">
                <button
                  class="btn btn-primary lift"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal">
                  <span class="fe fe-folder-plus"></span>
                  Report
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card mt-5"
          v-if="
            (company_name === null && company_name === 'null') ||
            (userRole && userRole.length < 1)
          ">
          <div class="card-body">
            For additional access, contact to BIB Admin
          </div>
        </div>
        <!-- Body -->
      </div>
    </div>
    <!-- / .header -->

    <!-- CARDS -->
    <div class="container-fluid">
      <div class="row d-none">
        <div class="col-12 col-lg-6 col-xl">
          <!-- Value  -->
          <div class="card">
            <div class="card-body">
              <div class="row align-items-center gx-0">
                <div class="col">
                  <!-- Title -->
                  <h6 class="text-uppercase text-muted mb-2">Value</h6>

                  <!-- Heading -->
                  <span class="h2 mb-0"> $24,500 </span>

                  <!-- Badge -->
                  <span class="badge bg-success-soft mt-n1"> +3.5% </span>
                </div>
                <div class="col-auto">
                  <!-- Icon -->
                  <span class="h2 fe fe-dollar-sign text-muted mb-0"></span>
                </div>
              </div>
              <!-- / .row -->
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl">
          <!-- Hours -->
          <div class="card">
            <div class="card-body">
              <div class="row align-items-center gx-0">
                <div class="col">
                  <!-- Title -->
                  <h6 class="text-uppercase text-muted mb-2">Total hours</h6>

                  <!-- Heading -->
                  <span class="h2 mb-0"> 763.5 </span>
                </div>
                <div class="col-auto">
                  <!-- Icon -->
                  <span class="h2 fe fe-briefcase text-muted mb-0"></span>
                </div>
              </div>
              <!-- / .row -->
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl">
          <!-- Exit -->
          <div class="card">
            <div class="card-body">
              <div class="row align-items-center gx-0">
                <div class="col">
                  <!-- Title -->
                  <h6 class="text-uppercase text-muted mb-2">Exit %</h6>

                  <!-- Heading -->
                  <span class="h2 mb-0"> 35.5% </span>
                </div>
                <div class="col-auto">
                  <!-- Chart -->
                  <div class="chart chart-sparkline">
                    <canvas class="chart-canvas" id="sparklineChart"></canvas>
                  </div>
                </div>
              </div>
              <!-- / .row -->
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl">
          <!-- Time -->
          <div class="card">
            <div class="card-body">
              <div class="row align-items-center gx-0">
                <div class="col">
                  <!-- Title -->
                  <h6 class="text-uppercase text-muted mb-2">Avg. Time</h6>

                  <!-- Heading -->
                  <span class="h2 mb-0"> 2:37 </span>
                </div>
                <div class="col-auto">
                  <!-- Icon -->
                  <span class="h2 fe fe-clock text-muted mb-0"></span>
                </div>
              </div>
              <!-- / .row -->
            </div>
          </div>
        </div>
      </div>

      <div
        class="header"
        v-if="
          userRole &&
          userRole.length > 0 &&
          userRole[0].healthcare_facility_type_clinic === 'Clinic Internal'
        ">
        <div class="card mt-5">
          <div class="card-body">
            <div class="row justify-content-between align-items-center">
              <div class="col-12 col-md-9 col-xl-7">
                <!-- Heading -->
                <h2 class="mb-2">Vaccination Summary</h2>
              </div>
              <div class="col-12 col-md-3" v-if="role_id === 1">
                <v-select
                  v-model="company_id"
                  :options="selectCompanyOption()"
                  label="company_name"
                  :reduce="(e) => e.id"
                  @input="getDataVacc()" />
              </div>
            </div>
          </div>
          <!-- Body -->
        </div>
      </div>
      <!-- / .row -->
      <div
        class="row"
        v-if="
          userRole &&
          userRole.length > 0 &&
          userRole[0].healthcare_facility_type_clinic === 'Clinic Internal'
        ">
        <div
          class="col-xl-3 col-md-6 col-sm-12"
          v-for="(item, index) in vacciness"
          :key="index">
          <!-- Traffic -->
          <div class="card">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title">{{ item.label }}</h4>
            </div>
            <div class="card-body">
              <!-- Chart -->
              <div id="chart">
                <apexchart
                  type="radialBar"
                  :height="dimensionChart()"
                  :options="chartOption(index)"
                  :series="totals(item.value)"></apexchart>
                <!-- <apexchart type="donut" :options="charts" :series="seriess"></apexchart> -->
                <h5 class="text-center">
                  <span class="fe fe-users mr-3"></span>
                  {{ convertNumber(item.total) }} of
                  {{ convertNumber(item.man_power) }} Employees
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / .main-content -->
</template>
<script>
// import Chart from 'chart.js'
import lang from "@/lang.json";
export default {
  name: "HomeView",
  computed: {
    vacciness() {
      return this.$store.getters["dashboard/vaccData"]
        ? this.$store.getters["dashboard/vaccData"]
        : [];
    },
    company() {
      return this.$store.getters["detailManagement/dataCompany"]
        ? this.$store.getters["detailManagement/dataCompany"]
        : [];
    }
  },
  data() {
    return {
      language: lang,
      direct: false,
      comp: false,
      company_id: "",
      role_id: "",
      fullname: "",
      company_name: "",
      // clinic_feature_id: "",
      chartSize: "",
      userRole: []
    };
  },
  created() {
    this.userRole = JSON.parse(localStorage.getItem("user_roles"));
  },
  mounted() {
    setTimeout(() => {
      this.role_id = parseInt(localStorage.getItem("roles_id"));
      this.company_id = localStorage.getItem("company");
      this.fullname = localStorage.getItem("fullname");
      this.company_name = localStorage.getItem("company_name");
      // let clinic_feature = localStorage.getItem("clinic_feature");
      // const data_feature = JSON.parse(clinic_feature);
      // let data_features = data_feature.map((e) => {
      //   return e.clinic_feature_id;
      // });
      // this.clinic_feature_id = parseInt(data_features[0]);
      this.getDataVacc();
      this.getCompany();
    }, 1500);
  },
  methods: {
    totals(e) {
      return [e];
    },

    chartOption(e) {
      let col = ["#F44336", "#f6c343", "#39afd1", "#00d97e"];
      // let chart = ""
      if (window.innerWidth <= 800) {
        this.chartSize = 500;
      } else if (window.innerWidth <= 1024) {
        this.chartSize = 400;
      } else if (window.innerWidth <= 1366) {
        this.chartSize = 280;
      } else if (window.innerWidth <= 1600) {
        this.chartSize = 300;
      } else if (window.innerWidth < 1920) {
        this.chartSize = 400;
      }

      let bodys = {
        chart: {
          height: this.chartSize,
          type: "radialBar",
          offsetY: -20
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e7e7e7",
              strokeWidth: "97%",
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: "#999",
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: "22px"
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          type: "fill",
          colors: col[e],
          gradient: {
            shade: "light",
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91]
          }
        },
        labels: ["Average Results"]
      };

      return bodys;
    },

    convertNumber(num) {
      return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    dimensionChart() {
      // let width = [800, 1024, 1366, 1600, 1920]
      if (window.innerWidth <= 800) {
        return "500";
      } else if (window.innerWidth <= 1024) {
        return "400";
      } else if (window.innerWidth <= 1366) {
        return "280";
      } else if (window.innerWidth <= 1600) {
        return "300";
      } else if (window.innerWidth < 1920) {
        return "400";
      }
    },

    selectCompanyOption() {
      const company = this.company;
      const term = [];
      term.push({
        id: 0,
        company_name: "BIB + Mitra"
      });

      company.map((i) => {
        term.push({
          id: i.id,
          company_name: i.company_name
        });
      });
      return term;
    },

    getDataVacc() {
      this.$store
        .dispatch("dashboard/getVacc", {
          company_id: this.company_id
        })
        .then(() => {
          let data = this.$store.getters["dashboard/vaccData"];
        });
    },
    getCompany() {
      this.$store.dispatch("detailManagement/getCompany");
    }
  }
};
</script>
