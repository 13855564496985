import queryString from "query-string";

import {
    GET_DISPOSABLE_DATA,
    ADD_DISPOSABLE,
    DEL_DISPOSABLE,
    UPDATE_DISPOSABLE
} from "@/api/inventory/disposable";

const state = {
    dataDisposable: [],
};

const mutations = {
    setDisposable: (states, payload) => {
        states.dataDisposable = payload;
    },
};

const actions = {
    getDisposables: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_DISPOSABLE_DATA(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setDisposable", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setDisposable", {
                        setDisposable: [],
                    });
                }
            });
    },

    addDisposable: async ({ }, args) => {
        return await ADD_DISPOSABLE(args);
    },

    delDisposable: async ({ }, id) => {
        return await DEL_DISPOSABLE(id);
    },

    updateDisposable: async ({ }, args) => {
        return await UPDATE_DISPOSABLE(args);
    },



};

const getters = {
    DisposableData: (states) => states.dataDisposable
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};