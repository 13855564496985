import queryString from 'query-string'
import {
    UPDATE_OH_DAILY
} from "@/api/oh_management/input";


const state = {
    dataOHDaily: [],
};

const mutations = {
    setOHDaily: (states, payload) => {

        states.dataOHDaily = payload;
    },
};

const actions = {

    updateOhDaily: async ({ }, args) => {
        return await UPDATE_OH_DAILY(args);
    },



};

const getters = {
    OHDailyData: (states) => states.dataOHDaily
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};