<template>
	<div>
		<div class="card p-0">
			<div class="card-header">
				<!-- Title -->
				<h4 class="card-header-title">Patient Info</h4>
			</div>
			<div v-if="load === false">
				<div class="list-group list-group-flush">
					<div class="list-group-item p-4">
						<div class="row">
							<div class="col-auto">
								<!-- Avatar -->
								<div class="avatar avatar-sm">
									<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
										<i class="fe fe-credit-card"></i>
									</div>
								</div>
							</div>
							<div class="col">
								<p class="small mt-2">
									<strong>NIK</strong>
								</p>
								<p class="small mt-2">
									<strong>{{ this.history.nik }}</strong>
								</p>
							</div>
							<div class="col-auto">
								<!-- Avatar -->
								<div class="avatar avatar-sm">
									<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
										<i class="fe fe-user"></i>
									</div>
								</div>
							</div>
							<div class="col">
								<p class="small mt-2">
									<strong>Name</strong>
								</p>
								<p class="small mt-2">
									<strong>{{ this.history.employee_name }}</strong>
								</p>
							</div>
							<div class="col-auto">
								<!-- Avatar -->
								<div class="avatar avatar-sm">
									<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
										<i class="fe fe-home"></i>
									</div>
								</div>
							</div>
							<div class="col">
								<p class="small mt-2">
									<strong>Company</strong>
								</p>
								<p class="small mt-2">
									<strong>{{ this.history.company }}</strong>
								</p>
							</div>
							<div class="col-auto">
								<!-- Avatar -->
								<div class="avatar avatar-sm">
									<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
										<i class="fe fe-layers"></i>
									</div>
								</div>
							</div>
							<div class="col">
								<p class="small mt-2">
									<strong>Departement</strong>
								</p>
								<p class="small mt-2">
									<strong>{{ this.history.department }}</strong>
								</p>
							</div>
							<div class="col-auto">
								<!-- Avatar -->
								<div class="avatar avatar-sm">
									<div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
										<i class="fe fe-award"></i>
									</div>
								</div>
							</div>
							<div class="col">
								<p class="small mt-2">
									<strong>Position</strong>
								</p>
								<p class="small mt-2">
									<strong>{{ this.history.position }}</strong>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body text-center" v-else>
				<div class="spinner-border" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
			</div>
		</div>
		<!-- <div class="row">
      <div
        class="col-12 col-xl-4"
        v-for="(item, index) in this.dataRecord"
        :key="index"
      > -->
		<div class="card" v-for="(item, index) in this.dataRec" :key="index">
			<div class="">
				<div class="card-header">
					<!-- Title -->
					<h4 class="card-header-title">{{ item.group_name }}</h4>
				</div>
				<div v-if="load === false">
					<div class="table-responsive">
						<table class="table table-sm">
							<thead>
								<tr>
									<th class="text-center">Check Title</th>
									<th class="text-center">Check Value</th>
									<th class="text-center">Item</th>
									<th class="text-center">Item Unit</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(e, i) in item.record" :key="i">
									<td>{{ e.check_title }}</td>
									<td>{{ e.check_value }}</td>
									<td>{{ e.item_name }}</td>
									<td>{{ e.item_unit }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="card-body text-center" v-else>
					<div class="spinner-border" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- </div>
  </div> -->
</template>
<script>
import moment from "moment";
export default {
	name: "reportStock",
	computed: {
		dataMCURecord() {
			return this.$store.getters["historyMcu/mcuRecordData"] ? this.$store.getters["historyMcu/mcuRecordData"] : [];
		},
	},
	data() {
		return {
			register_id: "",
			history: [],
			load: false,
			dataRec: [],
		};
	},
	mounted() {
		this.history = JSON.parse(localStorage.getItem("seehistory"));
    	this.register_id = this.history ? this.history.register_id : null;

		// this.getFisik();
		this.getDataColomn();
	},
	methods: {
		getDataColomn() {
			let body = {
				type_visit: "MCU",
				category_name: "Lab & Non Lab & Treadmill",
				id: this.register_id,
			};
			this.$store.dispatch("historyMcu/getMCUView", body).then(() => {
				const datas = this.$store.getters["historyMcu/mcuRecordData"];
				const list = datas.data;
				let rec = list.map((item) => {
					return item.mcu;
				});
				const da = rec[0];
				const rec2 = da[0].mcu_record;

				this.dataRec = rec2[0].detail_record;
			});
		},
		//action
		viewRecord(data) {
			localStorage.setItem("dataMCURecord", JSON.stringify(data));
			this.$router.push("/clinic-mcu/history/mcu-detail");
		},
	},
};
</script>
