import axiosIns from '@/api/axios'

export const GET_TYPE_DATA = (args) => {
    return axiosIns.get(`chemical/material/type?${args}`)
}

export const ADD_TYPE = (args) => {
    return axiosIns.post(`chemical/material/type/create`, args)
}

export const DEL_TYPE = (id) => {
    return axiosIns.put(`chemical/material/type/delete?id=${id}`)
}

export const UPDATE_TYPE = (args) => {
    return axiosIns.put(`chemical/material/type/update?id=${args.id}`, args)
}