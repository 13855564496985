<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-4">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item">
                  {{ $t("Role Management Clinic") }}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("Role Management Clinic") }}</h4>
          <!-- Button -->
          <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
            <span class="fe fe-file-plus"></span>
            {{ $t("Add") }}
          </b-button>
        </div>
        <vue-good-table
          :line-numbers="true"
          :columns="columns"
          :rows="rolemanagement"
          :sort-options="{
            enabled: true
          }"
          :total-rows="rolemanagement.totalItems"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }"
          style-class="vgt-table border-0 table"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange">
          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <!-- Custom Column -->
          <template slot="table-column" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.label == 'ACTION'">
              {{ $t("Action") }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'fullname'">
              {{ dataName(props.row) }}
            </span>
            <span v-if="props.column.field == 'fasility'">
              {{ dataFasility(props.row) }}
            </span>
            <span v-if="props.column.field == 'role'">
              {{ dataRole(props.row) }}
            </span>
            <span v-if="props.column.field == 'action'">
              <b-button
                class="no-wrap btn-pill"
                variant="warning"
                size="sm"
                @click="updateData(props.row)">
                <span class="fe fe-edit"></span>
              </b-button>
              <b-button
                class="no-wrap ml-2"
                variant="danger"
                style="margin-left: 5px"
                size="sm"
                @click="deleteData(props.row.id)">
                <span class="fe fe-trash-2"></span>
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </div>

      <!-- Modal -->
      <b-modal
        id="modal-1"
        hide-footer
        centered
        :title="$t('Role Management Clinic Form')">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="form-group">
            <label>{{ $t("Healthcare Facility") }}</label>
            <v-select
              v-model="bodyAdd.clinic_id"
              :options="healthcareFasility.data"
              label="healthcare_facility_name"
              :reduce="(e) => e.healthcare_facility_id"
              v-validate="'required'"
              name="Healthcare Fasility" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Healthcare Fasility')"
              >{{ errors.first("Healthcare Fasility") }}</span
            >
          </div>
          <div class="form-group">
            <label>{{ $t("Role") }}</label>
            <v-select
              v-model="bodyAdd.position_id"
              :options="role"
              label="position_name"
              :reduce="(e) => e.id"
              v-validate="'required'"
              name="Role" />
            <span class="text-sm text-danger" v-show="errors.has('Role')">{{
              errors.first("Role")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("User") }}</label>
            <v-select
              v-model="bodyAdd.user_id"
              :options="users"
              label="fullname"
              :reduce="(e) => e.keycloak_user_id" />
            <span class="text-sm text-danger" v-show="errors.has('User')">{{
              errors.first("User")
            }}</span>
          </div>
          <div class="form-group">
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
        <form @submit.prevent="saveUpdate()" v-else>
          <div class="form-group">
            <label>{{ $t("Healthcare Facility") }}</label>
            <v-select
              v-model="bodyUpdate.clinic_id"
              :options="healthcareFasility.data"
              label="healthcare_facility_name"
              :reduce="(e) => e.healthcare_facility_id"
              v-validate="'required'"
              name="Healthcare Fasility" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Healthcare Fasility')"
              >{{ errors.first("Healthcare Fasility") }}</span
            >
          </div>
          <div class="form-group">
            <label>{{ $t("Role") }}</label>
            <v-select
              v-model="bodyUpdate.position_id"
              :options="role"
              label="position_name"
              :reduce="(e) => e.id"
              v-validate="'required'"
              name="Role" />
            <span class="text-sm text-danger" v-show="errors.has('Role')">{{
              errors.first("Role")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("User") }}</label>
            <v-select
              v-model="bodyUpdate.user_id"
              :options="users"
              label="fullname"
              :reduce="(e) => e.keycloak_user_id"
              v-validate="'required'"
              name="User" />
            <span class="text-sm text-danger" v-show="errors.has('User')">{{
              errors.first("User")
            }}</span>
          </div>
          <div class="form-group">
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "defaultCMS",
  computed: {
    rolemanagement() {
      return this.$store.getters["roleClinicManagement/roleClinicManagement"]
        ? this.$store.getters["roleClinicManagement/roleClinicManagement"]
        : [];
    },
    role() {
      return this.$store.getters["roleClinicManagement/dataRole"]
        ? this.$store.getters["roleClinicManagement/dataRole"]
        : [];
    },
    healthcareFasility() {
      return this.$store.getters["healthcareFasility/HealthcareFasilityData"]
        ? this.$store.getters["healthcareFasility/HealthcareFasilityData"]
        : [];
    },
    users() {
      return this.$store.getters["roleClinicManagement/dataUser"]
        ? this.$store.getters["roleClinicManagement/dataUser"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        limit: 10,
        order: "",
        company_id: localStorage.getItem("company")
      },
      bodyFasility: {
        page: 1,
        limit: 1000,
        order: ""
      },
      bodys: {
        page: 1,
        limit: 10,
        order: ""
      },
      bodyAdd: {
        position_id: "",
        clinic_id: "",
        user_id: ""
      },
      bodyUpdate: {
        id: "",
        position_id: "",
        clinic_id: "",
        user_id: ""
      },
      isAdd: true,
      isLoading: false,
      columns: [
        {
          label: "NAME",
          field: "fullname",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "HEALTHCARE FASILITY",
          field: "fasility",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "ROLE",
          field: "role",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ],
      selectBlock: []
    };
  },
  mounted() {
    this.getRoleManagementClinic();
    this.getRole();
    this.getUsers();
    this.getHealthcareFasility();
  },
  methods: {
    dataName(props) {
      return props.fullname;
    },

    dataRole(props) {
      const data = props.clinic_roles;
      let datas = [];
      data.map((e) => {
        let dat = e.position;
        datas = dat.map((item) => {
          return item.position_name;
        });
      });
      return datas.join(", ");
    },
    dataFasility(props) {
      const data = props.clinic_roles;
      let datas = data.map((e) => {
        return e.clinic_name;
      });
      return datas.join(", ");
    },
    reset() {
      this.bodyAdd.position_id = "";
      this.bodyAdd.clinic_id = "";
      this.bodyAdd.user_id = "";
      this.bodyUpdate.position_id = "";
      this.bodyUpdate.clinic_id = "";
      this.bodyUpdate.user_id = "";
    },
    updateData(props) {
      this.$bvModal.show("modal-1");
      const data = props.clinic_roles;
      let datas = [];
      data.map((e) => {
        let dat = e.position;
        datas = dat.map((item) => {
          return item.position_id;
        });
      });
      const datafas = data.map((e) => {
        return e.clinic_id;
      });
      this.isAdd = false;
      this.reset();
      this.bodyUpdate.id = props.id;
      this.bodyUpdate.position_id = datas;
      this.bodyUpdate.clinic_id = datafas;
      this.bodyUpdate.user_id = props.keycloak_user_id;
    },
    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.isLoading = true;
          this.$store
            .dispatch(
              "roleClinicManagement/addRoleClinicManagement",
              this.bodyAdd
            )
            .then((resp) => {
              this.$swal({
                title: this.$t("Saved"),
                text: this.$t("Your input has been saved"),
                icon: "success",
                confirmButtonText: this.$t("OK")
              }).then((resp) => {
                this.isLoading = false;
                this.bodyAdd.position_id = "";
                this.bodyAdd.clinic_id = "";
                this.bodyAdd.user_id = "";
                this.getRoleManagementClinic();
                this.$bvModal.hide("modal-1");
              });
            });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
        }
      });
    },
    saveUpdate() {
      this.isLoading = true;
      this.$store
        .dispatch(
          "roleClinicManagement/updateRoleClinicManagement",
          this.bodyUpdate
        )
        .then((resp) => {
          this.$swal({
            title: this.$t("Saved"),
            text: this.$t("Your input has been saved"),
            icon: "success",
            confirmButtonText: this.$t("OK")
          }).then((resp) => {
            this.isLoading = false;
            this.getRoleManagementClinic();
            this.$bvModal.hide("modal-1");
          });
        })
        .catch((resp) => {
          this.$swal("Error", this.$t("Error while add data"), "error");
        });
    },
    deleteData(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          this.$store
            .dispatch("roleClinicManagement/deleteRoleClinicManagement", id)
            .then((resp) => {
              this.isLoading = false;
              this.getRole();
              this.$swal(
                "Deleted!",
                this.$t("Your file has been deleted"),
                "success"
              );
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    },

    // api hit
    async getRoleManagementClinic() {
      this.$store.dispatch(
        "roleClinicManagement/getDataRoleClinicManagement",
        this.body
      );
    },
    getRole() {
      this.$store.dispatch("roleClinicManagement/getRole");
    },
    getUsers() {
      this.$store.dispatch("roleClinicManagement/getUser");
    },
    async getHealthcareFasility() {
      await this.$store.dispatch(
        "healthcareFasility/getHealthcareFasility",
        this.bodyFasility
      );
    },

    // filter
    filterPage() {
      this.getRoleManagementClinic();
    },

    sorting() {
      this.getRoleManagementClinic();
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getRoleManagementClinic();
    },

    chapterAddSelect() {
      let blockData = this.block.data;
      let chapterId = this.bodyAdd.chapter_id;
      this.selectBlock = blockData.filter((item) => {
        return item.chapter_id == chapterId;
      });
    },

    chapterUpdateSelect() {
      let blockData = this.block.data;
      let chapterId = this.bodyUpdate.chapter_id;
      this.selectBlock = blockData.filter((item) => {
        return item.chapter_id == chapterId;
      });
    }
  }
};
</script>
