import queryString from "query-string";
import {
    EXPORT_EXCEL,
    GET_DRUGS, GET_DRUGS_DETAIL, GET_MEDICAL, GET_MUTATION, GET_STOCK_DETAIL, REVERT_DRUG, UPDATE_MEDICAL_TOOL,
} from "@/api/clinic/reporting";

const state = {
    dataDrugs: [],
    dataDrugsDetail: [],
    dataMutation: [],
    dataStockDetail: [],
    dataExcel: []
};

const mutations = {
    setDrugsList: (states, payload) => {
        states.dataDrugs = payload;
    },
    setDrugsDetail: (states, payload) => {
        states.dataDrugsDetail = payload;
    },
    setStockDetail: (states, payload) => {
        states.dataStockDetail = payload;
    },
    setMutatation: (states, payload) => {
        states.dataMutation = payload;
    },
    setExcel: (states, payload) => {
        states.dataExcel = payload;
    },
}

const actions = {
    getListDrugs: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_DRUGS(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {

                    commit("setDrugsList", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setDrugsList", {
                        setDrugsList: [],
                    });
                }
            });
    },

    getListMedical: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_MEDICAL(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {

                    commit("setDrugsList", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setDrugsList", {
                        setDrugsList: [],
                    });
                }
            });
    },

    detailDrugs: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_DRUGS_DETAIL(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {

                    commit("setDrugsDetail", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setDrugsDetail", {
                        setDrugsDetail: [],
                    });
                }
            });
    },

    detailStock: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_STOCK_DETAIL(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {

                    commit("setStockDetail", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setStockDetail", {
                        setStockDetail: [],
                    });
                }
            });
    },

    getMutation: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_MUTATION(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {

                    commit("setMutatation", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setMutatation", {
                        setMutatation: [],
                    });
                }
            });
    },

    medicalWaste: async ({ }, args) => {
        return await REVERT_DRUG(args);
    },

    downloadExport: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await EXPORT_EXCEL(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {

                    commit("setMutatation", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setMutatation", {
                        setMutatation: [],
                    });
                }
            });
    },

    updateMediTool: async ({ }, args) => {
        return await UPDATE_MEDICAL_TOOL(args);
    },



}

const getters = {
    listDrugsData: (states) => states.dataDrugs,
    detailDrugsData: (states) => states.dataDrugsDetail,
    detailStockData: (states) => states.dataStockDetail,
    mutationData: (states) => states.dataMutation,
    excelData: (states) => states.dataExcel,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};