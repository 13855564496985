<template>
  <div>
    <div class="card p-0">
      <div class="card-header">
        <!-- Title -->
        <h4 class="card-header-title">Patient Info</h4>
      </div>
      <div v-if="load === false">
        <div class="list-group list-group-flush">
          <div class="list-group-item p-4">
            <div class="row">
              <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                  <div
                    class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary"
                  >
                    <i class="fe fe-credit-card"></i>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="small mt-2">
                  <strong>NIK</strong>
                </p>
                <p class="small mt-2">
                  <strong>{{ this.history.nik }}</strong>
                </p>
              </div>
              <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                  <div
                    class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary"
                  >
                    <i class="fe fe-user"></i>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="small mt-2">
                  <strong>Name</strong>
                </p>
                <p class="small mt-2">
                  <strong>{{ this.history.employee_name }}</strong>
                </p>
              </div>
              <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                  <div
                    class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary"
                  >
                    <i class="fe fe-home"></i>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="small mt-2">
                  <strong>Company</strong>
                </p>
                <p class="small mt-2">
                  <strong>{{ this.history.company }}</strong>
                </p>
              </div>
              <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                  <div
                    class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary"
                  >
                    <i class="fe fe-layers"></i>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="small mt-2">
                  <strong>Departement</strong>
                </p>
                <p class="small mt-2">
                  <strong>{{ this.history.department }}</strong>
                </p>
              </div>
              <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                  <div
                    class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary"
                  >
                    <i class="fe fe-award"></i>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="small mt-2">
                  <strong>Position</strong>
                </p>
                <p class="small mt-2">
                  <strong>{{ this.history.position }}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body text-center" v-else>
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <!-- Data Lab & Dokter -->
    <div class="card p-0">
      <div class="card-header">
        <!-- Title -->
        <h4 class="card-header-title">Lab & Doctor Name</h4>
      </div>
      <div v-if="load === false">
        <div class="list-group list-group-flush">
          <div class="list-group-item p-4">
            <div class="row">
              <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                  <div
                    class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary"
                  >
                    <i class="fe fe-home"></i>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="small mt-2">
                  <strong>Lab</strong>
                </p>
                <p class="small mt-2">
                  <strong>{{ this.name_lab }}</strong>
                </p>
              </div>
              <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                  <div
                    class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary"
                  >
                    <i class="fe fe-user"></i>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="small mt-2">
                  <strong>Doctor Name</strong>
                </p>
                <p class="small mt-2">
                  <strong>{{ this.doctor_name }}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body text-center" v-else>
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div
        class="col-12 col-xl-4"
        v-for="(item, index) in this.dataRecord"
        :key="index"
      > -->
      <div class="card" v-for="(item, index) in kesimpulan" :key="index">
      <div class="">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ item.group_name }}</h4>
        </div>
        <div v-if="load === false">
          <div class="table-responsive">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th > Title</th>
                  <th > Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(e, i) in item.record" :key="i">
                  <td >{{ e.check_title }}</td>
                  <td >{{ e.check_value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-body text-center" v-else>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-for="(item, index) in rekomendasi" :key="index">
      <div class="">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ item.group_name }}</h4>
        </div>
        <div v-if="load === false">
          <div class="table-responsive">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(e, i) in item.record" :key="i">
                  <td >{{ e.check_title }}</td>
                  <td >{{ e.check_value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-body text-center" v-else>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-for="(item, index) in status_kesehatan" :key="index">
      <div class="">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ item.group_name }}</h4>
        </div>
        <div v-if="load === false">
          <div class="table-responsive">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th >Title</th>
                  <th >Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(e, i) in item.record" :key="i">
                  <td >{{ e.check_title }}</td>
                  <td >{{ e.check_value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-body text-center" v-else>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-for="(item, index) in this.dataRecord" :key="index">
      <div class="">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ item.group_name }}</h4>
        </div>
        <div v-if="load === false">
          <div class="table-responsive">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(e, i) in item.record" :key="i">
                  <td >{{ e.check_title }}</td>
                  <td >{{ e.check_value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-body text-center" v-else>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div>
  </div> -->
</template>
<script>
import axiosIns from "@/api/axios";
export default {
  name: "reportStock",
  computed: {
    dataMCURecord() {
      return this.$store.getters["historyMcu/mcuRecordData"]
        ? this.$store.getters["historyMcu/mcuRecordData"]
        : [];
    },
  },
  data() {
    return {
      register_id: "",
      history: [],
      load: false,
      dataRecord: [],
      kesimpulan:[],
      rekomendasi: [],
      status_kesehatan: [],
      doctor_name:"",
      name_lab:""
    };
  },
  mounted() {
    this.history = JSON.parse(localStorage.getItem("seehistory"));
    this.register_id = this.history ? this.history.register_id : null;
    
    this.getDataColomn();
    this.getDataColomnfisik()
  },
  methods: {
    getDataColomnfisik() {
      let body = {
        type_visit: "MCU",
        category_name: "Identitas & Pemeriksaan Fisik",
        id: this.register_id,
      };
      
      axiosIns.get(`${axiosIns.defaults.baseURL}/clinic/patient/mcu?type_visit=MCU&category_name=${body.category_name.toString().replace('&', '%26')}&id=${body.id}`).then((e) => {
        let gets = e.data.data.data[0].mcu[0].mcu_record[0]
        let getMcu = e.data.data.data[0].mcu[0]
        this.doctor_name = getMcu.doctor_name
        this.name_lab = getMcu.clinic_name
        this.kesimpulan = gets.detail_record.filter(this.filtering1)
        this.rekomendasi = gets.detail_record.filter(this.filtering2)
        this.status_kesehatan = gets.detail_record.filter(this.filtering3)
        // let afterFilter = gets.filter()
      })
        // const datas = this.$store.getters["historyMcu/mcuRecordData"];
        // const list = datas.data;
        // let rec = list.map((item) => {
        //   return item.mcu;
        // });
        // const da = rec[0];
        // const rec2 = da[0].mcu_record;

        // this.dataRecord = rec2[0].detail_record;
      // });
    },
    getDataColomn() {
      let body = {
        type_visit: "MCU",
        category_name: "Riwayat Kesehatan & Pajanan",
        id: this.register_id,
      };
      this.$store.dispatch("historyMcu/getMCUView", body).then(() => {
        const datas = this.$store.getters["historyMcu/mcuRecordData"];
        const list = datas.data;
        let rec = list.map((item) => {
          return item.mcu;
        });
        const da = rec[0];
        const rec2 = da[0].mcu_record;

        this.dataRecord = rec2[0].detail_record;
      });
    },
    viewRecord(data) {
      localStorage.setItem("dataMCURecord", JSON.stringify(data));
      this.$router.push("/clinic-mcu/history/mcu-detail");
    },
    getRiwayat() {
      let body = {
        type_visit: "MCU",
        category_name: "Riwayat Kesehatan & Pajanan",
        id: this.register_id,
      };
      this.$store.dispatch("historyMcu/getMCUView", body);
    },
    filtering1(e) {
      return e.group_name == 'KESIMPULAN'
    },
    filtering2(e) {
      return e.group_name == 'REKOMENDASI'
    },
    filtering3(e) {
      return e.group_name == 'STATUS KESEHATAN'
    }
  },
};
</script>
