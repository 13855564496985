import axiosIns from '@/api/axios'

export const GET_UNIT_DATA = (args) => {
    return axiosIns.get(`/unit?${args}`)
}

export const ADD_UNIT = (args) => {
    return axiosIns.post(`/unit/create`, args)
}

export const DEL_UNIT = (id) => {
    return axiosIns.put(`/unit/delete?id=${id}`)
}

export const UPDATE_UNIT  = (args) => {
    return axiosIns.put(`/unit/update?id=${args.id}`, args)
}