<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <!-- / .row -->

      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("Overhandle") }}</h4>
          <!-- Button -->
          <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
            <span class="fe fe-file-plus"></span>
            {{ $t("Overhandle") }}
          </b-button>
        </div>

        <div class="cart">
          <div class="card-body">
            <div class="d-flex justify-content-end mb-3">
              <!-- <b-button variant="primary" v-b-modal.modal-1>Export</b-button> -->
              <div class="d-flex">
                <label class="align-self-center mb-0 mr-3">Search</label>
                <b-form-input class="w-auto" v-model="body.searchQuery" placeholder="Type Here..."
                  @change="getCompany()"></b-form-input>
              </div>
            </div>
            <vue-good-table mode="remote" :line-numbers="true" :columns="columns" :rows="dataOverhandle.data"
              :total-rows="dataOverhandle.totalItems" :sort-options="{
                  enabled: true
                }" :pagination-options="{
                  enabled: true,
                  perPage: body.perPage
                }" style-class="vgt-table table table-bordered" @on-sort-change="onSortChange"
              :isLoading.sync="isLoading" @on-page-change="onPageChange" @on-per-page-change="onPageChange">
              <!-- Loading -->
              <div slot="emptystate" class="text-center font-size-14">
                No data available
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <b-button class="no-wrap btn-pill mr-2" variant="success" size="sm" @click="approveHandle()">
                    <span class="fe fe-check"></span>
                  </b-button>
                  <b-button class="no-wrap btn-pill" variant="warning" size="sm" @click="updateData(props.row)">
                    <span class="fe fe-edit"></span>
                  </b-button>
                  <b-button class="no-wrap ml-2" variant="danger" style="margin-left: 5px" size="sm"
                    @click="deleteData(props.row.company_id)">
                    <span class="fe fe-trash-2"></span>
                  </b-button>

                </span>
                <!-- <span v-else-if="props.column.field == 'offsite_from_date'">
                  {{ convertDate(props.column.offsite_from_date) }}
                </span>
                <span v-else-if="props.column.field == 'offsite_to_date'">
                  {{ convertDate(props.column.offsite_to_date) }}
                </span> -->
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-end">
                  <div>
                    <b-pagination :value="1" :total-rows="dataOverhandle.totalItems" :per-page="body.perPage"
                      first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="m-3"
                      @input="
                          (value) => props.pageChanged({ currentPage: value })
                        ">
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
          <!-- Link -->
        </div>
      </div>

      <!-- Modal -->
      <b-modal id="modal-1" size="xl" hide-footer :title="$t('Overhandle Form')">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="form-group">
            <label>PIC <span class="text-danger">*</span></label>
            <v-select v-model="bodyAdd.user_id" :options="users" label="fullname" :reduce="(e) => e.keycloak_user_id"
              name="PIC" v-validate="'required'" />
            <span class="text-sm text-danger" v-show="errors.has('PIC')">{{
                errors.first("PIC")
              }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Offsite Reason") }}<span class="text-danger">*</span></label>
            <textarea class="form-control" v-model="bodyAdd.offsite_cause" name="Offsite Reason"
              v-validate="'required|max:64'"></textarea>
            <span class="text-sm text-danger" v-show="errors.has('Offsite Reason')">{{
                errors.first("Offsite Reason")
              }}</span>
          </div>
          <div class="row">
            <div class="col">
              <label>{{ $t("Offsite From") }}<span class="text-danger">*</span></label>
              <datepicker input-class="form-control w-100" v-model="bodyAdd.offsite_from_date" name="Offsite From"
                v-validate="'required'" />
              <span class="text-sm text-danger" v-show="errors.has('Offsite From')">{{
                errors.first("Offsite From")
              }}</span>
            </div>
            <div class="col">
              <label>{{ $t("Offsite To") }}<span class="text-danger">*</span></label>
              <datepicker input-class="form-control w-100" v-model="bodyAdd.offsite_to_date" v-validate="'required'"
                name="Offsite To" />
              <span class="text-sm text-danger" v-show="errors.has('Offsite To')">{{
                errors.first("Offsite To")
              }}</span>
            </div>
          </div>
          <div class="form-group mt-3">
            <label>{{ $t("Overhandle to") }}<span class="text-danger">*</span></label>
            <v-select v-model="bodyAdd.overhandle_user_id" :options="users" label="fullname"
              :reduce="(e) => e.keycloak_user_id" name="Overhandle to" v-validate="'required'" />
            <span class="text-sm text-danger" v-show="errors.has('Overhandle to')">{{
                errors.first("Overhandle to")
              }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Task Note") }}<span class="text-danger">*</span></label>
            <textarea class="form-control" v-model="bodyAdd.overhandle_notes" name="Task Note"
              v-validate="'required'"></textarea>
            <span class="text-sm text-danger" v-show="errors.has('Task Note')">{{
                errors.first("Task Note")
              }}</span>
          </div>
          <button style="margin-left: auto; float: right" class="btn btn-primary" type="submit"
            v-if="isLoading == false">
            {{ $t("Save") }}
          </button>
          <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled v-else>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="form-group">
            <label>{{ $t("Alias") }}</label>
            <v-select v-model="bodyUpdate.company_id" :options="companySelection" label="company_name"
              :reduce="(e) => e.id" name="Alias" v-validate="'required'" />
            <span class="text-sm text-danger" v-show="errors.has('Alias')">{{
                errors.first("Alias")
              }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Company") }}</label>
            <v-select v-model="bodyUpdate.related_company" :options="companySelection" label="company_name"
              :reduce="(e) => e.id" name="Company" multiple v-validate="'required'" />
            <span class="text-sm text-danger" v-show="errors.has('Company')">{{
                errors.first("Company")
              }}</span>
          </div>
          <button style="margin-left: auto; float: right" class="btn btn-primary" type="submit"
            v-if="isLoading == false">
            {{ $t("Save") }}
          </button>
          <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled v-else>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
        </form>
      </b-modal>
      <b-modal id="modal-approver" size="xl" hide-footer :title="$t('Overhandle Approval Form')">
        <div class="row">
          <div class="col-lg-4" v-for="(item, index) in 3" :key="index">
            <div class="card">
              <div class="card-body">

                <!-- Features -->
                <div class="mb-3">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                      <small>Approver {{ index+1 }}</small> <small>ADHITYA DHIVA P.</small>
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                      <small>NIK</small> <small>C-039555</small>
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                      <small>Position</small> <small>HSE</small>
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                      <small>Dept</small> <small>Environment & Rehabilitatio</small>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>

        <button style="margin-left: auto; float: right" class="btn btn-primary" @click="approvee()" v-if="isLoading == false">
          {{ $t("Approve") }}
        </button>
        <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled v-else>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Loading...
        </button>
      </b-modal>
    </div>
  </div>
</template>
<script>
  import moment from 'moment';
  import Datepicker from "vuejs-datepicker";
  export default {
    name: "defaultCMS",
    computed: {
      dataOverhandle() {
        return this.$store.getters["overhandle/overhandleData"] ?
          this.$store.getters["overhandle/overhandleData"] : [];
      },
      users() {
        return this.$store.getters["roleClinicManagement/dataUser"] ?
          this.$store.getters["roleClinicManagement/dataUser"] : [];
      },
    },
    components: {
      Datepicker
    },
    data() {
      return {
        body: {
          page: 1,
          perPage: 10,
          sort: "",
          searchQuery: ""
        },
        bodyAdd: {
          user_id: "",
          offsite_cause: "",
          offsite_from_date: "",
          offsite_to_date: "",
          overhandle_user_id: "",
          overhandle_notes: ""
        },
        bodyUpdate: {
          company_id: "",
          related_company: []
        },
        isAdd: true,
        isLoading: false,
        columns: [{
            label: "PIC",
            field: "user_id",
            thClass: "table-header text-muted font-weight-bold",
            tdClass: "color-secondary align-middle"
          },
          {
            label: "Overhandle PIC",
            field: "overhandle_user_id",
            thClass: "table-header text-muted font-weight-bold",
            tdClass: "color-secondary align-middle"
          },
          {
            label: "From",
            field: "offsite_from_date",
            thClass: "table-header text-muted font-weight-bold",
            tdClass: "color-secondary align-middle"
          },
          {
            label: "To",
            field: "offsite_to_date",
            thClass: "table-header text-muted font-weight-bold",
            tdClass: "color-secondary align-middle"
          },
          {
            label: "ACTION",
            field: "action",
            sortable: false,
            thClass: "table-header  no-wrap text-muted font-weight-bold",
            tdClass: "no-wrap text-center color-secondary align-middle"
          }
        ]
      };
    },
    mounted() {
      this.getOverhandle();
      this.getCompanySelect();
      this.getUsers();
    },
    methods: {
      //action
      updateData(props) {
        this.$bvModal.show("modal-1");
        this.isAdd = false;
        this.bodyUpdate.company_id = props.company_id;
        this.bodyUpdate.related_company = props.related_company_id;
      },

      saveAdd() {
        this.$validator.validateAll().then(async (res) => {
          if (!res) return false;
          try {
            this.bodyAdd.offsite_from_date = moment(this.bodyAdd.offsite_from_date).format("YYYY-MM-DD"),
              this.bodyAdd.offsite_to_date = moment(this.bodyAdd.offsite_to_date).format("YYYY-MM-DD"),
              this.isLoading = true;
            this.$store
              .dispatch("overhandle/addOverhandle", this.bodyAdd)
              .then((resp) => {
                this.$swal({
                  title: this.$t("Saved"),
                  text: this.$t("Your input has been saved"),
                  icon: "success",
                  confirmButtonText: this.$t("OK")
                }).then((resp) => {
                  this.isLoading = false;
                  this.bodyAdd = {
                    offsite_cause: "",
                    offsite_to_date: "",
                    user_id: "",
                    offsite_from_date: "",
                    overhandle_notes: "",
                    overhandle_user_id: ""
                  }
                  this.getOverhandle();
                  this.$bvModal.hide("modal-1");
                });
              })
              .catch((resp) => {
                this.$swal("Error", `${resp.response.data.error}`, "error");
                this.isLoading = false;
              });
          } catch (resp) {
            this.$swal("Error", this.$t("Error while add data"), "error");
          }
        });
      },
      saveUpdate() {
        this.isLoading = true;
        this.$store
          .dispatch("company/addCompany", this.bodyUpdate)
          .then((resp) => {
            this.$swal({
              title: this.$t("Saved"),
              text: this.$t("Your input has been saved"),
              icon: "success",
              confirmButtonText: this.$t("OK")
            }).then((resp) => {
              this.isLoading = false;
              this.getCompany();
              this.$bvModal.hide("modal-1");
            });
          })
          .catch((resp) => {
            this.$swal("Error", this.$t("Error while edit data"), "error");
          });
      },

      deleteData(id) {
        this.$swal({
          title: this.$t("Are you sure?"),
          text: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00D97E",
          cancelButtonColor: "#E63757",
          confirmButtonText: this.$t("Yes, delete it!"),
          cancelButtonText: this.$t("Cancel")
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("company/delCompany", id)
              .then((resp) => {
                this.getCompany();
                this.$swal(
                  "Deleted!",
                  this.$t("Your file has been deleted"),
                  "success"
                );
              })
              .catch((resp) => {
                this.$swal("Error", this.$t("Error while delete data"), "error");
              });
          }
        });
      },

      approveHandle() {
        this.$bvModal.show("modal-approver");
      },

      approvee() {
        this.$swal({
          title: this.$t("Are you sure?"),
          text: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00D97E",
          cancelButtonColor: "#E63757",
          confirmButtonText: this.$t("Yes, Approve it!"),
          cancelButtonText: this.$t("Cancel")
        }).then((result) => {
          if (result.isConfirmed) {
            console.log('i know what you done');
          }
        });
      },

      onSortChange(params) {
        let sort1 = params[0].field;
        let sort2 = params[0].type;
        this.body.sort = `${sort1},${sort2}`;
        this.getCompany();
      },

      // api hit
      getOverhandle() {
        this.$store.dispatch("overhandle/getOverhandle");
      },
      getCompanySelect() {
        this.$store.dispatch("detailManagement/getCompany", {
          all: true
        });
      },
      getUsers() {
        this.$store.dispatch("roleClinicManagement/getUser");
      },

      // filter
      filterPage() {
        this.getCompany();
      },

      sorting() {
        this.getCompany();
      },

      // Pagination
      onPageChange(payload) {
        this.body.limit = payload.currentPerPage;
        this.body.page = payload.currentPage;
        this.getCompany();
        // }
      },

      convertDate(dat) {
        return moment(dat).format('DD MMMM YYYY')
      }
    }
  };
</script>