import queryString from "query-string";

import {
  DELETE_PATIENT,
  DIAGNOSE_PATIENT,
  DIAGNOSE_PATIENT_MCU,
  GET_ALL_DATA,
  GET_DIAGNOSE,
  // GET_DOCTOR,
  GET_EMPLOYEE,
  GET_PATIENT_QR,
  GET_QR,
  GET_RECORD,
  REGISTER_PATIENT,
  REGISTER_PATIENT2,
  IMPORT_REGISTER_PATIENT,
  GET_CATEGORY_MCU,
  ADD_NO_PARTNERSHIP,
  GET_INVENTORY_CLINIC,
  GET_DOSE,
  GET_HOW_TO_USE,
  CHECK_MCU_PATIENT
} from "@/api/clinic/patient";

const state = {
  dataAll: [],
  dataEmployee: [],
  dataQr: [],
  dataPatientQr: [],
  dataDiagnose: [],

  // Medical Record
  dataMedical: [],
  dataPatientMedicalRecord: [],
  dataInventoryCinic: [],

  dataDoctor: [],
  dataCategory: [],
  dataDose: [],
  dataHowtouse: [],

  dataCheckMcuPatient: []
};

const mutations = {
  setAllData: (states, payload) => {
    states.dataAll = payload;
  },
  setEmployee: (states, payload) => {
    states.dataEmployee = payload;
  },
  setQr: (states, payload) => {
    states.dataQr = payload;
  },
  setPatientQr: (states, payload) => {
    states.dataPatientQr = payload;
  },
  setDiagnose: (states, payload) => {
    states.dataDiagnose = payload;
  },

  // Medical Record
  setRecord: (states, payload) => {
    states.dataMedical = payload;
  },
  setRecordPatient: (states, payload) => {
    states.dataPatientMedicalRecord = payload;
  },

  setDoctor: (states, payload) => {
    states.dataDoctor = payload;
  },

  setCategory: (states, payload) => {
    states.dataCategory = payload;
  },

  setInventoryClinic: (states, payload) => {
    states.dataInventoryCinic = payload;
  },

  setDose: (states, payload) => {
    states.dataDose = payload;
  },

  setHowtouse: (states, payload) => {
    states.dataHowtouse = payload;
  },

  setCheckMcuPatient: (states, payload) => {
    states.dataCheckMcuPatient = payload;
  },
};

const actions = {
  importRegisterPatient: async ({ commit }, payload) => {
    try {
      const resp = await IMPORT_REGISTER_PATIENT(payload);

      return true;
    } catch (err) {
      console.log("Error while importing patient");
      return false;
    }
  },
  getAllData: async ({ commit }, args) => {
    const params = queryString.stringify(args);
    return await GET_ALL_DATA(params)
      .then((resp) => {

        if (resp.data.httpCode === 200) {
          commit("setAllData", resp.data.data);
        }
      })
      .catch((err) => {
        let resp = err.response;
        console.log(err);
        if (resp.data.httpCode === 404) {
          commit("setAllData", {
            setAllData: [],
          });
        }
      });
  },

  getDataEmoloyee: async ({ commit }, args) => {
    const params = queryString.stringify(args);
    return await GET_EMPLOYEE(params)
      .then((resp) => {

        if (resp.data.httpCode === 200) {
          commit("setEmployee", resp.data.data);
        }
      })
      .catch((err) => {
        let resp = err.response;
        console.log(err);
        if (resp.data.httpCode === 404) {
          commit("setEmployee", {
            setEmployee: [],
          });
        }
      });
  },

  getQrCode: async ({ commit }, args) => {
    const params = queryString.stringify(args);
    return await GET_QR(params)
      .then((resp) => {

        if (resp.data.httpCode === 200) {
          commit("setQr", resp.data.data);
        }
      })
      .catch((err) => {
        let resp = err.response;
        console.log(err);
        if (resp.data.httpCode === 404) {
          commit("setQr", {
            setQr: [],
          });
        }
      });
  },

  getPatientQr: async ({ commit }, args) => {
    const params = queryString.stringify(args);
    return await GET_PATIENT_QR(params)
      .then((resp) => {

        if (resp.data.httpCode === 200) {
          commit("setPatientQr", resp.data.data);
        }
      })
      .catch((err) => {
        let resp = err.response;
        console.log(err);
        if (resp.data.httpCode === 404) {
          commit("setPatientQr", {
            setPatientQr: [],
          });
        }
      });
  },

  getDiagnose: async ({ commit }, args) => {
    // const params = queryString.stringify(args);
    return await GET_DIAGNOSE(args)
      .then((resp) => {

        if (resp.data.httpCode === 200) {
          commit("setDiagnose", resp.data.data);
        }
      })
      .catch((err) => {
        let resp = err.response;
        console.log(err);
        if (resp.data.httpCode === 404) {
          commit("setDiagnose", {
            setDiagnose: [],
          });
        }
      });
  },

  // getDoctor: async ({ commit }) => {
  //   return await GET_DOCTOR()
  //     .then((resp) => {
  //       if (resp.data.httpCode === 200) {
  //         commit("setDoctor", resp.data.data);
  //       }
  //     })
  //     .catch((err) => {
  //       let resp = err.response;
  //       console.log(err);
  //       if (resp.data.httpCode === 404) {
  //         commit("setDoctor", {
  //           setDoctor: [],
  //         });
  //       }
  //     });
  // },

  getInventoryClinic: async ({
    commit
  }, args) => {
    // const params = queryString.stringify(args);
    return await GET_INVENTORY_CLINIC(args)
      .then((resp) => {

        if (resp.data.httpCode === 200) {
          commit("setInventoryClinic", resp.data.data);
        }
      })
      .catch((err) => {
        let resp = err.response;
        console.log(err);
        if (resp.data.httpCode === 404) {
          commit("setInventoryClinic", {
            setInventoryClinic: [],
          });
        }
      });
  },

  registerPatient: async ({ }, args) => {
    return await REGISTER_PATIENT(args);
  },

  registerPatient2: async ({ }, args) => {
    return await REGISTER_PATIENT2(args);
  },

  diagnosePatient: async ({ }, args) => {
    return await DIAGNOSE_PATIENT(args);
  },

  diagnosePatientMcu: async ({ }, args) => {
    return await DIAGNOSE_PATIENT_MCU(args);
  },

  deletePatient: async ({ }, id) => {
    return await DELETE_PATIENT(id);
  },

  addNoPartnership: async ({ }, args) => {
    return await ADD_NO_PARTNERSHIP(args);
  },

  // Medical Record
  getRecord: async ({ commit }, args) => {
    const params = queryString.stringify(args);
    return await GET_RECORD(params)
      .then((resp) => {

        if (resp.data.httpCode === 200) {
          commit("setRecord", resp.data.data);
        }
      })
      .catch((err) => {
        let resp = err.response;
        console.log(err);
        if (resp.data.httpCode === 404) {
          commit("setRecord", {
            setRecord: [],
          });
        }
      });
  },

  getCategoryMcu: async ({ commit },) => {
    // const params = queryString.stringify(args);
    return await GET_CATEGORY_MCU()
      .then((resp) => {

        if (resp.data.httpCode === 200) {
          commit("setCategory", resp.data.data);
        }
      })
      .catch((err) => {
        let resp = err.response;
        console.log(err);
        if (resp.data.httpCode === 404) {
          commit("setCategory", {
            setCategory: [],
          });
        }
      });
  },

  getDose: async ({ commit },) => {
    return await GET_DOSE()
      .then((resp) => {

        if (resp.data.httpCode === 200) {
          commit("setDose", resp.data.data);
        }
      })
      .catch((err) => {
        let resp = err.response;
        console.log(err);
        if (resp.data.httpCode === 404) {
          commit("setDose", {
            setDose: [],
          });
        }
      });
  },

  getHowtouse: async ({ commit },) => {
    return await GET_HOW_TO_USE()
      .then((resp) => {
        if (resp.data.httpCode === 200) {
          commit("setHowtouse", resp.data.data);
        }
      })
      .catch((err) => {
        let resp = err.response;
        console.log(err);
        if (resp.data.httpCode === 404) {
          commit("setHowtouse", {
            setHowtouse: [],
          });
        }
      });
  },

  getCheckMcuPatient: async ({ commit }, args) => {
    return await CHECK_MCU_PATIENT(args)
      .then((resp) => {
        if (resp.data.httpCode === 200) {
          commit("setCheckMcuPatient", resp.data);
        }
      })
      .catch((err) => {
        let resp = err.response;
        console.log(err);
        if (resp.data.httpCode === 404) {
          commit("setCheckMcuPatient", {
            setCheckMcuPatient: [],
          });
        }
      });
  },
};

const getters = {
  qrData: (states) => states.dataQr,
  patientQrData: (states) => states.dataPatientQr,
  allData: (states) => states.dataAll,
  employeeData: (states) => states.dataEmployee,
  diagnoseData: (states) => states.dataDiagnose,
  dataInventoryClinic: (states) => states.dataInventoryCinic,

  // Medical Record'
  medicalRecord: (states) => states.dataMedical,
  dataPatientRecord: (states) => states.dataPatientMedicalRecord,

  doctorData: (states) => states.dataDoctor,
  categoryData: (states) => states.dataCategory,
  doseData: (states) => states.dataDose,
  howtouseData: (states) => states.dataHowtouse,

  checkMcuPatient: (states) => states.dataCheckMcuPatient,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
