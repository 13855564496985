import queryString from "query-string";

import {
    GET_QUESTION_DATA,
    ADD_QUESTION_TYPE,
    DEL_QUESTION_TYPE,
    UPDATE_QUESTION_TYPE
} from "@/api/mcu/question/questionType";

const state = {
    dataQuestionType: [],
};

const mutations = {
    setQuestionType: (states, payload) => {
        states.dataQuestionType = payload;
    },
};

const actions = {
    getQuestionType: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_QUESTION_DATA(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    commit("setQuestionType", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setQuestionType", {
                        setCategory: [],
                    });
                }
            });
    },

    addQuestionType: async ({ }, args) => {
        return await ADD_QUESTION_TYPE(args);
    },

    delQuestionType: async ({ }, id) => {
        return await DEL_QUESTION_TYPE(id);
    },

    updateQuestionType: async ({ }, args) => {
        return await UPDATE_QUESTION_TYPE(args);
    },



};

const getters = {
    QuestionDataType: (states) => states.dataQuestionType
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};