import Vue from "vue";
import axios from "axios";

const urlApi = [
	'https://api.ugems.id/hms/api',
	'https://api.ugems.id/uhealth-dev/api',
	'https://kong-api.ugems.id/uhealth-prod',
	'https://kong-api.ugems.id/uhealth-dev',
	'https://kong-api.ugems.id/uhealth-dev/uhealth-dev/api'
];

const axiosIns = axios.create({
	// ================================
	// You can add your headers here
	// ================================

	baseURL: process.env.VUE_APP_API_URL,
	// timeout: 1000,
	headers: {
		Authorization: `Bearer ${localStorage.getItem("ugems-accessToken")}`,
		"ngrok-skip-browser-warning": !urlApi.includes(process.env.VUE_APP_API_URL),
		"Content-Type": "application/json",
	},
});

axiosIns.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error instanceof Error) {
			return Promise.reject(error);
		} else {
			return Promise.reject(new Error(error));
		}
	}
);

Vue.prototype.$http = axiosIns;

export default axiosIns;