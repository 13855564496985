import axiosIns from '@/api/axios'

export const GET_ACTION_DATA = (args) => {
    return axiosIns.get(`/ih/action?${args}`)
}

export const ADD_ACTION = (args) => {
    return axiosIns.post(`/ih/action/create`, args)
}

export const DEL_ACTION = (id) => {
    return axiosIns.put(`/ih/action/delete?id=${id}`)
}

export const UPDATE_ACTION  = (args) => {
    return axiosIns.put(`/ih/action/update?id=${args.id}`, args)
}


// IH MANAGEMENT MEASURING
export const ADD_MEASURING = (args) => {
    return axiosIns.post(`/ih/management/measurement`, args)
}

export const GET_MEASURING = (args) => {
    return axiosIns.get(`/ih/management/measurement?${args}`)
}

export const UPDATE_MEASUREMENT = (args) => {
    return axiosIns.put(`/ih/management/measurement`, args)
}

export const DEL_MEASURING = (id) => {
    return axiosIns.delete  (`/ih/management/measurement?id=${id}`)
}

export const ADD_NEW_PROGRESS = (args) => {
    return axiosIns.post(`/ih/management/progress`, args, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

export const ADD_NEW_RESCHEDULE = (args) => {
    return axiosIns.post(`/ih/management/reschedule`, args, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

export const APPROVE_PROGRESS = (args) => {
    return axiosIns.post(`/ih/management/progress/approval`, args)
}

export const APPROVE_RESCHEDULE = (args) => {
    return axiosIns.post(`/ih/management/reschedule/approval`, args)
}