<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <!-- / .row -->

      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("Company Mapping") }}</h4>
          <!-- Button -->
          <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
            <span class="fe fe-file-plus"></span>
            {{ $t("Mapping") }}
          </b-button>
        </div>

        <div class="cart">
          <div class="card-body">
            <div class="d-flex justify-content-end mb-3">
              <!-- <b-button variant="primary" v-b-modal.modal-1>Export</b-button> -->
              <div class="d-flex">
                <label class="align-self-center mb-0 mr-3">Search</label>
                <b-form-input
                  class="w-auto"
                  v-model="body.searchQuery"
                  placeholder="Type Here..."
                  @change="getCompany()"></b-form-input>
              </div>
            </div>
            <vue-good-table
              mode="remote"
              :line-numbers="true"
              :columns="columns"
              :rows="companyDatas.rows"
              :total-rows="companyDatas.totalRecords"
              :sort-options="{
                enabled: true
              }"
              :pagination-options="{
                enabled: true,
                perPage: body.perPage
              }"
              style-class="vgt-table table table-bordered"
              @on-sort-change="onSortChange"
              :isLoading.sync="isLoading"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange">
              <!-- Loading -->
              <div slot="emptystate" class="text-center font-size-14">
                No data available
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <b-button
                    class="no-wrap btn-pill"
                    variant="warning"
                    size="sm"
                    @click="updateData(props.row)">
                    <span class="fe fe-edit"></span>
                  </b-button>
                  <b-button
                    class="no-wrap ml-2"
                    variant="danger"
                    style="margin-left: 5px"
                    size="sm"
                    @click="deleteData(props.row.company_id)">
                    <span class="fe fe-trash-2"></span>
                  </b-button>
                </span>
                <!-- <span v-else-if="props.column.field == 'is_active'">
                                    <span v-if="props.row.is_active === true" class="badge bg-success-soft">ACTIVE</span>
                                    <span v-if="props.row.is_active === false" class="badge bg-danger-soft">INACTIVE</span>
                                </span> -->
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-end">
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="companyDatas.totalRecords"
                      :per-page="body.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="m-3"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      ">
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
          <!-- Link -->
        </div>
      </div>

      <!-- Modal -->
      <b-modal
        id="modal-1"
        centered
        hide-footer
        :title="$t('Company Mapping Form')">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="form-group">
            <label>{{ $t("Alias") }}</label>
            <v-select
              v-model="bodyAdd.company_id"
              :options="companySelection"
              label="company_name"
              :reduce="(e) => e.id"
              name="Alias"
              v-validate="'required'" />
            <span class="text-sm text-danger" v-show="errors.has('Alias')">{{
              errors.first("Alias")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Company") }}</label>
            <v-select
              v-model="bodyAdd.related_company"
              :options="companySelection"
              label="company_name"
              :reduce="(e) => e.id"
              name="Company"
              multiple
              v-validate="'required'" />
            <span class="text-sm text-danger" v-show="errors.has('Company')">{{
              errors.first("Company")
            }}</span>
          </div>
          <button
            style="margin-left: auto; float: right"
            class="btn btn-primary"
            type="submit"
            v-if="isLoading == false">
            {{ $t("Save") }}
          </button>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="button"
            disabled
            v-else>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
            Loading...
          </button>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="form-group">
            <label>{{ $t("Alias") }}</label>
            <v-select
              v-model="bodyUpdate.company_id"
              :options="companySelection"
              label="company_name"
              :reduce="(e) => e.id"
              name="Alias"
              v-validate="'required'" />
            <span class="text-sm text-danger" v-show="errors.has('Alias')">{{
              errors.first("Alias")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Company") }}</label>
            <v-select
              v-model="bodyUpdate.related_company"
              :options="companySelection"
              label="company_name"
              :reduce="(e) => e.id"
              name="Company"
              multiple
              v-validate="'required'" />
            <span class="text-sm text-danger" v-show="errors.has('Company')">{{
              errors.first("Company")
            }}</span>
          </div>
          <button
            style="margin-left: auto; float: right"
            class="btn btn-primary"
            type="submit"
            v-if="isLoading == false">
            {{ $t("Save") }}
          </button>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="button"
            disabled
            v-else>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
            Loading...
          </button>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "defaultCMS",
  computed: {
    companyDatas() {
      return this.$store.getters["company/CompanyData"]
        ? this.$store.getters["company/CompanyData"]
        : [];
    },
    companySelection() {
      return this.$store.getters["detailManagement/dataCompany"]
        ? this.$store.getters["detailManagement/dataCompany"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        perPage: 10,
        sort: "",
        searchQuery: ""
      },
      bodyAdd: {
        company_id: "",
        related_company: []
      },
      bodyUpdate: {
        company_id: "",
        related_company: []
      },
      isAdd: true,
      isLoading: false,
      columns: [
        {
          label: "COMPANY NAME",
          field: "company_alias",
          thClass: "table-header w-50 text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "ALIAS",
          field: "company_name",
          thClass: "table-header w-50 text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          sortable: false,
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap text-center color-secondary align-middle"
        }
      ]
    };
  },
  mounted() {
    this.getCompany();
    this.getCompanySelect();
  },
  methods: {
    //action
    updateData(props) {
      this.$bvModal.show("modal-1");
      this.isAdd = false;
      this.bodyUpdate.company_id = props.company_id;
      this.bodyUpdate.related_company = props.related_company_id;
    },

    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.isLoading = true;
          this.$store
            .dispatch("company/addCompany", this.bodyAdd)
            .then((resp) => {
              this.$swal({
                title: this.$t("Saved"),
                text: this.$t("Your input has been saved"),
                icon: "success",
                confirmButtonText: this.$t("OK")
              }).then((resp) => {
                this.isLoading = false;
                this.bodyAdd.company_id = "";
                this.bodyAdd.related_company = [];
                this.getCompany();
                this.$bvModal.hide("modal-1");
              });
            })
            .catch((resp) => {
              this.$swal("Error", `${resp.response.data.error}`, "error");
              this.isLoading = false;
            });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
        }
      });
    },
    saveUpdate() {
      this.isLoading = true;
      this.$store
        .dispatch("company/addCompany", this.bodyUpdate)
        .then((resp) => {
          this.$swal({
            title: this.$t("Saved"),
            text: this.$t("Your input has been saved"),
            icon: "success",
            confirmButtonText: this.$t("OK")
          }).then((resp) => {
            this.isLoading = false;
            this.getCompany();
            this.$bvModal.hide("modal-1");
          });
        })
        .catch((resp) => {
          this.$swal("Error", this.$t("Error while edit data"), "error");
        });
    },

    deleteData(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("company/delCompany", id)
            .then((resp) => {
              this.getCompany();
              this.$swal(
                "Deleted!",
                this.$t("Your file has been deleted"),
                "success"
              );
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    },

    onSortChange(params) {
      let sort1 = params[0].field;
      let sort2 = params[0].type;
      this.body.sort = `${sort1},${sort2}`;
      this.getCompany();
    },

    // api hit
    getCompany() {
      this.$store.dispatch("company/getCompany", this.body);
    },
    getCompanySelect() {
      this.$store.dispatch("detailManagement/getCompany", {
        all: true
      });
    },

    // filter
    filterPage() {
      this.getCompany();
    },

    sorting() {
      this.getCompany();
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getCompany();
      // }
    }
  }
};
</script>
