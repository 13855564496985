<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <!-- / .row -->

      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">Group</h4>
          <!-- Button -->
          <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
            <span class="fe fe-file-plus"></span>
            {{ $t("Add") }}
          </b-button>
          <b-button
            v-b-toggle.collapse-1
            variant="primary"
            style="margin-left: 7px">
            <span class="fe fe-sliders"></span>
          </b-button>
          <!-- <b-button v-b-toggle.collapse-1 variant="primary" style="margin-left : 7px">
            <span class="fe fe-sliders"></span>
          </b-button> -->
        </div>
        <b-collapse id="collapse-1">
          <div class="mt-3 mb-5" style="margin-left: 10px">
            <p class="card-text">{{ $t("Filter") }}</p>
            <v-select
              v-model="body.order"
              :options="['ASC', 'DESC']"
              @input="sorting" />
          </div>
        </b-collapse>
        <vue-good-table
          :line-numbers="true"
          :columns="columns"
          :rows="group.data"
          :sort-options="{
            enabled: true
          }"
          :total-rows="group.totalItems"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }"
          style-class="vgt-table border-0 table"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange">
          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <!-- Custom Rows -->
          <template slot="table-column" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.label == 'ACTION'">
              {{ $t("Action") }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.field == 'action'">
              <b-button
                class="no-wrap btn-pill"
                variant="warning"
                size="sm"
                @click="updateData(props.row)">
                <span class="fe fe-edit"></span>
              </b-button>
              <b-button
                class="no-wrap ml-2"
                variant="danger"
                style="margin-left: 5px"
                size="sm"
                @click="deleteData(props.row.id)">
                <span class="fe fe-trash-2"></span>
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </div>

      <!-- Modal -->
      <b-modal id="modal-1" hide-footer title="Group Form">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="form-group">
            <label for="exampleInputEmail1" class="form-label">{{
              $t("Category")
            }}</label>
            <v-select
              v-model="bodyAdd.category_id"
              :options="category.data"
              label="category_name"
              :reduce="(e) => e.id"
              name="Category"
              v-validate="'required'" />
            <span class="text-sm text-danger" v-show="errors.has('Category')">{{
              errors.first("Category")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Group Name") }}</label>
            <input
              type="text"
              v-model="bodyAdd.group_name"
              class="form-control"
              :placeholder="$t('Group Name')"
              v-validate="'required'"
              name="Group Name" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Group Name')"
              >{{ errors.first("Group Name") }}</span
            >
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="form-group">
            <label for="exampleInputEmail1" class="form-label">{{
              $t("Category")
            }}</label>
            <v-select
              v-model="bodyUpdate.category_id"
              :options="category.data"
              label="category_name"
              :reduce="(e) => e.id"
              name="Category"
              v-validate="'required'" />
            <span class="text-sm text-danger" v-show="errors.has('Category')">{{
              errors.first("Category")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Group Name") }}</label>
            <input
              type="text"
              v-model="bodyUpdate.group_name"
              class="form-control"
              :placeholder="$t('Group Name')" />
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "defaultCMS",
  computed: {
    group() {
      return this.$store.getters["groupMcu/GroupData"]
        ? this.$store.getters["groupMcu/GroupData"]
        : [];
    },
    category() {
      return this.$store.getters["categoryMcuTest/CategoryData"]
        ? this.$store.getters["categoryMcuTest/CategoryData"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        limit: 10,
        order: ""
      },
      bodyAdd: {
        category_id: "",
        group_name: ""
      },
      bodyUpdate: {
        id: "",
        category_id: "",
        group_name: ""
      },
      // group_name: "",
      // group_names: "",
      // id: "",
      isAdd: true,
      isLoading: false,
      columns: [
        {
          label: "CATEGORY",
          field: "category_name",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "GROUP",
          field: "group_name",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ]
    };
  },
  mounted() {
    // if (this.group && this.group.length < 1) {
    this.getGroup();
    this.$store.dispatch("categoryMcuTest/getCategory", this.body);
    // }
  },
  methods: {
    //action
    updateData(props) {
      this.$bvModal.show("modal-1");
      this.isAdd = false;
      this.bodyUpdate.id = props.id;
      this.bodyUpdate.category_id = props.category_id;
      this.bodyUpdate.group_name = props.group_name;
    },
    customStore() {
      this.$store.dispatch("groupMcu/getGroup", this.group);
    },
    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.isLoading = true;
          this.$store
            .dispatch("groupMcu/addGroup", this.bodyAdd)
            .then((resp) => {
              this.$swal({
                title: this.$t("Saved"),
                text: this.$t("Your input has been saved"),
                icon: "success",
                confirmButtonText: this.$t("OK")
              }).then((resp) => {
                this.isLoading = false;
                this.group_name = "";
                this.getGroup();
                this.$bvModal.hide("modal-1");
              });
            });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
        }
      });
    },

    saveUpdate() {
      this.isLoading = true;
      this.$store
        .dispatch("groupMcu/updateGroup", this.bodyUpdate)
        .then((resp) => {
          this.$swal({
            title: this.$t("Saved"),
            text: this.$t("Your input has been saved"),
            icon: "success",
            confirmButtonText: this.$t("OK")
          }).then((resp) => {
            this.isLoading = false;
            this.getGroup();
            this.$bvModal.hide("modal-1");
          });
        })
        .catch((resp) => {
          this.$swal("Error", this.$t("Error while edit data"), "error");
        });
    },

    deleteData(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then((group) => {
        if (group.isConfirmed) {
          this.$store
            .dispatch("groupMcu/delGroup", id)
            .then((resp) => {
              this.getGroup();
              this.$swal(
                "Deleted!",
                this.$t("Your file has been deleted"),
                "success"
              );
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    },

    // api hit
    getGroup() {
      this.$store.dispatch("groupMcu/getGroup", this.body);
      // this.customStore();
    },

    // filter
    filterPage() {
      this.getGroup();
    },

    sorting() {
      this.getGroup();
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;
      // if (
      //   parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
      //   1000
      // ) {
      this.getGroup();
      // }
    }
  }
};
</script>
