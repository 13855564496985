import queryString from "query-string";

import {
    GET_MEASURING_UNIT_DATA,
    ADD_MEASURING_UNIT,
    DEL_MEASURING_UNIT,
    UPDATE_MEASURING_UNIT
} from "@/api/ih/measuring/unit";

const state = {
    dataMeasuringUnit: [],
};

const mutations = {
    setMeasuringUnit: (states, payload) => {
        states.dataMeasuringUnit = payload;
    },
};

const actions = {
    getMeasuringUnit: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_MEASURING_UNIT_DATA(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setMeasuringUnit", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setMeasuringUnit", {
                        setMeasuringUnit: [],
                    });
                }
            });
    },

    addMeasuringUnit: async ({ }, args) => {
        return await ADD_MEASURING_UNIT(args);
    },

    delMeasuringUnit: async ({ }, id) => {
        return await DEL_MEASURING_UNIT(id);
    },

    updateMeasuringUnit: async ({ }, args) => {
        return await UPDATE_MEASURING_UNIT(args);
    },



};

const getters = {
    MeasuringUnitData: (states) => states.dataMeasuringUnit
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};