import queryString from "query-string";

import {
    GET_CAMPAIGN,
    ADD_CAMPAIGN,
    UPDATE_CAMPAIGN,
    DELETE_CAMPAIGN
} from "@/api/ih/measuring/campaign";

const state = {
    datacampaign: [],
};

const mutations = {
    setcampaign: (states, payload) => {
        states.datacampaign = payload;
    },
};

const actions = {
    getCampaign: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_CAMPAIGN(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setcampaign", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setcampaign", {
                        setcampaign: [],
                    });
                }
            });
    },

    addCampaign: async ({ }, args) => {
        return await ADD_CAMPAIGN(args);
    },

    delCampaign: async ({ }, id) => {
        return await DELETE_CAMPAIGN(id);
    },

    updateCampaign: async ({ }, args) => {
        return await UPDATE_CAMPAIGN(args);
    },



};

const getters = {
    campaignData: (states) => states.datacampaign
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};