import axiosIns from '@/api/axios'

export const ADD_DOC_ADD = (args) => {
    return axiosIns.post(`oh/management/doc/create`, args, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

export const DOC_UPDATE = (args) => {
    return axiosIns.put(`/oh/management/doc/update`, args, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

export const DOC_DELETE = (id) => {
    return axiosIns.put(`/oh/management/doc/delete?id=${id}`)
}

export const GET_ACTUAL = (args) => {
    return axiosIns.get(`/oh/management/doc/actual?${args}`)
}

export const GET_PLAN = (args) => {
    return axiosIns.get(`/oh/management/doc/plan?${args}`)
}

export const ADD_PLAN = (args) => {
    return axiosIns.post(`/oh/management/doc/plan`, args)
}

export const UPDATE_PLAN = (args) => {
    return axiosIns.put(`/oh/management/doc/plan`, args)
}

export const DELETE_PLAN = (id) => {
    return axiosIns.delete(`/oh/management/doc/plan?id=${id}`)
}