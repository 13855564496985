import axiosIns from "@/api/axios";

export const GET_ALL_DATA = (args) => {
    console.log(args)
    return axiosIns.get(`/mcu-notification?${args}`);
};

export const ADD_DATA = (args) => {
    return axiosIns.post(`/mcu-notification`, args);
};

export const UPDATE_DATA = (args) => {
    return axiosIns.put(`/mcu-notification/${args.id}`, args.data);
};

export const DELETE_DATA = (id) => {
    return axiosIns.delete(`/mcu-notification/${id}`);
};
