import axiosIns from '@/api/axios'

export const GET_DOCUMENT = (args) => {
    return axiosIns.get(`/oh/doc?${args}`)
}

export const ADD_DOCUMENT = (args) => {
    return axiosIns.post(`/oh/doc/create`, args)
}

export const DEL_DOCUMENT = (id) => {
    return axiosIns.put(`/oh/doc/delete?id=${id}`)
}

export const UPDATE_DOCUMENT  = (args) => {
    return axiosIns.put(`/oh/doc/update?id=${args.id}`, args)
}