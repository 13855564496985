<template>
  <div class="header">
    <div class="container-fluid">
      <div class="mt-5">
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">Notification Settings</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Card  -->
      <div class="card">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">Notification Settings</h4>
          <!-- Button -->
          <div class="card-header-title form-group col-3">
            <input
              type="text"
              v-model="bodyEmail.search"
              class="form-control"
              placeholder="Search by subject"
             @change="getList()"
            />
          </div>
          <b-button variant="primary" @click="addModalEmail()">
            <span class="fe fe-plus-circle"></span>
            Add
          </b-button>
        </div>
        <div>
          <div class="card-body">
            <vue-good-table
            :line-numbers="true"
            :columns="columnsEmail"
            :rows="list.data"
            :sort-options="{
              enabled: true,
            }"
            :total-rows="list.totalItems"
            :pagination-options="{
              enabled: true,
              mode: 'pages',
              position: 'bottom',
              perPage: 10,
              perPageDropdown: [10, 50, 100],
              dropdownAllowAll: false,
              setCurrentPage: 1,
              nextLabel: 'next',
              prevLabel: 'prev',
              rowsPerPageLabel: 'Rows per page',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }"
            style-class="vgt-table border-0 table"
            @on-sort-change="onSortChange"
            @on-page-change="onPageChangeEmail"
            @on-per-page-change="onPageChangeEmail"
          >
            <!-- Loading -->
            <div slot="emptystate" class="text-center font-size-14">
              No data available
            </div>
            <!-- Custom Rows -->
            <template slot="table-row" slot-scope="props">
              <!-- Table Action -->
              <span v-if="props.column.field == 'action'">
                <b-button
                class="no-wrap ml-2"
                  variant="info"
                  style="margin-left: 5px"
                  size="sm"
                  @click="seeInfo(props.row)">
                <span class="fe fe-info"></span>
                </b-button>
                <b-button
                  class="no-wrap ml-2"
                  variant="warning"
                  style="margin-left: 5px"
                  size="sm"
                  @click="updateEmail(props.row)"
                >
                  <span class="fe fe-edit"></span>
                </b-button>
                <b-button
                  class="no-wrap ml-2"
                  variant="danger"
                  style="margin-left: 5px"
                  size="sm"
                  @click="deleteEmail(props.row.id)"
                >
                  <span class="fe fe-trash-2"></span>
                </b-button>
              </span>
              <span v-else-if="props.column.field == 'template'">
                <div v-html="props.row.template"></div>
              </span>
              <span v-else-if="props.column.field == 'scheduler_time'">
                {{ props.row.scheduler_time }} Days
              </span>
            </template>
          </vue-good-table>
          </div>
          
        </div>
      </div>
      <b-modal
        id="modal-info"
        size="xl"
        hide-footer
      >
      <div class="row g-4">
            <div class="form-group col-4">
              <label
                >MCU Category</label>
               <v-select
                  v-model="modalUpdateEmail.mcu_category_id"
                  :reduce="(option) => option.id"
                  :options="optionMcuCategory"
                  label="name"
                  v-validate="'required'"
                  disabled/>
              <span
                class="text-sm text-danger"
                v-show="errors.has('MCU Category ')"
                >{{ errors.first("MCU Category ") }}</span
              >
            </div>
            <div class="form-group col-6">
              <label class="ml-3">Notification Schedule </label>
              <div class="input-group col-5">
                <div class="input-group-append">
                  <b-button
                    size="sm"
                    variant="primary"
                    @click="modalUpdateEmail.scheduler_time--"
                    disabled
                  >
                    <span class="fe fe-minus"></span>
                  </b-button>
                </div>
                <input
                  type="number"
                  class="form-control"
                  v-model="modalUpdateEmail.scheduler_time"
                   v-validate="'required'"
                   disabled
                />
                <div class="input-group-append">
                  <b-button
                    size="sm"
                    variant="primary"
                    @click="modalUpdateEmail.scheduler_time++"
                    disabled
                  >
                    <span class="fe fe-plus"></span>
                  </b-button>
                </div>
                <div class="input-group-append">
                  <div class="input-group-text"><b>Days</b></div>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-3">
           
          </div>


          <div class="form-group mt-5">
            <label>Template</label>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-12 col-lg-5 col-sm">
                <div class="card-body" style="background-color: #a1a1a180">
                  <div
                    class="card-body"
                    style="background-color: white; border-radius: 10px"
                  >
                    <div
                      class="row align-items-center gx-0"
                      style="color: #a1a1a1"
                    >
                      <div class="col">
                        <h4>Email Variable:</h4>
                        <div class="col">
                          <span class="ml-2"
                            >- [date] : Next Visit MCU</span
                          >
                        </div>
                        <!-- <div class="col mt-2">
                          <span class="ml-2"
                            >- [category_type] : Category MCU</span
                          >
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 col-xl">
                <div class="form-group">
                  <label
                    >Email Subject</label
                  >
                  <input
                    type="text"
                    v-model="modalUpdateEmail.subject"
                    class="form-control"
                    placeholder="Email Subject"
                    v-validate="'required'"
                    name="Email Subject"
                    disabled
                  />
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Email Subject')"
                    >{{ errors.first("Email Subject") }}</span
                  >
                </div>
                <div class="form-group">
                  <label>Email Body</label>
                  <vue-editor v-model="modalUpdateEmail.template" disabled/>
                </div>
              </div>
            </div>
          </div>
          <button
              type="button"
              style="margin-right: auto; float: right"
              class="mt-4 btn btn-primary mr-3"
              @click="$bvModal.hide('modal-info')"
            >
              Cancel
            </button>
        
      </b-modal>
      <!-- Modal -->
      <b-modal
        id="modal-1"
        size="xl"
        hide-footer
        title="Notification Setting Form"
      >
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
         <div class="row g-4">
            <div class="form-group col-4">
              <label
                >MCU Category <span class="text-danger">*</span
              ></label>
               <v-select
                  v-model="modalEmail.mcu_category_id"
                  :reduce="(option) => option.id"
                  :options="optionMcuCategory"
                  label="name"
                  v-validate="'required'"/>
              <!-- <input
                type="number"
                v-model="modalEmail.budget_notification"
                class="form-control"
                placeholder="Notification Budget"
                v-validate="'required'"
                name="Notification Budget"
              /> -->
              <span
                class="text-sm text-danger"
                v-show="errors.has('MCU Category ')"
                >{{ errors.first("MCU Category ") }}</span
              >
            </div>
            <div class="form-group col-6">
              <label class="ml-3">Notification Schedule </label>
              <span class="text-danger">*</span>
              <div class="input-group col-5">
                <div class="input-group-append">
                  <b-button
                    size="sm"
                    variant="primary"
                    @click="modalEmail.scheduler_time--"
                  >
                    <span class="fe fe-minus"></span>
                  </b-button>
                </div>
                <input
                  type="number"
                  class="form-control"
                  v-model="modalEmail.scheduler_time"
                   v-validate="'required'"
                />
                <div class="input-group-append">
                  <b-button
                    size="sm"
                    variant="primary"
                    @click="modalEmail.scheduler_time++"
                  >
                    <span class="fe fe-plus"></span>
                  </b-button>
                </div>
                <div class="input-group-append">
                  <div class="input-group-text"><b>Days</b></div>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-3">
          </div>


          <!-- </div> -->
          <div class="form-group mt-5">
            <label>Template </label>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-12 col-lg-5 col-sm">
                <div class="card-body" style="background-color: #a1a1a180">
                  <div
                    class="card-body"
                    style="background-color: white; border-radius: 10px"
                  >
                    <div
                      class="row align-items-center gx-0"
                      style="color: #a1a1a1"
                    >
                      <div class="col">
                        <h4>Email Variable:</h4>
                        <div class="col">
                          <span class="ml-2"
                            >- [date] : Next Visit MCU</span
                          >
                        </div>
                        <!-- <div class="col mt-2">
                          <span class="ml-2"
                            >- [category_type] : Category MCU</span
                          >
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 col-xl">
                <div class="form-group">
                  <label
                    >Email Subject <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    v-model="modalEmail.subject"
                    class="form-control"
                    placeholder="Email Subject"
                    v-validate="'required'"
                    name="Email Subject"
                  />
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Email Subject')"
                    >{{ errors.first("Email Subject") }}</span
                  >
                </div>
                <div class="form-group">
                  <label>Email Body <span class="text-danger">*</span></label>
                  <vue-editor v-model="modalEmail.template" />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false"
            >
              Save
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
            <button
              type="button"
              style="margin-right: auto; float: right"
              class="mt-4 btn btn-outline-primary mr-3"
              @click="$bvModal.hide('modal-1')"
            >
              Cancel
            </button>
          </div>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="row g-4">
            <div class="form-group col-4">
              <label
                >MCU Category <span class="text-danger">*</span
              ></label>
               <v-select
                  v-model="modalUpdateEmail.mcu_category_id"
                  :reduce="(option) => option.id"
                  :options="optionMcuCategory"
                  label="name"
                  v-validate="'required'"/>
             
              <span
                class="text-sm text-danger"
                v-show="errors.has('MCU Category ')"
                >{{ errors.first("MCU Category ") }}</span
              >
            </div>
            <div class="form-group col-6">
              <label class="ml-3">Notification Schedule </label>
              <span class="text-danger">*</span>
              <div class="input-group col-5">
                <div class="input-group-append">
                  <b-button
                    size="sm"
                    variant="primary"
                    @click="modalUpdateEmail.scheduler_time--"
                  >
                    <span class="fe fe-minus"></span>
                  </b-button>
                </div>
                <input
                  type="number"
                  class="form-control"
                  v-model="modalUpdateEmail.scheduler_time"
                   v-validate="'required'"
                />
                <div class="input-group-append">
                  <b-button
                    size="sm"
                    variant="primary"
                    @click="modalUpdateEmail.scheduler_time++"
                  >
                    <span class="fe fe-plus"></span>
                  </b-button>
                </div>
                <div class="input-group-append">
                  <div class="input-group-text"><b>Days</b></div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row g-3">
          </div> -->
          <div class="form-group mt-5">
            <label>Template </label>
          </div>
          <div class="from-group">
            <div class="row">
              <div class="col-12 col-lg-4 col-sm">
                <div class="card-body" style="background-color: #a1a1a180">
                  <div
                    class="card-body"
                    style="background-color: white; border-radius: 10px"
                  >
                    <div
                      class="row align-items-center gx-0"
                      style="color: #a1a1a1"
                    >
                      <div class="col">
                        <h4>Email Variable:</h4>
                        <div class="col">
                          <span class="ml-2"
                            >- [date] : Next Visit MCU</span
                          >
                        </div>
                        <!-- <div class="col mt-2">
                          <span class="ml-2"
                            >- [category_type] : Category MCU</span
                          >
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 col-xl">
                <div class="form-group">
                  <label
                    >Email Subject <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    v-model="modalUpdateEmail.subject"
                    class="form-control"
                    placeholder="Email Subject"
                    v-validate="'required'"
                    name="Email Subject"
                  />
                  <span
                    class="text-sm text-danger"
                    v-show="errors.has('Email Subject')"
                    >{{ errors.first("Email Subject") }}</span
                  >
                </div>
                <div class="form-group">
                  <label>Email Body <span class="text-danger">*</span></label>
                  <vue-editor v-model="modalUpdateEmail.template" />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false"
            >
              Save
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
            <button
              style="margin-right: auto; float: right"
              class="mt-4 btn btn-outline-primary mr-3"
              type="button"
              @click="$bvModal.hide('modal-1')"
            >
              Cancel
            </button>
          </div>
        </form>
      </b-modal>
      
    </div>
  </div>
</template>

<script>
export default {
  name: "listContract",
  computed: {
    list() {
      return this.$store.getters["notification/list"];
    },
     categoryMCU() {
      return this.$store.getters["categoryMcu/CategoryData"]
        ? this.$store.getters["categoryMcu/CategoryData"]
        : [];
    },
  },
  data() {
    return {
      bodyEmail: {
        page: 1,
        pageSize: 10,
        order: "",
        search: "",
      },
      bodyWhatsApp: {
        pageSize: 1,
        limit: 10,
        order: "",
      },
      direct: "Email",
      breadcrumb: "Email",
      isAdd: true,
      isAddEmail: true,
      isLoading: false,
      id: "",
      modalEmail: {
        mcu_category_id:"Annual Medical checkup",
        notification_type: "Regular",
        notification_time: 0,
        scheduler_time: "",
        subject: "",
        template: "",
      },
      modalUpdateEmail: {
        mcu_category_id:[
        {
         name: "Annual Medical Checkup",
          id : 5
        }
      ],
        notification_type: "Regular",
        notification_time: 0,
        scheduler_time: "",
        subject: "",
        template: "",
      },
      optionNotifType: [
        "Regular",
      ],
      optionMcuCategory:[
        {
         name: "Annual Medical Checkup",
          id : 5
        }
      ],
      columnsEmail: [
        {
          label: "Notification Type",
          field: "notification_type",
          thClass: "table-header no-wrap w-40 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "Schedule",
          field: "scheduler_time",
          thClass: "table-header no-wrap w-40 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "MCU Category",
          field: "mcu_category_name",
          thClass: "table-header no-wrap w-40 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        {
          label: "Email Subject",
          field: "subject",
          thClass: "table-header no-wrap w-40 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
        },
        // {
        //   label: "Email Body",
        //   field: "template",
        //   thClass: "table-header no-wrap w-40 text-muted font-weight-bold",
        //   tdClass: "no-wrap color-secondary align-middle",
        // },
        {
          label: "Action",
          field: "action",
          thClass: "table-header no-wrap w-40 text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
          sortable:false
        },
      ],
    };
  },
  mounted() {
    this.getList();
    this.getCategoryMCU()
    // this.getTypeNotification();
  },
  methods: {
    // getTypeNotification() {
    //   this.$store.dispatch("notification_type/GET_LIST", this.bodyEmail);
    // },
    getCategoryMCU() {
      this.$store.dispatch("categoryMcu/getCategory");
    },
    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        this.isLoading = true;
        try {
          this.modalEmail.mcu_category_id = 5
          // this.modalEmail.mcu_category_id = Number(
          //   this.modalEmail.mcu_category_id
          // );
          this.$store
            .dispatch("notification/CREATE_DATA", this.modalEmail)
            .then((resp) => {
              this.$swal("Saved", "Your input has been saved", "success").then(
                (resp) => {
                  this.isLoading = false;
                  (this.modalEmail = {
                    title: "",
                    notification_type: "",
                    notification_time: "",
                    // budget_notification: "",
                    subject: "",
                    template: "",
                  }),
                    this.getList();
                  this.$bvModal.hide("modal-1");
                }
              );
            }).catch((err) => {
              this.isLoading = false
              this.$swal("Error", "Error while add data", "error");
            })
        } catch (error) {
          console.log('error', error)
          this.isLoading = false
          this.$swal("Error", "Error while add data", "error");
        }
      });
    },
    saveUpdate() {
      this.modalUpdateEmail.mcu_category_id = 5
      // this.modalUpdateEmail.mcu_category_id = Number(
      //   this.modalUpdateEmail.mcu_category_id
      // );
      let body = {
        id: this.id,
        data: { ...this.modalUpdateEmail },
      };
      this.isLoading = true;
      this.$store
        .dispatch("notification/UPDATE_DATA", body)
        .then((resp) => {
          this.$swal("Saved", "Your input has been saved", "success").then(
            (resp) => {
              this.isLoading = false;
              (this.modalUpdateEmail = {
                title: "",
                notification_type: "",
                notification_time: null,
                mcu_category_id: "",
                subject: "",
                template: "",
              }),
                this.getList();
              this.$bvModal.hide("modal-1");
            }
          );
        })
        .catch((err) => {
          this.$swal("Error", "Error while update data", "error");
              this.isLoading = false;
        });
    },
    addModalEmail(props) {
      this.$bvModal.show("modal-1");
      this.isAdd = true;
    },
    updateEmail(props) {
      this.$bvModal.show("modal-1");
      this.isAdd = false;
      this.modalUpdateEmail.notification_type = props.notification_type;
      this.modalUpdateEmail.scheduler_time = props.scheduler_time;
      this.modalUpdateEmail.mcu_category_id = props.mcu_category_id;
      this.modalUpdateEmail.subject = props.subject;
      this.modalUpdateEmail.template = props.template;
      this.id = props.id;
    },
    seeInfo(props) {
      this.$bvModal.show("modal-info");
      this.isAdd = false;
      this.modalUpdateEmail.notification_type = props.notification_type;
      this.modalUpdateEmail.scheduler_time = props.scheduler_time;
      this.modalUpdateEmail.mcu_category_id = props.mcu_category_id;
      this.modalUpdateEmail.subject = props.subject;
      this.modalUpdateEmail.template = props.template;
      this.id = props.id;
    },

    deleteEmail(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("notification/DELETE_DATA", id)
            .then((resp) => {
              this.getList();
              this.$swal("Deleted!", "Your file has been deleted", "success");
            })
            .catch((resp) => {
              this.$swal("Error", "Error while delete data", "error");
            });
        }
      });
    },
    detailEmail(props) {
      console.log(props);
    },

    checkVal() {
      if (this.modalEmail.notification_type.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    getList() {
      this.$store.dispatch("notification/GET_LIST", this.bodyEmail);
    },

    // filter
    filterPage() {},

    sorting() {
      this.getList();
    },

    onSortChange(event) {
      this.bodyEmail.order = event[0].type == "none" ? "" : event[0].type;
      this.getList();
    },

    // Pagination
    onPageChangeEmail(payload) {
      this.bodyEmail.pageSize = payload.currentPerPage;
      this.bodyEmail.page = payload.currentPage;
      this.getList();
    },
  },
};
</script>
