<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-4">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item">{{ this.breadcrumb }}</li>
                <li class="breadcrumb-item">History</li>
                <li class="breadcrumb-item">MCU Record</li>
                <li class="breadcrumb-item">Detail</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="card p-0">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">Patient Info</h4>
        </div>
        <div v-if="load === false">
          <div class="list-group list-group-flush">
            <div class="list-group-item p-4">
              <div class="row">
                <div class="col-auto">
                  <!-- Avatar -->
                  <div class="avatar avatar-sm">
                    <div
                      class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                      <i class="fe fe-credit-card"></i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <p class="small mt-2">
                    <strong>NIK</strong>
                  </p>
                  <p class="small mt-2">
                    <strong>{{ this.history.nik }}</strong>
                  </p>
                </div>
                <div class="col-auto">
                  <!-- Avatar -->
                  <div class="avatar avatar-sm">
                    <div
                      class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                      <i class="fe fe-user"></i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <p class="small mt-2">
                    <strong>Name</strong>
                  </p>
                  <p class="small mt-2">
                    <strong>{{ this.history.employee_name }}</strong>
                  </p>
                </div>
                <div class="col-auto">
                  <!-- Avatar -->
                  <div class="avatar avatar-sm">
                    <div
                      class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                      <i class="fe fe-home"></i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <p class="small mt-2">
                    <strong>Company</strong>
                  </p>
                  <p class="small mt-2">
                    <strong>{{ this.history.company }}</strong>
                  </p>
                </div>
                <div class="col-auto">
                  <!-- Avatar -->
                  <div class="avatar avatar-sm">
                    <div
                      class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                      <i class="fe fe-layers"></i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <p class="small mt-2">
                    <strong>Departement</strong>
                  </p>
                  <p class="small mt-2">
                    <strong>{{ this.history.department }}</strong>
                  </p>
                </div>
                <div class="col-auto">
                  <!-- Avatar -->
                  <div class="avatar avatar-sm">
                    <div
                      class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                      <i class="fe fe-award"></i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <p class="small mt-2">
                    <strong>Position</strong>
                  </p>
                  <p class="small mt-2">
                    <strong>{{ this.history.position }}</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body text-center" v-else>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-4">
          <!-- Content -->
          <div class="card">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title">Patient</h4>
            </div>
            <div v-if="load === false">
              <div class="mb-3 p-4">
                <div class="row mb-4">
                  <div class="col">NIK : {{ this.data.nik }}</div>
                </div>
                <div class="row mb-4">
                  <div class="col">Name : {{ data.patient_name }}</div>
                </div>
                <div class="row mb-4">
                  <div class="col">Age : {{ data.patient_age }}</div>
                </div>
                <div class="row mb-4">
                  <div class="col">Company : {{ data.company }}</div>
                </div>
                <div class="row mb-4">
                  <div class="col">Department : {{ data.department }}</div>
                </div>
                <div class="row mb-4">
                  <div class="col">Position : {{ data.position }}</div>
                </div>
              </div>
            </div>
            <div class="card-body text-center" v-else>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-xl-8">
          <!-- Content -->
          <div class="card">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title">MCU</h4>
            </div>
            <div v-if="load === false">
              <div class="list-group list-group-flush">
                <div
                  class="list-group-item p-4"
                  v-for="(item, index) in data.mcu"
                  :key="index">
                  <div class="row">
                    <div class="col-auto">
                      <!-- Avatar -->
                      <div class="avatar avatar-sm">
                        <div
                          class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                          <i class="fe fe-user"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <p class="small mt-2">
                        <strong>Paramedic Name</strong>
                      </p>
                      <p class="small mt-2">
                        <strong>{{ item.paramedic_name }}</strong>
                      </p>
                    </div>
                    <div class="col-auto">
                      <!-- Avatar -->
                      <div class="avatar avatar-sm">
                        <div
                          class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                          <i class="fe fe-check-square"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <p class="small mt-2">
                        <strong>MCU Categoy</strong>
                      </p>
                      <p class="small mt-2">
                        <strong>{{ item.mcu_category }}</strong>
                      </p>
                    </div>
                    <div class="col-auto">
                      <!-- Avatar -->
                      <div class="avatar avatar-sm">
                        <div
                          class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                          <i class="fe fe-map-pin"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <p class="small mt-2">
                        <strong>MCU Location</strong>
                      </p>
                      <p class="small mt-2">
                        <strong>{{ item.mcu_location }}</strong>
                      </p>
                    </div>
                    <div class="col-auto">
                      <!-- Avatar -->
                      <div class="avatar avatar-sm">
                        <div
                          class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                          <i class="fe fe-calendar"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <p class="small mt-2">
                        <strong>MCU Year</strong>
                      </p>
                      <p class="small mt-2">
                        <strong>{{ date(item.mcu_year) }}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body text-center" v-else>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>

          <div class="card" v-for="(item, index) in dataMCURecord" :key="index">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title">{{ item.category_name }}</h4>
            </div>
            <div v-if="load === false">
              <div class="table-responsive">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th class="text-center">Group Name</th>
                      
                      <th class="text-center">Check Title</th>
                      <th class="text-center">Check Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(e, i) in item.detail_record" :key="i">
                      <td>{{ e.group_name }}</td>
                      <td>{{ e.check_title }}</td>
                      <td>{{ e.check_value }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-body text-center" v-else>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "patient",
  data() {
    return {
      register_id: "",
      data: [],
      dataMCURecord: [],
      history: [],
      breadcrumb: "",
      load: false,
    };
  },
  mounted() {
    const dataRecord = localStorage.getItem("dataMCURecord");
    this.breadcrumb = localStorage.getItem("breadcrumb");
    this.history = JSON.parse(localStorage.getItem("seehistory"));
    this.data = JSON.parse(dataRecord);
    this.dataRec();
  },

  methods: {
    dataRec() {
      const datas = this.data.mcu;
      const Record = datas.find((item) => {
        return item.register_id == item.register_id;
      });
      this.dataMCURecord = Record.mcu_record;
    },
    date(date) {
      return moment(date).format("YYYY");
    },
  },
};
</script>
