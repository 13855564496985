<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-6">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item">{{ $t("Inventory") }}</li>
                <li class="breadcrumb-item">Purchase Order</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Table -->
      <div class="row">
        <div class="col">
          <div class="card mt-2">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title">{{ $t("List PO") }}</h4>
              <!-- Button -->
              <router-link
                tag="b-button"
                variant="primary"
                to="/clinic-management/input-po">
                <span class="fe fe-file-plus"></span>
                Create New PO
              </router-link>
            </div>

            <div class="card-body">
              <div class="d-flex justify-content-end mb-3">
                <label class="align-self-center mb-0 mr-3">Search</label>
                <b-form-input
                  v-model="body.searchQuery"
                  class="w-auto"
                  placeholder="Type Here..."
                  @change="getDataPO()"></b-form-input>
              </div>

              <vue-good-table
                mode="remote"
                :line-numbers="true"
                :columns="listcolumns"
                :rows="dataPO.rows"
                :total-rows="dataPO.totalRecords"
                :sort-options="{
                  enabled: true
                }"
                :pagination-options="{
                  enabled: true,
                  mode: 'pages',
                  position: 'bottom',
                  perPage: 10,
                  perPageDropdown: [5, 10, 50, 100],
                  dropdownAllowAll: false,
                  setCurrentPage: 1,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'page',
                  allLabel: 'All'
                }"
                style-class="vgt-table table table-bordered"
                @on-sort-change="onSortChange"
                @on-page-change="onPageChange"
                @on-per-page-change="onPageChange"
                :isLoading.sync="isLoading">
                <!-- Loading -->
                <!-- @on-page-change="onPageChange"
                @on-per-page-change="onPageChange" -->
                <div slot="emptystate" class="text-center font-size-14">
                  No data available
                </div>
                <template slot="table-row" slot-scope="props">
                  <!-- Table Action -->
                  <span v-if="props.column.field == 'action'">
                    <b-button
                      class="no-wrap btn-pill"
                      title="Detail"
                      variant="info"
                      size="sm"
                      v-b-modal.modal-po
                      @click="detail(props.row)">
                      <span class="fe fe-clipboard"></span>
                    </b-button>
                    <b-button
                      v-if="
                        ['DELIVERED', 'ORDERED'].includes(props.row.po_status)
                      "
                      class="no-wrap btn-pill ml-2"
                      title="Delivery Order"
                      variant="primary"
                      size="sm"
                      @click.prevent="
                        $router.push(
                          `/clinic-management/input-do?po_id=${props.row.id}`
                        )
                      ">
                      <span class="fe fe-truck"></span>
                    </b-button>
                    <b-button
                      v-if="props.row.po_status === 'DELIVERED'"
                      class="no-wrap ml-2"
                      variant="success"
                      title="Add GR"
                      style="margin-left: 5px"
                      size="sm"
                      @click.prevent="grModal(props.row.id)">
                      <span class="fe fe-file-plus"></span>
                    </b-button>
                    <b-button
                      v-if="props.row.po_status === 'ORDERED'"
                      class="no-wrap ml-2"
                      variant="warning"
                      title="Edit"
                      style="margin-left: 5px"
                      size="sm"
                      @click.prevent="
                        $router.push(
                          `/clinic-management/input-po?id=${props.row.id}`
                        )
                      ">
                      <span class="fe fe-edit"></span>
                    </b-button>
                    <b-button
                      v-if="props.row.po_status === 'ORDERED'"
                      class="no-wrap ml-2"
                      variant="danger"
                      title="Cancel"
                      style="margin-left: 5px"
                      size="sm"
                      @click="deleteData(props.row.id)">
                      <span class="fe fe-archive"></span>
                    </b-button>
                  </span>
                  <span
                    v-else-if="props.column.field == 'po_status'"
                    v-html="badges(props.row.po_status)">
                  </span>
                  <!-- <span v-else-if="props.column.field == 'price'">
                    Rp. {{ convertNumber(props.row.price) }}
                  </span> -->
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
      id="modal-po"
      size="xl"
      hide-footer
      :title="`${dataDetail.id} Detail`"
      centered
      @hide="closeEvent()">
      <b-tabs content-class="mt-3">
        <b-tab title="PO Detail">
          <div class="row">
            <div class="col-lg-4 col-md-12">
              <table cellpadding="2" id="rawTable">
                <tr>
                  <td>SAP / System PO Number</td>
                  <td>:</td>
                  <td>{{ dataDetail.sap_po_number }}</td>
                </tr>
                <tr>
                  <td>Order Date</td>
                  <td>:</td>
                  <td>{{ convertDate(dataDetail.order_time) }}</td>
                </tr>
                <tr>
                  <td>Supplier Name</td>
                  <td>:</td>
                  <td>{{ dataDetail.supplier_name }}</td>
                </tr>
                <tr>
                  <td>Note</td>
                  <td>:</td>
                  <td>{{ dataDetail.note }}</td>
                </tr>
              </table>
            </div>
            <div class="col-lg-8 col-md-12">
              <table class="table table-sm table-bordered po-table">
                <thead>
                  <tr>
                    <th scope="col">NO</th>
                    <th scope="col">SAP Item ID</th>
                    <th scope="col">Item Name</th>
                    <th scope="col">QTY</th>
                    <th scope="col">Unit</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in dataDetail.item" :key="index">
                    <th class="text-center">{{ index + 1 }}</th>
                    <td>{{ item.sap_material_number }}</td>
                    <td class="text-left">{{ item.item_label }}</td>
                    <td>{{ item.item_qty }}</td>
                    <td>{{ item.item_uom_name }}</td>
                    <td class="text-right">
                      {{ convertCurrency(item.item_price) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-right" colspan="5">Total</td>
                    <!-- {{ dataDetail }} -->
                    <td class="text-right" v-if="checkProp(dataDetail)">
                      Rp. {{ convertCurrency(dataDetail.po_price) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-tab>
        <b-tab title="DO Detail">
          <div class="row">
            <div class="col-lg-4 col-md-12">
              <div
                class="card"
                v-for="(prop, index) in dataDO.do_list"
                :key="index">
                <div class="card-body">
                  <div class="list-group list-group-flush my-n3">
                    <div class="list-group-item">
                      <div class="row align-items-center">
                        <div class="col">
                          <h5 class="mb-0">{{ prop.id }}</h5>
                          <span
                            :class="`badge ${
                              prop.payment_status === 'PAID'
                                ? 'bg-success-soft'
                                : 'bg-warning-soft'
                            }`"
                            >{{ prop.payment_status }}</span
                          >
                        </div>
                        <div class="col-auto">
                          <small>
                            <button
                              class="btn btn-primary btn-sm"
                              @click.prevent="detailTable(prop)">
                              {{ $t("Detail") }}
                            </button></small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-8 col-md-12">
              <table class="table table-sm table-bordered po-table">
                <thead>
                  <tr>
                    <th scope="col">NO</th>
                    <th scope="col">SAP Item ID</th>
                    <th scope="col">Item Name</th>
                    <th scope="col">QTY</th>
                    <th scope="col">Unit</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in dataDetailDOtable" :key="index">
                    <th class="text-center">{{ index + 1 }}</th>
                    <td>{{ item.sap_material_number }}</td>
                    <td class="text-left">{{ item.item_name }}</td>
                    <td>{{ item.item_qty }}</td>
                    <td>{{ item.item_uom_name }}</td>
                    <td class="text-right">
                      {{ convertCurrency(item.item_price) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-right" colspan="5">Total</td>
                    <!-- {{ dataDetail }} -->
                    <td class="text-right">
                      Rp. {{ convertCurrency(totalDO) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
    <b-modal id="modal-gr" size="lg" centered hide-footer title="Add GR">
      <form>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form-label">DO List</label>
              <v-select
                v-model="temps"
                :options="dataDoList"
                label="id"
                multiple />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1" class="form-label"
                >Total Payment List</label
              >
              <table cellpadding="2" class="table" id="rawTable">
                <tr v-for="(field, index) in temps" :key="`lol${index}`">
                  <td>{{ field.id }}</td>
                  <td class="text-right">
                    Rp. {{ convertCurrency(field.do_price) }}
                  </td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td class="text-right">
                    Rp. {{ convertCurrency(sus(temps)) }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label for="exampleInputEmail1" class="form-label"
                >GR Number</label
              >
              <input v-model="bodyGR.gr_id" type="text" class="form-control" />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1" class="form-label"
                >Total Payment</label
              >
              <input
                v-model="bodyGR.total_payment"
                min="0"
                :max="maxPrice"
                type="number"
                class="form-control" />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1" class="form-label">Note</label>
              <textarea
                v-model="bodyGR.note"
                type="text"
                class="form-control" />
            </div>
          </div>
        </div>
        <button
          style="margin-left: auto; float: right"
          class="mt-3 btn btn-primary"
          @click.prevent="submitGR">
          {{ $t("Submit") }}
        </button>
      </form>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "inventoryInputPO",
  computed: {
    dataPO() {
      return this.$store.getters["po/PurchaseOrderData"]
        ? this.$store.getters["po/PurchaseOrderData"]
        : [];
    },
    dataDO() {
      return this.$store.getters["po/dataDetailDO"]
        ? this.$store.getters["po/dataDetailDO"]
        : [];
    },
    dataDoList() {
      return this.$store.getters["po/dataDo"]
        ? this.$store.getters["po/dataDo"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        perPage: 10,
        searchQuery: "",
        sort: ""
      },
      maxPrice: 0,
      dataDetail: {},
      dataDetailDOtable: {},
      totalDO: "",
      datas: [
        {
          id: 1,
          sap_code: "#5491",
          suply: "PT. Kalbe",
          qty: "100",
          price: "150000",
          status: "Order"
        },
        {
          id: 2,
          sap_code: "#2313",
          suply: "PT. Kimia Farma",
          qty: "150",
          price: "250000",
          status: "Order"
        }
      ],
      bodyGR: {
        // listSelected: [],
        po_id: "",
        do_id: [],
        gr_id: "",
        total_payment: "",
        note: ""
      },
      temps: [],
      listcolumns: [
        {
          label: "Internal PO",
          field: "id",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "SAP / System PO Number",
          field: "sap_po_number",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Supplier",
          field: "supplier_name",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Status",
          field: "po_status",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle text-center"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle text-center",
          sortable: false
        }
      ],
      isLoading: false
    };
  },
  mounted() {
    this.getDataPO();
  },
  methods: {
    async getDataPO() {
      this.isLoading = true;
      this.$store.dispatch("po/getListPO", this.body).finally(() => {
        this.isLoading = false;
      });
    },

    closeEvent() {
      this.dataDetailDOtable = {};
      this.totalDO = "";
    },

    convertNumber(num) {
      return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    convertDate(d) {
      return moment(d).format("D MMM YYYY");
    },

    onSortChange(params) {
      let sort1 = params[0].field;
      let sort2 = params[0].type;
      this.body.sort = `${sort1},${sort2}`;
      this.getDataPO();
    },

    deleteData(id) {
      let body = {
        id: id
      };
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("po/deletePO", body).then(() => {
              this.getDataPO();
              this.$swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              );
            });
          }
        });
    },

    findPrice(e) {
      let temp = this.bodyGR.do_id;
      this.temp_id = temp.map((a) => a.id);
    },

    submitGR() {
      this.bodyGR.do_id = this.temps.map((a) => a.id);
      // let body = {
      //   po_id: this.bodyGR.po_id,
      //   do_id: this.temp_id,
      //   gr_id: this.bodyGR.gr_id,
      //   note: this.bodyGR.note,
      // }

      this.$store
        .dispatch("po/submitGR", this.bodyGR)
        .then((resp) => {
          this.$bvModal.hide("modal-gr");
          this.$swal.fire("Success!", "Success Add GR", "success");
          this.bodyGR = {
            po_id: "",
            do_id: [],
            gr_id: "",
            note: ""
          };
          this.getDataPO();
        })
        .catch((resp) => {
          this.$swal.fire("Error!", `${resp.response.data.error}`, "error");
        });
    },

    grModal(id) {
      this.$store.dispatch("po/getDoList", id).then(() => {
        if (this.dataDoList.length > 0) {
          this.bodyGR.po_id = id;
          this.$bvModal.show("modal-gr");
        } else {
          this.$swal.fire("Info!", "There are no recent DO available.", "info");
        }
      });
    },

    detail(props) {
      this.dataDetail = props;
      this.$store.dispatch("po/detailDO", props.id);
    },

    sus(arr) {
      let total = arr.reduce((a, b) => a + (b.do_price || 0), 0);
      this.maxPrice = total;
      return total;
    },

    detailTable(e) {
      this.dataDetailDOtable = e.item;
      this.totalDO = e.total_price;
    },

    badges(status) {
      switch (status) {
        case "ORDERED":
          return `<span class="badge bg-info-soft">${status}</span>`;
        case "RECEIVED":
          return `<span class="badge bg-info">${status}</span>`;
        case "DELIVERED":
          return `<span class="badge bg-success-soft">${status}</span>`;
        case "COMPLETED":
          return `<span class="badge bg-success">${status}</span>`;
        case "CANCELED":
          return `<span class="badge bg-danger-soft">${status}</span>`;
        default:
          break;
      }
    },

    convertCurrency(dat) {
      // if (dat.length > 0 ) {
      return dat.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      // }
    },

    checkProp(e) {
      return e.hasOwnProperty("id");
    },

    onPageChange(payload) {
      this.body.perPage = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getDataPO();
    }

    // searchByQuery(e) {
    //   console.log(e);
    // }
  }
};
</script>
<style>
.po-table td:first-child,
.po-table td:nth-last-child(3),
.po-table td:nth-last-child(2),
.po-table td:last-child {
  text-align: center;
}

.po-table > :not(caption) > * > * {
  padding: 0.5rem !important;
}
</style>
