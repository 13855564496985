import axiosIns from '@/api/axios'

export const GET_STAFF_POSITION_DATA = (args) => {
    return axiosIns.get(`/medical/staff/position?${args}`)
}

export const ADD_STAFF_POSITION = (args) => {
    return axiosIns.post(`/medical/staff/position/create`, args)
}

export const DEL_STAFF_POSITION = (id) => {
    return axiosIns.put(`/medical/staff/position/delete?id=${id}`)
}

export const UPDATE_STAFF_POSITION  = (args) => {
    return axiosIns.put(`/medical/staff/position/update?id=${args.id}`, args)
}