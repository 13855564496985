import queryString from "query-string";

import {
    GET_VACC_DATA,
} from "@/api/dashboard";

const state = {
    dataVacc: [],
};

const mutations = {
    setVacc: (states, payload) => {
        states.dataVacc = payload;
    },
};

const actions = {
    getVacc: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_VACC_DATA(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    commit("setVacc", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setVacc", {
                        setVacc: [],
                    });
                }
            });
    },



};

const getters = {
    vaccData: (states) => states.dataVacc
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};