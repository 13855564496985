import axiosIns from '@/api/axios'

export const GET_CAMPAIGN = (args) => {
    return axiosIns.get(`/ih/campaign?${args}`)
}

export const ADD_CAMPAIGN = (args) => {
    return axiosIns.post(`/ih/campaign`, args)
}

export const UPDATE_CAMPAIGN = (args) => {
    return axiosIns.put(`/ih/campaign`, args)
}

export const DELETE_CAMPAIGN = (args) => {
    return axiosIns.delete(`/ih/campaign?id=${args}`)
}
