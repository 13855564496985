import axiosIns from '@/api/axios'

export const GET_INDICATOR_DATA = (args) => {
    return axiosIns.get(`/oh/indicator?${args}`)
}

export const ADD_INDICATOR = (args) => {
    return axiosIns.post(`/oh/indicator/create`, args)
}

export const DEL_INDICATOR = (id) => {
    return axiosIns.put(`/oh/indicator/delete?id=${id}`)
}

export const UPDATE_INDICATOR  = (args) => {
    return axiosIns.put(`/oh/indicator/update?id=${args.id}`, args)
}