<template>
  <!-- HEADER -->
  <div class="header">
    <!-- Body -->
    <div :class="`cart ${load === true ? '' : 'd-none'}`">
      <div class="card-body text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div :class="`cart ${load === true ? 'd-none' : ''}`">
      <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
        <div class="col-10" v-if="filterCompany == 1">
          <div class="form-group">
            <label>{{ $t("Company") }}</label>
            <v-select
              :options="selectCompanyOption()"
              v-model="body.company_id"
              label="company_name"
              :reduce="(e) => e.id"
              @input="changeCompany()" />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <!-- <label for="exampleInputEmail1" class="form-label">Order</label> -->
            <b-button
              v-b-modal.modal-1
              variant="primary"
              @click="isAdd = true"
              class="float-end">
              <span class="fe fe-edit-2"></span>
              {{ $t("Yearly") }}
            </b-button>
            <!-- <v-select v-model="body.order" :options="['ASC', 'DESC']" @input="sorting" /> -->
          </div>
        </div>
      </div>
      <hr />
      <b-collapse id="collapse-1">
        <form @submit.prevent="sorting()">
          <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
            <div class="col">
              <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">{{
                  $t("Company")
                }}</label>
                <v-select
                  :options="[
                    { label: 'UGEMS', id: 'UGEMS' },
                    { label: 'Februari', id: 'Februari' },
                    { label: 'Maret', id: 'Maret' }
                  ]" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">{{
                  $t("Monthly")
                }}</label>
                <v-select
                  :options="[
                    { label: 'Januari', id: 'Januari' },
                    { label: 'Februari', id: 'Februari' },
                    { label: 'Maret', id: 'Maret' }
                  ]" />
              </div>
            </div>
          </div>
          <div class="row mb-3" style="margin-right: 15px">
            <div class="col"></div>
            <div class="col-auto">
              <!-- Button -->
              <button type="submit" class="btn btn-danger lift">
                {{ $t("Filter") }}
              </button>
            </div>
          </div>
          <!-- / .row -->
        </form>
      </b-collapse>
      <b-tabs fill>
        <b-tab :title="$t('Safety')" @click="safetyTab">
          <div style="overflow: auto">
            <table class="table table-sm table-nowrap table-bordered">
              <thead>
                <tr>
                  <th scope="col" rowspan="2">{{ $t("Description") }}</th>
                  <th colspan="3" style="text-align: center">
                    {{ $t("Period 1 January Until 31 December") }}
                  </th>
                </tr>
                <tr>
                  <th scope="col">{{ $t("Permit Holder") }}</th>
                  <th scope="col">{{ $t("Mining Services Company") }}</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(label, index) in yearlyData" :key="index">
                  <th scope="row">{{ label.oh_label }}</th>
                  <td>{{ convertNumber(label.pemegang_izin) }}</td>
                  <td>{{ convertNumber(label.jasa_tambang) }}</td>
                  <td>{{ convertNumber(label.total) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab :title="$t('Health')" @click="healthTab">
          <div style="overflow: auto">
            <table class="table table-sm table-nowrap table-bordered">
              <thead>
                <tr>
                  <th scope="col" rowspan="2">Uraian</th>
                  <th colspan="3" style="text-align: center">
                    {{ $t("Period 1 January Until 31 December") }}
                  </th>
                </tr>
                <tr>
                  <th scope="col">{{ $t("Permit Holder") }}</th>
                  <th scope="col">{{ $t("Mining Services Company") }}</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(label, index) in yearlyData" :key="index">
                  <th scope="row">{{ label.oh_label }}</th>
                  <td>{{ convertNumber(label.pemegang_izin) }}</td>
                  <td>{{ convertNumber(label.jasa_tambang) }}</td>
                  <td>{{ convertNumber(label.total) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab :title="$t('Competency')" @click="competencyTab">
          <div style="overflow: auto">
            <table class="table table-sm table-nowrap table-bordered">
              <thead>
                <tr>
                  <th scope="col" rowspan="2">Uraian</th>
                  <th colspan="3" style="text-align: center">
                    {{ $t("Period 1 January Until 31 December") }}
                  </th>
                </tr>
                <tr>
                  <th scope="col">{{ $t("Permit Holder") }}</th>
                  <th scope="col">{{ $t("Mining Services Company") }}</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(label, index) in yearlyData" :key="index">
                  <th scope="row">{{ label.oh_label }}</th>
                  <td>{{ convertNumber(label.pemegang_izin) }}</td>
                  <td>{{ convertNumber(label.jasa_tambang) }}</td>
                  <td>{{ convertNumber(label.total) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
      </b-tabs>
    </div>

    <!-- Modal -->
    <b-modal
      id="modal-1"
      size="xl"
      hide-footer
      :title="$t('Form Input OH Yearly')">
      <form @submit.prevent="saveAdd()" v-if="isAdd === true">
        <!-- <div class="row d-none">
          <div class="form-group col-6 dno">
            <label for="exampleInputEmail1" class="form-label">Company</label>
            <v-select
              :options="company"
              v-model="company_id"
              label="company_name"
              :reduce="(e) => e.id"
              v-validate="'required'"
              name="Company"
            />
            <span class="text-sm text-danger" v-show="errors.has('Company')">{{
              errors.first("Company")
            }}</span>
          </div>
        </div> -->
        <div class="row mt-4">
          <label for="">
            <h3>{{ $t("Safety Field") }}</h3>
          </label>
          <template v-if="recordSafety.length === safety.data.length">
            <div
              class="form-group col-6"
              v-for="(item, index) in safety.data"
              :key="index">
              <label>{{ item.data_name }}</label>
              <span v-if="item.is_mandatory == true" style="color: red">*</span>
              <span :title="item.description" class="fe fe-alert-circle"></span>
              <div>
                <input
                  v-if="item.is_mandatory == true"
                  :type="item.type_data == 'String' ? 'text' : 'number'"
                  v-model="recordSafety[index]['value']"
                  class="form-control"
                  :placeholder="item.data_name"
                  v-validate="'required'"
                  :name="item.data_name"
                  min="0" />
                <input
                  v-else
                  :type="item.type_data == 'String' ? 'text' : 'number'"
                  v-model="recordSafety[index]['value']"
                  class="form-control"
                  :placeholder="item.data_name"
                  min="0" />
              </div>
              <span
                class="text-sm text-danger"
                v-show="errors.has(item.data_name)"
                >{{ "This Field is Required" }}</span
              >
            </div>
          </template>
        </div>

        <div class="row mt-4">
          <label for="">
            <h3>{{ $t("Health Field") }}</h3>
          </label>
          <template v-if="recordHealth.length === health.data.length">
            <div
              class="form-group col-6"
              v-for="(item, index) in health.data"
              :key="index">
              <label>{{ item.data_name }}</label>
              <span v-if="item.is_mandatory == true" style="color: red">*</span>
              <span :title="item.description" class="fe fe-alert-circle"></span>
              <div>
                <input
                  v-if="item.is_mandatory == true"
                  :type="item.type_data == 'String' ? 'text' : 'number'"
                  v-model="recordHealth[index]['value']"
                  class="form-control"
                  :placeholder="item.data_name"
                  v-validate="'required'"
                  :name="item.data_name"
                  min="0" />
                <input
                  v-else
                  :type="item.type_data == 'String' ? 'text' : 'number'"
                  v-model="recordHealth[index]['value']"
                  class="form-control"
                  :placeholder="item.data_name"
                  min="0" />
              </div>
              <span
                class="text-sm text-danger"
                v-show="errors.has(item.data_name)"
                >{{ "This Field is Required" }}</span
              >
            </div>
          </template>
        </div>

        <div class="row">
          <label for="">
            <h3>{{ $t("Competency Field") }}</h3>
          </label>
          <template v-if="record.length === competency.data.length">
            <div
              class="form-group col-6"
              v-for="(item, index) in competency.data"
              :key="index">
              <label>{{ item.data_name }}</label>
              <span v-if="item.is_mandatory == true" style="color: red">*</span>
              <span :title="item.description" class="fe fe-alert-circle"></span>
              <div>
                <input
                  v-if="item.is_mandatory == true"
                  :type="item.type_data == 'Integer' ? 'number' : 'text'"
                  v-model="record[index]['value']"
                  class="form-control"
                  :placeholder="item.data_name"
                  v-validate="'required'"
                  :name="item.data_name"
                  min="0" />
                <input
                  v-else
                  :type="item.type_data == 'Integer' ? 'number' : 'text'"
                  v-model="record[index]['value']"
                  class="form-control"
                  :placeholder="item.data_name"
                  min="0" />
              </div>
              <span
                class="text-sm text-danger"
                v-show="errors.has(item.data_name)"
                >{{ "This Field is Required" }}</span
              >
            </div>
          </template>
        </div>

        <div>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="submit"
            v-if="isLoading == false">
            {{ $t("Save") }}
          </button>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="button"
            disabled
            v-else>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
            Loading...
          </button>
          <!-- <button
          style="margin-left: auto; float: right"
          class="mt-4 btn btn-primary"
          type="submit"
          disabled
        >
          {{ $t('Save') }}
        </button> -->
        </div>
      </form>

      <!-- <form @submit.prevent="saveUpdate()" v-else>
        <div class="row">
          <label for="">
            <h3>Occupational Health</h3>
          </label>
          <div class="form-group col-6">
            <label>Tanggal</label>
            <input type="date" v-model="date" class="form-control" placeholder="Pilih Tanggal" v-validate="'required'"
              name="Pilih Tanggal" />
            <span class="text-sm text-danger"
              v-show="errors.has('Pilih Tanggal')">{{errors.first('Pilih Tanggal')}}</span>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-6" v-for="(item, index) in ohData.data" :key="index">
            <label>{{ item.data_name }}</label>
            <input type="text" v-model="data_name" class="form-control" :placeholder="item.data_name"
              v-validate="'required'" :name="item.data_name" />
            <span class="text-sm text-danger"
              v-show="errors.has(item.data_name)">{{errors.first(item.data_name)}}</span>
          </div>
        </div>
        <div class="row mt-4">
          <label for="">
            <h3>Occupational Health Activity</h3>
          </label>
          <div class="form-group col-6" v-for="(item, index) in activity.data" :key="index">
            <label>{{ item.activity_name }}</label>
            <input type="text" v-model="activity_name" class="form-control" :placeholder="item.activity_name"
              v-validate="'required'" :name="item.activity_name" />
            <span class="text-sm text-danger"
              v-show="errors.has(item.activity_name)">{{errors.first(item.activity_name)}}</span>
          </div>
        </div>
        <div class="form-group">
          <button style="margin-left: auto; float: right;" class="mt-4 btn btn-primary" type="submit">
            Save
          </button>
        </div>
      </form> -->
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
import axiosIns from "@/api/axios";
export default {
  name: "yearly",
  computed: {
    yearlyData() {
      return this.$store.getters["detailManagement/competencyData"]
        ? this.$store.getters["detailManagement/competencyData"]
        : [];
    },
    competency() {
      return this.$store.getters["yearly/competencyData"]
        ? this.$store.getters["yearly/competencyData"]
        : [];
    },
    health() {
      return this.$store.getters["yearly/healthData"]
        ? this.$store.getters["yearly/healthData"]
        : [];
    },
    safety() {
      return this.$store.getters["yearly/safetyData"]
        ? this.$store.getters["yearly/safetyData"]
        : [];
    },
    company() {
      return this.$store.getters["detailManagement/dataCompany"]
        ? this.$store.getters["detailManagement/dataCompany"]
        : [];
    },
    url() {
      return this.$store.getters["summary/excelUrl"]
        ? this.$store.getters["summary/excelUrl"]
        : [];
    },
    companySelect() {
      return this.$store.getters["detailManagement/companySelectData"]
        ? this.$store.getters["detailManagement/companySelectData"]
        : "1";
    }
  },
  data() {
    return {
      body: {
        company_id: localStorage.getItem("company"),
        oh_type: "Safety",
        type_input: "Yearly"
      },
      bodys: {
        is_input: true
      },
      // company_id: "",
      date_input: "",
      record: [],
      recordHealth: [],
      recordSafety: [],
      isAdd: true,
      isYearlyActivity: true,
      isLoading: false,
      load: true,
      filterCompany: ""
    };
  },
  mounted() {
    const company = localStorage.getItem("company");
    const roles = localStorage.getItem("roles_id");
    this.filterCompany = parseInt(roles) == 1 ? 1 : 0;
    // if (this.filterCompany === 1) {
    //   this.body.company_id = this.companySelect
    // }
    this.competencyGet();
    this.getSafety();
    this.getCompetency();
    this.getHealth();

    // this.getCompanny();
    // this.getLink();
  },
  methods: {
    selectCompanyOption() {
      const company = this.company;
      const term = [];
      term.push({
        id: 0,
        company_name: "BIB + Mitra"
      });

      company.map((i) => {
        term.push({
          id: i.id,
          company_name: i.company_name
        });
      });

      return term;
    },
    //action
    changeCompany() {
      const company = this.body.company_id;
      const body = {
        company_id: company,
        oh_type: "Safety",
        type_input: "Yearly"
      };
      this.load = true;
      this.$store.dispatch("detailManagement/getCompetency", body).then(() => {
        this.load = false;
      });
      this.$store.commit("detailManagement/setCompanySelect", company);
    },
    competencyTab() {
      this.body.oh_type = "Competency";
      this.competencyGet();
    },
    healthTab() {
      this.body.oh_type = "Health";
      this.competencyGet();
    },
    safetyTab() {
      this.body.oh_type = "Safety";
      this.competencyGet();
    },

    updateData(props) {
      this.$bvModal.show("modal-1");
      this.isAdd = false;
      this.id = props.id;
      // this.hazard_names = props.hazard_name;
    },

    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          let concat = this.record.concat(this.recordHealth, this.recordSafety);
          let payload = {
            record: concat,
            type_input: "Yearly",
            date_input: moment().format("YYYY")
            // company_id: this.company_id,
          };
          this.isLoading = true;

          this.$store
            .dispatch("yearly/addYearly", payload)
            .then((resp) => {
              this.$swal({
                title: this.$t("Saved"),
                text: this.$t("Your input has been saved"),
                icon: "success",
                confirmButtonText: this.$t("OK")
              }).then((resp) => {
                this.isLoading = false;
                this.competencyGet();
                this.$bvModal.hide("modal-1");
                const datas = this.$store.getters["yearly/competencyData"];
                const list = datas.data;
                let rec = list.map((item) => {
                  return {
                    oh_type: "Competency",
                    oh_id: Number(item.id),
                    oh_label: item.data_name,
                    value:
                      item.type_data == "Integer" && !item.is_mandatory ? 0 : ""
                  };
                });
                this.record = rec;
                const datass = this.$store.getters["yearly/healthData"];
                const listt = datass.data;
                let recH = listt.map((item) => {
                  return {
                    oh_type: "Health",
                    oh_id: Number(item.id),
                    oh_label: item.data_name,
                    value:
                      item.type_data == "Integer" && !item.is_mandatory ? 0 : ""
                  };
                });
                this.recordHealth = recH;
                const datasss = this.$store.getters["yearly/safetyData"];
                const listtt = datasss.data;
                let recS = listtt.map((item) => {
                  return {
                    oh_type: "Safety",
                    oh_id: Number(item.id),
                    oh_label: item.data_name,
                    value:
                      item.type_data == "Integer" && !item.is_mandatory ? 0 : ""
                  };
                });
                this.recordSafety = recS;
              });
            })
            .catch(() => {
              this.$swal("Error", this.$t("Error while add data"), "error");
              this.isLoading = false;
            });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
          this.isLoading = false;
        }
      });
    },

    saveUpdate() {
      let body = {
        id: this.id,
        hazard_name: this.hazard_names,
        is_deleted: false
      };
      // this.$store.dispatch("chemical/updateChemical", body).then(resp => {
      //   this.$swal("Saved", "Your input has been saved", "success").then(resp => {
      //     // this.getChemical();
      //     this.$bvModal.hide('modal-1');
      //   })
      // }).catch(resp => {
      //   this.$swal("Error", "Error while edit data", "error")
      // })
    },

    deleteData(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        // if (result.isConfirmed) {
        //   this.$store.dispatch("chemical/delChemical", id).then(resp => {
        //     // this.getChemical();
        //     this.$swal("Deleted!", "Your file has been deleted.", "success");
        //   }).catch(resp => {
        //     this.$swal("Error", "Error while delete data", "error")
        //   })
        // }
      });
    },

    convertNumber(num) {
      if (num) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return "0";
      }
    },

    download(url) {
      window.location.href = axiosIns.defaults.baseURL + "/" + url;
    },

    // api hit
    competencyGet() {
      this.load = true;
      this.$store
        .dispatch("detailManagement/getCompetency", this.body)
        .then(() => {
          this.load = false;
        });
    },

    getCompanny() {
      this.$store.dispatch("detailManagement/getCompany");
    },

    getLink() {
      let body = {
        data: "Yearly",
        date_input: moment().format("YYYY")
      };
      this.$store.dispatch("summary/downloadExcel", body);
    },

    getCompetency() {
      this.$store.dispatch("yearly/getCompetency", this.bodys).then(() => {
        const datas = this.$store.getters["yearly/competencyData"];
        const list = datas.data;
        let rec = list.map((item) => {
          return {
            oh_type: "Competency",
            oh_id: Number(item.id),
            oh_label: item.data_name,
            value: item.type_data == "Integer" && !item.is_mandatory ? 0 : ""
          };
        });
        this.record = rec;
      });
    },

    getHealth() {
      this.$store.dispatch("yearly/getHealth", this.bodys).then(() => {
        const datas = this.$store.getters["yearly/healthData"];
        const list = datas.data;
        let rec = list.map((item) => {
          return {
            oh_type: "Health",
            oh_id: Number(item.id),
            oh_label: item.data_name,
            value: item.type_data == "Integer" && !item.is_mandatory ? 0 : ""
          };
        });
        this.recordHealth = rec;
      });
    },

    getSafety() {
      this.$store.dispatch("yearly/getSafety", this.bodys).then(() => {
        const datas = this.$store.getters["yearly/safetyData"];
        const list = datas.data;
        let rec = list.map((item) => {
          return {
            oh_type: "Safety",
            oh_id: Number(item.id),
            oh_label: item.data_name,
            value: item.type_data == "Integer" && !item.is_mandatory ? 0 : ""
          };
        });
        this.recordSafety = rec;
      });
    }
  }
};
</script>
