<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <!-- / .row -->

      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("Medical Waste Partner") }}</h4>
          <!-- Button -->
          <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
            <span class="fe fe-file-plus"></span>
            {{ $t("Partner") }}
          </b-button>
        </div>

        <div class="cart">
          <div class="card-body">
            <div class="d-flex justify-content-end mb-3">
              <!-- <b-button variant="primary" v-b-modal.modal-1>Export</b-button> -->
              <div class="d-flex">
                <label class="align-self-center mb-0 mr-3">Search</label>
                <b-form-input
                  class="w-auto"
                  v-model="body.searchQuery"
                  placeholder="Type Here..."
                  @change="getPartner()"></b-form-input>
              </div>
            </div>
            <vue-good-table
              mode="remote"
              :line-numbers="true"
              :columns="columns"
              :rows="partner.rows"
              :total-rows="partner.totalRecords"
              :sort-options="{
                enabled: true
              }"
              :pagination-options="{
                enabled: true,
                mode: 'pages',
                position: 'bottom',
                perPage: 10,
                perPageDropdown: [10, 50, 100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: 'All'
              }"
              style-class="vgt-table table table-bordered"
              @on-sort-change="onSortChange"
              :isLoading.sync="isLoading"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange">
              <!-- Loading -->
              <div slot="emptystate" class="text-center font-size-14">
                No data available
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <b-button
                    class="no-wrap btn-pill"
                    variant="warning"
                    size="sm"
                    @click="updateData(props.row)">
                    <span class="fe fe-edit"></span>
                  </b-button>
                  <b-button
                    class="no-wrap ml-2"
                    variant="danger"
                    style="margin-left: 5px"
                    size="sm"
                    @click="deleteData(props.row.id)">
                    <span class="fe fe-trash-2"></span>
                  </b-button>
                </span>
              </template>
            </vue-good-table>
          </div>
          <!-- Link -->
        </div>
      </div>

      <!-- Modal -->
      <b-modal id="modal-1" centered hide-footer :title="$t('Chapter Form')">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="form-group">
            <label>{{ $t("Partner Name") }}</label>
            <input
              v-validate="'required'"
              name="Partner Name"
              type="text"
              v-model="partner_name"
              class="form-control"
              placeholder="Partner Name" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Partner Name')"
              >{{ errors.first("Partner Name") }}</span
            >
          </div>
          <div class="form-group">
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="form-group">
            <label>{{ $t("Partner Name") }}</label>
            <input
              type="text"
              v-model="partner_names"
              class="form-control"
              placeholder="Partner Name" />
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "defaultCMS",
  computed: {
    partner() {
      return this.$store.getters["partner/PartnerData"]
        ? this.$store.getters["partner/PartnerData"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        perPage: 10,
        sort: "",
        searchQuery: ""
      },
      partner_name: "",
      partner_names: "",
      id: "",
      isAdd: true,
      isLoading: false,
      columns: [
        {
          label: "PARTNER",
          field: "partner_name",
          thClass: "table-header text-muted w-100 font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle",
          sortable: false
        }
      ]
    };
  },
  mounted() {
    // if (this.chapter && this.chapter.length < 1) {
    this.getPartner();
    // }
  },
  methods: {
    //action
    updateData(props) {
      this.$bvModal.show("modal-1");
      this.isAdd = false;
      this.id = props.id;
      this.partner_names = props.partner_name;
    },
    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.isLoading = true;
          this.$store
            .dispatch("partner/addPartner", {
              partner_name: this.partner_name
            })
            .then((resp) => {
              this.$swal({
                title: this.$t("Saved"),
                text: this.$t("Your input has been saved"),
                icon: "success",
                confirmButtonText: this.$t("OK")
              }).then((resp) => {
                this.isLoading = false;
                this.chapter_name = "";
                this.getPartner();
                this.$bvModal.hide("modal-1");
              });
            });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
        }
      });
    },

    saveUpdate() {
      let body = {
        id: this.id,
        partner_name: this.partner_names
      };
      this.isLoading = true;
      this.$store
        .dispatch("partner/updatePartner", body)
        .then((resp) => {
          this.$swal({
            title: this.$t("Saved"),
            text: this.$t("Your input has been saved"),
            icon: "success",
            confirmButtonText: this.$t("OK")
          }).then((resp) => {
            this.isLoading = false;
            this.getPartner();
            this.$bvModal.hide("modal-1");
          });
        })
        .catch((resp) => {
          this.$swal("Error", this.$t("Error while add data"), "error");
        });
    },

    deleteData(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          this.$store
            .dispatch("partner/delPartner", id)
            .then((resp) => {
              this.isLoading = false;
              this.getPartner();
              this.$swal(
                "Deleted!",
                this.$t("Your file has been deleted"),
                "success"
              );
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    },

    // api hit
    getPartner() {
      this.$store.dispatch("partner/getPartner", this.body);
    },

    // filter
    filterPage() {
      this.getPartner();
    },

    sorting() {
      // this.onPageChange((payload) => {
      //   // payload.currentPerPage=  10,
      //   // payload.currentPage= 1
      // })

      this.getPartner();
      this.body.page = 1;
    },

    // Pagination
    onPageChange(payload) {
      this.body.perPage = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getPartner();
      // if (
      //   parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
      //   1000
      // ) {
      // }
    },

    onSortChange(params) {
      let sort1 = params[0].field;
      let sort2 = params[0].type;
      this.body.sort = `${sort1},${sort2}`;
      this.getPartner();
    }
  }
};
</script>
