import axiosIns from "@/api/axios";

export const GET_QR = (args) => {
  return axiosIns.get(`/clinic/register/get/code?${args}`);
};

export const GET_PATIENT_QR = (args) => {
  return axiosIns.get(`/clinic/register/status/code?${args}`);
};

export const REGISTER_PATIENT = (args) => {
  return axiosIns.post(`/clinic/register/code?id=${args.id}`, args);
};
export const REGISTER_PATIENT2 = (args) => {
  return axiosIns.post(`/clinic/register/patient`, args);
};

export const GET_ALL_DATA = (args) => {
  return axiosIns.get(`/clinic/register/get/record?${args}`);
};

export const GET_EMPLOYEE = (args) => {
  return axiosIns.get(`/users/clinic?${args}`);
};

export const GET_DIAGNOSE = (args) => {
  return axiosIns.get(`/clinic/patient/record/byRegistId?register_id=${args}`);
};

export const DIAGNOSE_PATIENT = (args) => {
  return axiosIns.post(`/clinic/patient/record/add`, args, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const DIAGNOSE_PATIENT_MCU = (args) => {
  return axiosIns.post(`/clinic/patient/mcu/record/add`, args);
};

export const DELETE_PATIENT = (id) => {
  return axiosIns.put(`/clinic/register/delete?register_id=${id}`);
};

// Medical Record
export const GET_RECORD = (args) => {
  return axiosIns.get(`/clinic/patient/record?${args}`);
};

// export const GET_DOCTOR = (args) => {
//   return axiosIns.get(`/roles/clinic?position_id=4`);
// };

export const IMPORT_REGISTER_PATIENT = (payload) => {
  return axiosIns.post("/clinic/patient/mcu/record/import", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const GET_CATEGORY_MCU = (args) => {
  return axiosIns.get(`/mcu/category?limit=1000&order=&page=1`);
};

export const 
ADD_NO_PARTNERSHIP = (args) => {
  return axiosIns.post(`/clinic/patient/record/treat/referral`, args, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const GET_INVENTORY_CLINIC = (args) => {
  // return axiosIns.get(`/inventory/clinic-items`, args);
  return axiosIns.get(`/inventory/clinic-items?type=${args}`)
};

export const GET_DOSE = () => {
  return axiosIns.get(`/medical/diagnose-dose?limit=1000&order=&page=1`)
};

export const GET_HOW_TO_USE = () => {
  return axiosIns.get(`/medical/diagnose-how-use?limit=1000&order=&page=1`)
};

export const CHECK_MCU_PATIENT = (args) => {
  return axiosIns.get(`/clinic/check-mcu/patient?nik=${args}`)
};
