import axiosIns from '@/api/axios'

// Bin
export const GET_BIN_DATA = (args) => {
    return axiosIns.get(`/medical-waste/pool?${args}`)
}

export const SUBMIT_MEDICAL_WASTE = (args) => {
    return axiosIns.post(`/medical-waste`, args)
}

//Disposal
export const GET_DISPOSABLE = (args) => {
    return axiosIns.get(`/medical-waste?${args}`)
}