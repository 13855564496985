import axiosIns from '@/api/axios'

export const GET_UNIT_PRODUCT = (args) => {
    return axiosIns.get(`/inventory/do/cart/item?id=${args}`)
}
export const ADD_UNIT_PRODUCT = (args) => {
    return axiosIns.put(`/inventory/do/cart`, args)
}
export const GET_INPUT_PRODUCT = (args) => {
    return axiosIns.get(`/inventory/do/cart?${args}`)
    
}
export const DEL_INPUT_PRODUCT = (args) => {
    return axiosIns.delete(`/inventory/do/cart?id=${args}`)
}

export const UPDATE_INNER_DO = (args) => {
    return axiosIns.put(`/inventory/do/cart`, args)
}

export const COPY_PRODUCT = (args) => {
    return axiosIns.put(`/inventory/do/cart/copy?${args}`)
}


export const SUBMIT_DO = (args) => {
    return axiosIns.post(`/inventory/do/submit`, args)
}
export const SAVE_DO = (args) => {
    return axiosIns.put(`/inventory/do/save`, args)
}

export const GET_LIST_DO = (args) => {
    return axiosIns.get(`/inventory/do?${args}`)
}
export const DELETE_LIST_DO = (args) => {
    return axiosIns.delete(`/inventory/do?${args}`)
}
export const GET_DETAIL_DO = (args) => {
    return axiosIns.get(`/inventory/do/detail?${args}`)
}
export const GET_EDIT_DO = (args) => {
    return axiosIns.get(`/inventory/do/edit?${args}`)
}


// New
export const GET_PO_REF = () => {
    return axiosIns.get('/inventory/po/ordered')
}
export const GET_REF_ID = (args) => {
    return axiosIns.get(`/inventory/do/editByPo?${args}`)
}


