import axiosIns from '@/api/axios'

export const GET_BLOCK_DATA = (args) => {
    return axiosIns.get(`/disease/block?${args}`)
}

export const ADD_BLOCK = (args) => {
    return axiosIns.post(`/disease/block/create`, args)
}

export const DEL_BLOCK = (id) => {
    return axiosIns.put(`/disease/block/delete?id=${id}`)
}

export const UPDATE_BLOCK  = (args) => {
    return axiosIns.put(`/disease/block/update?id=${args.id}`, args)
}