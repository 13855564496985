import axiosIns from '@/api/axios'

export const EXCEL_DOWNLOAD = (args) => {
    return axiosIns.get(`/oh/management/report/excel?${args}`)
}
export const SUMMARY_DATA = (args) => {
    return axiosIns.get(`/oh/management/summarize?${args}`)
}
export const PROFILE = () => {
    return axiosIns.get('/users/identify')
}
