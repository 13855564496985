<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <!-- / .row -->

      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <b-tabs content-class="mt-3" fill>
          <b-tab :title="$t('Competency')">
            <Competency />
          </b-tab>
          <b-tab :title="$t('Health')">
            <Health />
          </b-tab>
          <b-tab :title="$t('Safety')">
            <Safety />
          </b-tab>
        </b-tabs>
      </div>

      <!-- Modal -->
      <b-modal id="modals" hide-footer title="OH Form">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="form-group">
            <label>{{ $t("OH Name") }}</label>
            <input
              type="text"
              v-model="bodyAdd.data_name"
              class="form-control"
              placeholder="OH Name"
              v-validate="'required'"
              name="OH Name" />
            <span class="text-sm text-danger" v-show="errors.has('OH Name')">{{
              errors.first("OH Name")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Data Flag") }}</label>
            <v-select
              v-model="bodyAdd.data_flag"
              :options="['Daily', 'Monthly', 'Yearly']"
              v-validate="'required'"
              name="Data Flag"></v-select>
            <span
              class="text-sm text-danger"
              v-show="errors.has('Data Flag')"
              >{{ errors.first("Data Flag") }}</span
            >
          </div>
          <div class="form-group">
            <label>Order</label>
            <input
              type="number"
              v-model="bodyAdd.data_order"
              class="form-control"
              placeholder="Order"
              v-validate="'required'"
              name="Order" />
            <span class="text-sm text-danger" v-show="errors.has('Order')">{{
              errors.first("Order")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Description") }}</label>
            <textarea
              type="text"
              v-model="bodyAdd.description"
              class="form-control"
              placeholder="Description" />
          </div>
          <div class="form-group">
            <label>{{ $t("Type") }}</label>
            <v-select
              v-model="bodyAdd.type_data"
              :options="['Integer', 'String', 'Text Area']"
              v-validate="'required'"
              name="Type"></v-select>
            <span class="text-sm text-danger" v-show="errors.has('Type')">{{
              errors.first("Type")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Mandatory") }}</label>
            <v-select
              v-model="bodyAdd.is_mandatory"
              :options="mandatory"
              :label="label"
              :reduce="(e) => e.code"
              v-validate="'required'"
              name="Mandatory"></v-select>
            <span
              class="text-sm text-danger"
              v-show="errors.has('Mandatory')"
              >{{ errors.first("Mandatory") }}</span
            >
          </div>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="submit"
            v-if="isLoading == false">
            {{ $t("Save") }}
          </button>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="button"
            disabled
            v-else>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
            Loading...
          </button>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="form-group">
            <label>{{ $t("OH Name") }}</label>
            <input
              type="text"
              v-model="bodyUpdate.data_name"
              class="form-control"
              placeholder="OH Name"
              v-validate="'required'"
              name="OH Name" />
            <span class="text-sm text-danger" v-show="errors.has('OH Name')">{{
              errors.first("OH Name")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Data Flag") }}</label>
            <v-select
              v-model="bodyUpdate.data_flag"
              :options="['Daily', 'Monthly', 'Yearly']"
              v-validate="'required'"
              name="Data Flag"></v-select>
            <span
              class="text-sm text-danger"
              v-show="errors.has('Data Flag')"
              >{{ errors.first("Data Flag") }}</span
            >
          </div>
          <div class="form-group">
            <label>Order</label>
            <input
              type="number"
              v-model="bodyUpdate.data_order"
              class="form-control"
              placeholder="Order"
              v-validate="'required'"
              name="Order" />
            <span class="text-sm text-danger" v-show="errors.has('Order')">{{
              errors.first("Order")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Description") }}</label>
            <textarea
              type="text"
              v-model="bodyUpdate.description"
              class="form-control"
              placeholder="Description" />
          </div>
          <div class="form-group">
            <label>{{ $t("Type") }}</label>
            <v-select
              v-model="bodyUpdate.type_data"
              :options="['Integer', 'String', 'Text Area']"
              v-validate="'required'"
              name="Type"></v-select>
            <span class="text-sm text-danger" v-show="errors.has('Type')">{{
              errors.first("Type")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Mandatory") }}</label>
            <v-select
              v-model="bodyUpdate.is_mandatory"
              :options="mandatory"
              :label="label"
              :reduce="(e) => e.code"
              v-validate="'required'"
              name="Mandatory"></v-select>
            <span
              class="text-sm text-danger"
              v-show="errors.has('Mandatory')"
              >{{ errors.first("Mandatory") }}</span
            >
          </div>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="submit"
            v-if="isLoading == false">
            {{ $t("Save") }}
          </button>
          <button
            style="margin-left: auto; float: right"
            class="mt-4 btn btn-primary"
            type="button"
            disabled
            v-else>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
            Loading...
          </button>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
import Competency from "../../components/yearly/competency.vue";
import Health from "../../components/yearly/health.vue";
import Safety from "../../components/yearly/Safety.vue";
// import Safe from '../../components/yearly/safe.vue'
import moment from "moment";
import axiosIns from "@/api/axios";
export default {
  name: "data",
  computed: {
    ohData() {
      return this.$store.getters["ohData/OhData"]
        ? this.$store.getters["ohData/OhData"]
        : [];
    },
    url() {
      return this.$store.getters["summary/excelUrl"]
        ? this.$store.getters["summary/excelUrl"]
        : [];
    }
  },
  components: {
    Competency,
    Health,
    Safety
  },
  data() {
    return {
      body: {
        page: 1,
        limit: 10,
        data_name: "",
        order: ""
      },
      bodyAdd: {
        data_name: "",
        data_flag: "",
        data_order: "",
        description: "",
        type_data: "",
        is_mandatory: ""
      },
      bodyUpdate: {
        id: "",
        data_name: "",
        data_flag: "",
        data_order: "",
        description: "",
        type_data: "",
        is_mandatory: ""
      },
      // data_name: '',
      // data_names: '',
      // id: '',
      isAdd: true,
      isLoading: false,
      mandatory: [
        {
          label: "YES",
          code: true
        },
        {
          label: "NO",
          code: false
        }
      ]
    };
  },
  mounted() {
    if (this.ohData && this.ohData.length < 1) {
      this.getOh();
    }
  },
  methods: {
    //action
    updateData(props) {
      this.$bvModal.show("modals");
      this.isAdd = false;
      this.bodyUpdate.id = props.id;
      this.bodyUpdate.data_name = props.data_name;
      this.bodyUpdate.data_flag = props.data_flag;
      this.bodyUpdate.data_order = props.data_order;
      this.bodyUpdate.description = props.description;
      this.bodyUpdate.type_data = props.type_data;
      this.bodyUpdate.is_mandatory = props.is_mandatory;
    },

    saveAdd() {
      this.bodyAdd.data_order = parseInt(this.bodyAdd.data_order);
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.isLoading = true;
          this.$store.dispatch("ohData/addOh", this.bodyAdd).then((resp) => {
            this.$swal({
              title: this.$t("Saved"),
              text: this.$t("Your input has been saved"),
              icon: "success",
              confirmButtonText: this.$t("OK")
            }).then((resp) => {
              this.isLoading = false;
              this.bodyAdd.data_name = "";
              this.bodyAdd.data_flag = "";
              this.bodyAdd.data_order = "";
              this.bodyAdd.description = "";
              this.bodyAdd.type_data = "";
              this.bodyAdd.is_mandatory = "";
              this.getOh();
              this.$bvModal.hide("modals");
            });
          });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
        }
      });
    },
    customStore() {
      this.$store.dispatch("ohData/getOh", this.ohData);
    },
    saveUpdate() {
      this.isLoading = true;
      this.$store
        .dispatch("ohData/updateOh", this.bodyUpdate)
        .then((resp) => {
          this.$swal({
            title: this.$t("Saved"),
            text: this.$t("Your input has been saved"),
            icon: "success",
            confirmButtonText: this.$t("OK")
          }).then((resp) => {
            this.isLoading = false;
            this.getOh();
            this.$bvModal.hide("modals");
          });
        })
        .catch((resp) => {
          this.$swal("Error", this.$t("Error while edit data"), "error");
        });
    },

    deleteData(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("ohData/delOh", id)
            .then((resp) => {
              this.getOh();
              this.$swal(
                "Deleted!",
                this.$t("Your file has been deleted"),
                "success"
              );
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    },

    // api hit
    getOh() {
      this.$store.dispatch("ohData/getOh", this.body);
      // this.customStore();
    },

    // filter
    filterPage() {
      this.getOh();
    },

    sorting() {
      this.getOh();
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;
      // if (
      //   parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
      //   1000
      // ) {
      this.getOh();
      // }
    }
  }
};
</script>
