<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <!-- / .row -->

      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("Category") }}</h4>
          <!-- Button -->
          <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
            <span class="fe fe-file-plus"></span>
            {{ $t("Add") }}
          </b-button>
          <b-button
            v-b-toggle.collapse-1
            variant="primary"
            style="margin-left: 7px">
            <span class="fe fe-sliders"></span>
          </b-button>
        </div>
        <b-collapse id="collapse-1">
          <form @submit.prevent="sorting()">
            <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Block Name")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="body.block_name" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Chapter Name")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="body.chapter_name" />
                </div>
              </div>
            </div>

            <div class="row" style="margin-left: 15px; margin-right: 15px">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Category Name")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="body.category_name" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label"
                    >Order</label
                  >
                  <v-select v-model="body.order" :options="['ASC', 'DESC']" />
                </div>
              </div>
            </div>

            <div class="row mb-3" style="margin-right: 15px">
              <div class="col"></div>
              <div class="col-auto">
                <!-- Button -->
                <button type="submit" class="btn btn-primary lift">
                  {{ $t("Filter") }}
                </button>
              </div>
            </div>
            <!-- / .row -->
          </form>
        </b-collapse>
        <vue-good-table
          :line-numbers="true"
          :columns="columns"
          :rows="category.data"
          :sort-options="{
            enabled: true
          }"
          :total-rows="category.totalItems"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }"
          style-class="vgt-table border-0 table"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange">
          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <!-- Custom Column -->
          <template slot="table-column" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.label == 'CHAPTER'">
              {{ $t("Chapter") }}
            </span>
            <span v-if="props.column.label == 'BLOCK'">
              {{ $t("Block") }}
            </span>
            <span v-if="props.column.label == 'CATEGORY'">
              {{ $t("Category") }}
            </span>
            <span v-if="props.column.label == 'ACTION'">
              {{ $t("Action") }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.field == 'action'">
              <b-button
                class="no-wrap btn-pill w-"
                variant="warning"
                size="sm"
                @click="updateData(props.row)">
                <span class="fe fe-edit"></span>
              </b-button>
              <b-button
                class="no-wrap ml-2"
                variant="danger"
                style="margin-left: 5px"
                size="sm"
                @click="deleteData(props.row.id)">
                <span class="fe fe-trash-2"></span>
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </div>

      <!-- Modal -->
      <b-modal id="modal-1" hide-footer centered :title="$t('Category Form')">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="form-group">
            <label>{{ $t("Chapter") }}</label>
            <v-select
              v-model="bodyAdd.chapter_id"
              :options="chapter.data"
              label="chapter_name"
              :reduce="(e) => e.id"
              @input="chapterAddSelect()"
              v-validate="'required'"
              name="Chapter Name" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Chapter Name')"
              >{{ errors.first("Chapter Name") }}</span
            >
          </div>
          <div class="form-group" v-if="selectBlock.length > 0">
            <label>{{ $t("Block") }}</label>
            <v-select
              v-model="bodyAdd.block_id"
              :options="selectBlock"
              label="block_name"
              :reduce="(e) => e.id"
              v-validate="'required'"
              name="Block Name" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Block Name')"
              >{{ errors.first("Block Name") }}</span
            >
          </div>
          <div class="form-group">
            <label>{{ $t("Category Name") }}</label>
            <input
              type="text"
              v-model="bodyAdd.category_name"
              class="form-control"
              placeholder="Category Name"
              v-validate="'required'"
              name="Category Name" />
            <span
              class="text-sm text-danger"
              v-show="errors.has('Category Name')"
              >{{ errors.first("Category Name") }}</span
            >
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="form-group">
            <label>{{ $t("Chapter") }}</label>
            <v-select
              v-model="bodyUpdate.chapter_id"
              :options="chapter.data"
              label="chapter_name"
              :reduce="(e) => e.id"
              @input="chapterUpdateSelect()" />
          </div>
          <div class="form-group" v-if="selectBlock.length > 0">
            <label>{{ $t("Block") }}</label>
            <v-select
              v-model="bodyUpdate.block_id"
              :options="selectBlock"
              label="block_name"
              :reduce="(e) => e.id" />
          </div>
          <div class="form-group">
            <label>{{ $t("Category Name") }}</label>
            <input
              type="text"
              v-model="bodyUpdate.category_name"
              class="form-control"
              placeholder="Category Name" />
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button
              style="margin-left: auto; float: right"
              class="mt-4 btn btn-primary"
              type="button"
              disabled
              v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "defaultCMS",
  computed: {
    category() {
      return this.$store.getters["category/CategoryData"]
        ? this.$store.getters["category/CategoryData"]
        : [];
    },
    block() {
      return this.$store.getters["block/BlockData"]
        ? this.$store.getters["block/BlockData"]
        : [];
    },
    chapter() {
      return this.$store.getters["chapter/ChapterData"]
        ? this.$store.getters["chapter/ChapterData"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        limit: 500,
        order: "",
        chapter_name: "",
        category_name: "",
        block_name: ""
      },
      bodys: {
        page: 1,
        limit: 500,
        order: ""
      },
      bodyAdd: {
        category_name: "",
        block_id: "",
        chapter_id: ""
      },
      bodyUpdate: {
        id: "",
        category_name: "",
        block_id: "",
        chapter_id: ""
      },
      isAdd: true,
      isLoading: false,
      columns: [
        {
          label: "CHAPTER",
          field: "chapter_name",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "BLOCK",
          field: "block_name",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "CATEGORY",
          field: "category_name",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ],
      selectBlock: []
    };
  },
  mounted() {
    if (this.category && this.category.length < 1) {
      this.getCategory();
      this.getBlock();
      this.getChapter();
    }
  },
  methods: {
    //action
    updateData(props) {
      this.$bvModal.show("modal-1");
      this.isAdd = false;
      this.bodyUpdate.id = props.id;
      this.bodyUpdate.category_name = props.category_name;
      this.bodyUpdate.block_id = props.block_id;
      this.bodyUpdate.chapter_id = props.chapter_id;

      this.chapterUpdateSelect();
    },

    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          this.isLoading = true;
          this.$store
            .dispatch("category/addCategory", this.bodyAdd)
            .then((resp) => {
              this.$swal({
                title: this.$t("Saved"),
                text: this.$t("Your input has been saved"),
                icon: "success",
                confirmButtonText: this.$t("OK")
              }).then((resp) => {
                this.isLoading = false;
                this.bodyAdd.category_name = "";
                this.bodyAdd.block_id = "";
                this.bodyAdd.chapter_id = "";
                this.getCategory();
                this.$bvModal.hide("modal-1");
              });
            });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
        }
      });
    },

    saveUpdate() {
      this.isLoading = true;
      this.$store
        .dispatch("category/updateCategory", this.bodyUpdate)
        .then((resp) => {
          this.$swal({
            title: this.$t("Saved"),
            text: this.$t("Your input has been saved"),
            icon: "success",
            confirmButtonText: this.$t("OK")
          }).then((resp) => {
            this.isLoading = false;
            this.getCategory();
            this.$bvModal.hide("modal-1");
          });
        })
        .catch((resp) => {
          this.$swal("Error", this.$t("Error while add data"), "error");
        });
    },

    deleteData(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("category/delCategory", id)
            .then((resp) => {
              this.getCategory();
              this.$swal(
                "Deleted!",
                this.$t("Your file has been deleted"),
                "success"
              );
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    },

    // api hit
    async getCategory() {
      this.$store.dispatch("category/getCategory", this.body);
    },
    async getBlock() {
      await this.$store.dispatch("block/getBlock", this.bodys);
    },
    async getChapter() {
      await this.$store.dispatch("chapter/getChapter", this.bodys);
    },

    // filter
    filterPage() {
      this.getCategory();
    },

    sorting() {
      this.getCategory();
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;
      // if (
      //   parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
      //   1000
      // ) {
      this.getCategory();
      // }
    },

    chapterAddSelect() {
      let blockData = this.block.data;
      let chapterId = this.bodyAdd.chapter_id;
      this.selectBlock = blockData.filter((item) => {
        return item.chapter_id == chapterId;
      });
    },

    chapterUpdateSelect() {
      let blockData = this.block.data;
      let chapterId = this.bodyUpdate.chapter_id;
      this.selectBlock = blockData.filter((item) => {
        return item.chapter_id == chapterId;
      });
    }
  }
};
</script>
