import queryString from "query-string";

import {
    GET_ITEM_DATA,
    ADD_ITEM,
    DEL_ITEM,
    UPDATE_ITEM
} from "@/api/mcu/test/item";

const state = {
    dataItem: [],
};

const mutations = {
    setItem: (states, payload) => {
        states.dataItem = payload;
    },
};

const actions = {
    getItem: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_ITEM_DATA(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    commit("setItem", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setItem", {
                        setItem: [],
                    });
                }
            });
    },

    addItem: async ({}, args) => {
        return await ADD_ITEM(args);
    },

    delItem: async ({}, id) => {
        return await DEL_ITEM(id);
    },

    updateItem: async ({}, args) => {
        return await UPDATE_ITEM(args);
    },



};

const getters = {
    ItemData: (states) => states.dataItem
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};