import Vue from "vue";
import Vuex from "vuex";

//dashboard
import dashboard from "./dashboard";

// Role
import role from "./role/role"

//Company
import company from "./company/company";

// Disease
import disease from "./disease/disease";
import chapter from "./disease/chapter";
import block from "./disease/block";
import category from "./disease/category";

// Inventory
import drug from "./inventory/drug";
import unit from "./inventory/unit";
import categoryDrug from "./inventory/category";
import disposableCategory from "./inventory/disposable_category";
import disposableItem from "./inventory/disposable";
import toolCategory from "./inventory/tool-category";
import toolItem from "./inventory/tools";
import partner from "./inventory/partner";

// Medical
import facility from "./medical/facility";
import treatment from "./medical/treatment";
import group from "./medical/staff/training/group";
import subGroup from "./medical/staff/training/subGroup";
import position from "./medical/staff/position";
// Modul MCU
import categoryMcu from "./mcu/category/category";
import resultMcu from "./mcu/category/result";
import groupMcu from "./mcu/test/group";
import categoryMcuTest from "./mcu/test/category";
import itemMcu from "./mcu/test/item";
import typeQuestion from "./mcu/question/questionType"
import question from "./mcu/question/question"

// Ih
import action from "./ih/action";
import factor from "./ih/factor";
import measuringActivity from "./ih/measuring/activity";
import measuringMethod from "./ih/measuring/method";
import measuringUnit from "./ih/measuring/unit";
import kit from "./ih/measuring/kit";
import campaign from "./ih/measuring/campaign";
import chemicalIh from "./ih/chemical/chemical";

// Oh
import activity from "./oh/activity";
import indicator from "./oh/indicator";
import ohData from "./oh/data";
import document from "./oh/document";
import yearly from "./oh/yearly"

// Healthcare
import healthcare from "./healthcare/healthcare"
// Hazard 
import chemical from './hazard/chemical';
import name from './hazard/name';
import type from './hazard/type';
import healthcareFasility from './healthcare/fasility';

// Oh Management 
import detailManagement from './oh_management/detail_management'
import documentation from './oh_management/documentation'
import summary from './oh_management/summary'
import inputOH from './oh_management/input'
import leadingLagging from './oh_management/leading_lagging'
import recapDaily from './oh_management/recap_daily'

// Clinic Management
import patient from './clinic/patient'
import drugTest from './clinic/drugTest'
import roleClinicManagement from './clinic/role_management_clinic'
import medicalRecordMcu from './clinic/medical_record_mcu'
import historyMcu from './clinic/history'
import po from './clinic/po'
import Do from './clinic/do'
import entryStock from './clinic/entryStock'
import reporting from './clinic/reporting'
import opname from './clinic/opname'
import medicalWaste from './clinic/medical_waste'

// Role Management
import roleManagement from './role_management/role_management'

//Notification
import notification from './notification/index.js'

//Overhandle
import overhandle from './overhandle/overhandle'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    //dashboard
    dashboard,

    // Role 
    role,

    //company
    company,

    // Disease
    disease,
    chapter,
    block,
    category,

    // Inventory
    drug,
    unit,
    categoryDrug,
    disposableCategory,
    disposableItem,
    toolCategory,
    toolItem,
    partner,

    // Medical
    facility,
    treatment,
    group,
    subGroup,
    position,
    categoryMcu,
    resultMcu,
    groupMcu,
    itemMcu,
    typeQuestion,
    
    question,
    categoryMcuTest,

    // Ih
    action,
    factor,
    measuringActivity,
    measuringMethod,
    measuringUnit,
    kit,
    campaign,
    chemicalIh,

    // Oh
    activity,
    indicator,
    ohData,
    document,
    yearly,

    // Healthcare
    healthcare,

    // Hazard
    chemical,
    name,
    type,

    // Healthcare Fasility 
    healthcareFasility,

    // Oh Management 
    detailManagement,
    documentation,
    summary,
    inputOH,
    leadingLagging,
    recapDaily,

    // Clinic Management
    patient,
    drugTest,
    roleClinicManagement,
    roleManagement,
    medicalRecordMcu,
    historyMcu,
    po,
    Do,
    entryStock,
    reporting,
    opname,
    medicalWaste,
    //
    notification,
    overhandle

  }
});
