import axiosIns from '@/api/axios'

export const GET_COMPANY_DATA = (args) => {
    return axiosIns.get(`/company/mapping?${args}`)
}

export const ADD_COMPANY = (args) => {
    return axiosIns.post(`/company/mapping`, args)
}

export const DEL_COMPANY = (id) => {
    return axiosIns.delete(`/company/mapping?company_id=${id}`)
}

export const UPDATE_COMPANY  = (args) => {
    return axiosIns.post(`/company/mapping`, args)
}