import axiosIns from '@/api/axios'

export const GET_MEDICAL_RECORD = (args) => {
    return axiosIns.get(`/clinic/register/get/record?${args}`)
}

export const GET_MEDICAL_RECORD_DETAIL = (args) => {
    return axiosIns.get(`/clinic/patient/record/byRegistId?${args}`)
} 

export const GET_MCU_RECORD = (args) => {
    return axiosIns.get(`/clinic/patient/mcu?${args}`)
}

// export const GET_USER_MCU = (args) => {
//     return axiosIns.get('/clinic/register/export-pdf?nik=${this.profile.nik}&date=${this.now').then((resp) => {
//         this.pdf = resp.data.data.path
//     })
// }

// export const downloadPDF =(args)=>{
//     window.open('')
// }

