<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <!-- / .row -->

      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("Block") }}</h4>
          <!-- Button -->
          <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
            <span class="fe fe-file-plus"></span>
            {{ $t("Add") }}
          </b-button>
          <b-button v-b-toggle.collapse-1 variant="primary" style="margin-left: 7px">
            <span class="fe fe-sliders"></span>
          </b-button>
        </div>
        <b-collapse id="collapse-1">
          <form @submit.prevent="sorting()">
            <div class="row mt-4" style="">
              <div class="col">
                <form @submit.prevent="sorting()">
                  <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
                    <div class="col">
                      <div class="form-group">
                        <label for="exampleInputEmail1" class="form-label">{{
                          $t("Chapter Name")
                        }}</label>
                        <input type="text" class="form-control" v-model="body.chapter_name" />
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label for="exampleInputEmail1" class="form-label">{{
                          $t("Block Name")
                        }}</label>
                        <input type="text" class="form-control" v-model="body.block_name" />
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label for="exampleInputEmail1" class="form-label">Order</label>
                        <v-select v-model="body.order" :options="['ASC', 'DESC']" />
                        <!-- <v-select v-model="body.order" :options="['ASC', 'DESC']" @input="sorting" /> -->
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3" style="margin-right: 15px">
                    <div class="col"></div>
                    <div class="col-auto">
                      <!-- Button -->
                      <button type="submit" class="btn btn-danger lift">
                        {{ $t("Filter") }}
                      </button>
                    </div>
                  </div>
                  <!-- / .row -->
                </form>
              </div>
              <!-- / .row -->
            </div>
          </form>
        </b-collapse>
        <vue-good-table :line-numbers="true" :columns="columns" :rows="block.data" :sort-options="{
            enabled: true
          }" :total-rows="block.totalItems" :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }" style-class="vgt-table border-0 table" @on-page-change="onPageChange" @on-per-page-change="onPageChange">
          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <template slot="table-column" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.label == 'CHAPTER'">
              {{ $t("Chapter") }}
            </span>
            <span v-if="props.column.label == 'BLOCK'">
              {{ $t("Block") }}
            </span>
            <span v-if="props.column.label == 'ACTION'">
              {{ $t("Action") }}
            </span>
          </template>
          <!-- Custom Rows -->
          <template slot="table-row" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.field == 'action'">
              <b-button class="no-wrap btn-pill" variant="warning" size="sm" @click="updateData(props.row)">
                <span class="fe fe-edit"></span>
              </b-button>
              <b-button class="no-wrap ml-2" variant="danger" style="margin-left: 5px" size="sm"
                @click="deleteData(props.row.id)">
                <span class="fe fe-trash-2"></span>
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </div>

      <!-- Modal -->
      <b-modal id="modal-1" centered hide-footer :title="$t('Block Form')">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="form-group">
            <label>{{ $t("Chapter") }}<span class="text-danger">*</span></label>
            <v-select v-model="chapter_id" :options="chapter.data" label="chapter_name" :reduce="(e) => e.id"
              name="Chapter" v-validate="'required'" />
            <span class="text-sm text-danger" v-show="errors.has('Chapter')">{{
              errors.first("Chapter")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Block Name") }}<span class="text-danger">*</span></label>
            <input type="text" v-model="block_name" class="form-control" placeholder="Block Name" name="Block"
              v-validate="'required'" />
            <span class="text-sm text-danger" v-show="errors.has('Block')">{{
              errors.first("Block")
            }}</span>
          </div>
          <button style="margin-left: auto; float: right" class="btn btn-primary" type="submit"
            v-if="isLoading == false">
            {{ $t("Save") }}
          </button>
          <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled v-else>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="form-group">
            <label>{{ $t("Chapter") }}</label>
            <v-select v-model="chapter_ids" :options="chapter.data" label="chapter_name" :reduce="(e) => e.id"
              v-validate="'required'" name="Chapter Name" />
            <span class="text-sm text-danger"
              v-show="errors.has('Chapter Name')">{{ errors.first("Chapter Name") }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("Block Name") }}</label>
            <input type="text" v-model="block_names" class="form-control" placeholder="Block Name"
              v-validate="'required'" name="Block Name" />
            <span class="text-sm text-danger" v-show="errors.has('Block Name')">{{ errors.first("Block Name") }}</span>
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled v-else>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
  export default {
    name: "defaultCMS",
    computed: {
      block() {
        return this.$store.getters["block/BlockData"] ?
          this.$store.getters["block/BlockData"] :
          [];
      },
      chapter() {
        return this.$store.getters["chapter/ChapterData"] ?
          this.$store.getters["chapter/ChapterData"] :
          [];
      }
    },
    data() {
      return {
        body: {
          page: 1,
          limit: 100,
          order: "",
          block_name: "",
          chapter_name: ""
        },
        bodys: {
          page: 1,
          limit: 100,
          order: ""
        },
        chapter_id: null,
        chapter_ids: null,
        block_name: "",
        block_names: "",
        id: "",
        isAdd: true,
        isLoading: false,
        columns: [{
            label: "CHAPTER",
            field: "chapter_name",
            thClass: "table-header w-50 text-muted font-weight-bold",
            tdClass: "color-secondary align-middle"
          },
          {
            label: "BLOCK",
            field: "block_name",
            thClass: "table-header w-50 text-muted font-weight-bold",
            tdClass: "color-secondary align-middle"
          },
          {
            label: "ACTION",
            field: "action",
            thClass: "table-header no-wrap text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          }
        ]
      };
    },
    mounted() {
      // if (this.block && this.block.length < 1) {
      this.getBlock();
      this.getChapter();
      // }
    },
    methods: {
      //action
      updateData(props) {
        this.$bvModal.show("modal-1");
        this.isAdd = false;
        this.id = props.id;
        this.block_names = props.block_name;
        this.chapter_ids = props.chapter_id;
      },

      saveAdd() {
        this.$validator.validateAll().then(async (res) => {
          if (!res) return false;
          try {
            this.isLoading = true;
            this.$store
              .dispatch("block/addBlock", {
                block_name: this.block_name,
                chapter_id: this.chapter_id
              })
              .then((resp) => {
                this.$swal({
                  title: this.$t("Saved"),
                  text: this.$t("Your input has been saved"),
                  icon: "success",
                  confirmButtonText: this.$t("OK")
                }).then((resp) => {
                  this.isLoading = false;
                  this.block_name = "";
                  this.chapter_id = "";
                  this.getBlock();
                  this.$bvModal.hide("modal-1");
                });
              }).catch((err) => {
                this.$swal.fire("Error!", `${err.response.data.error}`, "error");
                this.isLoading = false
              });
          } catch (resp) {
            this.$swal("Error", this.$t("Error while add data"), "error");
          }
        });
      },
      saveUpdate() {
        let body = {
          id: this.id,
          block_name: this.block_names,
          chapter_id: this.chapter_ids
        };
        this.isLoading = true;
        this.$store
          .dispatch("block/updateBlock", body)
          .then((resp) => {
            this.$swal({
              title: this.$t("Saved"),
              text: this.$t("Your input has been saved"),
              icon: "success",
              confirmButtonText: this.$t("OK")
            }).then((resp) => {
              this.isLoading = false;
              this.getBlock();
              this.$bvModal.hide("modal-1");
            });
          })
          .catch((resp) => {
            this.$swal("Error", this.$t("Error while edit data"), "error");
          });
      },

      deleteData(id) {
        this.$swal({
          title: this.$t("Are you sure?"),
          text: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00D97E",
          cancelButtonColor: "#E63757",
          confirmButtonText: this.$t("Yes, delete it!"),
          cancelButtonText: this.$t("Cancel")
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("block/delBlock", id)
              .then((resp) => {
                this.getBlock();
                this.$swal(
                  "Deleted!",
                  this.$t("Your file has been deleted"),
                  "success"
                );
              })
              .catch((resp) => {
                this.$swal("Error", this.$t("Error while delete data"), "error");
              });
          }
        });
      },

      // api hit
      getBlock() {
        this.$store.dispatch("block/getBlock", this.body);
      },
      getChapter() {
        this.$store.dispatch("chapter/getChapter", this.bodys);
      },

      // filter
      filterPage() {
        this.getBlock();
      },

      sorting() {
        this.getBlock();
      },

      // Pagination
      onPageChange(payload) {
        this.body.limit = payload.currentPerPage;
        this.body.page = payload.currentPage;

        //   "total",
        //   parseInt(payload.currentPage) * parseInt(payload.currentPerPage)
        // );
        // if (
        //   parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
        //   1000
        // ) {
        this.getBlock();
        // }
      }
    }
  };
</script>