<template>
  <!-- HEADER -->
  <div class="header">
    <!-- Body -->
    <div :class="`cart ${load === true ? '' : 'd-none'}`">
      <div class="card-body text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div :class="`${load === true ? 'd-none' : ''}`">
      <b-collapse id="collapse-1">
        <form @submit.prevent="sorting()">
          <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
            <div class="col">
              <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">{{
                  $t("Name")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="body.hazard_name" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">Order</label>
                <v-select
                  v-model="body.order"
                  :options="['ASC', 'DESC']"
                  @input="sorting" />
              </div>
            </div>
          </div>

          <div class="row mb-3" style="margin-right: 15px">
            <div class="col"></div>
            <div class="col-auto">
              <!-- Button -->
              <button type="submit" class="btn btn-danger lift">
                {{ $t("Filter") }}
              </button>
            </div>
          </div>
          <!-- / .row -->
        </form>
      </b-collapse>
      <div style="overflow: auto">
        <table class="table table-sm table-nowrap table-bordered">
          <thead>
            <tr>
              <th scope="col" rowspan="2">
                {{ $t("Occupational Health Indicators") }}
              </th>
              <th colspan="12" style="text-align: center">{{ $t("Month") }}</th>
              <th scope="col" rowspan="2" class="text-center">
                {{ $t("Total") }}
              </th>
            </tr>
            <tr>
              <th
                v-for="(i, index) in mothly"
                :key="index"
                scope="col"
                class="text-center">
                {{ i }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(label, index) in ohDaily" :key="index">
              <th scope="row">
                {{ label.oh_label }}
                <span
                  v-if="label.description != null"
                  :title="label.description"
                  class="fe fe-alert-circle"></span>
              </th>
              <td
                class="text-center"
                v-for="(i, index) in lastmonth"
                :key="index">
                {{
                  acumalaka(i)
                    ? convertNumber(revealValue(i, label.values)) === ""
                      ? dataNol(index)
                      : convertNumber(revealValue(i, label.values))
                    : ""
                }}
              </td>
              <th class="text-center">
                {{ convertNumber(label.total) }}
                <!-- {{ convertNumber(findTotalData(label.oh_id, label.oh_type)) === "" ? 0 : convertNumber(findTotalData(label.oh_id, label.oh_type)) }} -->
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "Occupational",
  props: ["infoData"],
  computed: {
    ohData() {
      return this.$store.getters["ohData/OhData"]
        ? this.$store.getters["ohData/OhData"]
        : [];
    },
    activity() {
      return this.$store.getters["activity/ActivityData"]
        ? this.$store.getters["activity/ActivityData"]
        : [];
    },
    ohDaily() {
      return this.$store.getters["detailManagement/OHDailyData"]
        ? this.$store.getters["detailManagement/OHDailyData"]
        : [];
    },

    bodyData() {
      return this.$store.getters["leadingLagging/body_data"]
        ? this.$store.getters["leadingLagging/body_data"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        limit: 100,
        order: ""
      },
      bodyDataDaily: {
        oh_type: "Data",
        type_input: "Monthly",
        company_id: localStorage.getItem("company"),
        date_input: moment().format("YYYY")
      },
      date_input: "",
      record: [],
      recordAct: [],
      direct: "OH",
      mothly: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MEI",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC"
      ],
      totalData: [],
      thisMonth: moment().format("M"),
      thisYear: moment().format("YYYY"),
      lastmonth: 12,
      load: true
    };
  },
  mounted() {
    this.$root.$on("occupational", (body) => {
      this.bodyDataDaily.company_id = body.company_id;
      this.bodyDataDaily.date_input = body.date_input;
      this.customGetOH();
    });
    const lastmonth = moment().endOf("year").format("M");
    this.lastmonth = Number(lastmonth);
    if (this.bodyData.length == undefined) {
      this.bodyDataDaily.company_id = this.bodyData.company_id;
      this.bodyDataDaily.date_input = this.bodyData.date_input;
    }
    this.customGetOH();
  },
  // created() {
  // },
  methods: {
    acumalaka(i){
      if(this.bodyDataDaily.date_input != this.thisYear){
        return true
      }else{
        if(i <= this.thisMonth){
          return true
        }else{
          return false
        }
      }
    },

    revealValue(monthly, values) {
      const index = values.findIndex((e) => e.month === monthly);
      if (index > -1) {
        return values[index]["value"];
      } else {
        return "";
      }
    },
    dataNol(index) {
      const dayNow = moment().format("MM");
      if (index < dayNow) {
        return 0;
      }
    },
    // api hit
    getOh() {
      this.$store.dispatch("ohData/getOh", this.body).then(() => {
        const datas = this.$store.getters["ohData/OhData"];
        const list = datas.data;
        let rec = list.map((item) => {
          return {
            oh_type: "Data",
            oh_id: Number(item.id),
            oh_label: item.data_name,
            value: item.type_data == "Integer" && !item.is_mandatory ? 0 : ""
          };
        });
        this.record = rec;
      });
    },
    // findTotalData(id, type) {
    // 		const data = this.ohDaily;
    // 		let obj = data.find((o) => {
    //       return o.oh_type == type && o.oh_id == id;
    // 		});
    // 		let datas = obj.values.map((item) => {
    //       return item.value
    // 		})
    //       // return console.log(obj)

    // 		var dat = datas.reduce((total, value) => total + value, 0)

    // 		return dat;
    // 	},

    customGetOH() {
      this.load = true;
      this.$store
        .dispatch("detailManagement/getDataDaily", this.bodyDataDaily)
        .then(() => {
          this.load = false;
        });
    },

    getActivity() {
      this.$store.dispatch("activity/getActivity", this.body).then(() => {
        const datas = this.$store.getters["activity/ActivityData"];
        const list = datas.data;
        let rec = list.map((item) => {
          return {
            oh_type: "Activity",
            oh_id: Number(item.id),
            oh_label: item.activity_name,
            value: item.type_data == "Integer" && !item.is_mandatory ? 0 : ""
          };
        });
        this.recordAct = rec;
      });
    },

    emit() {},

    // filter
    filterPage() {
      this.getOhData();
    },

    sorting() {
      this.getOhData();
    },
    convertNumber(num) {
      if (num) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return num;
      }
    }
  }
};
</script>
