<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-6">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item">{{ $t("Inventory") }}</li>
                <li class="breadcrumb-item">Delivery Order</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Table -->
      <div class="row">
        <div class="col">
          <div class="card mt-2">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title">{{ $t("List DO") }}</h4>
              <!-- Button -->
              <router-link
                tag="b-button"
                variant="primary"
                to="/clinic-management/input-do">
                <span class="fe fe-file-plus"></span>
                Create New DO
              </router-link>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-end mb-3">
                <label class="align-self-center mb-0 mr-3">Search</label>
                <b-form-input
                  v-model="body.searchQuery"
                  class="w-auto"
                  placeholder="Type Here..."
                  @change="getDataDO()"></b-form-input>
              </div>

              <vue-good-table
                mode="remote"
                :line-numbers="true"
                :columns="listcolumns"
                :rows="dataDO.rows"
                :total-rows="dataDO.totalRecords"
                :sort-options="{
                  enabled: true
                }"
                :pagination-options="{
                  enabled: true,
                  mode: 'pages',
                  position: 'bottom',
                  perPage: 10,
                  perPageDropdown: [10, 50, 100],
                  dropdownAllowAll: false,
                  setCurrentPage: 1,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'page',
                  allLabel: 'All'
                }"
                style-class="vgt-table table table-bordered"
                @on-page-change="onPageChange"
                @on-per-page-change="onPageChange"
                @on-sort-change="onSortChange"
                :isLoading.sync="isLoading">
                <!-- Loading -->
                <div slot="emptystate" class="text-center font-size-14">
                  No data available
                </div>
                <template slot="table-row" slot-scope="props">
                  <!-- Table Action -->
                  <span v-if="props.column.field == 'action'">
                    <b-button
                      class="no-wrap btn-pill"
                      title="Detail"
                      variant="info"
                      size="sm"
                      v-b-modal.modal-do
                      @click="detail(props.row)">
                      <span class="fe fe-clipboard"></span>
                    </b-button>
                    <b-button
                      v-if="props.row.po_status === 'SAVED'"
                      class="no-wrap ml-2"
                      variant="warning"
                      title="Edit"
                      style="margin-left: 5px"
                      size="sm"
                      @click.prevent="
                        $router.push(
                          `/clinic-management/input-po?id=${props.row.id}`
                        )
                      ">
                      <span class="fe fe-edit"></span>
                    </b-button>
                    <b-button
                      v-if="props.row.po_status === 'SAVED'"
                      class="no-wrap ml-2"
                      variant="danger"
                      title="Cancel"
                      style="margin-left: 5px"
                      size="sm"
                      @click="deleteData(props.row.id)">
                      <span class="fe fe-archive"></span>
                    </b-button>
                  </span>
                  <span
                    v-else-if="props.column.field == 'do_status'"
                    v-html="badges(props.row.do_status)">
                  </span>
                  <!-- <span v-else-if="props.column.field == 'price'">
                                        Rp. {{ convertNumber(props.row.price) }}
                                    </span> -->
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
      id="modal-do"
      size="xl"
      hide-footer
      :title="`${dataDetail.id} Detail`"
      centered>
      <div class="row">
        <div class="col-lg-4 col-md-12">
          <table cellpadding="2" id="rawTable">
            <tr>
              <td>SAP / System DO Number</td>
              <td>:</td>
              <td>{{ dataDetail.sap_do_number }}</td>
            </tr>
            <tr>
              <td>Order Date</td>
              <td>:</td>
              <td>{{ convertDate(dataDetail.order_time) }}</td>
            </tr>
            <tr>
              <td>Supplier Name</td>
              <td>:</td>
              <td>{{ dataDetail.supplier_name }}</td>
            </tr>
            <tr>
              <td>Note</td>
              <td>:</td>
              <td>{{ dataDetail.note }}</td>
            </tr>
          </table>
        </div>
        <div class="col-lg-8 col-md-12">
          <table class="table table-bordered table-striped po-table table-sm">
            <thead>
              <tr>
                <th scope="col">NO</th>
                <th scope="col">SAP Item ID</th>
                <th scope="col">Item Name</th>
                <th scope="col">QTY</th>
                <th scope="col">Unit</th>
                <th scope="col">EXP Date</th>
                <th scope="col">Price</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in dataDetail.item" :key="index">
                <th>{{ index + 1 }}</th>
                <td>{{ item.sap_material_number }}</td>
                <td>{{ item.item_label }}</td>
                <td>{{ item.item_qty }}</td>
                <td>{{ item.item_uom_name }}</td>
                <td>{{ convertDate(item.expiry_date) }}</td>
                <td class="text-right">
                  Rp. {{ convertCurrency(item.item_price) }}
                </td>
              </tr>
              <tr>
                <td class="text-right" colspan="6">Total</td>
                <td class="text-right" v-if="checkProp(dataDetail)">
                  Rp. {{ dataDetail.do_price }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "inventoryInputDO",
  computed: {
    dataDO() {
      return this.$store.getters["Do/DeliveryOrderData"]
        ? this.$store.getters["Do/DeliveryOrderData"]
        : [];
    }
  },
  data() {
    return {
      body: {
        page: 1,
        perPage: 10,
        searchQuery: "",
        sort: ""
      },
      dataDetail: {},
      datas: [
        {
          id: 1,
          sap_code: "#5491",
          suply: "PT. Kalbe",
          qty: "100",
          price: "150000",
          status: "Order"
        },
        {
          id: 2,
          sap_code: "#2313",
          suply: "PT. Kimia Farma",
          qty: "150",
          price: "250000",
          status: "Order"
        }
      ],
      listcolumns: [
        {
          label: "SAP / System DO Number",
          field: "sap_do_number",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "SAP / System PO Number",
          field: "po_id",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Supplier",
          field: "supplier_name",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Status",
          field: "do_status",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle text-center"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle text-center",
          sortable: false
        }
      ],
      isLoading: false
    };
  },
  mounted() {
    this.getDataDO();
  },
  methods: {
    async getDataDO() {
      this.isLoading = true;
      this.$store.dispatch("Do/getListDO", this.body).finally(() => {
        this.isLoading = false;
      });
    },

    convertNumber(num) {
      return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    convertDate(d) {
      return moment(d).format("D MMM YYYY");
    },

    onSortChange(params) {
      let sort1 = params[0].field;
      let sort2 = params[0].type;
      this.body.sort = `${sort1},${sort2}`;
      this.getDataDO();
    },

    deleteData(id) {
      let body = {
        id: id
      };
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("Do/deleteDO", body).then(() => {
              this.getDataDO();
              this.$swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              );
            });
          }
        });
    },

    detail(props) {
      this.dataDetail = props;
    },

    badges(status) {
      switch (status) {
        case "SAVED":
          return `<span class="badge bg-info-soft">SAVED</span>`;
        case "DELIVERED":
          return `<span class="badge bg-success-soft">DELIVERED</span>`;
        case "CANCELED":
          return `<span class="badge bg-danger-soft">CANCELED</span>`;
        default:
          break;
      }
    },

    convertCurrency(dat) {
      return dat.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    checkProp(e) {
      return e.hasOwnProperty("id");
    },

    onPageChange(payload) {
      this.body.perPage = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getDataDO();
    }

    // searchByQuery(e) {
    //   console.log(e);
    // }
  }
};
</script>
<style>
.po-table td:first-child,
.po-table td:nth-last-child(3),
.po-table td:nth-last-child(2),
.po-table td:last-child {
  text-align: center;
}

.po-table > :not(caption) > * > * {
  padding: 0.5rem !important;
}
</style>
