<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-4">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item">{{ $t("Patient") }}</li>
                <li class="breadcrumb-item">Medical Record</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-5">
          <!-- Content -->
          <div class="card">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title">Patient</h4>
            </div>
            <div>
              <div class="mb-3 p-4">
                <div class="row mb-4">
                  <div class="col">Name : {{ patientRecord.patient_name }}</div>
                  <div class="col">Company : {{ patientRecord.company }}</div>
                </div>
                <div class="row">
                  <div class="col">
                    Department : {{ patientRecord.department }}
                  </div>
                  <div class="col">Position : {{ patientRecord.position }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-xl-7">
          <!-- Activity -->
          <div>
            <div class="card">
              <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">Medical Record</h4>
              </div>
              <div class="card-body">
                <!-- List group -->
                <div
                  class="list-group list-group-flush list-group-activity"
                  style="max-height: 550px; overflow: auto">
                  <div
                    class="list-group-item"
                    v-for="(item, index) in patientRecord.record"
                    :key="index">
                    <div class="row">
                      <div class="col-auto">
                        <!-- Avatar -->
                        <div class="avatar avatar-sm">
                          <div
                            class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                            <i class="fe fe-activity"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col ms-n2">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-6">
                                <div class="mb-2">
                                  <div>Date Visit</div>
                                  <div class="h4 font-weight-bold">
                                    {{ item.date_visit }}
                                  </div>
                                </div>
                                <div class="mb-2">
                                  <div>Clinic Name</div>
                                  <div class="h4 font-weight-bold">
                                    {{ item.clinic_name }}
                                  </div>
                                </div>
                                <div class="mb-2">
                                  <div>Paramedic Name</div>
                                  <div class="h4 font-weight-bold">
                                    {{ item.paramedic_name }}
                                  </div>
                                </div>
                                <div class="mb-2">
                                  <div>Doctor</div>
                                  <div class="h4 font-weight-bold">
                                    {{ item.doctor }}
                                  </div>
                                </div>
                                <div class="mb-2">
                                  <div>Weight</div>
                                  <div class="h4 font-weight-bold">
                                    {{ item.weight }} KG
                                  </div>
                                </div>
                                <div class="mb-2">
                                  <div>Allergy</div>
                                  <div class="h4 font-weight-bold">
                                    {{ item.allergy }}
                                  </div>
                                </div>
                                <div class="mb-2">
                                  <div>Vital Sign</div>
                                  <div class="h4 font-weight-bold">
                                    {{ item.vital_sign }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="mb-2">
                                  <div>Type Disease</div>
                                  <div class="h4 font-weight-bold">
                                    {{ item.disease_name }}
                                  </div>
                                </div>
                                <div class="mb-2">
                                  <div>Action Care</div>
                                  <div class="h4 font-weight-bold">
                                    {{ item.action_care }}
                                  </div>
                                </div>
                                <div class="mb-2">
                                  <div>PAK</div>
                                  <div class="h4 font-weight-bold">
                                    {{ item.pak }}
                                  </div>
                                </div>
                                <div class="mb-2">
                                  <div>SPELL</div>
                                  <div class="h4 font-weight-bold">
                                    {{ item.spell }}
                                  </div>
                                </div>
                                <div class="mb-2">
                                  <div>Height</div>
                                  <div class="h4 font-weight-bold">
                                    {{ item.height }} CM
                                  </div>
                                </div>
                                <div class="mb-2">
                                  <div>Blood Preasure</div>
                                  <div class="h4 font-weight-bold">
                                    {{ item.blood_pressure }} mmHg
                                  </div>
                                </div>
                                <div class="mb-2">
                                  <div>Complaint</div>
                                  <div class="h4 font-weight-bold">
                                    {{ item.init_complaint }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- / .row -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "patient",
  components: {},
  computed: {
    // patientRecord() {
    //     return this.$store.getters["patient/dataPatientRecord"] ?
    //         this.$store.getters["patient/dataPatientRecord"] : [];
    // },
  },
  data() {
    return {
      patientRecord: []
    };
  },
  mounted() {
    this.patientRecord = JSON.parse(localStorage.getItem("dataRecord"));
  }
};
</script>
