import queryString from 'query-string'
import {
    GET_CHEMICAL_DATA,
    ADD_CHEMICAL,
    DEL_CHEMICAL,
    UPDATE_CHEMICAL
} from "@/api/hazard/chemical";


const state = {
    dataChemical: [],
};

const mutations = {
    setChemical: (states, payload) => {
        states.dataChemical = payload;
    },
};

const actions = {
    getChemical: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_CHEMICAL_DATA(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    commit("setChemical", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setChemical", {
                        setChemical: [],
                    });
                }
            });
    },

    addChemical: async ({ }, args) => {
        return await ADD_CHEMICAL(args);
    },

    delChemical: async ({ }, id) => {
        return await DEL_CHEMICAL(id);
    },

    updateChemical: async ({ }, args) => {
        return await UPDATE_CHEMICAL(args);
    },



};

const getters = {
    ChemicalData: (states) => states.dataChemical
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};