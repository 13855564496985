import axiosIns from '@/api/axios'

export const GET_CHEMICAL_DATA = (args) => {
    return axiosIns.get(`chemical/hazard?${args}`)
}

export const ADD_CHEMICAL = (args) => {
    return axiosIns.post(`chemical/hazard/create`, args,  {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

export const DEL_CHEMICAL = (id) => {
    return axiosIns.put(`chemical/hazard/delete?id=${id}`)
}

export const UPDATE_CHEMICAL = (args) => {
    return axiosIns.put(`chemical/hazard/update`, args, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}