import queryString from "query-string";
import { GET_MRC } from "@/api/clinic/medical_record_mcu";

const state = {
    dataMRC: [],
};

const mutations = {
    setMRCData: (states, payload) => {
        states.dataMRC = payload;
    },
}

const actions = {
    getMRCData: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_MRC(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setMRCData", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setMRCData", {
                        setMRCData: [],
                    });
                }
            });
    },
}

const getters = {
    MRCData: (states) => states.dataMRC,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};