import axiosIns from '@/api/axios'

export const GET_TOOLS_DATA = (args) => {
    return axiosIns.get(`/items?${args}`)
}

export const ADD_TOOLS = (args) => {
    return axiosIns.post(`/items`, args)
}

export const DEL_TOOLS = (id) => {
    return axiosIns.delete(`/items?id=${id}`)
}

export const UPDATE_TOOLS  = (args) => {
    return axiosIns.put(`/items?id=${args.id}`, args)
}