<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <!-- / .row -->

      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("Category") }}</h4>
          <!-- Button -->
          <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
            <span class="fe fe-file-plus"></span>
            {{ $t("Add") }}
          </b-button>
          <b-button v-b-toggle.collapse-1 variant="primary" style="margin-left: 7px">
            <span class="fe fe-sliders"></span>
          </b-button>
        </div>
        <b-collapse id="collapse-1">
          <form @submit.prevent="sorting()">
            <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Category Name")
                  }}</label>
                  <input type="text" class="form-control" v-model="body.item_category_name" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">Order</label>
                  <v-select v-model="body.order" :options="['ASC', 'DESC']" />
                  <!-- <v-select v-model="body.order" :options="['ASC', 'DESC']" @input="sorting" /> -->
                </div>
              </div>
            </div>

            <div class="row mb-3" style="margin-right: 15px">
              <div class="col"></div>
              <div class="col-auto">
                <!-- Button -->
                <button type="submit" class="btn btn-danger lift">
                  {{ $t("Filter") }}
                </button>
              </div>
            </div>
            <!-- / .row -->
          </form>
        </b-collapse>
        <vue-good-table :line-numbers="true" :columns="columns" :rows="category" :sort-options="{
            enabled: true
          }" :total-rows="category.totalItems" :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }" style-class="vgt-table border-0 table" @on-page-change="onPageChange" @on-per-page-change="onPageChange">
          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <template slot="table-column" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.label == 'CATEGORY'">
              {{ $t("Category") }}
            </span>
            <span v-if="props.column.label == 'ACTION'">
              {{ $t("Action") }}
            </span>
          </template>
          <!-- Custom Rows -->
          <template slot="table-row" slot-scope="props">
            <!-- Table Action -->
            <span v-if="props.column.field == 'action'">
              <b-button class="no-wrap btn-pill" variant="warning" size="sm" @click="updateData(props.row)">
                <span class="fe fe-edit"></span>
              </b-button>
              <b-button class="no-wrap ml-2" variant="danger" style="margin-left: 5px" size="sm"
                @click="deleteData(props.row.id)">
                <span class="fe fe-trash-2"></span>
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </div>

      <!-- Modal -->
      <b-modal id="modal-1" hide-footer :title="$t('Category Form')">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="form-group">
            <label>{{ $t("Category Name") }}</label>
            <input type="text" v-model="item_category_name" class="form-control" placeholder="Category Name"
              v-validate="'required'" name="Category Name" />
            <span class="text-sm text-danger"
              v-show="errors.has('Category Name')">{{ errors.first("Category Name") }}</span>
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled v-else>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>

        <form @submit.prevent="saveUpdate()" v-else>
          <div class="form-group">
            <label>{{ $t("Category Name") }}</label>
            <input type="text" v-model="item_category_names" name="Category Name" v-validate="'required'" class="form-control" placeholder="Category Name" />
            <span class="text-sm text-danger"
              v-show="errors.has('Category Name')">{{ errors.first("Category Name") }}</span>
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled v-else>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
  export default {
    name: "defaultCMS",
    computed: {
      category() {
        return this.$store.getters["categoryDrug/CategoryData"] ?
          this.$store.getters["categoryDrug/CategoryData"] :
          [];
      }
    },
    data() {
      return {
        body: {
          page: 1,
          limit: 1000,
          order: "",
          item_category_name: "",
          item_type: "MEDICINE"
        },
        item_category_name: "",
        item_type: "MEDICINE",
        item_category_names: "",
        id: "",
        isAdd: true,
        isLoading: false,
        columns: [{
            label: "CATEGORY",
            field: "item_category_name",
            thClass: "table-header w-100 no-wrap text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          },
          {
            label: "ACTION",
            field: "action",
            thClass: "table-header no-wrap text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary align-middle"
          }
        ]
      };
    },
    mounted() {
      this.getCategory();
    },
    methods: {
      //action
      updateData(props) {
        this.$bvModal.show("modal-1");
        this.isAdd = false;
        this.id = props.id;
        this.item_category_names = props.item_category_name;
      },

      saveAdd() {
        let body = {
          item_category_name: this.item_category_name,
          item_type: "MEDICINE"
        };
        this.$validator.validateAll().then(async (res) => {
          if (!res) return false;
          try {
            this.isLoading = true;
            this.$store
              .dispatch("categoryDrug/addCategory", body)
              .then((resp) => {
                this.$swal({
                  title: this.$t("Saved"),
                  text: this.$t("Your input has been saved"),
                  icon: "success",
                  confirmButtonText: this.$t("OK")
                }).then((resp) => {
                  this.isLoading = false;
                  this.item_category_name = "";
                  this.getCategory();
                  this.$bvModal.hide("modal-1");
                });
              }).catch((err) => {
                this.isLoading = false;
                this.$swal("Error", `${err.response.data.error}`, "error");
              });
          } catch (resp) {
            this.$swal("Error", this.$t("Error while add data"), "error");
          }
        });
      },

      saveUpdate() {
        // let body = {
        //   id: this.id,
        //   item_category_name: this.item_category_names,
        // };
        let body = {
          id: this.id,
          item_category_name: this.item_category_names,
          item_type: "MEDICINE"
        };
        this.isLoading = true;
        this.$store
          .dispatch("categoryDrug/updateCategory", body)
          .then((resp) => {
            this.$swal({
              title: this.$t("Saved"),
              text: this.$t("Your input has been saved"),
              icon: "success",
              confirmButtonText: this.$t("OK")
            }).then((resp) => {
              this.isLoading = false;
              this.getCategory();
              this.$bvModal.hide("modal-1");
            });
          })
          .catch((resp) => {
            this.$swal("Error", this.$t("Error while add data"), "error");
          });
      },

      deleteData(id) {
        this.$swal({
          title: this.$t("Are you sure?"),
          text: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00D97E",
          cancelButtonColor: "#E63757",
          confirmButtonText: this.$t("Yes, delete it!"),
          cancelButtonText: this.$t("Cancel")
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("categoryDrug/delCategory", id)
              .then((resp) => {
                this.getCategory();
                this.$swal(
                  "Deleted!",
                  this.$t("Your file has been deleted"),
                  "success"
                );
              })
              .catch((resp) => {
                this.$swal("Error", this.$t("Error while delete data"), "error");
              });
          }
        });
      },

      // api hit
      getCategory() {
        this.$store.dispatch("categoryDrug/getCategory", this.body);
      },

      // filter
      filterPage() {
        this.getCategory();
      },

      sorting() {
        this.getCategory();
      },

      // Pagination
      onPageChange(payload) {
        // this.body.limit = payload.currentPerPage;
        // this.body.page = payload.currentPage;
        // this.getCategory();
      }
    }
  };
</script>