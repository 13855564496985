import { render, staticRenderFns } from "./factor.vue?vue&type=template&id=78aa1a54"
import script from "./factor.vue?vue&type=script&lang=js"
export * from "./factor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports