var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"mt-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item fw-bolder"},[_vm._v(" "+_vm._s(_vm.$t("OH Management"))+" ")]),_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Report")]),_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Recap Daily Input")])])])])]),_c('div',{staticClass:"card mt-5"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v("Recap Daily Input")]),_c('div',[_c('b-form-input',{attrs:{"id":"date_input","type":"date"},on:{"change":function($event){return _vm.getRecap()}},model:{value:(_vm.param.date_input),callback:function ($$v) {_vm.$set(_vm.param, "date_input", $$v)},expression:"param.date_input"}})],1)]),_c('div',{staticClass:"cart"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-end mb-3"},[_c('div',{staticClass:"d-flex"},[_c('label',{staticClass:"align-self-center mb-0 mr-3"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"w-auto",attrs:{"placeholder":"Type Here..."},on:{"change":function($event){return _vm.getRecap()}},model:{value:(_vm.param.searchQuery),callback:function ($$v) {_vm.$set(_vm.param, "searchQuery", $$v)},expression:"param.searchQuery"}})],1)]),_c('vue-good-table',{attrs:{"mode":"remote","line-numbers":false,"columns":_vm.columns,"rows":_vm.recap.rows,"total-rows":_vm.recap.totalRecords,"sort-options":{
                enabled: true
              },"pagination-options":{
                enabled: true,
                mode: 'pages',
                position: 'bottom',
                perPage: 10,
                perPageDropdown: [5, 10, 20, 50, 100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: 'All'
              },"group-options":{
                enabled: true,
                collapsable: true
              },"style-class":"vgt-table table table-bordered","isLoading":_vm.isLoading},on:{"on-sort-change":_vm.onSortChange,"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'timelog')?_c('span',[_vm._v(" "+_vm._s(_vm.timelog(props.row.timelog))+" ")]):_vm._e()]}}])},[_c('div',{staticClass:"text-center font-size-14",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" No data available ")])])],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }