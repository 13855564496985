import axiosIns from "@/api/axios";

export const GET_DATA_DRUG_TEST = (args) => {
    return axiosIns.get(`/clinic/patient/drugs/test?${args}`);
};

export const GET_DRUG_TEST_BY_REGISTER = (args) => {
    return axiosIns.get(`/clinic/patient/drugs/test/byRegistId?${args}`)
};

export const ADD_DRUG_TEST = (args) => {
    return axiosIns.post(`/clinic/patient/drugs/test/add`, args);
};