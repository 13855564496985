import queryString from "query-string";

import {
    GET_STAFF_POSITION_DATA,
    ADD_STAFF_POSITION,
    DEL_STAFF_POSITION,
    UPDATE_STAFF_POSITION
} from "@/api/medical/staff/position";

const state = {
    dataStaffPosition: [],
};

const mutations = {
    setStaffPosition: (states, payload) => {
        states.dataStaffPosition = payload;
    },
};

const actions = {
    getStaffPosition: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_STAFF_POSITION_DATA(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    commit("setStaffPosition", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setStaffPosition", {
                        setStaffPosition: [],
                    });
                }
            });
    },

    addStaffPosition: async ({}, args) => {
        return await ADD_STAFF_POSITION(args);
    },

    delStaffPosition: async ({}, id) => {
        return await DEL_STAFF_POSITION(id);
    },

    updateStaffPosition: async ({}, args) => {
        return await UPDATE_STAFF_POSITION(args);
    },



};

const getters = {
    StaffPositionData: (states) => states.dataStaffPosition
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};