import queryString from "query-string";

import {
    GET_CATEGORY_DATA,
    ADD_CATEGORY,
    DEL_CATEGORY,
    UPDATE_CATEGORY
} from "@/api/mcu/category/category";

const state = {
    dataCategory: [],
};

const mutations = {
    setCategory: (states, payload) => {
        states.dataCategory = payload;
    },
};

const actions = {
    getCategory: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_CATEGORY_DATA(params)
            .then((resp) => {
                if (resp.data.httpCode === 200) {
                    commit("setCategory", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setCategory", {
                        setCategory: [],
                    });
                }
            });
    },

    addCategory: async ({}, args) => {
        return await ADD_CATEGORY(args);
    },

    delCategory: async ({}, id) => {
        return await DEL_CATEGORY(id);
    },

    updateCategory: async ({}, args) => {
        return await UPDATE_CATEGORY(args);
    },



};

const getters = {
    CategoryData: (states) => states.dataCategory
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};