import queryString from "query-string";

import {
    GET_MEASURING_METHOD_DATA,
    ADD_MEASURING_METHOD,
    DEL_MEASURING_METHOD,
    UPDATE_MEASURING_METHOD
} from "@/api/ih/measuring/method";

const state = {
    dataMeasuringMethod: [],
};

const mutations = {
    setMeasuringMethod: (states, payload) => {
        states.dataMeasuringMethod = payload;
    },
};

const actions = {
    getMeasuringMethod: async ({
        commit
    }, args) => {
        const params = queryString.stringify(args);
        return await GET_MEASURING_METHOD_DATA(params)
            .then((resp) => {

                if (resp.data.httpCode === 200) {
                    commit("setMeasuringMethod", resp.data.data);
                }
            })
            .catch((err) => {
                let resp = err.response;
                console.log(err);
                if (resp.data.httpCode === 404) {
                    commit("setMeasuringMethod", {
                        setMeasuringMethod: [],
                    });
                }
            });
    },

    addMeasuringMethod: async ({ }, args) => {
        return await ADD_MEASURING_METHOD(args);
    },

    delMeasuringMethod: async ({ }, id) => {
        return await DEL_MEASURING_METHOD(id);
    },

    updateMeasuringMethod: async ({ }, args) => {
        return await UPDATE_MEASURING_METHOD(args);
    },



};

const getters = {
    MeasuringMethodData: (states) => states.dataMeasuringMethod
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};